<div class="object-data-container">
    <h4>{{ 'Rer.objects.addEdit.partnerDetails' | translate }}</h4>
    <mat-form-field class="w-100 mt-1">
        <input matInput autocomplete="off" type="details" [(ngModel)]="object.rer_client_name" [disabled]="true"
            placeholder="{{ 'Rer.objects.list.dataTable.client_name' | translate }}">
    </mat-form-field>
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [value]="(object.division ? object.division : '') + ' ' + object.city + ' ' +
            (object.street ? object.street : '') + ' ' + (object.street_number ? object.street_number : '')"
            [disabled]="true" placeholder="{{ 'Rer.objects.list.dataTable.address' | translate }}">
    </mat-form-field>
    <h4 class="mt-4">{{ 'Rer.objects.addEdit.objectDetails' | translate }}</h4>
    <div class="d-flex">
        <mat-form-field class="w-100 mt-4 mr-1">
            <input matInput autocomplete="off" type="details" [disabled]="true" [(ngModel)]="object.code"
                placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.code' | translate }}">
        </mat-form-field>
        <mat-form-field class="w-100 mt-4 ml-2">
            <input matInput autocomplete="off" type="details" [disabled]="true"
                value="{{ 'Rer.objects.status.' + (object.status | rerStatusTransform:'object') | translate }}"
                placeholder="{{ 'Rer.objects.list.dataTable.status' | translate }}">
        </mat-form-field>
    </div>
    <div class="d-flex">
        <mat-form-field class="w-100 mt-4 mr-1">
            <input matInput autocomplete="off" type="details" [disabled]="true" [value]="getWasteTypeName(object)"
                placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.wasteType' | translate }}">
        </mat-form-field>
        <mat-form-field class="w-100 mt-4 ml-2">
            <input matInput autocomplete="off" type="details" [disabled]="true" [value]="getBinTypeName(object)"
                placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.binType' | translate }}">
        </mat-form-field>
    </div>
    <div class="d-flex">
        <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center mr-2 pr-2" style="max-width: unset;">
            <ng-select [items]="rfidCompleteList" class="geozone flex-grow-1"
            placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.rfid' | translate }}" appendTo="body"
            bindLabel="rfid" bindValue="id" [multiple]="false" [clearable]="false" [closeOnSelect]="true"
            [(ngModel)]="object.rer_rfid_tag_id">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <span class="ml-2">{{ item.rfid }}</span>
            </ng-template>
        </ng-select>
        </div>
        <mat-form-field class="w-100 mt-4 ml-2">
            <input matInput autocomplete="off" type="details" [disabled]="true" [value]="getRerBinOwnershipName(object)"
                placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.binOwnership' | translate }}">
        </mat-form-field>
    </div>
    <div class="d-flex">
        <mat-form-field class="w-100 mt-4 mr-1">
            <input matInput autocomplete="off" type="details" [disabled]="true" [value]="object.rer_bin_setup_document_number"
                placeholder="{{ 'Rer.objects.list.dataTable.rer_bin_setup_document_number' | translate }}">
        </mat-form-field>
    </div>
    <mat-form-field class="w-100 mt-4 mb-4">
        <textarea matInput autocomplete="off" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="2"
            [(ngModel)]="object.description" [disabled]="!object.rer_client_address_id"
            placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.description' | translate }}">
        </textarea>
    </mat-form-field>
    <a *ngIf="object.latitude && object.latitude != 0 && object.longitude && object.longitude != 0"
        href="https://www.google.com/maps/search/?api=1&query={{ object.latitude }},{{ object.longitude}}"
        target="_blank" class="mt-4">
        {{ object.latitude + ', ' + object.latitude }}
    </a>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
<div class="d-flex justify-content-between align-items-center button-container">
    <div class="d-flex align-items-center">
        <div *ngIf="showMandatory">
            <i class="icon-cts-information font-size-28 color-red-primary align-middle mr-1"></i>
            <span class="align-middle"> {{ 'mandatoryFieldText' | translate }}</span>
        </div>
    </div>
    <div class="d-flex">
        <div *ngIf="showSendPVByEmailButton === true" class="button-component utility-info-bg-color ml-4"
            (click)="sendByEmail(object.rer_bin_setup_document_id)">
            <div class="inner inner-info user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.send' | translate }}</span>
            </div>
        </div>
        <div *ngIf="showSendPVByEmailButton === true" class="button-component utility-green-bg-color ml-4"
            (click)="downloadVerbalProcess(object.id)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.exportPdf' | translate }}</span>
            </div>
        </div>
        <div *ngIf="object.status === 1 && object.rer_bin_setup_document_id == null" class="button-component utility-red-bg-color ml-4"
            (click)="onChangeObjectStatus(0)">
            <div class="inner inner-red user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.deallocate' | translate }}</span>
            </div>
        </div>
        <div *ngIf="object.status === 1 && object.rer_bin_setup_document_id == null" class="button-component utility-orange-bg-color ml-4" (click)="onSuspend()">
            <div class="inner inner-orrange user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.suspend' | translate }}</span>
            </div>
        </div>
        <div *ngIf="object.status === 1 && object.rer_bin_setup_document_id == null" class="button-component utility-blue-bg-color ml-4"
            (click)="onChangeObjectStatus(3)">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.lost' | translate }}</span>
            </div>
        </div>
        <div *ngIf="(object.status === 2 || object.status === 3) && object.rer_bin_setup_document_id == null" class="button-component utility-blue-bg-color ml-4"
            (click)="onChangeObjectStatus(1)">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.activate' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-4" (click)="onSave()">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>
