import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../_service/rer.service';

import { RerCity } from '../models/rer-city.model';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.scss']
})
export class CityComponent {
  
  @Input() city: RerCity;
  
  public showSpinner = false;
  
  constructor(private rerService: RerService, private actionDialog: MatDialog, private translationService: TranslateService) { }

  public onSaveCity() {
    const city = {...this.city};
    city.street = undefined;
    this.showSpinner = true;
    this.rerService.saveRerStreetGeozoneCity(city).subscribe({
      next:(id: number) => {
        this.city.id = id;
        this.showSpinner = false;
      },
      error:() => {
        this.showSpinner = false;
        this.actionDialog.open(
          NotificationComponent,
          {
              maxWidth: '800px', panelClass: 'custom-dialog-container',
              data: { success: 0, headermessage: this.translationService.instant('error'), message: this.translationService.instant('generalError') }
          }
        );
      }
    });
  }

}
