import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { noop } from 'rxjs';

import { ToastData } from '../../../model/toastdata';
import { ToastRef } from '../../../model/toastref';
import { NotificationService } from '../../../service/notification.service';


@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {

    private intervalId: any;

    constructor(
        readonly data: ToastData,
        readonly ref: ToastRef,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnInit() {
        let duration = 0;
        switch (Number(this.data.notification.priority)) {
            case 1:
                duration = 2000;
                break;
            case 2:
                duration = 5000;
                break;
        }
        if (duration) {
            this.intervalId = setTimeout(() => this.close(), duration);
        }
    }

    ngOnDestroy() {
        if (this.intervalId) {
            clearTimeout(this.intervalId);
        }
    }

    public close(event?: any) {
        if (event) {
            event.stopPropagation();
            this.notificationService.markAsReadUserNotificationRequest({ ids: [this.data.notification.id] }).subscribe(
                () => this.notificationService.markAsReadUserNotifications([this.data.notification.id]),
                () => noop,
            );
        }
        this.ref.close();
    }

    public showNotification(): void {
        this.ref.close();
        this.router.navigate(['/notification', this.data.notification.id]);
    }
}
