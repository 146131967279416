import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../_service/rer.service';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { RerRoute } from '../models/rer-route.model';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean = false;
    public currentFilterValue: string = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Output() editrouteEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(private rerService: RerService, public dialog: MatDialog, private translationService: TranslateService, private actionDialog: MatDialog) { }

    ngOnInit() {
        this.showSpinner = true;
        this.displayedColumns = ['name', 'actions'];
        this.rerService.route$.subscribe({
            next:(cities: any[]) => {
                if (cities) {
                    this.showSpinner = false;
                }
                this.dataSource = new MatTableDataSource(cities);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            },
            error:() => {
                this.showSpinner = false;
            }
        });
    }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onEditRoute(id: number): void {
        this.editrouteEventEmitter.emit(id);
    }

    public onDeleteRoute(route: RerRoute): void {
        if (route && route.street.length > 0 || route.rerPlannings > 0) {
            return;
        }

        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.objects.list.dataTable.confirmDeleteAction')
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {                    
                    this.showSpinner = true;
                    this.rerService.deleteRerRoute(route.id).subscribe({
                        next:() => this.showSpinner = false,
                        error:() => {
                            this.showSpinner = false;
                            this.actionDialog.open(
                                NotificationComponent,
                                {
                                    maxWidth: '800px', panelClass: 'custom-dialog-container',
                                    data: {
                                        success: 0,
                                        headermessage: this.translationService.instant('error'),
                                        message: this.translationService.instant('generalError')
                                    }
                                }
                            );
                        }
                    });
                }
            }
        );
    }
}
