import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-upgrade-required',
    templateUrl: './upgrade-required.component.html',
    styleUrls: ['./upgrade-required.component.scss']
})
export class UpgradeRequiredComponent {

    constructor(
        public dialogRef: MatDialogRef<UpgradeRequiredComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
}
