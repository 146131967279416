import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription, noop } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';

import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerPlanning } from '../../_model/rerplanning.model';
import { RerService } from '../../_service/rer.service';
import { MatDialog } from '@angular/material/dialog';
import { RoutePlanningListComponent } from './route-planning-list/route-planning-list.component';

@Component({
    selector: 'app-planning-details',
    templateUrl: './planning-details.component.html',
    styleUrls: ['./planning-details.component.scss']
})
export class PlanningDetailsComponent implements OnInit, OnDestroy {

    public showSpinner: boolean;
    public dateFormat: string;
    public lastTransmition: string;
    public executionPercentage: number = 0;
    public routExecutionPlan;
    public faEye = faEye;
    public readingsCount = {
        total: 0,
        invoiceable: 0,
        invalid: 0
    }

    private _subscriptions: Subscription[] = [];

    @Output() closePlanningEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() changeDateEventEmitter: EventEmitter<{ date: string, planningId: number }> = new EventEmitter();
    @Input() planning: RerPlanning;

    constructor(
        private userPreferencesService: UserPreferencesService,
        private rerService: RerService,
        public translateService: TranslateService,
        private actionDialog: MatDialog
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.addSubscriptions();
    }

    ngOnDestroy(): void {
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }

    private addSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.planningsDetailsChangedSubject.subscribe(
                plannings => {
                    if (this.planning) {
                        this.planning = plannings.find(x => x.id == this.planning.id);
                    }
                }
            ),
            this.rerService.getRoutePlanningData(this.planning.id).subscribe({
                next:(data) => {
                    const target = data.length;
                    const completed = data.filter((el: any) => el.periodBegin).length;
                    this.executionPercentage = completed ? Math.ceil(completed / target * 100) : 0;
                    this.routExecutionPlan = data;
                },
                error:() => noop
            })
        );
    }

    public onCancelEventEmitter(event): void {
        this.closePlanningEventEmitter.emit(event);
    }

    public onChangeLastTransmition(event: string) {
        this.lastTransmition = event;
    }

    public onChangeReadingsCount(event) {
        this.readingsCount = event;
    }

    public changeReadingsFilter(type :boolean) {
        this.rerService.rerFilterByInvoiceableSubject.next(type);
    }

    public changePlanningDate(event) {
        this.changeDateEventEmitter.emit(event);
    }
    
    public compareDates(firstDate: string, secondDate: string, type: boolean) {
        if(type) {
            return moment(firstDate).isBefore(moment(secondDate));
        } else {
            return moment(firstDate).isSameOrAfter(moment(secondDate));
        }
    }

    public focusVehicleLocation() {
        this.rerService.rerMapVehicleLocationSubject.next(true);
    }

    public getWasteTypeName(id: number) {
        if (!id) {
            return '';
        }

        let wasteType = this.rerService.getWasteTypeById(id);
        return wasteType ? wasteType.name : '';
    }

    public onRoutePlanningExecutionView() {
        const dialogRef = this.actionDialog.open(
            RoutePlanningListComponent,
            {
                panelClass: 'custom-dialog-container',
                width: '80vw',
                height: '98vh',
                data: { routExecutionPlan: this.routExecutionPlan }
            }
        );
    }    
}
