import { FuelConsumptionInterface } from '../../map/interface/fuelconsumption.interface';

export class FuelConsumption implements FuelConsumptionInterface {
    constructor(

        public datetime = null,
        public mileage = null,
        public sensor = null,
        public value = null,
        public displayType = null,

    ) { }

    public static DISPLAY_TYPE_DISTANCE_BASED = 0;
    public static DISPLAY_TYPE_TIME_BASED = 1;
}
