<div class="bgcolor-white p-4 m-4" style="height: calc(100% - 50px);">
    <div class="d-flex h-50 overflow-auto w-100 p-2">
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportRerCollectionByWeightClient()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.rerCollectionByWeight.export.exportCollectionTitle' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportRerCollectionByWeightInDetail()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.rerCollectionByWeight.export.exportCollectionDetailTitle' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportRerWeighingReceipts()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.rerCollectionByWeight.export.exportWeighingReceiptTitle' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
