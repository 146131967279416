import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { UserPreferencesService } from 'app/service/userpreferences.service';

export interface CalendarDate {
    mDate: moment.Moment;
    selected?: boolean;
    today?: boolean;
}

// const CALENDAR_CONTAINER_HEIGHT = 460;
// const CALENDAR_CONTAINER_WIDTH = 308;

@Component({
    selector: 'app-datepicker-range',
    templateUrl: './datepicker-range.component.html',
    styleUrls: ['./datepicker-range.component.scss']
})
export class DatepickerRangeComponent implements OnInit, OnChanges {

    private currentDate = moment();
    private isCompleted: boolean;

    public weeks: CalendarDate[][] = [];
    public dayNames: String[];
    public isActivated: boolean;
    public selectedDates: CalendarDate[];
    public selectedDay: CalendarDate;
    public startDateTimeValue: String;
    public endDateTimeValue: String;
    public startTime = { hours: '00', minutes: '00' };
    public endTime = { hours: '23', minutes: '59' };
    public moment;
    public dateFormatShow;
    public timeFormat = ' HH:mm';
    public faCalendarAlt = faCalendarAlt;

    @Input() disabled = false;
    @Input() time = false;
    @Input() dateRange = [];
    @Input() dateFormat = 'YYYY-MM-DD';
    @Input() label = 'date';
    @Input() locale = 'en';
    @Input() recalculateTopPosition = false;
    @Input() alignCenter = false;
    @Input() justifyCenter = false;
    @Input() isMobile: boolean;
    @Input() zIndex = '999999999';
    @Input() maxHeight = '100%';

    @Input() allowFuture = true;
    @Input() allowPast = true;
    @Input() minDate: string; // string  format YYYY-MM-DD
    @Input() maxDate: string;       // string  format YYYY-MM-DD
    @Input() maxDaysNumber: number;

    @Output() selectDateEventEmitter = new EventEmitter<any>();
    @Input() doubleBorderLeft = false;
    @Input() doubleBorderTop = false;

    constructor(private userpreferencesService: UserPreferencesService) { }

    ngOnInit(): void {
        this.dateFormatShow = this.userpreferencesService.userPreferences.dateFormat ?
            this.userpreferencesService.userPreferences.dateFormat : this.userpreferencesService.defaultPreferences.dateFormat;

        moment.locale(this.locale);
        this.moment = moment;
        this.setDayNames();
        this.isActivated = false;
        this.isCompleted = false;
        this.setCalendar();
        this.generateCalendar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.selectedDates = [];
        this.setCalendar();
        this.generateCalendar();
    }

    private setCalendar(): void {
        let selectedDates = [];
        if (this.dateRange.length > 0 && this.dateRange[0] && this.dateRange[1]) {
            this.setTime(this.dateRange);
            selectedDates = this.getSelectedDates(this.dateRange);
        } else {
            selectedDates.push({ today: true, selected: true, mDate: moment() });
        }
        if (selectedDates[0] && selectedDates[selectedDates.length - 1]) {
            this.showDateTime(selectedDates[0].mDate, selectedDates[selectedDates.length - 1].mDate);
        }

        let isSelectedDatesAltered = false;

        selectedDates.forEach(
            (date: CalendarDate) => {
                if (this.isEnabled(date.mDate)) {
                    this.selectedDates.push(date);
                    isSelectedDatesAltered = true;
                }
            }
        );

        // if (isSelectedDatesAltered) {
        //     setTimeout(() => {
        //         this.showDateTime(this.selectedDates[0].mDate, this.selectedDates[this.selectedDates.length - 1].mDate);
        //         this.selectDateEventEmitter.emit({ dateBegin: this.startDateTimeValue, dateEnd: this.endDateTimeValue });
        //         console.log('set cu isselectedaltered');
        //     });
        // } else if (this.selectedDates.length === 0) {
        //     this.selectedDates.push({ today: true, selected: true, mDate: moment() });
        //     setTimeout(() => {
        //         this.showDateTime(this.selectedDates[0].mDate, this.selectedDates[this.selectedDates.length - 1].mDate);
        //         this.selectDateEventEmitter.emit({ dateBegin: this.startDateTimeValue, dateEnd: this.endDateTimeValue });
        //         console.log('set cu selectedlength0');
        //     });
        // }
    }

    private setDayNames(): void {
        switch (this.locale) {
            case 'ro':
                this.dayNames = ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa', 'Du'];
                break;
            case 'en':
                this.dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                break;
        }
    }

    private setTime(dateRange) {
        this.startTime.hours = dateRange[0].substring(11, 13);
        this.startTime.minutes = dateRange[0].substring(14, 16);
        this.endTime.hours = dateRange[1].substring(11, 13);
        this.endTime.minutes = dateRange[1].substring(14, 16);
    }

    private getSelectedDates(dateRange: String[]): CalendarDate[] {
        const startDay = dateRange[0].substring(0, 10);
        const endDay = dateRange[1].substring(0, 10);
        const range = moment(endDay).diff(moment(startDay), 'days');
        if (range === 0) {
            return [{ today: false, selected: true, mDate: moment(startDay) }];
        }

        return _.range(0, range + 1)
            .map((date: number): CalendarDate => ({ today: false, selected: true, mDate: moment(startDay).add(date, 'days') })
        );
    }

    private generateCalendar(): void {
        const dates = this.fillDates(this.currentDate);
        const weeks: CalendarDate[][] = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeks = weeks;
    }

    private fillDates(currentMoment: moment.Moment): CalendarDate[] {
        const firstOfMonth = moment(currentMoment).startOf('month').isoWeekday(1).day();
        const firstDayOfGrid = moment(currentMoment).startOf('month').isoWeekday(1).subtract(firstOfMonth, 'days');
        const startDay = firstDayOfGrid.date();
        return _.range(startDay + 1, startDay + 43).map((date: number): CalendarDate => {
                const d = moment(firstDayOfGrid).date(date);
                return { today: moment().isSame(moment(d), 'day'), selected: this.isSelected(d), mDate: d };
            }
        );
    }

    // date checkers
    private isSelected(date: moment.Moment): boolean {
        return _.findIndex(this.selectedDates, (selectedDate) => moment(date).isSame(selectedDate.mDate, 'day')) > -1;
    }

    public isSelectedMonth(date: moment.Moment): boolean {
        return moment(date).isSame(this.currentDate, 'month');
    }

    public isEnabled(date: moment.Moment): boolean {
        if (!this.allowFuture && moment(date).isAfter()) {
            return false;
        }

        if (this.maxDate && moment(date).isAfter(this.maxDate, 'day')) {
            return false;
        }

        if (!this.allowPast && moment(date).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
            return false;
        }

        if (this.minDate && moment(date).isBefore(this.minDate, 'day')) {
            return false;
        }

        if (!isNaN(this.maxDaysNumber) && this.maxDaysNumber > 0) {

            if (this.isCompleted && this.selectedDay) {
                const minDate = moment(this.selectedDay.mDate.format('YYYY-MM-DD'));
                minDate.subtract(this.maxDaysNumber, 'days');

                const maxDate = moment(this.selectedDay.mDate.format('YYYY-MM-DD'));
                maxDate.add(this.maxDaysNumber, 'days');
                if (date.isBetween(minDate.format('YYYY-MM-DD'), maxDate.format('YYYY-MM-DD')) === false) {
                    return false;
                }
            }
        }

        return true;
    }

    private showDateTime(startDate: moment.Moment, endDate: moment.Moment): void {
        if (this.time) {
            this.startDateTimeValue = startDate.format(this.dateFormat) + ' ' + this.startTime.hours + ':' + this.startTime.minutes;
            this.endDateTimeValue = endDate.format(this.dateFormat) + ' ' + this.endTime.hours + ':' + this.endTime.minutes;
            this.timeFormat = ' HH:mm';
        } else {
            this.startDateTimeValue = startDate.format(this.dateFormat);
            this.endDateTimeValue = endDate.format(this.dateFormat);
            this.timeFormat = '';
        }

    }

    private highlightSelectedDates(date: CalendarDate): void {
        let startDate = this.selectedDates[0].mDate;
        let endDate;

        if ((date.mDate.diff(startDate)) < 0) {
            endDate = startDate;
            startDate = date.mDate;
        } else {
            endDate = date.mDate;
        }

        this.selectedDates = [];

        this.weeks.forEach(
            (week) => week.forEach(
                day => {
                    if (this.isSelectedMonth(day.mDate)) {
                        if (startDate.diff(day.mDate, 'days') <= 0 && endDate.diff(day.mDate, 'days') >= 0) {
                            this.selectedDates.push(day);
                            day.selected = true;
                        }
                    }
                }
            )
        );

        const mStart = startDate.month() + startDate.year() * 12;
        const mEnd = endDate.month() + endDate.year() * 12;

        if (mEnd > mStart) {
            this.selectedDates = this.getSelectedDates([startDate.format(this.dateFormat), endDate.format(this.dateFormat)]);
        }

        this.showDateTime(startDate, endDate);
    }

    private verifyHours(): void {
        const hour = /[01]\d|2[0-3]/;

        if (!hour.test(this.startTime.hours)) {
            this.startTime.hours = '00';
        }

        if (!hour.test(this.endTime.hours)) {
            this.endTime.hours = '23';
        }
    }

    private verifyMinutes(): void {
        const minute = /[0-5]\d/;

        if (!minute.test(this.startTime.minutes)) {
            this.startTime.minutes = '00';
        }

        if (!minute.test(this.endTime.minutes)) {
            this.endTime.minutes = '59';
        }
    }

    private getSelectedDay(): CalendarDate {
        let selectedDay: CalendarDate;
        for (const week of this.weeks) {
            selectedDay = week.find(day => day.selected);
            if (selectedDay) {
                return selectedDay;
            }
        }
        return selectedDay;
    }


    public selectDate(date: CalendarDate): void {
        if (!this.isEnabled(date.mDate)) {
            return;
        }

        this.verifyHours();
        if (this.isCompleted) {
            this.highlightSelectedDates(date);
        } else {
            this.weeks.forEach((week) => week.forEach( day => day.selected = false));
            date.selected = true;
            this.selectedDates = [date, date];
            this.showDateTime(date.mDate, date.mDate);

            if (!isNaN(this.maxDaysNumber) && this.maxDaysNumber > 0) {
                this.selectedDay = this.getSelectedDay();
                this.generateCalendar();
            }
        }

        this.isCompleted = !this.isCompleted;
    }

    // actions from calendar

    public prevMonth(): void {
        this.currentDate = moment(this.currentDate).subtract(1, 'months');
        this.generateCalendar();
    }

    public nextMonth(): void {
        this.currentDate = moment(this.currentDate).add(1, 'months');
        this.generateCalendar();
    }

    public onDatepickerIconClick(event: any): void {
        event.stopPropagation();
        if (!this.disabled) {
            this.verifyHours();
            this.verifyMinutes();
            this.showDateTime(this.selectedDates[0].mDate, this.selectedDates[this.selectedDates.length - 1].mDate);
            this.selectDateEventEmitter.emit({ dateBegin: this.startDateTimeValue, dateEnd: this.endDateTimeValue });
            this.isActivated = !this.isActivated;
        }
    }

    public onClickOutside(event: MouseEvent) {
        event.stopPropagation();
        if (['pDateBegin', 'pDateEnd', 'spanDateBegin', 'spanDateBegin', 'date-text', 'week-date'].indexOf(event.target['id']) === -1) {
            this.verifyHours();
            this.verifyMinutes();
            this.showDateTime(this.selectedDates[0].mDate, this.selectedDates[this.selectedDates.length - 1].mDate);
            this.selectDateEventEmitter.emit({ dateBegin: this.startDateTimeValue, dateEnd: this.endDateTimeValue });
            this.isActivated = false;
        }
    }
}
