
import { AuthService } from 'app/service/auth.service';
import { Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatTableDataSource } from '@angular/material/table';
import { RerWeighingReceiptLine } from 'app/modules/rer/_model/rerweighingreceiptline.model';
import { RerCollectionByWeightService } from 'app/modules/rer/_service/rercollectionbyweight.service';
import { Observable, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-edit-weighing-receipt',
    templateUrl: './edit-weighing-receipt.component.html',
    styleUrls: ['./edit-weighing-receipt.component.scss'],
})

export class EditWeighingReceiptComponent implements OnInit, OnChanges {

    public total;
    public addresses;
    public formData;
    public disabledQuantity;
    public dateFormat: string;
    public proofSrc = '';
    public filename;
    public totalDifference;
    public operatedQuantity;
    public allocatedDifferences;
    public operatedQuantityCalculated;
    public displayedColumns: string[];
    public showMandatoryImageFormat: boolean;
    public currentFilterValue: string = null;
    public dataSource: MatTableDataSource<RerWeighingReceiptLine>;
    public rerWeighingReceiptLineList$: Observable<RerWeighingReceiptLine[]>;
    public showSpinner: boolean;
    private _subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<EditWeighingReceiptComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private authService: AuthService,
        private userPreferencesService: UserPreferencesService,
        public domSanitizer: DomSanitizer,
        private rerCollectionByWeightService: RerCollectionByWeightService
    ) { }

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit(): void {
        this.showSpinner = true;
        this.formData = new FormData();
        this.data.weighingReceipt.weighingReceiptLine = [];
        this.allocatedDifferences = 0;
        this.totalDifference = 0;
        this.allocatedDifferences = 0;
        this.operatedQuantity = 0;
        this.operatedQuantityCalculated = 0;
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.setDataTable();
        this.operatedQuantity = this.data.weighingReceipt.operated_quantity;
        this._subscriptions.push(this.rerCollectionByWeightService.getWeighingReceiptLineList(this.data.weighingReceipt.id).subscribe({
            next: (response) => {
                this.rerCollectionByWeightService.rerWeighingReceiptLineListSubject.next(response);
                this.showSpinner = false;
            },
            error: (error) => this.showSpinner = false
        }));
        this.rerWeighingReceiptLineList$ = this.rerCollectionByWeightService.rerWeighingReceiptLineList$;

        this._subscriptions.push(this.rerWeighingReceiptLineList$.subscribe(
            (weighingReceiptline: RerWeighingReceiptLine[]) => {
                this.dataSource = new MatTableDataSource(weighingReceiptline);
                this.addresses = weighingReceiptline.length;
                this.total = this.getTotalQuantity(weighingReceiptline);
                this.totalDifference = this.getTotalDifference();
                let total = 0;
                weighingReceiptline.forEach(
                    (data: RerWeighingReceiptLine) => {
                        data.original_quantity = data.computed_quantity + this.getCalculatedDifference(data.computed_quantity, this.totalDifference, this.total)
                        data.operated_quantity = data.operated_quantity ? data.operated_quantity : data.computed_quantity + this.getCalculatedDifference(data.computed_quantity, this.totalDifference, this.total)
                        total += data.operated_quantity;
                        if (total) {
                            this.operatedQuantityCalculated = total;
                        }
                        this.data.weighingReceipt.weighingReceiptLine.push(data);
                    }
                );
                this.allocatedDifferences = this.operatedQuantity - total;
                this.data.weighingReceipt.unallocated_quantity = this.allocatedDifferences;
                if (this.data.weighingReceipt.unallocated_quantity != 0) {
                    this.totalDifference = this.getTotalDifference()
                }
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            }
        ));
    }

    public onStampFileChanged(event: any): void {
        const file = event.target.files[0];

        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            let file: File = fileList[0];
            this.filename = file.name;
            this.formData.append('proof', file, file.name);
        }
        if (file.type && file.type.indexOf('image') !== -1 &&
            (file.type.indexOf('image/jpeg') >= 0 || file.type.indexOf('image/png') >= 0)) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.proofSrc = reader.result as string;
            };
        } else {
            this.showMandatoryImageFormat = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            const stampContent = changes.data.currentValue.proof;
            if (stampContent && stampContent.length > 0) {
                this.proofSrc = stampContent;
                if (stampContent.indexOf('data:image') === -1) {
                    this.proofSrc = 'data:image/jpeg;base64,' + atob(this.proofSrc);
                }
            }
        }
    }

    private setDataTable(): void {
        this.displayedColumns = [
            'rer_client_address_name',
            'computed_quantity',
            'calculated_difference',
            'allocated_difference',
            'allocated_on'
        ];
    }

    public getTotalQuantity(weighingReceiptline) {
        this.total = 0;
        weighingReceiptline.forEach(
            (data: RerWeighingReceiptLine) => {
                this.total += data.computed_quantity;
            }
        );
        return this.total;
    }

    public getTotalDifference() {
        if (this.operatedQuantityCalculated) {
            return this.operatedQuantityCalculated - this.total;
        }
        return this.operatedQuantity - this.total;
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public getCalculatedDifference(computedQuantity, totalDifference, total) {
        return ((totalDifference * (computedQuantity)) / total);
    }

    public onWeighingReceiptSave() {
        this.formData.append('weighingReceipt', JSON.stringify(this.data.weighingReceipt));
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
        this.rerCollectionByWeightService.rerWeighingReceiptLineListSubject.next([]);
        this.dialogRef.close(this.formData);
    }

    public changeOperatedQuantity(): void {
        this._subscriptions.push(this.rerWeighingReceiptLineList$.subscribe(
            (weighingReceiptline: RerWeighingReceiptLine[]) => {
                let total;
                total = 0;
                let calculated_difference;
                calculated_difference = 0;
                this.totalDifference = 0;
                weighingReceiptline.forEach(
                    (data: RerWeighingReceiptLine) => {
                        total += data.operated_quantity;
                        this.allocatedDifferences = this.data.weighingReceipt.operated_quantity - total;
                        calculated_difference = data.operated_quantity - data.computed_quantity;
                        this.totalDifference += calculated_difference;
                        this.data.weighingReceipt.unallocated_quantity = this.allocatedDifferences;
                    }
                );
            }
        ));
    }

    public changeQuantity(): void {
        this.showSpinner = true;
        let total = 0;
        this.totalDifference = this.data.weighingReceipt.operated_quantity - this.total;
        this._subscriptions.push(this.rerWeighingReceiptLineList$.subscribe(
            (weighingReceiptline: RerWeighingReceiptLine[]) => {
                weighingReceiptline.forEach(
                    (data: RerWeighingReceiptLine) => {
                        data.original_quantity = parseFloat((data.computed_quantity + this.getCalculatedDifference(data.computed_quantity, this.totalDifference, this.total)).toFixed(2))
                        data.operated_quantity = parseFloat((data.computed_quantity + this.getCalculatedDifference(data.computed_quantity, this.totalDifference, this.total)).toFixed(2))
                        total += data.operated_quantity;
                        this.allocatedDifferences = this.data.weighingReceipt.operated_quantity - total;
                        this.data.weighingReceipt.unallocated_quantity = this.allocatedDifferences;
                    }
                );
                this.showSpinner = false;
            }
        ));
    }

    public onCancel(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
        this.rerCollectionByWeightService.rerWeighingReceiptLineListSubject.next([]);
        this.dialogRef.close(false);
    }
}