import { AuthService } from './../../../service/auth.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rer-settings',
  templateUrl: './rer-settings.component.html',
  styleUrls: ['./rer-settings.component.scss']
})
export class RerSettingsComponent implements OnInit {

    constructor( private authService: AuthService ) { }
    public hasRerBinOwnershipAccess: boolean = false;

    ngOnInit() {
        this.hasRerBinOwnershipAccess = this.authService.userModulesOptions['rer']
                .find((item: string) => item === AuthService.USER_RIGHT_RER_BIN_OWNERSHIP_SETTINGS)
    }

}
