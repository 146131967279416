import { RerBinSetupImportInterface } from "../_interface/rerbinsetupimport.interface";

export class RerBinSetupImport implements RerBinSetupImportInterface {
    constructor(
        public type = null,
        public code = null,
        public addressCode = null,
        public name = null,
        public phone = null,
        public email = null,
        public county = null,
        public division = null,
        public city = null,
        public street = null,
        public streetNumber = null,
        public wasteCode = null,
        public binCode = null,
        public binOwnershipCode = null,
        public binSetupCode = null,
        public binSetupDate = null,
        public quantity = null,
    ) { }
}