<div class="search-content row mt-2">
    <div class="col-12 col-md-4 col-lg-2 pr-0">
        <div class="ng-select-wrapper w-100">
            <ng-select [items]="typeFilterList$ | async" placeholder="{{ 'Rer.selectType' | translate }}"
                appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                [(ngModel)]="selectedTypeList">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <ng-container *ngFor="let item of items; let i=index">
                        <div class="ng-value" *ngIf="i < 2 && item">
                            <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                            <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-container>
                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                        <span class="ml-2">{{ item }}</span>
                    </mat-checkbox>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2 pr-0 pl-2">
        <div class="ng-select-wrapper w-100">
            <ng-select [items]="cityFilterList$ | async" placeholder="{{ 'Rer.selectCity' | translate }}"
                appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                [(ngModel)]="selectedCityList">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <ng-container *ngFor="let item of items; let i=index">
                        <div class="ng-value" *ngIf="i < 2 && item">
                            <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                            <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-container>
                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                        <span class="ml-2">{{ item }}</span>
                    </mat-checkbox>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2 pr-0 pl-2">
        <div class="ng-select-wrapper w-100">
            <ng-select [items]="divisionFilterList$ | async" placeholder="{{ 'Rer.selectDivision' | translate }}"
                appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                [(ngModel)]="selectedDivisionList">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <ng-container *ngFor="let item of items; let i=index">
                        <div class="ng-value" *ngIf="i < 2 && item">
                            <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                            <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-container>
                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                        <span class="ml-2">{{ item }}</span>
                    </mat-checkbox>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2 pr-0 pl-2">
        <div class="ng-select-wrapper w-100">
            <ng-select [items]="streetFilterList$ | async" placeholder="{{ 'Rer.selectStreet' | translate }}"
                appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                [(ngModel)]="selectedStreetList">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <ng-container *ngFor="let item of items; let i=index">
                        <div class="ng-value" *ngIf="i < 2 && item">
                            <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                            <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-container>
                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                        <span class="ml-2">{{ item }}</span>
                    </mat-checkbox>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2 pr-0 pl-2">
        <div class="ng-select-wrapper w-100">
            <ng-select [items]="binOwnershipFilteredList$ | async" placeholder="{{ 'Rer.selectBinOwnership' | translate }}"
                appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                [(ngModel)]="selectedBinOwnsershipList">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <ng-container *ngFor="let item of items; let i=index">
                        <div class="ng-value" *ngIf="i < 2 && item">
                            <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                            <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                        </div>
                    </ng-container>
                    <div class="ng-value" *ngIf="items.length > 2">
                        <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                        <span class="ml-2">{{ item }}</span>
                    </mat-checkbox>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2 pr-0 pl-2">
        <div class="button-component utility-green-bg-color" style="width: fit-content" (click)="onLoadList()">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.loadList' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div class="search-content row">
    <div class="col-12">
        <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
            <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
            <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
                class="flex-grow-1" placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="client_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.client_type' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.client_type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_client_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.client_name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.rer_client_name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="division">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.division' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.division}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.city' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="ml-2">{{ element.city }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="street">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.street' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="ml-2">{{ element.street }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="street_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.streetNumber' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="ml-2">{{ element.street_number }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.wasteType' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onEditRerBin(element.id)" title="{{ 'edit' | translate }}"
            class="pointer">{{ element.waste_type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="bin_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.binType' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" (click)="onEditRerBin(element.id)"
            title="{{ 'edit' | translate }}" class="pointer">{{ element.bin_type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="bin_ownership">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.binOwnership' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" (click)="onEditRerBin(element.id)"
            title="{{ 'edit' | translate }}" class="pointer">{{ element.bin_ownership }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.status' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ 'Rer.objects.status.' + (element.status |
            rerStatusTransform:'object') | translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_rfid">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.rfid' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.rer_rfid }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.addEdit.objects.dataTable.description' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.description }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="latitude">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.coords' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">
            <a *ngIf="element.latitude && element.latitude != 0 && element.longitude && element.longitude != 0"
                href="https://www.google.com/maps/search/?api=1&query={{ element.latitude }},{{ element.longitude}}"
                target="_blank">
                {{ element.latitude + "" | shrinkfilter:8:'' }}, {{ element.longitude + "" | shrinkfilter:8:'' }}
            </a>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_bin_setup_document_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.rer_bin_setup_document_number' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" style="justify-content: center!important;">{{ element.rer_bin_setup_document_number }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_bin_setup_document_status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.rer_bin_setup_document_status' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element" style="justify-content: center!important;">
                <i *ngIf="element.rer_bin_setup_document_status === 1" class="icon-cts-selection-radio-on color-green-primary font-size-18" [title]="'Semnat'"></i>
                <i *ngIf="element.rer_bin_setup_document_status === 0" class="icon-cts-action-close color-red-primary font-size-18" [title]="'Nesemnat'"></i>
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="datetime_read">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.latest_datetime_read' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.datetime_read | offset:true | date:dateFormat + '
            HH:mm:ss' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="vehicle_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.vehicle_name' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.vehicle_name }}</mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let element; let rowIndex = index"
            style="justify-content: flex-end!important;">
            <i (click)="onEditClientObject(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
        </mat-cell>
    </ng-container> -->
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
