
import { AuthService } from 'app/service/auth.service';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerUnloadingArea } from 'app/modules/rer/_model/rerunloadingarea.model';
import { GeoCoords } from 'app/modules/model/map/geocoords.model';
import { environment } from 'environments/environment';
import { GoogleMapTileService } from 'app/modules/service/shared/googlemaptile.service';
import { GeofenceService } from 'app/modules/service/map/geofence.service';
import { AutocompleteAddressSuggestions } from 'app/modules/model/route/autocompleteaddresssuggestions.model';
import { GoogleapisService } from 'app/modules/service/shared/googleapis.service';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
    selector: 'app-add-edit-unloading-area',
    templateUrl: './add-edit-unloading-area.component.html',
    styleUrls: ['./add-edit-unloading-area.component.scss'],
})

export class AddEditUnloadingAreaComponent implements OnInit, AfterViewInit {
    public LATLNG_UNCHANGED = 1;
    public DEFAULT_RADIUS = 2000;
    public LATLNG_VALID = 2;
    public LATLNG_INVALID = 0;
    public addressGeozone: RerUnloadingArea;
    public showMandatory: boolean;
    public autocompleteAddressSuggestions: AutocompleteAddressSuggestions;
    private shapeAddressSubscription: Subscription;
    public addressFormated;

    private availableMapTypes: any[];
    private hasHasGoogleMapsRight = false;
    @ViewChild('gmap') gmapElement: any;
    private drawingManager: google.maps.drawing.DrawingManager;
    private polyOptions = { strokeWeight: 0, fillOpacity: 0.45, editable: true, draggable: true };
    public latLngPosition: string;
    public latLngPositionStatus: number;
    public map: google.maps.Map;
    private mapProp = {
        center: this.authService.getLatLngFromString(this.userPreferencesService.userPreferences.defaultMapCenter),
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, mapTypeIds: [], position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoom: 5,
        controlSize: 32,
        fullscreenControl: true,
        fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
    };

    constructor(
        public dialogRef: MatDialogRef<AddEditUnloadingAreaComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private authService: AuthService,
        private userPreferencesService: UserPreferencesService,
        private googleMapTileService: GoogleMapTileService,
        private geofenceService: GeofenceService,
        private googleapisService: GoogleapisService,
        private addressRef: ElementRef,
    ) { }

    ngOnInit(): void {
        if (this.data.unloadingArea.address) {
            this.addressFormated = this.data.unloadingArea.address.address_formatted;
        }
        this.addressGeozone = this.data.unloadingArea ? this.data.unloadingArea : new RerUnloadingArea();
        if (this.data.unloadingArea && this.data.unloadingArea.coords) {
            this.addressGeozone.coords = JSON.parse(this.data.unloadingArea.coords).map(
                (coords: GeoCoords) => new GeoCoords(+coords.lng, +coords.lat)
            );
        }
        if (this.data.unloadingArea && this.data.unloadingArea.precalc_values) {
            this.addressGeozone.precalc_values = JSON.parse(this.data.unloadingArea.precalc_values);
        }

        if (environment.isGoogleMapsAvailable) {
            this.availableMapTypes = [
                google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN,
                google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID,
                'openStreetsMap', 'mapBoxCustom'
            ];
            this.mapProp['streetViewControlOptions'] = {
                icon: '/assets/project/' + environment.projectName + '/images/favicon.png', position: google.maps.ControlPosition.RIGHT_TOP
            };
        } else {
            this.availableMapTypes = ['openStreetsMap'];
        }

        this.mapProp.mapTypeControlOptions.mapTypeIds = this.availableMapTypes;
        this.mapProp['streetViewControl'] = environment.isGoogleMapsAvailable && this.hasHasGoogleMapsRight ? true : false;

        this.showMandatory = false;

        this.autocompleteAddressSuggestions = new AutocompleteAddressSuggestions;
        this.autocompleteAddressSuggestions.group = [{ name: 'unknown', suggestions: [] }];
    }

    ngAfterViewInit(): void {
        this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
        this.drawShape();
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON]
            },
            markerOptions: { draggable: true },
            polylineOptions: { editable: true, draggable: true },
            rectangleOptions: this.polyOptions,
            circleOptions: this.polyOptions,
            polygonOptions: this.polyOptions,
            map: this.map
        });

        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: google.maps.drawing.OverlayCompleteEvent) => {
            this.deleteShape();
            this.resetGeofence();
            this.addressGeozone.shape = event.overlay;
            if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
                this.addressGeozone.type = 'circle';
                this.addressGeozone.radius = this.addressGeozone.shape.radius;
                this.addressGeozone.shape.addListener('radius_changed', () => this.onCreatedShapeRadiusChange(this.addressGeozone.shape));
                this.addressGeozone.center_latitude = this.addressGeozone.shape.center.lat();
                this.addressGeozone.center_longitude = this.addressGeozone.shape.center.lng();
            } else {
                this.addressGeozone.type = 'path';
                const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.addressGeozone.shape);
                this.addressGeozone.center_latitude = polyCenter.lat();
                this.addressGeozone.center_longitude = polyCenter.lng();
            }
            this.latLngPosition = this.addressGeozone.center_latitude + ',' + this.addressGeozone.center_longitude;
            this.onChangePosition();
        });

        this.shapeAddressSetup();

        // Create Google TileLayers
        const openStreetsMap = this.googleMapTileService.getOSMTileLayer();
        this.map.mapTypes.set('openStreetsMap', openStreetsMap);
        const mapBoxCustom = this.googleMapTileService.getMapBoxCustomTileLayer();
        this.map.mapTypes.set('mapBoxCustom', mapBoxCustom);

        this.map.setMapTypeId(this.availableMapTypes.indexOf(this.userPreferencesService.userPreferences.map.mapTypeId) >= 0
            ? this.userPreferencesService.userPreferences.map.mapTypeId : 'openStreetsMap');
    }

    private deleteShape(): void {
        if (this.addressGeozone.shape) {
            this.addressGeozone.shape.setVisible(false);
            this.addressGeozone.shape.setDraggable(false);
            this.addressGeozone.shape.setEditable(false);
        }
    }

    private drawShape() {
        if (this.addressGeozone.shape) {
            this.addressGeozone.shape.setVisible(true);
            this.addressGeozone.shape.setDraggable(true);
            this.addressGeozone.shape.setEditable(true);
        } else {
            switch (this.addressGeozone.type) {
                case 'circle':
                    this.createShapeCircle();
                    break;
                case 'path':
                    this.createShapePlygon();
                    if (!this.addressGeozone.center_latitude || !this.addressGeozone.center_longitude) {
                        const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.addressGeozone.shape);
                        this.addressGeozone.center_latitude = polyCenter.lat();
                        this.addressGeozone.center_longitude = polyCenter.lng();
                    }
                    break;
            }
        }
    }

    private resetGeofence(): void {
        this.addressGeozone.center_latitude = null;
        this.addressGeozone.center_longitude = null;
        this.latLngPosition = '';
        this.latLngPositionStatus = this.LATLNG_UNCHANGED;
        this.addressGeozone.radius = null;
        this.addressGeozone.coords = null;
        this.addressGeozone.address = { address_formatted: '' };
    }

    private createShapeCircle(): void {
        this.addressGeozone.shape = this.geofenceService.createGoogleMapsCircle(this.map, this.addressGeozone, this.polyOptions, true);
        this.addressGeozone.shape.addListener('radius_changed', () => this.onCreatedShapeRadiusChange(this.addressGeozone.shape));
        this.fitToBounds();
    }

    private createShapePlygon(): void {
        this.addressGeozone.shape = this.geofenceService.createGoogleMapsPolygon(this.map, this.addressGeozone, true, true);
        this.fitToBounds();
    }

    private fitToBounds(): void {

        const bounds = new google.maps.LatLngBounds();
        if (!this.addressGeozone.type) {
            bounds.extend(this.authService.getLatLngFromString(this.userPreferencesService.userPreferences.defaultMapCenter));
        }
        if (this.addressGeozone.type === 'circle') {
            bounds.extend(new google.maps.LatLng(Number(this.addressGeozone.center_latitude), Number(this.addressGeozone.center_longitude)));
            this.map.fitBounds(bounds);
            this.map.setZoom(10);
        } else {
            if (this.addressGeozone.coords) {
                this.addressGeozone.coords.forEach(
                    (item: GeoCoords) => bounds.extend(new google.maps.LatLng(Number(item['lat']), Number(item['lng'])))
                );
            }
            this.map.fitBounds(bounds);
        }
    }

    private onCreatedShapeRadiusChange(shape: any): void {
        this.addressGeozone.radius = shape.getRadius();
    }

    public onGeozoneSave() {
        if (!this.data.unloadingArea) {
            this.data.unloadingArea = new RerUnloadingArea;
        }
        this.showMandatory = this.data.unloadingArea.name ? false : true;
        if (this.addressGeozone.type == 'path') {
            const coords = this.addressGeozone.shape.getPath().getArray().map((latLng: any) => new GeoCoords(latLng.lng(), latLng.lat()));
            this.data.unloadingArea.coords = coords;
            this.data.unloadingArea.coords = JSON.stringify(this.data.unloadingArea.coords);

            const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.addressGeozone.shape);
            this.data.unloadingArea.center_latitude = polyCenter.lat();
            this.data.unloadingArea.center_longitude = polyCenter.lng();
            this.data.unloadingArea.radius = null;
        } else {
            this.data.unloadingArea.radius = this.addressGeozone.radius;
            this.data.unloadingArea.coords = null;
            this.data.unloadingArea.precalc_values = null;
        }
        this.data.unloadingArea.type = this.addressGeozone.type;
        this.data.unloadingArea.shape = null;
        this.data.unloadingArea.active = this.data.unloadingArea.active == 0 ? 0 : 1;
        this.findAddressbyLatlng(this.data.unloadingArea.center_latitude.toString(), this.data.unloadingArea.center_longitude.toString());
        if (!this.showMandatory) {
            this.dialogRef.close(this.data.unloadingArea);
        }
    }

    public onCancel() {
        this.dialogRef.close(false);
    }

    public onClickAddressAutocomplete(suggestions: any): void {
        this.data.unloadingArea.center_latitude = suggestions.geometry.location.lat;
        this.data.unloadingArea.center_longitude = suggestions.geometry.location.lng;
        this.latLngPosition = this.data.unloadingArea.center_latitude + ',' + this.data.unloadingArea.center_longitude;
        this.latLngPositionStatus = this.LATLNG_UNCHANGED;
        this.data.unloadingArea.radius = this.DEFAULT_RADIUS;
        this.data.unloadingArea.type = 'circle';
        this.autocompleteAddressSuggestions = new AutocompleteAddressSuggestions;
        this.autocompleteAddressSuggestions.group = [{ name: 'unknown', suggestions: [] }];
        this.deleteShape();
        this.createShapeCircle();
        this.onChangePosition();
    }

    private findAddressbyLatlng(lat: string, lng: string): void {
        this.googleapisService.reverseGeocode(lat, lng).subscribe(
            response => {
                this.addressGeozone.address = response;
                this.data.unloadingArea.address = response;
            },
        );
    }

    public onChangePosition(): void {
        const latLngRegExp = new RegExp(/^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/);
        if (latLngRegExp.test(this.latLngPosition)) {
            this.latLngPositionStatus = this.LATLNG_VALID;
            this.manageLatLngPosition();
        } else {
            this.latLngPositionStatus = this.LATLNG_INVALID;
        }

    }

    public manageLatLngPosition(): void {
        if (this.latLngPositionStatus === this.LATLNG_VALID) {
            const latLng = this.latLngPosition.split(',');
            this.googleapisService.reverseGeocode(latLng[0].trim(), latLng[1].trim()).subscribe(
                (address) => {
                    this.addressGeozone.address = address;
                    this.data.unloadingArea.address = address;
                    this.addressFormated = address.address_formatted;
                    if (!this.addressGeozone.radius) {
                        this.addressGeozone.radius = this.DEFAULT_RADIUS;
                    }
                    this.latLngPositionStatus = this.LATLNG_UNCHANGED;
                    this.addressGeozone.center_latitude = Number(latLng[0].trim());
                    this.addressGeozone.center_longitude = Number(latLng[1].trim());
                },
            );
        }
    }

    private shapeAddressSetup(): void {
        if (this.shapeAddressSubscription) {
            this.shapeAddressSubscription.unsubscribe();
        }
        const shapeAddress = this.addressRef.nativeElement.querySelectorAll('.shapeAddress');
        const shapeAddressObservableInput = fromEvent(shapeAddress, 'input')
            .pipe(map(event => event['target'].value)).pipe(debounceTime(500), distinctUntilChanged());

        this.shapeAddressSubscription = shapeAddressObservableInput.subscribe(
            value => {
                if (value.length < 3) {
                    return;
                }
                this.autocompleteAddressSuggestions.group = [{ name: 'unknown', suggestions: [] }];

                this.googleapisService.nominatimGeocode(value).subscribe(
                    (response) => {
                        const results = response.results.slice(0, 5);
                        results.forEach(element => {
                            if (element.length > 70) {
                                this.autocompleteAddressSuggestions.group[0].suggestions.push(element.substring(0, 68) + '...');
                            } else {
                                this.autocompleteAddressSuggestions.group[0].suggestions.push(element);
                            }
                        });
                    }
                );
            }
        );
    }
}
