import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';

import { RerArea } from '../../_model/rerarea.model';
import { AddAreaComponent } from './add-area/add-area.component';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
@Component({
    selector: 'app-area',
    templateUrl: './area.component.html',
    styleUrls: ['./area.component.scss']
})

export class AreaComponent implements OnInit {

    public areaList$: Observable<RerArea[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean = false;

    constructor(private rerService: RerService, 
        public dialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.displayedColumns = ['name', 'value', 'actions'];
        this.areaList$ = this.rerService.areaList$;

        this.areaList$.subscribe(
            (areas: RerArea[]) => {
                this.dataSource = new MatTableDataSource(areas);
            }
        );
    }

    public addEditArea(areaId) {
        let area = this.rerService.getAreaById(areaId);
        if (!area) {
            area = new RerArea();
            area.id = areaId;
        }

        const dialogRef = this.dialog.open(
            AddAreaComponent,
            {
                width: '500px',
                data: { area: area }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.rerService.saveArea(response).subscribe(
                        () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerSettings.savedSuccefuly")),
                        () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.saveFailed"))
                    )
                }
            }
        );
    }

    public deleteArea(id) {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.rerService
                        .getTranslationFor('Rer.rerSettings.confirmDeleteActionArea')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const aRea = this.rerService.getAreaById(id);
                    this.rerService.deleteArea(aRea.id).subscribe(
                        () => { this.showSpinner = false; },
                        (error: HttpErrorResponse) => {
                            let errorMessage = 'errorNotification';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rerSettings.errorAreaInUse';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage), '600px');
                        }
                    );
                }
            }
        );
    }
}