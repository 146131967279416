import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../../_service/rer.service';

import { NotificationComponent } from 'app/shared/notification/notification.component';
import { RerClientImportAddress } from '../../../_model/rerclientimportaddress.model';
import { RerBinSetupImport } from 'app/modules/rer/_model/rerbinsetupimport.model';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';

@Component({
    selector: 'app-bin-setup-final-import',
    templateUrl: './bin-setup-final-import.component.html',
    styleUrls: ['./bin-setup-final-import.component.scss']
})
export class BinSetupFinalImportComponent implements AfterViewInit {
    public dataSource: MatTableDataSource<any>;
    public displayedColumns = [
        'type',
        'code',
        'name',
        'phone',
        'email',
        'addressCode',
        'division',
        'county',
        'city',
        'street',
        'streetNumber',
        'wasteCode',
        'binCode',
        'binOwnershipCode',
        'binSetupCode',
        'binSetupDate',
        'quantity'
    ];

    @Input() set dataForImport(value: RerBinSetupImport[]) {
        this.dataSource = new MatTableDataSource(value);
        this.setDataTablePaginatorSort();
    }
    @Output() cancelImportEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() importDataEventEmitter: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    constructor(private rerService: RerService,
                public matDialog: MatDialog,
                private route: Router,
                private translationService: TranslateService,)
    { }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private prepareDataForRequest(data: RerBinSetupImport[]): any {
        let resultData: any = {};
        for (let i = 0; i< data.length; i++) {
            if(data[i].quantity <= 0) {
                return false;
            }

            resultData[i] = {
                type: data[i].type,
                code: data[i].code,
                name: data[i].name,
                phone: data[i].phone,
                email: data[i].email.replace('"',''),
                bin_setup_date: data[i].binSetupDate,
                bin_setup_code: data[i].binSetupCode,
                county: data[i].county,
                rer_client_address: [
                    new RerClientImportAddress(
                        data[i].addressCode, data[i].binSetupDate, data[i].name, data[i].division, data[i].city, data[i].street, data[i].streetNumber, [], [])
                ]
            };
            if (data[i].wasteCode && data[i].binCode) {
                resultData[i].rer_client_address[0].rer_bins.push(
                    { rer_waste_type_code: data[i].wasteCode, rer_bin_type_code: data[i].binCode, rer_bin_ownership_code: data[i].binOwnershipCode, quantity: data[i].quantity });
            }
        }

        return resultData;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onDoImport(): void {
        const requestData = this.prepareDataForRequest(this.dataSource.data);
        if(!requestData) {
            this.importDataEventEmitter.next(false);
            this.matDialog.open(
                NotificationComponent,
                {
                    panelClass: 'custom-dialog-container',
                    maxWidth: '480px',
                    data: {
                        headermessage: this.translationService.instant('error'),
                        message: this.translationService.instant('multipleImportDataError'),
                        success: 0
                    }
                }
            );
        } else {
            this.importDataEventEmitter.next(true);
            this.rerService.importRerBinSetupData({ importData: requestData }).subscribe(
                () => {
                    this.matDialog.open(
                        NotificationComponent,
                        {
                            maxWidth: '510px',
                            panelClass: 'custom-dialog-container',
                            data: {
                                success: 1,
                                headermessage: this.translationService.instant('Rer.dataImportRer.importSuccessHeader'),
                                message: this.translationService.instant('Rer.dataImportRer.importSuccessMessage')
                            }
                        }
                    );
                    this.route.navigate(['rer/management', { outlets: { rercontent: ['rer-client'] } }]);
                },
                (error: HttpErrorResponse) => {
                    let errorMessage = '';
                    switch (error.status) {
                        case  StatusCodes.FAILED_DEPENDENCY:
                            errorMessage = 'Rer.dataImportRer.binSetupImport.binSetupAlreadyExist';
                            break;
                        case  StatusCodes.NOT_ACCEPTABLE:
                            errorMessage = 'Rer.dataImportRer.binSetupImport.binSetupBinInformationsMissing';
                            break;
                        default :
                            errorMessage = 'errorNotification';
                    }
                    this.importDataEventEmitter.next(false);
                    this.matDialog.open(
                        NotificationComponent,
                        {
                            panelClass: 'custom-dialog-container',
                            maxWidth: '480px',
                            data: {
                                headermessage: this.translationService.instant('error'),
                                message: this.translationService.instant(errorMessage),
                                success: 0
                            }
                        }
                    );
                }
            );
        }
    }
}
