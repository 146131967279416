import { DsWebSocketWorkerInterface } from '../../map/interface/dswebsocketworker.interface';

import { interval } from 'rxjs';

import { DsData } from './dsdata.model';
import { MapService } from '../../service/shared/map.service';

const CHECK_MESSAGE_INTERVAL = 1000;

export class DsWebSocketWorker implements DsWebSocketWorkerInterface {

    public dsData: DsData[] = [];
    public wssConnection: WebSocket;

    constructor(
        public wssurl = null,
        public scope = null,
        public token = null,
        public deviceIds = [],
        public mapService: MapService
    ) {
        this.wssCreate();
    }

    public wssCreate(): void {
        this.wssConnection = new WebSocket('wss://' + this.wssurl + '/wss');
        this.wssConnection.onopen = event => this.onConnectMessage(event);
        this.wssConnection.onmessage = event => this.onMessage(event);
        this.wssConnection.onclose = event => this.onClose(event);
        this.wssConnection.onerror = event => this.onError(event);
        this.deviceIds.forEach(device => this.dsData[device] = new DsData);
    }

    private validateAndSendPosition(deviceId: number): void {
        const position = this.dsData[deviceId].pos;
        if (typeof position === 'object' && Object.keys(position).length > 0) {

            //  send position to the map
            if (position.valid && position.latitude !== undefined && position.longitude !== undefined) {

                this.mapService.sendPositionToMapSubject.next(this.dsData[deviceId].pos);
            }

            //  reset position
            this.dsData[deviceId].pos = null;
            if (this.dsData[deviceId].obs) {

                this.dsData[deviceId].obs.unsubscribe();
            }
        }
    }

    private onMessage(event): void {

        const dataPosition = JSON.parse(event.data);
        if (!isNaN(dataPosition.deviceid)) {
            if (this.dsData[dataPosition.deviceid].pos === null) {
                //  start timer
                this.dsData[dataPosition.deviceid].obs = interval(CHECK_MESSAGE_INTERVAL).subscribe(
                    () => this.validateAndSendPosition(dataPosition.deviceid)
                );
                //  add message
                this.dsData[dataPosition.deviceid].pos = Object.assign({}, dataPosition);
            } else {
                if (this.dsData[dataPosition.deviceid].pos.fixtime === dataPosition.fixtime) {

                    //  concatenate message
                    Object.keys(dataPosition).forEach(key => this.dsData[dataPosition.deviceid].pos[key] = dataPosition[key]);
                } else {

                    this.dsData[dataPosition.deviceid].pos = Object.assign({}, dataPosition);
                    this.dsData[dataPosition.deviceid].obs.unsubscribe();

                    this.dsData[dataPosition.deviceid].obs = interval(CHECK_MESSAGE_INTERVAL).subscribe(
                        () => this.validateAndSendPosition(dataPosition.deviceid)
                    );
                }
            }
        }
    }

    private onConnectMessage(event): void {
        const msg = {
            scope: this.scope,
            token: this.token,
            device_ids: this.deviceIds
        };

        this.wssConnection.send(JSON.stringify(msg));

    }

    private onClose(event): void { }

    private onError(event): void { }
}
