import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';

import { RerClient } from '../../_model/rerclient.model';

import { NotificationComponent } from 'app/shared/notification/notification.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { RecoverDeletedClientDialogComponent } from '../recoverdeletecclientdialog/recoverdeletedclientdialog.component';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit {

    public clientsList$: Observable<RerClient[]>;
    public cityFilterList$: Observable<string[]>;
    public divisionFilterList$: Observable<string[]>;
    public typeFilterList$: Observable<string[]>;
    public streetFilterList$: Observable<string[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public selectedCityList: string[] = [];
    public selectedDivisionList: string[] = [];
    public selectedTypeList: string[] = [];
    public selectedStreetList: string[] = [];
    public showSpinner: boolean;
    public currentFilterValue: string = null;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Input() openTabsNumber: number;

    @Output() editClientEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() deleteClientEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() loadClientListEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(private rerService: RerService,
        public dialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.clientsList$ = this.rerService.clientList$;
        this.cityFilterList$ = this.rerService.cityFilterList$;
        this.divisionFilterList$ = this.rerService.devisionFilterList$;
        this.typeFilterList$ = this.rerService.typeFilterList$;
        this.streetFilterList$ = this.rerService.streetFilterList$;
        this.displayedColumns = ['type', 'code', 'name', 'division', 'city', 'street', 'street_number', 'status',
            'phone', 'object_types_at_default_address', 'actions'];
        this.clientsList$.subscribe(
            (clients: RerClient[]) => {
                this.dataSource = new MatTableDataSource(clients);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onEditClient(id: number): void {
        this.editClientEventEmitter.emit(id);
    }

    public onDeleteClient(id: number): void {
        this.deleteClientEventEmitter.emit(id);
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }

    private getRerClientList(): void {
        this.showSpinner = true;
        this.rerService.getRerClients(
            {
                cityList: this.selectedCityList.join(','),
                divisionList: this.selectedDivisionList.join(','),
                typeList: this.selectedTypeList.join(','),
                streetList: this.selectedStreetList.join(','),
                activeOnly: false
            }
        ).subscribe(
            () => this.showSpinner = false,
            () => this.showNotification()
        );
    }

    public onLoadList(): void {
        if (this.openTabsNumber) {
            const dialogRef = this.dialog.open(
                ConfirmActionComponent,
                {
                    width: '450px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        headerMessage: this.translationService.instant('Rer.confirmCloseTabs.title'),
                        contentMessage: this.translationService.instant('Rer.confirmCloseTabs.msg')
                    }
                }
            );
            dialogRef.afterClosed().subscribe(
                (response) => {
                    if (response) {
                        this.loadClientListEventEmitter.emit();
                        this.getRerClientList();
                    }
                }
            );
        } else {
            this.getRerClientList();
        }
    }

    public onRecoverDeleted() {
        const dialogRef = this.dialog.open(
            RecoverDeletedClientDialogComponent,
            {
                data: {
                    message: this.translationService.instant('Rer.clients.list.recoverDeletedClient'),
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (dResponse) => {
                if (dResponse) {
                    this.showSpinner = false;
                    this.rerService.recoverDeletedClient(dResponse).subscribe(
                        () => {
                            this.showSpinner = false;
                        },
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            }
        );
    }
}
