<div class="d-flex flex-row align-items-stretch h-100">
	<div id="sidebar" class="tms" [ngClass]="{activate: !sidebarActive}">
		<app-leftsidebar></app-leftsidebar>
		<div>
			<div id="closeSideBar" (click)="onSideBar()" class="pointer">
				<div class="iconSideBarContainer p-2 bgcolor-blue d-flex justify-content-center align-items-center">
					<i [ngClass]="sidebarActive ? 'icon-cts-chevron-left': 'icon-cts-chevron-right'"></i>
				</div>
			</div>
		</div>
	</div>
	<div id="rightside" class="position-relative">
		<router-outlet name="rercontent"></router-outlet>
	</div>
</div>