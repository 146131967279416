import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RerService } from '../../_service/rer.service';
import { RerCity } from '../models/rer-city.model';
import { AddEditStreetComponent } from '../add-edit-street/add-edit-street.component';
import { RerStreet } from '../models/rer-street.model';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
  selector: 'app-street-list',
  templateUrl: './street-list.component.html',
  styleUrls: ['./street-list.component.scss']
})
export class StreetListComponent implements OnInit {

  @Input() city: RerCity;

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public showSpinner: boolean = false;
  public currentFilterValue: string = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private actionDialog: MatDialog, public dialog: MatDialog, private translationService: TranslateService, private rerService: RerService) { }

  ngOnInit() {
      this.displayedColumns = ['name', 'actions'];
      this.dataSource = new MatTableDataSource(this.city.street);
  }

  ngAfterViewInit(): void {
      this.setDataTablePaginatorSort();
  }

  private setDataTablePaginatorSort(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
  }

  public onAddEditStreet(data: RerStreet | undefined = undefined) {
    const street = data ? data : new RerStreet(undefined, this.city.id);
    const dialogRef = this.actionDialog.open( AddEditStreetComponent, { width: '90%', height: '90%', panelClass: 'custom-dialog-container', data: { street } });
    dialogRef.afterClosed().subscribe(
      (street: RerStreet | null) => {
        if (street) {
          this.showSpinner = true;
          street.geoZone = null;
          this.rerService.saveRerStreetGeozoneStreet(street).subscribe({
            next:(response: RerStreet) => {
              if (!street.id) {
                this.city.street = [...this.city.street, response];
              } else {
                const index = this.city.street.findIndex((el: RerStreet) => el.id === response.id);
                if (index !== -1) {
                  this.city.street[index] = {...response};
                }
              }
              this.dataSource = new MatTableDataSource(this.city.street);
              this.showSpinner = false;
            },
            error:() => {
              this.showSpinner = false;
              this.actionDialog.open(
                NotificationComponent,
                {
                    maxWidth: '800px', panelClass: 'custom-dialog-container',
                    data: { success: 0, headermessage: this.translationService.instant('error'), message: this.translationService.instant('generalError') }
                }
              );
            }
          });
        }
      }
    );
  } 

  public onDeleteStreet(id: number): void {
    const dialogRef = this.actionDialog.open(
        ConfirmActionComponent,
        {
            maxWidth: '800px',
            panelClass: 'custom-dialog-container',
            data: {
                headerMessage: this.translationService.instant('delete'),
                contentMessage: this.translationService.instant('Rer.streetGeozones.confirmDeleteStreet')
            }
        }
    );

    dialogRef.afterClosed().subscribe(
        (response: boolean) => {
            if (response) {                    
                this.showSpinner = true;
                this.rerService.deleteRerStreetGeozoneStreet(id).subscribe({
                    next:() => {
                      this.showSpinner = false;
                      this.city.street = this.city.street.filter((el: any) => el.id !== id);
                      this.dataSource = new MatTableDataSource(this.city.street);
                    },
                    error:() => {
                        this.showSpinner = false;
                        this.actionDialog.open(
                            NotificationComponent,
                            {
                                maxWidth: '800px', panelClass: 'custom-dialog-container',
                                data: {
                                    success: 0,
                                    headermessage: this.translationService.instant('error'),
                                    message: this.translationService.instant('generalError')
                                }
                            }
                        );
                    }
                });
            }
        }
    );
}
}
