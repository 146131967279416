<div class="search-content d-flex align-items-center mt-1">
    <div class="datatTableFilter p-2 d-flex align-items-center w-50 height-45">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerVerbalProcess.list.quickSearch' | translate }}">
    </div>
    <div class="ng-select-wrapper ml-2">
        <ng-select [items]="cityFilterList$ | async" placeholder="{{ 'Rer.selectCity' | translate }}"
            appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false" [(ngModel)]="selectedCityList">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <ng-container *ngFor="let item of items; let i=index">
                    <div class="ng-value" *ngIf="i < 2 && item">
                        <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                </ng-container>
                <div class="ng-value" *ngIf="items.length > 2">
                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <mat-checkbox [ngModel]="item$.selected">
                    <span class="ml-2">{{ item }}</span>
                </mat-checkbox>
            </ng-template>
        </ng-select>
    </div>
    <div class="ng-select-wrapper ml-2">
        <ng-select [items]="streetFilterList$ | async" placeholder="{{ 'Rer.selectStreet' | translate }}"
            appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
            [(ngModel)]="selectedStreetList">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <ng-container *ngFor="let item of items; let i=index">
                    <div class="ng-value" *ngIf="i < 2 && item">
                        <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                            aria-hidden="true">×</span>
                    </div>
                </ng-container>
                <div class="ng-value" *ngIf="items.length > 2">
                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                    <span class="ml-2">{{ item }}</span>
                </mat-checkbox>
            </ng-template>
        </ng-select>
    </div>
    <div class="ng-select-wrapper ml-2">
        <ng-select [items]="docFilterSigned" class="flex-grow-1" style="width: 150px;" 
            placeholder="{{ 'Rer.rerVerbalProcess.filter.title' | translate }}"
            (change)="onFilterChange($event)" appendTo="body" [multiple]="false" [clearable]="false"
            [(ngModel)]="filterSelected" bindLabel="labeli18" bindValue="id" [closeOnSelect]="true">
            <ng-template ng-multi-label-tmp let-items="items">
                <ng-option *ngFor="let option of items">{{ ('Rer.rerVerbalProcess.filter.' + option.label) | translate }}</ng-option>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="ml-2">{{ 'Rer.rerVerbalProcess.filter.' + item.label | translate }}</span>
                    <span *ngIf="item.count !== null">{{ item.count }}</span>
                </div>
            </ng-template>
        </ng-select>
    </div>
    <div class="button-component utility-green-bg-color ml-2 flex-shrink-0" (click)="onLoadList()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'Rer.loadList' | translate }}</span>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="number">
        <mat-header-cell *matHeaderCellDef mat-sort-header st>
            {{ 'Rer.rerVerbalProcess.list.number' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element"
            >{{ element.number }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_client_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.rer_client_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.rer_client_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="client_address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.client_address' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.client_address | translate }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="count_bins">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.count_bins' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.count_bins }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bin_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.bin_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.bin_type }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.waste_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.waste_type }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="responsible_person">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.responsible_person' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{ element.responsible_person ||
            '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="placement_address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.placement_address' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.placement_address || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.date' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.date | offset: true | date: dateFormat + ' HH:mm:ss' || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="signed_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.signed_at' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
                element.signed_at | offset: true | date: dateFormat + ' HH:mm:ss'|| '-' }}
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="username">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.username' | translate}}
        </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
           element.username || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="deleted_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.deleted_at' | translate}}
        </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
           element.deleted_at | offset: true | date: dateFormat + ' HH:mm:ss' || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="deleted_by">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerVerbalProcess.list.deleted_by' | translate}}
        </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
           element.deleted_by || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let rowIndex = index" style="justify-content: left!important;">
            <i *ngIf="hasDeleteRight && !element.deleted_at" (click)="deleteBinSetupDocument(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
            <i *ngIf="element.signed_at" (click)="downloadBinSetupDocument(element.id)" title="{{ 'download' | translate }}"
                class="icon-tmss-list-action-download font-size-14 pointer ml-2"></i>
            <fa-icon [icon]="faEnvelopeOpen"  *ngIf="element.signed_at" (click)="sendByEmail(element.id)" title="{{ 'email' | translate }}"
                class="pointer ml-2"></fa-icon>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
