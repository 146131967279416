import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RerService } from '../../_service/rer.service';
import { RerCity } from '../models/rer-city.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-street-geozones',
  templateUrl: './street-geozones.component.html',
  styleUrls: ['./street-geozones.component.scss']
})
export class StreetGeozonesComponent implements OnInit {

  public cityTabs: any[] = [];
  public selectedTabIndex = 0;
  public showSpinner = false;

  constructor(private rerService: RerService, public actionDialog: MatDialog, private translationService: TranslateService) { }

  ngOnInit(): void {
    this.rerService.getRerStreetGeozoneList();
  }

  public onCloseTab(tabIndex: number): void {
    this.cityTabs.splice(tabIndex, 1);
    if (this.selectedTabIndex === tabIndex - 1) {
      this.selectedTabIndex = 0;
    }
  }

  public closeAllTabs(): void {
    this.cityTabs = [];
  }

  public addNewCity() {
    const newCity = this.cityTabs.find(cl => !cl.id);
    if (!newCity) {
        this.cityTabs.unshift(new RerCity());
    }

    setTimeout(()=> {this.selectedTabIndex = 1 }, 10);
  }

  public onEditCity(id: any) {
    const index = this.cityTabs.findIndex((el: RerCity) => el.id === id);
    if (index != -1) {
      this.selectedTabIndex = index + 1;
      return;
    } else {
      const city = this.rerService.getStreetGeozoneCityById(id);
      if (city) {
        this.cityTabs.unshift(city);
        setTimeout(()=> {this.selectedTabIndex = 1 }, 10);
      }
    }
  }
}
