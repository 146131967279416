<div class="datePickerContainer" [ngStyle]="{'z-index': zIndex}">
    <mat-form-field class="w-100" [ngStyle]="{'max-height': maxHeight }" [ngClass]="{'disabled': disabled }">
        <mat-label>{{ label }}</mat-label>
        <input matInput
            value="{{ startDateTimeValue | date:(includeTime ? dateFormatShow + ' HH:mm:ss' : dateFormatShow) }}"
            [disabled]="disabled" [readonly]="true" id="pDate" autocomplete="off"
            (click)="onDatepickerIconClick($event)">
        <fa-icon [icon]="faCalendarAlt" [ngClass]="disabled ? 'color-grey': 'pointer'" matSuffix id="spanDate"
            (click)="onDatepickerIconClick($event)"></fa-icon>
    </mat-form-field>
    <div (appClickOutside)="onClickOutside($event)" class="calendar"
        [ngClass]="{'double-border-left-blue' : doubleBorderLeft, 'double-border-top-blue' : doubleBorderTop}"
        *ngIf="isActivated">
        <div class="calendar-navs">
            <div class="month-nav">
                <div (click)="prevMonth()"><span class="ml-4 icon-cts-chevron-left"></span></div>
                <span class="p4">{{ moment.months()[this.currentDate.month()] }} {{ currentDate.format('YYYY') }}</span>
                <div (click)="nextMonth()"><span class="mr-4 icon-cts-chevron-right"></span></div>
            </div>
        </div>
        <div class="month-grid d-flex-row">
            <div class="day-names justify-content-center">
                <div *ngFor="let name of dayNames" class="day-name p9">
                    {{ name }}
                </div>
            </div>
            <div class="weeks ml-2 mr-2 pb-2">
                <div *ngFor="let week of weeks" class="week justify-content-center">
                    <ng-container *ngFor="let day of week">
                        <ng-container *ngIf="!isEnabled(day.mDate)">
                            <ng-template [ngIf]="!isSelectedMonth(day.mDate)" [ngIfElse]="strike">
                                <div class="week-date disabled" id="week-date">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                            <ng-template #strike>
                                <div class="week-date strike" id="week-date">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="isEnabled(day.mDate)">
                            <ng-template [ngIf]="isSelectedMonth(day.mDate)" [ngIfElse]="notSelectedMonth">
                                <div class="week-date enabled" id="week-date" (click)="selectDate(day)"
                                    [ngClass]="{ today: day.today, selected: day.selected }">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                            <ng-template #notSelectedMonth>
                                <div class="week-date disabled" id="week-date">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="time" class="row time-container pl-4 pr-4">
            <div class="col-md-12">
                <span class="time-text">{{ 'startTime' | translate }}</span>
                <div class="d-flex justify-content-start align-items-center hhmm-container pl-2 pt-2 pb-1">
                    <input class="text-center" type="text" id="startDateH" [(ngModel)]="startTime.hours"
                        name="startDateH" (keyup)="selectTime()" maxlength="2">
                    <span class="ml-1 mr-1 font-size-14">:</span>
                    <input [(ngModel)]="startTime.minutes" class="text-center" type="text" id="startDateM"
                        name="startDateM" (keyup)="selectTime()" maxlength="2">
                </div>
            </div>
        </div>
        <div class="cal-util" (click)="onDatepickerIconClick($event)">{{ 'done' | translate }}</div>
    </div>
</div>