<div class="bgcolor-white p-2 m-4 position-relative form-container" style="height: calc(100% - 32px);">
    <div class="search-content mr-1 d-flex">
        <div class="mr-2 date-container">
            <app-datepicker [includeTime]="false" (value)="searchDate" (selectDateEventEmitter)="setDateForCopyEvent($event)"
                [justifyCenter]="true" [locale]="getLocale()" [zIndex]="'99999'" [label]="getTranslationFor('Rer.routePlanningSearch.date')" >
            </app-datepicker>
        </div>
        <div class="flex-grow-1 mr-2 street-content height-45 position-relative">
            <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45 position-relative">
                <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                <input matInput autocomplete="off" #searchStreet class="flex-grow-1" placeholder="{{ 'Rer.routePlanningSearch.streetSearch' | translate }}">
                <div *ngIf="isStreetDropdownOpen" (appClickOutside)="closeStreetDropdown();"  class="position-absolute street-container">
                    <ul>
                        <li (click)="onDropdownStreetClick(street)" *ngFor="let street of streetDropdownContent">{{ street.name }} - {{ street.city?.name }}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="flex-grow-1 mr-2 device-container">
            <div class="ng-select-wrapper w-100">
                <ng-select class="rer-planning" [items]="(rerService.deviceList$ | async)" bindLabel="name" bindValue="id" [multiple]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.device_name' | translate }}"
                    [closeOnSelect]="true" [(ngModel)]="selectedDevice" name="deviceId" >
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label">{{ item.name }}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="onSearch()" [class.disabled]="false" [class.pointer]="true">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4 text-center">
                <span class="color-white ml-0">{{ 'Rer.routePlanningSearch.search' | translate }}</span>
            </div>
        </div>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
        <ng-container matColumnDef="device">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.routePlanningSearch.list.dataTable.device_name' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.device }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="street">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.routePlanningSearch.list.dataTable.street' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.street }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="periodBegin">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.routePlanningSearch.list.dataTable.period_begin' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.periodBegin }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="periodEnd">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.routePlanningSearch.list.dataTable.period_end' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.periodEnd }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="waste">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.routePlanningSearch.list.dataTable.waste_type' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.waste }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
                <fa-icon *ngIf="hasPlanningRight()" (click)="navigateToPlanningDetails(element.planningId)" [icon]="faEye"
                    class="mr-2 pointer" title="{{ 'Rer.routePlanningSearch.list.dataTable.openPlanning' | translate }}"></fa-icon>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3"></mat-row>
    </mat-table>
    <mat-paginator class="mr-1" #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true"></mat-paginator>
    <div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute" style="height: 100%; top: 0; width: calc(100% - 100px);">
        <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
    </div>
</div>