<h4 mat-dialog-title class="p-2 header border-bottom-grey-1 font-size-24 text-uppercase text-center font-weight-bold">
    {{ 'Rer.rerSettings.wasteType' | translate }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [(ngModel)]="data.wasteType.name"
            placeholder="{{ 'Rer.rerSettings.dataTable.name' | translate }}" name="name">
    </mat-form-field>
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [(ngModel)]="data.wasteType.code"
            placeholder="{{ 'Rer.rerSettings.dataTable.code' | translate }}" name="code">
    </mat-form-field>
</div>
<div mat-dialog-actions class="mt-4 p-2 pr-4 pl-4">
    <div class="buttons d-flex justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="d-flex">
            <div class="button-component ml-2 utility-green-bg-color" (click)="onSave()">
                <div class="inner user-no-select inner-green pb-2 pt-2 pl-4 pr-4">
                    <span class="color-white ml-0">{{ 'save' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>