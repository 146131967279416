import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { AuthService } from 'app/service/auth.service';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';

@Component({
    selector: 'app-forgot-password-dialog',
    templateUrl: './forgot-password-dialog.component.html',
    styleUrls: ['./forgot-password-dialog.component.scss']
  })
export class ForgotPasswordDialogComponent {

    @ViewChild('resetpsswd') resetpsswd: NgForm;
    public isEmailModalShown = false;
    public background: string;
    public wrongData = false;
    public errorMessage = null;
    public logoSrc = '/assets/project/' + environment.projectName  + '/images/logo-small.png';

    constructor(
        public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private authService: AuthService,

        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    public onCancel(): void {

        this.dialogRef.close(false);
    }

    public onSubmit(): void {

        this.dialogRef.close(true);
    }

    sentResetPasswordRequest() {
        const loginData = this.resetpsswd.value.loginData;
        this.authService.sendPasswordResetEmail(loginData).subscribe(
            (response) => {
                this.resetpsswd.reset();
                this.isEmailModalShown = true;
                const innerThis = this;
                setTimeout( function () {
                    innerThis.isEmailModalShown = false;
                }, 10 * 60 * 1000);
            },
            (error: HttpErrorResponse) => {
                switch (error.status) {
                    case StatusCodes.BAD_REQUEST:
                        this.errorMessage = 'passwordRecovery.emailRequired';
                        break;
                    case  StatusCodes.FAILED_DEPENDENCY:
                        this.errorMessage = 'passwordRecovery.mailNotFound';
                        break;
                    default :
                        this.errorMessage = 'passwordRecovery.unknownerror';
                }
                this.resetpsswd.reset();
                this.wrongData = true;
            }
        );
    }
}
