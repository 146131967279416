import { RerClientImportAddressInterface } from '../_interface/rerclientimportaddress.interface';

export class RerClientImportAddress implements RerClientImportAddressInterface {
    constructor(
        public code = null,
        public reference_date = null,
        public name = null,
        public division = null,
        public city = null,
        public street = null,
        public street_number = null,
        public rer_bins = [],
        public area = null,
    ) { }
}
