import { Injectable, ComponentFactoryResolver, Injector, ComponentRef, ApplicationRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as StringPixelWidth from 'string-pixel-width';

import { UnitMeasurementsService } from '../../../service/unitmeasurements.service';
import { MapService } from './../shared/map.service';

import { MapVehicle } from '../../../modules/model/map/mapvehicle.model';

import { CoordonateInfoComponent } from '../../../shared/coordonate-info/coordonate-info.component';

const MARKER_COURSE = 90;

declare var require: any

@Injectable()
export class MarkerIconService {

    constructor(
        private mapService: MapService,
        private unitMeasurementsService: UnitMeasurementsService,
        private translateService: TranslateService,
        private injector: Injector,
        private resolver: ComponentFactoryResolver,
        private appRef: ApplicationRef
    ) { }

    public createMarker(dataMarker, map, lat, lng, deviceid, clickEvent = true) {
        let pie, cssSelector, marker;

        pie = (360 / 64);
        cssSelector = (Math.round(dataMarker.course / pie)) * (pie);
        marker = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            icon: {
                url: 'assets/images/map/vehicleMarker.png#' + cssSelector,
                anchor: new google.maps.Point(20, 20)
            },
            zIndex: google.maps.Marker.MAX_ZINDEX,
            map: map,
        });

        if (clickEvent) {
            marker.addListener('click', () => this.mapService.vehicleInfoSubject.next(deviceid));
        }

        return marker;
    }

    public createInfoWindow(vehicleName, deviceid, mapService) {

        const contentString = '<div class="label" id="' + vehicleName + '">' + vehicleName + '</div>';

        const pixelOffset = StringPixelWidth(vehicleName, { font: 'Open Sans', size: 15, bold: false }) + 25;
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const infobox = new InfoBox({
            content: contentString, disableAutoPan: true, alignBottom: true, showPointer: true,
            pixelOffset: new google.maps.Size(-1 * (((pixelOffset > 350 ? 360 : pixelOffset) / 2) + 18), -30),
            infoBoxClearance: new google.maps.Size(1, 1), closeBoxURL: 'assets/images/map/close.png'
        });

        if (google.maps.event) {
            google.maps.event.addListener(infobox, 'domready', function () {
                const vehicleDocument = document.getElementById(vehicleName);
                if (vehicleDocument) {
                    vehicleDocument.addEventListener('click', () => mapService.vehicleInfoSubject.next(deviceid));
                    vehicleDocument.addEventListener('contextmenu', () => mapService.contextMenuSubject.next(deviceid));
                }
            });
        }

        return infobox;
    }

    public getMarkerIcon(dataMarker, type) {
        if (!isNaN(dataMarker.course)) {
            let course = dataMarker.course;
            const pie = (360 / 64);
            const cssSelector = (Math.round(course / pie)) * (pie);
            if (type === 'marker' || type === 'directionIcon') {
                course -= MARKER_COURSE;
            }
            switch (dataMarker.status) {
                case -1:
                    if (type === 'directionIcon') {
                        return 'assets/images/map/vehicleEngineOnIcon.png#' + cssSelector;
                    }
                    return 'assets/images/map/vehicleEngineOnMarker.png#' + cssSelector;
                case 0:
                    if (type === 'directionIcon') {
                        return 'assets/images/map/vehicleStopIconN.png';
                    }
                    return 'assets/images/map/vehicleStopMarkerN.png';
                case 1:
                    if (type === 'directionIcon') {
                        return 'assets/images/map/vehiclePauseIcon.png';
                    }
                    return 'assets/images/map/vehiclePauseMarker.png';
                case 2:
                    if (type === 'directionIcon') {
                        return 'assets/images/map/vehicleIcon.png#' + cssSelector;
                    }

                    return 'assets/images/map/vehicleMarker.png#' + cssSelector;
            }
        } else {
            if (type === 'directionIcon') {
                return 'assets/images/map/vehicleNoCourseIcon.png';
            }

            return 'assets/images/map/vehicleNoCourseMarker.png';
        }
    }

    public getVehicleStateSymbol(state: number): string {
        let symbol = '';

        switch (state) {
            case -1:
                symbol = 'D';
                break;
            case 0:
                symbol = 'P';
                break;
            case 1:
                symbol = 'N';
                break;
            case 2:
                symbol = 'D';
                break;
        }

        return symbol;
    }

    public getInitialMarkerCourse() {
        return MARKER_COURSE;
    }

    public createContextMenuInfoWindow(event, canCreateGeozone = false) {
        let compRef: ComponentRef<CoordonateInfoComponent>;
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const coordonatesInfoWindow = new InfoBox({
            disableAutoPan: true, alignBottom: false, showPointer: false, pixelOffset: new google.maps.Size(-5, -10),
            infoBoxClearance: new google.maps.Size(1, 1), boxClass: 'contextMenuInfoBox', closeBoxURL: 'assets/images/map/close.png'
        });

        const compFactory = this.resolver.resolveComponentFactory(CoordonateInfoComponent);
        compRef = compFactory.create(this.injector);
        compRef.instance.latLng = event.latLng;
        compRef.instance.canCreateGeozone = canCreateGeozone;

        this.appRef.attachView(compRef.hostView);

        const div = document.createElement('div');
        div.appendChild(compRef.location.nativeElement);
        coordonatesInfoWindow.setContent(div);
        coordonatesInfoWindow.setZIndex(10000);
        google.maps.event.addListener(coordonatesInfoWindow, 'closeclick', () => {
            this.mapService.closeContextMenuSubject.next();
        });
        return coordonatesInfoWindow;
    }

    public createDetailedInfoWindow(vehicle: MapVehicle) {
        let dateFixtime, gpscoordonates, speed, address, mileageLabel, fuelLevelLabel;
        this.translateService.get('infobox.date').subscribe(res => dateFixtime = res);
        this.translateService.get('infobox.address').subscribe(res => address = res);
        this.translateService.get('infobox.gpscoordonates').subscribe(res => gpscoordonates = res);
        this.translateService.get('infobox.mileage').subscribe(res => mileageLabel = res);
        this.translateService.get('infobox.fuellevel').subscribe(res => fuelLevelLabel = res);
        this.translateService.get('infobox.speed').subscribe(res => speed = res);

        const speedValue = this.unitMeasurementsService.getUMValue(vehicle.speed, 'speed', true, 0);
        const mlgValue = this.unitMeasurementsService.getUMValue(vehicle.attributes.correctedMileage, 'distance', true, 0);
        const flValue = vehicle.attributes.fuel_level ?
            this.unitMeasurementsService.getUMValue(vehicle.attributes.fuel_level, 'volume', true, 2) : null;


        const contentString = '<div id="detail_' + vehicle.name + '" class="container-fluid content p-1"' +
            'style="width: 300px ;cursor: unset!important"><div class="font-size-15 mt-2 mb-2">' +
            '<span class="font-weight-bold p-0 m-0 mb-2 mr-2"><span class="label text-center font-size-14 color-white' +
            'font-weight-bold label' + vehicle.vehicleState.state + '" style="border-radius: 6px;  padding:3px 4px;">' +
            vehicle.vehicleState.symbol + '</span></span>' + vehicle.name + '</div>' +
            '<div class="d-flex mt-1 mb-1"><div class="p-0" style="width: 50%"><p class="p-0 m-0 text-left ">' + dateFixtime +
            ': ' + this.mapService.setDateOffset(vehicle.datetime) + '</p><p class="p-0 m-0 text-left">' + speed +
            ': <span class="font-weight-normal">' + speedValue + '</span></p>' + (flValue ? '<p class="p-0 m-0 text-left">' +
                fuelLevelLabel + ': <span class="font-weight-normal">' + flValue + '</span></p>' : '') + '</div>' +
            '<div class="pl-0" style="width: 50%"><p class="p-0 m-0 text-left">' + gpscoordonates +
            ': <a href="http://www.google.com/maps/place/' + vehicle.gpsCoordonates.latitude + ',' + vehicle.gpsCoordonates.longitude +
            '"target = "_blank" class="color-white" >' + vehicle.gpsCoordonates.latitude + ',' + vehicle.gpsCoordonates.longitude +
            '</a></p><p class="p-0 m-0 text-left font-weight-normal"></p> ' +
            (vehicle['attributes']['mileage_attribute'] ? '<p class="p-0 m-0 text-left">' + mileageLabel +
            ': <span class="font-weight-normal">' + mlgValue + '</span></p>' : '') + '</div></div><div><p class="p-0 m-0 text-justify">' +
            address + ': <span class="p-0 m-0 text-left font-weight-normal" data-device="' +
            vehicle.datetime + '">' + vehicle.locationAddress + '</span></p></div><span ' +
            'class="position-absolute bgcolor-white d-flex align-items-center justify-content-center ml-1 ng-star-inserted pointer"' +
            'style="width: 16px; height: 16px; top: 5px; right: 5px; border-radius: 50%;" id="directionArrowCloseWbg">' +
            '<i id="icon-cts-action-close" class="icon-cts-action-close font-size-10 color-blue"></i></span></div>';

        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const infobox = new InfoBox({
            content: contentString,
            disableAutoPan: true,
            alignBottom: true,
            showPointer: true,
            pixelOffset: new google.maps.Size(-160, -30),
            infoBoxClearance: new google.maps.Size(1, 1),
            closeBoxURL: 'assets/images/map/close.png'
        });

        if (google.maps.event) {
            google.maps.event.addListener(infobox, 'domready', () => {
                const vehicleDocument = document.getElementById('icon-cts-action-close');
                if (vehicleDocument) {
                    vehicleDocument.addEventListener('click', () => this.mapService.closeContextMenuSubject.next());
                }
            });
        }
        return infobox;
    }
}
