<div class="bgcolor-white p-4 m-4" style="height: calc(100% - 50px);">
    <div class="d-flex overflow-auto w-100 p-2">
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportRerData()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportCsv' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExport('unallocated')">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportUnallocatedData' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportIllegitimate()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportIllegitimateReadings' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportOrphans()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportOrphanReadings' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportDeletedClients()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportRerDeletedClientPartners' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportLostAndSuspendedBins()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportLostAndSuspendedBins' | translate }}</span>
            </div>
        </div>
        <div *ngIf="hasExportTagHistoryRight" class="button-component utility-green-bg-color ml-2" (click)="doExportTagHistory()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportRerRfidTagHistory' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="d-flex h-50 overflow-auto w-100 p-2">
        <div *ngIf="hasExportBinSetupsRight" class="button-component utility-green-bg-color ml-2" (click)="doExportBinSetups()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportRerBinSetups' | translate }}</span>
            </div>
        </div>
    </div>
    <br>
    <p>{{ 'Rer.dataExportRer.invoicingExports' | translate }}</p>
    <div class="d-flex h-50 overflow-auto w-100 p-2">
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportDetailed()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportRerDetailedReadings' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportInvoicing()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportRerInvoicingReadings' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="doExportCumulatedReadings()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportDeviceCumulatedReadings' | translate }}</span>
            </div>
        </div>`
        <div *ngIf="hasCalendarInvoiceableReadingsRight" class="button-component utility-green-bg-color ml-2" (click)="doExportCalendarInvoiceableReadings()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataExportRer.exportRerCalendarInvoiceableReadings' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
