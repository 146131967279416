<div class="bgcolor-white p-2 m-4 position-relative form-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms" [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="selectedTabIndex = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-cargo3d"></i>
                <span class="ml-1 mr-2">{{ 'Rer.objects.label' | translate }}</span>
            </ng-template>
            <app-list (deleteRerBinEventEmitter)="deleteBin($event)"
                [openTabsNumber]="objectsTabs.length"
                (editRerBinEventEmitter)="editBin($event)"
                (loadRerBinEventEmitter)="closeAllTabs()">
            </app-list>
        </mat-tab>
        <mat-tab *ngFor="let object of objectsTabs; let i = index">
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-cargo3d"></i>
                <span
                    class="ml-1 d-flex align-items-center">{{ object.id ? object.waste_type : ('Rer.objects.newObject' | translate) }}
                    <i class="icon-tmss-tab-close font-size-14 ml-1" (click)="onCloseTab(i)"></i>
                </span>
            </ng-template>
            <app-add-edit [object]="object" [rfidTagList]="rfidTagList"></app-add-edit>
        </mat-tab>
    </mat-tab-group>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
