import { RerService } from 'app/modules/rer/_service/rer.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-incident-export-filter',
  templateUrl: './incident-export-filter.component.html',
  styleUrls: ['./incident-export-filter.component.scss']
})

export class IncidentExportFilter {
    public status = '';
    public evaluation = '';

    public exportDates = {
        startDate: moment().subtract(moment.duration(7, 'months')).startOf('day').format('YYYY-MM-DD'),
        endDate: moment().endOf('day').format('YYYY-MM-DD')
    };

    constructor(
        public dialogRef: MatDialogRef<IncidentExportFilter>,
        private rerService: RerService,
        private translationService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { 
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public setExportDataPeriod(event: any) {
        this.exportDates.startDate = (event.dateBegin.indexOf(' ') !== -1 ?
            event.dateBegin.substr(0, event.dateBegin.indexOf(' ')) : event.dateBegin );
        this.exportDates.endDate = (event.dateEnd.indexOf(' ') !== -1 ?
            event.dateEnd.substr(0, event.dateEnd.indexOf(' ')) : event.dateEnd );
    }

    public getLocale(): String {
        return this.rerService.getLocale();
    }
    public getTranslationFor(label: string): String {
        return this.translationService.instant(label);
    }

    public onExport() {
        this.dialogRef.close({exportDates: this.exportDates, status: this.status, evaluation: this.evaluation});
    }
}