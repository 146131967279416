import { RerRfidTagInterface } from '../_interface/rerrfidtag.interface';

export class RerRfidTag implements RerRfidTagInterface {
    constructor(
        public id = null,
        public client_id = null,
        public rer_bin_id = null,
        public rfid = null,
        public created_by = null,
        public created_at = null,
        public updated_by = null,
        public updated_at = null,
    ) { }
}
