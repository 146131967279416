<div class="row ml-0 mt-4">
    <div class="select align-middle select-top-40 pl-2 w-25">
        <mat-placeholder class="dates-placeholder font-weight-bold">{{ 'status' | translate  }}
        </mat-placeholder>
        <ng-select class="height-40" style="top: -20px;" [items]="optionsStatus" [disabled]="!editMode" (change)="onStatusChanged()" [(ngModel)]="selectedStatus" 
                [clearable]="false">
            <ng-option *ngFor="let status of optionsStatus" [value]="status.value">
                {{ status.label | translate}}
            </ng-option>
        </ng-select>
    </div>
    <div class="select align-middle select-top-40 pl-2 w-25">
        <mat-placeholder class="dates-placeholder font-weight-bold">{{ 'Rer.rerIncident.list.dataTable.evaluation' | translate }}
        </mat-placeholder>
        <ng-select class="height-40" style="top: -20px;" (change)="onEvaluationChanged()" [disabled]="!editMode"
             [clearable]="false" [items]="optionsEvaluation" [(ngModel)]="selectedEvaluation">
            <ng-option *ngFor="let evaluation of optionsEvaluation" [value]="evaluation.value">
                {{ evaluation.label | translate}}
            </ng-option>
        </ng-select>
    </div>
    <div class="select align-middle pl-2 w-50">
        <mat-placeholder class="dates-placeholder font-weight-bold">{{ 'Rer.userAssociation.userList' | translate | translate }}
        </mat-placeholder>
        <ng-select class="height-40" style="top: -20px;" [items]="(clientUsersIncidentList$ | async)" class="flex-grow-1" bindValue="id"
                [closeOnSelect]="true" [(ngModel)]="incident.responsible" [disabled]="!editMode"
                (change)="updateSelection($event)" [multiple]="false" bindLabel="name">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <span class="ml-2">{{ item.name }}</span>
            </ng-template>
        </ng-select>
    </div>
</div>
<div class="row ml-0">
    <div class="select align-middle pl-2 w-50">
        <mat-placeholder class="dates-placeholder font-weight-bold">{{ 'Rer.rerIncident.details.clientList' | translate }}
        </mat-placeholder>
        <ng-select class="height-40" style="top: -20px;" [items]="(clientIncidentList$ | async)" class="flex-grow-1" bindValue="id" [disabled]="!editMode"
                [closeOnSelect]="true" [(ngModel)]="incident.rer_client_id"
                (change)="onChangeClient($event)" [multiple]="false" bindLabel="name">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <span class="ml-2">{{ item.name }}</span>
            </ng-template>
        </ng-select>
    </div>
    <div class="select align-middle pl-2 w-50">
        <mat-placeholder class="dates-placeholder font-weight-bold">{{ 'Rer.rerIncident.details.addressClient' | translate }}
        </mat-placeholder>
        <ng-select class="height-40" style="top: -20px;" [items]="(clientAddressIncidentList$ | async)" class="flex-grow-1" bindValue="id" [disabled]="!editMode"
            [closeOnSelect]="true" [(ngModel)]="incident.rer_client_address_id"
            (change)="onChangeClientAddress($event)" [multiple]="false" bindLabel="client_address">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <span class="ml-2">{{ item.client_address }}</span>
        </ng-template>
        </ng-select>
    </div>
</div>
<div class="row ml-0 text">
    <div class="w-50 pl-2">
        <mat-placeholder class="dates-placeholder font-weight-bold">{{ 'Rer.rerIncident.details.notes' | translate }}
        </mat-placeholder>
        <mat-form-field class="w-100 pt-2">
            <textarea matInput autocomplete="off"
                [(ngModel)]="incident.notes" rows="1" [disabled]="!editMode">
            </textarea>
        </mat-form-field>
    </div>
</div>
<hr>