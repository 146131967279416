import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'environments/environment';

import { GoogleMapTileService } from 'app/modules/service/shared/googlemaptile.service';
import { DynamicScriptLoaderService } from 'app/service/dynamic-script-loader.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { GeofenceService } from 'app/modules/service/map/geofence.service';
import { AuthService } from 'app/service/auth.service';

import { GeoCoords } from 'app/modules/model/map/geocoords.model';

@Component({
  selector: 'app-add-edit-street',
  templateUrl: './add-edit-street.component.html',
  styleUrls: ['./add-edit-street.component.scss']
})
export class AddEditStreetComponent {
  
  @ViewChild('gmap') gmapElement: any;

  private hasHasGoogleMapsRight = false;
  private availableMapTypes: any[];
  private mapProp: any = {};
  public map: google.maps.Map;
  private drawingManager: google.maps.drawing.DrawingManager | undefined;

  constructor(
    public dialogRef: MatDialogRef<AddEditStreetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private geofenceService: GeofenceService,
    private userPreferencesService: UserPreferencesService,
    private googleMapTileService: GoogleMapTileService,
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) { }

  ngAfterViewInit(): void {
    this.dynamicScriptLoader.load('googleApiScript').then((event) => {
      this.hasHasGoogleMapsRight = (this.authService.userModulesOptions.map.indexOf('google-maps') !== -1) ? true : false;
      this.mapProp = {
        center: this.authService.getLatLngFromString('46.080539,25.371337'),
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, mapTypeIds: [], position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoom: 6,
        controlSize: 32,
        fullscreenControl: true,
        fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
      };

      if (this.hasHasGoogleMapsRight && environment.isGoogleMapsAvailable) {
          this.availableMapTypes = [
              google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN,
              google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID,
              'openStreetsMap', 'mapBoxCustom', 'mapBoxHuGo'
          ];
          this.mapProp['streetViewControlOptions'] = {
              icon: '/assets/project/' + environment.projectName + '/images/favicon.png', position: google.maps.ControlPosition.RIGHT_TOP
          };
      } else {
          this.availableMapTypes = ['openStreetsMap', 'mapBoxHuGo'];
      }
  
      this.mapProp.mapTypeControlOptions.mapTypeIds = this.availableMapTypes;
      this.mapProp['streetViewControl'] = environment.isGoogleMapsAvailable && this.hasHasGoogleMapsRight ? true : false;

      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);

      const openStreetsMap = this.googleMapTileService.getOSMTileLayer();
      this.map.mapTypes.set('openStreetsMap', openStreetsMap);
      const mapBoxCustom = this.googleMapTileService.getMapBoxCustomTileLayer();
      this.map.mapTypes.set('mapBoxCustom', mapBoxCustom);
  
      this.map.setMapTypeId(
        this.availableMapTypes.indexOf(this.userPreferencesService.userPreferences.map.mapTypeId) >= 0 ? this.userPreferencesService.userPreferences.map.mapTypeId : 'openStreetsMap'
      );

      this.drawingManager = this.generateDrawingManager();
      google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (event: google.maps.Polygon) => this.onpPolygonComplete(event));

      if (this.data.street.coords && this.data.street.coords.length > 0) {
        this.data.street.geoZone = this.geofenceService.createGoogleMapsPolygon(this.map, { coords :this.data.street.coords }, false, false );
        const bounds = new google.maps.LatLngBounds();
        this.data.street.coords.forEach((pos) => bounds.extend(pos));
        this.map.fitBounds(bounds);

        this.data.street.geoZone.addListener('click', function() {
          this.setEditable(!this.getEditable());
          this.setDraggable(!this.getDraggable());
        });
      } else {
        this.drawingManager.setMap(this.map);
      }
    })
  }

  private generateDrawingManager(): google.maps.drawing.DrawingManager {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: { position: google.maps.ControlPosition.TOP_CENTER, drawingModes: [google.maps.drawing.OverlayType.POLYGON] },
      polygonOptions: { zIndex: 11 },
      map: null
    });

    return drawingManager;
  }

  private onpPolygonComplete(event: google.maps.Polygon) {
    if (this.data.street.geoZone) {
      this.data.street.geoZone.setMap(null);
    }

    this.drawingManager.setMap(null);
    this.data.street.geoZone = event;
    this.data.street.geoZone.addListener('click', function() {
      this.setEditable(!this.getEditable());
      this.setDraggable(!this.getDraggable());
    });
  }

  public onAdd() {
    if (!this.data.street.name || !this.data.street.geoZone) {
      return;
    }
    this.data.street.coords = this.data.street.geoZone.getPath().getArray().map((latLng: any) => new GeoCoords(latLng.lng(), latLng.lat()));

    this.dialogRef.close({...this.data.street});
  }

  public onNew() {
    if (this.data.street.geoZone) {
      this.data.street.geoZone.setMap(null);
    }

    this.drawingManager.setMap(this.map);
  }
}
