import { environment } from "./environment";

export const app = {
    version: {
        major: 8,
        minor: 54,
        patch: 4,
    },
    versionName: function () {
        if (environment.production) {
            return app.version.major + '.' + app.version.minor
                + (app.version.patch > 0 ? '.' + app.version.patch : '');
        } else {
            return 'next';
        }
    },
};
