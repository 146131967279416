import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedModule } from '../../../shared/shared.module';
import { DataExportRerComponent } from './data-export-rer.component';
import { CalendarInvoiceableReadingsDialogComponent } from './calendar-invoiceable-readings-dialog/calendar-invoiceable-readings-dialog.component';
import { CalendarInvoiceableAreaDialogComponent } from './calendar-invoiceable-area-dialog/calendar-invoiceable-area-dialog.component';
import { FilterClientAreaOwnershipDialogDialogComponent } from './filter-client-area-ownership-dialog/filter-client-area-ownership-dialog.component';
import { TagHistoryDialogComponent } from './tag-history-dialog/tag-history-dialog.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { IncidentExportFilter } from '../rer-incident/incident-export-fliter/incident-export-filter.component';
import { BinSetupDialogComponent } from './bin-setup-dialog/bin-setup-dialog.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }, // common / shared translation
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        DataExportRerComponent,
        CalendarInvoiceableReadingsDialogComponent,
        CalendarInvoiceableAreaDialogComponent,
        FilterClientAreaOwnershipDialogDialogComponent,
        TagHistoryDialogComponent,
        IncidentExportFilter,
        BinSetupDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: []
})
export class DataExportRerModule { }
