import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';

import { AuthService } from '../../../service/auth.service';

import { MapService } from '../shared/map.service';
import { DsWebSocketWorker } from '../../model/map/dswebsocketworker.model';

const SCOPE_POSITION = 'position';

@Injectable()
export class PositionSocketService {

    private token: string;

    private wssServers: string[] = [];

    public dsWorkers: DsWebSocketWorker[] = [];

    constructor(private authService: AuthService, private mapService: MapService) {

        this.token = this.authService.token;
    }

    public startWssWorkers(deviceDataConnexions: string[]): void {

        Object.keys(deviceDataConnexions).forEach(
            ddc => {
                this.wssServers.push(ddc);
                const worker = new DsWebSocketWorker(
                    ddc, SCOPE_POSITION, this.token, deviceDataConnexions[ddc].tcdeviceids, this.mapService
                );
                this.dsWorkers.push(worker);
            }
        );

    }

    public stopWssWorkers(): void {
        this.wssServers = [];
        if (this.dsWorkers.length > 0) {
            this.dsWorkers.forEach((worker: DsWebSocketWorker) => worker.wssConnection.close());
            this.dsWorkers = [];
        }
    }
}
