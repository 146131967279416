import { RerBinImportInterface } from '../_interface/rerbinimport.interface';

export class RerBinImport implements RerBinImportInterface {
    constructor(
        public rer_waste_type_code = null,
        public rer_bin_type_code = null,
        public rer_bin_ownership_code = null,
        public quantity = null
    ) { }
}
