import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'LeftPadFilter',
    pure: false
})
export class LeftPadFilter implements PipeTransform {
    transform(item: string, count: number, string: string): string {
        return (String(string).repeat(count) + item).substr((count * -1), count);
    }
}