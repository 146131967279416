import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../_service/rer.service';

import { RerLogs } from '../../_model/rerlogs.model';
@Component({
  selector: 'app-setting-logs',
  templateUrl: './setting-logs.component.html',
  styleUrls: ['./setting-logs.component.scss']
})
export class SettingLogsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public dataSource: MatTableDataSource<RerLogs>;
  public dateFormat:string = 'yyyy-MM-dd HH:mm';
  public displayedColumns: string[];
  public showSpinner: boolean;
  public currentFilterValue: string = null;

  constructor(private rerService: RerService,
    private translationService: TranslateService) { }

  ngOnInit() {
    this.showSpinner = true;
    this.displayedColumns = ['field_changed', 'old_value', 'new_value', 'updated_by', 'changed_at'];
    this.dataSource = new MatTableDataSource();
    this.rerService.getSettingsLogs().subscribe(
      (response: RerLogs[]) => {
        this.dataSource.data = response;
        this.showSpinner = false;
      },
      () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.logs.errorNotification"))
    )
  }

  ngAfterViewInit(): void {
    this.setDataTablePaginatorSort();
  }

  private setDataTablePaginatorSort(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
