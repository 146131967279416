<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ ((data.address.id ? 'Rer.clients.addEdit.address.editAddress' : 'Rer.clients.addEdit.address.addAddress') |
    translate) + ' - ' + data.clientName }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <div *ngIf="!isGeozoneScreen">
        <div class="row">
            <div class="col-6">
                <mat-checkbox [(ngModel)]="data.address.is_default"
                    [disabled]="data.isDefaultAddressDisabled && data.address.is_default === 1 || data.address.is_default === 1">
                    <span>{{ 'Rer.clients.addEdit.address.dataTable.is_default' | translate }}</span>
                </mat-checkbox>
            </div>
            <div class="col-6" *ngIf="hasGeozoneRight">
                <div class="d-flex align-items-center justify-content-end align-middle">
                    <p class="mt-2 mr-3" *ngIf="data.address.address_geozone">{{ 'Rer.clients.addEdit.address.hasGeozone' | translate }}</p>
                    <p class="mt-2 mr-3" *ngIf="!data.address.address_geozone">{{ 'Rer.clients.addEdit.address.hasNoGeozone' | translate }}</p>
                    <button type="button" (click)="switchGeozone();" [ngClass]="data.address.address_geozone ? 'utility-green-bg-color' : 'utility-orange-bg-color'" class="btn button">
                        {{ 'Rer.clients.addEdit.address.goToGeozone' | translate}}</button>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-50 mt-4 mr-2">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.address.code"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.code' | translate }}">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.address.code ?
                    'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </mat-form-field>
            <mat-form-field class="w-50 mt-4">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.address.name"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.name' | translate }}">
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-100 mt-4 mr-2">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.address.street"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.street' | translate }}">
            </mat-form-field>
            <mat-form-field class="w-100 mt-4">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.address.street_number"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.street_number' | translate }}">
            </mat-form-field>
        </div>
        <div class="d-flex">
            <mat-form-field class="w-100 mt-4 mr-2">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.address.city"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.city' | translate }}">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.address.city ?
                    'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </mat-form-field>
            <mat-form-field class="w-100 mt-4">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.address.division"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.division' | translate }}">
            </mat-form-field>
        </div>
        <div class="d-flex">
            <div class="ng-select-wrapper w-100 mt-4">
                <ng-select [items]="areaCompleteList" appendTo="body" [multiple]="false" [clearable]="false"
                    placeholder="{{ 'Rer.clients.addEdit.address.dataTable.area' | translate }}" [closeOnSelect]="true"
                    [(ngModel)]="data.address.rer_area_id" bindLabel="name" bindValue="id" name="areaId"
                    #areaId="ngModel" style="flex-grow: 1" (change)="updateSelection($event)">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <mat-form-field class="w-100 mt-4">
            <textarea matInput autocomplete="off" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="2"
                [(ngModel)]="data.address.description"
                placeholder="{{ 'Rer.clients.addEdit.address.dataTable.description' | translate }}">
            </textarea>
        </mat-form-field>
    </div>
    <div [ngClass]="{'d-none': !isGeozoneScreen }">
        <div #gmap style="height: 600px!important;" class="h-100 flex-grow-1"></div>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2" *ngIf="!isGeozoneScreen">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4" (click)="onAdd()"
            [ngClass]="data.address.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4"
                [ngClass]="data.address.id ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{ (data.address.id ? 'save' : 'add') | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2" *ngIf="isGeozoneScreen">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="onCancel()">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4" (click)="onGeozoneSave()"
            [ngClass]="data.address.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4"
                [ngClass]="data.address.id ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{ (data.address.id ? 'save' : 'add') | translate }}</span>
            </div>
        </div>
    </div>
</div>