import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { RerService } from '../../_service/rer.service';
import { RerRoute } from '../models/rer-route.model';


@Component({
  selector: 'app-route',
  templateUrl: './route.component.html',
  styleUrls: ['./route.component.scss']
})
export class RouteComponent implements OnInit {

  public routeTabs: any[] = [];
  public selectedTabIndex = 0;
  public showSpinner = false;

  constructor(private rerService: RerService, public actionDialog: MatDialog) { }

  ngOnInit(): void {
    this.rerService.getRerRouteList();
  }

  public onCloseTab(tabIndex: number): void {
    this.routeTabs.splice(tabIndex, 1);
    if (this.selectedTabIndex === tabIndex - 1) {
      this.selectedTabIndex = 0;
    }
  }

  public closeAllTabs(): void {
    this.routeTabs = [];
  }

  public addNewRoute() {
    const newRoute = this.routeTabs.find(cl => !cl.id);
    if (!newRoute) {
        this.routeTabs.unshift(new RerRoute());
    }

    setTimeout(()=> {this.selectedTabIndex = 1 }, 10);
  }

  public onEditRoute(event: any) {
    const index = this.routeTabs.findIndex((el: RerRoute) => el.id === event);
    if (index != -1) {
      this.selectedTabIndex = index + 1;
      return;
    } else {
      const route = this.rerService.getRouteById(event);

      if (route) {
        this.routeTabs.unshift(route);
        setTimeout(()=> {this.selectedTabIndex = 1 }, 10);
      }
    }
  }
}
