import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { convertUTCDateToLocalDate } from 'app/shared/util-funct';

@Component({
  selector: 'app-route-planning-list',
  templateUrl: './route-planning-list.component.html',
  styleUrls: ['./route-planning-list.component.scss']
})
export class RoutePlanningListComponent {

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public currentFilterValue: string = null;
  public convertUTCDateToLocalDate = convertUTCDateToLocalDate;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<RoutePlanningListComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.displayedColumns = ['street', 'device', 'periodBegin', 'periodEnd', 'waste'];

    this.dataSource = new MatTableDataSource(this.data.routExecutionPlan);
    this.setDataTablePaginatorSort();
    if (this.currentFilterValue) {
        this.applyFilter(this.currentFilterValue);
    }
  }

  ngAfterViewInit(): void {
      this.setDataTablePaginatorSort();
  }

  private setDataTablePaginatorSort(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
  }

  public formatDate(datetime: string) {
    if (!datetime) {
      return '';
    }
    
    return convertUTCDateToLocalDate(new Date(datetime));
  }
}
