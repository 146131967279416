<div style="height: calc(100% - 65px);">
    <ng-scrollbar #scrollable>
        <div class="d-flex mt-3 max-width-724px">
            <mat-form-field class="w-100 mr-4">
                <input type="details" matInput type="details"
                    placeholder="{{ 'Rer.clients.addEdit.overview.type' | translate }}" [(ngModel)]="client.type"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !client.type ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </mat-form-field>
            <mat-form-field class="w-100">
                <input type="details" matInput type="details"
                    placeholder="{{ 'Rer.clients.addEdit.overview.code' | translate }}"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'" [(ngModel)]="client.code">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !client.code ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </mat-form-field>
        </div>
        <div class="d-flex mt-3 max-width-724px">
            <mat-form-field class="w-100 mr-4">
                <input type="details" matInput type="details"
                    placeholder="{{ 'Rer.clients.addEdit.overview.name' | translate }}" [(ngModel)]="client.name"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !client.name ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </mat-form-field>
            <mat-form-field class="w-100">
                <input type="details" matInput type="email"
                    placeholder="{{ 'Rer.clients.addEdit.overview.email' | translate }}"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'" [(ngModel)]="client.email">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !client.email ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </mat-form-field>
        </div>
        <div class="d-flex mt-3 max-width-724px">
            <mat-form-field class="w-100 mr-4">
                <input type="details" matInput type="details"
                    placeholder="{{ 'Rer.clients.addEdit.overview.phone' | translate }}" [(ngModel)]="client.phone"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !client.phone ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </mat-form-field>
            <mat-form-field class="w-100">
                <input type="details" matInput type="details"
                    placeholder="{{ 'Rer.clients.addEdit.overview.contactPerson' | translate }}"
                    [(ngModel)]="client.delegate_name"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" matSuffix>
                </span>
            </mat-form-field>
        </div>
        <div class="mt-3 max-width-724px">
            <mat-checkbox class="mt-lg-1" (change)="handleFixedInvoicing($event)"
                [checked]="client.is_fixed_invoicing ? 'true' : 'false'">
                {{ 'Rer.clients.addEdit.overview.fixedInvoicing' | translate }}
            </mat-checkbox>
        </div>
        <div class="mt-3 max-width-724px">
            <mat-form-field class="w-100">
                <textarea type="details" matInput
                    placeholder="{{ 'Rer.clients.addEdit.overview.description' | translate }}"
                    [disabled]="!client.id || client.status === 1 ? 'false' : 'true'" [(ngModel)]='client.description'
                    [ngModelOptions]="{standalone: true}">
            </textarea>
            </mat-form-field>
        </div>
    </ng-scrollbar>
</div>
<div class="d-flex justify-content-between align-items-center button-container">
    <div class="d-flex align-items-center">
        <div *ngIf="showMandatory">
            <i class="icon-cts-information font-size-28 color-red-primary align-middle mr-1"></i>
            <span class="align-middle"> {{ 'mandatoryFieldText' | translate }}</span>
        </div>
    </div>
    <div class="action-buttons d-flex justify-content-between ml-2">
        <div *ngIf="client.id && client.status === 1" class="button-component utility-red-bg-color ml-2"
            (click)="onSuspendForNonPayment()">
            <div class="inner inner-red user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.clients.addEdit.actions.suspendForNonPayment' | translate
                    }}</span>
            </div>
        </div>
        <div *ngIf="client.id && client.status === 1" class="button-component utility-orange-bg-color ml-2"
            (click)="onSuspendOnRequest()">
            <div class="inner inner-orrange user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.clients.addEdit.actions.suspendOnRequest' | translate }}</span>
            </div>
        </div>
        <div *ngIf="client.id && (client.status === 2 || client.status === 3 || client.status === 1)"
            class="button-component utility-blue-bg-color ml-2" (click)="onTerminateContract()">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.clients.addEdit.actions.terminate' | translate }}</span>
            </div>
        </div>
        <div *ngIf="client.id && (client.status === 2 || client.status === 3 || client.status === 4)"
            class="button-component utility-blue-bg-color ml-2" (click)="onActivate()">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.clients.addEdit.actions.activate' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-2" (click)="onSave()"
            [ngClass]="client.id && client.status !== 1 ? 'disabled' : ''">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
