import { Injectable } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { DsDispatcher } from '../../model/map/dsdispatcher.model';
import { AuthService } from '../../../service/auth.service';

@Injectable()
export class DispatcherSocketService {

    public static SCOPE_DISPATCHER = 'chat';
    public static RECONNECT_INTERVAL = 2000;

    private wssServers: string[] = [];
    public reconnectObservable: Subscription;

    public ownDs: DsDispatcher;
    public dsDispatchers: DsDispatcher[] = [];
    public userService: any;
    public ownConnectionStatus = new BehaviorSubject<boolean>(false);
    public onlineOfflineIdsSubject = new BehaviorSubject<{ ids: number[], status: boolean }>(
        { ids: [], status: false }
    );
    public changeUnreadSubject = new Subject<{ userId: number, messageId: number }>();
    public sendMessageSubject = new Subject<{
        date: string,
        from_user_id: number,
        from_real: number,
        message: string,
        message_id: number,
        name: string,
        is_sms: boolean,
        send_file: number,
        to_user_id: number,
        type: string
    }>();
    public messageHistorySubject = new Subject<{
        type: string,
        from: number,
        history_date: string,
        message_id: number,
        messages: number[]
    }>();
    public disableReadMoreSubject = new Subject<{ state: boolean, from: number }>();

    constructor(private authService: AuthService) { }

    public startReconnectObservable(): void {
        // check wss connexion every RECONNECT_INTERVAL seconds
        this.reconnectObservable = interval(DispatcherSocketService.RECONNECT_INTERVAL).subscribe(
            () => {
                this.wssServers.forEach(
                    (wssServer) => {
                        const dsDispatcher = this.dsDispatchers.find((worker: DsDispatcher) => worker.wssurl === wssServer);
                        if (dsDispatcher.wssConnection.readyState !== 1 && dsDispatcher.wssConnection.readyState !== 0) {
                            dsDispatcher.wssCreate();
                        }
                    }
                );
            }
        );
    }

    public startWssDispatchers(wssConnections: string[], userId: number, groupIds: number[], hostname: string): void {
        wssConnections.forEach(
            ddc => {
                this.wssServers.push(ddc);
                const dispatcher = new DsDispatcher(
                    ddc, DispatcherSocketService.SCOPE_DISPATCHER, userId, groupIds, (hostname === ddc), this, this.authService
                );
                if (hostname === ddc) {
                    this.ownDs = dispatcher;
                }

                this.dsDispatchers.push(dispatcher);
            }
        );
    }

    public send(data: any, userDs = null): void {
        if (userDs) {
            const dsDispatcher = this.dsDispatchers.find((worker: DsDispatcher) => worker.wssurl === userDs);
            dsDispatcher.wssConnection.send(JSON.stringify(data));
        } else {
            this.ownDs.wssConnection.send(JSON.stringify(data));
        }
    }

    public closeWss(): void {
        this.dsDispatchers.forEach((ds: DsDispatcher) => ds.wssConnection.close());
    }
}
