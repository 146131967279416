import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../_service/rer.service';

import { RerClient } from '../_model/rerclient.model';

import { ConfirmActionComponent } from '../../../shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-rerclient',
    templateUrl: './rerclient.component.html',
    styleUrls: ['./rerclient.component.scss']
})
export class ClientComponent {

    public clientTabs: RerClient[] = [];
    public selectedTabIndex = 0;
    public showSpinner: boolean;

    constructor(private rerService: RerService, public actionDialog: MatDialog, private translationService: TranslateService) { }

    public addNewClient(): void {
        const newClient = this.clientTabs.find(cl => cl.id === null);
        if (!newClient) {
            this.clientTabs.unshift(new RerClient());
        }
        setTimeout(() => this.selectedTabIndex = 1);
    }

    public onCloseTab(tabIndex: number): void {
        this.clientTabs.splice(tabIndex, 1);
        if (this.selectedTabIndex === tabIndex - 1) {
            this.selectedTabIndex = 0;
        }
    }

    public editClient(id: number): void {
        let moveTo: number;
        const client = this.rerService.getRerClientById(id);
        const indexTab = this.clientTabs.findIndex((cl: RerClient) => cl.id === client.id);
        if (indexTab === -1) {
            this.clientTabs.push(client);
            moveTo = this.clientTabs.length;
        } else {
            moveTo = indexTab + 1;
        }
        setTimeout(() => { this.selectedTabIndex = moveTo; });
    }

    public deleteClient(id: number): void {

        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.clients.list.dataTable.confirmDeleteAction')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const client = this.rerService.getRerClientById(id);
                    if (client.status === RerClient.STATUS_TERMINATED) {
                        this.rerService.deleteClient(client.id).subscribe(
                            () => {
                                const indexTab = this.clientTabs.findIndex((cl: RerClient) => cl.id === client.id);
                                if (indexTab !== -1) {
                                    this.clientTabs.splice(indexTab, 1);
                                }
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.rerService.showErrorNotifications(
                                    this.translationService.instant('errorNotification'));
                            }
                        );
                    } else {
                        this.showSpinner = false;
                        this.rerService.showErrorNotifications(
                            this.translationService.instant('errorNotification'));
                    }
                }
            }
        );
    }

    public closeAllTabs(): void {
        this.clientTabs = [];
    }
}
