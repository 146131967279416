import { RerService } from 'app/modules/rer/_service/rer.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter-client-area-ownership-dialog',
  templateUrl: './filter-client-area-ownership-dialog.component.html',
  styleUrls: ['./filter-client-area-ownership-dialog.component.scss']
})

export class FilterClientAreaOwnershipDialogDialogComponent {
    public areaId = '';
    public clientType = '';
    public binOwnershipId = '';

    constructor(
        public dialogRef: MatDialogRef<FilterClientAreaOwnershipDialogDialogComponent>,
        private rerService: RerService,
        private translationService: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    public onCancel(): void {
        this.dialogRef.close();
    }

    public getLocale(): String {
        return this.rerService.getLocale();
    }
    public getTranslationFor(label: string): String {
        return this.translationService.instant(label);
    }

    public onExport() {
        this.dialogRef.close({clientType: this.clientType, areaId: this.areaId, binOwnershipId: this.binOwnershipId});
    }
}
