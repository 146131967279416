import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';

import { RerBinType } from '../../_model/rerbintype.model';

import { AddEditComponent } from './add-edit/add-edit.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-bin-type',
    templateUrl: './bin-type.component.html',
    styleUrls: ['./bin-type.component.scss']
})

export class BinTypeComponent implements OnInit {

    public binTypeList$: Observable<RerBinType[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean = false;

    constructor(private rerService: RerService,
        public dialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.displayedColumns = ['name', 'value', 'actions'];
        this.binTypeList$ = this.rerService.binTypeList$;

        this.binTypeList$.subscribe(
            (binTypes: RerBinType[]) => {
                this.dataSource = new MatTableDataSource(binTypes);
            }
        );
    }

    addEditBinType(binTypeId) {
        let binType = this.rerService.getBinTypeById(binTypeId);
        if (!binType) {
            binType = new RerBinType();
            binType.id = binTypeId;
        }

        const dialogRef = this.dialog.open(
            AddEditComponent,
            {
                width: '500px',
                data: { binType: binType }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.rerService.saveBinType(response).subscribe(
                        () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerSettings.savedSuccefuly")),
                        () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.saveFailed"))
                    )
                }
            }
        );
    }

    deleteBinType(id) {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService
                        .instant('Rer.rerSettings.confirmDeleteActionBT')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const bType = this.rerService.getBinTypeById(id);
                    this.rerService.deleteBinType(bType.id).subscribe(
                        () => { this.showSpinner = false; },
                        (error: HttpErrorResponse) => {
                            let errorMessage = 'errorNotification';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rerSettings.errorBinTypeInUse';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage), '600px');
                        }
                    );
                }
            }
        );
    }
}
