import { RerSettings } from './../_model/rersettings.model';
import { RerService } from '../_service/rer.service';
import { AuthService } from 'app/service/auth.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-power-bi-report',
    templateUrl: './power-bi-report.component.html',
    styleUrls: ['./power-bi-report.component.scss']
})
export class PowerBIReportComponent implements OnInit {

    public spinnerVisible = true;
    public hasPowerBIRights = false;
    public powerBIUrl = this.sanitizer.bypassSecurityTrustResourceUrl('/');
    public rerSettings: RerSettings[] = [];

    constructor(
        private sanitizer: DomSanitizer,
        private authService: AuthService,
        private rerService: RerService
        ) { }

    ngOnInit() {
        this.hasPowerBIRights = (this.authService.angularRoutes.indexOf('/power-bi-report') !== -1) ? true : false;

        this.rerService.getRerSettings().subscribe(
            (response: RerSettings[]) => {
                if (response.length > 0) {
                    this.rerSettings = response;

                    let setting = this.rerSettings.find((setting: RerSettings) => setting.name == this.rerService.RER_SETTINGS_POWER_BI_URL);
                    if(setting != null) {
                        this.powerBIUrl = this.sanitizer.bypassSecurityTrustResourceUrl(setting.value);
                    }
                }
            }
        );
    }
}
