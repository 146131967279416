<div class="snackbar-notification pointer" (click)="showNotification()">
    <div class="snackbar-header d-flex">
        <div [ngClass]="{ 'utility-green-bg-color' : data.notification.user_notification_template.priority === 1, 'bgcolor-orange' : data.notification.user_notification_template.priority === 2, 'utility-bg-error' : data.notification.user_notification_template.priority > 2 }"
            class="d-flex align-items-center justify-content-center">
            <i class="{{ data.type[data.notification.user_notification_type_id].class }} font-size-24 color-white"></i>
        </div>
        <div class="d-flex align-items-center justify-content-between flex-grow-1 font-weight-bold">
            <div class="ml-2 h-100 d-flex flex-column justify-content-center flex-grow-1">
                <p class="m-0 font-size-10 color-white">{{ data.notification.title }}</p>
                <p class="m-0 font-size-12 color-white">{{ data.notification.body }}</p>
            </div>
            <span *ngIf="data.notification.priority > 2" (click)="close($event)"
                class="text-uppercase color-white mr-2 ml-2 font-size-13 pointer">close</span>
        </div>
    </div>
    <div class="snackbar-body font-size-12 overflow-auto"
        [innerHTML]="data.notification.title && data.notification.title.length > 0 ? data.notification.title : data.notification.body"></div>
</div>
