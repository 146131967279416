<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ 'Rer.rerPlanning.list.editPlanning' | translate }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4" style="overflow: auto!important;">
    <div class="row">
        <div class="col-md-6">
            <div class="w-100 m-1 justify-content-start">
                <app-datepicker [label]="getTranslationFor('Rer.rerPlanning.planningDate')" [includeTime]="false"
                    (selectDateEventEmitter)="setPlanningDate($event)" [date]="data.planning.date"
                    [disabled]="data.planning.id > 0"></app-datepicker>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex">
                <ng-select class="rer-planning" [items]="(rerService.route$ | async)" [multiple]="false" [clearable]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.route' | translate }}" [closeOnSelect]="true"
                    [(ngModel)]="data.planning.rer_route_id" bindLabel="name" bindValue="id" name="wasteTypeId"
                    #rerRouteId="ngModel" style="flex-grow: 1" (change)="updateRouteSelection($event)">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="col-md-6">
            <mat-form-field class="w-100 mt-4 mr-1">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.planning.route_code" (input)="onRouteCodeChanged()"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.route_code' | translate }}" name="routeCode" #routeCode="ngModel">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field class="w-100 mt-4 mr-1">
                <input matInput autocomplete="off" type="details" [(ngModel)]="data.planning.area"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.area' | translate }}" name="area" #area="ngModel">
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="(!(area.value && area.value.trim()) && isMandatory['area']) ?
                        'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex">
                <ng-select class="rer-planning" [items]="wasteTypeCompleteList" [multiple]="false" [clearable]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.waste_type' | translate }}" [closeOnSelect]="true"
                    [(ngModel)]="data.planning.rer_waste_type_id" bindLabel="name" bindValue="id" name="wasteTypeId"
                    #wasteTypeId="ngModel" style="flex-grow: 1" (change)="updateSelection($event)">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex">
                <ng-select class="rer-planning" [items]="data.deviceList" bindLabel="name" bindValue="id" [multiple]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.device_name' | translate }}" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.planning.device_id" name="deviceId" #deviceId="ngModel"
                    style="flex-grow: 1;">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label">{{ item.name }}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
                <span style="margin: auto;" matTooltip="{{ 'mandatoryField' | translate }}"
                    [matTooltipPosition]="'right'" [ngClass]="(!deviceId.value && isMandatory['device_id']) ?
                        'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </div>
        </div>
        <div class="col-md-3">
            <mat-form-field class="w-100 mt-4 mr-1">
                <input matInput autocomplete="off" type="details" name="period_begin" #period_begin="ngModel"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.period_begin' | translate }}"
                    [(ngModel)]="data.planning.period_begin">
                <span style="margin: auto;" matTooltip="{{ 'mandatoryField' | translate }}"
                    [matTooltipPosition]="'right'" [ngClass]="data.planning.id && ((!data.planning.period_begin && isMandatory['period_begin']) || !isValidTimeFormat(data.planning.period_begin)) ?
                    'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field class="w-100 mt-4 mr-1">
                <input matInput autocomplete="off" type="details" name="period_end" #period_end="ngModel"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.period_end' | translate }}"
                    [(ngModel)]="data.planning.period_end">
                <span style="margin: auto;" matTooltip="{{ 'mandatoryField' | translate }}"
                    [matTooltipPosition]="'right'" [ngClass]="data.planning.id && ((!data.planning.period_end && isMandatory['period_end']) || !isValidTimeFormat(data.planning.period_end)) ?
                    'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex">
                <ng-select class="rer-planning" [items]="driverList" bindLabel="name" bindValue="id" [multiple]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.main_employee_name' | translate }}" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.planning.main_employee_id" name="driverId"
                    #driverId="ngModel" style="flex-grow: 1;">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label">{{ item.name }}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
                <span style="margin: auto;" matTooltip="{{ 'mandatoryField' | translate }}"
                    [matTooltipPosition]="'right'" [ngClass]="(!driverId.value && isMandatory['main_employee_id']) ?
                        'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex">
                <ng-select class="rer-planning" [items]="operatorList" bindLabel="name" bindValue="id"
                    [multiple]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.secundary_employee_name' | translate }}"
                    [clearable]="false" [closeOnSelect]="true" [(ngModel)]="data.planning.secundary_employee_id"
                    name="op1Id" #op1Id="ngModel" style="flex-grow: 1;">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label">{{ item.name }}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
                <span style="margin: auto;" matTooltip="{{ 'mandatoryField' | translate }}"
                    [matTooltipPosition]="'right'" [ngClass]="(!op1Id.value && isMandatory['secundary_employee_id']) ?
                        'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                    matSuffix>
                </span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ng-select-wrapper w-100 mt-4 mr-1">
                <ng-select class="rer-planning" [items]="tertiaryEmployeeCompleteList" bindLabel="name" bindValue="id"
                    [multiple]="false"
                    placeholder="{{ 'Rer.rerPlanning.dataTable.tertiary_employee_name' | translate }}"
                    [clearable]="false" [closeOnSelect]="true" [(ngModel)]="data.planning.tertiary_employee_id">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label">{{ item.name }}</span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div *ngIf="data.hasPlanningAddressRight">
        <div class="d-flex">
            <div class="ng-select-wrapper w-100 mt-4 mr-2">
                <ng-select class="rer-planning" [items]="clientCompleteList" [multiple]="false" [clearable]="false"
                    placeholder="{{ 'Rer.rerCollectionByWeight.decisionDocument.selectClient' | translate }}"
                    [closeOnSelect]="true" [(ngModel)]="selectedClientId" bindLabel="name" bindValue="id"
                    name="clientId" #areaId="ngModel" style="flex-grow: 1" (change)="updateClientAddress($event)">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <div class="ng-select-wrapper w-100 mt-4 mr-2">
                <ng-select class="rer-planning" [items]="adressSelectList" bindLabel="name" [closeOnSelect]="false" bindValue="id"
                    (search)="onAddressSearch($event)"
                    placeholder="{{ 'Rer.rerCollectionByWeight.decisionDocument.selectAddresses' | translate }}"
                    [clearable]="false" (change)="onChangeAddressList($event)" [closeOnSelect]="false" [multiple]="true"
                    [(ngModel)]="selectedAddresses">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 4">
                                <span class="ng-value-label">{{ item.name.slice(0,6) }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 4">
                            <span class="ng-value-label"> + {{ items.length - 4 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-header-tmp>
                        <div style="margin-right: 10px" *ngIf="showAddressesListSelectAll">
                            <mat-checkbox (click)="onSelectAllAddresses($event)"
                                [checked]="isSelectAllAddressesChecked">
                                <span class="ml-2 color-blue">{{ 'selectAll' | translate }}</span>
                            </mat-checkbox>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item.name }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
            <div (click)="onAddAddresses()"
                class="height-60 mt-4 pointer w-100 utility-green-bg-color color-white d-flex align-items-center justify-content-center">
                + {{'Rer.rerCollectionByWeight.decisionDocument.addAddresses' | translate }}
            </div>
        </div>
        <div class="w-100 mt-4">
            <mat-table #table [dataSource]="dataSource" matSort style="height: 250px">
                <ng-container matColumnDef="clientName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Rer.rerCollectionByWeight.decisionDocument.clientName' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.client_name }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="addressName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center!important;">
                        {{ 'Rer.rerCollectionByWeight.decisionDocument.addressName' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="minimumNotificationQuantity">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'Rer.rerCollectionByWeight.decisionDocument.minimumNotificationQuantity' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element"><mat-form-field class="w-100 py-2">
                            <input type="number" matInput autocomplete="off" min="0"
                                (focus)="element.minimum_notification_quantity === 0 && element.minimum_notification_quantity = null"
                                (blur)="!element.minimum_notification_quantity ? element.minimum_notification_quantity = 0 : ''"
                                [(ngModel)]="element.minimum_notification_quantity">
                        </mat-form-field></mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
                        <i (click)="onDeleteAddress(element.rer_client_address_id)" title="{{ 'delete' | translate }}"
                            class="icon-tmss-list-action-delete font-size-14 pointer"></i>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
                </mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
                </mat-row>
            </mat-table>
            <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]"
                [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
    </div>
    <mat-form-field class="w-100 mt-4 mb-2">
        <textarea matInput autocomplete="off" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="2"
            [(ngModel)]="data.planning.notes" placeholder="{{ 'Rer.rerPlanning.dataTable.notes' | translate }}">
        </textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="mt-4 p-2 pr-4 pl-4">
    <div class="buttons d-flex justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="d-flex">
            <div *ngIf="this.data.planning.id" class="button-component utility-orange-bg-color ml-2"
                (click)="onDelete()">
                <div class="inner inner-orrange user-no-select pb-2 pt-2 pl-4 pr-4">
                    <span class="color-white ml-0">{{ 'delete' | translate }}</span>
                </div>
            </div>
            <div class="button-component ml-2 utility-green-bg-color" (click)="onSave()">
                <div class="inner user-no-select inner-green pb-2 pt-2 pl-4 pr-4">
                    <span class="color-white ml-0">{{ 'save' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>