import { RerService } from 'app/modules/rer/_service/rer.service';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-copy-from-date',
    templateUrl: './copy-from-date.component.html',
    styleUrls: ['./copy-from-date.component.scss']
})
export class CopyFromDateComponent implements OnInit {

    public showMandatory: boolean;
    public date = moment().format('YYYY-MM-DD');
    public copyDate = moment().format('YYYY-MM-DD');

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CopyFromDateComponent>,
        public translateService: TranslateService,
        public rerService: RerService,
        public dialog: MatDialog
    ) { }

    ngOnInit() { }

    public onSubmit() {
        this.dialogRef.close( { date: this.date, copyDate: this.copyDate});
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public setDateForCurrentEvent(event: any) {
        this.date = event;
    }
    
    public setDateForCopyEvent(event: any) {
        this.copyDate = event;
    }

    public getLocale(): String {
        const lang = localStorage.getItem('currentLang');
        return (lang) ? lang.substring(0, 2) : 'ro';
    }

}
