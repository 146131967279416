import { Component, ViewChild, OnInit, OnDestroy, Renderer2, Inject, Injector } from '@angular/core';
import { environment } from 'environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { faEye, faEyeSlash, faPowerOff } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from '../../service/auth.service';
import { MatDialog } from '@angular/material/dialog';

import { ForgotPasswordDialogComponent } from './dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { UpgradeRequiredComponent } from '../upgrade-required/upgrade-required.component';
import { FacebookPixelService } from '../../service/facebookpixel.service';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { HttpStatusCode } from '@angular/common/http';
import { EnvService } from 'app/env.service';
import { SettingsService } from 'app/modules/service/settings/settings.service';
import { app } from 'environments/app';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.scss']
})

export class SigninComponent implements OnInit, OnDestroy {
    private facebookPixelService;
    public hasFacebookPixelAccount = false;
    public hide = true;
    public faEye = faEye;
    public faEyeSlash = faEyeSlash;
    public faPowerOff = faPowerOff;
    public currentLanguage;
    public localVersionName = '';
    public localVersion: {
        major: number,
        minor: number,
        patch: number,
    };

    constructor(
        private authService: AuthService,
        private router: Router,
        public translateService: TranslateService,
        private titleService: Title,
        private dialog: MatDialog,
        private renderer2: Renderer2,
        private injector: Injector,
        @Inject(DOCUMENT) private _document: Document,
        private settingsService: SettingsService,
    ) {
        if (environment.projectName === 'cargotrack') {
            this.facebookPixelService = this.injector.get<FacebookPixelService>(FacebookPixelService);
            this.hasFacebookPixelAccount = true;
        }
    }

    private static HTTP_UPGRADE_REQUIRED = 426;
    private static HTTP_MISDIRECTED_REQUEST = 421;

    @ViewChild('f') signinForm: NgForm;
    @ViewChild('resetpsswd') resetpsswd: NgForm;

    private _subscriptions: Subscription[] = [];

    public wrongCredentials: boolean;
    public rememberme: boolean;
    public logoSrc = '/assets/project/' + environment.projectName + '/images/logo.png';
    public background: string;
    public wrongData = false;
    public errorMessage = null;
    public isEmailModalShown = false;
    public backgroundImage: Array<any> = [
        [
            '/assets/project/' + environment.projectName + '/images/signin/0-winter-1.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/0-winter-2.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/0-winter-3.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/0-winter-4.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/0-winter-5.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/0-winter-6.jpg',
        ],
        [
            '/assets/project/' + environment.projectName + '/images/signin/1-spring-1.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/1-spring-2.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/1-spring-3.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/1-spring-4.jpg',
        ],
        [
            '/assets/project/' + environment.projectName + '/images/signin/2-summer-1.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/2-summer-2.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/2-summer-3.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/2-summer-4.jpg',
        ],
        [
            '/assets/project/' + environment.projectName + '/images/signin/3-autumn-1.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/3-autumn-2.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/3-autumn-3.jpg',
            '/assets/project/' + environment.projectName + '/images/signin/3-autumn-4.jpg',
        ]
    ];

    ngOnInit() {
        this._subscriptions.push(this.translateService.get('SignIn.title')
            .subscribe((response: string) => this.titleService.setTitle(response)));
        // Global site tag (gtag.js) - Google Analytics
        const s = this.renderer2.createElement('script');
        s.type = 'text/javascript';
        s.src = 'https://www.googletagmanager.com/gtag/js?id=UA-55507844-1';
        s.text = '';
        this.renderer2.appendChild(this._document.body, s);

        const sgtag = this.renderer2.createElement('script');
        sgtag.type = 'text/javascript';
        sgtag.text = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-55507844-1');
        `;
        this.renderer2.appendChild(this._document.body, sgtag);

        this.rememberme = true;
        this.isEmailModalShown = false;
        if (this.authService.token) {
            if (this.authService.angularRoutes.length > 0) {
                this.router.navigate([this.authService.angularRoutes[0]]);
                return;
            }
        }

        this.wrongCredentials = false;
        const now = moment().format('M');
        const seasonIndex: any = (+now % 12) / 3;
        let imageIndex = 0;
        if (environment.isRandomBackgroundAtSignin) {
            imageIndex = this.getRandomInt(this.backgroundImage[+parseInt(seasonIndex, 10)].length);
        }
        this.background = this.backgroundImage[+parseInt(seasonIndex, 10)][imageIndex];

        this.currentLanguage = this.translateService.currentLang;

        this.localVersion = app.version;
        this.localVersionName = app.versionName();
    }

    ngOnDestroy(): void {
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }

    public resetApplication(): void {
        localStorage.clear();
        document.cookie.split(';').forEach(function (c) {
            document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        });
        window.location.replace('/');
    }

    public switchLanguage(language: string) {
        this.translateService.use(language);

        // Set page title
        this._subscriptions.push(this.translateService.get('SignIn.title')
            .subscribe((response: string) => this.titleService.setTitle(response)));
        localStorage.setItem('currentLang', language);
    }

    public login() {
        const minimumVersionMajor = +this.settingsService.minimumVersion.substring(0, this.settingsService.minimumVersion.indexOf('.'));
        const minimumVersionMinor = +this.settingsService.minimumVersion.slice(this.settingsService.minimumVersion.indexOf('.') + 1);
        if (this.settingsService.minimumVersion && (this.localVersion.major < minimumVersionMajor
            || (this.localVersion.major == minimumVersionMajor && this.localVersion.minor < minimumVersionMinor))) {
            const dialogRef = this.dialog.open(
                UpgradeRequiredComponent,
                { maxWidth: '800px', panelClass: 'custom-dialog-container', disableClose: true }
            );
            dialogRef.afterClosed().subscribe(
                response => {
                    if (response) {
                        location.reload();
                        return false;
                    }
                }
            );
            return;
        }
        this._subscriptions.push(
            this.authService.signin(this.signinForm.value.username, this.signinForm.value.password, this.rememberme).subscribe(
                {
                    next: (result) => {
                        if (result === true) {
                            this.router.navigate(['/']);
                        } else {
                            this.launchWrongCredentialsModal();
                        }
                    },
                    error: (error) => {
                        switch (error.status) {
                            case SigninComponent.HTTP_UPGRADE_REQUIRED:
                                const dialogRef = this.dialog.open(
                                    UpgradeRequiredComponent,
                                    { maxWidth: '800px', panelClass: 'custom-dialog-container', disableClose: true }
                                );
                                dialogRef.afterClosed().subscribe(
                                    response => {
                                        if (response) {
                                            location.reload();
                                            return false;
                                        }
                                    }
                                );
                                break;
                            case SigninComponent.HTTP_MISDIRECTED_REQUEST:
                                window.open(error.error.autoLoginLink, '_self');
                                break;
                            case HttpStatusCode.Forbidden:
                                this.launchWrongCredentialsModal(true);
                                break;
                            default:
                                this.launchWrongCredentialsModal();
                                break;
                        }
                    }
                }
            )
        );
    }

    public launchWrongCredentialsModal(oldPassword = false): void {
        const dialogRef = this.dialog.open(
            NotificationComponent,
            {
                width: '480px',
                panelClass: 'custom-dialog-container',
                data: {
                    headermessage: oldPassword ? this.translateService.instant('SignIn.oldPasswordTitle') : this.translateService.instant('SignIn.invalidCredentialsTitle'),
                    message: oldPassword ? this.translateService.instant('SignIn.oldPassword') : this.translateService.instant('SignIn.invalidCredentials'),
                    success: 0
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                this.resetApplication();
            }
        );
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);
        return result;
    }

    public getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    public openForgotPassModal() {
        this.dialog.open(ForgotPasswordDialogComponent, { width: '600px', data: { message: ' ' } });
    }
}
