import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectItem } from 'app/modules/rer/_model/selectitem.model';

import { TranslateService } from '@ngx-translate/core';
import { RerIncident } from 'app/modules/rer/_model/rerincident.model';
import { RerService } from 'app/modules/rer/_service/rer.service';
import { RerUserIncident } from 'app/modules/rer/_model/reruserincident.model';
import { RerClientIncident } from 'app/modules/rer/_model/rerclientincident.model';
import { RerClientAddressIncident } from 'app/modules/rer/_model/rerclientaddressincident.model';

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

    public optionsStatus = [];
    public optionsEvaluation = [];
    public clientUsersIncidentList$: Observable<RerUserIncident[]>
    public clientIncidentList$: Observable<RerClientIncident[]>
    public clientAddressIncidentList$: Observable<RerClientAddressIncident[]>
    public selectedEvaluation: SelectItem;
    public selectedStatus: SelectItem;


    @Output() closeIncidentEventEmitter: EventEmitter<number> = new EventEmitter();
    @Input() incident: RerIncident;
    @Input() editMode: boolean;

    constructor(
        private rerService: RerService,
        public translateService: TranslateService,
        private translationService: TranslateService) { }

    ngOnInit() {

        this.optionsStatus = [
            { id: 0, label: this.translationService.instant('Rer.rerIncident.details.statuses.0') },
            { id: 1, label: this.translationService.instant('Rer.rerIncident.details.statuses.1') },
            { id: 2, label: this.translationService.instant('Rer.rerIncident.details.statuses.2') }
        ];

        this.optionsEvaluation = [
            { id: 0, label: this.translationService.instant('Rer.rerIncident.details.evaluation.0') },
            { id: 1, label: this.translationService.instant('Rer.rerIncident.details.evaluation.1') },
            { id: 2, label: this.translationService.instant('Rer.rerIncident.details.evaluation.2') },
        ];
        this.clientUsersIncidentList$ = this.rerService.clientUsersIncidentList$;
        this.rerService.getUserClient();
        this.clientIncidentList$ = this.rerService.clientIncidentList$;
        this.rerService.getRerClient();
        this.clientAddressIncidentList$ = this.rerService.clientAddressIncidentList$;
        this.selectedEvaluation = this.optionsEvaluation.find(x => x.id == this.incident.evaluation);
        this.selectedStatus = new SelectItem(this.incident.status_string, this.incident.status);

    }

    public onStatusChanged() {
        this.incident.status_string = this.selectedStatus.label;
        this.incident.status = this.selectedStatus.id;
    }
    public onEvaluationChanged() {
        this.incident.evaluation_string = this.selectedEvaluation.label;
        this.incident.evaluation = this.selectedEvaluation.id;
    }

    public updateSelection(event) {
        if(event){
            this.incident.responsible = event.id;
        };
    }

    public onChangeClient(event) {
        if(event){
            this.rerService.getRerClientAddress(event.id);
            this.incident.rer_client_id = event.id;
        }
    }

    public onChangeClientAddress(event) {
        if(event){
            this.incident.rer_client_address_id = event.id;
        }
    }
    
}