/// <reference types="@types/google.maps" />

import { Component, ViewChild, AfterViewInit, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { GoogleMapCustomControl } from '../../../../service/shared/googlemapcustomcontrol.service';
import { GoogleMapDrawService } from '../../../../service/shared/googlemapdraw.service';
import { GoogleMapTileService } from '../../../../service/shared/googlemaptile.service';
import { UserPreferencesService } from '../../../../../service/userpreferences.service';
import { HistoryService } from '../../../../service/map/history.service';
import { AuthService } from 'app/service/auth.service';

import { environment } from 'environments/environment';

import { RerIncident } from 'app/modules/rer/_model/rerincident.model';
import * as moment from 'moment-timezone';
import { GoogleapisService } from 'app/modules/service/shared/googleapis.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit,AfterViewInit {
    @ViewChild('gmap', { static: false }) gmapElement: any;
    private map: google.maps.Map;
    private _subscriptions: Subscription[] = [];
    private zoomlevelOnSelectMarker = 20;
    private availableMapTypes: any[];
    private mapProp = {
        center: this.authService.getLatLngFromString(this.userPreferencesService.userPreferences.defaultMapCenter),
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, mapTypeIds: [], position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoom: this.googleMapDrawService.zoomlevelOnOverview, // 5
        controlSize: 32,
        fullscreenControl: true,
        fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
    };
    private trafficLayer: google.maps.TrafficLayer;
    private kmlLayerCurrentZoom = 0;
    private zipLayersCurrentZoom = 0;

    public sidebarActive: boolean;
    public kmlLayersShown = false;
    public kmlList: any = {};
    public zipLayersShown = false;
    public zipMarkers: any = {};
    public isMobile = false;
    public dateFormat: string;
    public marker: any;
    public points: any = [];
    public path = new google.maps.Polyline({
        geodesic: true,
        strokeColor: "#3b5297",
        strokeOpacity: 1.0,
        strokeWeight: 5,
    });

    @Input() incident: RerIncident;

    constructor(
        private translateService: TranslateService,
        private googleapisService: GoogleapisService,
        private googleMapDrawService: GoogleMapDrawService,
        private googleMapTileService: GoogleMapTileService,
        private googleMapCustomControlService: GoogleMapCustomControl,
        private userPreferencesService: UserPreferencesService,
        private authService: AuthService,
        private historyService: HistoryService
    ) {
        this.translateService.setDefaultLang('ro_RO');
        const lang = localStorage.getItem('currentLang');
        if (lang) {
            this.translateService.use(lang);
        }
    }

    ngOnInit() {
        if (window.screen.width <= 768) {
            this.isMobile = true;
        }
        window.onresize = () => this.isMobile = window.innerWidth <= 768;
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.zoomlevelOnSelectMarker = (this.userPreferencesService.userPreferences &&
            this.userPreferencesService.userPreferences.map && this.userPreferencesService.userPreferences.map.mapZoomlevel) ?
            this.userPreferencesService.userPreferences.map.mapZoomlevel : this.zoomlevelOnSelectMarker;

        this.sidebarActive = true;

    }

    ngAfterViewInit() {

        if (environment.isGoogleMapsAvailable) {
            this.availableMapTypes = [
                google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN,
                google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID,
                'openStreetsMap', 'mapBoxCustom',
            ];
            this.mapProp['streetViewControlOptions'] = {
                icon: '/assets/project/' + environment.projectName + '/images/favicon.png', position: google.maps.ControlPosition.RIGHT_TOP
            };
        } else {
            this.availableMapTypes = ['openStreetsMap'];
        }

        this.mapProp.mapTypeControlOptions.mapTypeIds = this.availableMapTypes;
        this.mapProp['streetViewControl'] = environment.isGoogleMapsAvailable ? true : false;

        this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);

        this.map.addListener('maptypeid_changed', () => this.onMapTypeIdChanged());
        this.map.addListener('bounds_changed', () => setTimeout(() => this.setGoogleMapControlsDesign(), 1000));


        if (environment.isGoogleMapsAvailable) {
            this.trafficLayer = new google.maps.TrafficLayer();
            this.googleMapCustomControlService.createTrafficLayerControl(this.trafficLayer, this.map);
        }

        const openStreetsMap = this.googleMapTileService.getOSMTileLayer();
        this.map.mapTypes.set('openStreetsMap', openStreetsMap);
        const mapBoxCustom = this.googleMapTileService.getMapBoxCustomTileLayer();
        this.map.mapTypes.set('mapBoxCustom', mapBoxCustom);

        this.map.setMapTypeId(this.availableMapTypes.indexOf(this.userPreferencesService.userPreferences.map.mapTypeId) >= 0
            ? this.userPreferencesService.userPreferences.map.mapTypeId : 'openStreetsMap');
        this.setSubscriptionsForAfterViewInit();
    }

    private setSubscriptionsForAfterViewInit(): void {

        var currentdate = new Date();
        var datetime = currentdate.getFullYear() + "-"
            + (currentdate.getMonth() + 1) + "-"
            + currentdate.getDate() + " "
            + currentdate.getHours() + ":"
            + ((currentdate.getMinutes() < 10 ? '0' : '') + currentdate.getMinutes()) + ":"
            + ((currentdate.getSeconds() < 10 ? '0' : '') + currentdate.getSeconds());

        if (this.incident && this.incident.device_id) {
            this._subscriptions.push(
                this.historyService.getVehiclesHistoryPositions({
                    apiEndPoint: this.incident.device_hostname,
                    deviceId: this.incident.traccar_device,
                    startDate: moment(this.incident.incident_time).subtract(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(this.incident.incident_time).add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
                    received: 0,
                }).subscribe(
                    (positions) => {
                        if (positions.length > 0) {
                            this.drawIncidentPosition(positions);
                        }
                    }
                )
            );
        }
    }

    private drawIncidentPosition(positions: any) {

        let points = [];
        let initialPosition = positions[0];
        positions.forEach(element => {
            this.points.push({
                lat: element.latitude,
                lng: element.longitude
            });
            if (moment(element.devicetime).isSameOrBefore(moment(this.incident.incident_time))) {
                initialPosition = element;
            } else {
                if (moment(element.devicetime).diff(moment(this.incident.incident_time)) < moment(this.incident.incident_time).diff(moment(initialPosition.devicetime))) {
                    initialPosition = element;
                }
                this.incident.latitude = element.latitude;
                this.incident.longitude = element.longitude;

            }
            this.path.setPath(this.points);
            this.path.setMap(this.map);
        });
        this.googleapisService.reverseGeocodeNominatim(initialPosition.latitude, initialPosition.longitude).subscribe(
            (response) => {
                let formattedAddress = this.googleapisService.formatNominatimAddress(response);
                let locationAddress = formattedAddress;
                this.incident.location = formattedAddress;
            }
        )
        this.generateInfoBox(initialPosition);
        this.setMapBounds();
    }


    private generateInfoBox(position: any) {

        this.marker = new google.maps.Marker({
            position: new google.maps.LatLng(position.latitude, position.longitude),
            map: this.map
        });
    }

    focusOnLocation(coordinate) {
        var bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
        );
        this.mapFitToBounds(bounds, true);
    }

    setMapBounds() {
        let bounds = new google.maps.LatLngBounds();
        if (this.points && this.points.length > 0) {
            this.points.forEach(element => {
                bounds.extend(new google.maps.LatLng(element.lat, element.lng));
            });
        }

        this.mapFitToBounds(bounds);
    }

    private mapFitToBounds(bounds: google.maps.LatLngBounds, waitForMap: boolean = true) {
        if (this.gmapElement && this.gmapElement.nativeElement.clientWidth) {
            this.map.fitBounds(bounds);
        } else {
            if (waitForMap) {
                setTimeout(() => { this.mapFitToBounds(bounds, false); }, 1000);
            }
        }
    }

    private onMapTypeIdChanged() {
        const userPreferences = this.userPreferencesService.userPreferences;
        userPreferences.map.mapTypeId = this.map.getMapTypeId();
        this.userPreferencesService.userPreferences = userPreferences;
        this.authService.frontendPreferences = userPreferences;
        this.setGoogleMapControlsDesign();
    }

    private setGoogleMapControlsDesign(): void {
        const pegman = document.querySelector('.gm-svpc');
        if (pegman) {
            Array.from(pegman.lastChild['children']).forEach(
                (img: HTMLElement) => img['style'].width = img['style'].height = '20px!important'
            );
        }
        const gmnoprintButtonZoomOut = document.querySelector('.gmnoprint.gm-bundled-control > .gmnoprint > div > button:nth-child(1)');
        if (gmnoprintButtonZoomOut) {
            Array.from(gmnoprintButtonZoomOut.children).forEach(
                (img: HTMLElement) => {
                    img['src'] = GoogleMapCustomControl.zoomOutControllSrc;
                    img['style'].width = '20px';
                    img['style'].height = '20px';
                }
            );
        }
        const gmnoprintButtonZoomIn = document.querySelector('.gmnoprint.gm-bundled-control > .gmnoprint > div > button:nth-child(3)');
        if (gmnoprintButtonZoomIn) {
            Array.from(gmnoprintButtonZoomIn.children).forEach(
                (img: HTMLElement) => {
                    img['src'] = GoogleMapCustomControl.zoomInControllSrc;
                    img['style'].width = '20px';
                    img['style'].height = '20px';
                }
            );
        }
        const fullscreenButton = document.querySelector('.gm-style > .gm-control-active.gm-fullscreen-control');
        if (fullscreenButton) {
            Array.from(fullscreenButton.children).forEach(
                (img: HTMLElement) => {
                    img['src'] = GoogleMapCustomControl.fullscreenControlSrc;
                    img['style'].width = '20px';
                    img['style'].height = '20px';
                }
            );
        }
        const mapSettings = this.googleMapDrawService.styleMapTypes(this.map, this.zipLayersCurrentZoom, this.zipLayersShown,
            this.zipMarkers, this.kmlLayerCurrentZoom, this.kmlLayersShown, this.kmlList);
        if (mapSettings) {
            this.zipLayersCurrentZoom = mapSettings.zip.zipLayersCurrentZoom;
            this.zipLayersShown = mapSettings.zip.zipLayersShown;
            this.zipMarkers = mapSettings.zip.zipMarkers;
            this.kmlLayerCurrentZoom = mapSettings.boundary.kmlLayerCurrentZoom;
            this.kmlLayersShown = mapSettings.boundary.kmlLayersShown;
            this.kmlList = mapSettings.boundary.kmlList;
        }
    }
}
