<div style="height: calc(100% - 64px);">
    <div class="search-content d-flex align-items-center">
        <div *ngIf="isEditable" class="button-component utility-green-bg-color mr-4" (click)="onAddEditAddress(null)">
            <div class="inner inner-green user-no-select" style="padding: 5px 23px;">
                <span class="color-white ml-0">{{ 'Rer.clients.addEdit.address.addAddress' | translate }}</span>
            </div>
        </div>
        <div class="datatTableFilter p-2 d-flex align-items-center flex-grow-1">
            <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" class="flex-grow-1"
                placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
        </div>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort class="h-100">
        <ng-container matColumnDef="reference_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.reference_date' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element1">{{ element1.reference_date }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.code' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element2" (click)="onAddEditAddress(element2.id)" title="{{ 'edit' | translate }}"
                class="pointer">{{ element2.code }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.name' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element3" (click)="onAddEditAddress(element3.id)" title="{{ 'edit' | translate }}"
                class="pointer">{{ element3.name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="city">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.city' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element4">{{ element4.city }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="division">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.division' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element5">{{ element5.division }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="street">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.street' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element6">{{ element6.street }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="street_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.street_number' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element7">{{ element7.street_number }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.description' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element8">{{ element8.description }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="objects_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.objects_number' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element9" style="justify-content: center!important;">
                {{ element9.objects_number }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rer_area_id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.area' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element10" style="justify-content: center!important;">
                {{ getAreaName(element10.rer_area_id) }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="is_default">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.address.dataTable.is_default' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element10" style="justify-content: center!important;">
                <i *ngIf="element10.is_default" class="icon-cts-selection-radio-on color-green-primary font-size-18"></i>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let rowIndex = index"
                style="justify-content: flex-end!important;">
                <i *ngIf="isEditable" (click)="onDeleteAddress(element.id)" title="{{ 'delete' | translate }}"
                    class="icon-tmss-list-action-delete font-size-14 pointer"></i>
                <i *ngIf="isEditable" (click)="onAddEditAddress(element.id)" title="{{ 'edit' | translate }}"
                    class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
        </mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>