<div class="search-content d-flex align-items-center">
    <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerCollectionByWeight.quickSearch' | translate }}">
    </div>
    <div class="col-12 mb-4 mb-sm-0 col-sm-6 col-md-2 pl-2 ">
        <div class="button-component utility-green-bg-color ml-2" (click)="loadReportsByPeriod()"
            style="width: fit-content; float: right;" >
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4 height-42">
                <span class="color-white ml-0">{{ 'Rer.rerCollectionByWeight.loadReports' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 190px)" class="ml-2">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.id' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            #{{ element.id }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="client_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.client' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.client_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="device_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.device' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.device_name | translate }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.quantity' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.quantity || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.waste_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.waste_type || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="datetime_sent">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.datetime_sent' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{ element.datetime_sent | offset: true | date: dateFormat + ' HH:mm:ss' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="email_sent">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.email_sent' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.email_sent || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="period_begin">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.period_begin' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.period_begin  | offset: true | date: dateFormat + ' HH:mm:ss' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="period_end">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.period_end' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
                element.period_end  | offset: true | date: dateFormat + ' HH:mm:ss' }}
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell class="d-flex justify-content-center" *matHeaderCellDef>{{ 'Rer.rerCollectionByWeight.actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="d-flex justify-content-center">
            <i title="{{ 'download' | translate }}" class="ml-2 mr-2 icon-tmss-list-action-download pointer"
            (click)="downloadReport(element.id)">
        </i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
