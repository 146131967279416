import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditComponent implements OnInit {

    public showMandatory: boolean;
    public showSpinner = false;

    constructor(
        public dialogRef: MatDialogRef<AddEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.showMandatory = false;
    }

    public onAdd(): void {
        this.showMandatory = this.data.rfid ? false : true;
        if (!this.showMandatory) {
            this.dialogRef.close(this.data);
        }
    }

    public checkValue(event): void {
        this.data.rfid = event != null ? event.toUpperCase().replace(/([^0-9A-Z])/g, ''): '';
    }
}
