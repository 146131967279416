<div class="bgcolor-white p-2 m-4 position-relative form-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms" [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="selectedTabIndex = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-partners"></i>
                <span class="ml-1 mr-2">{{ 'Rer.clients.label' | translate }}</span>
                <span (click)="addNewClient()"
                    class="utility-green-bg-color d-flex align-items-center justify-content-center ml-1"
                    style="width: 24px; height: 24px;">
                    <i class="icon-cts-action-add font-size-12 color-white"></i>
                </span>
            </ng-template>
            <app-list (deleteClientEventEmitter)="deleteClient($event)" [openTabsNumber]="clientTabs.length"
                (editClientEventEmitter)="editClient($event)" (loadClientListEventEmitter)="closeAllTabs()">
            </app-list>
        </mat-tab>
        <mat-tab *ngFor="let client of clientTabs; let i = index">
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-partners"></i>
                <span class="ml-1 d-flex align-items-center">{{ client.id ? client.name : ('Rer.clients.newClient' |
                    translate) }}
                    <i class="icon-tmss-tab-close font-size-14 ml-1" (click)="onCloseTab(i)"></i>
                </span>
            </ng-template>
            <app-add-edit [client]="client"></app-add-edit>
        </mat-tab>
    </mat-tab-group>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>