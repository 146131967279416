<div class="search-content">
    <div class="row mt-2 w-100 mx-0">
        <div class="col-6 pr-0">
            <div class="row w-100">
                <div class="col-3 pr-0 pl-0">
                    <div class="ng-select-wrapper w-100">
                        <ng-select [items]="typeFilterList$ | async" placeholder="{{ 'Rer.selectType' | translate }}"
                            appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                            [(ngModel)]="selectedTypeList">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <ng-container *ngFor="let item of items; let i=index">
                                    <div class="ng-value" *ngIf="i < 2 && item">
                                        <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-container>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                                    <span class="ml-2">{{ item }}</span>
                                </mat-checkbox>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-3 pr-0">
                    <div class="ng-select-wrapper w-100">
                        <ng-select [items]="cityFilterList$ | async" placeholder="{{ 'Rer.selectCity' | translate }}"
                            appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                            [(ngModel)]="selectedCityList">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <ng-container *ngFor="let item of items; let i=index">
                                    <div class="ng-value" *ngIf="i < 2 && item">
                                        <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-container>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                                    <span class="ml-2">{{ item }}</span>
                                </mat-checkbox>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-3 pr-0">
                    <div class="ng-select-wrapper w-100">
                        <ng-select [items]="divisionFilterList$ | async"
                            placeholder="{{ 'Rer.selectDivision' | translate }}" appendTo="body" [multiple]="true"
                            [clearable]="true" [closeOnSelect]="false" [(ngModel)]="selectedDivisionList">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <ng-container *ngFor="let item of items; let i=index">
                                    <div class="ng-value" *ngIf="i < 2 && item">
                                        <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-container>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                                    <span class="ml-2">{{ item }}</span>
                                </mat-checkbox>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="col-3 pr-0">
                    <div class="ng-select-wrapper w-100">
                        <ng-select [items]="streetFilterList$ | async"
                            placeholder="{{ 'Rer.selectStreet' | translate }}" appendTo="body" [multiple]="true"
                            [clearable]="true" [closeOnSelect]="false" [(ngModel)]="selectedStreetList">
                            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                <ng-container *ngFor="let item of items; let i=index">
                                    <div class="ng-value" *ngIf="i < 2 && item">
                                        <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                            aria-hidden="true">×</span>
                                    </div>
                                </ng-container>
                                <div class="ng-value" *ngIf="items.length > 2">
                                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                                </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                                    <span class="ml-2">{{ item }}</span>
                                </mat-checkbox>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 pl-0 pr-0">
            <div class="row w-100 mx-0">
                <div class="col-2 px-0">
                    <div class="button-component utility-green-bg-color" (click)="onLoadList()">
                        <div class="inner inner-green user-no-select pb-2 pt-2 pl-3 pr-3">
                            <span class="color-white ml-0">{{ 'Rer.loadList' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-6 pr-0">
                    <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
                        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue"
                            (keyup)="applyFilter($event.target.value)" class="flex-grow-1"
                            placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
                    </div>
                </div>
                <div class="col-4 pr-1">
                    <div class="button-component utility-blue-bg-color"
                        (click)="onRecoverDeleted()">
                        <div class="inner inner-blue user-no-select pb-2 pt-2 pl-3 pr-3">
                            <span class="color-white ml-0">{{ 'Rer.clients.list.recoverDeletedClient' | translate
                                }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.type' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.code' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onEditClient(element.id)" title="{{ 'edit' | translate }}"
            class="pointer">{{ element.code }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onEditClient(element.id)" title="{{ 'edit' | translate }}"
            class="pointer">{{ element.name}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center!important;">
            {{ 'Rer.clients.list.dataTable.status' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" style="justify-content: center!important;">
            {{ 'Rer.clients.addEdit.status.' + (element.status | rerStatusTransform:'client') | translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="division">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.division' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="ml-2">{{ element.division }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.city' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.city }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="street">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.street' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.street }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="street_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.streetNumber' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.street_number }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.phone' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.phone }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="object_types_at_default_address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.list.dataTable.objects' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.object_types_at_default_address | elipsizeText:3 }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
            <i *ngIf="element.status === 4" (click)="onDeleteClient(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
            <i (click)="onEditClient(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute"
    style="height: 100%; top: 0; width: calc(100% - 100px);">
    <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
</div>