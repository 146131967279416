import { Component, Inject, OnInit, ViewChild, ElementRef, Input, } from '@angular/core';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import * as moment from 'moment';
import { RerService } from '../../../_service/rer.service';
import { faFastForward, faFastBackward } from '@fortawesome/free-solid-svg-icons';
import { RerIncident } from 'app/modules/rer/_model/rerincident.model';
import { IAlbum, Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { RerPlanning } from 'app/modules/rer/_model/rerplanning.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

    public showMandatory: boolean;
    public date = moment().format('YYYY-MM-DD HH:mm:ss');
    public planning: RerPlanning;
    private subscription: Subscription;
    public showSpinner = true;
    public noVideoOrError = false;
    public dateFormat;
    public faFastForward = faFastForward;
    public faFastBackward = faFastBackward;
    public imageObject: IAlbum[] = [];
    public firstImage;
    public backSkip = 1;
    public nextSkip = 1;
    public openedIndex;
    public isOpenedLightBox = false;
    public cameraId;

    @Input() incident: RerIncident;
    @ViewChild("videoStream", { static: true }) videoStreamElement: ElementRef;

    constructor(
        private lightbox: Lightbox,
        private lightboxEvent: LightboxEvent,
        private rerService: RerService,
        private userPreferencesService: UserPreferencesService
    ) {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        }
        
        ngOnInit() {
            this.cameraId = this.incident.ipcameras[0];
            this.rerService.getRerRfidReadingImage(this.incident.device_id, this.rerService.setDateOffset(this.incident.incident_time), this.cameraId).subscribe(result => {
                const reader = new FileReader();
                reader.readAsDataURL(result.body);
                reader.onload = _event => {
                    this.showSpinner = false;
                    let url = reader.result;
                    this.firstImage = {
                        src: url,
                        thumb: url,
                        order: 0,
                        first: true
                    };
                    this.imageObject.push(this.firstImage);
                    this.getNextPrev(0);
                    this.getNextPrev(1);
                };
            }, error => {
                this.noVideoOrError = true;
                this.showSpinner = false;
            });
        }
    
        openImage(event: any) {
            if (event == 0) {
                this.getNextPrev(0);
            }
            if (event == 2) {
                this.getNextPrev(1);
            }
        }
    
        open(): void {
            let index = this.imageObject.findIndex(x => x['first'] == true);
            if(this.isOpenedLightBox) {
                index = this.openedIndex ? this.openedIndex : index;
                this.subscription.unsubscribe();
            }
            this.lightbox.open(this.imageObject, index, { showDownloadButton: true, showZoom: true });
            this.subscription = this.lightboxEvent.lightboxEvent$.subscribe(event => this.onReceivedEvent(event));
            this.isOpenedLightBox = true;
        }
    
        private onReceivedEvent(event: any): void {
            if (event.id === LIGHTBOX_EVENT.CLOSE) {
              this.subscription.unsubscribe();
              this.isOpenedLightBox = false;
              this.openedIndex = 0;
            }
        
            if (event.id === LIGHTBOX_EVENT.OPEN) {
            }
        
            if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
                if(event.data == 0) {
                    this.getNextPrev(0);
                }
                if(event.data == this.imageObject.length - 1 ) {
                    this.getNextPrev(1);
                }
                this.openedIndex = event.data;
            }
          }
    
        close(): void {
            this.lightbox.close();
        }
    
        public getNextPrev(next: number) {
            let skip = next ? this.nextSkip : this.backSkip;
            let direction = next;
            skip = Math.abs(skip);
            this.rerService.getNextPrevImage(this.incident.device_id, this.rerService.setDateOffset(this.incident.incident_time), this.cameraId, skip, direction).subscribe(result => {
                const reader = new FileReader();
                reader.readAsDataURL(result.body);
                reader.onload = _event => {
                    this.showSpinner = false;
                    let url = reader.result;
                    if (next) {
                        this.imageObject.push({
                            src: url.toString(),
                            thumb: url.toString()
                        });
                        this.nextSkip += 1;
                    } else {
                        this.imageObject.unshift({
                            src: url.toString(),
                            thumb: url.toString()
                        });
                        this.backSkip += 1;
                    }
                    if(this.isOpenedLightBox) {
                        this.open();
                    }
                    let currentOrder = 1;
                    this.imageObject.forEach(element => {
                        element['id'] = currentOrder;
                        currentOrder += 1;
                    });
                };
            });
        }
    
        public getLocale(): String {
            return this.rerService.getLocale();
        }
}