import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';

import { RerBin } from '../../_model/rerbin.model';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit {
    public unallocatedBinList$: Observable<RerBin[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean;
    public currentFilterValue: string = null;
    public dateFormat: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Input() openTabsNumber: number;

    @Output() editRerBinEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() deleteRerBinEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() loadRerBinEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(
        private rerService: RerService,
        public dialog: MatDialog,
        private userPreferencesService: UserPreferencesService,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;

        this.displayedColumns = [
            'id', 'waste_type', 'bin_type', 'status', 'rer_rfid', 'description', 'coords', 'datetime_read', 'vehicle_name', 'actions'
        ];
        this.unallocatedBinList$ = this.rerService.unallocatedObjectList$;
        this.unallocatedBinList$.subscribe(
            (clientObjects: RerBin[]) => {
                this.dataSource = new MatTableDataSource(clientObjects);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onEditClientObject(rowIndex: number): void {
        this.editRerBinEventEmitter.emit(rowIndex);
    }

    public onDeleteClientObject(rowIndex: number): void {
        this.deleteRerBinEventEmitter.emit(rowIndex);
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    private getUnallocatedObjectList(): void {
        this.showSpinner = true;
        this.unallocatedBinList$.subscribe(
            (clientObjects: RerBin[]) => {
                this.dataSource = new MatTableDataSource(clientObjects);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            },
            () => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            }
        );
    }

    public onLoadList(): void {
        if (this.openTabsNumber) {
            const dialogRef = this.dialog.open(
                ConfirmActionComponent,
                {
                    width: '450px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        headerMessage: this.translationService.instant('Rer.confirmCloseTabs.title'),
                        contentMessage: this.translationService.instant('Rer.confirmCloseTabs.msg')
                    }
                }
            );
            dialogRef.afterClosed().subscribe(
                (response) => {
                    if (response) {
                        this.loadRerBinEventEmitter.emit();
                        this.getUnallocatedObjectList();
                    }
                }
            );
        } else {
            this.getUnallocatedObjectList();
        }
    }
}
