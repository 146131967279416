import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../../shared/shared.module';
import { RerCollectionByWeightComponent } from './rer-collection-by-weight.component';
import { RouterModule } from '@angular/router';

import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { GoogleMapDrawService } from 'app/modules/service/shared/googlemapdraw.service';
import { HistoryService } from 'app/modules/service/map/history.service';
import { GoogleapisService } from 'app/modules/service/shared/googleapis.service';
import { GoogleMapCustomControl } from 'app/modules/service/shared/googlemapcustomcontrol.service';
import { GoogleKmlService } from 'app/modules/service/map/googlekmlservice.service';
import { MarkerIconService } from 'app/modules/service/shared/markericon.service';
import { GoogleMapTileService } from 'app/modules/service/shared/googlemaptile.service';
import { PositionSocketService } from 'app/modules/service/map/position-socket.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RerReportsSentAutomaticallyComponent } from './rer-reports-sent-automatically/rer-reports-sent-automatically.component';
import { RerGeneralReportsComponent } from './rer-general-reports/rer-general-reports.component';
import { RerOperationOfWeighingReceiptsComponent } from './rer-operation-of-weighing-receipts/rer-operation-of-weighing-receipts.component';
import { RerUnloadingAreasComponent } from './rer-unloading-areas/rer-unloading-areas.component';
import { FilterReportCollectionByWeightClientDialogComponent } from './rer-general-reports/filter-report-collecton-by-weight-client-dialog/filter-report-collection-by-weight-client-dialog.component';
import { FilterReportCollectionByWeightInDetailDialogComponent } from './rer-general-reports/filter-report-collection-by-weight-in-detail-dialog/filter-report-collection-by-weight-in-detail-dialog.component';
import { AddEditUnloadingAreaComponent } from './rer-unloading-areas/add-edit-unloading-area/add-edit-unloading-area.component';
import { EditWeighingReceiptComponent } from './rer-operation-of-weighing-receipts/edit-weighing-receipt/edit-weighing-receipt.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' },
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        RerCollectionByWeightComponent,
        RerReportsSentAutomaticallyComponent,
        RerGeneralReportsComponent,
        RerUnloadingAreasComponent,
        RerOperationOfWeighingReceiptsComponent,
        FilterReportCollectionByWeightClientDialogComponent,
        FilterReportCollectionByWeightInDetailDialogComponent,
        AddEditUnloadingAreaComponent,
        EditWeighingReceiptComponent
    ],
    imports: [
        NgxMaterialTimepickerModule,
        CommonModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        RouterModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: [
        GoogleMapDrawService,
        HistoryService,
        GoogleapisService,
        GoogleMapCustomControl,
        GoogleKmlService,
        MarkerIconService,
        GoogleMapTileService,
        PositionSocketService,
        GoogleapisService
    ]
})
export class RerCollectionByWeightModule { }