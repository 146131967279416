<ul class="navbar-nav mr-auto">
    <li *ngFor="let item of menuitems" class="nav-item d-none d-md-block d-lg-block d-xl-block">
        <a [routerLink]="[{ outlets: { rercontent: [item.route] } }]" routerLinkActive="menu-active"
            [routerLinkActiveOptions]="{exact: false}" class="{{ item.fontoff }} nav-link"
            matTooltip="{{ ('Rer.' + item.text + '.label') | translate }}" matTooltipPosition="right"
            (click)="onModuleClick(item.route)">
        </a>
    </li>
    <li class="nav-item d-none d-md-block d-lg-block d-xl-block" *ngIf="hasPowerBiRight">
        <a [routerLink]="[{ outlets: { rercontent: powerBiRoute } }]" routerLinkActive="menu-active"
            [routerLinkActiveOptions]="{exact: false}" matTooltip="{{ ('Rer.rerPowerBiReport.label') | translate }}"
            matTooltipPosition="right">
            <img src="{{ powerBiLinkSrc }}" class="nav-link" style="height: 40px;width: 40px; margin: auto;"></a>
    </li>
</ul>
