export class HistoryMessage {

    constructor(
        public date_send: string,
        public date_read: string,
        public from_user_id: number,
        public id: number,
        public message: string,
        public to_user_id: number,
        public from_real_user_id: number,
        public is_sms: boolean,
        public user_file_id: number,
        public state_received: boolean,
        public name: string,
        public show_date: boolean,
        public real_user_from
    ) { }
}
