<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    <span [ngClass]="{ 'text-danger': data.success === 0 }">
        {{ data.headermessage }}
    </span>
</h4>
<div *ngIf="!data.innerHtml" mat-dialog-content class="p-4 content">
    {{ data.message }}
</div>
<div *ngIf="data.innerHtml" [innerHTML]="data.innerHtml" mat-dialog-content class="p-4 content"></div>
<div mat-dialog-actions class="mt-4 p-3">
    <div class="buttons d-flex align-items-center justify-content-center pt-3">
        <div
            class="button-component"
            [ngClass]="{ 'utility-green-bg-color': data.success === 1, 'utility-bg-error': data.success === 0 }"
            (click)="dialogRef.close(false)">
            <div
                class="inner user-no-select pb-2 pt-2 pl-4 pr-4"
                [ngClass]="{ 'inner-green': data.success === 1, 'inner-red': data.success === 0 }">
                <span class="color-white ml-0">{{ 'ok' | translate }}</span>
            </div>
        </div>
    </div>
</div>
