<div class="search-content d-flex align-items-center">
    <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerCollectionByWeight.quickSearch' | translate }}">
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 190px)" class="ml-2">
    <ng-container matColumnDef="receipt_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.receipt_number' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            #{{ element.id }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="operated_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.operated_at' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.created_at | offset: false | date: dateFormat + ' HH:mm:ss' | translate }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_unloading_zone_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.rer_unloading_zone_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.rer_unloading_zone_name  }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="device_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.device_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.device_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.operated_quantity' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.operated_quantity  }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="calculated_quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.registered_quantity' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.computed_quantity  }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="difference_quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.difference_quantity' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.operated_quantity - element.computed_quantity }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="unallocated_quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.unallocated_quantity' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.unallocated_quantity }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.waste_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.waste_type || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="fully_allocated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.weighingReceipt.fully_allocated' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.is_fully_allocated ? ('yes' | translate) :  ('no' | translate)  }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell class="d-flex justify-content-center" *matHeaderCellDef>{{ 'Rer.rerCollectionByWeight.actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="d-flex justify-content-center">
            <i (click)="editWeighingReceipt(element.id)" title="{{ 'Rer.rerCollectionByWeight.weighingReceipt.editArea' | translate}}" class="ml-2 mr-2 icon-tmss-list-action-edit pointer">
            </i>
            <i *ngIf="element.proof" title="{{ 'download' | translate }}" class="ml-2 mr-2 icon-tmss-list-action-download pointer"
            (click)="downloadProof(element.id)"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>