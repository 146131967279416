import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { AuthService } from '../../../service/auth.service';
import { ClientPartner } from '../../model/clientpartner.model';
import { EndpointsService } from '../../../service/endpoints.service';

@Injectable()
export class ClientPartnerService {
    private _clientPartners: ClientPartner[] = [];
    private mandatoryClientPartnerProperties = ['name', 'address', 'country_code', 'vat_number', 'price_per_km', 'email', 'phone'];

    private patternClientPartnerProperties = [
        { 'key': 'email', 'pattern': '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' },
        { 'key': 'phone', 'pattern': '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$' }
    ];

    public countryCodes = [
        { value: 'al', label: 'Albania' },
        { value: 'dz', label: 'Algeria' },
        { value: 'ad', label: 'Andorra' },
        { value: 'uk', label: 'Anglia' },
        { value: 'am', label: 'Armenia' },
        { value: 'at', label: 'Austria' },
        { value: 'az', label: 'Azerbaidjan' },
        { value: 'by', label: 'Belarus' },
        { value: 'be', label: 'Belgia' },
        { value: 'ba', label: 'Bosnia şi Herţegovina' },
        { value: 'bg', label: 'Bulgaria' },
        { value: 'cz', label: 'Cehia' },
        { value: 'cy', label: 'Cipru' },
        { value: 'hr', label: 'Croatia' },
        { value: 'dk', label: 'Danemarca' },
        { value: 'eg', label: 'Egipt' },
        { value: 'ch', label: 'Elvetia' },
        { value: 'ee', label: 'Estonia' },
        { value: 'fi', label: 'Finlanda' },
        { value: 'fr', label: 'Franta' },
        { value: 'ge', label: 'Georgia' },
        { value: 'de', label: 'Germania' },
        { value: 'gr', label: 'Grecia' },
        { value: 'ie', label: 'Irlanda' },
        { value: 'is', label: 'Islanda' },
        { value: 'it', label: 'Italia' },
        { value: 'kz', label: 'Kazahstan' },
        { value: 'lt', label: 'Lituania' },
        { value: 'lv', label: 'Letonia' },
        { value: 'li', label: 'Liechtenstein' },
        { value: 'lu', label: 'Luxembourg' },
        { value: 'mt', label: 'Malta' },
        { value: 'ma', label: 'Maroc' },
        { value: 'mc', label: 'Monaco' },
        { value: 'me', label: 'Muntenegru' },
        { value: 'no', label: 'Norvegia' },
        { value: 'nl', label: 'Olanda' },
        { value: 'pl', label: 'Polonia' },
        { value: 'pt', label: 'Portugalia' },
        { value: 'mk', label: 'Republica Macedonia' },
        { value: 'md', label: 'Republica Moldova' },
        { value: 'ro', label: 'Romania' },
        { value: 'ru', label: 'Rusia' },
        { value: 'sm', label: 'San Marino' },
        { value: 'gb', label: 'Scotia' },
        { value: 'rs', label: 'Serbia' },
        { value: 'sk', label: 'Slovacia' },
        { value: 'si', label: 'Slovenia' },
        { value: 'es', label: 'Spania' },
        { value: 'se', label: 'Suedia' },
        { value: 'tn', label: 'Tunisia' },
        { value: 'tr', label: 'Turcia' },
        { value: 'ua', label: 'Ucraina' },
        { value: 'hu', label: 'Ungaria' },
        { value: 'va', label: 'Vatican' }
    ];

    public bankOptions = [
        { value: '1', label: 'Alpha Bank' },
        { value: '2', label: 'Bancpost' },
        { value: '3', label: 'BCR' },
        { value: '4', label: 'BRD – Groupe Société Générale' },
        { value: '5', label: 'Banca Românească - National Bank of Greece Group' },
        { value: '6', label: 'Banca Transilvania' },
        { value: '7', label: 'Bank Leumi' },
        { value: '8', label: 'Blom Bank' },
        { value: '9', label: 'BNP Paribas Fortis' },
        { value: '10', label: 'CEC Bank' },
        { value: '11', label: 'Citibank' },
        { value: '12', label: 'Crédit Agricole' },
        { value: '13', label: 'Credit Europe Bank' },
        { value: '14', label: 'EximBank' },
        { value: '15', label: 'Garanti Bank' },
        { value: '16', label: 'Idea Bank' },
        { value: '17', label: 'ING Bank' },
        { value: '18', label: 'Intesa Sanpaolo' },
        { value: '19', label: 'Libra Internet Bank' },
        { value: '20', label: 'Marfin Bank' },
        { value: '21', label: 'OTP Bank' },
        { value: '22', label: 'Patria Bank' },
        { value: '23', label: 'Piraeus Bank' },
        { value: '24', label: 'Porsche Bank' },
        { value: '25', label: 'ProCredit Bank' },
        { value: '26', label: 'Raiffeisen Bank' },
        { value: '27', label: 'Unicredit Bank' }
    ];

    public partnersType = [
        {
            value: 'collaborator',
            label: 'TMS.clientPartner.collaborator'
        }
    ];

    public get clientPartners(): ClientPartner[] {
        return this._clientPartners;
    }

    public clientPartnerListChangedSubject = new Subject<ClientPartner[]>();
    public clientPartnerAddedChangedSubject = new Subject<number>();
    public clientPartnerChangedSubject = new Subject<void>();

    constructor(private httpClient: HttpClient,
        private authService: AuthService,
        private translateService: TranslateService,
        private endpointsService: EndpointsService) {
        this.getClientPartners();
    }

    public getClientPartners() {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
        }
        this.httpClient.get(
            this.endpointsService.get('clientpartner.list')
        ).subscribe(
            (clientPartners: ClientPartner[]) => {
                this._clientPartners = clientPartners;
                this.clientPartnerListChangedSubject.next(this._clientPartners);
            }
        );
    }

    public createClientPartner(clientPartner: ClientPartner) {
        return this.httpClient.post(this.endpointsService.get('clientpartner.create'), clientPartner);
    }

    public addClientPartner(clientPartner: ClientPartner) {
        this._clientPartners.push(clientPartner);
        this._clientPartners.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.clientPartnerListChangedSubject.next(this._clientPartners);
    }

    public editClientPartner(clientPartner: ClientPartner) {
        return this.httpClient.put(this.endpointsService.get('clientpartner.update', [clientPartner.id]), clientPartner);
    }

    public updateClientPartner(clientPartner: ClientPartner) {
        const client = this._clientPartners.find(clpr => clpr.id === clientPartner.id);
        Object.keys(client).forEach((key) => client[key] = clientPartner[key]);
        this._clientPartners.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.clientPartnerListChangedSubject.next(this._clientPartners);
    }

    public deleteClientPartner(id: number) {

        return this.httpClient.delete(this.endpointsService.get('clientpartner.delete', [id]));
    }

    public removeClient(id) {
        for (const prop in this._clientPartners) {
            if (+this._clientPartners[prop].id === +id) {
                this._clientPartners.splice(parseInt(prop, 10), 1);
                break;
            }
        }
        this.clientPartnerListChangedSubject.next(this._clientPartners);
    }

    public getClientPartnerById(id) {
        const clientPartner = this._clientPartners.find(client => client.id === +id);
        return clientPartner;
    }

    /*
    *  start
    *  new eTms methods
    */

    public getMandatoryClientPartnerProperties(): string[] {
        return this.mandatoryClientPartnerProperties;
    }

    public canSaveClientPartner(partner: ClientPartner): boolean {
        let result = true;
        for (let i = 0; i < this.mandatoryClientPartnerProperties.length; i++) {
            if (partner[this.mandatoryClientPartnerProperties[i]] === undefined ||
                partner[this.mandatoryClientPartnerProperties[i]] === null ||
                (typeof partner[this.mandatoryClientPartnerProperties[i]]) === 'string' &&
                (partner[this.mandatoryClientPartnerProperties[i]].length === 0 ||
                    partner[this.mandatoryClientPartnerProperties[i]].trim().length === 0)) {

                result = false;
                break;
            }
        }
        return result;
    }

    public getClientPartnerBank(index: string) {
        const bank = this.bankOptions.find(bankOp => bankOp.value === index);
        return bank.label;
    }

    public getCountry(index) {
        const country = this.countryCodes.find(searchCountry => searchCountry.value === index);
        return country.label;
    }

    public canSaveClientPartnerPattern(clientPartner: ClientPartner): any {
        const result = {};
        for (let i = 0; i < this.patternClientPartnerProperties.length; i++) {
            if (!RegExp(this.patternClientPartnerProperties[i].pattern, 'i')
                .test(clientPartner[this.patternClientPartnerProperties[i].key])) {
                result[this.patternClientPartnerProperties[i].key] = true;
            }
        }
        return result;
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);
        return result;
    }

    /*
    *  end
    *  new eTms methods
    */
    public getClientParnerDataVies(vatNumber: string, countryCode: string): Observable<any> {

        return this.httpClient.get(this.endpointsService.get('clientpartner.viesVatChecker', [countryCode, vatNumber]));
    }

    public verifyClientPartnerName(name: string, clientId: number): boolean {
        if (this.clientPartners.find(partner => (clientId !== partner.id && partner.name.toLowerCase() === name.toLowerCase()))) {
            return true;
        }

        return false;
    }

}
