<div class="h-100 canvas-background" [ngStyle]="{'background-image': 'url(' + background + ' )'}">
	<div class="signin-modal-dialog signin-cascading-modal">
		<div class="shape">
			<div class="modal-content">
				<div class="modal-header m-0">
					<div class="container-fluid">
						<div class="row">
							<div class="col p-0">
								<img class="img-fluid pull-left" src="{{ logoSrc }}">
							</div>
							<div class="col d-flex align-items-center justify-content-end">
                                <div class="ng-select-wrapper select-top-25 w-100 ml-4 mr-3 arrow-botton-12 float-right p-0">
                                    <ng-select  name="language" [(ngModel)] = "currentLanguage"
                                        [ngModelOptions]="{standalone: true}" [clearable]="false"
                                        (change)="switchLanguage($event)">
                                        <ng-option [value]="'en_US'">
                                            {{ 'SignIn.en' | translate }} </ng-option>
                                        <ng-option [value]="'de_DE'">
                                            {{ 'SignIn.de' | translate }} </ng-option>
                                        <ng-option [value]="'ro_RO'">
                                            {{ 'SignIn.ro' | translate }} </ng-option>
                                        <ng-option [value]="'it_IT'">
                                            {{ 'SignIn.it' | translate }} </ng-option>
                                    </ng-select>
                                </div>
                                <fa-icon (click)="resetApplication()" class="pointer color-orange text-center" [icon]="faPowerOff"></fa-icon>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-body">
					<div class="d-flex justify-content-between p-2">
						<div class="align-self-center">
							<p class="sign-in-text" >sign in</p>
							<p class="sign-in-more">{{ 'SignIn.signin' | translate }}</p>
						</div>
						<div class="align-self-center hide">
							<i class="icon-ctl-nav-fleet-on font-size-90 color-grey"></i>
						</div>
                    </div>

					<form (ngSubmit)="login()" #f="ngForm" autocomplete="off" class="sign-in-form">
						<div class="container-fluid">
                            <mat-form-field class="w-100">
                                <input
                                    matInput
                                    name="username"
                                    #username="ngModel"
                                    ngModel
                                    id="username"
                                    required
                                    (ngModelChange)="wrongCredentials=false"
                                    placeholder="{{ 'SignIn.username' | translate }}"
                                    [ngClass]="{'invalid' : !username.valid && username.touched, 'active': username.value}"
                                    >
                            </mat-form-field>
                            <mat-form-field class="w-100 mt-3">
                                <input
                                    [type]="hide ? 'password' : 'text'"
                                    name="password"
                                    matInput
                                    #password="ngModel"
                                    id="password"
                                    ngModel
                                    (ngModelChange)="wrongCredentials=false"
                                    placeholder="{{ 'SignIn.password' | translate }}"
                                    required
                                    [ngClass]="{'invalid' : password.touched && !password.valid, 'active': username.value}"
                                >
                                <mat-icon matSuffix (click)="hide = !hide"><fa-icon [icon]="hide ? faEyeSlash : faEye" class="password-state-icon"></fa-icon></mat-icon>
                            </mat-form-field>
							<div class="d-flex justify-content-between signin">
                                <div class="hide">
                                    <div class="text-center mt-2">
                                        <mat-checkbox
                                            [(ngModel)]="rememberme"
                                            name="rememberme">
                                            {{ 'remember' | translate }}
                                        </mat-checkbox>
                                    </div>
                                    <div class="text-center mt-2">
                                        <button id="forgotPass" type="button" (click)="openForgotPassModal()">
                                            {{ 'forgotPassword' | translate }}
                                        </button>
                                    </div>
                                </div>
								<div class="text-center mt-2">
									<button type="submit" [disabled]="!f.valid" class="btn button utility-green-bg-color">Sign in</button>
								</div>
							</div>
						</div>
                    </form>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="hasFacebookPixelAccount">
    <noscript>
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=505699036957910&ev=PageView&noscript=1"/>
    </noscript>
</div>


