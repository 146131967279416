<div class="search-content d-flex align-items-center">
    <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerCollectionByWeight.quickSearch' | translate }}">
    </div>
    <div class="col-12 mb-4 mb-sm-0 col-sm-6 col-md-2 pl-2 mr-4">
        <div class="button-component utility-green-bg-color ml-2" (click)="addEditUnloadingArea(0)"
            style="width: fit-content; float: right;" >
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4 height-42">
                <span class="color-white ml-0">{{ 'Rer.rerCollectionByWeight.unloadingArea.addArea' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 190px)" class="ml-2">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.address' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.address.address_formatted | translate }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.type  }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="center_latitude">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.center_latitude' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{ element.center_latitude }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="center_longitude">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.center_longitude' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.center_longitude  }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="radius">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.radius' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.radius }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="coords">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.coords' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.coords }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="precalc_values">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.precalc_values' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.precalc_values }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.created_at' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
                element.created_at | offset: false | date: dateFormat + ' HH:mm:ss' }}
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_by">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerCollectionByWeight.unloadingArea.created_by' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
                element.username }}
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell class="d-flex justify-content-center" *matHeaderCellDef>{{ 'Rer.rerCollectionByWeight.actions' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" class="d-flex justify-content-center">
            <i (click)="addEditUnloadingArea(element.id)" title="{{ 'Rer.rerCollectionByWeight.unloadingArea.editArea' | translate}}" class="ml-2 mr-2 icon-tmss-list-action-edit pointer">
            </i>
            <fa-icon (click)="activateOrDeactivateArea(element.id)" title="{{ 'Rer.rerCollectionByWeight.unloadingArea.deactivateArea' | translate}}"[icon]="faCheck" class="color-green-primary pointer" *ngIf="element.active === 1"></fa-icon>
            <fa-icon (click)="activateOrDeactivateArea(element.id)" title="{{ 'Rer.rerCollectionByWeight.unloadingArea.activateArea' | translate}}"[icon]="faTimes" class="pointer" *ngIf="element.active === 0"></fa-icon>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
