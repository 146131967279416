import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { RerService } from '../../_service/rer.service';

import { RerRoute } from '../models/rer-route.model';
import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
  selector: 'app-route-item',
  templateUrl: './route-item.component.html',
  styleUrls: ['./route-item.component.scss']
})
export class RouteItemComponent {
  
  @Input() route: RerRoute;
  
  public showSpinner = false;
  
  constructor(private rerService: RerService, private actionDialog: MatDialog, private translationService: TranslateService) { }

  public onSaveRoute() {
    const route = {...this.route};
    route.street = undefined;
    this.showSpinner = true;
    this.rerService.saveRerRoute(route).subscribe({
      next:(id: number) => {
        this.route.id = id;
        this.showSpinner = false;
      },
      error:() => {
        this.showSpinner = false;
        this.actionDialog.open(
          NotificationComponent,
          {
              maxWidth: '800px', panelClass: 'custom-dialog-container',
              data: { success: 0, headermessage: this.translationService.instant('error'), message: this.translationService.instant('generalError') }
          }
        );
      }
    });
  }

}
