import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { RerService } from '../../../_service/rer.service';

import { RerClientStatusActions } from '../../../_constants/rerclientstatusConstants';
import { RerClient } from 'app/modules/rer/_model/rerclient.model';

import { ConfirmActionComponent } from '../../../../../shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit,OnDestroy {

    public showMandatory: boolean;
    public showSpinner: boolean;
    private _subscriptions: Subscription[] = [];

    @Input() client: RerClient;
    @Output() changeClientStatusEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(
        private rerService: RerService,
        public actionDialog: MatDialog,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.setSubscriptions();
    }

    private setSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.rerClientChangedSubject.subscribe(
                (response: boolean) => {
                    this.client = this.rerService.getRerClientById(this.client.id);
                }
            )
        );
    }

    public onSave(): void {
        this.showMandatory = (this.client.name && this.client.type && this.client.code && this.client.phone) ? false : true;

        if (!this.showMandatory) {
            this.showSpinner = true;
            this.rerService.addEditClient(this.client).subscribe(
                () => {
                    this.showSpinner = false;
                    this.rerService.showSaveConfirmation(
                        this.translationService.instant('dataSuccessfullySaved'));
                },
                () => {
                    this.showSpinner = false;
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            );
        }
    }

    public onSuspendForNonPayment() {
        this.editClientStatus(RerClient.STATUS_SUSPENDED_FOR_NON_PAYMENT);
    }

    public onSuspendOnRequest() {
        this.editClientStatus(RerClient.STATUS_SUSPENDED_ON_REQUEST);
    }

    public onTerminateContract() {
        this.editClientStatus(RerClient.STATUS_TERMINATED);
    }

    public onActivate() {
        this.editClientStatus(RerClient.STATUS_ACTIVE);
    }

    public handleFixedInvoicing(event) {
        this.client.is_fixed_invoicing = event.checked ? 1 : 0;
    }

    private editClientStatus(status: number) {
        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant(
                        'Rer.clients.addEdit.actions.' + RerClientStatusActions[status] + ''),
                    contentMessage: this.translationService.instant('Rer.clients.addEdit.confirmStatusChange')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (data) => {
                if (data) {
                    this.changeClientStatusEventEmitter.emit(status);
                }
            }
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

}
