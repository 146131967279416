<div class="search-content d-flex w-100">
    <div class="d-flex justify-content-end w-100 mb-2">
        <div class="button-component utility-green-bg-color flex-shrink-0 ml-2" (click)="addEditArea(0)">
            <div class="inner inner-green user-no-select ml-0 pb-2 pt-2 pl-4 pr-4">
                <span class="color-white">{{ 'Rer.rerSettings.addArea' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.dataTable.name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="value">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.dataTable.code' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.code }}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
            <i (click)="addEditArea(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
            <i (click)="deleteArea(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
        </mat-cell>
    </ng-container>
</mat-table>
<div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute"
    style="height: 100%; top: 0; width: calc(100% - 100px);">
    <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
</div>
