<h1 mat-dialog-title class="p-2">{{ data.messageCode | translate }}</h1>
<div mat-dialog-content class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <div class="p-3 d-flex align-items-center justify-content-center">
        {{ data.message | translate}}
    </div>
</div>
<div *ngIf="!data.autoclose" mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <span class="mr-3 eButton pointer primary pb-2 pt-2 pl-3 pr-3" (click)="onCancel()">
        {{ 'ok' }}
    </span>
</div>