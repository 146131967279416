<p class="m-0 color-dark-grey">
    <span class="font-family-montserrat font-size-13 text-uppercase font-weight-bold">
        {{ 'Rer.rerPlanning.list.planningsInterval' | translate }}
    </span>
    <span class="font-family-open-sans font-size-15">
        {{periodBegin}} - {{periodEnd}}
    </span>
</p>
<div class="search-content d-flex align-items-center mt-2">
    <div class="datatTableFilter mb-1 mr-1 p-2 d-flex align-items-center w-80 flex-grow-1">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerEmployee.list.quickSearch' | translate }}">
    </div>
    <div *ngIf="hasEditRight" class="button-component utility-green-bg-color flex-shrink-0 ml-2" (click)="editPlanning(0)">
        <div class="inner inner-green user-no-select ml-0 pb-2 pt-2 pl-4 pr-4">
            <span class="color-white">{{ 'Rer.rerPlanning.add_row' | translate }}</span>
        </div>
    </div>
    <div *ngIf="hasEditRight" class="button-component utility-green-bg-color flex-shrink-0 ml-2" (click)="copyPlanning()">
        <div class="inner inner-green user-no-select ml-0 pb-2 pt-2 pl-4 pr-4">
            <span class="color-white">{{ 'Rer.rerPlanning.copy_planning' | translate }}</span>
        </div>
    </div>
    <div class="button-component utility-green-bg-color ml-2 mb-1" (click)="loadArchivedPlannings()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'Rer.rerPlanning.list.loadArchive' | translate }}</span>
        </div>
    </div>
    <div class="button-component utility-green-bg-color ml-2 mb-1 mr-1" (click)="export()">
        <div class="inner inner-green user-no-select ml-0 pb-2 pt-2 pl-4 pr-4">
            <span class="color-white">{{ 'Rer.rerPlanning.exportPlannings' | translate }}</span>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.id' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">#{{ element.id }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.date' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.date }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="route_code">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.route_code' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.route_code }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_route">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.route' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.rer_route?.name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_waste_type_id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.waste_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ getWasteTypeName(element.rer_waste_type_id) }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="device_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.device_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.device_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="period_begin">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.period_begin' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.period_begin | offset: true | date: "HH:mm" }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="period_end">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.period_end' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.period_end | offset: true | date: "HH:mm" }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="area">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.area' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.area }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="main_employee_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.main_employee_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.main_employee_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="secundary_employee_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.secundary_employee_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.secundary_employee_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tertiary_employee_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.tertiary_employee_name' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.tertiary_employee_name }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="notes">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerPlanning.dataTable.notes' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.notes }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="percent">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Rer.rerPlanning.dataTable.percent' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ showPlanningPercentage(element.rer_route_planning_execution) }} %</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
            <i *ngIf="hasDetailsRight" title="{{ 'Details' | translate }}"
                    (click)="detailsPlanning(element.id)"
                    class="mr-2 pointer icon-screen font-size-14 pointer">
            </i>
            <i *ngIf="hasEditRight" (click)="editPlanning(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
            <i *ngIf="hasEditRight" (click)="deletePlanning(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer ml-2"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute"
    style="height: 100%; top: 0; width: calc(100% - 100px);">
    <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
</div>
