<div class="bgcolor-white p-2 m-4 position-relative rerplannings-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms" [selectedIndex]="selectedTabIndex"
    (selectedIndexChange)="selectedTabIndex = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-shipments font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.rerIncident.incidentList' | translate }}
                </span>
            </ng-template>
                <app-list (detailsIncidentEventEmitter)="onDetailsIncident($event)" 
                (closeIncidentEventEmitter)="onCloseIncident($event)"></app-list>

        </mat-tab>
        <mat-tab *ngFor="let incident of incidentDetailsTabs">
            <ng-template mat-tab-label>
                <div *ngIf="incident.id" class="d-flex align-items-center">
                    <i class="ctsicon-tabnav-incident font-size-20 mr-2"></i>
                    <span class="mr-2">#{{ (incident.id ? incident.id  : '' ) }}</span>
                    <i class="icon-tmss-tab-close font-size-14" (click)="onCloseIncident(incident.id)"></i>
                </div>
            </ng-template>
            <app-incident-details 
            [incident]="incident"
            (closeIncidentEventEmitter)="onCloseDelivery($event)">
            </app-incident-details>
        </mat-tab>
    </mat-tab-group>
</div>