import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'app-add-area',
    templateUrl: './add-area.component.html',
    styleUrls: ['./add-area.component.scss']
})
export class AddAreaComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<AddAreaComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    ngOnInit() { }

    onSave() {
        this.dialogRef.close(this.data.area);
    }
}