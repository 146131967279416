<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ ((data.id ? 'Rer.rfidManagement.editRfidTag' : 'Rer.rfidManagement.addRfidTag') | translate)}}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <mat-form-field class="w-100 mt-4 mb-4">
        <input matInput autocomplete="off" type="details" [(ngModel)]="data.rfid" (keydown)="checkValue(data.rfid)"
            (keyup)="checkValue(data.rfid)" placeholder="{{ 'Rer.rfidManagement.rfidTag' | translate }}">
    </mat-form-field>
    <div class="row ml-0 mr-0 mb-4 pt-2">
        <div class="col-xl-3 col-lg-4 col-md-6 pl-0">
            <mat-form-field class="w-100">
                <input type="details" matInput type="details"
                    placeholder="{{'Rer.rfidManagement.created_at' | translate }}" readonly="true"
                    [(ngModel)]="data.created_at">
            </mat-form-field>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 pl-0">
            <mat-form-field class="w-100">
                <input type="details" matInput type="details"
                    placeholder="{{'Rer.rfidManagement.created_by_name' | translate }}" readonly="true"
                    [(ngModel)]="data.created_by_name">
            </mat-form-field>
        </div>
    </div>
    <div class="row ml-0 mr-0 mb-4 pt-2">
        <div class="col-xl-3 col-lg-4 col-md-6 pl-0">
            <mat-form-field class="w-100">
                <input type="details" matInput type="details"
                    placeholder="{{'Rer.rfidManagement.updated_at' | translate }}" readonly="true"
                    [(ngModel)]="data.updated_at">
            </mat-form-field>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 pl-0">
            <mat-form-field class="w-100">
                <input type="details" matInput type="details"
                    placeholder="{{'Rer.rfidManagement.updated_by_name' | translate }}" readonly="true"
                    [(ngModel)]="data.updated_by_name">
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4" (click)="onAdd()"
            [ngClass]="data.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="data.id ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{ (data.id ? 'save' : 'add') | translate }}</span>
            </div>
        </div>
    </div>
</div>