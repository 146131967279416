import { UserPreferencesService } from 'app/service/userpreferences.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RerRfidTagReading } from '../../../_model/rerrfidtagreading.model';

import { RerClient } from '../../../_model/rerclient.model';
import { RerService } from '../../../_service/rer.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-rfid-readings',
    templateUrl: './rfid-readings.component.html',
    styleUrls: ['./rfid-readings.component.scss']
})
export class RfidReadingsComponent implements OnInit {


    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public dateFormat: string;

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @Input() client: RerClient;

    constructor(
        private rerService: RerService,
        public userPrefService: UserPreferencesService
    ) { }

    ngOnInit() {
        this.displayedColumns = ['datetime_read', 'vehicle_name', 'waste_type', 'bin_type', 'rfid', 'coords'];
        if (this.client.id) {
            this.rerService.getRfidTagReadingList(this.client.id).subscribe(
                (response: RerRfidTagReading[]) => {
                    this.dataSource = new MatTableDataSource(response);
                    this.setDataTablePaginatorSort();
                }
            );
        }
        this.dateFormat = this.userPrefService.userPreferences.dateFormat ?
            this.userPrefService.userPreferences.dateFormat : this.userPrefService.defaultPreferences.dateFormat;
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
}
