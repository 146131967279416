import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { RerIncident } from '../../_model/rerincident.model';
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RerService } from '../../_service/rer.service';
import { MatTableDataSource } from '@angular/material/table';
import { StatusCodes } from 'http-status-codes';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'app/service/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndpointsService } from 'app/service/endpoints.service';
import * as moment from 'moment';
import { MessageDialogComponent } from 'app/modules/tms/shipping/add-edit/message-dialog/message-dialog.component';
import { saveAs } from 'file-saver';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { ExportPeriodDialogComponent } from '../../data-export-rer/export-period-dialog/export-period-dialog.component';
import { IncidentExportFilter } from '../incident-export-fliter/incident-export-filter.component';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
    private _subscriptions: Subscription[] = [];

    public incidentList$: Observable<RerIncident[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean;
    public currentFilterValue: string = null;
    public hasDetailsRight: boolean = false;
    public hasDeleteRight: boolean = false;
    public hasDetailsRightOnId: boolean = false;
    public dateFormat: string;
    public periodBegin: any;
    public periodEnd: any;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @Output() detailsIncidentEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() closeIncidentEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(private rerService: RerService,
        public dialog: MatDialog,
        private httpClient: HttpClient,
        private endpointsService: EndpointsService,
        private translateService: TranslateService,
        private userPreferencesService: UserPreferencesService,
        private translationService: TranslateService,
        public messageDialog: MatDialog) { }


    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat;
        this.displayedColumns = [
            'id', 'incident_time','status', 'incident_type', 'location', 'route_code', 'rer_waste_type', 'device_name', 'main_employee_name',
            'responsible', 'evaluation', 'actions',
        ];
        this.incidentList$ = this.rerService.incidentList$;
        this.periodEnd = moment().format('YYYY-MM-DD');
        this.periodBegin = moment().subtract(7,'d').format('YYYY-MM-DD');

        this.incidentList$.subscribe(
            (incidents: RerIncident[]) => {
                this.dataSource = new MatTableDataSource(incidents);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
					this.applyFilter(this.currentFilterValue);
				}
            }
        );
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_INCIDENT_DETAILS)) {
			this.hasDetailsRightOnId = true;
		}
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_INCIDENT_DELETE)) {
			this.hasDeleteRight = true;
		}
        this.addSubscriptions();
    }

    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }

    public applyFilter(filterValue: string): void {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

    private setDataTablePaginatorSort(): void {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

    private addSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.rerIncidentListSubject.subscribe(
                incident => {
                    incident = incident.map(x => {
                        this.translateService.get('Rer.rerIncident.details.statuses.' + x.status).subscribe(el=> x.status_string = el);
                        this.translateService.get('Rer.rerIncident.details.evaluation.'+ x.evaluation).subscribe(el=> x.evaluation_string = el);
                        this.translateService.get('Rer.rerIncident.details.type.'+ x.incident_type).subscribe(el=> x.incident_type_name = el);
                        return x;
                    });
                }
            )
        );
    }

    public detailsIncident(incidentId): void {
        this.detailsIncidentEventEmitter.emit(incidentId);
    }

    public deleteIncident(id) {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.rerService
                        .getTranslationFor('Rer.rerIncident.list.dataTable.confirmDeleteActionIncident')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const incident = this.rerService.getIncidentById(id);
                    this.rerService.deleteIncident(incident.id).subscribe(
                        () => { this.showSpinner = false; },
                        (error: HttpErrorResponse) => {
                            let errorMessage = 'errorNotification';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rerSettings.errorIncidentInUse';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage), '600px');
                        }
                    );
                }
            }
        );
    }

    public doExportIncident() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            IncidentExportFilter, { width: '600px' }
        );

        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                let params = new HttpParams().set("period_begin", obj.exportDates.startDate)
                    .set("period_end", obj.exportDates.endDate)
                    .set("status", String(obj.status))
                    .set("evaluation", String(obj.evaluation));
                const httpOptions = {
                    headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                    params: params
                };
        
                let columHearders = [this.translateService.instant('Rer.rerIncident.export.dateTime'),
                this.translateService.instant('Rer.rerIncident.export.status'), this.translateService.instant('Rer.rerIncident.export.incidentType'), this.translateService.instant('Rer.rerIncident.export.location'),
                this.translateService.instant('Rer.rerIncident.export.routeCode'), this.translateService.instant('Rer.rerIncident.export.wasteType'),
                this.translateService.instant('Rer.rerIncident.export.deviceName'),this.translateService.instant('Rer.rerIncident.export.driver'),
                this.translateService.instant('Rer.rerIncident.export.operator1'), this.translateService.instant('Rer.rerIncident.export.operator2'),
                this.translateService.instant('Rer.rerIncident.export.evaluation'), this.translateService.instant('Rer.rerIncident.export.responsible'),
                this.translateService.instant('Rer.rerIncident.export.client'),  this.translateService.instant('Rer.rerIncident.export.notes')];
        
                this.httpClient.get(this.endpointsService.get('rer.exportIncident'), httpOptions).subscribe(
                    (data: any) => {
                        var data1 = data.map((x: any, index) =>{
                            if(index > 0) {
                                x.status = this.translateService.instant('Rer.rerIncident.details.statuses.' + x.status);
                                x.evaluation = this.translateService.instant('Rer.rerIncident.details.evaluation.'+ x.evaluation);
                                x.incident_type = this.translateService.instant('Rer.rerIncident.details.type.'+ x.incident_type);
                                x.incident_time = this.getDateOffset(x.incident_time);
                            }
                            return x;
                        });
                        data = data1.unshift(columHearders);
                        var csv = "";
                        for (let row of data1) {
                            for (let col of Object.keys(row)) { csv += row[col] + ";"; }
                            csv += "\r\n";
                        }
        
                        var blob = new Blob([csv], {type: "text/csv"});
                        let fileName: any =
                            this.translationService.instant('Rer.rerIncident.export.exportIncident');
                        fileName = fileName.replaceAll(' ', '_') + ' '
                            + moment().format('YYYY-MM-DD') + '.csv';
                        saveAs(blob, fileName);
                        this.showSpinner = false;
                    },
                    () => {
                        this.showSpinner = false;
                        this.showNotification();
                    }
                );

            } else {
                this.showSpinner = false;
            }
        }
        );
    }

    public loadIncidentsByPeriod(): void {
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent, { width: '450px' }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.showSpinner = true;
                    this.rerService.getIncidentList(response.startDate, response.endDate).subscribe(
                        (incidents: RerIncident[]) => {
                            if (incidents) {
                                this.periodBegin = response.startDate;
                                this.periodEnd = response.endDate;
                                this.showSpinner = false;
                                this.rerService.rerIncidentListSubject.next(incidents);
                                dialogRef.close();
                                if (incidents.length === 0) {
                                    this.messageDialog.open(MessageDialogComponent,
                                        {
                                            data: {
                                                message: this.translationService.instant('Rer.rerIncident.list.emptyIncidentsList'),
                                                messageCode: 'warning'
                                            }
                                        }
                                    );
                                }
                            }
                        },
                        (error) => {
                            this.showSpinner = false;
                            dialogRef.close();
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            });
    }

    public getDateOffset(date_send: string) {
        return this.rerService.setDateOffset(date_send, false, this.dateFormat.toUpperCase() + ' HH:mm:ss');
    }

}
