import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, map } from 'rxjs';
import { RerCollectionByWeight } from '../_model/rercollectionbyweight.model';
import { EndpointsService } from 'app/service/endpoints.service';
import { RerCollectionClient } from '../_model/rercollectionclient.model';
import { Device } from 'app/modules/model/settings/device.model';
import { RerUnloadingArea } from '../_model/rerunloadingarea.model';
import { RerWeighingReceipt } from '../_model/rerweighingreceipt.model';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { TranslateService } from '@ngx-translate/core';
import { RerWeighingReceiptLine } from '../_model/rerweighingreceiptline.model';

@Injectable({
    providedIn: 'root',
})
export class RerCollectionByWeightService {
    public rerAutomaticReportsListSubject = new BehaviorSubject<RerCollectionByWeight[]>([]);
    public rerCollectionClientAddressListSubject = new BehaviorSubject<RerCollectionClient[]>([]);
    public clientListSubject = new BehaviorSubject<any[]>([]);
    public deviceListSubject = new BehaviorSubject<any[]>([]);
    public rerUnloadingAreaListSubject = new BehaviorSubject<RerUnloadingArea[]>([]);
    public rerWeighingReceiptListSubject = new BehaviorSubject<RerWeighingReceipt[]>([]);
    public rerWeighingReceiptLineListSubject = new BehaviorSubject<RerWeighingReceiptLine[]>([]);

    public automaticReportsList$: Observable<RerCollectionByWeight[]> = this.rerAutomaticReportsListSubject.asObservable();
    public rerCollectionClientAddressList$: Observable<RerCollectionClient[]> = this.rerCollectionClientAddressListSubject.asObservable();
    public unloadingAreaList$: Observable<RerUnloadingArea[]> = this.rerUnloadingAreaListSubject.asObservable();
    public rerWeighingReceiptList$: Observable<RerWeighingReceipt[]> = this.rerWeighingReceiptListSubject.asObservable();
    public rerWeighingReceiptLineList$: Observable<RerWeighingReceiptLine[]> = this.rerWeighingReceiptLineListSubject.asObservable();

    constructor(
        private httpClient: HttpClient,
        private endpointsService: EndpointsService,
        private actionDialog: MatDialog,
        private translateService: TranslateService
    ) {
    }

    public showErrorNotifications(message: string, mWidth = '800px'): void {
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: mWidth, panelClass: 'custom-dialog-container',
                data: { success: 0, headermessage: this.getTranslationFor('error'), message: message }
            }
        );
    }
  
    public showSaveConfirmation(message: string): void {
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: { success: 1, headermessage: this.getTranslationFor('success'), message: message }
            }
        );
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe(
            (response: string) => result = response
        );
        return result;
    }

    public getAutomaticReportsList(startDate: String, endDate: String): Observable<any> {
        let params = new HttpParams().set("period_begin", String(startDate))
            .set("period_end", String(endDate));
        const httpOptions = {
            params: params
        };
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getAutomaticReportsList'), httpOptions);
    }

    public downloadAutomaticReport(reportId: number) {
        return this.httpClient.get<any>(this.endpointsService.get('rer.collectionByWeight.automaticReportDownload', [reportId]));
    }

    public getClientSelectList() {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getClientSelectList')).subscribe({
            next: (response) => this.clientListSubject.next(response),
            error: (error) => console.error(error)
        });
    }

    public getDeviceSelectList() {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getDeviceSelectList')).subscribe({
            next: (response) => this.deviceListSubject.next(response),
            error: (error) => console.error(error)
        });
    }

    public getCollectionDevices(): Observable<Device[]> {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getDeviceSelectList'));
    }

    public getRerCollectionClientAddressList(clientId = null): Observable<any> {
        let params = new HttpParams();
        if (clientId) {
            params = params.set("rer_client_id", String(clientId));
        }
        const httpOptions = {
            params: params
        };
        return this.httpClient.get(this.endpointsService.get('rer.collectionByWeight.getRerCollectionClientAddressList'), httpOptions);
    }

    public getUnloadingZoneList() {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getUnloadingZoneList')).subscribe({
            next: (response) => {
                if(response){
                    response.forEach(x => {
                        x.address = JSON.parse(x.address);
                    })
                }
                this.rerUnloadingAreaListSubject.next(response)
            },
            error: (error) => console.error(error)
        });
    }

    public getUnloadingAreaById(id: number): any {
        const unloadingAreas = this.rerUnloadingAreaListSubject.getValue();
        const ar = unloadingAreas.find((obj: RerUnloadingArea) => obj.id === id);
        if (ar !== undefined) {
            const unloadingArea = JSON.stringify(unloadingAreas.find((obj: RerUnloadingArea) => obj.id === id));
            return JSON.parse(unloadingArea);
        }

        return null;
    }

    public saveUnloadingArea(unloadingArea: RerUnloadingArea) {
        return this.httpClient.post(this.endpointsService.get('rer.collectionByWeight.addEditUnloadingArea'), unloadingArea).subscribe({
            next:
                (response) => {
                    unloadingArea.id = response;
                    const areas = this.rerUnloadingAreaListSubject.getValue();
                    const newareas = areas.slice(0);
                    const areaIndex = areas.findIndex((doc: RerUnloadingArea) => doc.id === unloadingArea.id);
                    if (areaIndex !== -1) {
                        newareas[areaIndex] = unloadingArea;
                    } else {
                        newareas.unshift(unloadingArea);
                    }
                    this.rerUnloadingAreaListSubject.next(newareas);
                    return response;
                }
        });
    }

    public activateOrDeactivateArea(id) {
        return this.httpClient.get(this.endpointsService.get('rer.collectionByWeight.activateOrDeactivateArea', [id])).subscribe({
            next:
                (response) => {
                    id = response;
                    const areas = this.rerUnloadingAreaListSubject.getValue();
                    const areaIndex = areas.findIndex((area: RerUnloadingArea) => area.id === id);
                    if (areaIndex !== -1) {
                        areas[areaIndex].active = areas[areaIndex].active == 1 ? 0 : 1;
                        this.rerUnloadingAreaListSubject.next(areas);
                    }
                    return response;
                }
        });
    }

    public getWeighingReceiptList() {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getWeighingReceiptList')).subscribe({
            next: (response) => this.rerWeighingReceiptListSubject.next(response),
            error: (error) => console.error(error)
        });
    }

    public getWeighingReceiptById(id: number): any {
        const weighingReceipts = this.rerWeighingReceiptListSubject.getValue();
        const receipt = weighingReceipts.find((obj: RerWeighingReceipt) => obj.id === id);
        if (receipt !== undefined) {
            const weighingReceipt = JSON.stringify(weighingReceipts.find((obj: RerWeighingReceipt) => obj.id === id));
            return JSON.parse(weighingReceipt);
        }

        return null;
    }

    public saveWeighingReceipt(formData: FormData) {
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        let options = { headers: headers };
        return this.httpClient.post(this.endpointsService.get('rer.collectionByWeight.editWeighingReceipt'), formData, options).pipe(
            map(
                (response: RerWeighingReceipt) => {
                    const receipts = this.rerWeighingReceiptListSubject.getValue();
                    const newweighingreceipts = receipts.slice(0);
                    const weighingReceiptIndex = receipts.findIndex((doc: RerWeighingReceipt) => doc.id === response.id);
                    if (weighingReceiptIndex !== -1) {
                        newweighingreceipts[weighingReceiptIndex].operated_quantity = response.operated_quantity;
                        newweighingreceipts[weighingReceiptIndex].unallocated_quantity = response.unallocated_quantity;
                    } else {
                        newweighingreceipts.unshift(response);
                    }
                    this.rerWeighingReceiptListSubject.next(newweighingreceipts);
                    return response;
                }
            )
        );
    }

    public getWeighingReceiptLineList(id) {
        return this.httpClient.get<any[]>(this.endpointsService.get('rer.collectionByWeight.getWeighingReceiptLineList', [id]));
    }

    public getWeighingReceiptLineById(id: number): any {
        const weighingReceiptLines = this.rerWeighingReceiptLineListSubject.getValue();
        const receiptLine = weighingReceiptLines.find((obj: RerWeighingReceiptLine) => obj.id === id);
        if (receiptLine !== undefined) {
            const weighingReceiptLine = JSON.stringify(weighingReceiptLines.find((obj: RerWeighingReceiptLine) => obj.id === id));
            return JSON.parse(weighingReceiptLine);
        }

        return null;
    }

    public downloadProof(id: number) {
        return this.httpClient.get<any>(this.endpointsService.get('rer.collectionByWeight.downloadProof', [id]));
    }
}