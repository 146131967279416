import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

import { UserPreferencesService } from 'app/service/userpreferences.service';

export interface CalendarDate {
    mDate: moment.Moment;
    selected?: boolean;
    today?: boolean;
}

@Component({
    selector: 'app-datepicker',
    templateUrl: './datepicker.component.html',
    styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit, OnChanges {

    private currentDate = moment();
    private regxFormat = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

    public weeks: CalendarDate[][] = [];
    public dayNames: string[];
    public isActivated: boolean;
    public selectedDates: CalendarDate[] = [];
    public startDateTimeValue: string;
    public endDateTimeValue: string;
    public startTime;
    public moment;
    public dateFormatShow: string;
    public faCalendarAlt = faCalendarAlt;

    @Input() date;
    @Input() dateFormat = 'YYYY-MM-DD';
    @Input() label = 'data';
    @Input() locale = 'en';
    @Input() time = false;
    @Input() disabled = false;
    @Input() recalculateTopPosition = false;
    @Input() alignCenter = false;
    @Input() justifyCenter = false;
    @Input() isMobile = false;
    @Input() maxHeight = '100%';
    @Input() zIndex = '999999999';
    @Input() doubleBorderLeft = false;
    @Input() doubleBorderTop = false;

    @Input() allowFuture = true;
    @Input() allowPast = true;
    @Input() minDate: string;       // string  format YYYY-MM-DD
    @Input() maxDate: string;       // string  format YYYY-MM-DD
    @Input() includeTime = true;

    @Output() selectDateEventEmitter = new EventEmitter<String>();

    constructor(private userpreferencesService: UserPreferencesService) { }

    ngOnInit(): void {
        this.dateFormatShow = this.userpreferencesService.userPreferences.dateFormat ?
        this.userpreferencesService.userPreferences.dateFormat : this.userpreferencesService.defaultPreferences.dateFormat;
        moment.locale(this.locale);
        this.moment = moment;
        this.setDayNames();
        this.isActivated = false;

        let date: CalendarDate;

        if (this.date && this.date.length > 0) {
            const startDay = (this.time) ? this.date.substring(0, 10) : this.date;
            if (startDay.match(this.regxFormat)) {
                date = {
                    today: false,
                    selected: true,
                    mDate: moment(startDay),
                };
            } else {
                date = { today: true, selected: true, mDate: moment() };
            }

            if (this.time) {
                this.startTime = {
                    hours: this.date.substring(11, 13),
                    minutes: this.date.substring(14, 16)
                };
            }
        } else {
            date = { today: true, selected: true, mDate: moment() };
            this.startTime = {
                hours: (String(moment().hour()).length === 1) ? '0' + String(moment().hour()) : String(moment().hour()),
                minutes: (String(moment().minutes()).length === 1) ? '0' + String(moment().minutes()) : String(moment().minutes())
            };
        }

        this.selectedDates = [date];
        this.startDateTimeValue = date.mDate.format(this.dateFormat);
        if (this.time) {
            this.startDateTimeValue += ' ' + this.startTime.hours + ':' + this.startTime.minutes + ':00';
        }

        this.generateCalendar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.date && changes.date.previousValue && changes.date.currentValue && changes.date.currentValue !== changes.date.previousValue) {
            this.startDateTimeValue = changes.date.currentValue; 
            const hasTime = new Date(this.startDateTimeValue).getTime();
            if (this.time && !hasTime) {
                this.startDateTimeValue += ' ' + this.startTime.hours + ':' + this.startTime.minutes + ':00';
            }
        }

        if (changes.selectedDates && changes.selectedDates.currentValue && changes.selectedDates.currentValue.length > 1) {
            this.generateCalendar();
        }
    }

    private setDayNames(): void {
        switch (this.locale) {
            case 'ro':
                this.dayNames = ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sa', 'Du'];
                break;
            case 'en':
                this.dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
                break;
        }
    }

    // generate the calendar grid
    private generateCalendar(): void {
        const dates = this.fillDates(this.currentDate);
        const weeks: CalendarDate[][] = [];
        while (dates.length > 0) {
            weeks.push(dates.splice(0, 7));
        }
        this.weeks = weeks;
    }

    private fillDates(currentMoment: moment.Moment): CalendarDate[] {
        const firstOfMonth = moment(currentMoment).startOf('month').isoWeekday(1).day();
        const firstDayOfGrid = moment(currentMoment).startOf('month').isoWeekday(1).subtract(firstOfMonth, 'days');
        const startDay = firstDayOfGrid.date();
        return _.range(startDay + 1, startDay + 43).map((date: number): CalendarDate => {
            const d = moment(firstDayOfGrid).date(date);
            return { today: moment().isSame(moment(d), 'day'), selected: this.isSelected(d), mDate: d };
        });
    }

    // date checkers
    private isSelected(date: moment.Moment): boolean {
        return _.findIndex(this.selectedDates, (selectedDate) => moment(date).isSame(selectedDate.mDate, 'day')) > -1;
    }

    public isSelectedMonth(date: moment.Moment): boolean {
        return moment(date).isSame(this.currentDate, 'month');
    }

    public isEnabled(date: moment.Moment): boolean {

        if (!this.allowFuture && moment(date).isAfter()) {
            return false;
        }

        if (this.maxDate && moment(date).isAfter(this.maxDate, 'day')) {
            return false;
        }

        if (!this.allowPast && moment(date).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
            return false;
        }

        if (this.minDate && moment(date).isBefore(this.minDate, 'day')) {
            return false;
        }

        return true;
    }

    public selectDate(date: CalendarDate): void {
        this.weeks.forEach(week => week.forEach(day => day.selected = false));
        date.selected = true;
        this.selectedDates = [date];
        this.startDateTimeValue = date.mDate.format(this.dateFormat);
        if (this.time) {
            this.startDateTimeValue += ' ' + this.startTime.hours + ':' + this.startTime.minutes + ':00';
        }
        this.selectDateEventEmitter.emit(this.startDateTimeValue);
    }

    public selectTime(): void {
        this.startDateTimeValue = this.selectedDates[0].mDate.format(this.dateFormat) +
            ' ' + this.startTime.hours + ':' + this.startTime.minutes + ':00';
        this.selectDateEventEmitter.emit(this.startDateTimeValue);
    }

    // actions from calendar
    public prevMonth(): void {
        this.currentDate = moment(this.currentDate).subtract(1, 'months');
        this.generateCalendar();
    }

    public nextMonth(): void {
        this.currentDate = moment(this.currentDate).add(1, 'months');
        this.generateCalendar();
    }

    public prevYear(): void {
        this.currentDate = moment(this.currentDate).subtract(1, 'year');
        this.generateCalendar();
    }

    public nextYear(): void {
        this.currentDate = moment(this.currentDate).add(1, 'year');
        this.generateCalendar();
    }

    public onDatepickerIconClick(event: any): void {
        event.stopPropagation();
        if (!this.disabled) {
            this.isActivated = !this.isActivated;
        }
    }

    public onClickOutside(event: MouseEvent) {
        if (['pDate', 'spanDate'].indexOf(event.target['id']) === -1) {
            this.isActivated = false;
        }
    }
}
