import * as bigdecimal from 'bigdecimal';
export class DeviceAttribute {

    public LOGIC_ERROR = 'LOGIC_ERROR';

    private LOGIC_TYPE_ARITHMETIC = 'arithmetic';
    private LOGIC_TYPE_CONDITIONAL = 'conditional';
    private LOGIC_TYPE_LINEAR = 'linear';
    private LOGIC_TYPE_FUNCTION = 'function';
    // arithmetic logic operators
    private ARITHMETIC_OPERATOR_ADD = '+';
    private ARITHMETIC_OPERATOR_SUBTRACT = '-';
    private ARITHMETIC_OPERATOR_MULTIPLY = '*';
    private ARITHMETIC_OPERATOR_DIVIDE = '/';
    private ARITHMETIC_OPERATOR_ROUND = '~';
    private ARITHMETIC_OPERATOR_CONCAT = '|';
    // conditional logic operators
    private CONDITIONAL_OPERATOR_EQUALS = '=';
    private CONDITIONAL_OPERATOR_NOT_EQUALS = '!=';
    private CONDITIONAL_OPERATOR_LESS_THAN = '<';
    private CONDITIONAL_OPERATOR_GREATER_THAN = '>';
    private CONDITIONAL_OPERATOR_LESS_OR_EQUAL_THAN = '<=';
    private CONDITIONAL_OPERATOR_GREATER_OR_EQUAL_THAN = '>=';

    // function operators
    private FUNCTION_OPERATOR_DEC2HEX = 'DEC2HEX';
    private FUNCTION_OPERATOR_DEC2HEX32 = 'DEC2HEX32'; // output of a 32 bit (4 byte) hex number ex: E2 00 00 20
    private FUNCTION_OPERATOR_DEC2HEX64 = 'DEC2HEX64'; // output of a 64 bit (8 byte) hex number ex: 00 00 40 13 44 D2 68 4F
    private FUNCTION_OPERATOR_HEX2STR = 'HEX2STR';
    private FUNCTION_OPERATOR_HEX2BIN = 'HEX2BIN';
    private FUNCTION_OPERATOR_INVERT2 = 'INVERT2';
    private FUNCTION_OPERATOR_LOWERCASE = 'LOWERCASE';
    private FUNCTION_OPERATOR_UPPERCASE = 'UPPERCASE';
    private FUNCTION_OPERATOR_STRLEN = 'STRLEN';
    private FUNCTION_OPERATOR_SUBSTRING = 'SUBSTRING';
    private FUNCTION_OPERATOR_TRIM = 'TRIM';
    private FUNCTION_OPERATOR_DONOTHING = 'DONOTHING';

    constructor(
        private rawValues: {},       // device latest position values
        private definitions: any   //  device->attributes
    ) { }

    public setRawValues(rawValues) {
        this.rawValues = rawValues;
    }

    public getValue(attributeKey: string, deciveId = null) {
        if (this.rawValues.hasOwnProperty(attributeKey)) {
            return this.rawValues[attributeKey];
        } else if (this.definitions.hasOwnProperty(attributeKey)) {
            const attribute = this.definitions[attributeKey];
            if (attribute.hasOwnProperty('logic')) {
                return this.determineValue(JSON.parse(attribute.logic));
            } else {
                return this.LOGIC_ERROR;
            }
        } else {
            return this.LOGIC_ERROR;
        }
    }

    private determineValue(logic) {

        switch (logic.type) {
            case this.LOGIC_TYPE_ARITHMETIC:
                return this.determineArithmeticValue(logic);
            case this.LOGIC_TYPE_CONDITIONAL:
                return this.determineConditionalValue(logic);
            case this.LOGIC_TYPE_LINEAR:
                return this.determineLinearValue(logic);
            case this.LOGIC_TYPE_FUNCTION:
                return this.determineFunctionValue(logic);
            default:
                return this.LOGIC_ERROR;
        }
    }

    private determineArithmeticValue(logic) {
        if ((!logic.hasOwnProperty('base_operand') &&
            logic.hasOwnProperty('operand_list')) ||
            !Array.isArray(logic.operand_list) ||
            (logic.operand_list.length) < 1) {
            return this.LOGIC_ERROR;
        }

        let result = this.getValue(logic.base_operand);
        if (result === this.LOGIC_ERROR) {
            return this.LOGIC_ERROR;
        }



        for (const val of logic.operand_list) {
            if (val.hasOwnProperty('operator') && val.hasOwnProperty('operand') && val.hasOwnProperty('fixed_value')) {

                let operand = val.fixed_value;
                if (val.operand !== '') {
                    operand = this.getValue(val.operand);
                    if (operand === this.LOGIC_ERROR) {
                        return this.LOGIC_ERROR;
                    }
                }

                //  Force variables to be numbers
                if (val.operator !== this.ARITHMETIC_OPERATOR_CONCAT) {
                    result = +result;
                    operand = + operand;
                }

                switch (val.operator) {
                    case this.ARITHMETIC_OPERATOR_ADD:
                        result = result + operand;
                        break;
                    case this.ARITHMETIC_OPERATOR_SUBTRACT:
                        result = result - operand;
                        break;
                    case this.ARITHMETIC_OPERATOR_MULTIPLY:
                        result = result * operand;
                        break;
                    case this.ARITHMETIC_OPERATOR_DIVIDE:
                        result = result / operand;
                        break;
                    case this.ARITHMETIC_OPERATOR_ROUND:
                        result = this.precisionRound(result, parseInt(operand, 10));
                        break;
                    case this.ARITHMETIC_OPERATOR_CONCAT:
                        result = String(result) + String(operand);
                        break;
                }
            }
        }

        return result;
    }

    private precisionRound(number, precision) {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    private determineConditionalValue(logic) {
        if (!(logic.hasOwnProperty('conditions') && logic.hasOwnProperty('final_else')) || !Array.isArray(logic.conditions)) {
            return this.LOGIC_ERROR;
        }

        for (const condition of logic.conditions) {
            if (condition.hasOwnProperty('elements') && condition.hasOwnProperty('result') && Array.isArray(condition['elements'])) {

                let conditionTrue = true;
                for (const element of condition['elements']) {
                    if (element.hasOwnProperty('attribute') &&
                        element.hasOwnProperty('logic_operator') &&
                        element.hasOwnProperty('test_value')) {
                        const compareTo = this.getValue(element['attribute']);
                        if (compareTo === this.LOGIC_ERROR) {
                            return this.LOGIC_ERROR;
                        } else {
                            if (typeof compareTo === 'number') {
                                element['test_value'] = +element['test_value'];
                            }
                        }
                        if (element['test_value'] === 'true') {
                            element['test_value'] = true;
                        }
                        if (element['test_value'] === 'false') {
                            element['test_value'] = false;
                        }

                        switch (element['logic_operator']) {
                            case this.CONDITIONAL_OPERATOR_EQUALS:
                                if (!(compareTo === element['test_value'])) {
                                    conditionTrue = false;
                                }
                                break;
                            case this.CONDITIONAL_OPERATOR_NOT_EQUALS:
                                if (!(compareTo !== element['test_value'])) {
                                    conditionTrue = false;
                                }
                                break;
                            case this.CONDITIONAL_OPERATOR_LESS_THAN:
                                if (!(+compareTo < +element['test_value'])) {
                                    conditionTrue = false;
                                }
                                break;
                            case this.CONDITIONAL_OPERATOR_GREATER_THAN:
                                if (!(+compareTo > +element['test_value'])) {
                                    conditionTrue = false;
                                }
                                break;
                            case this.CONDITIONAL_OPERATOR_LESS_OR_EQUAL_THAN:
                                if (!(+compareTo <= +element['test_value'])) {
                                    conditionTrue = false;
                                }
                                break;
                            case this.CONDITIONAL_OPERATOR_GREATER_OR_EQUAL_THAN:
                                if (!(+compareTo >= +element['test_value'])) {
                                    conditionTrue = false;
                                }
                                break;
                        }
                    }
                }

                if (conditionTrue) {
                    if (condition.hasOwnProperty('result_to_be_evaluated') && condition['result'] === '') {
                        return this.getValue(condition['result_to_be_evaluated']);
                    } else {
                        return condition['result'];
                    }
                }
            }
        }
        if (logic.hasOwnProperty('final_else_to_be_evaluated') && logic['final_else'] === '') {
            return this.getValue(logic['final_else_to_be_evaluated']);
        } else {
            return logic['final_else'];
        }
    }

    // see the "point-slope" form of the equation of a straight line
    // y − y1 = m(x − x1)
    // slope: m = (y2-y1)/(x2-x1)
    private determineLinearValue(logic) {

        if (!logic.hasOwnProperty('attribute') ||
            !logic.hasOwnProperty('coords') ||
            !logic.hasOwnProperty('min') ||
            !logic.hasOwnProperty('max') ||
            !logic.hasOwnProperty('coords')) {
            return this.LOGIC_ERROR;
        }

        let value = this.getValue(logic.attribute);
        if (value === this.LOGIC_ERROR) {
            return this.LOGIC_ERROR;
        }

        const x = +value;
        let x1 = null;
        let y1 = null;
        let m: number;

        const logicProperties = Object.getOwnPropertyNames(logic.coords);

        for (const x2 of logicProperties) {

            if (x < +x2 && (typeof x1 === 'undefined')) {

                return this.LOGIC_ERROR;
            } else if (x === +x2) {

                return +logic.coords[x2];
            } else if (x < +x2) {
                if (+x2 - x1 === 0) {
                    return this.LOGIC_ERROR;
                }
                m = (+logic.coords[x2] - y1) / (+x2 - x1);
                // y = m(x − x1) + y1
                value = m * (x - x1) + y1;
                break;
            }
            x1 = +x2;
            y1 = +logic.coords[x2];
        }

        // level result
        if (value < logic.min) {
            value = logic.min;
        }

        if (value > logic.max) {
            value = logic.max;
        }

        return value;
    }


    private determineFunctionValue(logic) {

        if (
            logic['function_argument'] === undefined ||
            logic['function_list'] === undefined
            ||
            ((!Array.isArray(logic['function_list']) || logic['function_list'].length < 1)
                &&
                (typeof logic['function_list'] !== 'object' || Object.keys(logic['function_list']).length < 1))
        ) {
            return this.LOGIC_ERROR;
        }

        let result = this.getValue(logic['function_argument']);
        if (result === this.LOGIC_ERROR || result === 0) {
            return this.LOGIC_ERROR;
        }
        var logicArray = logic['function_list'];

        if (typeof logic['function_list'] === 'object' && logic['function_list'] !== null) {
            logicArray = Object.keys(logic['function_list']);
        }

        for (const val of logicArray) {
            if (logic['function_list'][val] != null && logic['function_list'][val].length) {
                var values = logic['function_list'][val];
            }
            switch (val.toUpperCase()) {
                case this.FUNCTION_OPERATOR_DEC2HEX:
                    result = this.dec2hex(result);
                    break;
                case this.FUNCTION_OPERATOR_DEC2HEX32:
                    result = this.dec2hex(result).slice(-8).padStart(8, '0');
                    break;
                case this.FUNCTION_OPERATOR_DEC2HEX64:
                    result = this.dec2hex(result).slice(-16).padStart(16, '0');
                    break;
                case this.FUNCTION_OPERATOR_HEX2STR:
                    result = this.hex2str(result);
                    break;
                case this.FUNCTION_OPERATOR_HEX2BIN:
                    result = this.hex2bin(result);
                    break;
                case this.FUNCTION_OPERATOR_INVERT2:
                    result = this.invert2(result);
                    break;
                case this.FUNCTION_OPERATOR_LOWERCASE:
                    result = result.toLowerCase();
                    break;
                case this.FUNCTION_OPERATOR_UPPERCASE:
                    result = result.toUpperCase();
                    break;
                case this.FUNCTION_OPERATOR_STRLEN:
                    result = String(result).length;
                    break;
                case this.FUNCTION_OPERATOR_SUBSTRING:
                    if (!values && !values.isArray()) {
                        return this.LOGIC_ERROR;
                    }
                    result = result.substring(values[0], parseInt(values[0]) + parseInt(values[1])) ?? null;
                    break;
                case this.FUNCTION_OPERATOR_TRIM:
                    result = result.trim();
                    break;
                case this.FUNCTION_OPERATOR_DONOTHING:
                    result = result;
                    break;
            }
        }

        return result;
    }

    public dec2hex(dec) {
        let bigNumber;
        if (+dec >= 0) {
            bigNumber = new bigdecimal.BigInteger(dec + '');
        } else {
            // convert negative decimal to binary
            const bin = (new bigdecimal.BigInteger(dec + '')).toString(2);
            // compute the two's complement of the binary number
            const twc = this.binaryAddition(this.binaryFlip(bin), '1');
            // transform binary to decimal
            bigNumber = new bigdecimal.BigInteger(twc, 2);
        }
        let result: String = bigNumber.toString(16).toUpperCase();
        return result;
    }

    public hex2bin(hex) {
        var bytes = [];
        var str;
        for (var i = 0; i < hex.length - 1; i += 2) {
            bytes.push(parseInt(hex.substr(i, 2), 16));
        }
        str = String.fromCharCode.apply(String, bytes);
        return str;
    }

    public binaryFlip(bin) {
        return bin.replaceAll('0', '-').replaceAll('1', '0').replaceAll('-', '1');
    }

    public binaryAddition(a, b) {
        let result = '',
            carry = 0;
        while (a || b || carry) {
            const sum = +a.slice(-1) + +b.slice(-1) + carry; // get last digit from each number and sum
            if (sum > 1) {
                result = sum % 2 + result;
                carry = 1;
            } else {
                result = sum + result;
                carry = 0;
            }
            // trim last digit (110 -> 11)
            a = a.slice(0, -1);
            b = b.slice(0, -1);
        }
        return result;
    }

    public hex2str(hex) {
        let string = '';
        for (let i = 0; i < hex.length; i += 2) {
            string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
        }
        return string;
    }

    /**
     * Invert a string by taking 2 chars pairs
     * Output is always an even lenghted string
     *
     * example: 10833C319000020
     * =>  1 08 33 C3 19 00 00 20
     * => 20 00 00 19 C3 33 08 01
     * => 20000019C3330801
     * @param string input
     * @return string
     */

    public invert2(input) {
        let output = '';
        length = input.length;
        for (let i = length; i > 0; i -= 2) {
            const start = i - 2;
            if (start >= 0) {
                output += input.substr(i - 2, 2);
            } else {
                output += '0' + input.substr(0, 1);
            }
        }

        return output;
    }

}
