<div class="search-content" class="mr-1 mb-4">
    <div class="row mt-2 w-100 mx-0">
        <div class="col-6 pl-0 pr-0">
            <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
                <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
                    class="flex-grow-1" placeholder="{{ 'Rer.route.list.quickSearch' | translate }}">
            </div>
        </div>
        <div class="col-6 pr-0">
            <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45 position-relative">
                <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                <input matInput autocomplete="off" #searchStreet class="flex-grow-1" placeholder="{{ 'Rer.route.streetSearch' | translate }}">
                <div *ngIf="isStreetDropdownOpen" (appClickOutside)="closeStreetDropdown()"  class="position-absolute street-container">
                    <ul>
                        <li (click)="onDropdownStreetClick(street)" *ngFor="let street of streetDropdownContent" [class.cursor-not-allowed]="checkIfExists(street.id)">
                            {{ street.name }} - {{ street.city?.name }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 190px)">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.route.list.dataTable.name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }} - {{ element.city?.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
            <i (click)="onDeleteStreet(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator class="mr-1" #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div class="d-flex justify-content-start align-items-center button-container mt-2">
    <div class="button-component utility-green-bg-color ml-2" (click)="onSaveRouteStreets()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'save' | translate }}</span>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>