import { Component, OnInit, Output, Input, EventEmitter, Inject, LOCALE_ID} from '@angular/core';
import { faMinusSquare, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import { RerIncident } from '../../_model/rerincident.model';
import { RerService } from '../../_service/rer.service';
import { TranslateService } from '@ngx-translate/core';
import { RerIncidentLog } from '../../_model/rerincidentlog.model';
import { AuthService } from 'app/service/auth.service';

@Component({
    selector: 'app-incident-details',
    templateUrl: './incident-details.component.html',
    styleUrls: ['./incident-details.component.scss']
})
export class IncidentDetailsComponent implements OnInit {

    public showSpinner: boolean;
    public editMode: boolean = false;
    public shownLog: boolean = true;
    public faMinusSquare = faMinusSquare;
    public faPlusSquare = faPlusSquare;
    public shownDetails: boolean = true;
    public shownAdministration: boolean = true;
    public shownVideo: boolean = true;
    public shownGallery: boolean = true;
    public shownMap: boolean = true;
    public hasGalleryRight: boolean = false;
    public hasDetailsRight: boolean = false;
    public videoData: { fileName: string, url: any } = { fileName: null, url: null };

    private _subscriptions: Subscription[] = [];

    @Output() closeIncidentEventEmitter: EventEmitter<number> = new EventEmitter();
    @Input() incident: RerIncident;

    constructor(
        private rerService: RerService,
        public translateService: TranslateService,
        private translationService: TranslateService,
        @Inject(LOCALE_ID) private locale: string) { }

    ngOnInit() {

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_INCIDENT_DETAILS)) {
			this.hasDetailsRight = true;
		}
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_IMAGE_GALLERY)) {
			this.hasGalleryRight = true;
		}

    }

    public onSave(): void {

        if(this.editMode){
            this.showSpinner = true;
            this.rerService.saveIncident(this.incident).subscribe(
                () => {
                    this.showSpinner = false;
                    this.rerService.showSaveConfirmation(
                        this.translationService.instant('dataSuccessfullySaved'));
                    this.editMode = false;
                    this._subscriptions.push(
                        this.rerService.getRerIncidentLog(this.incident.id)
                            .pipe(tap((costs: RerIncidentLog[]) => costs.map((log: RerIncidentLog) => {
                                log.text = this.rerService.getLogText(log)
                                log.dateShown = this.checkShowDate(log.created_at);
                            })))
                            .subscribe(
                                (logs: RerIncidentLog[]) => {
                                    this.incident.logs = logs;
                                }
                            )
                    );
                },
                () => {
                    this.showSpinner = false;
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            );
        }
    }

    public checkShowDate(dateToCheck: string) {
        const dateTimePipe = new DatePipe(this.locale);
        dateToCheck = dateTimePipe.transform(dateToCheck, 'dd.MM.YYYY', null, this.locale);
        return dateToCheck;
    }

    public onCancel(): void {
        this.editMode = false;
    }
    
}
