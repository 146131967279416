import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { StatusCodes } from 'http-status-codes';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

import { AuthService } from 'app/service/auth.service';
import { RerService } from 'app/modules/rer/_service/rer.service';
import { EndpointsService } from 'app/service/endpoints.service';

import { RerWasteType } from 'app/modules/rer/_model/rerwastetype.model';
import { RerEmployee } from './../../_model/reremployee.model';
import { Device } from 'app/modules/model/settings/device.model';
import { RerPlanning } from '../../_model/rerplanning.model';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { CopyFromDateComponent } from './copy-from-date/copy-from-date.component';
import { AddEditListItemComponent } from './add-edit-list-item/add-edit-list-item.component';
import { MessageDialogComponent } from 'app/modules/tms/shipping/add-edit/message-dialog/message-dialog.component';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { ExportPeriodDialogComponent } from '../../data-export-rer/export-period-dialog/export-period-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit {

    @Input() hasEditRight: boolean;
    @Input() hasPlanningAddressRight: boolean;
    @Output() editPlanningEventEmitter: EventEmitter<any> = new EventEmitter();
    @Output() detailsPlanningEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() closePlanningEventEmitter: EventEmitter<number> = new EventEmitter();

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    public rerPlanningList$: Observable<RerPlanning[]>;
    public dataSource: MatTableDataSource<any>;

    public displayedColumns: string[];
    public selectedTypeList: string[] = [];
    public showSpinner: boolean;
    public currentFilterValue: string = null;
    public periodBegin: any;
    public periodEnd: any;
    public hasDetailsRight: boolean = false;
    public addDialogRef;

    private _subscriptions: Subscription[] = [];
    private deviceList$: Observable<Device[]>;
    private employeeList$: Observable<RerEmployee[]>;
    private wasteTypeList$: Observable<RerWasteType[]>;

    public deviceList: Device[] = [];
    public driverList: RerEmployee[] = [];
    public operatorList: RerEmployee[] = [];
    public wasteTypeList: RerWasteType[] = [];


    constructor(
        private rerService: RerService,
        public dialog: MatDialog,
        public messageDialog: MatDialog,
        public actionDialog: MatDialog,
        private endpointsService: EndpointsService,
        private httpClient: HttpClient,
        private translationService: TranslateService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.periodEnd = new Date(new Date().getTime()).toISOString().slice(0, 10);
        this.periodBegin = new Date(new Date().getTime()).toISOString().slice(0, 10);

        this.rerPlanningList$ = this.rerService.rerPlanningList$;

        this.displayedColumns = ['id', 'date', 'route_code', 'rer_route', 'rer_waste_type_id', 'device_name', 'period_begin', 'period_end',
            'area', 'main_employee_name', 'secundary_employee_name', 'tertiary_employee_name', 'notes', 'percent', 'actions'];
        if (this.route.snapshot.queryParams['planningId']) {
            this._subscriptions.push(this.rerService.getPlannings(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')).subscribe(
                (plannings: RerPlanning[]) => {
                    if (plannings) {
                        this.periodBegin = moment().format('YYYY-MM-DD');
                        this.periodEnd = moment().format('YYYY-MM-DD');
                        this.showSpinner = false;
                        this.rerService.rerPlanningListSubject.next(plannings);
                        if (plannings.length === 0) {
                            this.messageDialog.open(MessageDialogComponent,
                                {
                                    data: {
                                        message: this.translationService.instant('Rer.rerPlanning.list.emptyPlanningList'),
                                        messageCode: 'warning'
                                    }
                                }
                            );
                        }
                        this.detailsPlanningEventEmitter.emit(parseInt(this.route.snapshot.queryParams['planningId']));
                    }
                },
                (error) => {
                    this.showSpinner = false;
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            ));

        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_DETAILS)) {
            this.hasDetailsRight = true;
        }
        this.deviceList$ = this.rerService.deviceList$;
        this.employeeList$ = this.rerService.employeeList$;
        this.wasteTypeList$ = this.rerService.wasteTypeList$;
        this.setSubscriptions();
    }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'date': return moment(item.date);
                case 'route_code': return Number(item.route_code);
                default: return item[property];
            }
        }
        this.dataSource.sort = this.sort;
    }


    private setSubscriptions() {
        this._subscriptions.push(
            this.rerService.intervalForPlanningsChangedSubject.subscribe(date => {
                if (date) {
                    this.periodBegin = date;
                    this.periodEnd = date
                }
            }),
            this.rerPlanningList$.subscribe(
                (plannings: RerPlanning[]) => {
                    this.dataSource = new MatTableDataSource(plannings);
                    this.setDataTablePaginatorSort();
                    if (this.currentFilterValue) {
                        this.applyFilter(this.currentFilterValue);
                    }
                }
            ),
            this.deviceList$.subscribe(
                (devices: Device[]) => {
                    this.deviceList = devices;
                }
            ),
            this.employeeList$.subscribe(
                (employee: RerEmployee[]) => {
                    this.driverList = employee.filter(
                        (driver: RerEmployee) => driver.type === RerEmployee.TYPE_DRIVER);
                    // this.addDialogRef.data.driverList = this.driverList;
                    this.operatorList = employee.filter(
                        (operator: RerEmployee) => operator.type === RerEmployee.TYPE_OPERATOR);
                    // this.addDialogRef.data.operatorList = this.operatorList;
                }
            ),
            this.wasteTypeList$.subscribe(
                (wasteTypes: RerWasteType[]) => {
                    this.wasteTypeList = wasteTypes;

                }
            )
        );
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public editPlanning(id: number): void {
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_EDIT)) {
            let planning = this.rerService.getPlanningById(id);
            let date = this.periodBegin === this.periodEnd ? this.periodBegin : new Date(new Date().getTime()).toISOString().slice(0, 10);
            if (!planning) {
                planning = new RerPlanning();
                planning.id = 0;
            } else {
                planning.period_begin = moment.utc(planning.period_begin).local().format('HH:mm');
                planning.period_end = moment.utc(planning.period_end).local().format('HH:mm');
                date = planning.date;
            }

            // console.log(planning);

            this.addDialogRef = this.actionDialog.open(
                AddEditListItemComponent,
                {
                    width: '800px',
                    disableClose: true,
                    panelClass: 'custom-dialog-container',
                    data: {
                        date: date,
                        planning: planning,
                        deviceList: this.deviceList,
                        wasteTypeList: this.wasteTypeList,
                        hasPlanningAddressRight: this.hasPlanningAddressRight
                    }
                }
            );
            this.addDialogRef.afterClosed().subscribe(
                (obj) => {
                    if (obj) {
                        this.rerService.showSaveConfirmation(
                            this.translationService.instant('dataSuccessfullySaved'));
                    }
                }
            );
        } else {
            this.showSpinner = false;
            this.rerService.showErrorNotifications(
                this.translationService.instant('Rer.rerPlanning.rightMissing'));
        }
    }

    public detailsPlanning(planningId): void {
        this.detailsPlanningEventEmitter.emit(planningId);
    }

    public deletePlanning(id: number): void {
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_EDIT)) {
            const dialogRef = this.actionDialog.open(
                ConfirmActionComponent,
                {
                    maxWidth: '800px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        headerMessage: this.translationService.instant('delete'),
                        contentMessage: this.translationService.instant('Rer.rerPlanning.list.actionDeleteConfirmation')
                    }
                }
            );

            dialogRef.afterClosed().subscribe(
                (response: boolean) => {
                    if (response) {
                        this.showSpinner = true;
                        const planning = this.rerService.getPlanningById(id);
                        this.rerService.deletePlanning(planning.id).subscribe(
                            () => {
                                this.showSpinner = false;
                                this.rerService.showSaveConfirmation(
                                    this.translationService.instant('dataSuccessfullySaved'));
                            },
                            () => {
                                this.showSpinner = false;
                                this.rerService.showErrorNotifications(
                                    this.translationService.instant('errorNotification'));
                            }
                        );
                    } else {
                        this.showSpinner = false;
                    }
                }
            );
        } else {
            this.showSpinner = false;
            this.rerService.showErrorNotifications(
                this.translationService.instant('Rer.rerPlanning.rightMissing'));
        }
    }

    public loadArchivedPlannings(): void {
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent, { width: '450px' }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    if (moment(response.endDate).diff(moment(response.startDate), 'days') > 30) {
                        alert(this.translationService.instant('Rer.rerPlanning.list.archiveDateLimit'));
                    } else {
                        this.showSpinner = true;
                        this.closePlanningEventEmitter.emit(-1);
                        this.rerService.getPlannings(response.startDate, response.endDate).subscribe({
                            next:(plannings: RerPlanning[]) => {
                                if (plannings) {
                                    this.periodBegin = response.startDate;
                                    this.periodEnd = response.endDate;
                                    this.showSpinner = false;
                                    this.rerService.rerPlanningListSubject.next(plannings);
                                    dialogRef.close();
                                    if (plannings.length === 0) {
                                        this.messageDialog.open(MessageDialogComponent,
                                            {
                                                data: {
                                                    message: this.translationService.instant('Rer.rerPlanning.list.emptyPlanningList'),
                                                    messageCode: 'warning'
                                                }
                                            }
                                        );
                                    }
                                }
                            },
                            error:(error) => {
                                this.showSpinner = false;
                                dialogRef.close();
                                this.rerService.showErrorNotifications(
                                    this.translationService.instant('errorNotification'));
                            }
                        });
                    }
                }
            });
    }

    public copyPlanning(): void {
        const dialogRef = this.dialog.open(
            CopyFromDateComponent, { width: '450px' }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response.copyDate && response.date && response.date !== response.copyDate) {
                    this.showSpinner = true;
                    return this.rerService.copyRerPlanning(response.copyDate, response.date).subscribe(
                        (resp: any) => {
                            this.showSpinner = false;
                            this.messageDialog.open(MessageDialogComponent,
                                {
                                    data: {
                                        message: this.translationService.instant('Rer.rerPlanning.list.copyPlanningSuccess'),
                                        messageCode: 'success'
                                    }
                                }
                            );
                        },
                        (error: HttpErrorResponse) => {
                            this.showSpinner = false;
                            let errorMessage = 'errorNotification';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rerPlanning.list.emptyCopyList';
                                    break;
                                case StatusCodes.UNPROCESSABLE_ENTITY:
                                    errorMessage = 'Rer.rerPlanning.list.periodBeginErrror';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage), '600px');
                        }
                    );
                }
            });
    }

    public export(): void {
        const dialogRef = this.actionDialog.open(
            ExportPeriodDialogComponent,
            {
                width: '500px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {}
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportPlannings', [
                        obj.startDate, obj.endDate]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                let fileName: any = this.translationService.instant('Rer.rerPlanning.exportPlannings');
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + moment().format('YYYY-MM-DD') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => this.showNotification()
                        );
                }
                this.showSpinner = false;
            }
        );
    }

    private showNotification(): void {
        this.showSpinner = false;
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }

    public getWasteTypeName(id: number) {
        if (!id) {
            return '';
        }

        let wasteType = this.rerService.getWasteTypeById(id);
        return wasteType ? wasteType.name : '';
    }

    public showPlanningPercentage(rerRoutePlanningExecution: any) {
        if (!rerRoutePlanningExecution) {
            return 0;
        }
        const target = rerRoutePlanningExecution.length;
        const completed = rerRoutePlanningExecution.filter((el: any) => el.period_begin).length;

        return completed ? Math.ceil(completed / target * 100) : 0;
    }
}
