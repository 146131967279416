import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../_service/rer.service';

import { RerEmployee } from '../../_model/reremployee.model';

@Component({
  selector: 'app-add-edit',
  templateUrl: './add-edit.component.html',
  styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
  private readonly pattern = /[0-9]/;

  public showMandatory: boolean;
  public showSpinner: boolean;
  public employeeType: any;

  @Input() employee: RerEmployee;
  @Output() changeClientStatusEventEmitter: EventEmitter<number> = new EventEmitter();

  constructor(
    private rerService: RerService,
    public actionDialog: MatDialog,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.employeeType = this.rerService.employeeType;
  }

  public onSave(): void {
    if (!this.employee.type){
      this.showMandatory = true;
    } else {
      if (this.employee.type == RerEmployee.TYPE_DRIVER){
        this.showMandatory = (this.employee.name && this.employee.type && this.employee.phone) ? false : true;
      } else {
        this.showMandatory = (this.employee.name && this.employee.type) ? false : true;
      }
    }

    if (!this.showMandatory) {
      this.showSpinner = true;
      this.rerService.addNewEmployee(this.employee).subscribe(
        () => {
          this.showSpinner = false;
          this.rerService.showSaveConfirmation(
            this.translationService.instant('dataSuccessfullySaved'));
        },
        () => {
          this.showSpinner = false;
          this.rerService.showErrorNotifications(
            this.translationService.instant('errorNotification'));
        }
      );
    }
  }

  public onUpdate(): void {
    if (!this.employee.type){
      this.showMandatory = true;
    } else {
      if (this.employee.type == RerEmployee.TYPE_DRIVER){
        this.showMandatory = (this.employee.name && this.employee.type && this.employee.phone) ? false : true;
      } else {
        this.showMandatory = (this.employee.name && this.employee.type) ? false : true;
      }
    }

    if (!this.showMandatory) {
      this.showSpinner = true;
      this.rerService.updateEmployee(this.employee).subscribe(
        () => {
          this.showSpinner = false;
          this.rerService.showSaveConfirmation(
            this.translationService.instant('dataSuccessfullySaved'));
        },
        () => {
          this.showSpinner = false;
          this.rerService.showErrorNotifications(
            this.translationService.instant('errorNotification'));
        }
      );
    }
  }

  public removeChars(event: KeyboardEvent) {
    if (!this.pattern.test(event.key)) {
      event.preventDefault();
    }
  }
}