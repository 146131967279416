/// <reference types="@types/google.maps" />

import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import * as StringPixelWidth from 'string-pixel-width';

import { GoogleMapCustomControl } from '../../../../service/shared/googlemapcustomcontrol.service';
import { GoogleMapDrawService } from '../../../../service/shared/googlemapdraw.service';
import { GoogleMapTileService } from '../../../../service/shared/googlemaptile.service';
import { UserPreferencesService } from '../../../../../service/userpreferences.service';
import { MarkerIconService } from '../../../../service/shared/markericon.service';
import { HistoryService } from '../../../../service/map/history.service';
import { AuthService } from 'app/service/auth.service';
import { MapService } from '../../../../service/shared/map.service';
import { PositionSocketService } from 'app/modules/service/map/position-socket.service';

import { environment } from 'environments/environment';

import { Subject } from 'rxjs';
import { RerPlanning } from 'app/modules/rer/_model/rerplanning.model';
import { RerService } from 'app/modules/rer/_service/rer.service';
import * as moment from 'moment-timezone';
import { RerRfidTagReading } from 'app/modules/rer/_model/rerrfidtagreading.model';
import { GeneralSocket } from 'app/service/general-socket.service';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})

export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('gmap', { static: false }) gmapElement: any;
    private map: google.maps.Map;
    private _subscriptions: Subscription[] = [];
    private zoomlevelOnSelectMarker = 20;
    private availableMapTypes: any[];
    private mapProp = {
        center: this.authService.getLatLngFromString(this.userPreferencesService.userPreferences.defaultMapCenter),
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, mapTypeIds: [], position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoom: this.googleMapDrawService.zoomlevelOnOverview, // 5
        controlSize: 32,
        fullscreenControl: true,
        fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
    };
    private trafficLayer: google.maps.TrafficLayer;
    private kmlLayerCurrentZoom = 0;
    private zipLayersCurrentZoom = 0;
    private timeIntervalForMovingDevices = 7000;    //  7 seconds
    private deviceDataGroups: any = {};
    private planningReadings: RerRfidTagReading[] = [];
    private markers: any = {};
    private firstTimeMarkersBound: boolean = false;
    private firstTimeVehicleHistoryBound: boolean = false;
    private wasteType: string;
    public deviceMaximumSpeed;
    private invoiceableReadings: RerRfidTagReading[] = [];
    private nonInvoiceableReadings: RerRfidTagReading[] = [];

    public selectedRfidReadingId: number;
    public sidebarActive: boolean;
    public showothers = false;
    public kmlLayersShown = false;
    public kmlList: any = {};
    public zipLayersShown = false;
    public zipMarkers: any = {};
    public isMobile = false;
    public dateFormat: string;
    public spinnerSubject: Subject<boolean> = new Subject();
    public showSpinner: boolean = false;
    public marker: any;
    public points: any = [];
    public lastTransmition: string;
    public lastVehiclePosition = {
        latitude: 0,
        longitude: 0
    };
    public path = new google.maps.Polyline({
        geodesic: true,
        strokeColor: "#3b5297",
        strokeOpacity: 1.0,
        strokeWeight: 5,
    });

    @Input() planning: RerPlanning;


    @Output() lastTransmitionEventEmitter: EventEmitter<string> = new EventEmitter();
    @Output() readingsCountEventEmitter: EventEmitter<object> = new EventEmitter();
    @Output() changeDateEventEmitter: EventEmitter<{ date: string, planningId: number }> = new EventEmitter();

    constructor(
        private mapService: MapService,
        private rerService: RerService,
        private translateService: TranslateService,
        private googleMapDrawService: GoogleMapDrawService,
        private markerIconService: MarkerIconService,
        private googleMapTileService: GoogleMapTileService,
        private googleMapCustomControlService: GoogleMapCustomControl,
        private userPreferencesService: UserPreferencesService,
        private authService: AuthService,
        private historyService: HistoryService,
        private positionSocketService: PositionSocketService,
        private generalSocketService: GeneralSocket
    ) {
        this.translateService.setDefaultLang('ro_RO');
        const lang = localStorage.getItem('currentLang');
        if (lang) {
            this.translateService.use(lang);
        }
    }

    ngOnInit() {
        if (window.screen.width <= 768) {
            this.isMobile = true;
        }
        window.onresize = () => this.isMobile = window.innerWidth <= 768;
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.zoomlevelOnSelectMarker = (this.userPreferencesService.userPreferences &&
            this.userPreferencesService.userPreferences.map && this.userPreferencesService.userPreferences.map.mapZoomlevel) ?
            this.userPreferencesService.userPreferences.map.mapZoomlevel : this.zoomlevelOnSelectMarker;

        this.sidebarActive = true;

        this.rerService.getPlanningRfidTagReadingList(this.planning.id);
        this.generalSocketService.wssSubscribeToEntity('rer_planning', this.planning.id)
        this.wasteType = this.planning.rer_waste_type_id ? this.rerService.getWasteTypeById(this.planning.rer_waste_type_id).name : null;
        this.setSubscriptionsForOnInit();

    }

    private setSubscriptionsForOnInit(): void {
        this._subscriptions.push(
            this.rerService.getRerSettings().subscribe(result => {
                if (result) {
                    const rs = result.find(x => x.name == 'device_max_speed');
                    this.deviceMaximumSpeed = rs?.value;
                }
            }),
            this.rerService.planningsRfidReadingsChangedSubject.subscribe(result => {
                let planning = result.find(x => x.id == this.planning.id);
                if (planning) {
                    this.planningReadings = planning.readings;
                    this.drawReadings();
                    this.calculateReadingsCount();
                    if (!this.firstTimeMarkersBound && this.planningReadings && this.planningReadings.length > 0) {
                        this.firstTimeMarkersBound = true;
                        this.setMapBounds()
                    }
                }
            })
        );
    }

    ngAfterViewInit() {

        if (environment.isGoogleMapsAvailable) {
            this.availableMapTypes = [
                google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN,
                google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID,
                'openStreetsMap', 'mapBoxCustom',
            ];
            this.mapProp['streetViewControlOptions'] = {
                icon: '/assets/project/' + environment.projectName + '/images/favicon.png', position: google.maps.ControlPosition.RIGHT_TOP
            };
        } else {
            this.availableMapTypes = ['openStreetsMap'];
        }

        this.mapProp.mapTypeControlOptions.mapTypeIds = this.availableMapTypes;
        this.mapProp['streetViewControl'] = environment.isGoogleMapsAvailable ? true : false;

        this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);

        this.map.addListener('maptypeid_changed', () => this.onMapTypeIdChanged());
        this.map.addListener('bounds_changed', () => setTimeout(() => this.setGoogleMapControlsDesign(), 1000));


        if (environment.isGoogleMapsAvailable) {
            this.trafficLayer = new google.maps.TrafficLayer();
            this.googleMapCustomControlService.createTrafficLayerControl(this.trafficLayer, this.map);
        }

        const openStreetsMap = this.googleMapTileService.getOSMTileLayer();
        this.map.mapTypes.set('openStreetsMap', openStreetsMap);
        const mapBoxCustom = this.googleMapTileService.getMapBoxCustomTileLayer();
        this.map.mapTypes.set('mapBoxCustom', mapBoxCustom);

        this.map.setMapTypeId(this.availableMapTypes.indexOf(this.userPreferencesService.userPreferences.map.mapTypeId) >= 0
            ? this.userPreferencesService.userPreferences.map.mapTypeId : 'openStreetsMap');
        this.setSubscriptionsForAfterViewInit();
    }

    private setSubscriptionsForAfterViewInit(): void {

        var currentdate = new Date();
        var datetime = currentdate.getFullYear() + "-"
            + (currentdate.getMonth() + 1) + "-"
            + currentdate.getDate() + " "
            + currentdate.getHours() + ":"
            + ((currentdate.getMinutes() < 10 ? '0' : '') + currentdate.getMinutes()) + ":"
            + ((currentdate.getSeconds() < 10 ? '0' : '') + currentdate.getSeconds());

        if (this.planning && this.planning.device) {
            this.fetchPositions();
            this._subscriptions.push(
                this.rerService.rerFilterByInvoiceableSubject.subscribe(
                    type => {
                        if (type != null) {
                            Object.entries(this.markers).forEach(([key, value]) => {
                                let found = type ? this.invoiceableReadings.find(x => x.id == key) : this.nonInvoiceableReadings.find(x => x.id == key);
                                if (found) {
                                    this.markers[key].marker.setMap(this.map);
                                } else {
                                    this.markers[key].marker.setMap(null);
                                }
                            });
                        } else {
                            Object.entries(this.markers).forEach(([key, value]) => {
                                this.markers[key].marker.setMap(this.map);
                            });
                        }

                    })
            );
        }
    }

    public received = 0;
    public fetchPositions() {
        this._subscriptions.push(this.historyService.getVehiclesHistoryPositions({
            apiEndPoint: this.planning.device.data_server.hostname,
            deviceId: this.planning.device.traccar_device_id,
            startDate: this.planning.period_begin,
            endDate: this.planning.period_end,
            received: this.received,
        }).subscribe(
            (positions) => {
                if (positions.length > 0) {
                    positions.forEach(element => {
                        this.points.push({
                            lat: element.latitude,
                            lng: element.longitude
                        });
                    });
                    this.path.setPath(this.points);
                    this.path.setMap(this.map);
                    this.lastTransmition = positions[positions.length - 1].devicetime;
                    this.lastTransmitionEventEmitter.emit(positions[positions.length - 1].devicetime);
                    if (!this.firstTimeVehicleHistoryBound && !this.firstTimeMarkersBound) {
                        this.firstTimeVehicleHistoryBound = true;
                        this.setMapBounds();
                    }
                    if (moment().isAfter(moment(this.planning.period_begin)) && moment().isBefore(moment(this.planning.period_end))) {
                        this.handleInProgressPlanning(positions[positions.length - 1]);
                    }
                    if(positions.length >= 10000) {
                        this.received += 10000;
                        this.fetchPositions();
                    }
                }
            }
        ));
    }

    private drawReadings() {
        if (this.planningReadings) {
            this.planningReadings.forEach(element => {
                if (!this.markers[element.id]) {
                    let binType = this.rerService.getBinTypeById(element.bin_type_id);
                    RerRfidTagReading.getStatusOptions(element, this.wasteType, this.deviceMaximumSpeed, binType).then(result => {
                        const svgMarker = {
                            path: result.icon,
                            fillColor: result.color,
                            fillOpacity: 1,
                            rotation: 0,
                            scale: binType.scale ? parseFloat(binType.scale) : 0.03,
                            origin: new google.maps.Point(0, 0),
                        };
                        var myLatlng = new google.maps.LatLng(parseFloat(element.latitude), parseFloat(element.longitude));
                        var marker = new google.maps.Marker({
                            position: myLatlng,
                            icon: svgMarker
                        });
                        marker.setMap(this.map);
                        this.markers[element.id] = {};
                        this.markers[element.id].marker = marker;
                        marker.addListener("click", () => {
                            this.selectedRfidReadingId = element.id;
                            if (!this.markers[element.id].infoMarker) {
                                Object.entries(this.markers).forEach(([key, value]) => {
                                    if (element.id != key && value['infoMarker']) {
                                        value['infoMarker'].close();
                                        value['infoMarker'] = false;
                                    }
                                });
                                this.markers[element.id].infoMarker = this.markers[element.id].infoMarker = this.generateInfoBox(element);
    
                                this.markers[element.id].infoMarker.open(this.map, marker);
                            } else {
                                this.markers[element.id].infoMarker.close();
                                this.markers[element.id].infoMarker = false;
                                this.selectedRfidReadingId = null;
                            }
                        });
                    });
                }
            });
            if (this.markers && this.markers.length > 0) {
                this.map.addListener("click", () => {
                    Object.entries(this.markers).forEach(([key, value]) => {
                        if (value['infoMarker']) {
                            value['infoMarker'].close();
                            value['infoMarker'] = false;
                            this.selectedRfidReadingId = null;
                        }
                    });
                });
            }
            this.map.addListener("drag", () => {
                Object.entries(this.markers).forEach(([key, value]) => {
                    if (value['infoMarker']) {
                        value['infoMarker'].close();
                        value['infoMarker'] = false;
                        this.selectedRfidReadingId = null;
                    }
                });
            });
        }
    }

    changePlanningDate(event) {
        this.changeDateEventEmitter.emit(event);
    }

    public centerReading(reading) {
        if (!this.markers[reading.id].infoMarker) {
            Object.entries(this.markers).forEach(([key, value]) => {
                if (reading.id != key && value['infoMarker']) {
                    value['infoMarker'].close();
                    value['infoMarker'] = false;
                }
            });
            this.markers[reading.id].infoMarker = this.generateInfoBox(reading);


            this.markers[reading.id].infoMarker.open(this.map, this.markers[reading.id].marker);
            this.focusOnLocation(reading);
        } else {
            this.markers[reading.id].infoMarker.close();
            this.markers[reading.id].infoMarker = false;
        }
    }

    private handleInProgressPlanning(lastPosition) {
        if (lastPosition) {
            this.createUpdateDevicesOnMap(lastPosition);
            this.lastVehiclePosition.latitude = lastPosition.latitude;
            this.lastVehiclePosition.longitude = lastPosition.longitude;
        }
        if (this.deviceDataGroups.hasOwnProperty(this.planning.device.data_server.hostname)) {
            this.deviceDataGroups[this.planning.device.data_server.hostname].tcdeviceids.push(this.planning.device.traccar_device_id);
        } else {
            this.deviceDataGroups[this.planning.device.data_server.hostname] = { tcdeviceids: [this.planning.device.traccar_device_id], apierror: 0 };
        }
        this.positionSocketService.startWssWorkers(this.deviceDataGroups);
        this.requestPositionUpdates();
        this._subscriptions.push(
            this.rerService.rerMapVehicleLocationSubject.subscribe(x => {
                if (this.lastVehiclePosition.latitude && this.lastVehiclePosition.longitude && x) {
                    this.focusOnLocation(this.lastVehiclePosition);
                }
            }),
            this.mapService.sendPositionToMapSubject.subscribe(
                pos => {
                    // comment for debugging reasons
                    // console.log(pos, this.getVehicleByDeviceId(pos.deviceid).name);
                    if (pos.deviceid == this.planning.device.traccar_device_id) {
                        this.lastTransmition = pos.devicetime;
                        this.lastTransmitionEventEmitter.emit(pos.devicetime);
                        this.createUpdateDevicesOnMap(pos);
                        this.lastVehiclePosition.latitude = pos.latitude;
                        this.lastVehiclePosition.longitude = pos.longitude;
                    }
                })
        );
    }

    private generateInfoBox(element: RerRfidTagReading) {
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const pixelOffset = StringPixelWidth(element.rfid, { font: 'Open Sans', size: 15, bold: false }) + 25;
        const contentString = '<div class="label" id="' + element.rfid + '">' + element.rfid +
            (element.client_name ? '<br>' + element.client_name : '') +
            (element.waste_type ? '<br>' + element.waste_type : '') + (element.bin_type ? ' / ' + element.bin_type : '') +
            (element.client_address ? '<br>' + element.client_address : '') +
            '<br>' + this.translateService.instant('Rer.clients.addEdit.rfidReadings.dataTable.coords') + ': ' + element.latitude + ',' + element.longitude +
            '<br>' + this.translateService.instant('Rer.rerPlanning.vehicleSpeed') + element.vehicle_speed + ' km/h' +
            '</div>';
        let infoBox = new InfoBox({
            boxStyle: {
                width: pixelOffset + "px",
            },
            content: contentString, disableAutoPan: true, alignBottom: true, visible: true,
            pixelOffset: new google.maps.Size(-1 * (((pixelOffset > 350 ? 340 : pixelOffset) / 2)), -20),
            infoBoxClearance: new google.maps.Size(1, 1), closeBoxURL: 'assets/images/map/close.png'
        });
        return infoBox;
    }

    private requestPositionUpdates() {
        // Every this.timeIntervalForMovingDevices the position on map for devices will be updated
        this._subscriptions.push(
            interval(this.timeIntervalForMovingDevices).subscribe(
                () => {
                    let i = 0;
                    const deviceDataGroupsProp = Object.keys(this.deviceDataGroups);
                    //  testing websocket connections
                    // this.positionSocketService.dsWorkers.map(
                    //     dsconn => console.log(dsconn.wssurl, dsconn.wssConnection.readyState)
                    // );

                    if (deviceDataGroupsProp.length) {
                        deviceDataGroupsProp.forEach(
                            deviceData => {
                                const dsWorker = this.positionSocketService.dsWorkers.find(ds => ds.wssurl === deviceData);
                                //  Check if we have connection to WebSocket Server
                                if (dsWorker && dsWorker.wssConnection.readyState === 3) {
                                    //  Reconnect client to WebSocket server
                                    dsWorker.wssCreate();
                                    //  Make a api request for positions
                                    this.mapService.getLastMapPosition(deviceData, this.deviceDataGroups[deviceData]['tcdeviceids'])
                                        .subscribe(
                                            (positions) => {
                                                positions.forEach(position => {
                                                    this.createUpdateDevicesOnMap(position)
                                                    this.lastTransmition = position.devicetime;
                                                    this.lastTransmitionEventEmitter.emit(position.devicetime);
                                                    this.lastVehiclePosition.latitude = position.latitude;
                                                    this.lastVehiclePosition.longitude = position.longitude;
                                                });
                                            },
                                        );
                                }
                            }
                        );
                    }
                }
            )
        );
    }

    focusOnLocation(coordinate) {
        var bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
        );
        this.mapFitToBounds(bounds, true);
    }

    setMapBounds() {
        let bounds = new google.maps.LatLngBounds();
        if (this.planningReadings && this.planningReadings.length > 0) {
            this.planningReadings.forEach(element => {
                bounds.extend(new google.maps.LatLng(element.latitude, element.longitude));
            });
        } else if (this.points && this.points.length > 0) {
            this.points.forEach(element => {
                bounds.extend(new google.maps.LatLng(element.lat, element.lng));
            });
        }

        this.mapFitToBounds(bounds);
    }

    calculateReadingsCount() {
        let readingsCount = {
            total: 0,
            invoiceable: 0,
            invalid: 0
        }
        if (this.planningReadings && this.planningReadings.length > 0) {
            readingsCount.total = this.planningReadings.length;
            this.invoiceableReadings = this.planningReadings.filter(x => x.status == 1
                && ((x.waste_type_id && x.waste_type_id == this.planning.rer_waste_type_id)
                    || !this.planning.rer_waste_type_id)
                && x.vehicle_speed < parseFloat(this.deviceMaximumSpeed));
            this.nonInvoiceableReadings = this.planningReadings.filter(x => !this.invoiceableReadings.includes(x));
            readingsCount.invoiceable = this.invoiceableReadings.length;
            readingsCount.invalid = readingsCount.total - readingsCount.invoiceable;
            this.readingsCountEventEmitter.emit(readingsCount);
        }
    }

    private mapFitToBounds(bounds: google.maps.LatLngBounds, waitForMap: boolean = true) {
        if (this.gmapElement && this.gmapElement.nativeElement.clientWidth) {
            this.map.fitBounds(bounds);
        } else {
            if (waitForMap) {
                setTimeout(() => { this.mapFitToBounds(bounds, false); }, 1000);
            }
        }
    }

    private createUpdateDevicesOnMap(pos, deviceId = null): boolean {
        let position;
        if (pos && !((pos.latitude === 0 && pos.longitude === 0) || pos.latitude < -90 || pos.latitude > 90 || pos.longitude < -180 ||
            pos.longitude > 180)) {
            position = { deviceid: deviceId, latitude: pos.latitude, longitude: pos.longitude, map: this.map };
        } else {
            return false;
        }

        this.points.push({ lat: pos.latitude, lng: pos.longitude });
        this.path.setPath(this.points);
        this.path.setMap(this.map);

        let status = pos.ignitionstate ? (pos.ignitionstate == 'on' ? 2 : 1) : -1;

        const vehicleName = this.planning.device_name;
        if (this.marker) {
            this.marker.course = pos.course;
            this.marker.status = status;
            this.marker.icon.url = this.markerIconService.getMarkerIcon({ course: pos.course, status: status }, 'marker');
            this.marker.setPosition(new google.maps.LatLng(position.latitude, position.longitude));
        } else {
            this.marker = this.markerIconService.createMarker(
                { course: pos.course, status: status }, position.map, position.latitude, position.longitude, deviceId
            );
        }

        const pixelOffset = StringPixelWidth(vehicleName, { font: 'Open Sans', size: 15, bold: false }) + 25;
        const contentString = '<div class="label" id="' + vehicleName + '">' + vehicleName + '</div>';
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const info = new InfoBox({
            boxStyle: {
                width: pixelOffset + "px",
            },
            content: contentString, disableAutoPan: true, alignBottom: true, visible: true,
            pixelOffset: new google.maps.Size(-1 * (((pixelOffset > 350 ? 360 : pixelOffset) / 2) + 18), -20),
            infoBoxClearance: new google.maps.Size(1, 1), closeBoxURL: 'assets/images/map/close.png'
        });

        info.open(position.map, this.marker);

        return true;
    }


    private onMapTypeIdChanged() {
        const userPreferences = this.userPreferencesService.userPreferences;
        userPreferences.map.mapTypeId = this.map.getMapTypeId();
        this.userPreferencesService.userPreferences = userPreferences;
        this.authService.frontendPreferences = userPreferences;
        this.setGoogleMapControlsDesign();
    }

    private setGoogleMapControlsDesign(): void {
        const pegman = document.querySelector('.gm-svpc');
        if (pegman) {
            Array.from(pegman.lastChild['children']).forEach(
                (img: HTMLElement) => img['style'].width = img['style'].height = '20px!important'
            );
        }
        const gmnoprintButtonZoomOut = document.querySelector('.gmnoprint.gm-bundled-control > .gmnoprint > div > button:nth-child(1)');
        if (gmnoprintButtonZoomOut) {
            Array.from(gmnoprintButtonZoomOut.children).forEach(
                (img: HTMLElement) => {
                    img['src'] = GoogleMapCustomControl.zoomOutControllSrc;
                    img['style'].width = '20px';
                    img['style'].height = '20px';
                }
            );
        }
        const gmnoprintButtonZoomIn = document.querySelector('.gmnoprint.gm-bundled-control > .gmnoprint > div > button:nth-child(3)');
        if (gmnoprintButtonZoomIn) {
            Array.from(gmnoprintButtonZoomIn.children).forEach(
                (img: HTMLElement) => {
                    img['src'] = GoogleMapCustomControl.zoomInControllSrc;
                    img['style'].width = '20px';
                    img['style'].height = '20px';
                }
            );
        }
        const fullscreenButton = document.querySelector('.gm-style > .gm-control-active.gm-fullscreen-control');
        if (fullscreenButton) {
            Array.from(fullscreenButton.children).forEach(
                (img: HTMLElement) => {
                    img['src'] = GoogleMapCustomControl.fullscreenControlSrc;
                    img['style'].width = '20px';
                    img['style'].height = '20px';
                }
            );
        }
        const mapSettings = this.googleMapDrawService.styleMapTypes(this.map, this.zipLayersCurrentZoom, this.zipLayersShown,
            this.zipMarkers, this.kmlLayerCurrentZoom, this.kmlLayersShown, this.kmlList);
        if (mapSettings) {
            this.zipLayersCurrentZoom = mapSettings.zip.zipLayersCurrentZoom;
            this.zipLayersShown = mapSettings.zip.zipLayersShown;
            this.zipMarkers = mapSettings.zip.zipMarkers;
            this.kmlLayerCurrentZoom = mapSettings.boundary.kmlLayerCurrentZoom;
            this.kmlLayersShown = mapSettings.boundary.kmlLayersShown;
            this.kmlList = mapSettings.boundary.kmlList;
        }
    }

    ngOnDestroy(): void {
        let currentPlannings = this.rerService.planningsRfidReadingsChangedSubject.getValue();
        let index = currentPlannings.findIndex(x => x.id == this.planning.id);
        currentPlannings.splice(index, 1);
        this.rerService.planningsRfidReadingsChangedSubject.next(currentPlannings);
        this.generalSocketService.wssUnsubscribeEntity('rer_planning', this.planning.id)
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
        //  Unsubscribe to observable that prepare the positions for the devices
        //  Close websockets connections
        this.positionSocketService.dsWorkers.forEach(
            (worker) => {
                if (worker.deviceIds.includes(this.planning.device.traccar_device_id)) {
                    worker.wssConnection.close();
                    worker.dsData.forEach(
                        ds => {
                            if (ds.obs) {
                                ds.obs.unsubscribe();
                            }
                        }
                    );
                }
            }
        );
        this.positionSocketService.dsWorkers = [];
    }
}
