import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedModule } from '../../../shared/shared.module';

import { RoutePlanningSearchComponent } from './route-planning-search/route-planning-search.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: '.json' }, // common / shared translation
    { prefix: './assets/i18n/rer/', suffix: '.json' },
  ]);
}

@NgModule({
  declarations: [
    RoutePlanningSearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: true
    }),
  ]
})
export class RoutePlanningSearch { }
