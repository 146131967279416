<div class="row">
    <div class="col-md-6">
        <div class="datatTableFilter p-2 mb-4 d-flex" style="height: 46px;">
            <i class="icon-cts-action-search mr-2 font-size-20 color-grey pt-1"></i>
            <input matInput (keyup)="applyFilter($event.target.value)" [ngModel]="dataSource.filter"
                placeholder="{{ 'quicksearch' | translate }}" class="color">
        </div>
    </div>
    <div class="col-md-3">
        <app-datepicker [label]="getTranslationFor('Rer.rerPlanning.dataTable.date')" [includeTime]="false"
        (selectDateEventEmitter)="changePlanningDate($event)" [date]="planning.date" [maxHeight]="'48px'"></app-datepicker>
    </div>
    <div class="col-md-3 lastTransmitionbutton">
        <div class="btn button-component ml-2 bgcolor-blue" *ngIf="lastTransmition && compareDates(lastTransmition, planning.period_end, true)" 
            (click)="focusVehicleLocation()">
            <div class="inner inner-blue user-no-select pb-0 pt-0 pl-4 pr-4">
                <span class="color-white ml-0 font-size-12 display"> {{ 'Rer.rerPlanning.lastTransmition' | translate }}</span>
                <p class="color-white m-0 font-size-12 font-weight-bold date">{{
                    lastTransmition? (lastTransmition |
                    offset:true
                    | date:dateFormat + ' ' + 'HH:mm:ss') : "-" }}</p>
            </div>
                <div
                    *ngIf="!lastTransmition || (lastTransmition && compareDates(lastTransmition, planning.period_end, false))">
                    {{ lastTransmition ? (lastTransmition | offset:true
                        | date:dateFormat + ' ' + 'HH:mm:ss') : "-" }}
                </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort class="readings-list pr-0">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Rer.clients.addEdit.rfidReadings.dataTable.id' |
            translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.id }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="datetime_read">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{
            'Rer.clients.addEdit.rfidReadings.dataTable.datetime_read' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.datetime_read | offset:true | date:dateFormat + ' HH:mm:ss' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="client_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Rer.clients.addEdit.rfidReadings.dataTable.clientName'
            | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.client_name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Rer.clients.addEdit.rfidReadings.dataTable.wasteType' |
            translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.waste_type }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="bin_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Rer.clients.addEdit.rfidReadings.dataTable.binType' |
            translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.bin_type }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rfid">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.addEdit.rfidReadings.dataTable.rfid' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.rfid }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Rer.clients.addEdit.rfidReadings.dataTable.status' |
            translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngSwitch]="element.status" style="justify-content: center!important;"> 
            <span class="badge badge-grey" *ngSwitchCase = "null">
                {{ 'Rer.clients.addEdit.rfidReadings.status.no_object' |  translate }}
            </span>
            <span class="badge badge-warning" *ngSwitchCase="0">
                {{  'Rer.clients.addEdit.rfidReadings.status.' + element.status | translate }}
            </span>
            <span class="badge badge-success" *ngSwitchCase="1">
                  {{  'Rer.clients.addEdit.rfidReadings.status.' + element.status | translate }}
            </span>
            <span class="badge badge-danger" *ngSwitchCase="2">
                  {{  'Rer.clients.addEdit.rfidReadings.status.' + element.status | translate }}
            </span>
            <span class="badge badge-danger" *ngSwitchCase="3">
                  {{  'Rer.clients.addEdit.rfidReadings.status.' + element.status | translate }}
            </span>
            <span class="badge badge-danger" *ngSwitchCase="4">
                  {{  'Rer.clients.addEdit.rfidReadings.status.' + element.status | translate }}
            </span>
        </mat-cell>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ 'Rer.clients.addEdit.rfidReadings.dataTable.actions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
                <div *ngFor="let camera of device_cameras"><fa-icon *ngIf="hasCameraRight" [icon]="faVideo" (click)="streamIpCamera(element, camera)" title="{{ 'Rer.clients.addEdit.rfidReadings.dataTable.cameraStream' | translate }}"
                    class="pointer mr-2">
                </fa-icon></div>
                <div *ngFor="let camera of device_cameras"><fa-icon *ngIf="hasImageGalleryRight" [icon]="faImage" (click)="streamPhotos(element, camera)" title="{{ 'Rer.clients.addEdit.rfidReadings.dataTable.imageGallery' | translate }}"
                    class="pointer mr-2">
                </fa-icon></div>
                <div (click)="onCenterReading(row)"><fa-icon [icon]="faEye" class="mr-2 pointer" title="{{ 'Rer.clients.addEdit.rfidReadings.dataTable.readingMap' | translate }}"></fa-icon></div>
            </mat-cell>
        </ng-container>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row (click)="onCenterReading(row)" *matRowDef="let row; columns: displayedColumns;"
        class="mb-1 p-1 mr-3 pointer">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="1000" [pageSizeOptions]="[5, 10, 20, 50, 100, 1000]"
    [showFirstLastButtons]="true">
</mat-paginator>