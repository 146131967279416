<div class="datePickerContainer m-auto" [ngStyle]="{'z-index': zIndex}">
    <mat-form-field [ngStyle]="{'max-height': maxHeight }" [ngClass]="{'mat-focused': isActivated, 'disabled': disabled }" class="w-100">
        <mat-label>{{ label }}</mat-label>
        <input matInput [readonly]="true" value="{{ startDateTimeValue | date:dateFormatShow + timeFormat }} -- {{ endDateTimeValue | date:dateFormatShow + timeFormat }}" id="pDateBegin"
            autocomplete="off" class="font-size-14" (click)="onDatepickerIconClick($event)">
        <span  [ngClass]="disabled ? 'color-grey': 'pointer'" matSuffix id="spanDateBegin"
        (click)="onDatepickerIconClick($event)"><fa-icon [icon]="faCalendarAlt"></fa-icon></span>
    </mat-form-field>
    <div (appClickOutside)="onClickOutside($event)" class="calendar" *ngIf="isActivated" [ngClass]="{'double-border-left-blue' : doubleBorderLeft, 'double-border-top-blue' : doubleBorderTop}">
        <div class="calendar-navs">
            <div class="month-nav">
                <div (click)="prevMonth()"><span class="ml-4 icon-cts-chevron-left"></span></div>
                <span class="p4">{{ moment.months(this.currentDate.month()) | slice:0:3 }}
                    {{ currentDate.format('YYYY') }}</span>
                <div (click)="nextMonth()"><span class="mr-4 icon-cts-chevron-right"></span></div>
            </div>
        </div>
        <div class="month-grid d-flex-row">
            <div class="day-names justify-content-center">
                <div *ngFor="let name of dayNames" class="day-name p9">{{ name }}</div>
            </div>
            <div class="weeks">
                <div *ngFor="let week of weeks" class="week justify-content-center">
                    <ng-container *ngFor="let day of week">
                        <ng-container *ngIf="!isEnabled(day.mDate)">
                            <ng-template [ngIf]="!isSelectedMonth(day.mDate)" [ngIfElse]="strike">
                                <div class="week-date disabled" id="week-date">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                            <ng-template #strike>
                                <div class="week-date strike" id="week-date">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="isEnabled(day.mDate)">
                            <ng-template [ngIf]="isSelectedMonth(day.mDate)" [ngIfElse]="notSelectedMonth">
                                <div class="week-date enabled" id="week-date"
                                    (click)="selectDate(day)" [ngClass]="{ today: day.today, selected: day.selected }">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                            <ng-template #notSelectedMonth>
                                <div class="week-date disabled" id="week-date">
                                    <span id="date-text" class="date-text">{{ day.mDate.date() }}</span>
                                </div>
                            </ng-template>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
        <div *ngIf="time" class="d-flex time-container justify-content-around">
            <div>
                <span class="time-text">{{ 'startTime' | translate }}</span>
                <div class="d-flex justify-content-start align-items-center hhmm-container p-2">
                    <input class="text-center" type="text" id="startDateH" [(ngModel)]="startTime.hours"
                        name="startDateH" maxlength="2">
                    <span class="ml-1 mr-1 font-size-14">:</span>
                    <input [(ngModel)]="startTime.minutes" class="text-center" type="text" id="startDateM"
                        name="startDateM" maxlength="2">
                </div>
            </div>
            <div>
                <span class="time-text">{{ 'endTime' | translate }}</span>
                <div class="d-flex justify-content-start align-items-center hhmm-container p-2">
                    <input class="text-center" type="text" [(ngModel)]="endTime.hours" id="endDateH" name="endDateH"
                        maxlength="2">
                    <span class="ml-1 mr-1 font-size-14">:</span>
                    <input class="text-center" type="text" id="endDateM" [(ngModel)]="endTime.minutes" name="endDateM"
                        maxlength="2">
                </div>
            </div>
        </div>
        <div class="cal-util" (click)="onDatepickerIconClick($event)">{{ 'done' | translate }}</div>
    </div>
</div>
