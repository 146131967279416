import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'app/modules/model/menuitem.model';
import { RerService } from '../../_service/rer.service';
import { Observable } from 'rxjs';
import { RerCollectionByWeight } from '../../_model/rercollectionbyweight.model';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { ExportPeriodDialogComponent } from '../../data-export-rer/export-period-dialog/export-period-dialog.component';
import { MessageDialogComponent } from 'app/modules/map/dialogs/message-dialog/message-dialog.component';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { saveAs } from 'file-saver';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { RerCollectionByWeightService } from '../../_service/rercollectionbyweight.service';

@Component({
  selector: 'app-rer-reports-sent-automatically',
  templateUrl: './rer-reports-sent-automatically.component.html',
  styleUrls: ['./rer-reports-sent-automatically.component.scss']
})
export class RerReportsSentAutomaticallyComponent implements OnInit {

  public automaticReportsList$: Observable<RerCollectionByWeight[]>;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public showSpinner: boolean = false;
  public currentFilterValue: string = null;
  public dateFormat: string;
  public periodBegin: any;
  public periodEnd: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private rerCollectionByWeightService: RerCollectionByWeightService,
    private rerService: RerService,
    public dialog: MatDialog,
    private translationService: TranslateService,
    private userPreferencesService: UserPreferencesService,
    public messageDialog: MatDialog) { }

  ngOnInit() {
    this.displayedColumns = ['id', 'client_name', 'device_name', 'quantity', 'waste_type', 'datetime_sent', 'email_sent', 'period_begin', 'period_end', 'actions'];
    this.dateFormat = this.userPreferencesService.userPreferences.dateFormat;
    this.periodEnd = moment().format('YYYY-MM-DD');
    this.periodBegin = moment().subtract(7, 'd').format('YYYY-MM-DD');
    // this.automaticReportsList$ = this.rerService.getAutomaticReportsList(this.periodBegin, this.periodEnd);

    this.automaticReportsList$ = this.rerCollectionByWeightService.automaticReportsList$;

    this.automaticReportsList$.subscribe(
      (reports: RerCollectionByWeight[]) => {
        this.dataSource = new MatTableDataSource(reports);
        this.setDataTablePaginatorSort();
        if (this.currentFilterValue) {
          this.applyFilter(this.currentFilterValue);
        }
      }
    );
  }

  private setDataTablePaginatorSort(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public loadReportsByPeriod(): void {
    const dialogRef = this.dialog.open(
      ExportPeriodDialogComponent, { width: '450px' }
    );

    dialogRef.afterClosed().subscribe(
      (response) => {
        if (response) {
          this.showSpinner = true;
          this.rerCollectionByWeightService.getAutomaticReportsList(response.startDate, response.endDate).subscribe(
            (reports: RerCollectionByWeight[]) => {
              if (reports) {
                this.periodBegin = response.startDate;
                this.periodEnd = response.endDate;
                this.showSpinner = false;
                this.rerCollectionByWeightService.rerAutomaticReportsListSubject.next(reports);
                dialogRef.close();
                if (reports.length === 0) {
                  this.messageDialog.open(MessageDialogComponent,
                    {
                      data: {
                        message: this.translationService.instant('Rer.rerCollectionByWeight.emptyList'),
                        messageCode: 'warning'
                      }
                    }
                  );
                }
              }
            },
            (error) => {
              this.showSpinner = false;
              dialogRef.close();
              this.rerService.showErrorNotifications(
                this.translationService.instant('errorNotification'));
            }
          );
        }
      });
  }

  public downloadReport(reportId: number) {
    this.showSpinner = true;
    this.rerCollectionByWeightService.downloadAutomaticReport(reportId).subscribe(
      (response: any) => {
        if (response.data.length !== undefined) {
          const filename = response.filename;
          const content = response.data;
          const filetype = '';
          const byteCharacters = content;
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: filetype });
          this.showSpinner = false;
          saveAs(blob, filename);
        }
      },
      () => this.showErrorNotification()
    );
  }

  private showErrorNotification(): void {
    this.showSpinner = false;
    this.dialog.open(
      NotificationComponent,
      {
        maxWidth: '800px', panelClass: 'custom-dialog-container',
        data: {
          success: 0,
          headermessage: this.rerService.getTranslationFor('error'),
          message: this.rerService.getTranslationFor('generalError')
        }
      }
    );
  }
}