import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';

import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerService } from '../../_service/rer.service';

import { RerBin } from '../../_model/rerbin.model';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit {
    public rerBinList$: Observable<RerBin[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public cityFilterList$: Observable<string[]>;
    public divisionFilterList$: Observable<string[]>;
    public typeFilterList$: Observable<string[]>;
    public streetFilterList$: Observable<string[]>;
    public binOwnershipFilteredList$: Observable<String[]>;
    public selectedCityList: string[] = [];
    public selectedDivisionList: string[] = [];
    public selectedTypeList: string[] = [];
    public selectedStreetList: string[] = [];
    public selectedBinOwnsershipList: string[] = [];
    public showSpinner: boolean;
    public currentFilterValue: string = null;
    public dateFormat: string;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @Input() openTabsNumber: number;

    @Output() editRerBinEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() deleteRerBinEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() loadRerBinEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(
        private rerService: RerService,
        public dialog: MatDialog,
        private userPrefService: UserPreferencesService,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPrefService.userPreferences.dateFormat ?
            this.userPrefService.userPreferences.dateFormat : this.userPrefService.defaultPreferences.dateFormat;

        this.rerBinList$ = this.rerService.rerBinList$;
        this.cityFilterList$ = this.rerService.cityFilterList$;
        this.divisionFilterList$ = this.rerService.devisionFilterList$;
        this.streetFilterList$ = this.rerService.streetFilterList$;
        this.typeFilterList$ = this.rerService.typeFilterList$;
        this.binOwnershipFilteredList$ = this.rerService.binOwnershipFilteredList$;
        this.displayedColumns = [
            'client_type', 'rer_client_name', 'division', 'city', 'street', 'street_number', 'waste_type', 'bin_type', 'bin_ownership', 'status', 'rer_rfid', 'description', 'latitude',
            'rer_bin_setup_document_number', 'rer_bin_setup_document_status','datetime_read', 'vehicle_name',
        ];
        this.rerBinList$.subscribe(
            (clientObjects: RerBin[]) => {
                this.dataSource = new MatTableDataSource(clientObjects);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onEditRerBin(rowIndex: number): void {
        this.editRerBinEventEmitter.emit(rowIndex);
    }

    public onDeleteRerBin(rowIndex: number): void {
        this.deleteRerBinEventEmitter.emit(rowIndex);
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }

    private getRerBinList(): void {
        this.showSpinner = true;
        this.rerService.rerBins(
            {
                cityList: this.selectedCityList.join(','),
                divisionList: this.selectedDivisionList.join(','),
                typeList: this.selectedTypeList.join(','),
                streetList: this.selectedStreetList.join(','),
                binOwnershipList: this.selectedBinOwnsershipList.join(','),
                activeOnly: false
            }
        ).subscribe(
            () => this.showSpinner = false,
            () => this.showNotification()
        );
    }

    public onLoadList(): void {
        if (this.openTabsNumber) {
            const dialogRef = this.dialog.open(
                ConfirmActionComponent,
                {
                    width: '450px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        headerMessage: this.translationService.instant('Rer.confirmCloseTabs.title'),
                        contentMessage: this.translationService.instant('Rer.confirmCloseTabs.msg')
                    }
                }
            );
            dialogRef.afterClosed().subscribe(
                (response) => {
                    if (response) {
                        this.loadRerBinEventEmitter.emit();
                        this.getRerBinList();
                    }
                }
            );
        } else {
            this.getRerBinList();
        }
    }
}
