<div class="search-content d-flex align-items-center">
    <div class="datatTableFilter p-2 d-flex align-items-center w-100">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" class="flex-grow-1"
            placeholder="{{ 'Rer.dataImportRer.binSetupImport.dataTable.quickSearch' | translate }}">
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort class="w-100 mt-2" style="height: calc(100% - 215px);">
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.type' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.code' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.code }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.phone' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.phone }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.email' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="ml-2">{{ element.email  }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="addressCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.addressCode' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.addressCode }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="division">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.division' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.division }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="county">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.county' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.county }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.city' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.city }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="street">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.street' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.street }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="streetNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.streetNumber' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.streetNumber }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="wasteCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.wasteCode' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.wasteCode }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="binCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.binCode' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.binCode }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="binOwnershipCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.binOwnershipCode' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.binOwnershipCode }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="binSetupCode">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.binSetupCode' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.binSetupCode }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="binSetupDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.binSetupDate' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.binSetupDate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.dataImportRer.binSetupImport.dataTable.quantity' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.quantity }}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div class="d-flex align-items-end justify-content-between mt-2" style="height: 60px; border-top: 1px solid #eceef0;">
    <div class="button-component bgcolor-white" (click)="cancelImportEventEmitter.next(0)">
        <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
        </div>
    </div>
    <div class="button-component utility-green-bg-color" (click)="onDoImport()">
        <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
            <span
                class="color-white ml-0">{{ 'Rer.dataImportRer.binSetupImport.import' | translate }}</span>
        </div>
    </div>
</div>
