import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as geoXML3 from 'evoweb-geoxml3/polys/geoxml3.js';
import { MapService } from '../shared/map.service';
declare const geoXML3: any;

@Injectable()
export class GoogleKmlService {

    public availableCountries = [
        { value: 'at', label: 'Austria', zipPattern: true, bonduary: true },
        { value: 'be', label: 'Belgium', zipPattern: true, bonduary: false },
        { value: 'de', label: 'Germany', zipPattern: true, bonduary: true },
        { value: 'es', label: 'Spain', zipPattern: true, bonduary: false },
        { value: 'fr', label: 'France', zipPattern: true, bonduary: false },
        { value: 'pt', label: 'Portugal', zipPattern: true, bonduary: false },
        { value: 'uk', label: 'United Kingdom', zipPattern: true, bonduary: true },
        { value: 'it', label: 'Italy', zipPattern: true, bonduary: false },
        { value: 'nl', label: 'Nederland', zipPattern: true, bonduary: false }
    ];

    //  still need for kml layers
    public zoomIndexCountries = {
        'at': { '0': 4, '1': 5, '2': 8},
        'be': { '0': 4, '1': 5, '2': 8},
        'de': { '0': 4, '1': 5, '2': 8},
        'es': { '0': 4, '1': 5, '2': 8},
        'fr': { '0': 4, '1': 5, '2': 8},
        'hu': { '0': 4, '1': 5, '2': 8},
        'pt': { '0': 4, '1': 5, '2': 8},
        'uk': { '0': 4, '1': 5, '2': 8},
        'it': { '0': 4, '1': 5, '2': 8},
        'nl': { '0': 4, '1': 5, '2': 8},
        'ro': { '0': 4, '1': 5, '2': 8}
    };

    constructor(private http: HttpClient, private translateService: TranslateService, private mapService: MapService) { }

    public zoomToIndex(zoomLevel: number): number {
        if (zoomLevel <= 4) {
            return 0;
        } else if (zoomLevel <= 5) {
            return 1;
        } else if (zoomLevel <= 8) {
            return 2;
        } else {
            return 3;
        }
    }

    public zoomToIndexCountry(zoomLevel: number, countryCode: string): number {
        const cc = this.zoomIndexCountries[countryCode];
        let index = 0;
        Object.keys(cc).some( key => {
            index = +key;
            return (zoomLevel <= cc[key]);
        });
        return index;
    }

    public buildKmlPath(zoomIndex: number, countryCode: string): string {
        const kmlfile = '../../../assets/zip-bonduaries/';
        return (kmlfile + zoomIndex + 'digit/' + countryCode.toUpperCase() + '_zip.kml');
    }

    public createKmlLayers(kmlList, requestedCountries, map: google.maps.Map, zoomLevel: number) {
        requestedCountries.forEach( (countryCode) => {
            kmlList[countryCode] = new Array();
            this.createKmlLayer(kmlList, countryCode, map, zoomLevel);
        });
    }

    public createKmlLayer(kmlList, countryCode, map: google.maps.Map, zoomLevel: number) {
        const zoomIndex = this.zoomToIndexCountry(zoomLevel, countryCode);
        const filename = this.buildKmlPath(zoomIndex, countryCode);
        kmlList[countryCode][zoomIndex] = new geoXML3.parser({ map: map, zoom: false });
        kmlList[countryCode][zoomIndex].parse(filename);
    }

    public showKmlLayerCountry(kmlList, map: google.maps.Map, zoomLevel: number) {
        Object.keys(kmlList).forEach( (countryCode) => {
            const zoomIndex = this.zoomToIndexCountry(zoomLevel, countryCode);
            if (kmlList[countryCode][zoomIndex]) {
                kmlList[countryCode][zoomIndex].showDocument();
            } else {
                this.createKmlLayer(kmlList, countryCode, map, zoomLevel);
            }
        });
    }

    public hideKmlLayerCountry(kmlList, zoomLevel: number) {
        Object.keys(kmlList).forEach( (countryCode) => {
            const zoomIndex = this.zoomToIndexCountry(zoomLevel, countryCode);
            if (kmlList[countryCode][zoomIndex]) {
                kmlList[countryCode][zoomIndex].hideDocument();
            }
        });
    }

    public createZipPatternMarkers(zipMarkers, requestedCountries, map: google.maps.Map, zoomLevel) {
        requestedCountries.forEach((countryCode) => {
            zipMarkers[countryCode] = new Array();
            this.getJSON('./assets/zip-bonduaries/zip-patterns/' + countryCode.toUpperCase() + '_zip.json').
            subscribe(
                data => {
                    zipMarkers[countryCode] = this.buildMarkerArray(data, zipMarkers[countryCode]);
                    this.showZipPatternLayerCountry(zipMarkers, map, zoomLevel, countryCode);
                },
                error => {
                    // console.log(error);
                });
        });
    }

    public showZipPatternLayerCountry(zipMarkers, map: google.maps.Map, zoomLevel: number, cCode = null) {
        if (cCode) {
            if (zipMarkers[cCode][zoomLevel]) {
                zipMarkers[cCode][zoomLevel].forEach((marker) => {
                    marker.setMap(map);
                });
            }
        } else {
            Object.keys(zipMarkers).forEach( (countryCode) => {
                if (zipMarkers[countryCode][zoomLevel]) {
                    zipMarkers[countryCode][zoomLevel].forEach((marker) => {
                        marker.setMap(map);
                    });
                }
            });
        }
    }

    public hideZipPatternLayerCountry(zipMarkers, zoomLevel: number) {
        Object.keys(zipMarkers).forEach( (countryCode) => {
            if (zipMarkers[countryCode][zoomLevel]) {
                zipMarkers[countryCode][zoomLevel].forEach((marker) => {
                    marker.setMap(null);
                });
            }
        });
    }

    private buildMarkerArray(source, zipM) {
        if (!zipM) {
            zipM = new Array();
        }
        source.forEach( element => {
            const position = new google.maps.LatLng(element.lat, element.lng);
            for (let i = +element.minzoom; i <= +element.maxzoom; i++) {
                if (!zipM[i]) {
                    zipM[i] = new Array();
                }
                zipM[i].push(new google.maps.Marker({
                    position: position,
                    title: element.label,
                    label: {
                        text: element.label,
                        color: element.color,
                        fontSize: element.size + 'px',
                        fontWeight: '600'
                    },
                    icon: './assets/images/transparent.png',
                }));
            }
        });
        return zipM;
    }

    private getJSON(jsonURL): Observable<any> {
        return this.http.get(jsonURL, {responseType: 'json'});
    }

    public getTranslationFor(label: string, value = {}): String {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);
        return result;
    }

    public createHuGoKml(filename, map: google.maps.Map) {
        const kmlParser = new geoXML3.parser({
            map: map,
            suppressInfoWindows: true,
            singleInfoWindow: true,
            createMarker: (placemark) => {
                const marker = kmlParser.createMarker(placemark);
                google.maps.event.addListener(marker, 'rightclick', () => {
                    this.mapService.contextMenuEventSubject.next(marker.getPosition());
                });
                return marker;
            }
        });
        kmlParser.parse(filename);
        return kmlParser;
    }
}
