import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/internal/operators/map';
import { Subject } from 'rxjs';
import * as moment from 'moment';

import { EndpointsService } from './endpoints.service';

import { UserNotificationTypeInterface } from '../modules/notification/_interface/usernotificationtype.interface';
import { UserNotificationInterface } from '../modules/notification/_interface/usernotification.interface';

const HEADER_NOTIFICATIONS_NUMBER = 7;
const NOTIFICATION_LIMIT = 1000;

@Injectable()
export class NotificationService {

    private unreadUserNotificationsNumberSubject = new BehaviorSubject<number>(0);
    private userNotificationsListSubject = new BehaviorSubject<UserNotificationInterface[]>([]);
    private userNotificationsTypesListSubject = new BehaviorSubject<UserNotificationTypeInterface[]>([]);

    public unreadUserNotificationsNumber$: Observable<number> = this.unreadUserNotificationsNumberSubject.asObservable();
    public userNotificationsList$: Observable<UserNotificationInterface[]> = this.userNotificationsListSubject.asObservable();
    public userNotificationsTypesList$: Observable<UserNotificationTypeInterface[]> = this.userNotificationsTypesListSubject.asObservable();

    public showSnackMessageSubject = new Subject<UserNotificationInterface>();
    public reportNotificationSubject = new Subject<any>();
    public rerNotificationSubject = new Subject<any>();
    public roadtaxNotificationSubject = new Subject<any>();

    constructor(private httpClient: HttpClient, private endpointsService: EndpointsService, public translateService: TranslateService) {
        this.getUserNotificationsAndTypes();
        this.getTotalUnreadNotifications();
    }

    private getTotalUnreadNotifications(): void {
        this.httpClient.get(this.endpointsService.get('notification.unreadCount')).subscribe(
            (totalUnread: number) => this.unreadUserNotificationsNumberSubject.next(totalUnread)
        );
    }

    private replaceChar(string, to, withChar) {
        let result = '';
        for (const i of string) {
            if (i === to) {
                result += withChar;
            } else {
                result += i;
            }
        }

        return result;
    }

    public setDateOffset(date_send: any, requestToServer = false, timeformat = 'YYYY-MM-DD HH:mm:ss') {

        if (!date_send) {
            return '';
        }

        if (typeof date_send !== 'string') {
            date_send = date_send.toString();
        }
        const offset = new Date().getTimezoneOffset();
        const date = new Date(this.replaceChar(date_send, '-', '/'));
        if (requestToServer) {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }
        return moment(date).format(timeformat);
    }

    public decreaseUnreadNotificationsNumber(): void {
        const count = this.unreadUserNotificationsNumberSubject.getValue() - 1;
        this.unreadUserNotificationsNumberSubject.next(count);
    }

    public increaseUnreadNotificationsNumber(): void {
        const count = this.unreadUserNotificationsNumberSubject.getValue() + 1;
        this.unreadUserNotificationsNumberSubject.next(count);
    }

    public getUserNotificationsAndTypes(): void {

        const notifResponse = this.httpClient.get(this.endpointsService.get('notification.list') + '?limit=' + NOTIFICATION_LIMIT + '&skip=0');
        const notifTypeResponse = this.httpClient.get(this.endpointsService.get('notification.typelist'));

        forkJoin([notifResponse, notifTypeResponse]).subscribe(
            (data: any[]) => {
                this.userNotificationsTypesListSubject.next(data[1]);
                this.userNotificationsListSubject.next(data[0]);
            }
        );
    }

    public filterUserNotificatiosnByTypeId(notifications: UserNotificationInterface[], typeId: number): UserNotificationInterface[] {
        if (typeId !== 0) {
            return notifications.filter((el: UserNotificationInterface) => el.user_notification_template.user_notification_type_id === typeId);
        }

        return notifications;
    }

    public headerUserNotificationsList(): Observable<UserNotificationInterface[]> {
        return this.userNotificationsList$.pipe(map((notifications: any[]) => notifications.slice(0, HEADER_NOTIFICATIONS_NUMBER)));
    }

    public saveUserNotification(data: any): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('notification.save'), data);
    }

    public deleteUserNotificationRequest(ids: { ids: number[] }): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('notification.delete'), ids);
    }

    public deleteUserNotifications(ids: number[]): void {
        this.getTotalUnreadNotifications();
        const notifications = this.userNotificationsListSubject.getValue();
        ids.forEach(
            (id: number) => {
                const index = notifications.findIndex(el => el.id === id);
                if (index !== -1) {
                    notifications.splice(index, 1);
                }
            }
        );
        this.userNotificationsListSubject.next(notifications.slice(0));
    }

    public markAsReadUserNotificationRequest(ids: { ids: number[] }): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('notification.markAsRead'), ids);
    }

    public markAsReadUserNotifications(ids: number[]): void {
        this.getTotalUnreadNotifications();
        const notifications = this.userNotificationsListSubject.getValue();
        ids.forEach(
            (id: number) => {
                const notif = notifications.find((el: UserNotificationInterface) => el.id === id);
                if (notif && !notif.date_read) {
                    notif.date_read = this.setDateOffset(moment().format('YYYY-MM-DD HH:mm'), true);
                }
            }
        );
        this.userNotificationsListSubject.next(notifications.slice(0));
    }

    public markAsUnreadUserNotificationRequest(ids: { ids: number[] }): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('notification.markAsUnread'), ids);
    }

    public markAsUnreadUserNotifications(ids: number[]): void {
        this.getTotalUnreadNotifications();
        const notifications = this.userNotificationsListSubject.getValue();
        ids.forEach(
            (id: number) => {
                const notif = notifications.find((el: UserNotificationInterface) => el.id === id);
                if (notif) {
                    notif.date_read = null;
                }
            }
        );
        this.userNotificationsListSubject.next(notifications.slice(0));
    }

    public addUserNotification(notif: UserNotificationInterface): void {
        const userNot = this.userNotificationsListSubject.getValue().slice(0);
        const index = userNot.findIndex((el: UserNotificationInterface) => el.id === notif.id);
        if (index === -1) {
            userNot.unshift(notif);
            this.userNotificationsListSubject.next(userNot);
            const unreadNotifNumber = this.unreadUserNotificationsNumberSubject.getValue();
            this.unreadUserNotificationsNumberSubject.next(unreadNotifNumber + 1);
        }

        this.showSnackMessageSubject.next(notif);
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public triggerUserNotificationsListSubject(): void {
        const notif = this.userNotificationsListSubject.getValue();
        this.userNotificationsListSubject.next(notif.slice(0));
    }

    public findNotificationById(id: number): UserNotificationInterface {
        const notifications = this.userNotificationsListSubject.getValue().slice(0);
        const notification = notifications.find((el: UserNotificationInterface) => el.id === id);
        if (notification) {
            return notification;
        }

        return null;
    }
}
