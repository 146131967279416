import { DsDispatcherInterface } from '../../map/interface/dsdispatcher.interface';
import { HistoryMessage } from '../historymessage.model';

export class DsDispatcher implements DsDispatcherInterface {

    public wssConnection: WebSocket;

    constructor(
        public wssurl = null,
        public scope = null,
        public userId = null,
        public groupIds = null,
        public isOwn = false,
        public dispatcherSocketService,
        public authService
    ) {
        this.wssCreate();
    }

    public wssCreate(): void {
        this.wssConnection = new WebSocket('wss://' + this.wssurl + '/wss');
        this.wssConnection.onopen = event => this.onConnectMessage(event);
        this.wssConnection.onmessage = event => this.onMessage(event);
        this.wssConnection.onclose = event => this.onClose(event);
        this.wssConnection.onerror = event => this.onError(event);
    }

    private onMessage(event: any): void {
        const data = JSON.parse(event.data);
        const type: string = data.type;
        switch (type) {
            case 'status_connected':
                this.dispatcherSocketService.onlineOfflineIdsSubject.next({ ids: data.user_ids, status: true });
                //  Update messages status to received
                this.dispatcherSocketService.userService.updateMessageStateOnConnect(data.user_ids, 'state_received');
                break;
            case 'status_disconnected':
                this.dispatcherSocketService.onlineOfflineIdsSubject.next({ ids: data.user_ids, status: false });
                break;
            case 'message':
                this.dispatcherSocketService.userService.scrollDownSubject.next();
                this.authService.sendMessageSubject.next({ to_user_id: data.to_user_id, message: data.message });
                // console.log(data);
                /*
                    Send message to MessageComponent
                    Send message to User Component
                    Update users[index].history
                */
                this.dispatcherSocketService.sendMessageSubject.next(data);
                break;
            case 'status_message_read':
                // Update messages status to read
                this.dispatcherSocketService.userService.updateMessageStateOnRead(data.user_id, data.messages);
                break;
            case 'message_id':
                this.dispatcherSocketService.sendMessageSubject.next(data);
                // Update message id in UserService
                this.dispatcherSocketService.userService.updateMessageId(data.to_user_id, data.message_id);
                break;
            case 'message_state':
                /*
                    Update Message Component
                    Update User Service
                */
                this.dispatcherSocketService.userService.updateMessageState(
                    data.to_user_id, data.message_ids, data.message_state, data.state
                );
                break;
            case 'message_from_same':
                // If user is conected multiple times what he writes should appear in all devices where is connected
                this.dispatcherSocketService.userService.scrollDownSubject.next();
                // Update user message from userlist
                this.dispatcherSocketService.sendMessageSubject.next({
                    date: data.date_send,
                    from: data.from,
                    from_real: data.from,
                    message: data.message,
                    message_id: data.message_id,
                    name: null,
                    is_sms: data.is_sms,
                    send_file: null,
                    to: data.to,
                    type: 'message_from_same'
                });

                const messageToAdd = new HistoryMessage(
                    data.date_send,
                    null,
                    this.userId,
                    data.message_id,
                    data.message,
                    +data.to,
                    this.userId,
                    data.is_sms,
                    null,
                    false,
                    null,
                    false,
                    { name: null }
                );

                // Update users from userService
                this.dispatcherSocketService.userService.updateUsersHistory(messageToAdd, +data.to);
                /*
                    Update users from userlist
                    update message and date_send
                    and test if messages are read..
                */
                break;
            case 'message_history':
                data.messages.filter((message: any) => !message.date_read && message.to_user_id === this.userId).forEach(
                    (unRead: any) => {
                        this.dispatcherSocketService.changeUnreadSubject.next(
                            { userId: unRead.from_user_id, messageId: unRead.id }
                        );
                    }
                );
                /*
                    Update Message Component
                    Update userlist from userService
                */
                this.dispatcherSocketService.messageHistorySubject.next(data);
                this.dispatcherSocketService.userService.addMoreHistoryMessages(data);
                if (!data.history_date) {
                    this.dispatcherSocketService.disableReadMoreSubject.next({ state: true, from: data.from });
                }
                break;
        }
    }

    private onConnectMessage(event: any): void {
        const msg = {
            scope: this.scope,
            token: this.authService.token,
            user_id: this.userId,
            payload: ''
        };
        this.wssConnection.send(JSON.stringify(msg));

        if (this.isOwn) {
            this.dispatcherSocketService.ownConnectionStatus.next(true);
        }
    }

    private onClose(event: any): void {
        if (this.isOwn) {
            this.dispatcherSocketService.ownConnectionStatus.next(false);
        }
    }

    private onError(event: any): void {
        if (this.isOwn) {
            this.dispatcherSocketService.ownConnectionStatus.next(false);
        }
    }
}
