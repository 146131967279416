import { RefuelingInterface } from '../../map/interface/refueling.interface';

export class Refueling implements RefuelingInterface {

    constructor(
        public device_id = null,
        public fuel_diff = null,
        public date_begin = null,
        public date_end = null,
        public address_link = null,
        public location = null,
        public position  = { latitude: null, longitude: null },
        public country_code = null,
        public initial_value = null,
        public end_value = null,
        public mileage = null,
    ) { }
}
