<div>
    <div class="datatTableFilter mb-3 p-2 d-flex align-items-center w-100 flex-grow-1">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
    </div>
    <mat-table #table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc"
        matSortDisableClear style="margin-right: 0px !important;">
        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.clientChanges.dataTable.date' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.date | offset:true | date:dateFormat + ' HH:mm:ss' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="entity">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.clientChanges.dataTable.entity' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ 'Rer.clients.addEdit.clientChanges.entities.' + element.entity |
                translate }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="field">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.clientChanges.dataTable.field' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ 'Rer.clients.addEdit.clientChanges.fields.' + element.field |
                translate }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="old_value">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.clientChanges.dataTable.old_value' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.old_value }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="new_value">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.clientChanges.dataTable.new_value' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.new_value }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.clientChanges.dataTable.username' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.username }}</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
        </mat-row>
    </mat-table>
</div>
