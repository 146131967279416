<p class="pt-4 pb-4 bgcolor-blue text-center font-weight-bold color-white"> {{ ('Rer.rerPlanning.cameraStreamDialog.stream' | translate) + (planning ?
    planning.device.name : "") + ('Rer.rerPlanning.cameraStreamDialog.at' | translate) + (data.reading.datetime_read | offset:true | date: dateFormat + ' HH:mm:ss') }}</p>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div id="instructions">
            <video [ngClass]="!showSpinner && !noVideoOrError ? 'd-block' : 'd-none'" autoplay playsinline #videoStream style="max-width:600px"
                controls preload="none">
            </video>
            <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
                class="d-flex justify-content-center align-items-center position-absolute z-index-100"
                *ngIf="showSpinner">
                <mat-spinner></mat-spinner>
            </div>
            <p *ngIf="noVideoOrError">{{ 'Rer.rerPlanning.cameraStreamDialog.noVideoOrError' | translate }}</p>
            <div class="d-flex justify-content-between">
                <div class="button-component bgcolor-white" (click)="getNextPrev(0)">
                    <div class="inner inner-white user-no-select">
                        <span class="color-grey m-0"><fa-icon class="mr-2"[icon]="faFastBackward"></fa-icon>{{ 'previous' | translate }}</span>
                    </div>
                </div>
                <div class="button-component bgcolor-white" (click)="getNextPrev(1)">
                    <div class="inner inner-white user-no-select">
                        <span class="color-grey m-0">{{ 'next' | translate }}<fa-icon class="ml-2" [icon]="faFastForward"></fa-icon></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="mt-2 p-2">
    <div class="buttons d-flex align-items-center justify-content-end pt-2">
        <div class="mr-4 button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
    </div>
</div>