<div class="bgcolor-white p-2 m-4 position-relative rerplannings-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms"
    [selectedIndex]='activeTab'>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-cts-sidenav-history"></i>
                <span class="ml-1 mr-2">{{ 'Rer.rerPlanning.planningHistory' | translate }}</span>
            </ng-template>
            <app-list [hasEditRight]="hasEditRight" [hasPlanningAddressRight]="hasPlanningAddressRight" (detailsPlanningEventEmitter)="onDetailsPlanning($event)"
                 (closePlanningEventEmitter)="onClosePlanning($event)"></app-list>
        </mat-tab>
        <mat-tab *ngIf="hasEditRight">
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-documents font-size-16"></i>
                <span class="ml-1 mr-2">{{ 'Rer.rerPlanning.logs.label' | translate }}</span>
            </ng-template>
            <app-planning-logs></app-planning-logs>
        </mat-tab>
        <mat-tab *ngFor="let planning of planningDetailsTabs">
            <ng-template mat-tab-label>
                <div *ngIf="planning.id" class="d-flex align-items-center">
                    <i class="icon-tmss-tab-documents font-size-20 mr-2"></i>
                    <span class="mr-2">{{ (planning.route_code ? planning.route_code + '-' : '' ) + planning.device_name }}</span>
                    <i class="icon-tmss-tab-close font-size-14" (click)="onClosePlanning(planning.id)"></i>
                </div>
            </ng-template>
            <app-planning-details
                [planning]="planning"
                (closePlanningEventEmitter)="onCloseDelivery($event)"
                (changeDateEventEmitter)="onChangePeriod($event)">
            </app-planning-details>
        </mat-tab>
    </mat-tab-group>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
