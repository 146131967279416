import { Component, ViewEncapsulation, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { RerService } from '../_service/rer.service';

@Component({
    selector: 'app-bin-suspend-dialog',
    templateUrl: './bin-suspend-dialog.component.html',
    styleUrls: ['./bin-suspend-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class BinSuspendDialogComponent implements OnInit {

    public showMandatory: boolean;
    public date = moment().format('YYYY-MM-DD HH:mm:ss');

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<BinSuspendDialogComponent>,
        private rerService: RerService
    ) { }

    ngOnInit() { }

    public onSelectSuspendationDate(event: string): void {
        this.date = event;
    }

    public onSave(): void {
        this.data.object.date = this.date;
        this.dialogRef.close(this.data.object);
    }

    public getLocale(): String {
        return this.rerService.getLocale();
    }

}

