import { RerBinTypeInterface } from '../_interface/rerbintype.interface';

export class RerBinType implements RerBinTypeInterface {
    public constructor(
        public id = null,
        public name = null,
        public code = null,
        public file = null,
        public scale = null
    ) { }
}
