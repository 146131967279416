import { Component, OnInit } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';
import { Router } from '@angular/router';

import { AuthService } from '../../service/auth.service';
import { EnvService } from 'app/env.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    constructor(private authService: AuthService, private router: Router,
        private oneSignal: OneSignal, private env: EnvService) { }

    ngOnInit() {
        this.oneSignal.init({
            appId: this.env.oneSignalAppId
        });
        this.oneSignal.setExternalUserId(this.authService.userId.toString());
        if (this.authService.angularRoutes && this.authService.angularRoutes.length > 0) {
            for (let index = 0; index < this.authService.angularRoutes.length; index++) {
                const element = this.authService.angularRoutes[index];
                if (element) {
                    this.router.navigate([element]);
                    break;
                }

            }
        } else {
            this.authService.logout();
        }
    }

}
