import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { RerService } from '../../../_service/rer.service';

import { RerClientAddress } from '../../../_model/rerclientaddress.model';
import { RerBin } from '../../../_model/rerbin.model';
import { RerClient } from '../../../_model/rerclient.model';

import { AddEditAddressComponent } from './add-edit-address/add-edit-address.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';


@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, OnDestroy {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean;
    public isEditable = true;
    private _subscriptions: Subscription[] = [];

    @Input() client: RerClient;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private rerService: RerService, 
        public actionDialog: MatDialog,
        private translationService: TranslateService,) { }

    ngOnInit() {
        if (this.client.id) {
            this.getAddressList();
        }
        this.displayedColumns = [
            'reference_date',
            'code',
            'name',
            'city',
            'division',
            'street',
            'street_number',
            'description',
            'objects_number',
            'rer_area_id',
            'is_default',
            'actions'
        ];
        if (this.client.status !== RerClient.STATUS_ACTIVE) {
            this.isEditable = false;
        }
        this.setSubscriptions();
    }

    private manageDeleteRerClientAddress(id: number): void {
        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.clients.addEdit.address.dataTable.confirmDeleteAction')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    this.rerService.deleteClientAddress(id).subscribe(
                        (res: any) => {
                            this.showSpinner = false;
                            const addressIndex = this.client.rer_client_address.findIndex((el: RerClientAddress) => el.id === id);
                            if (addressIndex !== -1) {
                                this.client.rer_client_address.splice(addressIndex, 1);
                                this.dataSource = new MatTableDataSource(this.client.rer_client_address);
                                this.setDataTablePaginatorSort();
                            }
                        },
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            }
        );
    }

    private setSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.rerClientEditableSubject.subscribe(
                (response: boolean) => {
                    this.isEditable = response;
                }
            ),
            this.rerService.rerClientChangedSubject.subscribe(
                (response: boolean) => {
                    const client = this.rerService.getRerClientById(this.client.id);
                    if (client) {
                        this.client.status = client.status;
                    }
                    if (this.client.status !== RerClient.STATUS_ACTIVE) {
                        this.isEditable = false;
                    } else {
                        this.isEditable = true;
                    }
                }
            ),
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private getAddressList(): void {
        this.showSpinner = true;
        this.rerService.getRerClientAddressList(this.client.id).subscribe(
            (addressList: RerClientAddress[]) => {
                this.client.rer_client_address = addressList;
                this.dataSource = new MatTableDataSource(addressList);
                this.showSpinner = false;
                this.setDataTablePaginatorSort();
            },
            () => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            }
        );
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private addEditRerClientAddressList(addr: RerClientAddress): void {
        this.showSpinner = true;
        if (!this.client.rer_client_address) {
            this.client.rer_client_address = [];
        }
        addr.is_default = (this.client.rer_client_address.length === 0) ? 1 : Number(addr.is_default);
        addr.rer_client_id = this.client.id;
        this.rerService.addEditRerClientAddress(addr).subscribe(
            (response: boolean) => {
                this.showSpinner = false;
                if (response) {
                    this.updateEditRerClientAddressList(addr);
                }
            },
            () => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            }
        );
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onAddEditAddress(id: number): void {
        let address: RerClientAddress;
        if (id) {
            const clientAaddress = this.client.rer_client_address.find((el: RerClientAddress) => el.id === id);
            address = clientAaddress ? Object.assign({}, clientAaddress) : new RerClientAddress();
        } else {
            address = new RerClientAddress();
        }

        const dialogRef = this.actionDialog.open(
            AddEditAddressComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    address: address,
                    clientName: this.client.name,
                    isDefaultAddressDisabled: (this.client.rer_client_address && this.client.rer_client_address.length === 1)
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (addr: RerClientAddress) => {
                if (addr) {
                    this.addEditRerClientAddressList(addr);
                }
            }
        );
    }

    private updateEditRerClientAddressList(addr: RerClientAddress): void {
        const addressitemToEdit = this.client.rer_client_address.find(obj => obj.id === addr.id);
        if (!addressitemToEdit) {
            this.client.rer_client_address.push(addr);
            this.dataSource = new MatTableDataSource(this.client.rer_client_address);
        } else {
            Object.keys(addressitemToEdit).forEach(key => addressitemToEdit[key] = addr[key]);
        }
    }

    public onDeleteAddress(id: number): void {
        const address = this.client.rer_client_address.find((el: RerClientAddress) => el.id === id);
        if (!address) {
            return;
        }

        if (address.is_default) {
            this.rerService.showErrorNotifications(
                this.translationService.instant('Rer.clients.addEdit.address.isDefaultDeleteError'));
            return;
        }

        const indexObject = this.client.rer_bins.findIndex((el: RerBin) => el.rer_client_address_id === id);
        if (indexObject !== -1) {
            this.rerService.showErrorNotifications(
                this.translationService.instant('Rer.clients.addEdit.address.hasObjectDeleteError'));
            return;
        }

        this.manageDeleteRerClientAddress(id);
    }

    public getAreaName(id: number) {
        if (!id) {
            return '';
        }

        let area = this.rerService.getAreaById(id);
        return area ? area.name : '';
    }
}
