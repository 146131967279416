import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RerPlanning } from 'app/modules/rer/_model/rerplanning.model';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import * as moment from 'moment';
import { RerService } from '../../../_service/rer.service';
import { faFastForward, faFastBackward } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-camera-stream-dialog',
    templateUrl: './camera-stream-dialog.component.html',
    styleUrls: ['./camera-stream-dialog.component.scss']
})
export class CameraStreamDialogComponent implements OnInit {

    public showMandatory: boolean;
    public date = moment().format('YYYY-MM-DD HH:mm:ss');
    public planning: RerPlanning;
    public showSpinner = true;
    public noVideoOrError = false;
    public dateFormat;
    public faFastForward = faFastForward;
    public faFastBackward = faFastBackward;
    public skip = 0;
    @ViewChild("videoStream", { static: true }) videoStreamElement: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CameraStreamDialogComponent>,
        private rerService: RerService,
        private userPreferencesService: UserPreferencesService
    ) {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.planning = this.rerService.getPlanningById(this.data.planningId);
    }

    ngOnInit() {
        if (this.videoStreamElement.nativeElement) {
            this.rerService.getRerRfidReadingVideo(this.planning.device_id, this.rerService.setDateOffset(this.data.reading.datetime_read), this.data.cameraId).subscribe(result => {
                const reader = new FileReader();
                reader.readAsDataURL(result.body);
                reader.onload = _event => {
                    this.showSpinner = false;
                    let url = reader.result;
                    this.videoStreamElement.nativeElement.src = url;
                    this.videoStreamElement.nativeElement.autoplay = true;
                    this.videoStreamElement.nativeElement.load();
                };
            }, error => {
                this.noVideoOrError = true;
                this.showSpinner = false;
            });
        }
    }

    public getNextPrev(next: number) {
        next ? this.skip += 1 : this.skip -= 1;
        let direction = next;
        let skip = this.skip;
        this.skip >= 0 ? direction = 1 : direction = 0;skip = Math.abs(skip);
        this.rerService.getNextPrevVideo(this.planning.device_id, this.rerService.setDateOffset(this.data.reading.datetime_read), this.data.cameraId, skip, direction).subscribe(result => {
            const reader = new FileReader();
                reader.readAsDataURL(result.body);
                reader.onload = _event => {
                    this.showSpinner = false;
                    let url = reader.result;
                    this.videoStreamElement.nativeElement.src = url;
                    this.videoStreamElement.nativeElement.autoplay = true;
                    this.videoStreamElement.nativeElement.load();
                };
        });
    }

    public getLocale(): String {
        return this.rerService.getLocale();
    }
}

