import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Component, OnInit, ViewChild, } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { StatusCodes } from 'http-status-codes';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import saveAs from 'file-saver';

import { AuthService } from 'app/service/auth.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerService } from '../../_service/rer.service';

import { RerBinSetupDocument } from '../../_model/rerbinsetupdocument.model';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

    public rerBinSetuptDocumentList$: Observable<RerBinSetupDocument[]>;
    private rerBinSetupDocumentsSubject = new BehaviorSubject<RerBinSetupDocument[]>([]);
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean;
    public currentFilterValue: string = null;
    public hasDeleteRight: boolean = false;
    public dateFormat: string;
    public cityFilterList$: Observable<string[]>;
    public streetFilterList$: Observable<string[]>;
    public selectedCityList: string[] = [];
    public selectedStreetList: string[] = [];
    public docFilterSigned: { id: number, label: string, labeli18?: string}[];
    public filterSelected;
    public faEnvelopeOpen = faEnvelopeOpen;

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(private rerService: RerService,
        public dialog: MatDialog,
        private userPreferencesService: UserPreferencesService,
        private translationService: TranslateService,
        public messageDialog: MatDialog
    ) { }


    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat;
        this.cityFilterList$ = this.rerService.cityFilterList$;
        this.streetFilterList$ = this.rerService.streetFilterList$;
        this.displayedColumns = [
            'number','rer_client_name', 'client_address', 'count_bins' , 'bin_type' , 'waste_type', 'responsible_person', 'placement_address', 'date', 'signed_at', 
            'deleted_at', 'deleted_by','actions'
        ];
        this.rerBinSetuptDocumentList$ = this.rerService.rerBinSetuptDocumentList$;
  
        this.rerBinSetuptDocumentList$.subscribe(
          (documents: RerBinSetupDocument[]) => {
              this.dataSource = new MatTableDataSource(documents);
              this.setDataTablePaginatorSort();
              if (this.currentFilterValue) {
                  this.applyFilter(this.currentFilterValue);
            }
          }
        )

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_VERBAL_PROCESS_DELETE)) {
			this.hasDeleteRight = true;
		}

        this.docFilterSigned = [
            { id: 0, label: 'all', labeli18: this.rerService.getTranslationFor('Rer.rerVerbalProcess.filter.all') },
            { id: 1, label: 'signed', labeli18: this.rerService.getTranslationFor('Rer.rerVerbalProcess.filter.signed')  },
            { id: 2, label: 'notsigned', labeli18: this.rerService.getTranslationFor('Rer.rerVerbalProcess.filter.notsigned') }
        ];
    }

    public applyFilter(filterValue: string): void {
		filterValue = filterValue.trim();
		filterValue = filterValue.toLowerCase();
		this.dataSource.filter = filterValue;
	}

    private setDataTablePaginatorSort(): void {
		this.dataSource.paginator = this.paginator;
		this.dataSource.sort = this.sort;
	}

    public deleteBinSetupDocument(id){
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.rerService
                        .getTranslationFor('Rer.rerVerbalProcess.list.confirmDeleteActionBinSetupDocument')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    const document = this.rerService.getBinSetupDocumentById(id);
                    this.rerService.deleteBinSetupDocument(document).subscribe(
                        () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerVerbalProcess.deletedSuccefuly")),
                        () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerVerbalProcess.deletedFailed"))
                    )
                }
            }
        );
    }

    public downloadBinSetupDocument(rerBinSetupDocumentId: number) {
        this.showSpinner = true;
        this.rerService.downloadVerbalProcess(rerBinSetupDocumentId).subscribe({
            next:(response: any) => {
                if (response.data.length !== undefined) {
                    const byteNumbers = new Array(response.data.length);
                    for (let i = 0; i < response.data.length; i++) {
                        byteNumbers[i] = response.data.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: '' });
                    this.showSpinner = false;
                    saveAs(blob, response.filename);
                    this.showSpinner = false;
                }
            },
            error:() => {
                this.rerService.showErrorNotifications(this.translationService.instant('errorNotification'));
                this.showSpinner = false;
            }
        }
        );
    }

    public sendByEmail(rerBinSetupDocumentId: number): void {
        if (!rerBinSetupDocumentId) {
            return;
        }

        this.showSpinner = true;
        this.rerService.sendBinSetupByEmail(rerBinSetupDocumentId).subscribe({
            next:() => {
                this.dialog.open(
                    NotificationComponent,
                    {
                        maxWidth: '510px',
                        panelClass: 'custom-dialog-container',
                        data: {
                            success: 1,
                            headermessage: this.translationService.instant('Rer.dataImportRer.importSuccessHeader'),
                            message: this.translationService.instant('Rer.sendBinSetupByEmail.sendEmailSuccessMessage')
                        }
                    }
                );
                this.showSpinner = false;
            },
            error:(error: HttpErrorResponse) => {
                let errorMessage = '';
                switch (error.status) {
                    case StatusCodes.FAILED_DEPENDENCY:
                        errorMessage = 'Rer.sendBinSetupByEmail.clientHasNoEmail';
                        break;
                    case StatusCodes.NOT_FOUND:
                        errorMessage = 'Rer.sendBinSetupByEmail.failedSend';
                        break;
                    default:
                        errorMessage = 'errorNotification';
                }
                this.dialog.open(
                    NotificationComponent,
                    {
                        panelClass: 'custom-dialog-container',
                        maxWidth: '480px',
                        data: {
                            headermessage: this.translationService.instant('error'),
                            message: this.translationService.instant(errorMessage),
                            success: 0
                        }
                    }
                );
                this.showSpinner = false;
            }
        });
    }

    public getDocumentList(): void {
        this.showSpinner = true;
        this.rerService.getRerBinSetupDocuments(
            {
                cityList: this.selectedCityList.join(','),
                streetList: this.selectedStreetList.join(','),
                documentSigned: this.filterSelected,
            }
        ).subscribe({
            next:() => this.showSpinner = false,
            error:() => this.showNotification()
        });
    }

    public onLoadList(): void {
        this.getDocumentList();
    }


    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }


    public onFilterChange(event: any): void {
        const documents = this.rerBinSetupDocumentsSubject.getValue();
        this.rerBinSetupDocumentsSubject.next(documents);
    }


}
