import { RerClientAddressGeozoneInterface } from '../_interface/rerclientaddressgezone.interface';

export class RerClientAddressGeozone implements RerClientAddressGeozoneInterface {

    constructor(
        public id: number = null,
        public client_id: number = null,
        public rer_client_id: number = null,
        public rer_client_address_id: number = null,
        public type: string = null,
        public center_latitude: number = null,
        public center_longitude: number = null,
        public radius: number = null,
        public coords: any = null,
        public precalc_values: any = null,
        public shape: any = null,
        public checked = false,
        public label = null,
        public marker = null,
        public visible = true,
        public color = '#274397',
        public group_color = null,
    ) { }
}