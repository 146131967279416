import { Subscription } from 'rxjs';
import { DsDataInterface } from '../../map/interface/dsdata.interface';

export class DsData implements DsDataInterface {

    constructor(
        public obs = new Subscription,
        public pos = null
    ) { }
}
