<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ ('Rer.clients.addEdit.objects.suspendObject' | translate) + ' - ' + data.object.code }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="d-flex justify-content-between align-items-center mb-4">
        <app-datepicker
            [locale]="getLocale()"
            [alignCenter]="true"
            [justifyCenter]="true"
            [date]="date" label="{{ 'Rer.clients.addEdit.objects.suspendationDate' | translate }}"
            [time]="true"
            [disabled]="true"
        >
        </app-datepicker>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4" (click)="onSave()" [ngClass]="data.object.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="data.object.id ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>
