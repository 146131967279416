import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../service/auth.service';
import { GoogleapisService } from '../../modules/service/shared/googleapis.service';
import { RouteService } from '../../modules/service/route/route.service';
import { MapService } from '../../modules/service/shared/map.service';
import { UserPreferencesService } from '../../service/userpreferences.service';
import { GeofenceService } from 'app/modules/service/map/geofence.service';
import { faCopy, faMap } from '@fortawesome/free-solid-svg-icons';
import { noop } from 'rxjs';

@Component({
  selector: 'app-coordonate-info',
  templateUrl: './coordonate-info.component.html',
  styleUrls: ['./coordonate-info.component.scss']
})
export class CoordonateInfoComponent implements OnInit {

    @Input() latLng: any;
    @Input() canCreateGeozone = false;

    public hasRouteRight = false;
    public lat: any;
    public lng: any;
    public address: string;
    public countryCode: string;
    public faCopy = faCopy;
    public faMap = faMap;

    constructor(
        private googleapisService: GoogleapisService,
        private authService: AuthService,
        private router: Router,
        private mapService: MapService,
        private geofenceService: GeofenceService,
        private routeService: RouteService,
        private userPreferencesService: UserPreferencesService,
    ) { }

    ngOnInit() {
        if (this.authService.angularRoutes.indexOf('/route') !== -1) {
            this.hasRouteRight = true;
        }
        this.lat = parseFloat(Number(this.latLng.lat()).toFixed(6));
        this.lng = parseFloat(Number(this.latLng.lng()).toFixed(6));
        this.getAddress();
    }

    private getAddress(): void {
        this.googleapisService.reverseGeocode(this.lat, this.lng).subscribe(
            (response) => {
                this.address = response.address_formatted;
                this.countryCode = response.address_elements.country_code;
            },
            () => noop
        );
    }

    public redirectToRoutes(): void {
        const device = JSON.stringify({
            deviceId: 0,
            gpsCoordonates: {
                latitude: this.lat,
                longitude: this.lng
            }
        });
        if (this.router.url.startsWith('/route')) {
            this.routeService.deviceChangedSubject.next(device);
        } else {
            this.router.navigate(['route', device]);
        }
    }

    public copyMessage(): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.lat + ',' + this.lng;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    public addLocation(): void {
        this.mapService.closeContextMenuSubject.next(null);
        this.mapService.addNewRouteItemSubject.next(
            { address: this.address, lat: this.lat, lng: this.lng, countryCode: this.countryCode, index: null });
    }

    public setDefaultMapCenter(): void {
        const userPreferences = this.userPreferencesService.userPreferences;
        userPreferences.defaultMapCenter = this.lat + ', ' + this.lng;
        this.userPreferencesService.userPreferences = userPreferences;
        this.authService.frontendPreferences = userPreferences;
        this.mapService.closeContextMenuSubject.next(null);
    }

    public createGeozone(): void {
        this.mapService.closeContextMenuSubject.next(null);
        this.geofenceService.addEditGeofenceSubject.next({ geozoneId: null, latLng: this.latLng });
    }

    public hasRight(option: String) {
        return this.authService.userModulesOptions.settings
            .find((item: string) => item === option);
    }
}
