import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
    selector: 'app-edit-email-template',
    templateUrl: './edit-email-template.component.html',
    styleUrls: ['./edit-email-template.component.scss'],
})
export class EditEmailTemplateComponent implements OnInit {

    angularEditorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '25rem',
        sanitize: false,
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
          ['bold']
          ],
        customClasses: [
          {
            name: "quote",
            class: "quote",
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: "titleText",
            class: "titleText",
            tag: "h1",
          },
        ]
      };

      @Input() config: AngularEditorConfig = this.angularEditorConfig;

    constructor(
        public dialogRef: MatDialogRef<EditEmailTemplateComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }
    

    ngOnInit() { }

    onSave() {
        this.dialogRef.close(this.data.emailTemplate);
    }
}