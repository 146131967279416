import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RerService } from '../_service/rer.service';
import { RerIncident } from '../_model/rerincident.model';
import { AuthService } from 'app/service/auth.service';

@Component({
  selector: 'app-rer-incident',
  templateUrl: './rer-incident.component.html',
  styleUrls: ['./rer-incident.component.scss']
})
export class RerIncidentComponent implements OnInit {

  public incidentDetailsTabs: RerIncident[] = [];
  public activeTab: number;
  public selectedTabIndex = 0;
  public hasDetailsRight: boolean = false;

  constructor( 
      public actionDialog: MatDialog,
      private rerService: RerService) { }

  ngOnInit() {
    if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_INCIDENT_DETAILS)) {
			this.hasDetailsRight = true;
		}
  }

  public onDetailsIncident(id : number): void {
    let moveTo: number;
    const incident = this.rerService.getIncidentById(id);
    const indexTab = this.incidentDetailsTabs.findIndex((et: RerIncident) => et.id === incident.id);
      if (indexTab === -1) {
          this.incidentDetailsTabs.push(incident);
          moveTo = this.incidentDetailsTabs.length;
      } else {
          moveTo = indexTab + 1;
      }
    setTimeout(() => { this.selectedTabIndex = moveTo; });
  }

  public onChangeTab(event) {
    this.activeTab = event.index;
}

  public onCloseIncident(event: number): void {

    if (event == -1) {
        this.incidentDetailsTabs = [];
        this.rerService.incidentsDetailsChangedSubject.next(this.incidentDetailsTabs);
        setTimeout(() => { this.activeTab = 0; }, 100);
    } else {
        const index = this.incidentDetailsTabs.findIndex(element => element.id === event);

        if (index > -1) {
            this.incidentDetailsTabs.splice(index, 1);
            setTimeout(() => { this.activeTab = this.incidentDetailsTabs.length ? this.incidentDetailsTabs.length + 2 : 0; }, 100);
        }

        this.rerService.incidentsDetailsChangedSubject.next(this.incidentDetailsTabs);
    }
  } 
}
