import { Component, OnInit} from '@angular/core';
import { faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { RerSettings } from '../../_model/rersettings.model';
import { RerService } from '../../_service/rer.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SaveDialogComponent } from '../save-dialog/save-dialog.component';

@Component({
    selector: 'app-email-settings',
    templateUrl: './email-settings.component.html',
    styleUrls: ['./email-settings.component.scss']
})
export class EmailSettingsComponent implements OnInit {

    public showMandatory = false;
    public showSpinner = false;
    public showFormatMandatory = false;
    public smtp_email_address;
    public smtp_email_password;
    public smtp_host;
    public smtp_port = 0;
    public smtp_verified = false;
    public smtp_failed_counter = false;
    public faEye = faEye;
    public faEyeSlash = faEyeSlash;

    private _subscriptions: Subscription[] = [];
    private patternProperties = [
        { 'key': 'emailaddress', 'pattern': '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$' }
    ];
    public hide = true;
    private settings: RerSettings[] = [];

    constructor(
        private rerService: RerService,
        private translationService: TranslateService,
        public saveDialog: MatDialog
    ) { }
  

    ngOnInit() {
        this.showSpinner = true;
        this.rerService.getRerSettings().subscribe(
            (response: RerSettings[]) => {
                if (response.length > 0) {
                    this.settings = response;
                    this.updateValues();
                    this.showSpinner = false;
                } else {
                    this.showSpinner = false;
                }
            }
        );
        this.setSubscriptions();
    }

    public saveEmailSettings() {
        this.showSpinner = true;
        this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_EMAIL_ADDRESS, this.smtp_email_address);
        this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_EMAIL_PASSWORD, this.smtp_email_password);
        this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_HOST, this.smtp_host);
        this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_PORT, this.smtp_port);

        let customMailSettings = {
            emailaddress: this.smtp_email_address,
            emailpassword: this.smtp_email_password,
            smtphost: this.smtp_host,
            smtpport: this.smtp_port,
            verified: this.smtp_verified
        };

        this.showFormatMandatory = !this.canSavePattern(customMailSettings);
        this.showMandatory = !this.smtp_email_address || !this.smtp_email_password || !this.smtp_host || !this.smtp_port;
        if(this.showMandatory || this.showFormatMandatory) {
            this.showSpinner = false;
            this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.mandatoryFields"));
            return;
        }
        this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_VERIFIED, this.smtp_verified);
        this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_FAILED_COUNTER, this.smtp_failed_counter);

        this.rerService.updateRerSettings(this.settings).subscribe(
            () => {
                this.showSpinner = false
                this.rerService.showSaveConfirmation(
                    this.translationService.instant("Rer.rerSettings.savedSuccefuly")
                );
            },
            () => {
                this.rerService.showErrorNotifications(
                    this.translationService.instant("Rer.rerSettings.saveFailed")
                );
                this.showSpinner = false;
            }
        )
    }

    public removeChars(event: KeyboardEvent) {
        let pattern = /[0-9]/;
        if (!pattern.test(event.key)) {
            event.preventDefault();
        }
    }

    private updateValues() {
        this.smtp_email_address = this.getSettingValue(this.rerService.RER_SETTINGS_SMTP_EMAIL_ADDRESS, null);
        this.smtp_email_password = this.getSettingValue(this.rerService.RER_SETTINGS_SMTP_EMAIL_PASSWORD, null);
        this.smtp_host = this.getSettingValue(this.rerService.RER_SETTINGS_SMTP_HOST, null);
        this.smtp_port = this.getSettingValue(this.rerService.RER_SETTINGS_SMTP_PORT, null);
        this.smtp_verified = this.getSettingValue(this.rerService.RER_SETTINGS_SMTP_VERIFIED, null);
        this.smtp_failed_counter = this.getSettingValue(this.rerService.RER_SETTINGS_SMTP_FAILED_COUNTER, 0);
    }

    private getSettingValue(settingName: String, defaultValue: any) {
        let setting = this.settings.find((setting: RerSettings) => setting.name == settingName);
        return setting != null ? setting.value : (defaultValue != null ? defaultValue : 0);
    }

    private updateSettingValue(settingName: String, newValue: any) {
        let index = this.settings.findIndex((setting: RerSettings) => setting.name == settingName);
        if (index > -1) {
            this.settings[index].value = newValue;
        } else {
            let setting = new RerSettings(null, settingName, newValue);
            this.settings.push(setting);
        }
    }

    public dataChanged() {
        this.smtp_verified = false;
    }

    public onTestMail(): void {
        this.showSpinner = true;
        let customMailSettings = {
            emailaddress: this.smtp_email_address,
            emailpassword: this.smtp_email_password,
            smtphost: this.smtp_host,
            smtpport: this.smtp_port,
            verified: this.smtp_verified
        };
        if (this.smtp_email_address != '' && this.smtp_email_password != '' && this.smtp_host != '' && this.smtp_port > 0) {
            this.rerService.testSmtpSettings(customMailSettings);
        }
    }

    private setSubscriptions() {
        this._subscriptions.push(
            this.rerService.smtpTestSubject.subscribe(
                (response) => {
                    this.smtp_verified = (response.status === 200);
                    this.showSpinner = false;
                    let message =  (response.status === 200 ?
                        this.translationService.instant('Rer.rerSettings.emailSettings.mailCheckPassed') :
                        this.translationService.instant('Rer.rerSettings.emailSettings.mailCheckFailed') + ':'
                            + response.error);

                    this.showSaveModal(message)
                },
                (error) => {
                    this.smtp_verified = false;
                    this.showSpinner = false;
                    let message =  this.translationService.instant('Rer.rerSettings.emailSettings.mailCheckFailed') + error
                    this.showSaveModal(message)
                }
            )
        );
    }

    private showSaveModal(message): void {
        const dialogRef = this.saveDialog.open(
            SaveDialogComponent,
            {
                maxWidth: '800px',
                data: {
                    message: message
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            () => {
                this.updateSettingValue(this.rerService.RER_SETTINGS_SMTP_VERIFIED, this.smtp_verified);
                this.rerService.updateRerSettings(this.settings).subscribe(
                    () => { },
                    () => {
                        this.rerService.showErrorNotifications(
                            this.translationService.instant("Rer.rerSettings.saveFailed")
                        );
                    }
                )
            }
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public canSavePattern(data): boolean {
        let result = true;
        const properties = this.patternProperties;
        for (let i = 0; i < properties.length; i++) {
            if (data[properties[i].key]) {
                result = RegExp(properties[i].pattern, 'i').test(data[properties[i].key]);
                if (!result) {
                    break;
                }
            }
        }
        return result;
    }
}
