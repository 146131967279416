import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'elipsizeText'
})
export class ElipsizeTextPipe implements PipeTransform {

    transform(value: string, wordCount: number): any {
        if (!value || (value.match(/,/g) || []).length < wordCount) {
            return value;
        }
        const wordsArray = value.split(' ');
        let elipsizedText = '';
        for (let i = 0; i < wordCount; i++) {
            elipsizedText += wordsArray[i] + ' ';
        }
        return elipsizedText + ' ...';
    }

}
