import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'app/modules/model/menuitem.model';
import { RerService } from '../../_service/rer.service';
import { RerCollectionByWeightService } from '../../_service/rercollectionbyweight.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerWeighingReceipt } from '../../_model/rerweighingreceipt.model';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EditWeighingReceiptComponent } from './edit-weighing-receipt/edit-weighing-receipt.component';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
  selector: 'app-rer-operation-of-weighing-receipts',
  templateUrl: './rer-operation-of-weighing-receipts.component.html',
  styleUrls: ['./rer-operation-of-weighing-receipts.component.scss']
})
export class RerOperationOfWeighingReceiptsComponent implements OnInit {

  public rerWeighingReceiptList$: Observable<RerWeighingReceipt[]>;
  public dataSource: MatTableDataSource<any>;
  public currentFilterValue: string = null;
  public showSpinner: boolean = false;
  public displayedColumns: string[];
  public dateFormat: string;

  constructor(private rerCollectionByWeightService: RerCollectionByWeightService,
    public dialog: MatDialog,
    private userPreferencesService: UserPreferencesService,
    private rerService: RerService,
    private translationService: TranslateService) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit() {
      this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
      this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
      this.displayedColumns = ['receipt_number', 'operated_at', 'rer_unloading_zone_name', 'device_name', 'quantity','calculated_quantity', 'difference_quantity', 'unallocated_quantity', 'waste_type', 'fully_allocated', 'actions'];
      this.rerCollectionByWeightService.getWeighingReceiptList();
      this.rerWeighingReceiptList$ = this.rerCollectionByWeightService.rerWeighingReceiptList$;

      this.rerWeighingReceiptList$.subscribe(
        (weighingReceipt: RerWeighingReceipt[]) => {
            this.dataSource = new MatTableDataSource(weighingReceipt);
            this.setDataTablePaginatorSort();
            if (this.currentFilterValue) {
                this.applyFilter(this.currentFilterValue);
          }
        }
      )
    }

    private setDataTablePaginatorSort(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  
    public applyFilter(filterValue: string): void {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
    }

    public editWeighingReceipt(id: number): void {
      let weighingReceipt = this.rerCollectionByWeightService.getWeighingReceiptById(id);

      const dialogRef = this.dialog.open(
        EditWeighingReceiptComponent,
        {
            width: '1000px',
            data: { weighingReceipt: weighingReceipt }
        }
    );

    dialogRef.afterClosed().subscribe(
        (response) => {
            if (response) {
                this.rerCollectionByWeightService.saveWeighingReceipt(response).subscribe(
                    () => this.rerCollectionByWeightService.showSaveConfirmation(this.translationService.instant("Rer.rerSettings.savedSuccefuly")),
                    () => this.rerCollectionByWeightService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.saveFailed"))
                )
              }
          }
      );
    }

    public downloadProof(id: number): void {
      this.showSpinner = true;
        this.rerCollectionByWeightService.downloadProof(id).subscribe(
          (response: any) => {
            if (response.data.length !== undefined) {
              const filename = response.filename;
              const content = response.data;
              const filetype = '';
              const byteCharacters = content;
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], { type: filetype });
              this.showSpinner = false;
              saveAs(blob, filename);
            }
          },
          () => this.showErrorNotification()
        );
    }

    private showErrorNotification(): void {
      this.showSpinner = false;
      this.dialog.open(
        NotificationComponent,
        {
          maxWidth: '800px', panelClass: 'custom-dialog-container',
          data: {
            success: 0,
            headermessage: this.rerCollectionByWeightService.getTranslationFor('error'),
            message: this.rerCollectionByWeightService.getTranslationFor('generalError')
          }
        }
      );
  }
}