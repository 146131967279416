<div class="row" style="height: calc(100% - 65px);">
    <div class="col-6">
        <div class="row" *ngIf="bin.id">
            <div class="col-6">
                <mat-form-field class="w-100 mt-4">
                    <input matInput autocomplete="off" type="details" [disabled]="true" [(ngModel)]="bin.code"
                        placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.code' | translate }}">
                </mat-form-field>
            </div>
            <div class="col-6">
                <mat-form-field class="w-100 mt-4">
                    <input matInput autocomplete="off" type="details" [disabled]="true"
                        value="{{ 'Rer.objects.status.' + (bin.status | rerStatusTransform:'object') | translate }}"
                        placeholder="{{ 'Rer.objects.list.dataTable.status' | translate }}">
                </mat-form-field>
            </div>
        </div>
        <div class="row w-100 d-flex align-items-center ml-0">
            <div class="col-6 pl-0">
                <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex" style="max-width: unset;">
                    <ng-select [items]="wasteTypeList" appendTo="body" [multiple]="false" [clearable]="false"
                        placeholder="{{ 'Rer.objects.list.dataTable.wasteType' | translate }}"
                        [closeOnSelect]="true" [(ngModel)]="bin.rer_waste_type_id" bindLabel="name" bindValue="id"
                        name="wasteTypeId" #wasteTypeId="ngModel" style="flex-grow: 1">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <ng-container *ngFor="let item of items; let i=index">
                                <span class="ng-value ng-value-label"> {{ item.name }} </span>
                            </ng-container>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <span>{{ item.name }}</span>
                        </ng-template>
                    </ng-select>
                    <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !bin.rer_waste_type_id ?
                        'mt-2 pt-1 mr-2 icon-cts-information pointer font-size-18 color-red-primary': 'align-middle'">
                    </span>
                </div>
            </div>
            <div class="col-6 pr-0">
                <div class="ng-select-wrapper w-100 mt-4 mr-1 d-flex" style="max-width: unset;">
                    <ng-select [items]="binTypeList" appendTo="body" bindLabel="name" bindValue="id" [multiple]="false"
                        placeholder="{{ 'Rer.objects.list.dataTable.binType' | translate }}"
                        [clearable]="false" [closeOnSelect]="true" [(ngModel)]="bin.rer_bin_type_id"
                        name="binTypeId" #binTypeId="ngModel" style="flex-grow: 1;">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <ng-container *ngFor="let item of items; let i=index">
                                <span class="ng-value ng-value-label">{{ item.name }}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <span>{{ item.name }}</span>
                        </ng-template>
                    </ng-select>
                    <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !bin.rer_bin_type_id ?
                        'mt-2 pt-1 mr-2 icon-cts-information pointer font-size-18 color-red-primary': 'align-middle'">
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                    <ng-select [items]="rfidCompleteList" class="geozone flex-grow-1"
                        placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.rfid' | translate }}"
                        appendTo="body" bindLabel="rfid" bindValue="id" [multiple]="false" [clearable]="false"
                        [closeOnSelect]="true" [(ngModel)]="bin.rer_rfid_tag_id">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <span class="ml-2">{{ item.rfid }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <mat-form-field class="w-100 mt-4 mb-4">
                    <textarea matInput autocomplete="off" matTextareaAutosize matAutosizeMinRows="2"
                        matAutosizeMaxRows="2" [(ngModel)]="bin.description"
                        placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.description' | translate }}">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a *ngIf="bin.latitude && bin.latitude != 0 && bin.longitude && bin.longitude != 0"
                    href="https://www.google.com/maps/search/?api=1&query={{ bin.latitude }},{{ bin.longitude}}"
                    target="_blank" class="mt-4">
                    {{ bin.latitude + ', ' + bin.latitude }}
                </a>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="allocation-list w-100 mt-4">
                    <mat-table #table [dataSource]="dataSource" matSort>
                        <ng-container matColumnDef="allocation_date">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'Rer.clients.addEdit.objects.rfidAllocation.allocation_date' |
                                translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.allocation_date }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="rer_rfid">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'Rer.objects.list.dataTable.rfid' | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.rer_rfid }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="username">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ 'Rer.clients.addEdit.objects.rfidAllocation.username' | translate
                                }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let element">{{ element.username }}</mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
                        </mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
<div class="d-flex justify-content-between align-items-center button-container">
    <div class="d-flex align-items-center">
        <div *ngIf="showMandatory">
            <i class="icon-cts-information font-size-28 color-red-primary align-middle mr-1"></i>
            <span class="align-middle"> {{ 'mandatoryFieldText' | translate }}</span>
        </div>
    </div>
    <div class="d-flex">
        <div *ngIf="bin.id" class="button-component utility-blue-bg-color ml-4" (click)="onAllocate()">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.allocate' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color ml-4" (click)="onSave()">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>
