import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, forkJoin, noop, Subscription } from 'rxjs';
import { map,timeout } from 'rxjs/operators';
import * as moment from 'moment';

import { EndpointsService } from '../../../service/endpoints.service';

import { ConsumptionFuelLevelBased } from '../../model/shared/consumptionfuellevelbased.model';
import { RefuelingChart } from '../../model/map/refuelingchart.model';
import { Refueling } from '../../model/map/refueling.model';
import { STObject } from '../../model/map/stobject.model';
import { FuelConsumption } from 'app/modules/model/map/fuelconsumption.model';

const STOBJECT_TYPE_STOP = 0;
const STOBJECT_TYPE_TRIP = 1;
const STOBJECT_TYPE_STOPTRIP = 2;

@Injectable()
export class HistoryService {

    private processedHistory: STObject[];
    private processedRefuelings: { chartData: any[], refuelings: any[], distance: number, consumptions: any[], deviceId: number };
    private buffer: STObject;
    private minStopDuration: number;

    public historySubscription: Subscription;
    public refuelingsFilterObservableInput: Observable<any>;
    public showHistoryCrossedGeofencesSubject = new Subject<void>();
    public positionDetailWasClickedSubject = new Subject<string>();
    public showRefuelingChartInfoSubject = new Subject<boolean>();
    public historyDetailsTableHeaderSubject = new Subject<any>();
    public activateDetailPositionSubject = new Subject<string>();
    public showHistoryChartInfoSubject = new Subject<boolean>();
    public generateHistoryErrorSubject = new Subject<String>();
    public itemStopActiveSubject = new Subject<number>();
    public itemTripActiveSubject = new Subject<string>();
    public changeHistoryPeriodSubject = new Subject<void>();
    public generateHistorySubject = new Subject<any>();
    public historyItemPositionsDetailsSpinnerSubject = new Subject<boolean>();
    public createDeviceHistorySubject = new Subject<boolean>();

    constructor(private httpClient: HttpClient, private translateService: TranslateService, private endpointsService: EndpointsService) { }

    public getTranslationFor(label: string, value = {}): String {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public getLocale(): String {
        const lang = localStorage.getItem('currentLang');
        return (lang) ? lang.substring(0, 2) : 'ro';
    }

    public setDateOffset(date_send, requestToServer = false, timeformat = 'YYYY-MM-DD HH:mm:ss') {

        if (!date_send) {
            return '';
        }

        if (typeof date_send !== 'string') {
            date_send = date_send.toString();
        }
        const offset = new Date().getTimezoneOffset();
        const date = new Date(this.replaceChar(date_send, '-', '/'));
        if (requestToServer) {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }
        return moment(date).format(timeformat);
    }

    public replaceChar(string: string, to: string, withChar: string) {
        let result = '';
        for (const i of string) {
            if (i === to) {
                result += withChar;
            } else {
                result += i;
            }
        }

        return result;
    }

    private getSTObjectType(duration: { stopped: number; idling: number; moving: number }): number {
        return duration.moving === 0 ?
            STOBJECT_TYPE_STOP : (duration.idling + duration.stopped > 0 ? STOBJECT_TYPE_STOPTRIP : STOBJECT_TYPE_TRIP);
    }

    private processBuffer(): STObject[] {
        if (this.getSTObjectType(this.buffer.duration) === STOBJECT_TYPE_STOPTRIP) {
            const sharedPeriod = moment(this.buffer.period.begin)
                .add(this.buffer.duration.idling + this.buffer.duration.stopped, 'seconds').format('YYYY-MM-DD HH:mm:ss');

            const stop = new STObject(
                { begin: this.buffer.period.begin, end: sharedPeriod },
                { begin: this.buffer.address.begin, end: this.buffer.address.begin },
                { stopped: this.buffer.duration.stopped, idling: this.buffer.duration.idling, moving: 0 },
                0, [this.buffer.positions[0]], null, null, null, null, null, null, null,
                (typeof this.buffer.distance_can === 'number') ? 0 : null,
                (typeof this.buffer.distance_gps === 'number') ? 0 : null
            );
            const trip = new STObject(
                { begin: sharedPeriod, end: this.buffer.period.end }, { begin: this.buffer.address.begin, end: this.buffer.address.end },
                { stopped: 0, idling: 0, moving: this.buffer.duration.moving }, this.buffer.distance, this.buffer.positions.slice(1),
                null, null, null, null, null, null, null, this.buffer.distance_can, this.buffer.distance_gps
            );

            return [stop, trip];
        }

        return [
            new STObject(
                { begin: this.buffer.period.begin, end: this.buffer.period.end },
                { begin: this.buffer.address.begin, end: this.buffer.address.end },
                { stopped: this.buffer.duration.stopped, idling: this.buffer.duration.idling, moving: this.buffer.duration.moving },
                this.buffer.distance, this.buffer.positions, null, null, null, null, null, null, null,
                this.buffer.distance_can, this.buffer.distance_gps
            )
        ];
    }

    private concatStops(stop1: STObject, stop2: STObject): void {
        stop1.period.end = stop2.period.end;
        stop1.duration.stopped += stop2.duration.stopped;
        stop1.duration.idling += stop2.duration.idling;
        stop1.duration.moving += stop2.duration.moving;
        stop1.distance += stop2.distance;
        if (stop1.distance_can !== null && stop2.distance_can !== null) {
            stop1.distance_can += stop2.distance_can;
        }
        if (stop1.distance_gps !== null && stop2.distance_gps !== null) {
            stop1.distance_gps += stop2.distance_gps;
        }
        stop1.address.end = stop2.address.end;
    }

    private concatTrips(trip1: STObject, trip2: STObject): void {
        trip1.period.end = trip2.period.end;
        trip1.address.end = trip2.address.end;
        trip1.duration.moving += trip2.duration.moving;
        trip1.duration.stopped += trip2.duration.stopped;
        trip1.duration.idling += trip2.duration.idling;
        trip1.positions = [...trip1.positions, ...trip2.positions];
        trip1.distance += trip2.distance;
        trip1.hiddenStops += trip2.hiddenStops;
        if (trip1.distance_can !== null && trip2.distance_can !== null) {
            trip1.distance_can += trip2.distance_can;
        }
        if (trip1.distance_gps !== null && trip2.distance_gps !== null) {
            trip1.distance_gps += trip2.distance_gps;
        }
    }

    private addStopToTrip(stop: STObject, trip: STObject): void {
        trip.period.end = stop.period.end;
        trip.duration.idling += stop.duration.idling;
        trip.duration.stopped += stop.duration.stopped;
        trip.duration.moving += stop.duration.moving;
        trip.distance += stop.distance;
        trip.hiddenStops++;
        if (trip.distance_can !== null && stop.distance_can !== null) {
            trip.distance_can += stop.distance_can;
        }
        if (trip.distance_gps !== null && stop.distance_gps !== null) {
            trip.distance_gps += stop.distance_gps;
        }
    }

    private flushBuffer(): void {
        const prHistLength = this.processedHistory.length;
        if (prHistLength === 0) {
            this.processedHistory = this.processBuffer();
        } else {
            const lastProcessedItem = this.processedHistory[prHistLength - 1];
            const processedBuffer = this.processBuffer();
            //  if last proccessed item is STOP
            if (this.getSTObjectType(lastProcessedItem.duration) === STOBJECT_TYPE_STOP) {
                switch (this.getSTObjectType(processedBuffer[0].duration)) {
                    case STOBJECT_TYPE_STOP:
                        this.concatStops(lastProcessedItem, processedBuffer[0]);
                        if (processedBuffer[1]) {
                            this.processedHistory.push(processedBuffer[1]);
                        }
                        break;
                    case STOBJECT_TYPE_TRIP:
                        this.processedHistory.push(processedBuffer[0]);
                        break;
                }
                //  if last proccessed item is TRIP
            } else {
                switch (this.getSTObjectType(processedBuffer[0].duration)) {
                    case STOBJECT_TYPE_STOP:
                        //  check if duration of processedBuffer[0] <= minStopDuration
                        if (processedBuffer[0].duration.idling + processedBuffer[0].duration.stopped <= this.minStopDuration) {
                            this.addStopToTrip(processedBuffer[0], lastProcessedItem);
                            //  if I have second element it must be a TRIP
                            if (processedBuffer[1]) {
                                this.concatTrips(lastProcessedItem, processedBuffer[1]);
                            }
                        } else {
                            this.processedHistory.push(processedBuffer[0]);
                            //  if I have second element it must be a TRIP
                            if (processedBuffer[1]) {
                                this.processedHistory.push(processedBuffer[1]);
                            }
                        }
                        break;
                    case STOBJECT_TYPE_TRIP:
                        this.concatTrips(lastProcessedItem, processedBuffer[0]);
                        break;
                }
            }
        }
    }

    private addToBuffer(histDtaItem: STObject): void {
        if (this.buffer.duration.moving === 0) {
            this.buffer.duration.stopped += histDtaItem.duration.stopped;
            this.buffer.duration.idling += histDtaItem.duration.idling;
            this.buffer.duration.moving += histDtaItem.duration.moving;
            this.buffer.period.end = histDtaItem.period.end;
            this.buffer.address.end = histDtaItem.address.end;
            this.buffer.distance += histDtaItem.distance;

            if (typeof histDtaItem.distance_gps === 'number') {
                this.buffer.distance_gps += histDtaItem.distance_gps;
            }
            if (typeof histDtaItem.distance_can === 'number') {
                this.buffer.distance_can += histDtaItem.distance_can;
            }

            if (histDtaItem.duration.moving > 0) {
                this.buffer.positions = [...this.buffer.positions, ...histDtaItem.positions];
            } else {
                this.buffer.positions = histDtaItem.positions;
            }
        } else {
            this.flushBuffer();
            this.buffer = histDtaItem;
        }
    }

    private processHistoryRequest(history: STObject[], processRefuelings: boolean, fuelConsumptionDisplayType: number): void {
        this.processedHistory = [];
        let jStringfy = JSON.stringify(history[0]);
        this.buffer = JSON.parse(jStringfy);

        for (let index = 1; index < history.length; index++) {
            jStringfy = JSON.stringify(history[index]);
            this.addToBuffer(JSON.parse(jStringfy));
        }
        this.flushBuffer();
        const conFuelLevelBased = processRefuelings ?
            new ConsumptionFuelLevelBased(this.processedRefuelings.consumptions, this.processedRefuelings.chartData) : null;
        let stopNumber = 1;
        let flIndex = 0;
        this.processedHistory.forEach(
            (item: STObject, itemIndex: number) => {
                if (processRefuelings) {
                    this.processedRefuelings.distance += item.distance;
                }
                if (item.duration.moving === 0) {
                    item.stopNumber = stopNumber++;
                    item.hiddenStops = null;
                } else {
                    if (processRefuelings) {
                        const fuelConsumption = conFuelLevelBased.getConsumption(item.period.begin, item.period.end);
                        if (fuelConsumptionDisplayType === FuelConsumption.DISPLAY_TYPE_DISTANCE_BASED) {
                            // FuelConsumption.DISPLAY_TYPE_DISTANCE_BASED
                            item.fuelConsumptionFuelLevelBased = (fuelConsumption * 100) / item.distance;
                            if (!isNaN(item.distance) && item.distance > 0) {
                                item.fuelConsumptionSenzorValueBased =
                                    !isNaN(item.positions[item.positions.length - 1].fcs) && !isNaN(item.positions[0].fcs) &&
                                        (item.positions[item.positions.length - 1].fcs - item.positions[0].fcs !== 0) ?
                                        ((item.positions[item.positions.length - 1].fcs - item.positions[0].fcs) * 100) / item.distance
                                         : null;
                            }
                        } else {
                            // FuelConsumption.DISPLAY_TYPE_TIME_BASED
                            const onDuration = item.duration.idling + item.duration.moving;
                            if (!isNaN(onDuration) && onDuration > 0) {
                                item.fuelConsumptionFuelLevelBased = (fuelConsumption * 3600) / onDuration;
                                item.fuelConsumptionSenzorValueBased =
                                    !isNaN(item.positions[item.positions.length - 1].fcs) && !isNaN(item.positions[0].fcs) &&
                                        (item.positions[item.positions.length - 1].fcs - item.positions[0].fcs !== 0) ?
                                        ((item.positions[item.positions.length - 1].fcs - item.positions[0].fcs) * 3600) / onDuration
                                         : null;
                            } else {
                                item.fuelConsumptionFuelLevelBased = null;
                            }
                        }
                    }
                    //  add (before) stop position to trip
                    if (itemIndex > 0) {
                        item.positions = [...this.processedHistory[itemIndex - 1].positions, ...item.positions];
                    }
                }
                if (processRefuelings) {
                    //  Set fuel level to item positions.
                    let index: number;
                    item.positions.forEach(
                        (el: { dtu: string; lat: number; lng: number; dir: number; spd: number; mil: number; fl: number; fcs: number }) => {
                            for (index = flIndex; index < this.processedRefuelings.chartData.length; index++) {
                                if (moment(this.processedRefuelings.chartData[index].devicetime).isAfter(moment(el.dtu))) {
                                    const position = this.processedRefuelings.chartData[index - 1];
                                    el.fl = position ? position.fuel_level : 0;
                                    break;
                                }
                            }
                            flIndex = index;
                        }
                    );
                }
            }
        );
    }

    private processRefuelingsRequest(data: any, devId: number): void {
        const chartData = data.chartData.map((dc: any) => new RefuelingChart(dc.dt, dc.fuel));
        const refuelings = [];
        if (data.refuelings.length > 0) {
            const dRef = data.refuelings[0];
            const addrRef = (dRef.loc.address_formatted) ? dRef.loc.address_formatted : '';
            const ctrCode = (dRef.loc.address_elements) ?
                (dRef.loc.address_elements.country_code ? dRef.loc.address_elements.country_code : '') : '';
            const addrlnk = 'https://www.google.com/maps/search/?api=1&query=' + (dRef.lat ? dRef.lat : '') + ',' +
                (dRef.lon ? dRef.lon : '');
            const position = { latitude: dRef.lat, longitude: dRef.lon };
            refuelings.push(
                new Refueling(devId, dRef.fd, dRef.db, dRef.de, addrlnk, addrRef, position, ctrCode, dRef.iv, dRef.ev, dRef.mil));
            for (let i = 1; i < data.refuelings.length; i++) {
                if (moment(data.refuelings[i].db).diff(moment(refuelings[refuelings.length - 1].date_end), 'seconds') <= 1) {
                    refuelings[refuelings.length - 1].date_end = data.refuelings[i].de;
                    refuelings[refuelings.length - 1].fuel_diff += data.refuelings[i].fd;
                } else {
                    const ref = data.refuelings[i];
                    const address = (ref.loc.address_formatted) ? ref.loc.address_formatted : '';
                    const addlink = 'https://www.google.com/maps/search/?api=1&query=' + (dRef.lat ? dRef.lat : '') + ',' +
                        (dRef.lon ? dRef.lon : '');
                    const countryCd = (ref.loc.address_elements) ?
                        (ref.loc.address_elements.country_code ? ref.loc.address_elements.country_code : '') : '';
                    const pos = { latitude: ref.lat, longitude: ref.lon };
                    refuelings.push(
                        new Refueling(devId, ref.fd, ref.db, ref.de, addlink, address, pos, countryCd, ref.iv, ref.ev, ref.mil)
                    );
                }
            }
        }

        this.processedRefuelings = {
            chartData: chartData, refuelings: refuelings, distance: 0, consumptions: data.consumptions, deviceId: devId
        };
    }

    public createDeviceHistory(
        historyData: { startDate: string, endDate: string, validDuration: number, apiEndPoint: string, deviceId: number },
        processRefuelings: boolean,
        fuelConsumptionDisplayType: number
    ): Observable<any[]> {
        const postData = { period_begin: historyData.startDate, period_end: historyData.endDate, device_id: historyData.deviceId };
        const historyUri = this.endpointsService.get('report.getHistoryReport', null, historyData.apiEndPoint);
        const observableList = [this.httpClient.post(historyUri, postData).pipe(timeout(60000))];
        if (processRefuelings) {
            const refuelingsUri = this.endpointsService.get('report.getDeviceDataRefuelings', null, historyData.apiEndPoint);
            observableList.push(this.httpClient.post(refuelingsUri, postData).pipe(timeout(60000)));
        }
        return forkJoin(observableList).pipe(
            map((histData: any[]) => {
                if (histData[0].length > 0) {
                    this.minStopDuration = historyData.validDuration * 60;
                    if (processRefuelings) {
                        this.processRefuelingsRequest(histData[1], postData.device_id);
                        this.processHistoryRequest(histData[0], processRefuelings, fuelConsumptionDisplayType);
                        return [this.processedHistory, this.processedRefuelings];
                    } else {
                        this.processHistoryRequest(histData[0], processRefuelings, fuelConsumptionDisplayType);
                        return [this.processedHistory, []];
                    }
                }
                return [[], []];
            })
        );
    }

    public getVehiclesHistoryPositions(deviceData: any): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('device.positions', null, deviceData.apiEndPoint), deviceData
        ).pipe(map((positions: any[]) => {
            var JSONbig = require('json-bigint')({ storeAsString: true });
            positions.forEach(
                pos => {
                    const attributes = JSONbig.parse(pos.attributes);
                    Object.keys(attributes).forEach((key) => pos[key] = attributes[key]);
                    delete pos.attributes;
                }
            );

            return positions;
        }));
    }

    public updateDeviceRefuelingsData(
        hostServer: string, refuelingThresholds: { deviceId: number, refuelingTreshold: number, leakTreshold: number }
    ): void {
        this.httpClient.post(this.endpointsService.get('report.updateDeviceRefuelingsData', null, hostServer), refuelingThresholds)
            .subscribe(() => noop, () => noop);
    }
}
