<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-16">
    {{ data.message }}
</h4>
<mat-form-field class="w-100">
    <input type="details" matInput type="details" name='code'
        placeholder="{{ 'Rer.clients.addEdit.overview.code' | translate }}" [(ngModel)]="code">
</mat-form-field>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="onCancel()">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4" (click)="onSave()"
            [ngClass]="code ? 'utility-green-bg-color' : 'bgcolor-blue disabled'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="code ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>