import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RerService } from 'app/modules/rer/_service/rer.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';

import { RerRfidTag } from '../../../_model/rerrfidtag.model';
import { RerWasteType } from 'app/modules/rer/_model/rerwastetype.model';
import { RerBinType } from 'app/modules/rer/_model/rerbintype.model';
import { RerClient } from 'app/modules/rer/_model/rerclient.model';
import { RerBin } from 'app/modules/rer/_model/rerbin.model';
import { RerBinOwnership } from './../../../_model/rerbinownership';
import { RerBinSetupDocument } from 'app/modules/rer/_model/rerbinsetupdocument.model';
import { AuthService } from 'app/service/auth.service';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-verbal-process',
    templateUrl: './verbal-process.component.html',
    styleUrls: ['./verbal-process.component.scss']
})
export class VerbalProcessComponent implements OnInit {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean;
    public rfidTagList$ = null;
    public rfidTagList = null;
    public isEditable = true;
    public dateFormat: string;

    public rerClientBinSetupDocumentList$: Observable<RerBinSetupDocument[]>;
    public currentFilterValue: string = null;
    public hasDeleteRight: boolean = false;

    @Input() client: RerClient;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(private rerService: RerService,
        public actionDialog: MatDialog,
        private userPreferencesService: UserPreferencesService,
        private translationService: TranslateService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;

        this.displayedColumns = [
            'number','rer_client_name', 'client_address', 'count_bins', 'bin_type', 'waste_type', 'responsible_person', 'placement_address', 'date', 'signed_at', 
            'deleted_at', 'deleted_by','actions'
        ];
        if (this.client.id) {
            this.rerService.getRerBinSetupDocumentList(this.client.id);
            this.rerClientBinSetupDocumentList$ = this.rerService.rerClientBinSetupDocumentList$;
  
            this.rerClientBinSetupDocumentList$.subscribe(
              (documents: RerBinSetupDocument[]) => {
                  this.dataSource = new MatTableDataSource(documents);
                  this.setDataTablePaginatorSort();
                  if (this.currentFilterValue) {
                      this.applyFilter(this.currentFilterValue);
                }
              }
            )
        }

        if (this.client.status !== RerClient.STATUS_ACTIVE) {
            this.isEditable = false;
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_VERBAL_PROCESS_DELETE)) {
			this.hasDeleteRight = true;
		}

    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }


    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public deleteBinSetupDocument(id){
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.rerService
                        .getTranslationFor('Rer.rerVerbalProcess.list.confirmDeleteActionBinSetupDocument')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    const document = this.rerService.getClientBinSetupDocumentById(id);
                    this.rerService.deleteClientBinSetupDocument(document).subscribe(
                        () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerVerbalProcess.deletedSuccefuly")),
                        () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerVerbalProcess.deletedFailed"))
                    );
                }
            }
        );
    }

}
