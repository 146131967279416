import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import 'moment-duration-format';
import { TranslateService } from '@ngx-translate/core';

import { UserPreferencesService } from '../../../service/userpreferences.service';
import { UnitMeasurementsService } from '../../../service/unitmeasurements.service';
import { GoogleMapCustomControl } from './../shared/googlemapcustomcontrol.service';
import { GoogleKmlService } from './../map/googlekmlservice.service';
import { GoogleapisService } from './../shared/googleapis.service';
import { HistoryService } from './../map/history.service';
import { MapService } from './../shared/map.service';

import { RouteInterface as RouteShippingInterface } from '../../tms/shipping/interface/route.interface';
import { HistHighlightedSegment } from '../../model/map/histhighlightedsegment.model';
import { MapVehicleRoute } from '../../model/map/mapvehicleroute.model';
import { SelectedVehicle } from '../../model/map/selectedvehicle.model';
import { Duration } from '../../map/interface/duration.interface';
import { RoutePoint } from '../../model/map/routepoint.model';
import { MapVehicle } from '../../model/map/mapvehicle.model';
import { Geofence } from '../../model/map/geofence.model';
import { STObject } from '../../model/map/stobject.model';

import * as StringPixelWidth from 'string-pixel-width';

const DirectionArrowMarkerzIndex = 21;
const InfoBoxzIndex = 999;

@Injectable()
export class GoogleMapDrawService {
    private static maxZoomLevelOnDisplayRoute = 12;
    private _routePoints: RoutePoint[] = [];
    private _map;
    private mapEventsEnabled = true;
    private isForStaticMap: boolean;
    public isRouteLoad = false;
    public flightPathHoverColorFirst = '#F58220';
    public flightPathStrokeColorFirst = '#274397';
    public flightPathStrokeWeightFirst = 4;
    public flightPathStrokeOpacityFirst = 1;
    public markerScaledSize = 42;
    public flightPathStrokeWeightSecond = 4;
    public routePointsSubject = new Subject<RoutePoint[]>();
    public routePolylineSubject = new Subject<any[]>();
    public drawVehicleRouteOnMapSubject = new Subject<{ vehicleRoute: MapVehicleRoute, routeId: number }>();

    public get routePoints(): RoutePoint[] {
        return this._routePoints;
    }

    public get zoomlevelOnOverview(): number {
        if (window.innerWidth <= 420) {
            return 6;
        } else if (window.innerWidth <= 768) {
            return 10;
        } else {
            return 5;
        }
    }

    constructor(
        private mapService: MapService,
        private historyService: HistoryService,
        private googleapisService: GoogleapisService,
        private translateService: TranslateService,
        private unitMeasurementsService: UnitMeasurementsService,
        private googleMapCustomControlService: GoogleMapCustomControl,
        private googleKmlService: GoogleKmlService,
        private userPreferencesService: UserPreferencesService,
    ) { }

    public closeStartEndInfoWindow(selectedVehicle) {
        if (selectedVehicle.startMarker.info) {
            selectedVehicle.startMarker.info.close();
        }
        if (selectedVehicle.endMarker.info) {
            selectedVehicle.endMarker.info.close();
        }
    }

    private deleteStartEndMarker(selectedVehicle: SelectedVehicle) {
        if (selectedVehicle.startMarker.marker) {
            selectedVehicle.startMarker.marker.setMap(null);
        }
        if (selectedVehicle.endMarker.marker) {
            selectedVehicle.endMarker.marker.setMap(null);
        }
    }

    public deleteHistoryItemDraw(selectedVehicle: SelectedVehicle, highlightedSegments = null) {
        this.deleteStartEndMarker(selectedVehicle);
        selectedVehicle.historyInfo.forEach(
            (item: STObject) => {
                if (item.mapPath) {
                    item.mapPath.setMap(null);
                }
                if (item.mapMarker) {
                    if (item.mapMarker) {
                        item.mapMarker.marker.setMap(null);
                    }
                    if (item.mapMarker.info) {
                        item.mapMarker.info.close();
                    }
                }
            }
        );
        this.closeStartEndInfoWindow(selectedVehicle);
        if (highlightedSegments && highlightedSegments.length > 0) {
            highlightedSegments.forEach(
                (segment: HistHighlightedSegment) => {
                    if (segment.shape.length > 0) {
                        segment.shape.forEach((shape: google.maps.Polyline) => shape.setMap(null));
                    }
                }
            );
        }
    }

    private generateMarkerIcon(type) {
        switch (type) {
            case 'start':
                return 'assets/images/map/historyStartMarker.png';
            case 'stop':
                return 'assets/images/map/historyStopMarker.png';
            case 'end':
                return 'assets/images/map/historyEndMarker.png';
        }

    }

    public closeDirectionArrowMarkerInfoWindow(infowindow) {
        const element = document.getElementById('directionArrowCloseWbg');
        if (element) {
            const closeInfoWindow = () => infowindow.close();
            google.maps.event.addDomListener(element, 'click', closeInfoWindow);
        }
    }

    public setDirectionArrowInfoWindowsLocationAddress(position: any) {
        const addressMaxLength = 170;
        this.googleapisService.reverseGeocodeNominatim(position.lat, position.lng).subscribe(
            response => {
                const element = document.querySelector('span[data-device="' + position.dtu + '"]');
                if (element) {
                    let address = this.googleapisService.formatNominatimAddress(response);

                    if (address.length <= addressMaxLength) {
                        element.textContent = address;
                    } else {
                        address = address.substr(0, addressMaxLength);
                        element.textContent = address.substr(0, address.lastIndexOf(' '));
                    }
                }
            }
        );

    }

    private generateArrowMarkerContent(
        position: any, vehicle: MapVehicle, speedValue: string, flValue: string, mlgValue: string, dateFormat: string
    ): string {
        let dateFixtime, gpscoordonates, speed, address, mileageLabel, fuelLevelLabel;
        this.translateService.get('Map.vehicleHistory.date').subscribe(res => dateFixtime = res);
        this.translateService.get('Map.vehicleInfo.address').subscribe(res => address = res);
        this.translateService.get('Map.vehicleInfo.gpscoordonates').subscribe(res => gpscoordonates = res);
        this.translateService.get('Map.vehicleInfo.mileage').subscribe(res => mileageLabel = res);
        this.translateService.get('Map.vehicleInfo.fuellevel').subscribe(res => fuelLevelLabel = res);
        this.translateService.get('Map.vehicleInfo.speed').subscribe(res => speed = res);
        return '<div id="content" class="container-fluid p-1" style="cursor: unset!important">' +
            '<div class="d-flex mt-1 mb-1"><div class="p-0" style="width: 37%"><p class="p-0 m-0 text-left ">' + dateFixtime +
            ': ' + this.mapService.setDateOffset(position.dtu, false, dateFormat.toUpperCase() + ' HH:mm:ss') +
            '</p><p class="p-0 m-0 text-left">' + speed + ': <span class="font-weight-normal">' + speedValue + '</span></p>' +
            (flValue ? '<p class="p-0 m-0 text-left">' + fuelLevelLabel + ': <span class="font-weight-normal">' + flValue +
                '</span></p>' : '') + '</div><div class="pl-0" style="width: 63%"><p class="p-0 m-0 text-left">' + gpscoordonates +
            ': <a href="http://www.google.com/maps/place/' + position.lat + ',' + position.lng +
            '"target = "_blank" class="color-white" >' + position.lat + ',' + position.lng + '</a></p>' +
            '<p class="p-0 m-0 text-left font-weight-normal"></p> ' +
            (vehicle['attributes']['mileage_attribute'] ? '<p class="p-0 m-0 text-left">' + mileageLabel +
            ': <span class="font-weight-normal">' + mlgValue + '</span></p>' : '') + '</div></div><div><p class="p-0 m-0 text-justify">' +
            address + ': <span class="p-0 m-0 text-left font-weight-normal" data-device="' + position.dtu + '"></span></p></div><span ' +
            'class="position-absolute bgcolor-white d-flex align-items-center justify-content-center ml-1 ng-star-inserted pointer"' +
            'style="width: 16px; height: 16px; top: 5px; right: 5px; border-radius: 50%;" id="directionArrowCloseWbg">' +
            '<i class="icon-cts-action-close font-size-10 color-blue"></i></span></div>';
    }

    public generateDirectionArrowMarker(position: any, map: google.maps.Map, vehicle: MapVehicle, dateFormat: string) {
        const pie = (360 / 64);
        const cssSelector = (Math.round(position.dir / pie)) * (pie);
        const marker = new google.maps.Marker({
            position: { lat: position.lat, lng: position.lng }, map: map, zIndex: DirectionArrowMarkerzIndex,
            optimized: false, icon: { url: 'assets/images/map/history-arrow.png#' + cssSelector, anchor: new google.maps.Point(7, 7) }
        });
        const speedValue = this.unitMeasurementsService.getUMValue(position.spd, 'speed', true, 0);
        const mlgValue = this.unitMeasurementsService.getUMValue(position.mil, 'distance', true, 0);
        const flValue = position.fl ? this.unitMeasurementsService.getUMValue(position.fl, 'volume', true, 2) : null;
        const contentString = this.generateArrowMarkerContent(position, vehicle, speedValue, flValue, mlgValue, dateFormat);
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const infobox = new InfoBox({
            content: contentString, disableAutoPan: false, maxWidth: 500, alignBottom: false,
            pixelOffset: new google.maps.Size(-210, -120), zIndex: null, pane: 'floatPane',
            boxStyle: {
                opacity: 0.95, zIndex: InfoBoxzIndex, backgroundColor: '#274397', width: '400px',
                borderRadius: '6px', fontFamily: 'Open Sans', fontSize: '11px',
            },
            infoBoxClearance: new google.maps.Size(1, 1)
        });

        const callback = () => {
            this.historyService.itemStopActiveSubject.next(null);
            this.mapService.closeExistingInfoWindowsSubject.next(null);
            infobox.open(map, marker);
            setTimeout(() => {
                const element = document.getElementById('directionArrowCloseWbg');
                if (element) {
                    const closeInfoBox = () => infobox.close();
                    google.maps.event.addDomListener(element, 'click', closeInfoBox);
                }
            }, 300);
            this.setDirectionArrowInfoWindowsLocationAddress(position);
            this.historyService.activateDetailPositionSubject.next(position.dtu);

        };
        marker.addListener('click', callback);

        return { marker: marker, info: infobox };
    }

    public generateFlightPathPolyline(flightPlanCoordinates, map, strokeColor, strokeOpacity, strokeWeight, zIndex = 1) {
        const flightPath = new google.maps.Polyline({
            path: flightPlanCoordinates, geodesic: true, strokeColor: strokeColor,
            strokeOpacity: strokeOpacity, strokeWeight: strokeWeight, zIndex: zIndex
        });
        flightPath.setMap(map);

        return flightPath;
    }

    public getPointsInViewPort(map: google.maps.Map, positions: any): any[] {
        let point, resultPositions;
        const currentBounds = map.getBounds(); // get bounds of the map object's viewport
        resultPositions = [];
        positions.forEach(
            (pos) => {
                point = new google.maps.LatLng(parseFloat(pos.lat), parseFloat(pos.lng));
                if (currentBounds.contains(point)) {
                    resultPositions.push(pos);
                }
            }
        );

        return resultPositions;
    }

    public compareDistanceBetweenArrowMarkers(map: google.maps.Map, firstPosition: any, secondPosition: any): any {
        const p1 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(firstPosition.lat, firstPosition.lng));
        const p2 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(secondPosition.lat, secondPosition.lng));
        const pixelSize = Math.pow(2, -map.getZoom());
        const d = Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
        return d;
    }

    public getPositionsForDirectionArrowMarkers(map: google.maps.Map, positions: any[], distanceBetweenMrkers: number): any[] {
        let i = 0;
        let j = 1;
        const resultPositions = [];
        while (i < positions.length - 2 && j < positions.length - 1) {
            const firstPosition = positions[i];
            const secondPosition = positions[j];
            const distance = this.compareDistanceBetweenArrowMarkers(map, firstPosition, secondPosition);
            if (Math.trunc(distance) > distanceBetweenMrkers) {
                resultPositions.push(positions[j]);
                i = j;
                j++;
            } else {
                j++;
            }
        }

        return resultPositions;
    }

    public toggleHistoryItemStopInfoWindow(historyItem: STObject, stopNumber: number, map: google.maps.Map) {
        if (historyItem.stopNumber === stopNumber && historyItem.mapMarker && historyItem.mapMarker.info) {
            historyItem.mapMarker.info.open(map, historyItem.mapMarker.marker);
        } else if (historyItem.stopNumber !== null && historyItem.mapMarker && historyItem.mapMarker.info) {
            historyItem.mapMarker.info.close();
        }
    }

    private createHistoryStopMarker(map: google.maps.Map, historyItem: STObject, markerType: string): google.maps.Marker {
        const marker = new google.maps.Marker({
            position: { lat: historyItem.positions[0].lat, lng: historyItem.positions[0].lng },
            zIndex: google.maps.Marker.MAX_ZINDEX + historyItem.stopNumber, map: map
        });

        const icon = { url: this.generateMarkerIcon(markerType) };
        if (markerType === 'stop') {
            let fontSize: string;
            let originX = 15;
            let originY = 14;

            switch (historyItem.stopNumber.toString().length) {
                case 1:
                    fontSize = '13px';
                    break;
                case 2:
                    fontSize = '10px';
                    break;
                case 3:
                    fontSize = '9px';
                    break;
                default:
                    fontSize = '8px'; originX = 14; originY = 14;
                    break;
            }

            icon['labelOrigin'] = new google.maps.Point(originX, originY);
            const label = {
                text: historyItem.stopNumber.toString(), color: '#274397', fontFamily: 'Open Sans', fontWeight: 'bolder', fontSize: fontSize
            };
            marker.setLabel(label);
        }
        marker.setIcon(icon);

        return marker;
    }

    private createHistoryStopInfoBox(historyItem: STObject, dateFormat: string): any {
        const duration = moment.duration(historyItem.duration.stopped + historyItem.duration.idling, 'seconds') as Duration;
        const contentString = '<div id="content" class="d-flex flex-row align-items-center"><div class="m-1 p-2 bgcolor-white rounded">' +
            '<p class="p-0 m-0 color-blue font-weight-bold">' + historyItem.stopNumber + '</p></div><div class="m-1">' +
            '<p class="p-0 m-0 text-left text-nowrap">' +
            this.mapService.setDateOffset(historyItem.period.begin, false, dateFormat + ' HH:mm:ss') + '</p>' +
            '<p class="p-0 m-0 text-left text-nowrap">' +
            this.mapService.setDateOffset(historyItem.period.end, false, dateFormat + ' HH:mm:ss') + '</p></div>' +
            '<div class="m-1"><i class="icon-cts-vehicle-duration font-size-42 color-white"></i></div><div><p class="p-0 m-0 text-left">' +
            'DURATION</p><p class="p-0 m-0 text-left text-nowrap">' + duration.format('h[h] m[m] s[s]') + '</p></div></div>';
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        return new InfoBox({
            content: contentString, disableAutoPan: false, alignBottom: false, zIndex: null,
            pixelOffset: new google.maps.Size(-140, -120), pane: 'floatPane', enableEventPropagation: true,
            boxStyle: { opacity: 0.95, zIndex: 999, padding: '5px', backgroundColor: '#274397' },
            infoBoxClearance: new google.maps.Size(1, 1),
        });
    }

    public drawHistoryItemOnMap(historyItem: STObject, map: google.maps.Map, markerType: string, dateFormat: string) {
        if (historyItem) {
            if (historyItem.stopNumber === null) {
                historyItem.mapPath = this.generateFlightPathPolyline(
                    historyItem.positions.map(pos => ({ lat: pos.lat, lng: pos.lng })), map, this.flightPathStrokeColorFirst,
                    this.flightPathStrokeOpacityFirst, this.flightPathStrokeWeightFirst
                );
            } else {
                const marker = this.createHistoryStopMarker(map, historyItem, markerType);
                const infobox = this.createHistoryStopInfoBox(historyItem, dateFormat.toUpperCase());
                const callback = () => {
                    this.historyService.itemStopActiveSubject.next(historyItem.stopNumber);
                    infobox.open(map, marker);
                };
                marker.addListener('click', callback);
                historyItem.mapMarker = { marker: marker, info: infobox };
            }
        }
    }

    private isHighlightedSegmentLogicFulfilled(positionValue: number, userValue: number, logic: string): boolean {
        switch (logic) {
            case '=':
                if (positionValue === userValue) {
                    return true;
                }
                break;
            case '<>':
                if (positionValue !== userValue) {
                    return true;
                }
                break;
            case '>':
                if (positionValue > userValue) {
                    return true;
                }
                break;
            case '<':
                if (positionValue < userValue) {
                    return true;
                }
                break;
            case '>=':
                if (positionValue >= userValue) {
                    return true;
                }
                break;
            case '<=':
                if (positionValue <= userValue) {
                    return true;
                }
                break;
            default:
                return false;
        }
    }

    public drawHistHighlightedSegmentOnMap(histHighlightedSegments: HistHighlightedSegment[], positions: any, map: google.maps.Map): void {
        histHighlightedSegments.forEach(
            (segment: HistHighlightedSegment) => {
                const logic = segment.logic;
                let flightPlanCoordinates = [];

                positions.forEach(
                    (pos: any, index: number) => {
                        const logicFulfilled = this.isHighlightedSegmentLogicFulfilled(+pos.spd, +logic.value, logic.status);

                        if (logicFulfilled) {
                            flightPlanCoordinates.push({ lat: pos.lat, lng: pos.lng });
                        } else {
                            if (flightPlanCoordinates.length > 0) {
                                if (positions[index + 1]) {
                                    flightPlanCoordinates.push({ lat: pos.lat, lng: pos.lng });
                                }
                                segment.shape.push(this.generateFlightPathPolyline(
                                    flightPlanCoordinates, map, segment.logic.color, this.flightPathStrokeOpacityFirst,
                                    this.flightPathStrokeWeightFirst, 5
                                ));
                                flightPlanCoordinates = [];
                            }
                        }
                    }
                );
                if (flightPlanCoordinates.length > 0) {
                    segment.shape.push(this.generateFlightPathPolyline(
                        flightPlanCoordinates, map, segment.logic.color, this.flightPathStrokeOpacityFirst,
                        this.flightPathStrokeWeightFirst, 5
                    ));
                }
            }
        );
    }

    public getCrossingGeofences(positions: any, geofences: Geofence[]): any {
        const foundGeofencesIds = [];
        positions.forEach(
            (position: any) => {
                geofences.forEach(
                    (geofence: Geofence) => {
                        if (foundGeofencesIds.indexOf(geofence.id) === -1) {
                            const currentGeofence = new Geofence(
                                geofence.id, geofence.client_id, geofence.user_id, geofence.name, geofence.description, geofence.address,
                                geofence.color, geofence.type, geofence.center_latitude, geofence.center_longitude, geofence.radius,
                                geofence.coords, geofence.precalc_values, geofence.geofenceGroups, geofence.shape, geofence.checked,
                                geofence.label, geofence.marker,
                            );
                            if (currentGeofence.type === 'path' && currentGeofence.isInside(position.lat, position.lng)) {
                                foundGeofencesIds.push(currentGeofence.id);
                            } else {
                                if (currentGeofence.distanceBetweenPoints(
                                    { lat: position.lat, lng: position.lng }) <= currentGeofence.radius / 1000) {
                                    foundGeofencesIds.push(currentGeofence.id);
                                }
                            }
                        }
                    }
                );

            }
        );

        return foundGeofencesIds;
    }

    public generateStartEndMarker(map: google.maps.Map, position: any, type: string, dateFormat: string) {
        const url = this.generateMarkerIcon(type);
        const marker = new google.maps.Marker({ position: { lat: position.lat, lng: position.lng }, map: map, icon: { url: url } });
        const content = '<div id="content" class="d-flex flex-row align-items-center"><div class="m-1"><p class="p-0 m-0 text-left">' +
            this.mapService.setDateOffset(position.fixtime, false, dateFormat + ' HH:mm:ss') + '</p></div></div>';
        const infowindow = new google.maps.InfoWindow({ content: content });
        const callback = () => { infowindow.open(map, marker); this.historyService.itemStopActiveSubject.next(null); };
        marker.addListener('click', callback);

        return { marker: marker, info: infowindow };
    }

    public displayRoute(points, addresses, options, flagCodes, wayPoints, map, eventsEnabled = true, isForStaticMap = false, isRouteLoad = false) {
        this.mapEventsEnabled = eventsEnabled;
        this.isForStaticMap = isForStaticMap;
        this.isRouteLoad = isRouteLoad;
        this._map = map;
        let countValidPoints = 0;
        const bounds = new google.maps.LatLngBounds();
        // update routePoints according to given points list
        for (let i = 0; i < points.length; i++) {
            this.routingUpdateRoutePoint(i, points, addresses, flagCodes, options, wayPoints);
            if (points[i] != null) {
                countValidPoints++;
                bounds.extend(points[i]);
            }
        }

        // cleanup
        if (this._routePoints.length > points.length) {
            for (let i = points.length; i < this._routePoints.length; i++) {
                this.routingEmptyRoutePoint(i);
            }
            this._routePoints.splice(points.length);
        }
        // determine map center and zoom level
        if (countValidPoints < 1) {
            map.setCenter(new google.maps.LatLng(48.210033, 16.363449));
            map.setZoom(4);
            return;
        } else if (countValidPoints === 1) {
            map.setCenter(bounds.getCenter());
            map.setZoom(8);
        } else {
            map.fitBounds(bounds, 50);
            if (map.getZoom() > GoogleMapDrawService.maxZoomLevelOnDisplayRoute) {
                map.setZoom(GoogleMapDrawService.maxZoomLevelOnDisplayRoute);
            }
        }

    }

    private routingUpdateRoutePoint(index, points, addresses, flagCodes, options, wayPoints) {

        const currentPoint = points[index];
        const prevPoint = points[index - 1];
        if (currentPoint != null) {
            if (!this._routePoints[index]) {
                this._routePoints.push(new RoutePoint());
            } else if (index === 0 && JSON.stringify(this._routePoints[index].latlng) === JSON.stringify(currentPoint)) {
                return;
            } else if (
                JSON.stringify(this._routePoints[index].latlng) === JSON.stringify(currentPoint) &&
                JSON.stringify(this._routePoints[index - 1].latlng) === JSON.stringify(prevPoint) &&
                JSON.stringify(this._routePoints[index].options) === JSON.stringify(options[index])
            ) {
                return;
            }

            // update _routePoints[index]
            if (JSON.stringify(this._routePoints[index].latlng) !== JSON.stringify(currentPoint)) {
                // update marker
                this._routePoints[index].latlng = Object.assign({}, currentPoint);
                this._routePoints[index].address = addresses[index];
                this._routePoints[index].addressResponse = null;
                this._routePoints[index].flagCode = flagCodes[index];
                this.routingUpdateMarker(index);
                //  update next route because at next step
                //  it will not detect change in this (previous) point
                if (this._routePoints[index + 1]) {
                    this.routingUpdateRouteBetweenPoints(index + 1);
                }
            }
            this._routePoints[index].options = Object.assign({}, options[index]);
            this._routePoints[index].wayPoints = wayPoints[index];
            if (index > 0) {
                this.routingUpdateRouteBetweenPoints(index);
            }
        } else {
            this.routingEmptyRoutePoint(index);
        }
    }

    private routingEmptyRoutePoint(index) {
        if (this._routePoints[index]) {
            if (this._routePoints[index].marker != null) {
                this._routePoints[index].marker.setMap(null);
            }
            this.routingEmptyRoutePointDisplay(index);
            if (this._routePoints[index + 1]) {
                this.routingEmptyRoutePointDisplay(index + 1);
            }
            this._routePoints[index] = new RoutePoint();
        } else {
            this._routePoints.push(new RoutePoint());
        }
        // let everyone know
        this.routePointsSubject.next(Object.assign({}, this._routePoints));
        this.routePolylineSubject.next(Object.assign({}, this.convertroutePointsToroutePoly(this._routePoints)));
    }

    private routingEmptyRoutePointDisplay(index) {
        this._routePoints[index].distance = null;
        this._routePoints[index].duration = null;
        this._routePoints[index].options = { avoidHighways: false, avoidTolls: false, avoidFerries: false, };
        if (this._routePoints[index].routeDisplay != null) {
            this._routePoints[index].routeDisplay.setMap(null);
            this._routePoints[index].routeData = null;
        }
    }

    private routingUpdateMarker(index) {
        if (this._routePoints[index].marker == null) {
            this._routePoints[index].marker = new google.maps.Marker({
                position: this._routePoints[index].latlng,
                label: { text: this.convertNumberToLetter(index), color: 'white', fontSize: '10px', fontWeight: 'bold' },
                map: this._map,
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(this.setRouteMarkerIcon()),
                    labelOrigin: new google.maps.Point(9, 11)
                },
                clickable: false, draggable: this.mapEventsEnabled,
            });
            if (this.mapEventsEnabled) {
                this._routePoints[index].marker.addListener('dragend', (event) => {
                    this._routePoints[index].latlng = {
                        lat: this._routePoints[index].marker.getPosition().lat(), lng: this._routePoints[index].marker.getPosition().lng()
                    };
                    this.googleapisService.reverseGeocode(this._routePoints[index].latlng.lat, this._routePoints[index].latlng.lng)
                        .subscribe(
                            (response) => {
                                this._routePoints[index].address = response.address_formatted;
                                this._routePoints[index].addressResponse = response;
                                this._routePoints[index].flagCode = response.address_elements.country_code;
                                this.routePointsSubject.next(Object.assign({}, this._routePoints));
                                this.routePolylineSubject.next(Object.assign({}, this.convertroutePointsToroutePoly(this._routePoints)));
                            },
                            // (error) => console.log(error)
                        );
                    if (index > 0 && this._routePoints[index - 1].latlng != null) {
                        this.routingUpdateRouteBetweenPoints(index);
                    }
                    if (index + 1 < this._routePoints.length && this._routePoints[index + 1].latlng != null) {
                        this.routingUpdateRouteBetweenPoints(index + 1);
                    }
                });
                this._routePoints[index].marker.addListener('rightclick', () => {
                    this.mapService.contextMenuSubject.next({
                        lat: this._routePoints[index].marker.getPosition().lat(), lng: this._routePoints[index].marker.getPosition().lng()
                    });
                }
                );
            }
        } else {
            this._routePoints[index].marker.setMap(null);
            this._routePoints[index].marker.setPosition(this._routePoints[index].latlng);
            this._routePoints[index].marker.setMap(this._map);
        }
    }

    private routingUpdateRouteBetweenPoints(index) {
        if (index === 0) {
            return;
        }
        const currentPoint = this._routePoints[index];
        const prevPoint = this._routePoints[index - 1];
        if (prevPoint.latlng == null) {
            // cleanup
            if (currentPoint.routeDisplay != null) {
                currentPoint.routeDisplay.setMap(null);
                currentPoint.routeDisplay = null;
            }
            currentPoint.distance = null;
            currentPoint.duration = null;
            currentPoint.routeData = null;
            return;
        }
        const service = new google.maps.DirectionsService;
        const request: google.maps.DirectionsRequest = {
            origin: prevPoint.latlng, destination: currentPoint.latlng, travelMode: google.maps.TravelMode.DRIVING,
            waypoints: this.isForStaticMap ? currentPoint.wayPoints : [], optimizeWaypoints: false,
            unitSystem: google.maps.UnitSystem.METRIC, provideRouteAlternatives: false, avoidHighways: currentPoint.options.avoidHighways,
            avoidTolls: currentPoint.options.avoidTolls, avoidFerries: currentPoint.options.avoidFerries,
        };

        service.route(request, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                if (currentPoint.routeDisplay != null) {
                    currentPoint.routeDisplay.setDirections(result);
                } else {
                    const ds = new google.maps.DirectionsRenderer({
                        polylineOptions: { strokeColor: '#274397', strokeOpacity: 0.9, strokeWeight: 4 },
                        map: this._map, suppressMarkers: true, preserveViewport: true, draggable: this.mapEventsEnabled,
                    });
                    ds.setDirections(result);
                    currentPoint.routeDisplay = ds;
                    ds.addListener('directions_changed', () => {
                        const dResult = ds.getDirections().routes[0].legs[0];
                        if (dResult.via_waypoints.length > 0) {
                            this.googleapisService.reverseGeocode(
                                dResult.via_waypoints[0].lat().toString(), dResult.via_waypoints[0].lng().toString()
                            ).subscribe(
                                (response) => {
                                    this.mapService.addNewRouteItemSubject.next({
                                        address: response.address_formatted, lat: dResult.via_waypoints[0].lat(),
                                        lng: dResult.via_waypoints[0].lng(), countryCode: response.address_elements.country_code,
                                        index: index
                                    });
                                }
                            );
                        }
                        this.routePointsSubject.next(Object.assign({}, this._routePoints));
                        this.routePolylineSubject.next(Object.assign({},
                            this.convertroutePointsToroutePoly(this._routePoints)));
                    });
                }
                currentPoint.routeData = result;
                currentPoint.distance = result.routes[0].legs[0].distance.value;
                currentPoint.duration = result.routes[0].legs[0].duration.value;
                if (!this.isForStaticMap) {
                    // let everyone know
                    this.routePointsSubject.next(Object.assign({}, this._routePoints));
                } else {
                    this.routePolylineSubject.next(Object.assign({}, this.convertroutePointsToroutePoly(this._routePoints)));
                }
            }
            //commented due to complex routes causing api limit errors and blocking client
            // else {
                //alert('Could not display directions due to: ' + status);
            // }
        });
    }

    private setRouteMarkerIcon(color = '#274397'): string {
        return '<svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M9.5 0C4.3 0 0 4.24901 0 9.38735C0 11.6601 0.8 13.834 2.3 15.5138L9.5 25L16.7 15.5138C18.2 13.834 19 ' +
            '11.6601 19 9.38735C19 4.24901 14.7 0 9.5 0Z" fill="white"/><path d="M9.5 2C5.4 2 2 5.4 2 9.5C2 11.4 2.7 13.2 ' +
            '3.9 14.5L9.5 22L15.1 14.5C16.3 13.2 17 11.4 17 9.5C17 5.4 13.6 2 9.5 2Z" fill="#274397"/></svg>';
    }

    private setVehicleAddressMarkerIcon(color: string): string {
        return '<svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M9.5 0C4.3 0 0 4.24901 0 9.38735C0 11.6601 0.8 13.834 2.3 15.5138L9.5 25L16.7 15.5138C18.2 13.834 ' +
            '19 11.6601 19 9.38735C19 4.24901 14.7 0 9.5 0Z" fill="white"/><path d="M9.5 2C5.4 2 2 5.4 2 9.5C2 11.4 2.7 ' +
            '13.2 3.9 14.5L9.5 22L15.1 14.5C16.3 13.2 17 11.4 17 9.5C17 5.4 13.6 2 9.5 2Z" fill="' + color + '"/>' +
            '<path d="M7 6L14 9.5L7 13V6Z" fill="white"/></svg>';
    }

    private setPickupMarkerIcon(color: string): string {
        return '<svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M9.5 0C4.3 0 0 4.24901 0 9.38735C0 11.6601 0.8 13.834 2.3 15.5138L9.5 25L16.7 15.5138C18.2 13.834 ' +
            '19 11.6601 19 9.38735C19 4.24901 14.7 0 9.5 0Z" fill="white"/><path d="M9.5 2C5.4 2 2 5.4 2 9.5C2 11.4 2.7 ' +
            '13.2 3.9 14.5L9.5 22L15.1 14.5C16.3 13.2 17 11.4 17 9.5C17 5.4 13.6 2 9.5 2Z" fill="' + color + '"/>' +
            '<path d="M5 9.49998C5 11.9 7 13.9 9.4 13.9C11.8 13.9 13.8 11.9 13.8 9.49998C13.8 7.09998 11.8 5.09998 9.4 ' +
            '5.09998C7 5.09998 5 7.09998 5 9.49998Z" fill="white"/><path d="M4 9.5C4 12.5556 6.44444 15 9.5 15C12.5556 15 ' +
            '15 12.5556 15 9.5C15 6.44444 12.5556 4 9.5 4C6.44444 4 4 6.44444 4 9.5ZM12.5556 ' +
            '10.1111H10.7222V12.5556H8.27778V10.1111H6.44444L9.5 5.83333L12.5556 10.1111Z" fill="' + color + '"/></svg>';
    }

    private setDeliveryMarkerIcon(color: string): string {
        return '<svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<path d="M9.5 0C4.3 0 0 4.24901 0 9.38735C0 11.6601 0.8 13.834 2.3 15.5138L9.5 25L16.7 15.5138C18.2 13.834 ' +
            '19 11.6601 19 9.38735C19 4.24901 14.7 0 9.5 0Z" fill="white"/><path d="M9.5 2C5.4 2 2 5.4 2 9.5C2 11.4 2.7 ' +
            '13.2 3.9 14.5L9.5 22L15.1 14.5C16.3 13.2 17 11.4 17 9.5C17 5.4 13.6 2 9.5 2Z" fill="' + color + '"/>' +
            '<path d="M5 9.49998C5 11.9 7 13.9 9.4 13.9C11.8 13.9 13.8 11.9 13.8 9.49998C13.8 7.09998 11.8 5.09998 ' +
            '9.4 5.09998C7 5.09998 5 7.09998 5 9.49998Z" fill="white"/><path d="M15 9.5C15 6.44444 12.5556 4 9.5 4C6.44444 ' +
            '4 4 6.44444 4 9.5C4 12.5556 6.44444 15 9.5 15C12.5556 15 15 12.5556 15 9.5ZM6.44444 8.88889L8.27778 ' +
            '8.88889V6.44444L10.7222 6.44444V8.88889H12.5556L9.5 13.1667L6.44444 8.88889Z" fill="' + color + '"/></svg>';
    }

    public getLatLngFromString(location) {
        if (!location || location === null) {
            return null;
        }
        const latlang = location.replace(/[()]/g, '');
        const latlng = latlang.split(',');
        const locate = new google.maps.LatLng(parseFloat(latlng[0]), parseFloat(latlng[1]));
        return locate;
    }

    public getCountryCodeFromAddressComponents(components: any): String {
        for (const component of components) {
            if (component.types.indexOf('country') !== -1) {
                return component.short_name.toLowerCase();
            }
        }

        return '';
    }

    public convertNumberToLetter(number: Number) {
        switch (number) {
            case 0: return 'A';
            case 1: return 'B';
            case 2: return 'C';
            case 3: return 'D';
            case 4: return 'E';
            case 5: return 'F';
            case 6: return 'G';
            case 7: return 'H';
            case 8: return 'I';
            case 9: return 'J';
            case 10: return 'K';
            case 11: return 'L';
            case 12: return 'M';
            case 13: return 'N';
            case 14: return 'O';
            case 15: return 'P';
            case 16: return 'Q';
            case 17: return 'R';
            case 18: return 'S';
            case 19: return 'T';
            case 20: return 'U';
            case 21: return 'V';
            case 22: return 'W';
            case 23: return 'X';
            case 24: return 'Y';
            case 25: return 'Z';
            default: return '';
        }
    }

    public convertroutePointsToroutePoly(routePoints: RoutePoint[]) {
        let arrSum = 0;
        routePoints.forEach((element) => { arrSum = arrSum + +element.distance; });
        const maxCityNr = 11 - (routePoints.length - 2);
        return routePoints.map(
            (item, index) => {
                const currentItem: any = {};
                currentItem['label'] = this.convertNumberToLetter(index);
                currentItem['position'] = item.latlng;
                if (item.routeData) {
                    currentItem['polyline'] = item.routeData.routes[0].overview_polyline;
                    currentItem['distance'] = item.distance;
                    const citynr = ((item.distance * 100) / arrSum);
                    if (item.distance && item.routeData.routes[0].legs[0].steps) {
                        currentItem['intermediatePoints'] = this.getPointsBetweenCities(
                            currentItem['distance'],
                            Math.floor(citynr + 1),
                            maxCityNr,
                            item.routeData.routes[0].legs[0].steps);
                    }
                } else {
                    currentItem['distance'] = 0;
                    currentItem['polyline'] = item.routeData;
                }
                return currentItem;
            }
        );
    }

    private getPointsBetweenCities(totalDistance: number, cityNr: number = 100, maxCityNr: number = 11, legs: any[]) {
        cityNr = Math.floor((maxCityNr * (cityNr / 100)));
        let minDistance = Math.floor(totalDistance / (cityNr + 1) * 0.85);
        minDistance = (minDistance <= 5000 ? 5000 : minDistance);
        const intermediates: any[] = [];
        let currentDistance = 0;
        legs.forEach((item) => {
            currentDistance = currentDistance + item.distance.value;
            if (currentDistance >= minDistance) {
                intermediates.push({
                    'lat': item.end_location.lat(), 'lng': item.end_location.lng(),
                    'latLng': item.end_location.lat() + ',' + item.end_location.lng(), 'distance': currentDistance,
                });
                currentDistance = 0;
            }
        });
        return intermediates;
    }

    public deleteRouteFromMap(vehicleRoute: MapVehicleRoute, map: google.maps.Map): void {
        if (vehicleRoute.routePolylines.length > 0) {
            vehicleRoute.routePolylines.forEach((line: google.maps.Polyline) => line.setMap(null));
            vehicleRoute.routePolylines = [];
        }
        if (vehicleRoute.routeMarkers.length > 0) {
            vehicleRoute.routeMarkers.forEach((mrk: google.maps.Marker) => mrk.setMap(null));
            vehicleRoute.routeMarkers = [];
        }
    }

    public drawRouteOnMap(vehicleRoute: MapVehicleRoute, map: google.maps.Map, routeId = null): void {
        this.deleteRouteFromMap(vehicleRoute, map);
        let routes: RouteShippingInterface[];
        let routeColor = '#FF0000';

        if (routeId) {
            if (vehicleRoute.routes && vehicleRoute.routes.length > 0) {
                const route = vehicleRoute.routes.find((rt: RouteShippingInterface) => rt.id === routeId);
                if (route) {
                    routes = route.routes;
                    routeColor = route.color;
                } else {
                    return;
                }
            }
        } else {
            routes = vehicleRoute.visibleRoute.routes;
            routeColor = vehicleRoute.visibleRoute.color;
        }

        routes.forEach(
            (route: RouteShippingInterface) => {
                const itemPosition = route.position.split(',');
                const position = { lat: parseFloat(itemPosition[0]), lng: parseFloat(itemPosition[1]) };
                let svgData;
                switch (route.flag) {
                    case 0:
                        svgData = this.setVehicleAddressMarkerIcon(routeColor);
                        break;
                    case 1:
                        svgData = this.setPickupMarkerIcon(routeColor);
                        break;
                    case 2:
                        svgData = this.setDeliveryMarkerIcon(routeColor);
                        break;
                }
                const icon = { url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgData) };
                const marker = new google.maps.Marker({
                    position: position, zIndex: google.maps.Marker.MAX_ZINDEX, map: map, icon: icon
                });
                vehicleRoute.routeMarkers.push(marker);
                if (Array.isArray(route.path) && route.path.length) {
                    const line = new google.maps.Polyline({
                        path: route.path.map((rt: any) => (new google.maps.LatLng(rt.lat, rt.lng))),
                        strokeColor: routeColor, strokeOpacity: 0.9, strokeWeight: 4
                    });
                    line.setMap(map);
                    vehicleRoute.routePolylines.push(line);
                }
            }
        );
    }

    public onZoomChanged = (
        selectedVehicle: SelectedVehicle, endCreateHistoryInfo: boolean, map: google.maps.Map,
        tilesloaded: () => void, kmlLayersShown: boolean, kmlList, zipLayersShown: boolean, zipMarkers
    ) => {
        if (selectedVehicle && Object.keys(selectedVehicle.historyInfo).length > 0 && endCreateHistoryInfo) {
            google.maps.event.addListenerOnce(map, 'idle', tilesloaded);
        }
        let bonduaryZoom;
        let zipZoom;
        if (kmlLayersShown) {
            bonduaryZoom = this.setZipBonduaryLayout(map.getZoom(), kmlList, map);
        }
        if (zipLayersShown) {
            zipZoom = this.setZipPatternLayout(map.getZoom(), zipMarkers, map);
        }
        if (selectedVehicle) {
            google.maps.event.addListenerOnce(map, 'idle', tilesloaded);
        }
        return { bonduaryZoom: bonduaryZoom, zipzoom: zipZoom };
    }

    private createZipCodesControl(mapTypes: HTMLElement, innerHTML: string, map: google.maps.Map,
        zipLayersCurrentZoom: number, zipLayersShown: boolean, zipMarkers) {
        if (this.userPreferencesService.userPreferences.map.zipPattern) {
            const zipCodes = document.querySelector('div.gmnoprint.gm-style-mtc ul > li.zip-code:nth-of-type(7),div.gmnoprint.gm-style-mtc ul > li.zip-code:nth-of-type(8)');
            if (!zipCodes) {
                let controZipCodesUI: HTMLElement;
                controZipCodesUI = document.createElement('li');
                controZipCodesUI.className = 'zip-code';
                controZipCodesUI.setAttribute("role", "menuitemcheckbox");
                controZipCodesUI.innerHTML = innerHTML + 'ZIP Codes</label>';
                controZipCodesUI.addEventListener('mousedown', function(event) {
                    event.stopImmediatePropagation();
                    zipLayersCurrentZoom = map.getZoom();
                    const { layerShown, markers } = this.toogleZipPatternLayer(map.getZoom(), zipLayersShown, zipMarkers, map);
                    zipLayersShown = layerShown;
                    zipMarkers = markers;
                    this.googleMapCustomControlService.toggleControlCheckBox(controZipCodesUI);
                }.bind(this));
                mapTypes.after(controZipCodesUI);
            }
        }
        return { zipLayersCurrentZoom: zipLayersCurrentZoom, zipLayersShown: zipLayersShown, zipMarkers: zipMarkers };
    }

    private createBoundaryControl(mapTypes: HTMLElement, innerHTML: string, map: google.maps.Map,
        kmlLayerCurrentZoom: number, kmlLayersShown: boolean, kmlList) {
        if (this.userPreferencesService.userPreferences.map.zipBoundary) {
            const zipBoundary = document.querySelector('div.gmnoprint.gm-style-mtc ul > li.zip-boundary:nth-of-type(7),div.gmnoprint.gm-style-mtc ul > li.zip-boundary:nth-of-type(8)');
            if (!zipBoundary) {
                let controlBoundaryUI: HTMLElement;
                controlBoundaryUI = document.createElement('li');
                controlBoundaryUI.className = 'zip-boundary';
                controlBoundaryUI.setAttribute("role", "menuitemcheckbox");
                controlBoundaryUI.innerHTML = innerHTML + 'Boundaries</label>';
                controlBoundaryUI.addEventListener('mousedown', function(event) {
                    event.stopImmediatePropagation();
                    kmlLayerCurrentZoom = map.getZoom();
                    const { layerShown, list } = this.toogleKmlLayer(map.getZoom(), kmlLayersShown, kmlList, kmlLayerCurrentZoom, map);
                    kmlLayersShown = layerShown;
                    kmlList = list;
                    this.googleMapCustomControlService.toggleControlCheckBox(controlBoundaryUI);
                }.bind(this));
                mapTypes.after(controlBoundaryUI);
            }
        }
        return { kmlLayerCurrentZoom: kmlLayerCurrentZoom, kmlLayersShown: kmlLayersShown, kmlList: kmlList };
    }

    public styleMapTypes(map: google.maps.Map,
        zipLayersCurrentZoom: number, zipLayersShown: boolean, zipMarkers,
        kmlLayerCurrentZoom: number, kmlLayersShown: boolean, kmlList
    ) {
        const mapTypes: any = document.querySelector('div.gmnoprint.gm-style-mtc ul > li:last-child');
        if (mapTypes) {
            const mapTerrain = document.querySelector('div.gmnoprint.gm-style-mtc ul > li:nth-of-type(5)');
            if (mapTerrain) {
                mapTerrain.firstChild.firstChild['src'] = GoogleMapCustomControl.checkBoxUnchecked;
                mapTerrain.firstChild.lastChild['src'] = GoogleMapCustomControl.checkBoxChecked;
            }

            const mapSatellite = document.querySelector('div.gmnoprint.gm-style-mtc ul > li:nth-of-type(6)');
            if (mapSatellite) {
                mapSatellite.firstChild.firstChild['src'] = GoogleMapCustomControl.checkBoxUnchecked;
                mapSatellite.firstChild.lastChild['src'] = GoogleMapCustomControl.checkBoxChecked;
            }
            return {
                zip: this.createZipCodesControl(mapTypes, GoogleMapCustomControl.innerHTML, map,
                    zipLayersCurrentZoom, zipLayersShown, zipMarkers),
                boundary: this.createBoundaryControl(mapTypes, GoogleMapCustomControl.innerHTML, map,
                    kmlLayerCurrentZoom, kmlLayersShown, kmlList)
            };
        }
    }

    private toogleZipPatternLayer(zoomLevel: number, zipLayersShown: boolean, zipMarkers, map: google.maps.Map) {
        if (zipLayersShown && zipMarkers) {
            this.googleKmlService.hideZipPatternLayerCountry(zipMarkers, zoomLevel);
        } else if (!zipLayersShown && zipMarkers && Object.getOwnPropertyNames(zipMarkers).length > 0) {
            this.googleKmlService.showZipPatternLayerCountry(zipMarkers, map, zoomLevel);
        } else {
            this.googleKmlService.createZipPatternMarkers(
                zipMarkers,
                this.userPreferencesService.userPreferences.map.zipCountries,
                map,
                zoomLevel);
        }
        zipLayersShown = !zipLayersShown;
        return { layerShown: zipLayersShown, markers: zipMarkers };
    }

    public setZipPatternLayout(zoomLevel: number, zipMarkers, map: google.maps.Map) {
        let zipLayersCurrentZoom;
        if (zipLayersCurrentZoom !== zoomLevel) {
            this.googleKmlService.hideZipPatternLayerCountry(zipMarkers, zipLayersCurrentZoom);
            this.googleKmlService.showZipPatternLayerCountry(zipMarkers, map, zoomLevel);
            zipLayersCurrentZoom = zoomLevel;
        }
        return zipLayersCurrentZoom;
    }

    public setZipBonduaryLayout(zoomLevel: number, kmlList, map: google.maps.Map) {
        let kmlLayerCurrentZoom;
        if (kmlLayerCurrentZoom !== zoomLevel) {
            this.googleKmlService.hideKmlLayerCountry(kmlList, kmlLayerCurrentZoom);
            this.googleKmlService.showKmlLayerCountry(kmlList, map, zoomLevel);
            kmlLayerCurrentZoom = zoomLevel;
        }
        return kmlLayerCurrentZoom;
    }

    private toogleKmlLayer(zoomLevel: number, kmlLayersShown: boolean, kmlList, kmlLayerCurrentZoom: number, map: google.maps.Map) {
        if (kmlLayersShown && kmlList) {
            this.googleKmlService.hideKmlLayerCountry(kmlList, kmlLayerCurrentZoom);
        } else if (!kmlLayersShown && kmlLayersShown && Object.getOwnPropertyNames(kmlList).length > 0) {
            this.googleKmlService.showKmlLayerCountry(kmlList, map, zoomLevel);
        } else {
            this.googleKmlService.createKmlLayers(
                kmlList, this.userPreferencesService.userPreferences.map.zipBoundaryCountries, map, zoomLevel
            );
        }
        kmlLayersShown = !kmlLayersShown;

        return { layerShown: kmlLayersShown, list: kmlList };
    }

    public toogleHuGoLayer(huGoLayerVisibile: boolean, huGoLayer, map: google.maps.Map) {
        if (huGoLayerVisibile && huGoLayer) {
            huGoLayer.docs[0].placemarks.forEach(element => { element.marker.setMap(null); });
            huGoLayer.hideDocument();
        } else if (!huGoLayerVisibile && huGoLayer) {
            huGoLayer.docs[0].placemarks.forEach(element => { element.marker.setMap(map); });
            huGoLayer.showDocument();
        } else {
            huGoLayer = this.googleKmlService.createHuGoKml('../../../assets/HU-GO viszonteladói pontok.kml', map);
        }
        huGoLayerVisibile = !huGoLayerVisibile;
        return { huGoLayerVisibile: huGoLayerVisibile, huGoLayer: huGoLayer };
    }

    public createDetailInfoWindow(vehicleName, position, vehicle, deviceid, mapService) {

        //  const contentString = '<div class="label" id="' + vehicleName + '">' + vehicleName + '</div>';

        let dateFixtime, gpscoordonates, speed, address, mileageLabel, fuelLevelLabel;
        this.translateService.get('Map.vehicleHistory.date').subscribe(res => dateFixtime = res);
        this.translateService.get('Map.vehicleInfo.address').subscribe(res => address = res);
        this.translateService.get('Map.vehicleInfo.gpscoordonates').subscribe(res => gpscoordonates = res);
        this.translateService.get('Map.vehicleInfo.mileage').subscribe(res => mileageLabel = res);
        this.translateService.get('Map.vehicleInfo.fuellevel').subscribe(res => fuelLevelLabel = res);
        this.translateService.get('Map.vehicleInfo.speed').subscribe(res => speed = res);

        const speedValue = this.unitMeasurementsService.getUMValue(position.spd, 'speed', true, 0);
        const mlgValue = this.unitMeasurementsService.getUMValue(position.mil, 'distance', true, 0);
        const flValue = position.fl ? this.unitMeasurementsService.getUMValue(position.fl, 'volume', true, 2) : null;

        const contentString = '<div id="content" class="container-fluid p-1" style="cursor: unset!important">' +
            '<div class="d-flex mt-1 mb-1"><div class="p-0" style="width: 37%"><p class="p-0 m-0 text-left ">' + dateFixtime +
            ': ' + this.mapService.setDateOffset(position.dtu) + '</p><p class="p-0 m-0 text-left">' + speed +
            ': <span class="font-weight-normal">' + speedValue + '</span></p>' + (flValue ? '<p class="p-0 m-0 text-left">' +
                fuelLevelLabel + ': <span class="font-weight-normal">' + flValue + '</span></p>' : '') + '</div>' +
            '<div class="pl-0" style="width: 63%"><p class="p-0 m-0 text-left">' + gpscoordonates +
            ': <a href="http://www.google.com/maps/place/' + position.lat + ',' + position.lng +
            '"target = "_blank" class="color-white" >' + position.lat + ',' + position.lng + '</a></p>' +
            '<p class="p-0 m-0 text-left font-weight-normal"></p> ' +
            (vehicle['attributes']['mileage_attribute'] ? '<p class="p-0 m-0 text-left">' + mileageLabel +
            ': <span class="font-weight-normal">' + mlgValue + '</span></p>' : '') + '</div></div><div><p class="p-0 m-0 text-justify">' +
            address + ': <span class="p-0 m-0 text-left font-weight-normal" data-device="' + position.dtu + '"></span></p></div><span ' +
            'class="position-absolute bgcolor-white d-flex align-items-center justify-content-center ml-1 ng-star-inserted pointer"' +
            'style="width: 16px; height: 16px; top: 5px; right: 5px; border-radius: 50%;" id="directionArrowCloseWbg">' +
            '<i class="icon-cts-action-close font-size-10 color-blue"></i></span></div>';
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const pixelOffset = StringPixelWidth(vehicleName, { font: 'Open Sans', size: 15, bold: false }) + 25;
        const infobox = new InfoBox({
            content: contentString, disableAutoPan: true, alignBottom: true, showPointer: true,
            pixelOffset: new google.maps.Size(-1 * ((pixelOffset / 2) + 18), -30),
            infoBoxClearance: new google.maps.Size(1, 1), closeBoxURL: 'assets/images/map/close.png'
        });

        google.maps.event.addListener(infobox, 'closeclick', () => {
            this.mapService.closeContextMenuSubject.next();
        });

        // if (google.maps.event) {
        //     google.maps.event.addListener(infobox, 'domready', function () {
        //         const vehicleDocument = document.getElementById(vehicleName);
        //         if (vehicleDocument) {
        //             vehicleDocument.addEventListener('click', () => mapService.vehicleInfoSubject.next(deviceid));
        //             vehicleDocument.addEventListener('contextmenu', () => mapService.contextMenuSubject.next(deviceid));
        //         }
        //     });
        // }

        return infobox;
    }

}
