<div class="d-flex justify-content-center align-items-center mb-2">
    <div id="instructions w-100" style="min-width: 600px!important;min-height: 200px!important;">
        <img *ngIf="firstImage" style="max-width: 100%!important;" [src]="firstImage.thumb" class="pointer img-responsive" (click)="open()" />
        <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
            class="d-flex justify-content-center align-items-center position-absolute z-index-100"
            *ngIf="showSpinner">
            <mat-spinner></mat-spinner>
        </div>
        <p *ngIf="noVideoOrError">{{ 'Rer.rerPlanning.cameraStreamDialog.noVideoOrError' | translate }}</p>
    </div>
</div>