import { Component, OnInit } from '@angular/core';
import { RerService } from '../_service/rer.service';
import { AuthService } from 'app/service/auth.service';
import { MenuItem } from '../../model/menuitem.model';
import { faScaleUnbalancedFlip } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-leftsidebar',
    templateUrl: './leftsidebar.component.html',
    styleUrls: ['./leftsidebar.component.scss']
})
export class LeftsidebarComponent implements OnInit {
    public menuitems: MenuItem[];

    public powerBiLinkSrc = '/assets/images/power-bi.svg';
    public hasPowerBiRight = false;
    public powerBiRoute = '/';
    public faScaleUnbalancedFlip = faScaleUnbalancedFlip;
    constructor(private rerService: RerService) { }

    ngOnInit() {
        this.menuitems = [];

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_DASHBOARD)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-menu-dashboard font-size-27',
                    'icon-tmss-menu-dashboard',
                    RerService.ROUTE_RER_DASHBOARD,
                    'rerDashboard',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-table-header-scheduled font-size-27',
                    'icon-tmss-table-header-scheduled',
                    RerService.ROUTE_RER_PLANING,
                    'rerPlanning',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_ROUTE_PLANNING_SEARCH)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-cts-action-search font-size-27',
                    'icon-cts-action-search',
                    RerService.ROUTE_RER_ROUTE_PLANNING_SEARCH,
                    'routePlanningSearch',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CLIENTS)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-tab-partners font-size-27',
                    'icon-tmss-menu-drivers',
                    RerService.ROUTE_RER_CLIENTS,
                    'clients',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_BINS)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-tab-cargo3d font-size-27',
                    'icon-tmss-tab-cargo3d',
                    RerService.ROUTE_RER_OBJECTS,
                    'objects',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CLIENT_ASSOCIATION)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-cts-user-overview font-size-22',
                    'icon-cts-user-overview',
                    RerService.ROUTE_RER_OBJECTS_ALLOCATION,
                    'userAssociation',
                    false
                )
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CLIENT_BIN_SETUP_DOC_ASSOCIATION)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-cts-user-overview font-size-22',
                    'icon-cts-user-overview',
                    RerService.ROUTE_RER_BIN_SETUP_DOCUMENTS_ALLOCATION,
                    'rerBinSetupDocumentAssociation',
                    false
                )
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_DATA_IMPORT)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-cts-route-pickup font-size-22',
                    'icon-cts-route-pickup',
                    RerService.ROUTE_RER_DATA_IMPORT,
                    'dataImportRer',
                    false
                )
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_DATA_EXPORT)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-cts-route-delivery-pickup font-size-22',
                    'icon-cts-route-delivery-pickup',
                    RerService.ROUTE_RER_DATA_EXPORT,
                    'dataExportRer',
                    false
                )
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_RFID_MANAGEMENT)) {
            this.menuitems.push(
                new MenuItem(
                    'ctsicon-fap-rfid font-size-27',
                    'ctsicon-fap-rfid',
                    RerService.ROUTE_RER_RFID_MANAGEMENT,
                    'rfidManagement',
                    false
                ),
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_UNALLOCATED_RER_BINS)) {
            this.menuitems.push(
                new MenuItem(
                    'ctsicon-fap-waste-bin font-size-27',
                    'ctsicon-fap-waste-bin',
                    RerService.ROUTE_RER_UNALLOCATED_OBJECTS,
                    'unallocatedObjects',
                    false
                )
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_EMPLOYEE)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-cts-notification-driver font-size-27',
                    'icon-cts-notification-driver',
                    RerService.ROUTE_RER_EMPLOYEE,
                    'rerEmployee',
                    false
                )
            );
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_COLLECTION_BY_WEIGHT)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-tab-application font-size-27',
                    'icon-tmss-tab-application',
                    RerService.ROUTE_RER_COLLECTION_BY_WEIGHT,
                    'rerCollectionByWeight',
                    false
                )
            )
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_INCIDENT)) {
            this.menuitems.push(
                new MenuItem(
                    'ctsicon-leftnav-incident font-size-27',
                    'ctsicon-leftnav-incident',
                    RerService.ROUTE_RER_INCIDENT,
                    'rerIncident',
                    false
                )
            )
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_STREET_GEOZONE)) {
            this.menuitems.push(
                new MenuItem(
                    'ctsicon-topnav-road-off font-size-50',
                    'ctsicon-topnav-road-off',
                    RerService.ROUTE_RER_STREET_GEOZONE,
                    'streetGeozones',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_ROUTE)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-ctl-nav-route-creator-off font-size-50',
                    'icon-ctl-nav-route-creator-off',
                    RerService.ROUTE_RER_ROUTE,
                    'route',
                    false
                )
            );
        }

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_SETTINGS)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-menu-settings font-size-27',
                    'ctsicon-topnav-settings-off',
                    RerService.ROUTE_RER_SETTINGS,
                    'rerSettings',
                    false
                )
            )
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_VERBAL_PROCESS)) {
            this.menuitems.push(
                new MenuItem(
                    'icon-tmss-tab-documents font-size-27',
                    'icon-tmss-tab-documents',
                    RerService.ROUTE_RER_VERBAL_PROCESS,
                    'rerVerbalProcess',
                    false
                )
            )
        }

        this.hasPowerBiRight = this.rerService.hasRight(AuthService.USER_RIGHT_RER_POWER_BI_REPORT);
        this.powerBiRoute = RerService.ROUTE_POWER_BI_REPORT;
    }

    public onModuleClick(route: string): void {
        switch (route) {
            case 'objects-association':
                this.rerService.emptyRerBinsList();
                break;
            case 'rer-bin-setup-document-association':
                this.rerService.emptyRerBinSetupDocumentsList();
                break;
        }
    }

}
