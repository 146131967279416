<h1 mat-dialog-title class="p-2"> {{ 'Rer.dataExportRer.exportBinSetupDetailsTitle' | translate }}</h1>
<div mat-dialog-content>
    <div class="row ml-0 mr-0 mt-4">
        <div class="col-md-12">
            <app-datepicker-range [dateRange]="[exportDates.startDate, exportDates.endDate]"
                (selectDateEventEmitter)="setExportDataPeriod($event)" [time]="false" [alignCenter]="true"
                [justifyCenter]="true" [locale]="getLocale()" [label]="getTranslationFor('dateRange')">
            </app-datepicker-range>
        </div>
        <div class="col-12 mt-4">
            <div class="ng-select-wrapper w-100">
                <ng-select placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.status' | translate }}" [clearable]="false" 
                    [(ngModel)]="selectedOption" appendTo="body">
                    <ng-option *ngFor="let status of statusOptions" [value]="status.value">
                        {{ status.name }}
                    </ng-option>
                </ng-select>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <span class="mr-3 eButton cancel pointer pb-2 pt-2 pl-3 pr-3" (click)="onCancel()">
        {{ 'cancel' | translate }}
    </span>
    <span class="mr-3 eButton pointer primary pb-2 pt-2 pl-3 pr-3" (click)="onExport()">
        {{ 'ok' | translate }}
    </span>

</div>