import { RerUnloadingAreaInterface } from "../_interface/rerunloadingarea.interface";

export class RerUnloadingArea implements RerUnloadingAreaInterface {

    constructor(
        public id = null,
        public client_id = null,
        public active = null,
        public name = null,
        public address = null,
        public type = null,
        public center_latitude = null,
        public center_longitude = null,
        public radius = null,
        public coords = null,
        public precalc_values = null,
        public created_at = null,
        public username = null,
        public shape = null,
        public checked = false,
        public label = null,
        public marker = null,
        public visible = true,
        public color = '#274397',
        public group_color = null,
    ) { }
}