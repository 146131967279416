import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { DynamicScriptLoaderService } from '../../../service/dynamic-script-loader.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsGuard implements CanLoad,CanActivate {
  constructor(private dynamicScriptLoader: DynamicScriptLoaderService){}
  canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<any> {
      return new Promise((resolve, reject) => {
        this.dynamicScriptLoader.load('googleApiScript').then(onfulfilled=>resolve(true));
      });
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.dynamicScriptLoader.load('googleApiScript').then(onfulfilled=>resolve(true));
    });
}
}
