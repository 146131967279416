<ng-template mat-tab-label>
    <i class="ctsicon-topnav-settings-off font-size-26 p-0"></i>
    <span class="ml-1 d-flex align-items-center">
        {{ 'Rer.rerSettings.label' | translate }}
    </span>
</ng-template>
<div class="companycontainer overflow-auto row mt-2 ml-0 mr-0 pt-2">
    <div class="col-md-6 ml-0 pl-0">
        <div class="row mb-3 pl-3">
            <div class="col-md-12 pl-0">
                <mat-form-field class="w-100">
                    <input type="number" matInput required placeholder="{{ 'Rer.rerSettings.deviceSpeed' | translate }}"
                        [(ngModel)]="deviceMinimumSpeed">
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3 pl-3">
            <div class="col-md-12 pl-0">
                <mat-form-field class="w-100">
                    <input type="number" matInput required placeholder="{{ 'Rer.rerSettings.validUntil' | translate }}"
                        [(ngModel)]="validUntil">
                </mat-form-field>
            </div>
        </div>
        <div class="row mb-3 pl-3">
            <div class="col-md-12 pl-0">
                <mat-form-field class="w-100">
                    <input type="number" matInput required placeholder="{{ 'Rer.rerSettings.invalidFrom' | translate }}"
                        [(ngModel)]="invalidFrom">
                </mat-form-field>
            </div>
        </div>
        <div class="border-top-grey my-3">
            <h4 class="my-3">{{ 'Rer.rerSettings.categories.phones' | translate }}</h4>
            <div class="row mb-3 pl-3">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput (keypress)="removeChars($event)"
                            placeholder="{{ 'Rer.rerSettings.phoneX' | translate:{ number: 1 } }}" [(ngModel)]="phone1">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3 pl-3">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput (keypress)="removeChars($event)"
                            placeholder="{{ 'Rer.rerSettings.phoneX' | translate:{ number: 2 } }}" [(ngModel)]="phone2">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3 pl-3">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput (keypress)="removeChars($event)"
                            placeholder="{{ 'Rer.rerSettings.phoneX' | translate:{ number: 3 } }}" [(ngModel)]="phone3">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3 pl-3">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput (keypress)="removeChars($event)"
                            placeholder="{{ 'Rer.rerSettings.phoneX' | translate:{ number: 4 } }}" [(ngModel)]="phone4">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3 pl-3">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput (keypress)="removeChars($event)"
                            placeholder="{{ 'Rer.rerSettings.phoneX' | translate:{ number: 5 } }}" [(ngModel)]="phone5">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3 pl-3" *ngIf="hasPowerBIRight">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput placeholder="{{ 'Rer.rerSettings.powerBIUrl' | translate }}" [(ngModel)]="powerBIUrl">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-between align-items-center button-container">
    <div class="button-component utility-green-bg-color" (click)="saveSettings()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'save' | translate }}</span>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
