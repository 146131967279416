import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RerRfidTagReading } from 'app/modules/rer/_model/rerrfidtagreading.model';

@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddEditComponent>,
        public domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    ngOnInit() { 
        if(!this.data.binType.file) {
            this.data.binType.file = RerRfidTagReading.DEFAULT_BIN_MARKER;
        }

        if(!this.data.binType.scale) {
            this.data.binType.scale = 0.03;
        }
    }

    onSave() {
        this.dialogRef.close(this.data.binType);
    }

    public onFileChanged(event: any): void {
        const file = event.target.files[0];
        if (file.type && file.type.indexOf('image') !== -1 && file.type.indexOf('image/svg+xml') !== -1) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.data.binType.file = reader.result as string;
            };
        }
    }
}
