<div style="width:12rem;">
    <div class="header">
        <h6>{{ lat }} , {{ lng }}</h6>
        <p class="card-text"> {{ address }}</p>
    </div>
    <div class="list-group">
        <a class="list-group-item pointer" (click)="copyMessage()">
            <span class="icon"><fa-icon [icon]="faCopy"></fa-icon></span>
            <span class="text">{{ 'Coordinates.copyToClipboard' | translate }}</span>
        </a>
        <a class="list-group-item pointer" href="http://www.google.com/maps/place/{{ lat }},{{ lng }}" target="_blank">
            <span class="icon"><fa-icon [icon]="faMap"></fa-icon></span>
            <span class="text">{{ 'Coordinates.sendToMap' | translate }}</span>
        </a>
        <a *ngIf="hasRouteRight" class="list-group-item pointer" (click)="redirectToRoutes()">
            <span class="icon"><i class="icon-ctl-nav-route-creator-on"></i></span>
            <span class="text">{{ 'Coordinates.sendToRoute' | translate }}</span>
        </a>
        <a class="list-group-item pointer" (click)="addLocation()">
            <span class="icon"><i class="icon-ctl-nav-route-creator-on"></i></span>
            <span class="text">{{ 'Coordinates.addLocation' | translate }}</span>
        </a>
        <a class="list-group-item pointer" (click)="setDefaultMapCenter()">
            <span class="icon"><i class="icon-cts-map-focus"></i></span>
            <span class="text">{{ 'Coordinates.mapDefaultCenter' | translate }}</span>
        </a>
        <a *ngIf="canCreateGeozone && hasRight('settings-geozone')" class="list-group-item pointer" (click)="createGeozone()">
            <span class="icon"><i class="icon-cts-map-focus"></i></span>
            <span class="text">{{ 'Coordinates.addGeofenceButton' | translate }}</span>
        </a>
    </div>
</div>