import { ClientDataImportRerInterface } from "../_interface/clientdataimportrer.interface";

export class ClientDataImportRer implements ClientDataImportRerInterface {
    constructor(
        public type = null,
        public code = null,
        public name = null,
        public referenceDate = null,
        public phone = null,
        public addressCode = null,
        public area = null,
        public addressName = null,
        public division = null,
        public city = null,
        public street = null,
        public streetNumber = null,
        public wasteCode = null,
        public binCode = null,
        public binOwnershipCode = null,
        public quantity = null,
    ) { }
}
