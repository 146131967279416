import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RerService } from '../../_service/rer.service';
import { RerClientInterface } from '../../_interface/rerclient.interface';
import { ClientDataImportRer } from '../../_model/clientdataimportrer.model';

@Component({
    selector: 'app-clients-import',
    templateUrl: './clients-import.component.html',
    styleUrls: ['./clients-import.component.scss']
})
export class ClientsImportComponent implements OnInit {

    public showSpinner: boolean;
    public fileColumns: string[];
    public dataForImport: ClientDataImportRer[] = [];
    public dataWarningList = {};
    public Object;
    public showFinalImport: boolean;
    public fileName: string;

    constructor(private rerService: RerService) { }

    ngOnInit() {
        this.Object = Object;
        this.fileColumns = [
            'Tip client',
            'Cod client',
            'Denumire',
            'Data semnarii',
            'Telefon',
            'Cod secundar',
            'Zona facturare',
            'Denumire PL',
            'Diviziune',
            'Localitate',
            'Strada',
            'Nr Strada',
            'Tip deseu',
            'Tip pubela',
            'Tip proprietate',
            'Cantitate'
        ];
    }

    private isImportLineValid(importLine: string[], line: number): { warnings: any[], data: any } {
        let response = true;
        let dataWarningList = { warnings: [], data: null };
        if (importLine.length !== 16) {
            dataWarningList.warnings.push('Numarul de coloane diferit de 16!');
            dataWarningList.data = importLine;
            return dataWarningList;
        }
        const codPattern = /^[0-9]+$/i;
        if (!codPattern.test(importLine[1])) {
            dataWarningList.warnings.push('Coloand <span class="font-weight-bold">Cod client</span> trebuie sa contina doar numere!');
            response = false;
        }
        if (!codPattern.test(importLine[5])) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Cod secundar</span> trebuie sa contina doar numere!');
            response = false;
        }

        // eslint-disable-next-line max-len
        const pattern = /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        if (!pattern.test(importLine[3])) {
            // eslint-disable-next-line max-len
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Data semnarii</span> trebuie sa fie valida si in format <span class="font-weight-bold">zz.ll.aaaa</span>!');
            response = false;
        }

        if (!importLine[0] || importLine[0].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip client</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[2] || importLine[2].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Denumire</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[7] || importLine[7].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Denumire PL</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[9] || importLine[9].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Localitate</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[13] || importLine[13].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip pubela</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[12] || importLine[12].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip deseu</span> nu poate fi goala!');
            response = false;
        }

        if (isNaN(parseInt(importLine[15], 10))) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Cantitate</span> trebuie sa fie un numar!');
            if (response) {
                importLine[12] = null;
                importLine[13] = null;
            }

            response = false;
        }

        if (response) {
            dataWarningList = null;
        } else {
            dataWarningList.data = importLine;
        }

        return dataWarningList;
    }

    public onInportFileChanged(event: any): void {
        this.showSpinner = true;
        const file = event.target.files[0];
        const reader = new FileReader();
        this.fileName = file.name;
        reader.readAsText(file);
        reader.onload = () => {
            this.rerService.clientList$.subscribe(
                (clients: RerClientInterface[]) => {
                    const csvData = reader.result;
                    const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
                    if (csvRecordsArray[0].includes('Cod Client') && csvRecordsArray[0].includes('Diviziune')) {
                        csvRecordsArray.splice(0, 1);
                    }
                    const dataWarningList = {};

                    csvRecordsArray.forEach(
                        (line: string, index: number) => {
                            if (line.length > 0) {
                                const dataToProcess = line.split(',');
                                const isValid = this.isImportLineValid(dataToProcess, index);
                                if (isValid) {
                                    dataWarningList[index] = isValid;
                                } else {
                                    this.dataForImport.push(
                                        new ClientDataImportRer(
                                            dataToProcess[0],
                                            dataToProcess[1],
                                            dataToProcess[2],
                                            moment(dataToProcess[3], 'DD.MM.YYYY').format('YYYY-MM-DD').toString(),
                                            dataToProcess[4],
                                            dataToProcess[5],
                                            dataToProcess[6],
                                            dataToProcess[7],
                                            dataToProcess[8],
                                            dataToProcess[9],
                                            dataToProcess[10],
                                            dataToProcess[11],
                                            dataToProcess[12],
                                            dataToProcess[13],
                                            dataToProcess[14],
                                            dataToProcess[15],
                                        )
                                    );
                                }
                            }
                        }
                    );
                    this.dataWarningList = dataWarningList;
                    if (Object.keys(this.dataWarningList).length === 0) {
                        this.showFinalImport = true;
                    }
                    this.showSpinner = false;
                }
            );
        };
    }

    public onCancelImport(): void {
        this.dataForImport = [];
        this.dataWarningList = {};
        this.showFinalImport = false;
    }

    public onContinueImport(): void {
        this.dataWarningList = {};
        this.showFinalImport = true;
    }
}
