import { RerBinSetupDocumentAssociationInterface } from "../_interface/rerbinsetupdocumentassociation.interface";

export class RerBinSetupDocumentAssociation implements RerBinSetupDocumentAssociationInterface {

    public constructor(
        public id = null,
        public name = null,
        public rer_bin_setup_documents = [],
    ) { }
}
