<div class="costsContainer">
    <div class="row w-100 pt-2">
        <div class="col-12">
            <ul class="timeline">
                <li class="timeline-inverted" *ngFor="let log of incident.logs">
                    <div class="timeline-date">
                        <p>{{ log.dateShown }} {{ log.created_at | offset: true | date: 'HH:mm'}}</p>
                    </div>
                    <div class="timeline-panel">
                        <div class="timeline-body">
                            <p [innerHTML]="log.text"></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>