import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { saveAs } from 'file-saver';

import { RerService } from '../../_service/rer.service';

import { RerRfidTag } from '../../_model/rerrfidtag.model';
import { RerRfidTagAllocation } from '../../_model/rerrfidtagallocation.model';
import { RerClientAddress } from '../../_model/rerclientaddress.model';
import { RerBin } from '../../_model/rerbin.model';
import { RerClient } from '../../_model/rerclient.model';
import { RerBinAction } from '../../_constants/rerbinstatusconstants';

import { BinSuspendDialogComponent } from '../../bin-suspend-dialog/bin-suspend-dialog.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { AddEditAddressComponent } from '../../rer-client/add-edit/address/add-edit-address/add-edit-address.component';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';
import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
    private clientAddressListSubject = new BehaviorSubject<RerClientAddress[]>([]);

    public showMandatory: boolean;
    public showSpinner: boolean;
    public showSendPVByEmailButton: boolean;
    public rfidCompleteList: any[];
    public clientList$: Observable<RerClient[]>;
    public clientAddressList$: Observable<RerClientAddress[]> = this.clientAddressListSubject.asObservable();
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    
    @Input() object: RerBin;
    @Input() rfidTagList: RerRfidTag[];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(private rerService: RerService,
                public matDialog: MatDialog,
                public actionDialog: MatDialog,
                private translationService: TranslateService,) { }

    ngOnInit() {
        this.showSendPVByEmailButton = false;
        this.rfidCompleteList = this.rfidTagList.map(x => Object.assign({}, x));
        this.clientList$ = this.rerService.clientList$;
        this.object.code = (String('0').repeat(7) + this.object.id).substr((7 * -1), 7);
        if (this.object.rer_client_id) {
            this.onClientListChange(true);
        }
        if(this.object.rer_bin_setup_document_id && this.object.rer_bin_setup_document_status === 1){
            this.showSendPVByEmailButton = true;
        }

        if (this.rfidCompleteList.find(rt => rt.id === this.object.rer_rfid_tag_id) === undefined) {
            if (this.object.rer_rfid_tag_id) {
                const current = Object.assign({},
                    new RerRfidTag(this.object.rer_rfid_tag_id, null, this.object.id, this.object.rer_rfid, null, null, null, null));
                this.rfidCompleteList.unshift(current);
            }
            this.rfidCompleteList.unshift(new RerRfidTag());
        }

        this.displayedColumns = ['allocation_date', 'object_name', 'period_begin', 'period_end', 'username'];
        if (this.object.rer_rfid_tag_id) {
            this.rerService.getRfidTagAllocationLog(this.object.rer_rfid_tag_id).subscribe(
                (response: RerRfidTagAllocation[]) => {
                    this.dataSource = new MatTableDataSource(response);
                    this.setDataTablePaginatorSort();
                }
            );
        }
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public onClientListChange(init = false): void {
        if (!init) {
            this.object.rer_client_address_id = null;
        }
        this.showSpinner = true;
        this.rerService.getRerClientAddressList(this.object.rer_client_id).subscribe(
            (addressList: RerClientAddress[]) => {
                this.clientAddressListSubject.next(addressList);
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            }
        );
    }

    public onClientAddressListChange(): void {
        const clientAddress = this.clientAddressListSubject.getValue();
        const address = clientAddress.find((el: RerClientAddress) => el.id === this.object.rer_client_address_id);
        if (address) {
            this.object.address = address.address;
            this.object.city = address.city;
        }
    }

    public onSave(): void {
        this.showMandatory = (this.object.waste_type && this.object.rer_client_address_id && this.object.rer_client_id) ? false : true;

        if (!this.showMandatory) {
            this.showSpinner = true;
            if (this.object.rer_rfid_tag_id) {
                const rfidTag = this.rerService.getRfidTagById(this.object.rer_rfid_tag_id);
                if (rfidTag) {
                    this.object.rer_rfid = rfidTag.rfid;
                }
            }

            this.rerService.addEditRerBin(this.object, true).subscribe(
                () => {
                    this.showSpinner = false;
                },
                () => {
                    this.showSpinner = false;
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            );
        }
    }

    public sendByEmail(rerBinSetupDocumentId: number): void{
        if (!rerBinSetupDocumentId) {
            return;
        }

        this.showSpinner = true;
        const client = this.rerService.sendBinSetupByEmail(rerBinSetupDocumentId).subscribe(
            () => {
                this.matDialog.open(
                    NotificationComponent,
                    {
                        maxWidth: '510px',
                        panelClass: 'custom-dialog-container',
                        data: {
                            success: 1,
                            headermessage: this.translationService.instant('Rer.dataImportRer.importSuccessHeader'),
                            message: this.translationService.instant('Rer.sendBinSetupByEmail.sendEmailSuccessMessage')
                        }
                    }
                );
                this.showSpinner = false;
            },
            (error: HttpErrorResponse) => {
                let errorMessage = '';
                switch (error.status) {
                    case  StatusCodes.FAILED_DEPENDENCY:
                        errorMessage = 'Rer.sendBinSetupByEmail.clientHasNoEmail';
                        break;
                    case  StatusCodes.NOT_FOUND:
                        errorMessage = 'Rer.sendBinSetupByEmail.failedSend';
                        break;
                    default :
                        errorMessage = 'errorNotification';
                }
                this.matDialog.open(
                    NotificationComponent,
                    {
                        panelClass: 'custom-dialog-container',
                        maxWidth: '480px',
                        data: {
                            headermessage: this.translationService.instant('error'),
                            message: this.translationService.instant(errorMessage),
                            success: 0
                        }
                    }
                );
                this.showSpinner = false;
            }
        );
    }

    public addNewClientAddress(): void {
        if (!this.object.rer_client_id) {
            return;
        }

        const client = this.rerService.getRerClientById(this.object.rer_client_id);
        const dialogRef = this.actionDialog.open(
            AddEditAddressComponent,
            {
                width: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    address: new RerClientAddress(null, null, null, null, 1, null, null, null, this.object.rer_client_id),
                    clientName: client.name,
                    isDefaultAddressDisabled: false
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (clientAddress: RerClientAddress) => {
                if (clientAddress) {
                    this.showSpinner = true;
                    this.rerService.addEditRerClientAddress(clientAddress).subscribe(
                        (res: { id: number }) => {
                            clientAddress.id = res.id;
                            const clientAddr = this.clientAddressListSubject.getValue();
                            const newAddress = clientAddr.slice(0);
                            newAddress.push(clientAddress);
                            this.clientAddressListSubject.next(newAddress);
                            this.rerService.updateDefaultClientAddress(clientAddress.rer_client_id, clientAddress);
                            this.showSpinner = false;
                        },
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );

                }
            }
        );
    }

    public onSuspend(): void {
        const dialogRef = this.actionDialog.open(
            BinSuspendDialogComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: { object: this.object }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: RerBin) => {
                if (obj) {
                    this.changeBinStatus(RerBin.STATUS_SUSPENDED);
                }
            }
        );
    }


    public onChangeObjectStatus(status: number) {
        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('Rer.objects.action.' + RerBinAction[status] + ''),
                    contentMessage: this.translationService.instant('Rer.objects.confirmStatusChange')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (data) => {
                if (data) {
                    this.changeBinStatus(status);
                }
            }
        );
    }

    private changeBinStatus(status: number) {
        this.object.status = status;
        this.rerService.editUnalocatedRerBinChangeStatus(this.object)
            .pipe(finalize(() => {
                this.showSpinner = false;
            }))
            .subscribe(
                () => {
                    this.rerService.showSaveConfirmation(
                        this.translationService.instant('Rer.objects.statusChangeSuccess'));
                },
                () => {
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            );
    }

    public getBinTypeName(obj: RerBin) {
        if (!obj.rer_bin_type_id) {
            return '';
        }

        let binType = this.rerService.getBinTypeById(obj.rer_bin_type_id);
        return binType ? binType.name : '';
    }

    public getWasteTypeName(obj: RerBin) {
        if (!obj.rer_waste_type_id) {
            return '';
        }

        let wasteType = this.rerService.getWasteTypeById(obj.rer_waste_type_id);
        return wasteType ? wasteType.name : '';
    }

    public getRerBinOwnershipName(obj: RerBin) {
        if (!obj.rer_bin_ownership_id) {
            return '';
        }

        let onOwnership = this.rerService.getRerBinOwnershipById(obj.rer_bin_ownership_id);
        return onOwnership ? onOwnership.name : '';
    }

    public downloadVerbalProcess(binId){
        this.rerService.downloadVerbalProcess(binId).subscribe(
            (response: any) => {
              if (response.data.length !== undefined) {
                const filename = response.filename;
                const content = response.data;
                const filetype = '';
                const byteCharacters = content;
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: filetype });
                this.showSpinner = false;
                saveAs(blob, filename);
              }
            },
            (error: any) => this.rerService.showErrorNotifications(
                this.translationService.instant('errorNotification'))
          );
    }
}
