<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18 text-uppercase">
    {{ ((data.object.id ? 'Rer.objects.action.allocate' : 'Rer.clients.addEdit.objects.addObject') | translate) }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="row">
        <div class="col-md-6">
            <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                <ng-select [items]="cityFilterList$ | async" placeholder="{{ 'Rer.selectCity' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    (change)="onCityListChange()" [(ngModel)]="selectedCityList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                <ng-select [items]="divisionFilterList$ | async" placeholder="{{ 'Rer.selectDivision' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    (change)="onDivisionListChange()" [(ngModel)]="selectedDivisionList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                <ng-select [items]="streetFilterList$ | async" placeholder="{{ 'Rer.selectStreet' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    (change)="onStreetListChange()" [(ngModel)]="selectedStreetList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row mt-4">
                <div class="col-md-8 mr-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput type="details" name='searchCode' [(ngModel)]="searchCode"
                             placeholder="{{ 'Rer.selectClientCode' | translate }}">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <div class="button-component utility-blue-bg-color ml-2" (click)="onClientCodeListChange()">
                        <div class="inner inner-blue user-no-select button-search pb-2 pt-2 pl-4 pr-4">
                            <span class="color-white ml-0">{{ 'search' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                <ng-select [items]="clientList" class="geozone flex-grow-1"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.client' | translate }}"
                    (change)="onClientListChange($event)"
                    appendTo="body" bindLabel="name" bindValue="id" [multiple]="false" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.object.rer_client_id">
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                        <span class="ml-2">{{ item.name }}</span>
                    </ng-template>
                </ng-select>
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.object.rer_client_address_id ?
                    'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </div>
        </div>
        <div class="col-md-6">
            <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                <ng-select [items]="binOwnershipList" class="geozone flex-grow-1"
                    placeholder="{{ 'Rer.selectBinOwnership' | translate }}"
                    appendTo="body" bindLabel="name" bindValue="id" [multiple]="false" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.object.rer_bin_ownership_id">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span class="ml-2">{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
        <ng-select [items]="addressList" class="geozone flex-grow-1"
            placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.address' | translate }}"
            appendTo="body" bindLabel="label" bindValue="id" [multiple]="false" [clearable]="false"
            [closeOnSelect]="true" [(ngModel)]="data.object.rer_client_address_id">
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <span class="ml-2">{{ item.division }} {{ item.city }} {{ item.street }} {{ item.street_number }}</span>
            </ng-template>
        </ng-select>
        <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.object.rer_client_address_id ?
            'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
        </span>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4" (click)="onAdd()" [ngClass]="data.object.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="data.object.id ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{ (data.object.id ? 'save' : 'add') | translate }}</span>
            </div>
        </div>
    </div>
</div>
