import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RerService } from './_service/rer.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/service/auth.service';

@Component({
    selector: 'app-rer-planning',
    templateUrl: './rer.component.html',
    styleUrls: ['./rer.component.scss']
})
export class RerComponent implements OnInit {

    public sidebarActive: boolean;

    constructor(
        private readonly translateService: TranslateService,
        private rerService: RerService,
        private router: Router
    ) { }

    ngOnInit() {
        this.sidebarActive = true;
        this.rerService.getAllDatas();
        this.translateService.setDefaultLang('ro_RO');
        const lang = localStorage.getItem('currentLang');

        if (lang) {
            this.translateService.use(lang);
        }

        const option = this.getRouteOption();
        this.router.navigate(['rer/management', { outlets: { rercontent: [option] } }]);
    }

    onSideBar() {
        this.sidebarActive = !this.sidebarActive;
    }

    private getRouteOption(): String {
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_DASHBOARD)) {
            return RerService.ROUTE_RER_DASHBOARD;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING)) {
            return RerService.ROUTE_RER_PLANING;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CLIENTS)) {
            return RerService.ROUTE_RER_CLIENTS;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_BINS)) {
            return RerService.ROUTE_RER_OBJECTS;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CLIENT_ASSOCIATION)) {
            return RerService.ROUTE_RER_OBJECTS_ALLOCATION;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CLIENT_BIN_SETUP_DOC_ASSOCIATION)) {
            return RerService.ROUTE_RER_BIN_SETUP_DOCUMENTS_ALLOCATION;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_DATA_IMPORT)) {
            return RerService.ROUTE_RER_DATA_IMPORT;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_DATA_EXPORT)) {
            return RerService.ROUTE_RER_DATA_EXPORT;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_RFID_MANAGEMENT)) {
            return RerService.ROUTE_RER_RFID_MANAGEMENT;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_UNALLOCATED_RER_BINS)) {
            return RerService.ROUTE_RER_UNALLOCATED_OBJECTS;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_EMPLOYEE)) {
            return RerService.ROUTE_RER_EMPLOYEE;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_COLLECTION_BY_WEIGHT)) {
            return RerService.ROUTE_RER_COLLECTION_BY_WEIGHT;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_INCIDENT)) {
            return RerService.ROUTE_RER_INCIDENT;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_VERBAL_PROCESS)) {
            return RerService.ROUTE_RER_VERBAL_PROCESS;
        }
    }
}
