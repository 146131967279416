 <div class="h-100" [ngStyle]="{'background-image': 'url(' + background + ' )'}">
	<div class="signin-modal-dialog signin-cascading-modal">
		<div class="shape">
			<div class="modal-content">
				<div class="modal-header m-0">
					<div class="container-fluid">
						<div class="row">
							<div class="col p-0">
								<img class="img-fluid pull-left" src="{{ logoSrc }}">
							</div>
						</div>
					</div>
				</div>
				<div class="modal-body">
					<form (ngSubmit)="changePassword()" #resetPass="ngForm" autocomplete="off">
                        <div class="modal-body">
                            <div class="container-fluid">
                                <div class="md-form form-sm mb-0">
                                    <mat-form-field class="w-100">
                                        <input [type]="hide ? 'password' : 'text'" name="newpassword"
                                            #newpassword="ngModel" ngModel
                                            matInput
                                            [ngClass]="{'invalid' : !newpassword.valid && newpassword.touched}"
                                            (ngModelChange)="wrongCredentials=false" id="newpassword" required>
                                        <mat-icon matSuffix (click)="hide = !hide"><fa-icon [icon]="hide ? faEyeSlash : faEye" class="password-state-icon"></fa-icon></mat-icon>
                                    </mat-form-field>
                                    <label for="newpassword"
                                        #newpasswdLabel>{{ 'passwordRecovery.newpassword' | translate }}
                                    </label>
                                </div>
                                <div class="md-form form-sm mb-0">
                                    <mat-form-field class="w-100">
                                        <input [type]="hide2 ? 'password' : 'text'" name="repeatpassword"
                                            #repeatpassword="ngModel" ngModel
                                            matInput
                                            [ngClass]="{'invalid' : !repeatpassword.valid && repeatpassword.touched}"
                                            (ngModelChange)="wrongCredentials=false" id="repeatpassword" required>
                                            <mat-icon matSuffix (click)="hide2 = !hide2"><i class="fa " [ngClass]="hide ? 'fa-eye' : 'fa-eye-slash' "></i></mat-icon>
                                    </mat-form-field>
                                    <label for="repeatpassword"
                                        #repeatpasswdLabel>{{ 'passwordRecovery.repeatpassword' | translate }}</label>
                                </div>
                                <div class="height-25">
									<p class="text-center p-0 m-0">
										<span *ngIf="wrongCredentials" class="text-danger">
											{{ errorMessage | translate }}
                                        </span>
									</p>
									<p class="text-center p-0 m-0">
										<span class="sign-in-more">
											{{ "passwordRecovery.passHint" | translate }}
                                        </span>
									</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
                                <button type="button" class="btn button utility-grey-bg-color mr-4" aria-label="Close" (click)="onCancel()">
                                    {{ 'close' | translate }}</button>
                                <button [disabled]="!resetPass.valid" type="submit" class="pt-2 pb-2 pr-3 pl-3 btn button utility-green-bg-color">
                                    {{ 'passwordRecovery.buttonsave' | translate}}</button>
                            </div>
                        </div>
                    </form>
				</div>
			</div>
		</div>
	</div>
</div>
