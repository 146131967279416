import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Subject, Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import * as moment from 'moment';

import { TranslateService } from '@ngx-translate/core';
import { EndpointsService } from '../../../service/endpoints.service';

import { FuelConsumption } from '../../model/map/fuelconsumption.model';
import { RefuelingChart } from '../../model/map/refuelingchart.model';
import { DeviceAttribute } from '../../model/deviceattribute.model';
import { MapVehicle } from '../../model/map/mapvehicle.model';
@Injectable()
export class MapService {

    public addNewRouteItemSubject = new Subject<{ address: string, lat: number, lng: number, countryCode: string, index: number }>();
    public refuelingValuesChartSubject = new BehaviorSubject<{ dateBegin: string, dateEnd: string, value: number }[]>([]);
    public historyRouteFitToBoundsSubject = new Subject<google.maps.LatLngBounds>();
    public refuelingChartSubject = new BehaviorSubject<RefuelingChart[]>([]);
    public tripGeoaddressResponseReceivedSubject = new Subject<string>();
    public isSaveWaitMeSpinnerActive = new Subject<boolean>();
    public historyItemDetailsSubject = new Subject<number>();
    public closeExistingInfoWindowsSubject = new Subject();
    public refuelingChartImageSubject = new Subject<any>();
    public sendPositionToMapSubject = new Subject<any>();
    public contextMenuEventSubject = new Subject<any>();
    public vehicleInfoSubject = new Subject<number>();
    public showothersSubject = new Subject<boolean>();
    public vehiclesListSortBySubject = new Subject<void>();
    public followupSubject = new Subject<boolean>();
    public closeContextMenuSubject = new Subject<void>();
    public contextMenuSubject = new Subject<any>();
    public currentBoundsToFit: google.maps.LatLngBounds;

    constructor(private httpClient: HttpClient, public translateService: TranslateService, private endpointsService: EndpointsService) { }

    private deg2rad(deg: any) {
        return deg * (Math.PI / 180);
    }

    public getVehicleFuelLevelSince(deviceId: number, hostname: string): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('device.fuelLevelSince', [deviceId], hostname));
    }

    public getMapDevicesAndGroups(): Observable<any> {

        return forkJoin([
            this.httpClient.get(this.endpointsService.get('device.list')),
            this.httpClient.get<any[]>(this.endpointsService.get('clientdevicegrup.list'))
        ]);
    }

    public getMapDevices(): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('device.list'));
    }

    public getLastMapPosition(hostname: string, tcdeviceids: any): Observable<any> {
        return this.httpClient
        .post(this.endpointsService.get('device.latestPosition', null, hostname), tcdeviceids).pipe(timeout(15000));
    }

    public getLastPositionForFuelConsumption(hostname: string, tcdeviceid: number, datetime: string): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('device.latestPositionRelative', [datetime], hostname), [tcdeviceid]);
    }

    public updateDeviceFuelConsumptionData(fuelConsumptionData: FuelConsumption): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('device.updateFuelConsumption', null), fuelConsumptionData);
    }

    public getVehicleStatusSince(deviceData: any, hostname: string): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('device.statusSince', null, hostname), deviceData);
    }

    public prepareDataForVehicle(rawValues: any, vehicle: MapVehicle) {
        try {
            const driverAttribute = vehicle.driver_id_attribute;
            const fuelLevelAttribute = vehicle.attributes.fuel_level_attribute;
            const mileageAttribute = vehicle.attributes.mileage_attribute;
            const powerSupplyAttribute = vehicle.attributes.power_supply_attribute;
            const cameraAttribute = vehicle.attributes.camera_attribute;
            const ignitionAttribute = vehicle.attributes.ignition_attribute;
            const deviceAttribute = new DeviceAttribute(rawValues, vehicle.definitions);
            const deviceStatus = deviceAttribute.getValue(vehicle.status_attribute, vehicle.deviceId);
            const fuelLevel = fuelLevelAttribute ? deviceAttribute.getValue(fuelLevelAttribute, vehicle.deviceId) : null;
            const fuelConsumption = vehicle.attributes.fuel_consumption_attribute ?
                deviceAttribute.getValue(vehicle.attributes.fuel_consumption_attribute, vehicle.deviceId) : null;
            const mileage = mileageAttribute ? deviceAttribute.getValue(mileageAttribute, vehicle.deviceId) : null;
            const driverId = driverAttribute ? deviceAttribute.getValue(driverAttribute, vehicle.deviceId) : null;
            const powerSupply = powerSupplyAttribute ? deviceAttribute.getValue(powerSupplyAttribute, vehicle.deviceId) : null;
            const camera = cameraAttribute ? deviceAttribute.getValue(cameraAttribute, vehicle.deviceId) : null;
            const ignition = ignitionAttribute ? deviceAttribute.getValue(ignitionAttribute, vehicle.deviceId) : null;

            let infoAttributes = [];
            if(vehicle.infoAttributes.length > 0) {
                vehicle.infoAttributes.forEach(element => {
                    if(element.name == 'speed'){
                        element.computedValue = rawValues.speed;
                    } else {
                        element.computedValue = element.definition ? deviceAttribute.getValue(element.definition, vehicle.deviceId) : null;
                    }
                });
            }

            return {
                deviceid: rawValues.deviceid, latitude: rawValues.latitude, longitude: rawValues.longitude,
                speed: rawValues.speed, course: rawValues.course, datetime: rawValues.datetime, directionIcon: '',
                status: (deviceStatus === deviceAttribute.LOGIC_ERROR) ? 0 : +deviceStatus,
                fuelLevel: (fuelLevel === deviceAttribute.LOGIC_ERROR || +fuelLevel === 0 || +fuelLevel < 0) ? 0 : +fuelLevel,
                fuelConsumption: (fuelConsumption === deviceAttribute.LOGIC_ERROR) ? 0 : +fuelConsumption,
                mileage: (mileage === deviceAttribute.LOGIC_ERROR || +mileage === 0 || +mileage < 0) ? 0 : mileage,
                camera: (camera === deviceAttribute.LOGIC_ERROR) ? null : camera,
                ignition: (ignition === deviceAttribute.LOGIC_ERROR) ? null : +ignition,
                powerSupply: (powerSupply === deviceAttribute.LOGIC_ERROR || +powerSupply === 0 || +powerSupply < 0) ? 0 : +powerSupply,
                driverIdAttribute: (driverId === deviceAttribute.LOGIC_ERROR) ? false : driverId,
                address: (rawValues.address ? rawValues.address : null),
                infoAttributes: infoAttributes
            };
        } catch (error) {
            return {
                deviceid: rawValues.deviceid, latitude: rawValues.latitude, longitude: rawValues.longitude, speed: rawValues.speed,
                course: rawValues.course, datetime: rawValues.datetime, directionIcon: '', status: -1, fuelLevel: 0, fuelConsumption: 0,
                mileage: 0, powerSupply: 0, fuel_consumption: 0, camera: 0, ignition: null, driverIdAttribute: null
            };
        }
    }

    public setDateOffset(date_send, requestToServer = false, timeformat = 'YYYY-MM-DD HH:mm:ss') {

        if (!date_send) {
            return '';
        }

        if (typeof date_send !== 'string') {
            date_send = date_send.toString();
        }
        const offset = new Date().getTimezoneOffset();
        const date = new Date(this.replaceChar(date_send, '-', '/'));
        if (requestToServer) {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }
        return moment(date).format(timeformat);
    }

    public getLocale(): String {
        const lang = localStorage.getItem('currentLang');
        return (lang) ? lang.substring(0, 2) : 'ro';
    }

    public capitalize = (s) => {
        if (typeof s !== 'string') { return ''; }
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    public getTranslationFor(label: string, value = {}): String {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public fitToBounds(positions: any): void {
        const bounds = new google.maps.LatLngBounds();
        positions.forEach(pos => bounds.extend(new google.maps.LatLng(pos.lat, pos.lng)));
        this.historyRouteFitToBoundsSubject.next(bounds);
    }

    public replaceChar(string, to, withChar) {
        let result = '';
        for (const i of string) {
            if (i === to) {
                result += withChar;
            } else {
                result += i;
            }
        }

        return result;
    }

    public distanceBetweenPoints(lat1, lon1, lat2, lon2) {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    }


    public fuelConsumptionIsBetweenLimits(computedValue: any) {
        return (computedValue >= 1 && computedValue <= 150);
    }
}
