<h4 mat-dialog-title class="p-4 header border-bottom-grey-1 font-size-18">
    {{ 'Rer.rerCollectionByWeight.weighingReceipt.receipt_number' | translate }} - {{ data.weighingReceipt.id }} - {{
    data.weighingReceipt.rer_unloading_zone_name }} -
    {{ data.weighingReceipt.device_name }}
    <p class="text-right">{{ 'Rer.rerCollectionByWeight.weighingReceipt.operated_at' | translate }}: {{
        data.weighingReceipt.created_at | offset: false | date: dateFormat + ' HH:mm:ss' | translate }} </p>
</h4>
<div *ngIf="!showSpinner">
    <div mat-dialog-content class="content pl-4 pr-4">
        <div class="row ml-0 mr-0">
            <div class="col-3">
                <p class="mt-3 ml-2">{{ 'Rer.rerCollectionByWeight.weighingReceipt.operated_quantity' | translate }}</p>
            </div>
            <div class="col-4 pl-0 pr-1">
                <mat-form-field class="w-100 mt-1">
                    <input matInput autocomplete="off" type="number"
                        [(ngModel)]="data.weighingReceipt.operated_quantity" name="name" (change)="changeQuantity()"
                        [disabled]="data.weighingReceipt.is_fully_allocated">
                </mat-form-field>
            </div>
        </div>
        <div class="row ml-0 mr-0">
            <div class="col-2">
                <p class="mt-3 ml-2">{{ 'Rer.rerCollectionByWeight.weighingReceipt.proof' | translate }}</p>
            </div>
            <div class="col-10 pl-0 pr-1 mt-3">
                <input type="file" accept="image/png, image/jpeg" class="d-none" (change)="onStampFileChanged($event)"
                    #stampInput>
                <span (click)="stampInput.click()" class="pointer icon-cts-route-delivery-pickup">
                    {{ data.weighingReceipt.proof ? data.weighingReceipt.proof : (filename ? filename :
                    'Rer.rerCollectionByWeight.weighingReceipt.uploadProof' | translate) }}
                </span>
                <span *ngIf="showMandatoryImageFormat"
                    class="icon-cts-information pointer font-size-16 color-red-primary align-middle">
                    {{ 'Rer.rerCollectionByWeight.weighingReceipt.acceptedImageTypes' | translate }}
                </span>
            </div>
        </div>
        <mat-table #table [dataSource]="dataSource" matSort class="readings-list pr-0">
            <ng-container matColumnDef="rer_client_address_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{
                    'Rer.rerCollectionByWeight.weighingReceipt.rer_client_address_name' |
                    translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> #{{ element.id + '. ' + element.rer_client_address_name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="computed_quantity">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{
                    'Rer.rerCollectionByWeight.weighingReceipt.registered_quantity_uat' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.computed_quantity.toFixed(2) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="calculated_difference">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{
                    'Rer.rerCollectionByWeight.weighingReceipt.difference_quantity_uat'
                    | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ (element.operated_quantity -
                    element.computed_quantity).toFixed(2) }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="allocated_difference">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{
                    'Rer.rerCollectionByWeight.weighingReceipt.operated_quantity_uat' |
                    translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-form-field class="w-100 py-2">
                        <input type="number" matInput autocomplete="off" min="0" (change)="changeOperatedQuantity()"
                            [(ngModel)]="element.operated_quantity"
                            [disabled]="element.rer_collection_by_weight_automatic_report_id">
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="allocated_on">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{
                    'Rer.rerCollectionByWeight.weighingReceipt.allocated_on' |
                    translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.rer_collection_by_weight_automatic_report_id ?
                    ('Rer.rerCollectionByWeight.weighingReceipt.automatic_report' |
                    translate) + ' #' + element.rer_collection_by_weight_automatic_report_id : '' }} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns" class="pt-2 pb-2 mb-1">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3 pointer">
            </mat-row>
        </mat-table>
        <div class="row mt-4 total">
            <div class="col-3">
                <p class="ml-4">{{ 'Rer.rerCollectionByWeight.weighingReceipt.total' | translate }}</p>
            </div>
            <div class="col-2 ml-4">
                <p class="ml-2">{{ this.total.toFixed(2) }}</p>
            </div>
            <div class="col-2 ml-3">
                <p class="ml-2">{{ this.totalDifference.toFixed(2) }}</p>
            </div>
            <div class="col-2">
                <p>{{ this.allocatedDifferences.toFixed(2) }}</p>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="mt-4 p-2">
        <div class="buttons d-flex align-items-center justify-content-between pt-2">
            <div class="button-component bgcolor-white" (click)="onCancel()">
                <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                    <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
                </div>
            </div>
            <div class="button-component ml-4 utility-green-bg-color" (click)="onWeighingReceiptSave()">
                <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4 inner-green">
                    <span class="color-white ml-0">{{ 'save' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
    <p *ngIf="data.weighingReceipt.operated_at" class="text-right font-size-14">{{
        'Rer.rerCollectionByWeight.weighingReceipt.operatedAt' | translate }} {{ data.weighingReceipt.operated_at |
        offset: false | date: dateFormat + ' HH:mm:ss' | translate }} {{ 'Rer.rerCollectionByWeight.weighingReceipt.by'
        | translate }} {{ data.weighingReceipt.updated_by }}</p>
</div>
<div mat-dialog-content class="content pl-4 pr-4" *ngIf="showSpinner">
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)" *ngIf="showSpinner"
        class="d-flex justify-content-center align-items-center position-absolute">
        <mat-spinner></mat-spinner>
    </div>
</div>