<div class="search-content d-flex align-items-center mt-2">
    <div class="ng-select-wrapper">
        <ng-select [items]="employeeType | keyvalue" placeholder="{{ 'Rer.selectType' | translate }}" appendTo="body"
            [multiple]="true" [clearable]="true" [closeOnSelect]="false" [(ngModel)]="selectedTypeList" bindValue="key"
            bindLabel="value">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <ng-container *ngFor="let item of items; let i=index">
                    <div class="ng-value" *ngIf="i < 2 && item">
                        <span class="ng-value-label">{{ item ? ('Rer.rerEmployee.list.type.' +
                            item.value | translate).slice(0,6) : '-' }}</span>
                        <span class=" ml-1 ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                    </div>
                </ng-container>
                <div class="ng-value" *ngIf="items.length > 2">
                    <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                </div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                    <span class="ml-2">{{ 'Rer.rerEmployee.list.type.' + item.value | translate }}
                    </span>
                </mat-checkbox>
            </ng-template>
        </ng-select>
    </div>
    <div class="button-component utility-green-bg-color ml-2 flex-shrink-0" (click)="onLoadList()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0">{{ 'Rer.loadList' | translate }}</span>
        </div>
    </div>
    <div class="datatTableFilter ml-3 mr-1 p-2 d-flex align-items-center w-100 flex-grow-1">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerEmployee.list.quickSearch' | translate }}">
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerEmployee.list.dataTable.type' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ 'Rer.rerEmployee.list.type.' +
            employeeType[element.type] | translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerEmployee.list.dataTable.name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="editEmployee(element.id)" title="{{ 'edit' | translate }}"
            class="pointer">{{ element.name}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerEmployee.list.dataTable.phone' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.phone }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="tag1">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerEmployee.list.dataTable.tagX' | translate:{number: 1} }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.tag1 }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tag2">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerEmployee.list.dataTable.tagX' | translate:{number: 2} }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.tag2 }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="tag3">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerEmployee.list.dataTable.tagX' | translate:{number: 3} }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.tag3 }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
            <i (click)="editEmployee(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
            <i (click)="deleteEmployee(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute"
    style="height: 100%; top: 0; width: calc(100% - 100px);">
    <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
</div>