import { RerBinSetupDocumentInterface } from "../_interface/rerbinsetupdocument.interface";

export class RerBinSetupDocument implements RerBinSetupDocumentInterface {
    static readonly STATUS_NOT_SIGNED = 0;
    static readonly STATUS_SIGNED = 1;

    constructor(
        public id = null,
        public number = null,
        public rer_client_name = null,
        public rer_client_id = null,
        public signed_at = null,
        public date = null,
        public user_id = null,
        public rer_bins = [],
        public count_bins = null,
        public address = null,
        public city = null,
        public division =null,
        public street = null,
        public street_number = null,
        public bin_type = null,
        public waste_type = null,
        public client_address = null,
        public responsible_person = null,
        public placement_address = null,
        public username = null,
        public deleted_at = null,
        public deleted_by = null,
    ) { }
}
