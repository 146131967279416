import { Component, OnInit, ViewEncapsulation, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RerService } from 'app/modules/rer/_service/rer.service';
import { RerArea } from 'app/modules/rer/_model/rerarea.model';
import { Observable } from 'rxjs';
import { AuthService } from 'app/service/auth.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { environment } from 'environments/environment';
import { GoogleMapTileService } from 'app/modules/service/shared/googlemaptile.service';
import { GeofenceService } from 'app/modules/service/map/geofence.service';
import { GeoCoords } from 'app/modules/model/map/geocoords.model';
import { RerClientAddressGeozone } from 'app/modules/rer/_model/rerclientaddressgeozone.model';

const DEFAULT_RADIUS = 2000;
const LATLNG_INVALID = 0;
const LATLNG_UNCHANGED = 1;
const LATLNG_VALID = 2;
@Component({
    selector: 'app-add-edit-address',
    templateUrl: './add-edit-address.component.html',
    styleUrls: ['./add-edit-address.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditAddressComponent implements OnInit, AfterViewInit {

    public showMandatory: boolean;
    public isGeozoneScreen: boolean = false;
    public areaCompleteList: RerArea[] = [];
    public hasGeozoneRight: boolean = false;
    private areaList$: Observable<RerArea[]>;
    private addressGeozone: RerClientAddressGeozone;

    private availableMapTypes: any[];
    private hasHasGoogleMapsRight = false;
    @ViewChild('gmap') gmapElement: any;
    private drawingManager: google.maps.drawing.DrawingManager;
    private polyOptions = { strokeWeight: 0, fillOpacity: 0.45, editable: true, draggable: true };
    public latLngPosition: string;
    public latLngPositionStatus: number;
    public map: google.maps.Map;
    private mapProp = {
        center: this.authService.getLatLngFromString(this.userPreferencesService.userPreferences.defaultMapCenter),
        mapTypeControl: true,
        mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, mapTypeIds: [], position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoom: 5,
        controlSize: 32,
        fullscreenControl: true,
        fullscreenControlOptions: { position: google.maps.ControlPosition.TOP_RIGHT },
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
    };

    constructor(
        public dialogRef: MatDialogRef<AddEditAddressComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: AuthService,
        private userPreferencesService: UserPreferencesService,
        private rerService: RerService,
        private googleMapTileService: GoogleMapTileService,
        private geofenceService: GeofenceService
    ) { }

    ngOnInit() {
        this.hasHasGoogleMapsRight = true;
        this.addressGeozone = this.data.address.address_geozone ? Object.assign({}, this.data.address.address_geozone) : new RerClientAddressGeozone();
        if (this.data.address.address_geozone && this.data.address.address_geozone.coords) {
            this.addressGeozone.coords = JSON.parse(this.data.address.address_geozone.coords).map(
                (coords: GeoCoords) => new GeoCoords(+coords.lng, +coords.lat)
            );
        }
        if (this.data.address.address_geozone && this.data.address.address_geozone.precalc_values) {
            this.addressGeozone.precalc_values = JSON.parse(this.data.address.address_geozone.precalc_values);
        }

        if (this.hasHasGoogleMapsRight && environment.isGoogleMapsAvailable) {
            this.availableMapTypes = [
                google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN,
                google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID,
                'openStreetsMap', 'mapBoxCustom', 'mapBoxHuGo'
            ];
            this.mapProp['streetViewControlOptions'] = {
                icon: '/assets/project/' + environment.projectName + '/images/favicon.png', position: google.maps.ControlPosition.RIGHT_TOP
            };
        } else {
            this.availableMapTypes = ['openStreetsMap', 'mapBoxHuGo'];
        }

        this.mapProp.mapTypeControlOptions.mapTypeIds = this.availableMapTypes;
        this.mapProp['streetViewControl'] = environment.isGoogleMapsAvailable && this.hasHasGoogleMapsRight ? true : false;

        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_ADDRESS_GEOZONE)) {
            this.hasGeozoneRight = true;
        }
        this.showMandatory = false;
        this.areaList$ = this.rerService.areaList$;

        this.setSubscriptions();
    }

    ngAfterViewInit(): void {
        this.map = new google.maps.Map(this.gmapElement.nativeElement, this.mapProp);
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [google.maps.drawing.OverlayType.CIRCLE, google.maps.drawing.OverlayType.POLYGON]
            },
            markerOptions: { draggable: true },
            polylineOptions: { editable: true, draggable: true },
            rectangleOptions: this.polyOptions,
            circleOptions: this.polyOptions,
            polygonOptions: this.polyOptions,
            map: this.map
        });

        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: google.maps.drawing.OverlayCompleteEvent) => {
            this.deleteShape();
            this.resetGeofence();
            this.addressGeozone.shape = event.overlay;
            if (event.type === google.maps.drawing.OverlayType.CIRCLE) {
                this.addressGeozone.type = 'circle';
                this.addressGeozone.radius = this.addressGeozone.shape.radius;
                this.addressGeozone.shape.addListener('radius_changed', () => this.onCreatedShapeRadiusChange(this.addressGeozone.shape));
            } else {
                this.addressGeozone.type = 'path';
            }
        });

        // Create Google TileLayers
        const openStreetsMap = this.googleMapTileService.getOSMTileLayer();
        this.map.mapTypes.set('openStreetsMap', openStreetsMap);
        const mapBoxCustom = this.googleMapTileService.getMapBoxCustomTileLayer();
        this.map.mapTypes.set('mapBoxCustom', mapBoxCustom);

        this.map.setMapTypeId(this.availableMapTypes.indexOf(this.userPreferencesService.userPreferences.map.mapTypeId) >= 0
            ? this.userPreferencesService.userPreferences.map.mapTypeId : 'openStreetsMap');
    }

    private deleteShape(): void {
        if (this.addressGeozone.shape) {
            this.addressGeozone.shape.setVisible(false);
            this.addressGeozone.shape.setDraggable(false);
            this.addressGeozone.shape.setEditable(false);
        }
    }

    private drawShape() {
        if (this.addressGeozone.shape) {
            this.addressGeozone.shape.setVisible(true);
            this.addressGeozone.shape.setDraggable(true);
            this.addressGeozone.shape.setEditable(true);
        } else {
            switch (this.addressGeozone.type) {
                case 'circle':
                    this.createShapeCircle();
                    break;
                case 'path':
                    this.createShapePlygon();
                    if (!this.addressGeozone.center_latitude || !this.addressGeozone.center_longitude) {
                        const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.addressGeozone.shape);
                        this.addressGeozone.center_latitude = polyCenter.lat();
                        this.addressGeozone.center_longitude = polyCenter.lng();
                    }
                    break;
            }
        }
    }

    private resetGeofence(): void {
        this.addressGeozone.center_latitude = null;
        this.addressGeozone.center_longitude = null;
        this.latLngPosition = '';
        this.latLngPositionStatus = LATLNG_UNCHANGED;
        this.addressGeozone.radius = null;
        this.addressGeozone.coords = null;
    }

    private createShapeCircle(): void {
        this.addressGeozone.shape = this.geofenceService.createGoogleMapsCircle(this.map, this.addressGeozone, this.polyOptions, true);
        this.addressGeozone.shape.addListener('radius_changed', () => this.onCreatedShapeRadiusChange(this.addressGeozone.shape));
        this.fitToBounds();
    }

    private createShapePlygon(): void {
        this.addressGeozone.shape = this.geofenceService.createGoogleMapsPolygon(this.map, this.addressGeozone, true, true);
        this.fitToBounds();
    }

    private fitToBounds(): void {

        const bounds = new google.maps.LatLngBounds();
        if (!this.addressGeozone.type) {
            bounds.extend(this.authService.getLatLngFromString(this.userPreferencesService.userPreferences.defaultMapCenter));
        }
        if (this.addressGeozone.type === 'circle') {
            bounds.extend(new google.maps.LatLng(Number(this.addressGeozone.center_latitude), Number(this.addressGeozone.center_longitude)));
            this.map.fitBounds(bounds);
            this.map.setZoom(10);
        } else {
            if (this.addressGeozone.coords) {
                this.addressGeozone.coords.forEach(
                    (item: GeoCoords) => bounds.extend(new google.maps.LatLng(Number(item['lat']), Number(item['lng'])))
                );
            }
            this.map.fitBounds(bounds);
        }
    }

    private onCreatedShapeRadiusChange(shape: any): void {
        this.addressGeozone.radius = shape.getRadius();
    }

    public updateSelection(action: RerArea) {
        this.data.address.rer_area_id = action.id;
    }

    public onGeozoneSave() {
        if (!this.data.address.address_geozone) {
            this.data.address.address_geozone = new RerClientAddressGeozone;
        }
        this.isGeozoneScreen = false;
        if (this.addressGeozone.type == 'path') {
            const coords = this.addressGeozone.shape.getPath().getArray().map((latLng: any) => new GeoCoords(latLng.lng(), latLng.lat()));
            this.data.address.address_geozone.coords = coords;
            this.data.address.address_geozone.coords = JSON.stringify(this.data.address.address_geozone.coords);

            const polyCenter = this.geofenceService.getPolygonApproximateCenter(this.addressGeozone.shape);
            this.data.address.address_geozone.center_latitude = polyCenter.lat();
            this.data.address.address_geozone.center_longitude = polyCenter.lng();
            this.data.address.address_geozone.radius = null;
        } else {
            this.data.address.address_geozone.center_latitude = this.addressGeozone.shape.center.lat();
            this.data.address.address_geozone.center_longitude = this.addressGeozone.shape.center.lng();
            this.data.address.address_geozone.radius = this.addressGeozone.radius;
            this.data.address.address_geozone.coords = null;
            this.data.address.address_geozone.precalc_values = null;
        }
        this.data.address.address_geozone.type = this.addressGeozone.type;
    }

    public onCancel() {
        this.isGeozoneScreen = false;
        this.deleteShape();
        this.resetGeofence();
        if(this.data.address.address_geozone) {
            this.addressGeozone = Object.assign({}, this.data.address.address_geozone);
            if (this.data.address.address_geozone.coords) {
                this.addressGeozone.coords = JSON.parse(this.data.address.address_geozone.coords).map(
                    (coords: GeoCoords) => new GeoCoords(+coords.lng, +coords.lat)
                );
            }
            if (this.data.address.address_geozone.precalc_values) {
                this.addressGeozone.precalc_values = JSON.parse(this.data.address.address_geozone.precalc_values);
            }
            this.drawShape();
        }
    }

    private setSubscriptions(): void {
        this.areaList$.subscribe(
            (area: RerArea[]) => {
                this.areaCompleteList = area;
            }
        )
    };

    public switchGeozone() {
        this.isGeozoneScreen = !this.isGeozoneScreen;
        this.drawShape();
    }

    public onAdd(): void {
        this.showMandatory = (this.data.address.city && this.data.address.code) ? false : true;
        if (!this.showMandatory) {
            this.dialogRef.close(this.data.address);
        }
    }
}
