<h4 mat-dialog-title class="p-2 header border-bottom-grey-1 font-size-24 text-uppercase text-center font-weight-bold">
    {{ 'Rer.rerSettings.emailTemplate.editEmailTemplate' | translate }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <mat-form-field class="w-100 mt-4">
        <input matInput autocomplete="off" type="details" [(ngModel)]="data.emailTemplate.subject"
            placeholder="{{ 'Rer.rerSettings.emailTemplate.subject' | translate }}" name="subject">
    </mat-form-field>
    <div class="mt-4">
        <angular-editor [config]="angularEditorConfig"  [(ngModel)]="data.emailTemplate.body" ></angular-editor>
    </div>
    <mat-form-field class="w-100 mt-4">
        <textarea autocomplete="off"
            matInput readonly
            placeholder="{{ 'Rer.rerSettings.emailTemplate.variables' | translate }}"
            [(ngModel)]="data.emailTemplate.variables">
        </textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions class="mt-4">
    <div class="buttons w-100 d-flex justify-content-between pb-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color" (click)="onSave()">
            <div class="inner user-no-select inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>