import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators'

import { AuthService } from '../service/auth.service';

import { CtsErrorHandler } from './ctserrorhandler';
import { EnvService } from 'app/env.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private injector: Injector,
        public errorHandler: CtsErrorHandler,
        private authService: AuthService,
        private env: EnvService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req && req.url.includes(this.env.apiDomain)) {
            const token = this.injector.get(AuthService).token;
            const clientDataServer = this.authService.clientDataServer ? this.authService.clientDataServer : 'ds.cargotrack.ro';
            const headers = new HttpHeaders().set('Accept', 'application/json')
                .append('X-Requested-With', 'XMLHttpRequest')
                .append('Client-Data-Server', clientDataServer)
                .append('Authorization', 'Bearer ' + token);

            const copiedReq = req.clone({ headers: headers });
            return next.handle(copiedReq)
                .pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                    if (err) {
                        this.errorHandler.handleError(err);
                    }
                }));
        }

        if (!req.url.includes('hooks.slack.com')) {
            return next.handle(req)
                .pipe(tap((event: HttpEvent<any>) => { }, (err: any) => {
                    if (err) {
                        this.errorHandler.handleError(err);
                    }
                }));
        } else {
            return next.handle(req);
        }
    }
}
