import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { EndpointsService } from '../../../service/endpoints.service';
import * as crypto from 'crypto-js';

@Injectable()
export class GoogleapisService {
    private lang = localStorage.getItem('currentLang');

    constructor(private httpClient: HttpClient, private endpointsService: EndpointsService) { }

    public nominatimGeocode(address: string): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('geocode.searchGeocode', [address]));
    }

    public googleGeocode(address: string): Observable<any> {
        return this.httpClient.get(this.endpointsService.get('geocode.searchGoogleGeocode', [address]));
    }

    public reverseGeocode(lat: string, lng: string): Observable<any> {
        if (this.lang != null) {
            const lang_code = this.lang.split('_');
            return this.httpClient.get(this.endpointsService.get('geocode.reverseGeocode', [lat, lng])
             + '?language=' + lang_code[0]);
        } else {
            return this.httpClient.get(this.endpointsService.get('geocode.reverseGeocode', [lat, lng]));
        }
    }

    public reverseGeocodeNominatim(lat: string, lng: string): Observable<any> {
        var message = "format=json&lat=" + lat + "&lon=" + lng + "&zoom=18";
        const hash = crypto.HmacSHA512(message, message).toString();
        var key = hash.substr(0, 32); // 32 chars required
        var iv = hash.substr(-32); // 32 chars required
        var hexKey = crypto.enc.Hex.parse(key);
        var hexIv = crypto.enc.Hex.parse(iv);
        var encrypted = crypto.AES.encrypt(message, hexKey, {
            iv: hexIv,
            padding: crypto.pad.ZeroPadding,
        });
        const output = key + iv + encrypted.toString();
        return this.httpClient.get("https://nominatim2.trakosoft.ro/reverse.php?format=json&lat="
            + lat + "&lon=" + lng +  "&zoom=18" + "&hash=" + btoa(output));
    }

    public formatNominatimAddress(addressObject: any) {
        let formattedAddress = '';
        if (addressObject.address) {
            const address = addressObject.address;
            if (address.road) {
                formattedAddress += address.road;
                if (address.house_number) {
                    formattedAddress += address.house_number;
                }
                formattedAddress += ', '
            }
            if (address.village) {
                formattedAddress += address.village  + ', '
            }
            if (address.town) {
                formattedAddress += address.town  + ', '
            }
            if (address.city) {
                formattedAddress += address.city  + ', '
            }
            if (address.county) {
                formattedAddress += address.county  + ', '
            }
            if (address.postcode) {
                formattedAddress += address.postcode  + ', '
            }
            if (address.country) {
                formattedAddress += address.country  + ', '
            }
        } else {
            formattedAddress = addressObject.display_name;
        }

        return formattedAddress;
    }

    public googleStaticMap(routing: any): Observable<any> {
        return this.httpClient.post(this.endpointsService.get('geocode.staticGeoMap'), routing);
    }
}
