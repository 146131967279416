import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../../service/auth.service';

@Injectable()
export class AutologinGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {

        const urlExploded = state.url.split("/");
        var hiddenHeader = urlExploded[3] == 'hiddenHeader' ? true : false; 
        this.authService.autologin(urlExploded[2], hiddenHeader).subscribe(
            (response) => {
                if (response) {
                    this.router.navigate(['/']);
                } else {
                    this.authService.logout();
                }
            },
            (error) => this.authService.logout()

        );

        return false;
    }
}
