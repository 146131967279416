<h3 mat-dialog-title class="p-2">
    <div class="col p-0 pr-4">
        <img class="img-fluid" src="{{ logoSrc }}">
    </div>
    <span *ngIf="!isEmailModalShown">
        {{ 'forgotPassword' | translate }}
    </span>
    <button type="button" class="close pull-right pointer" aria-label="Close"
        (click)="onCancel()">
        <span aria-hidden="true">×</span>
    </button>
</h3>
<div mat-dialog-content>
    <div *ngIf="!isEmailModalShown">
        <form (ngSubmit)="sentResetPasswordRequest()" #resetpsswd="ngForm" autocomplete="off">
            <div class="modal-body">
                <div class="container-fluid" >
                    <div>
                        <span class="sign-in-more">{{ "SignIn.description" | translate }}</span>
                    </div>
                    <div class="md-form form-sm mt-4">
                        <mat-form-field class="w-100">
                            <input matInput name="loginData" #loginData="ngModel"
                                ngModel
                                id="loginData" required
                                placeholder="{{ 'Email / Username' | translate }}">
                        </mat-form-field>

                    </div>
                    <div class="height-25">
                        <p class="text-center p-0 m-0"><span *ngIf="wrongData" class="text-danger">{{ errorMessage | translate}}</span></p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="!loginData.valid" type="submit" class="btn button utility-green-bg-color">
                    {{ 'SignIn.resetPassword' | translate}}
                </button>
            </div>
        </form>
        </div>

        <div class="modal-content" *ngIf="isEmailModalShown">
            <div class="modal-body">
                <span class="sign-in-more">{{ "SignIn.emailSent" | translate }}</span>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onSubmit();" class="btn button utility-green-bg-color">
                    {{ 'close' | translate}}</button>
            </div>
        </div>
</div>
