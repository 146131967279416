import { RerWasteTypeInterface } from '../_interface/rerwastetype.interface';

export class RerWasteType implements RerWasteTypeInterface {

    public constructor(
        public id = null,
        public name = null,
        public code = null,
    ) { }
}
