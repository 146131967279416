import { STObjectInterface } from '../../map/interface/stobject.interface';

export class STObject implements STObjectInterface {

    constructor(
        public period = { begin: null, end: null },
        public address = { begin: null, end: null },
        public duration = { stopped: 0, idling: 0, moving: 0 },
        public distance = 0,
        public positions = [],
        public hiddenStops = 0,
        public stopNumber = null,
        public mapMarker = null,
        public mapPath = null,
        public rawPositions = null,
        public fuelConsumptionFuelLevelBased = null,
        public fuelConsumptionSenzorValueBased = null,
        public distance_can = null,
        public distance_gps = null,
    ) { }
}
