<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ data.headerMessage | translate }}
</h4>
<div mat-dialog-content class="p-4 content m-0">
    <p>{{ data.contentMessage | translate }}</p>
</div>
<div mat-dialog-actions class="mt-4 mb-0 p-3">
    <div *ngIf="!data.confirmButton"
        class="w-100 buttons d-flex align-items-center justify-content-between pt-3">
        <div
            class="button-component ml-2 utility-green-bg-color"
            (click)="dialogRef.close(false)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'no' | translate }}</span>
            </div>
        </div>
        <div
            class="button-component ml-2 utility-green-bg-color"
            (click)="dialogRef.close(true)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'yes' | translate }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="data.confirmButton"
        class="w-100 buttons d-flex align-items-center justify-content-center pt-3">
        <div
            class="button-component ml-2"
            [ngClass]="data.confirmButton.bgColor ? data.confirmButton.bgColor : 'utility-green-bg-color'"
            (click)="dialogRef.close(true)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4"
                [ngClass]="data.confirmButton.color ? data.confirmButton.color : 'inner-green'">
                <span class="color-white ml-0">{{ data.confirmButton.text | translate }}</span>
            </div>
        </div>
    </div>
</div>

