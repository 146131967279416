import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../../../service/auth.service';

@Injectable()
export class UnallocatedRerBinGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const hasRight = this.authService.userModulesOptions['rer']
            .find((item: string) => item === AuthService.USER_RIGHT_RER_UNALLOCATED_RER_BINS);

        if (hasRight) {
            return true;
        }

        this.authService.logout();

        return false;
    }
}
