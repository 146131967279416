import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../_service/rer.service';

import { RerEmployee } from '../_model/reremployee.model';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-rer-employee',
    templateUrl: './rer-employee.component.html',
    styleUrls: ['./rer-employee.component.scss']
})
export class RerEmployeeComponent {

    public employeeTabs: RerEmployee[] = [];
    public selectedTabIndex = 0;
    public showSpinner: boolean;

    constructor(private rerService: RerService, 
        public actionDialog: MatDialog,
        private translationService: TranslateService) { }

    public addNewEmployee(): void {
        const newEmployee = this.employeeTabs.find(et => et.id === null);
        if (!newEmployee) {
            this.employeeTabs.unshift(new RerEmployee());
        }
        setTimeout(() => this.selectedTabIndex = 1);
    }

    public onCloseTab(tabIndex: number): void {
        this.employeeTabs.splice(tabIndex, 1);
        if (this.selectedTabIndex === tabIndex - 1) {
            this.selectedTabIndex = 0;
        }
    }

    public editEmployee(id: number): void {
        let moveTo: number;
        const employee = this.rerService.getRerEmployeeById(id);
        const indexTab = this.employeeTabs.findIndex((et: RerEmployee) => et.id === employee.id);
        if (indexTab === -1) {
            this.employeeTabs.push(employee);
            moveTo = this.employeeTabs.length;
        } else {
            moveTo = indexTab + 1;
        }
        setTimeout(() => { this.selectedTabIndex = moveTo; });
    }

    public deleteEmployee(id: number): void {
        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.rerEmployee.list.dataTable.confirmDeleteAction')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const employee = this.rerService.getRerEmployeeById(id);
                    this.rerService.deleteEmployee(employee.id).subscribe(
                        () => {
                            const indexTab = this.employeeTabs.findIndex((emp: RerEmployee) => emp.id === employee.id);
                            if (indexTab !== -1) {
                                this.employeeTabs.splice(indexTab, 1);
                            }
                            this.showSpinner = false;
                        },
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                } else {
                    this.showSpinner = false;
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            }
        );
    }

    public closeAllTabs(): void {
        this.employeeTabs = [];
    }
}
