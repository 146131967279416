import { RerBinOwnership } from './../../_model/rerbinownership';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';

import { AddEditOwnershipComponent } from './add-edit-ownership/add-edit-ownership.component';
import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-bin-ownership',
    templateUrl: './bin-ownership.component.html',
    styleUrls: ['./bin-ownership.component.scss']
})

export class BinOwnershipComponent implements OnInit {

    public binOwnershipList$: Observable<RerBinOwnership[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean = false;

    constructor(private rerService: RerService,
        public dialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.displayedColumns = ['name', 'value', 'actions'];
        this.binOwnershipList$ = this.rerService.binOwnershipList$;

        this.binOwnershipList$.subscribe(
            (binOwnerships: RerBinOwnership[]) => {
                this.dataSource = new MatTableDataSource(binOwnerships);
            }
        );
    }

    addEditRerBinOwnership(binOwnershipId) {
        let binOwnership = this.rerService.getRerBinOwnershipById(binOwnershipId);
        if (!binOwnership) {
            binOwnership = new RerBinOwnership();
            binOwnership.id = binOwnershipId;
        }

        const dialogRef = this.dialog.open(
            AddEditOwnershipComponent,
            {
                width: '500px',
                data: { rerBinOwnership: binOwnership }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.rerService.saveRerBinOwnership(response).subscribe(
                        () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerSettings.savedSuccefuly")),
                        () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.saveFailed"))
                    )
                }
            }
        );
    }

    deleteRerBinOwnership(id) {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService
                        .instant('Rer.rerSettings.confirmDeleteActionBO')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const bOwn = this.rerService.getRerBinOwnershipById(id);
                    // console.log(bOwn);
                    this.rerService.deleteRerBinOwnership(bOwn.id).subscribe(
                        () => { this.showSpinner = false; },
                        (error: HttpErrorResponse) => {
                            let errorMessage = 'errorNotification';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rerSettings.errorRerBinOwnershipInUse';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage), '600px');
                        }
                    );
                }
            }
        );
    }
}
