import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RerPlanning } from 'app/modules/rer/_model/rerplanning.model';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import * as moment from 'moment';
import { RerService } from '../../../_service/rer.service';
import { faFastForward, faFastBackward } from '@fortawesome/free-solid-svg-icons';
import { IAlbum, Lightbox, LightboxEvent, LIGHTBOX_EVENT } from 'ngx-lightbox';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-image-gallery-dialog',
    templateUrl: './image-gallery-dialog.component.html',
    styleUrls: ['./image-gallery-dialog.component.scss']
})
export class ImageGalleryDialogComponent implements OnInit {

    public showMandatory: boolean;
    public date = moment().format('YYYY-MM-DD HH:mm:ss');
    public planning: RerPlanning;
    private subscription: Subscription;
    public showSpinner = true;
    public noVideoOrError = false;
    public dateFormat;
    public faFastForward = faFastForward;
    public faFastBackward = faFastBackward;
    public imageObject: IAlbum[] = [];
    public firstImage;
    public backSkip = 1;
    public nextSkip = 1;
    public openedIndex;
    public isOpenedLightBox = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private lightbox: Lightbox,
        private lightboxEvent: LightboxEvent,
        public dialogRef: MatDialogRef<ImageGalleryDialogComponent>,
        private rerService: RerService,
        private userPreferencesService: UserPreferencesService
    ) {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.planning = this.rerService.getPlanningById(this.data.planningId);
    }

    ngOnInit() {
        this.rerService.getRerRfidReadingImage(this.planning.device_id, this.rerService.setDateOffset(this.data.reading.datetime_read), this.data.cameraId).subscribe(result => {
            const reader = new FileReader();
            reader.readAsDataURL(result.body);
            reader.onload = _event => {
                this.showSpinner = false;
                let url = reader.result;
                this.firstImage = {
                    src: url,
                    thumb: url,
                    order: 0,
                    first: true
                };
                this.imageObject.push(this.firstImage);
                this.getNextPrev(0);
                this.getNextPrev(1);
            };
        }, error => {
            this.noVideoOrError = true;
            this.showSpinner = false;
        });
    }

    openImage(event: any) {
        if (event == 0) {
            this.getNextPrev(0);
        }
        if (event == 2) {
            this.getNextPrev(1);
        }
    }

    open(): void {
        let index = this.imageObject.findIndex(x => x['first'] == true);
        if(this.isOpenedLightBox) {
            index = this.openedIndex ? this.openedIndex : index;
            this.subscription.unsubscribe();
        }
        this.lightbox.open(this.imageObject, index, { showDownloadButton: true, showZoom: true });
        this.subscription = this.lightboxEvent.lightboxEvent$.subscribe(event => this.onReceivedEvent(event));
        this.isOpenedLightBox = true;
    }

    private onReceivedEvent(event: any): void {
        if (event.id === LIGHTBOX_EVENT.CLOSE) {
          this.subscription.unsubscribe();
          this.isOpenedLightBox = false;
          this.openedIndex = 0;
        }
    
        if (event.id === LIGHTBOX_EVENT.OPEN) {
        }
    
        if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
            if(event.data == 0) {
                this.getNextPrev(0);
            }
            if(event.data == this.imageObject.length - 1 ) {
                this.getNextPrev(1);
            }
            this.openedIndex = event.data;
        }
      }

    close(): void {
        this.lightbox.close();
    }

    public getNextPrev(next: number) {
        let skip = next ? this.nextSkip : this.backSkip;
        let direction = next;
        skip = Math.abs(skip);
        this.rerService.getNextPrevImage(this.planning.device_id, this.rerService.setDateOffset(this.data.reading.datetime_read), this.data.cameraId, skip, direction).subscribe(result => {
            const reader = new FileReader();
            reader.readAsDataURL(result.body);
            reader.onload = _event => {
                this.showSpinner = false;
                let url = reader.result;
                if (next) {
                    this.imageObject.push({
                        src: url.toString(),
                        thumb: url.toString()
                    });
                    this.nextSkip += 1;
                } else {
                    this.imageObject.unshift({
                        src: url.toString(),
                        thumb: url.toString()
                    });
                    this.backSkip += 1;
                }
                if(this.isOpenedLightBox) {
                    this.open();
                }
                let currentOrder = 1;
                this.imageObject.forEach(element => {
                    element['id'] = currentOrder;
                    currentOrder += 1;
                });
            };
        });
    }

    public getLocale(): String {
        return this.rerService.getLocale();
    }
}

