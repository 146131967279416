import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RerService } from '../_service/rer.service';
import { AuthService } from 'app/service/auth.service';

@Component({
  selector: 'app-rer-verbal-process',
  templateUrl: './rer-verbal-process.component.html',
  styleUrls: ['./rer-verbal-process.component.scss']
})
export class RerVerbalProcessComponent implements OnInit {
  public hasVerbalProcessListRight: boolean = false;
  public selectedTabIndex = 0;

  constructor(public actionDialog: MatDialog, private rerService: RerService) { }

  ngOnInit() {
    if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_VERBAL_PROCESS)) {
			  this.hasVerbalProcessListRight = true;
		}
  }
}
