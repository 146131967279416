export enum RerBinStatus {
    'available' = 0,
    'active' = 1,
    'suspended' = 2,
    'lost' = 3,
    'deleted' = 4
}
export enum RerBinAction {
    'deallocate' = 0,
    'activate' = 1,
    'suspend' = 2,
    'lost' = 3
}
