import { AddEditListItemComponent } from './list/add-edit-list-item/add-edit-list-item.component';
import { ListComponent } from './list/list.component';
import { RerPlanningComponent } from './rer-planning.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedModule } from '../../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LightboxModule } from 'ngx-lightbox';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { PlanningLogsComponent } from './planning-logs/planning-logs.component';
import { CopyFromDateComponent } from './list/copy-from-date/copy-from-date.component';
import { PlanningDetailsComponent } from './details/planning-details.component';
import { MapComponent } from './details/map/map.component';

import { GoogleMapDrawService } from 'app/modules/service/shared/googlemapdraw.service';
import { HistoryService } from 'app/modules/service/map/history.service';
import { GoogleapisService } from 'app/modules/service/shared/googleapis.service';
import { GoogleMapCustomControl } from 'app/modules/service/shared/googlemapcustomcontrol.service';
import { GoogleKmlService } from 'app/modules/service/map/googlekmlservice.service';
import { MarkerIconService } from 'app/modules/service/shared/markericon.service';
import { GoogleMapTileService } from 'app/modules/service/shared/googlemaptile.service';
import { PositionSocketService } from 'app/modules/service/map/position-socket.service';
import { ReadingsListComponent } from './details/readings-list/readings-list.component';
import { CameraStreamDialogComponent } from './details/camera-stream-dialog/camera-stream-dialog.component';
import { ImageGalleryDialogComponent } from './details/image-gallery-dialog/image-gallery-dialog.component';
import { RoutePlanningListComponent } from './details/route-planning-list/route-planning-list.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }, // common / shared translation
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        RerPlanningComponent,
        ListComponent,
        AddEditListItemComponent,
        CopyFromDateComponent,
        PlanningLogsComponent,
        PlanningDetailsComponent,
        MapComponent,
        ReadingsListComponent,
        CameraStreamDialogComponent,
        ImageGalleryDialogComponent,
        RoutePlanningListComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        LightboxModule,
        NgxMaterialTimepickerModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: [
        GoogleMapDrawService,
        HistoryService,
        GoogleapisService,
        GoogleMapCustomControl,
        GoogleKmlService,
        MarkerIconService,
        GoogleMapTileService,
        PositionSocketService
    ]
})
export class RerPlanningModule { }
