import { RoutePointInterface } from '../../map/interface/routepoint.interface';

export class RoutePoint implements RoutePointInterface {

    constructor(
        public address = null,
        public addressResponse = null,
        public latlng = null,
        public flagCode = null,
        public distance = null,
        public duration = null,
        public marker = null,
        public options = { avoidHighways: false, avoidTolls: false, avoidFerries: false, },
        public wayPoints = [],
        public routeData = null,
        public routeDisplay = null,
    ) { }

}
