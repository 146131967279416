<div class="bgcolor-white p-4 m-4" style="height: calc(100% - 50px);">
    <div class="import-csv-data h-100 overflow-auto" *ngIf="Object.entries(dataList).length === 0">
        <h4 class="font-family-montserrat">{{ 'Rer.dataImportRer.tagsImport.inportInfoText' | translate }}</h4>
        <input type="file" accept="file/csv" class="" (change)="onInportFileChanged($event)" [disabled]="showSpinner">
    </div>
    <div class="final-import-datatable w-100 h-100 overflow-auto">
        <app-tags-final-import *ngIf="dataList && Object.entries(dataList).length > 0" [dataList]="dataList" (cancelImportEventEmitter)="onCancelContinueImport()" (importDataEventEmitter)="showSpinner = $event"></app-tags-final-import>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>