<div class="bgcolor-white p-2 m-4 position-relative rerplannings-container">
    <mat-tab-group class="h-100" animationDuration="0ms" [selectedIndex]="selectedTabIndex" (selectedIndexChange)="selectedTabIndex = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-documents font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">{{ 'Rer.rerVerbalProcess.label' | translate }}</span>
            </ng-template>
            <app-list></app-list>
        </mat-tab>
    </mat-tab-group>
</div>