import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { RerService } from '../../_service/rer.service';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { RerRoute } from '../models/rer-route.model';
import { RerStreet } from '../../street-geozones/models/rer-street.model';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map, of, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-street-list',
  templateUrl: './street-list.component.html',
  styleUrls: ['./street-list.component.scss']
})
export class StreetListComponent implements OnInit {

  @Input() route: RerRoute;

  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public showSpinner: boolean = false;
  public currentFilterValue: string = null;
  public isStreetDropdownOpen = false;
  public streetDropdownContent: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchStreet', { static: false }) searchStreet: ElementRef;

  constructor(private actionDialog: MatDialog, public dialog: MatDialog, private translationService: TranslateService, private rerService: RerService) { }

  ngOnInit() {
    this.displayedColumns = ['name', 'actions'];
    this.dataSource = new MatTableDataSource(this.route.street);
  }

  ngAfterViewInit(): void {
      this.setDataTablePaginatorSort();

      fromEvent(this.searchStreet.nativeElement, 'input')
      .pipe(
          map((data: any) => data.target.value),
          tap(() => this.isStreetDropdownOpen = true),
          debounceTime(250),
          filter(query => query.length >= 2 || query.length === 0),
          distinctUntilChanged(),
          switchMap((value) => {
            if (value.length === 0) {
              return of([]);
            }

            return this.rerService.getRerStreets(value);
          })
      ).subscribe({
        next:(value) => {
          this.streetDropdownContent = value;
        }
      });
  }

  private setDataTablePaginatorSort(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    if (!filterValue) {
      return;
    }

    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  // public onAddStreet(data: RerStreet | undefined = undefined) {
  //   const street = data ? data : new RerStreet(undefined, this.route.id);
  //   this.setDataTablePaginatorSort();
    // const dialogRef = this.actionDialog.open( AddEditStreetComponent, { width: '90%', height: '90%', panelClass: 'custom-dialog-container', data: { street } });
    // dialogRef.afterClosed().subscribe(
    //   (street: RerStreet | null) => {
    //     if (street) {
    //       this.showSpinner = true;
    //       street.geoZone = null;
    //       this.rerService.saveRerStreetGeozoneStreet(street).subscribe({
    //         next:(response: RerStreet) => {
    //           if (!street.id) {
    //             this.city.street = [...this.city.street, response];
    //           } else {
    //             const index = this.city.street.findIndex((el: RerStreet) => el.id === response.id);
    //             if (index !== -1) {
    //               this.city.street[index] = {...response};
    //             }
    //           }
    //           this.dataSource = new MatTableDataSource(this.city.street);
    //           this.showSpinner = false;
    //         },
    //         error:() => {
    //           this.showSpinner = false;
    //           this.actionDialog.open(
    //             NotificationComponent,
    //             {
    //                 maxWidth: '800px', panelClass: 'custom-dialog-container',
    //                 data: { success: 0, headermessage: this.translationService.instant('error'), message: this.translationService.instant('generalError') }
    //             }
    //           );
    //         }
    //       });
    //     }
    //   }
    // );
  // }

  public onDropdownStreetClick(street: any) {
    if (this.checkIfExists(street.id)) {
      return;
    }

    this.route.street = this.route.street ? [...this.route.street, street] : [street];
    this.dataSource = new MatTableDataSource(this.route.street);
    this.applyFilter(this.currentFilterValue);
    this.closeStreetDropdown();
  }

  public onDeleteStreet(id: number) {
    this.route.street = this.route.street.filter((el: any) => el.id !== id);
    this.dataSource = new MatTableDataSource(this.route.street);
    this.applyFilter(this.currentFilterValue);
  }

  public closeStreetDropdown() {
    this.isStreetDropdownOpen = false;
    this.streetDropdownContent = [];
    this.searchStreet.nativeElement.value = '';
  }

  public onSaveRouteStreets() {
    const streets = this.route.street ? this.route.street.map((el: any) => el.id) : [];
    this.showSpinner = true;
    this.rerService.getRerRouteStreets(this.route.id, { streets }).subscribe({
      next:() => this.showSpinner = false,
      error:() => {
        this.showSpinner = false;
        this.actionDialog.open(
          NotificationComponent,
          {
              maxWidth: '800px', panelClass: 'custom-dialog-container',
              data: { success: 0, headermessage: this.translationService.instant('error'), message: this.translationService.instant('generalError') }
          }
        );
      }
    });
  }

  public checkIfExists(id: number):boolean {
    if (!this.route.street || this.route.street.length === 0) {
      return false;
    }

    const index = this.route.street.findIndex((el: any) => el.id === id);
    return  index === -1 ? false : true;
  }
}
