<div class="text-center py-5" >
    <div class="" >
        <div class="">
            <div class="">
                <img class="img-fluid modal-img" src="{{ logoSrc }}">
                <h3 class="pt-5 text-secondary">{{ 'ErrorPage.line1' | translate }}</h3>
                <p class="pt-3 text-muted"><small>{{ 'ErrorPage.line2' | translate }}</small></p>
                <p class="pt-1 text-muted"><small>{{ 'ErrorPage.line3' | translate }}</small></p>
                <p class="pt-3 mb-0 text-muted"><small>{{ 'ErrorPage.line4' | translate }}</small></p>
                <p class="pt-1 text-muted"><small>{{ 'ErrorPage.line5' | translate }}</small></p>
            </div>
        </div>
    </div>
</div>
