<div class="h-100 bgcolor-white pt-2 position-relative form-container settings-container">
  <nav mat-tab-nav-bar class="mat-elevation-z0 ml-2">
    <a *ngIf="hasRight('report-sent-automatically')" mat-tab-link (click)="toNavigate = 'report-sent-automatically'"
      [routerLink]="[{ outlets: { rercollectionbyweight: ['report-sent-automatically'] } }]"
      [active]="toNavigate === 'report-sent-automatically'">
      <mat-icon> <fa-icon [icon]="faEnvelopeOpenText" class="font-size-26"></fa-icon></mat-icon>
      <span class="ml-1 mt-1">{{ 'Rer.rerCollectionByWeight.tab.sentReports' | translate }}</span>
    </a>
    <a *ngIf="hasRight('general-reports')" mat-tab-link (click)="toNavigate = 'general-reports'"
      [routerLink]="[{ outlets: { rercollectionbyweight: ['general-reports'] } }]"
      [active]="toNavigate === 'general-reports'">
      <mat-icon> <fa-icon [icon]="faFileExcel" class="font-size-20"></fa-icon></mat-icon>
      <span class="ml-1 mt-1">{{ 'Rer.rerCollectionByWeight.tab.generalReports' | translate }}</span>
    </a>
    <a *ngIf="hasRight('unloading-areas')" mat-tab-link (click)="toNavigate = 'unloading-areas'"
      [routerLink]="[{ outlets: { rercollectionbyweight: ['unloading-areas'] } }]"
      [active]="toNavigate === 'unloading-areas'">
      <mat-icon> <fa-icon [icon]="faUpload" class="font-size-20"></fa-icon></mat-icon>
      <span class="ml-1 mt-1">{{ 'Rer.rerCollectionByWeight.tab.unloadingAreas' | translate }}</span>
    </a>
    <a *ngIf="hasRight('operation-of-weighing-receipts')" mat-tab-link
      (click)="toNavigate = 'operation-of-weighing-receipts'"
      [routerLink]="[{ outlets: { rercollectionbyweight: ['operation-of-weighing-receipts'] } }]"
      [active]="toNavigate === 'operation-of-weighing-receipts'">
      <mat-icon><fa-icon [icon]="faReceipt" class="font-size-20"></fa-icon></mat-icon>
      <span class="ml-1 mt-1">{{ 'Rer.rerCollectionByWeight.tab.weighingReceipts' | translate }}</span>
    </a>
  </nav>
  <router-outlet name="rercollectionbyweight"></router-outlet>
</div>