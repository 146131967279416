import { Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { faVideo, faImage } from '@fortawesome/free-solid-svg-icons';

import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerService } from 'app/modules/rer/_service/rer.service';

import { RerRfidTagReading } from 'app/modules/rer/_model/rerrfidtagreading.model';

import { CameraStreamDialogComponent } from '../camera-stream-dialog/camera-stream-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/service/auth.service';
import { RerPlanning } from 'app/modules/rer/_model/rerplanning.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { ImageGalleryDialogComponent } from '../image-gallery-dialog/image-gallery-dialog.component';

@Component({
    selector: 'app-readings-list',
    templateUrl: './readings-list.component.html',
    styleUrls: ['./readings-list.component.scss']
})
export class ReadingsListComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    private _subscriptions: Subscription[] = [];

    public readings: RerRfidTagReading[];
    public device_cameras: number[] = [];
    public categories = [];
    public dateFormat: string;
    public displayedColumns: string[];
    public dataSource: MatTableDataSource<RerRfidTagReading>;
    public hasCameraRight = false;
    public hasImageGalleryRight = false;
    private invoiceableReadings: RerRfidTagReading[] = [];
    private nonInvoiceableReadings: RerRfidTagReading[] = [];
    public faVideo = faVideo;
    public faEye = faEye;
    public faImage = faImage;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Output() centerPlanningReadingEventEmitter: EventEmitter<RerRfidTagReading> = new EventEmitter();
    @Output() changeDateEventEmitter: EventEmitter<{ date: string, planningId: number }> = new EventEmitter();
    @Input() lastTransmition: string;
    @Input() planningId: number;
    @Input() selectedId: number;
    @Input() deviceMaximumSpeed;
    @Input() planning: RerPlanning;

    constructor(
        private rerService: RerService,
        private userPreferencesService: UserPreferencesService,
        private dialog: MatDialog,
        public translateService: TranslateService
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.addSubscriptions();
        this.readings = [];
        this.device_cameras = this.rerService.getPlanningById(this.planningId).ipcameras;
        this.hasCameraRight = this.rerService.hasRight(AuthService.USER_RIGHT_RER_IP_CAMERA);
        this.hasImageGalleryRight = this.rerService.hasRight(AuthService.USER_RIGHT_RER_IMAGE_GALLERY);
        this.setDataTable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.selectedId) {
            this.applyFilter(this.selectedId.toString());
        } else if (this.dataSource) {
            this.applyFilter('');
        }
    }

    ngAfterViewInit() {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private addSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.planningsRfidReadingsChangedSubject.subscribe(
                result => {
                    let planning = result.find(x => x.id == this.planningId);
                    if (planning) {
                        this.readings = planning.readings;
                        this.filterReadings();
                        this.dataSource = new MatTableDataSource(this.readings);
                        this.setDataTablePaginatorSort();
                    }
                }
            ),
            this.rerService.rerFilterByInvoiceableSubject.subscribe(
                type => {
                    if (type != null) {
                        if (type) {
                            this.dataSource = new MatTableDataSource(this.invoiceableReadings);
                            this.setDataTablePaginatorSort();
                        } else {
                            this.dataSource = new MatTableDataSource(this.nonInvoiceableReadings);
                            this.setDataTablePaginatorSort();
                        }
                    } else {
                        this.dataSource = new MatTableDataSource(this.readings);
                        this.setDataTablePaginatorSort();
                    }
                }
            )
        );
    }

    streamIpCamera(reading: RerRfidTagReading, cameraId: number) {
        const dialogRef = this.dialog.open(CameraStreamDialogComponent, {
            maxWidth: '700px',
            panelClass: 'custom-dialog-container',
            data: {
                reading: reading,
                planningId: this.planningId,
                cameraId: cameraId
            }
        });
    }

    streamPhotos(reading: RerRfidTagReading, cameraId: number) {
        const dialogRef = this.dialog.open(ImageGalleryDialogComponent, {
            maxWidth: '700px',
            panelClass: 'custom-dialog-container',
            data: {
                reading: reading,
                planningId: this.planningId,
                cameraId: cameraId
            }
        });
    }

    private setDataTable(): void {
        this.displayedColumns = [
            'datetime_read',
            'client_name',
            'waste_type',
            'bin_type',
            'rfid',
            'status',
            'actions'
        ];

        this.dataSource = new MatTableDataSource(this.readings);
    }

    public getTranslationFor(label: string): String {

        return this.translateService.instant(label);
    }


    ngOnDestroy(): void {
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onCenterReading(planningReading: RerRfidTagReading): void {
        this.centerPlanningReadingEventEmitter.emit(planningReading);
    }

    private filterReadings() {
        let planning = this.rerService.getPlanningById(this.planningId);
        if (this.readings && this.readings.length > 0) {
            this.invoiceableReadings = this.readings.filter(x => x.status == 1
                && ((x.waste_type_id && x.waste_type_id == planning.rer_waste_type_id)
                || !planning.rer_waste_type_id)
                && x.vehicle_speed < parseFloat(this.deviceMaximumSpeed));
                this.nonInvoiceableReadings = this.readings.filter(x => !this.invoiceableReadings.includes(x));
        }
    }

    changePlanningDate(event: string) {
        this.changeDateEventEmitter.emit({ date: event, planningId: this.planning.id });
    }

    public compareDates(firstDate: string, secondDate: string, type: boolean) {
        if(type) {
            return moment(firstDate).isBefore(moment(secondDate));
        } else {
            return moment(firstDate).isSameOrAfter(moment(secondDate));
        }
    }

    public focusVehicleLocation() {
        this.rerService.rerMapVehicleLocationSubject.next(true);
    }

    isNumber(val): boolean { return typeof val === 'number'; }
}
