<div class="bgcolor-white p-2 m-2" style="height: calc(100% - 50px);">
    <div class="import-csv-data h-100 overflow-auto"
        *ngIf="Object.entries(dataWarningList).length === 0 && dataForImport.length === 0">
        <h4 class="font-family-montserrat">{{ 'Rer.dataImportRer.clientsImport.inportInfoText' | translate }}</h4>
        <ul class="font-family-open-sans">
            <li *ngFor="let col of fileColumns">{{ col }}</li>
        </ul>
        <input type="file" accept="file/csv" class="" (change)="onInportFileChanged($event)" [disabled]="showSpinner">
    </div>
    <div class="warnings-content h-100 overflow-auto w-100 p-2 pt-4" *ngIf="Object.keys(dataWarningList).length > 0">
        <ng-scrollbar #scrollable class="map-scrollbar pr-4" style="height: calc(100% - 60px);">
            <div class="bgcolor-orange p-2 d-flex mb-2 align-items-center">
                <i class="icon-tmss-table-header-warning font-size-28 color-red-primary mr-2"></i>
                <p class="m-0 font-weight-bold">{{ 'Rer.dataImportRer.warning' | translate:{ fileName: fileName, rows:
                    Object.keys(dataWarningList).length } }}</p>
            </div>
            <div *ngFor="let warning of Object.keys(dataWarningList); let i = index" class="mb-4">
                <h4 class="font-size-18 font-weight-bold font-family-montserrat">
                    {{ 'Rer.dataImportRer.line' | translate }} {{ warning + 1 }}
                </h4>
                <table class="table w-100 mb-2">
                    <thead>
                        <tr>
                            <th *ngFor="let col of fileColumns" class="font-size-14"> {{ col }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td *ngFor="let col of dataWarningList[warning].data" class="font-size-14"> {{ col }}</td>
                        </tr>
                    </tbody>
                </table>
                <div *ngFor="let info of dataWarningList[warning].warnings" [innerHtml]="info"
                    class="m-0 font-family-montserrat"></div>
            </div>
        </ng-scrollbar>
        <div class="d-flex align-items-end justify-content-between"
            style="height: 60px; border-top: 1px solid #eceef0;">
            <div class="button-component bgcolor-white" (click)="onCancelImport()">
                <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                    <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
                </div>
            </div>
            <div *ngIf="dataForImport.length > 0" class="button-component utility-green-bg-color"
                (click)="onContinueImport()">
                <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                    <span class="color-white ml-0">{{ 'Rer.dataImportRer.clientsImport.continue' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="final-import-datatable w-100 h-100 overflow-auto" *ngIf="showFinalImport">
        <p *ngIf="dataForImport && dataForImport.length > 10000" class="text-danger">{{
            'Rer.dataImportRer.clientsImport.importWarningText' | translate }}</p>
        <app-final-import [dataForImport]="dataForImport" (cancelImportEventEmitter)="onCancelImport()"
            (importDataEventEmitter)="showSpinner = $event"></app-final-import>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>