import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
    selector: 'app-add-edit-ownership',
    templateUrl: './add-edit-ownership.component.html',
    styleUrls: ['./add-edit-ownership.component.scss']
})
export class AddEditOwnershipComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AddEditOwnershipComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    ngOnInit() { }

    onSave() {
        this.dialogRef.close(this.data.rerBinOwnership);
    }
}
