import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RerService } from '../../_service/rer.service';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent, map, tap, debounceTime, filter, distinctUntilChanged, switchMap, of } from 'rxjs';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/service/auth.service';
import { NavigationExtras, Router } from '@angular/router';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
  selector: 'app-route-planning-search',
  templateUrl: './route-planning-search.component.html',
  styleUrls: ['./route-planning-search.component.scss']
})
export class RoutePlanningSearchComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('searchStreet', { static: false }) searchStreet: ElementRef;

  public routeTabs: any[] = [];
  public selectedTabIndex = 0;
  public showSpinner = false;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = [];
  public currentFilterValue: string = null;
  public selectedDevice: number;
  public selectedStreet: number;
  public isStreetDropdownOpen = false;
  public streetDropdownContent: any;
  public searchDate = moment().format('YYYY-MM-DD');
  public faEye = faEye;

  constructor(
    public rerService: RerService,
    public translateService: TranslateService,
    private router: Router,
    private actionDialog: MatDialog,
    private translationService: TranslateService
  ) { }

  ngOnInit(): void {
    this.rerService.getDeviceList();
    this.displayedColumns = ['device', 'street', 'periodBegin', 'periodEnd', 'waste', 'actions'];
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
      this.setDataTablePaginatorSort();

      fromEvent(this.searchStreet.nativeElement, 'input')
      .pipe(
          map((data: any) => data.target.value),
          tap(() => this.isStreetDropdownOpen = true),
          debounceTime(250),
          filter(query => query.length >= 2 || query.length === 0),
          distinctUntilChanged(),
          switchMap((value) => {
            this.selectedStreet = null;
            if (value.length === 0) {
              return of([]);
            }

            return this.rerService.getRerStreets(value);
          })
      ).subscribe({
        next:(value) => {
          this.streetDropdownContent = value;
        }
      });
  }

  public onDropdownStreetClick(street: any) {
      this.selectedStreet = street.id
      this.searchStreet.nativeElement.value = street.name;
      this.closeStreetDropdown();
    }

  public closeStreetDropdown() {
      this.isStreetDropdownOpen = false;
      this.streetDropdownContent = [];
      if (!this.selectedStreet) {
        this.searchStreet.nativeElement.value = '';
      }
  }

  private setDataTablePaginatorSort(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
		filterValue = filterValue.trim();
		filterValue = filterValue.toUpperCase();
		this.dataSource.filter = filterValue;
	}

  public goToPlanning(id: number) {

  }

  public onSearch() {
    this.showSpinner = true;
    this.rerService.getRoutePlanningSearchData({ date: this.searchDate, streetId: this.selectedStreet, deviceId: this.selectedDevice }).subscribe({
      next:(data) => {
        if (data?.length === 0) {
          this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: { success: 1, headermessage: this.translationService.instant('success'), message: this.translationService.instant('Rer.routePlanningSearch.emptySearchResult'), autoClose: true }
            }
          );
        }

        this.dataSource = new MatTableDataSource(data);
        this.showSpinner = false;
        this.setDataTablePaginatorSort();
      },
      error:() => this.showSpinner = false
    })
  }

  public getTranslationFor(label: string, value = {}): string {
      let result = '';
      this.translateService.get(label, value).subscribe((response: string) => result = response);

      return result;
  }

  public getLocale(): String {
      const lang = localStorage.getItem('currentLang');
      return (lang) ? lang.substring(0, 2) : 'ro';
  }

  public setDateForCopyEvent(event: any) {
      this.searchDate = event;
  }

  public navigateToPlanningDetails(planningId: number) {
		if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_DETAILS)) {
			const navigationExtras: NavigationExtras = { queryParams: { 'planningId': planningId }, skipLocationChange: true };
			this.router.navigate(['rer/management', { outlets: { rercontent: [RerService.ROUTE_RER_PLANING] } }], navigationExtras);
		}
	}

  public hasPlanningRight(): boolean {

    return this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_DETAILS);
  }
}
