import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from 'app/modules/rer/_service/rer.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';

import { RerLogs } from '../../_model/rerlogs.model';

@Component({
  selector: 'app-planning-logs',
  templateUrl: './planning-logs.component.html',
  styleUrls: ['./planning-logs.component.scss']
})
export class PlanningLogsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public dataSource: MatTableDataSource<RerLogs>;
  public displayedColumns: string[];
  public showSpinner: boolean;
  public currentFilterValue: string = null;
  public dateFormat: string;

  constructor(private rerService: RerService,
    private userPreferencesService: UserPreferencesService,
    private translationService: TranslateService) { }

  ngOnInit() {
    this.dateFormat = this.userPreferencesService.userPreferences.dateFormat;
    this.showSpinner = true;
    this.displayedColumns = ['date', 'device_name', 'field_changed', 'old_value', 'new_value', 'updated_by', 'created_at'];
    this.dataSource = new MatTableDataSource();
    this.rerService.getPlanningsLogs().subscribe(
      (response: RerLogs[]) => {
        this.dataSource.data = response;
        this.showSpinner = false;
      },
      () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerPlanning.logs.errorNotification"))
    )
  }

  ngAfterViewInit(): void {
    this.setDataTablePaginatorSort();
  }

  private setDataTablePaginatorSort(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
