import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthService } from '../../service/auth.service';
import * as moment from 'moment';
import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';
import { faEye,faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {

    private _subscriptions: Subscription[] = [];
    public faEye = faEye;
    public faEyeSlash = faEyeSlash;
    public errorMessage = '';
    public wrongCredentials = true;
    private resetToken = '';
    public hide = true;
    public hide2 = true;
    public backgroundImage: Array<any> = [
        [
            '/assets/project/' + environment.projectName  + '/images/signin/0-winter-1.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/0-winter-2.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/0-winter-3.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/0-winter-4.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/0-winter-5.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/0-winter-6.jpg',
        ],
        [
            '/assets/project/' + environment.projectName  + '/images/signin/1-spring-1.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/1-spring-2.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/1-spring-3.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/1-spring-4.jpg',
        ],
        [
            '/assets/project/' + environment.projectName  + '/images/signin/2-summer-1.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/2-summer-2.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/2-summer-3.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/2-summer-4.jpg',
        ],
        [
            '/assets/project/' + environment.projectName  + '/images/signin/3-autumn-1.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/3-autumn-2.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/3-autumn-3.jpg',
            '/assets/project/' + environment.projectName  + '/images/signin/3-autumn-4.jpg',
        ]
    ];
    background: string;
    public logoSrc = '/assets/project/' + environment.projectName.toLowerCase()  + '/images/logo-small.png';

    @ViewChild('resetPass') resetPass: NgForm;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private auth: AuthService
    ) { }

    ngOnInit() {
        this.wrongCredentials = false;
        const now = moment().format('M');
        const seasonIndex: any = (+now % 12) / 3;
        let imageIndex = 0;
        if (environment.isRandomBackgroundAtSignin) {
            imageIndex = this.getRandomInt(this.backgroundImage[+parseInt(seasonIndex, 10)].length);
        }
        this.background = this.backgroundImage[+parseInt(seasonIndex, 10)][imageIndex];

        this._subscriptions.push(
            this.activatedRoute.params.subscribe(
                params => {
                    if (params['token']) {
                        this.resetToken = params['token'];
                    }
                }
            )
        );
    }

    policyCheck(password) {
        if (password.length < 4) {
            return false;
        }
        if (!password.match('[0-9]+')) {
            return false;
        }
        if (!password.match('[a-z]+')) {
            return false;
        }
        return true;
    }

    changePassword() {
        this.wrongCredentials = false;
        if (this.resetPass.value.newpassword !== this.resetPass.value.repeatpassword) {
            this.errorMessage = 'passwordRecovery.passwdnotequal';
            this.wrongCredentials = true;
        } else if (!this.policyCheck(this.resetPass.value.newpassword)) {
            this.wrongCredentials = true;
            this.errorMessage = 'passwordRecovery.passwdweek';
        }

        if (!this.wrongCredentials && this.resetToken) {
            this.auth.resetPassword(this.resetToken, this.resetPass.value.newpassword).subscribe(
                (response) => {
                    this.resetPass.reset();
                    this.router.navigate(['/signin']);
                },
                (error: HttpErrorResponse) => {
                    switch (error.status) {
                        case  StatusCodes.FAILED_DEPENDENCY:
                            this.errorMessage = 'passwordRecovery.linkExpired';
                            break;
                        default :
                            this.errorMessage = 'passwordRecovery.unknownerror';
                    }
                    this.wrongCredentials = true;
                }
            );
        }
    }

    public onCancel() {
        this.router.navigate(['/signin']);
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
