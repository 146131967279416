import { FacebookPixelService } from './service/facebookpixel.service';
import { NgModule, NO_ERRORS_SCHEMA, ErrorHandler } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { CtsErrorHandler } from './interceptor/ctserrorhandler';
import { DatePipe } from '@angular/common';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { AppRoutingModule } from './app.routing';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { DispatcherSocketService } from './modules/service/dispatcher/dispatcher-socket.service';
import { ErrorMessageService } from './modules/service/notification/errormessage.service';
import { UnitMeasurementsService } from './service/unitmeasurements.service';
import { UserPreferencesService } from './service/userpreferences.service';
import { GeneralSocket } from './service/general-socket.service';
import { EndpointsService } from './service/endpoints.service';
import { UserService } from './modules/service/user.service';
import { AuthService } from './service/auth.service';
import { DynamicScriptLoaderService } from './service/dynamic-script-loader.service';

import { AutologinGuard } from './modules/service/guard/autologin-guard.guard';
import { AuthGuard } from './modules/service/guard/auth-guard.service';
import { NotificationService } from './service/notification.service';

import { ForgotPasswordDialogComponent } from './components/signin/dialogs/forgot-password-dialog/forgot-password-dialog.component';
import { ConfirmDialogComponent } from './components/signin/dialogs/confirm-dialog/confirm-dialog.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { UpgradeRequiredComponent } from './components/upgrade-required/upgrade-required.component';
import { NotificationComponent } from './modules/header/notification/notification.component';
import { ThankyouComponent } from './shared/thankyou/thankyou.component';
import { SigninComponent } from './components/signin/signin.component';
import { HeaderComponent } from './modules/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { AppComponent } from './app.component';
import { EnvServiceProvider } from './env.service.provider';
import { ErrorComponent } from './shared/error/error.component';
import { RerModule } from './modules/rer/rer.module';
import { SharedModule } from './shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClientPartnerService } from './modules/service/shared/clientpartner.service';
import { SettingsService } from './modules/service/tms/settings.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' },
        { prefix: './assets/i18n/map/', suffix: '.json' },
    ]);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgSelectModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatDialogModule,
        MatSnackBarModule,
        MatMenuModule,
        MatToolbarModule,
        NgxMaterialTimepickerModule,
        AppRoutingModule,
        RerModule,
        SharedModule,
        AngularEditorModule,
    ],
    schemas: [NO_ERRORS_SCHEMA],
    declarations: [
        AppComponent,
        SigninComponent,
        PasswordRecoveryComponent,
        ForgotPasswordDialogComponent,
        ConfirmDialogComponent,
        ThankyouComponent,
        HeaderComponent,
        HomeComponent,
        NotificationComponent,
        UpgradeRequiredComponent,
        ErrorComponent
    ],
    providers: [
        EnvServiceProvider,
        ErrorMessageService,
        AuthService,
        EndpointsService,
        AuthGuard,
        AutologinGuard,
        DynamicScriptLoaderService,
        GeneralSocket,
        UserService,
        NotificationService,
        UnitMeasurementsService,
        UserPreferencesService,
        DispatcherSocketService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        Title,
        CtsErrorHandler,
        DatePipe,
        { provide: ErrorHandler, useClass: CtsErrorHandler },
        FacebookPixelService,
        ClientPartnerService,
        SettingsService,
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
