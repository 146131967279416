import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    public logoSrc = '/assets/project/' + environment.projectName  + '/images/logo-small.png';

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        console.error(this.route.snapshot.params.message);
    }

}
