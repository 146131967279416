import { RerBinInterface } from '../_interface/rerbin.interface';

export class RerBin implements RerBinInterface {
    static readonly STATUS_AVAILABLE = 0;
    static readonly STATUS_ACTIVE = 1;
    static readonly STATUS_SUSPENDED = 2;
    static readonly STATUS_LOST = 3;
    static readonly STATUS_DELETED = 4;

    constructor(
        public id = null,
        public rer_client_id = null,
        public rer_client_address_id = null,
        public address = null,
        public city = null,
        public waste_type = null,
        public rer_waste_type_id = null,
        public code = null,
        public status = null,
        public rer_rfid = null,
        public rer_rfid_tag_id = null,
        public bin_type = null,
        public rer_bin_type_id = null,
        public description = null,
        public rer_client_name = null,
        public name = null,
        public division = null,
        public street = null,
        public street_number = null,
        public latitude = null,
        public longitude = null,
        public datetime_read = null,
        public vehicle_name = null,
        public bin_ownership = null,
        public rer_bin_ownership_id = null,
        public rer_bin_setup_document_id = null,
        public rer_bin_setup_document_number = null,
        public rer_bin_setup_document_status = null,
    ) { }
}
