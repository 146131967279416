import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from '../../../service/auth.service';

import { Device } from '../../model/settings/device.model';
import { EndpointsService } from 'app/service/endpoints.service';
import { NotificationType } from 'app/modules/model/settings/notificationtype.model';
import { NotificationPreference } from 'app/modules/model/settings/notificationpreference.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    
    public minimumVersion: String;

    constructor(private httpClient: HttpClient,
        private authService: AuthService,
        private translateService: TranslateService,
        private endpointsService: EndpointsService,) { }

    public getDeviceList(): Observable<any> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }

        return this.httpClient.get(this.endpointsService.get('device.settings.list'));
    }

    public updateDevice(device: Device): Observable<any> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }

        return this.httpClient.post(this.endpointsService.get('device.settings.update'), device);
    }

    public getLatestVersion(): Observable<any> {
        return this.httpClient.get(
            this.endpointsService.get('version.latest'));
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);
        return result;
    }

    public getLatestMileage(deviceId: number, hostname: string): Observable<{ devicetime: string, mileage: number }> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }
        return this.httpClient.post<{ devicetime: string, mileage: number }>(
            this.endpointsService.get('device.latestMileage', [deviceId], hostname), {}
        );
    }

    public getNotificationType(): Observable<NotificationType[]> {
        return this.httpClient.get<any[]>(this.endpointsService.get('notification.getNotificationType'))
            .pipe(map(
                (notificationTypes: any[]) => {
                    const notificationTypeList: NotificationType[] = [];
                    notificationTypes.forEach(
                        notificationType => {
                            notificationTypeList.push(
                                new NotificationType(
                                    notificationType.id,
                                    notificationType.name,
                                    notificationType.class
                                )
                            );
                        }
                    );
                    return notificationTypeList;
                }
            ))
    }

    public getNotificationPreference(): Observable<NotificationPreference[]> {
        return this.httpClient.get<any[]>(this.endpointsService.get('notification.getNotificationPreference'))
            .pipe(map(
                (notificationPreferences: any[]) => {
                    const notificationPreferenceList: NotificationPreference[] =[];
                    notificationPreferences.forEach(
                        notificationPreference => {
                            notificationPreferenceList.push(
                                new NotificationPreference(
                                    notificationPreference.user_notification_type_id,
                                    notificationPreference.channel,
                                )
                            );
                        }
                    );
                    return notificationPreferenceList;
                }
            ))
    }

    public updateNotificationPreference(notificationPreferenceList: NotificationPreference[]): Observable<any[]> {
        return this.httpClient.post<any[]>(this.endpointsService.get('notification.updateNotificationPreference'), notificationPreferenceList);
    }

    public getMinimumVersion(): Observable<any> {
        return this.httpClient.get(
            this.endpointsService.get('version.minimumVersionAccepted'));
    }
}
