import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { SharedModule } from '../../../shared/shared.module';

import { DataImportRerComponent } from './data-import-rer.component';
import { FinalImportComponent } from './clients-import/final-import/final-import.component';
import { ClientsImportComponent } from './clients-import/clients-import.component';
import { TagsImportComponent } from './tags-import/tags-import.component';
import { TagsFinalImportComponent } from './tags-import/final-import/tags-final-import.component';
import { BinSetupImportComponent } from './bin-setup-import/bin-setup-import.component';
import { BinSetupFinalImportComponent } from './bin-setup-import/final-import/bin-setup-final-import.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }, // common / shared translation
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        DataImportRerComponent,
        FinalImportComponent,
        ClientsImportComponent,
        TagsImportComponent,
        TagsFinalImportComponent,
        BinSetupImportComponent,
        BinSetupFinalImportComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: []
})
export class DataImportRerModule { }
