import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { ToastRef } from '../model/toastref';
import { ToastData } from '../model/toastdata';

import { NotificationComponent } from '../modules/header/notification/notification.component';

@Injectable({ providedIn: 'root' })
export class ToastService {

    private lastToast: ToastRef;

    constructor(private overlay: Overlay, private parentInjector: Injector) { }

    public show(data: ToastData) {
        const positionStrategy = this.getPositionStrategy();
        const overlayRef = this.overlay.create({ positionStrategy });

        const toastRef = new ToastRef(overlayRef);
        this.lastToast = toastRef;
        const injector = this.getInjector(data, toastRef, this.parentInjector);
        const toastPortal = new ComponentPortal(NotificationComponent, null, injector);

        overlayRef.attach(toastPortal);

        return toastRef;
    }

    private getInjector(data: ToastData, toastRef: ToastRef, parentInjector: Injector) {
        const tokens = new WeakMap();

        tokens.set(ToastData, data);
        tokens.set(ToastRef, toastRef);

        return new PortalInjector(parentInjector, tokens);
    }

    private getPositionStrategy() {

        return this.overlay.position().global().top(this.getPosition()).right('0 px');
    }

    public getPosition() {
        const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
        const position = lastToastIsVisible ? this.lastToast.getPosition().bottom : 64;

        return position + 'px';
    }
}
