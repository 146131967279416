import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../service/auth.service';
import { EndpointsService } from 'app/service/endpoints.service';

import { TmsSettingsModel } from '../../model/tms/tmssettings.model';
import { Vehicle } from 'app/modules/model/tms/vehicle.model';
import { Driver } from 'app/modules/model/driver/driver.model';

import { NotificationComponent } from 'app/shared/notification/notification.component';
@Injectable()

export class SettingsService {

    private _settings = new TmsSettingsModel;
    public settingsTab = {
        companyTab: 0,
        invoiceTab: 1,
        orderTab: 2,
        fieldsTab: 3,
        ordersTab: 4,
        addressesTab: 5,
        smsTab: 6,
    }
    private mandatorySettingsProperties = [
        { field: 'name', tabId: this.settingsTab.companyTab },
        { field: 'currency', tabId: this.settingsTab.companyTab },
        { field: 'vat_number', tabId: this.settingsTab.companyTab },
        { field: 'country', tabId: this.settingsTab.companyTab },
        { field: 'headquarters_address', tabId: this.settingsTab.companyTab },
        { field: 'contact_person_name', tabId: this.settingsTab.companyTab },
        { field: 'phone', tabId: this.settingsTab.companyTab },
        { field: 'email_address', tabId: this.settingsTab.companyTab },
        { field: 'delegate_name', tabId: this.settingsTab.companyTab },
        { field: 'delegate__ic', tabId: this.settingsTab.companyTab },
        { field: 'delegate_ic_serie', tabId: this.settingsTab.companyTab },
        { field: 'delegate_ic_number', tabId: this.settingsTab.companyTab },
        { field: 'invoice_currency', tabId: this.settingsTab.companyTab },
        { field: 'vat_percentage', tabId: this.settingsTab.companyTab },
        { field: 'accepted_distance_to_pickup', tabId: this.settingsTab.ordersTab },
        { field: 'invoice_number', tabId: this.settingsTab.invoiceTab },
        { field: 'invoice_series', tabId: this.settingsTab.invoiceTab },
        { field: 'price_per_km', tabId: this.settingsTab.ordersTab },
        { field: 'shipping_number', tabId: this.settingsTab.orderTab },
        { field: 'shipping_series', tabId: this.settingsTab.orderTab },
        { field: 'shipping_number', tabId: this.settingsTab.ordersTab },
        { field: 'shipping_series', tabId: this.settingsTab.ordersTab },
        { field: 'vat_percentage', tabId: this.settingsTab.invoiceTab },
        { field: 'country', tabId: this.settingsTab.companyTab },
    ];

    public get settings() {
        return this._settings;
    }
    public set settings(value) {
        this._settings = value;
    }
    public settingsLoaded =  new Subject<boolean>();
    public settingListChangedSubject = new Subject<TmsSettingsModel>();
    public settingSavedChangedSubject = new Subject<boolean>();
    public smtpTestSubject = new Subject<any>();
    public archiveDaysWarning = false;

    public curencyCodes = [
        { value: 'RON', label: 'RON' },
        { value: 'EUR', label: 'EUR' },
        { value: 'USD', label: 'USD' },
        { value: 'GBP', label: 'GBP' },
        { value: 'BGN', label: 'BGN' },
        { value: 'HUF', label: 'HUF' },
    ];

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        public translateService: TranslateService,
        private endpointsService: EndpointsService,
        private actionDialog: MatDialog
    ) {
        this.translateService.setDefaultLang('ro_RO');
        const lang = localStorage.getItem('currentLang');
        if (lang) {
            this.translateService.use(lang);
        }
        this.getSettings();
    }


    public getSettings() {
        this.httpClient.get(this.endpointsService.get('tms.settings.getclientsettings')).subscribe(
            (settings: any) => {
                this._settings = settings;
                this.settingListChangedSubject.next(this._settings);
                this.settingsLoaded.next(true);
            }
            , error => this.settingsLoaded.next(true));
    }

    public editSettings(settings: any, tab: string) {
        this.httpClient.post(this.endpointsService.get('tms.settings.updateclientsettings') + '/' + tab, settings).subscribe(
            (response: TmsSettingsModel) => {
                if (response['message'] === 'success') {
                    this._settings = response['settings'];
                    this.settingListChangedSubject.next(this._settings);
                    this.settingSavedChangedSubject.next(true);
                }
            },
            () => this.settingSavedChangedSubject.next(false)
        );
    }

    public createData(settingsData: TmsSettingsModel, tab: string) {
        let data = {};
        if (tab == 'companytab') {
            data =
            {
                bank_name: settingsData.bank_name,
                contact_person_name: settingsData.contact_person_name,
                country: settingsData.country,
                cr_number: settingsData.cr_number,
                currency: settingsData.currency,
                delegate__ic: settingsData.delegate__ic,
                delegate_ic_number: settingsData.delegate_ic_number,
                delegate_ic_serie: settingsData.delegate_ic_serie,
                delegate_name: settingsData.delegate_name,
                delivery_address: settingsData.delivery_address,
                delivery_as_headquarters: settingsData.delivery_as_headquarters,
                email_address: settingsData.email_address,
                email_verified: settingsData.email_verified,
                headquarters_address: settingsData.headquarters_address,
                iban_eur: settingsData.iban_eur,
                iban_ron: settingsData.iban_ron,
                invoice_currency: settingsData.invoice_currency,
                invoice_vat_paid_type: settingsData.invoice_vat_paid_type,
                logo_content: settingsData.logo_content,
                name: settingsData.name,
                phone: settingsData.phone,
                stamp_content: settingsData.stamp_content,
                swift_code: settingsData.swift_code,
                use_custom_mail: settingsData.use_custom_mail,
                vat_percentage: settingsData.vat_percentage,
                vat_number: settingsData.vat_number,
            };
        } else if (tab == 'invoicetab') {
            data =
            {
                delegate__ic: settingsData.delegate__ic,
                delegate_ic_number: settingsData.delegate_ic_number,
                delegate_ic_serie: settingsData.delegate_ic_serie,
                delegate_name: settingsData.delegate_name,
                invoice_currency: settingsData.invoice_currency,
                invoice_footer: settingsData.invoice_footer,
                invoice_generated_name: settingsData.invoice_generated_name,
                invoice_header: settingsData.invoice_header,
                invoice_number: settingsData.invoice_number,
                invoice_other: settingsData.invoice_other,
                invoice_payment_term: settingsData.invoice_payment_term,
                invoice_series: settingsData.invoice_series,
                invoice_vat_paid_type: settingsData.invoice_vat_paid_type,
                vat_percentage: settingsData.vat_percentage
            };
        } else if (tab == 'ordertab') {
            data =
            {
                shipping_document_generated_name: settingsData.shipping_document_generated_name,
                shipping_number: settingsData.shipping_number,
                shipping_series: settingsData.shipping_series,
                shipping_special_conditions: settingsData.shipping_special_conditions
            };
        } else if (tab == 'custommailtab') {
            data =
            {
                email_address: settingsData.email_address,
                email_password: settingsData.email_password,
                email_verified: settingsData.email_verified,
                smtp_host: settingsData.smtp_host,
                smtp_port: settingsData.smtp_port,
                use_custom_mail: settingsData.use_custom_mail
            };
        } else if (tab == 'fieldstab') {
            data =
            {
                field_settings: settingsData.field_settings
            };
        } else if (tab == 'addressestab') {
            data =
            {
                addresses: settingsData.addresses,
                constrain_addresses_list: settingsData.constrain_addresses_list,
                ignore_vehicle_location: settingsData.ignore_vehicle_location
            };
        } else if (tab == 'orderstab') {
            data =
            {
                accepted_distance_to_pickup: settingsData.accepted_distance_to_pickup,
                archive_shippings_days_number: settingsData.archive_shippings_days_number,
                location_from: settingsData.location_from,
                price_per_km: settingsData.price_per_km,
                unfinished_shipping_days: settingsData.unfinished_shipping_days,
                unfinished_shipping_status: settingsData.unfinished_shipping_status,
                shipping_number: settingsData.shipping_number,
                shipping_series: settingsData.shipping_series
            };
        }

        return data;
    }

    public getNextDocumentNumber(): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('tms.settings.getnextdocumentnumber'), {});
    }

    public incrementWizardStep(): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('tms.settings.incrementwizardstep'), {});
    }

    public addVehiclesDriversFromWizard(vehicles: Vehicle[], drivers: Driver[]): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('tms.settings.addvehiclesdriversfromwizard'), { vehicles, drivers });
    }

    public addSelfClientPartner(settings: TmsSettingsModel): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('tms.settings.addSelfClientPartner'), settings);
    }

    public getNextInvoiceNumber(data: any): Observable<any> {
        return this.httpClient.post(
            this.endpointsService.get('tms.settings.getnextinvoicenumber'), data);
    }

    public getCtsClientData(): Observable<any> {
        return this.httpClient.get(
            this.endpointsService.get('tms.settings.getctsclientdata'));
    }

    public getPricePerKm(): number {
        return (this._settings.price_per_km) ? this._settings.price_per_km : 0;
    }

    public getAcceptedDistanceToPickup(): number {
        return (this._settings && this._settings.accepted_distance_to_pickup !== undefined) ?
            Number(this._settings.accepted_distance_to_pickup) : 0;
    }

    public getMandatorySettingsProperties(tabId?: number): any[] {
        if (tabId != undefined) {
            return this.mandatorySettingsProperties.filter(x => x.tabId == tabId);
        }
        return this.mandatorySettingsProperties;
    }

    public canSaveSettings(settings: TmsSettingsModel, properties: any[], tabId: number): boolean {
        let result = true;
        let propertiesToCheck = properties.filter(x => x.tabId == tabId);
        for (let i = 0; i < propertiesToCheck.length; i++) {
            if (settings[propertiesToCheck[i].field] === undefined ||
                settings[propertiesToCheck[i].field] === null ||
                (typeof settings[propertiesToCheck[i].field] != "boolean" && !settings[propertiesToCheck[i].field])) {
                result = false;
                break;
            }
        }

        if (tabId == this.settingsTab.ordersTab && (settings['archive_shippings_days_number'] < 30 || settings['archive_shippings_days_number'] > 1825)) {
            result = false;
            this.archiveDaysWarning = true;
        }

        return result;
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public showErrorModal(message: string): void {
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: { success: 0, headermessage: this.getTranslationFor('error'), message: message }
            }
        );
    }

    public showSuccessModal(message: string): void {
        this.actionDialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: { success: 1, headermessage: this.getTranslationFor('success'), message: message }
            }
        );
    }

    public testSmtpSettings(mailSettings: any) {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
        }
        this.httpClient.post(
            this.endpointsService.get('tms.settings.smtptest'), mailSettings, { observe: 'response' })
            .subscribe(
                (response) => this.smtpTestSubject.next(response),
                (error) => this.smtpTestSubject.next(error)
            );
    }
}
