import { GeofenceInterface } from '../../map/interface/geofence.interface';

const EARTH_RADIUS = 6371; // km

export class Geofence implements GeofenceInterface {

    constructor(
        public id: number,
        public client_id: number,
        public user_id: number,
        public name: string,
        public description: string,
        public address: any,
        public color: string,
        public type: string,
        public center_latitude: number,
        public center_longitude: number,
        public radius: number,
        public coords: any,
        public precalc_values: any,
        public geofenceGroups: number[],
        public shape: any,
        public checked = false,
        public label = null,
        public marker = null,
        public visible = true,
        public group_color = null,
        public selected_group_id = null,
        public selected_group_name = null
    ) { }


    /**
     *
     * see http://alienryderflex.com/polygon/
     *
     */
    public isInside(latitude: number, longitude: number) {
        let ret = false;
        const length = this.coords.length;
        let j = length - 1;
        for (let i = 0; i < length; i++) {
            if ((this.coords[i]['lat'] < latitude && this.coords[j]['lat'] >= latitude
                || this.coords[j]['lat'] < latitude && this.coords[i]['lat'] >= latitude)) {
                // ret ^= (latitude * this.precalc_values['multiple'][i] + this.precalc_values['constant'][i]) < longitude;
                ret = ( ret || ((latitude * this.precalc_values['multiple'][i] + this.precalc_values['constant'][i]) < longitude) ) &&
                 !( ret && ((latitude * this.precalc_values['multiple'][i] + this.precalc_values['constant'][i]) < longitude) );
            }
            j = i;
        }
        return ret;
    }

    /**
     * Returns distance in KM between two points
     *
     */
    public  distanceBetweenPoints(point1: any) {
        const point2 = { lat: this.center_latitude, lng: this.center_longitude };
        if (!point1['lat'] || !point1['lng'] || !point2['lat'] || !point2['lng']) {
            return;
        }

        const sLat1 = Math.sin(this.deg2rad(point1['lat']));
        const sLat2 = Math.sin(this.deg2rad(point2['lat']));
        const cLat1 = Math.cos(this.deg2rad(point1['lat']));
        const cLat2 = Math.cos(this.deg2rad(point2['lat']));
        const cLon = Math.cos(this.deg2rad(point1['lng']) - this.deg2rad(point2['lng']));

        const cosD = sLat1 * sLat2 + cLat1 * cLat2 * cLon;
        let acos = Math.acos(cosD);
        if (isNaN(acos)) {
            acos = 0;
        }

        return EARTH_RADIUS * acos;
    }

    private deg2rad(deg: any) {
        return +deg * (Math.PI / 180);
    }

}

