<div class="warnings-content h-100 overflow-auto w-100 p-2 pt-4">
    <ng-scrollbar #scrollable class="map-scrollbar pr-4" style="height: calc(100% - 70px);">
        <div *ngFor="let line of emptyLines; let i = index" class="mb-4">
            <div class="bgcolor-orange p-2 d-flex mb-2 align-items-center">
                <i class="icon-tmss-table-header-warning font-size-28 color-red-primary mr-2"></i>
                <p class="m-0 font-weight-bold">{{'Rer.dataImportRer.tagsImport.noTagsForLine' | translate}} {{line}}
                </p>
            </div>
        </div>
        <div *ngIf="duplicateRfids.length" class="mb-4">
            <div class="bgcolor-orange p-2 d-flex mb-2 align-items-center">
                <i class="icon-tmss-table-header-warning font-size-28 color-red-primary mr-2"></i>
                <p class="m-0 font-weight-bold"> {{'Rer.dataImportRer.tagsImport.duplicateRfids' | translate}}
                    <br>
                    <span *ngFor="let duplicate of duplicateRfids; let i = index" class="m-0 font-weight-bold">{{i ===
                        duplicateRfids.length-1 ? duplicate : duplicate + ','}} &nbsp;</span>
                </p>
            </div>
        </div>
        <div class="search-content d-flex align-items-center">
            <div class="datatTableFilter p-2 d-flex align-items-center w-100">
                <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" class="flex-grow-1"
                    placeholder="{{ 'Rer.dataImportRer.clientsImport.dataTable.quickSearch' | translate }}">
            </div>
        </div>
        <mat-table #table [dataSource]="dataSource" matSort class="w-100 mt-2" style="height: calc(100% - 180px);">
            <ng-container matColumnDef="line">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.dataImportRer.line' | translate }} </mat-header-cell>
                <mat-cell class="pl-2" *matCellDef="let element">{{ element.line }}</mat-cell>=
            </ng-container>
            <ng-container matColumnDef="rfid">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.clients.addEdit.rfidReadings.dataTable.rfid' | translate }} </mat-header-cell>
                <mat-cell class="pl-2" *matCellDef="let element">{{ element.rfid }}</mat-cell>=
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
            </mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]"
            [showFirstLastButtons]="true">
        </mat-paginator>
    </ng-scrollbar>
    <div class="d-flex align-items-end justify-content-between mt-2"
        style="height: 60px; border-top: 1px solid #eceef0;">
        <div class="button-component bgcolor-white" (click)="cancelImportEventEmitter.next(0)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component utility-green-bg-color" *ngIf="requestData && requestData.length" (click)="onDoImport()">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.dataImportRer.clientsImport.import' | translate }}</span>
            </div>
        </div>
    </div>
</div>