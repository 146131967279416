<mat-tab-group animationDuration="0ms" style="height: 100%;">
    <mat-tab>
        <ng-template mat-tab-label>
            <i class="icon-cts-user-overview"></i>
            <span class="ml-1 mr-2">{{ 'Rer.clients.addEdit.overview.label' | translate }}</span>
        </ng-template>
        <app-overview [client]="client" (changeClientStatusEventEmitter)="changeClientStatus($event)" ></app-overview>
    </mat-tab>
    <mat-tab [disabled]="!client.id">
        <ng-template mat-tab-label>
            <i class="icon-tmss-tab-company"></i>
            <span class="ml-1 mr-2">{{ 'Rer.clients.addEdit.address.label' | translate }}</span>
        </ng-template>
        <app-address [client]="client"></app-address>
    </mat-tab>
    <mat-tab [disabled]="!client.id">
        <ng-template mat-tab-label>
            <i class="icon-tmss-tab-cargo3d"></i>
            <span class="ml-1 mr-2">{{ 'Rer.clients.addEdit.objects.label' | translate }}</span>
        </ng-template>
        <app-bins [client]="client"></app-bins>
    </mat-tab>
    <mat-tab [disabled]="!client.id">
        <ng-template mat-tab-label>
            <i class="ctsicon-fap-rfid"></i>
            <span class="ml-1 mr-2">{{ 'Rer.clients.addEdit.rfidReadings.label' | translate }}</span>
        </ng-template>
        <app-rfid-readings [client]="client"></app-rfid-readings>
    </mat-tab>
    <mat-tab [disabled]="!client.id">
        <ng-template mat-tab-label>
            <i class="ctsicon-fap-rfid"></i>
            <span class="ml-1 mr-2">{{ 'Rer.clients.addEdit.clientChanges.label' | translate }}</span>
        </ng-template>
        <app-client-changes [client]="client"></app-client-changes>
    </mat-tab>
    <mat-tab *ngIf="hasVerbalProcessListRight" [disabled]="!client.id">
        <ng-template mat-tab-label>
            <i class="ctsicon-fap-rfid"></i>
            <span class="ml-1 mr-2">{{ 'Rer.clients.addEdit.verbalProcess.label' | translate }}</span>
        </ng-template>
        <app-verbal-process *ngIf="hasVerbalProcessListRight" [client]="client"></app-verbal-process>
    </mat-tab>
</mat-tab-group>
