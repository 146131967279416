import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RerService } from 'app/modules/rer/_service/rer.service';
import { UserPreferencesService } from 'app/service/userpreferences.service';

import { RerRfidTag } from '../../../_model/rerrfidtag.model';
import { RerWasteType } from 'app/modules/rer/_model/rerwastetype.model';
import { RerBinType } from 'app/modules/rer/_model/rerbintype.model';
import { RerClient } from 'app/modules/rer/_model/rerclient.model';
import { RerBin } from 'app/modules/rer/_model/rerbin.model';
import { RerBinOwnership } from './../../../_model/rerbinownership';

import { ConfirmActionComponent } from '../../../../../shared/confirm-action/confirm-action.component';
import { AddEditBinComponent } from './add-edit-bin/add-edit-bin.component';

@Component({
    selector: 'app-bins',
    templateUrl: './bins.component.html',
    styleUrls: ['./bins.component.scss']
})
export class BinsComponent implements OnInit, OnDestroy {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean;
    public rfidTagList$ = null;
    public rfidTagList = null;
    public isEditable = true;
    public dateFormat: string;

    private wasteTypeList: RerWasteType[] = [];
    private binTypeList: RerBinType[] = [];
    private binOwnershipsList: RerBinOwnership[] = [];

    private wasteTypeList$: Observable<RerWasteType[]>;
    private binTypeList$: Observable<RerBinType[]>;
    private binOwnershipList$: Observable<RerBinOwnership[]>;
    private _subscriptions: Subscription[] = [];

    @Input() client: RerClient;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(private rerService: RerService,
        public actionDialog: MatDialog,
        private userPreferencesService: UserPreferencesService,
        private translationService: TranslateService,
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;

        this.displayedColumns = [
            'id', 'waste_type', 'address',
            'bin_type', 'bin_ownership', 'status', 'rer_rfid',
            'description', 'latitude',
            'datetime_read', 'vehicle_name',
            'rer_bin_setup_document_number', 'rer_bin_setup_document_status',
            'actions'
        ];
        if (this.client.id) {
            this.getBinsList();
        }

        if (this.client.status !== RerClient.STATUS_ACTIVE) {
            this.isEditable = false;
        }

        this.rfidTagList$ = this.rerService.rfidTagList$;
        this.wasteTypeList$ = this.rerService.wasteTypeList$;
        this.binTypeList$ = this.rerService.binTypeList$;
        this.binOwnershipList$ = this.rerService.binOwnershipList$;

        this.setSubscriptions();
    }

    private setSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.rerBinListChangedSubject.subscribe(
                ({ rerBin, clientId }) => {
                    if (clientId === this.client.id)
                        this.client.rer_bins = rerBin;
                        this.client.rer_bins.forEach(element => {
                            if (!element.address) {
                                element.address = element.city + ', ' + element.division + ' ' + element.street
                                    + ' ' + element.street_number;
                            }
                        });
                        this.dataSource = new MatTableDataSource(this.client.rer_bins);
                        this.setDataTablePaginatorSort();
                }
            ),
            this.rerService.rerClientEditableSubject.subscribe(
                (response: boolean) => {
                    this.isEditable = response;
                }
            ),
            this.rerService.rerClientChangedSubject.subscribe(
                (response: boolean) => {
                    const client = this.rerService.getRerClientById(this.client.id);
                    this.client.status = client.status;
                    if (this.client.status !== RerClient.STATUS_ACTIVE) {
                        this.isEditable = false;
                    } else {
                        this.isEditable = true;
                    }
                }
            ),
            this.rfidTagList$.subscribe(
                (rfidTags: RerRfidTag[]) => {
                    this.rfidTagList = rfidTags;
                }
            ),
            this.wasteTypeList$.subscribe(
                (wasteType: RerWasteType[]) => {
                    this.wasteTypeList = wasteType;
                }
            ),
            this.binTypeList$.subscribe(
                (binType: RerBinType[]) => {
                    this.binTypeList = binType;
                }
            ),
            this.binOwnershipList$.subscribe(
                (binOwnerships: RerBinOwnership[]) => {
                    this.binOwnershipsList = binOwnerships;
                }
            ),
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private getBinsList(): void {
        this.showSpinner = true;
        this.rerService.getRerBinsList(this.client.id).subscribe(
            (rerBinsList: RerBin[]) => {
                this.client.rer_bins = rerBinsList;
                this.client.rer_bins.forEach(element => {
                    if (!element.address) {
                        element.address = element.city + (element.division != null ? ', ' + element.division + ' ' : ' ') + (element.street != null ? element.street + ' ' : ' ')
                            + (element.street_number != null ? element.street_number : ' ');
                    }
                });

                this.rerService.rerBinListChangedSubject.next({ rerBin: this.client.rer_bins, clientId: this.client.id });
                this.dataSource = new MatTableDataSource(rerBinsList);
                this.showSpinner = false;
                this.setDataTablePaginatorSort();
            },
            () => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            });
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private addEditRerBinsList(object: RerBin): void {
        if (!this.client.rer_bins) {
            this.client.rer_bins = [];
        }
        this.showSpinner = true;
        this.rerService.addEditRerBin(object).subscribe(
            (response: boolean) => {
                this.showSpinner = false;
                if (response) {
                    this.updateEditRerBinsList(object);
                }
            },
            (error) => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            }
        );
    }

    private updateEditRerBinsList(object: RerBin): void {
        const objectItemToEdit = this.client.rer_bins.find(obj => obj.id === object.id);
        if (!objectItemToEdit) {
            this.client.rer_bins.push(object);
            this.client.rer_bins.forEach(element => {
                if (!element.address) {
                    element.address = element.city + (element.division ? ', ' + element.division : '') + ' ' + (element.street ? element.street : '')
                        + ' ' + (element.street_number ? element.street_number : '');
                }
            });
            this.dataSource = new MatTableDataSource(this.client.rer_bins);
            this.setDataTablePaginatorSort();
        } else {
            Object.keys(objectItemToEdit).forEach(key => objectItemToEdit[key] = object[key]);
        }
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onAddEditBin(id: number): void {
        let object: RerBin;
        let clientObject = null;
        if (id) {
            clientObject = this.client.rer_bins.find((el: RerBin) => el.id === id);
            object = clientObject ? Object.assign({}, clientObject) : new RerBin();
        } else {
            object = new RerBin();
        }

        const tagsList = this.rfidTagList.map(x => Object.assign({}, x));

        if (tagsList.find(rt => rt.id === object.rer_rfid_tag_id) === undefined) {
            const current = Object.assign({}, new RerRfidTag(object.rer_rfid_tag_id, null, null, object.rer_rfid, null, null, null, null));
            tagsList.unshift(current);
        }

        const dialogRef = this.actionDialog.open(
            AddEditBinComponent,
            {
                width: '800px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    object: object,
                    clientName: this.client.name,
                    address: this.client.rer_client_address,
                    rfidTagList: tagsList,
                    clientStatus: this.client.status,
                    wasteType: this.wasteTypeList,
                    binType: this.binTypeList,
                    binOwnership: this.binOwnershipsList
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: RerBin) => {
                if (obj) {
                    const selectedAddress = this.client.rer_client_address.filter(addr => addr.id === obj.rer_client_address_id);
                    if (selectedAddress.length) {
                        obj.division = selectedAddress[0].division;
                        obj.city = selectedAddress[0].city;
                        obj.street = selectedAddress[0].street;
                        obj.street_number = selectedAddress[0].street_number;
                    }
                    if (clientObject && clientObject.status !== obj.status) {
                        this.rerService.editUnalocatedRerBinChangeStatus(obj)
                            .pipe(finalize(() => {
                                this.showSpinner = false;
                            }))
                            .subscribe(
                                () => {
                                    this.rerService.showSaveConfirmation(
                                        this.translationService.instant('Rer.objects.statusChangeSuccess'));
                                    this.getBinsList();
                                    this.updateEditRerBinsList(obj);
                                },
                                () => {
                                    this.rerService.showErrorNotifications(
                                        this.translationService.instant('errorNotification'));
                                }
                            );
                    } else {
                        if (object.rer_rfid_tag_id) {
                            const rfidTag = this.rerService.getRfidTagById(object.rer_rfid_tag_id);
                            if (rfidTag) {
                                object.rer_rfid = rfidTag.rfid;
                            }
                        }
                        this.addEditRerBinsList(obj);
                    }
                }
            }
        );
    }

    public onDeleteBin(id: number): void {
        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.clients.addEdit.objects.dataTable.confirmDeleteAction')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    this.rerService.deleteRerBin(id).subscribe(
                        (result: boolean) => {
                            this.showSpinner = false;
                            if (result) {
                                const objectIndex = this.client.rer_bins.findIndex((el: RerBin) => el.id === id);
                                if (objectIndex !== -1) {
                                    this.client.rer_bins.splice(objectIndex, 1);
                                    this.client.rer_bins.forEach(element => {
                                        if (!element.address) {
                                            element.address = element.city + ', ' + element.division + ' ' + element.street
                                                + ' ' + element.street_number;
                                        }
                                    });

                                    // this.rerService.rerBinListChangedSubject.next(this.client.rer_bins);
                                    this.rerService.rerBinListChangedSubject.next({ rerBin: this.client.rer_bins, clientId: this.client.id });
                                    this.dataSource.data = this.client.rer_bins;
                                    this.setDataTablePaginatorSort();
                                }
                            }
                        },
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            }
        );
    }
}
