import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'offset'
})
export class DateOffsetipe implements PipeTransform {

    transform(value, seconds, timeformat) {
        if (!value) {
            return value;
        }
        const offset = new Date().getTimezoneOffset();
        const date = new Date(this.replaceChar(value, '-', '/'));

        date.setMinutes(date.getMinutes() - offset);
        if (!timeformat ) {
            if (seconds) {
                timeformat = 'YYYY-MM-DD HH:mm:ss';
            } else {
                timeformat = 'YYYY-MM-DD HH:mm';
            }
        }
        return moment(date).format(timeformat);
    }

    private replaceChar(string, to, withChar) {
        let result = '';
        for (const i of string) {
            if (i === to) {
                result += withChar;
            } else {
                result += i;
            }
        }

        return result;
    }
}
