<div class="row w-100 text">
    <div class="col-12 col-md-6">
        <div class="mt-2 d-flex align-items-center" [ngClass]="shownDetails ? '' : 'hasBorder'">
            <div class="row w-100 d-flex align-items-center pl-3">
                <div role="button" class="pb-2" (click)="shownDetails = !shownDetails"
                    [ngClass]="shownDetails ?  'icon-cts-chevron-right rotate': 'icon-cts-chevron-right'">
                </div>
                <div class="col-sm-5 text-truncate "><h6 class="font-weight-bold pt-2">{{ 'Rer.rerIncident.details.incidentDetails' | translate
                    }}</h6></div>
            </div>
        </div>
        <app-overview *ngIf="shownDetails" [incident]="incident"></app-overview>
        <div class="mt-2 d-flex align-items-center" [ngClass]="shownAdministration ? '' : 'hasBorder'">
            <div class="buttonsContainer row w-100 d-flex align-items-center pl-3">
                <div role="button" class="pb-2" (click)="shownAdministration = !shownAdministration"
                    [ngClass]="shownAdministration ?  'icon-cts-chevron-right rotate': 'icon-cts-chevron-right'">
                </div>
                <div class="col-4 text-truncate font-weight-bold d-flex">
                    <span><h6 class="text font-weight-bold pt-2">{{'Rer.rerIncident.details.incidentAdministration' | translate }}</h6></span>
                    <div *ngIf="hasDetailsRight" class="icon-tmss-list-action-edit font-size-18 pointer pt-2 pl-2 pr-2"
                    (click)="editMode = !editMode"></div>
                </div>
                <div class="col-6">
                    <span *ngIf="hasDetailsRight" class="eButton pl-4 pr-4 ml-2 pt-1 pb-1"
                        [ngClass]="editMode ? 'pointer primary': 'disable'" (click)="onSave()">
                        <span [ngClass]="editMode ? 'color-white': 'color-grey'" class="text-center">{{
                            'Rer.rerIncident.details.save' | translate }}</span>
                    </span>
                    <span *ngIf="hasDetailsRight" class="eButton ml-0 pl-4 pr-4 ml-2 pt-1 pb-1"
                        [ngClass]="editMode ? 'utility-red-bg-color': 'disable'" (click)="onSave()">
                        <span [ngClass]="editMode ? 'color-white': 'color-grey'" class="text-center">{{
                            'Rer.rerIncident.details.cancel' | translate }}</span>
                    </span>
                </div>
            </div>
        </div>
        <app-administration *ngIf="shownAdministration" [incident]="incident" [editMode]="editMode">
        </app-administration>
        <div class="mt-2 d-flex align-items-center" [ngClass]="shownLog ? '' : 'hasBorder'">
            <div class="row w-100 d-flex align-items-center pl-3">
                <div role="button" class="pb-2" (click)="shownLog = !shownLog"
                    [ngClass]="shownLog ? 'icon-cts-chevron-right rotate': 'icon-cts-chevron-right'"></div>
                <div class="col-sm-5 text-truncate"><h6 class="font-weight-bold pt-2">{{ 'Rer.rerIncident.details.incidentLog' | translate }}
                </h6></div>
            </div>
        </div>
        <app-incident-log *ngIf="shownLog" [incident]="incident"></app-incident-log>
    </div>
    <div class="col-12 col-md-6">
        <div class="mt-2 d-flex align-items-center" [ngClass]="shownVideo ? '' : 'hasBorder'">
            <div class="row w-100 d-flex align-items-center pl-3">
                <div role="button" class="pb-2" (click)="shownVideo = !shownVideo" [ngClass]="shownVideo ? 'icon-cts-chevron-right rotate': 'icon-cts-chevron-right'"></div>
                <div class="col-md-10">
                    <h6 class="font-weight-bold pt-2">
                        {{ 'Rer.rerIncident.details.incidentVideo' | translate }}
                        <span class="liveFeed"><span class="dot"></span> &nbsp; {{ 'Rer.rerIncident.details.liveFeed' | translate }}</span>
                        <a class="liveFeed green ml-2" *ngIf="videoData.url" [href]="videoData.url" [download]="videoData.fileName">
                            <span class="dot green"></span> &nbsp; {{ 'download' | translate }}
                        </a>
                    </h6>
                </div>
            </div>
        </div>
        <app-video *ngIf="shownVideo" [incident]="incident" [videoData]="videoData"></app-video>
        <div *ngIf="hasGalleryRight" class="mt-2 d-flex align-items-center" [ngClass]="shownGallery ? '' : 'hasBorder'">
            <div class="row w-100 d-flex align-items-center pl-3">
                <div role="button" class="pb-2" (click)="shownGallery = !shownGallery"
                    [ngClass]="shownGallery ? 'icon-cts-chevron-right rotate': 'icon-cts-chevron-right'"></div>
                <div class="col-md-10"><h6 class="font-weight-bold pt-2">{{ 'Rer.rerIncident.details.incidentGallery' | translate }}</h6>
                </div>
            </div>
        </div>
        <app-gallery *ngIf="shownGallery && hasGalleryRight" [incident]="incident"></app-gallery>
        <div class="mt-2 d-flex align-items-center" [ngClass]="shownMap ? '' : 'hasBorder'">
            <div class="row w-100 d-flex align-items-center pl-3">
                <div role="button" class="pb-2" (click)="shownMap = !shownMap"
                    [ngClass]="shownMap ? 'icon-cts-chevron-right rotate': 'icon-cts-chevron-right'"></div>
                <div class="col-md-10"><h6 class="font-weight-bold pt-2">{{ 'Rer.rerIncident.details.map' | translate }}</h6></div>
            </div>
        </div>
        <app-map *ngIf="shownMap" [incident]="incident"></app-map>
    </div>
</div>