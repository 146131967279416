<div class="search-content d-flex align-items-center">
    <div class="datatTableFilter p-2 d-flex align-items-center w-100 flex-grow-1">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.code' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" (click)="onEditClientObject(element.id)"
            title="{{ 'edit' | translate }}" class="pointer">{{ element.id | LeftPadFilter:7:'0' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.wasteType' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element" (click)="onEditClientObject(element.id)" title="{{ 'edit' | translate }}"
            class="pointer">{{ element.waste_type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="bin_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.binType' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" (click)="onEditClientObject(element.id)"
            title="{{ 'edit' | translate }}" class="pointer">{{ element.bin_type }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.status' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ 'Rer.objects.status.' + (element.status |
            rerStatusTransform:'object') | translate }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_rfid">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.rfid' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.rer_rfid }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.clients.addEdit.objects.dataTable.description' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.description }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="coords">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.coords' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">
            <a *ngIf="element.latitude && element.latitude != 0 && element.longitude && element.longitude != 0"
                href="https://www.google.com/maps/search/?api=1&query={{ element.latitude }},{{ element.longitude}}"
                target="_blank">
                {{ element.latitude + "" | shrinkfilter:8:'' }}, {{ element.longitude + "" | shrinkfilter:8:'' }}
            </a>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="datetime_read">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.latest_datetime_read' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.datetime_read | offset:true | date:this.dateFormat +
            ' HH:mm:ss' }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="vehicle_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.objects.list.dataTable.vehicle_name' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.vehicle_name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let rowIndex = index"
            style="justify-content: flex-end!important;">
            <i (click)="onDeleteClientObject(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
            <i (click)="onEditClientObject(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
