<div class="bgcolor-white p-4 m-4 position-relative form-container d-flex" style="height: calc(100% - 42px);">
    <div class="w-50 pr-2">
        <div class="search-content">
            <div class="button-component mr-2 pull-right"
                [ngClass]="selectedUserId ? 'utility-green-bg-color' : 'disabled'"
                (click)="doExportRerClientAssociationList()">
                <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="selectedUserId ? 'inner-green' : 'disabled'">
                    <span class="color-white ml-0">{{ 'Rer.rerBinSetupDocumentAssociation.exportUserAssociatedObjectsList' | translate
                        }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="search-content d-flex align-items-center flex-grow-1">
                <div class="datatTableFilter p-2 d-flex align-items-center w-100">
                    <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                    <input matInput autocomplete="off" class="flex-grow-1 search-selected-objects"
                        placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
                </div>
            </div>
            <div class="ng-select-wrapper flex-shrink-0 flex-grow-1 ml-1">
                <ng-select [items]="(clientUsersBinSetupDocumentList$ | async)" class="flex-grow-1" bindValue="id"
                    placeholder="{{ 'Rer.rerBinSetupDocumentAssociation.userList' | translate }}" [closeOnSelect]="true"
                    (change)="onClientUsersListChange($event)" appendTo="body" [multiple]="false" bindLabel="name">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span class="ml-2">{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
            <div class="ng-select-wrapper flex-shrink-0 flex-grow-1 ml-1">
                <ng-select [items]="filterListItemsUser" class="flex-grow-1"
                    placeholder="{{ 'Rer.rerBinSetupDocumentAssociation.filter.title' | translate }}"
                    (change)="onUserFilterChange($event)" appendTo="body" [multiple]="false" [clearable]="false"
                    [(ngModel)]="userFilterSelected" bindLabel="label" bindValue="id" [closeOnSelect]="true">
                    <ng-template ng-multi-label-tmp let-items="items">
                        <ng-option *ngFor="let option of items">
                            {{ ('Rer.rerBinSetupDocumentAssociation.filter.' + option.label) | translate }}
                        </ng-option>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="ml-2">{{ 'Rer.rerBinSetupDocumentAssociation.filter.' + item.label | translate }}</span>
                            <span *ngIf="item.count !== null">{{ item.count }}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="data-container">
            <ul class="pl-2 mb-0" style="border-bottom: 1px solid #eceef0; background-color: #FAFAFA;">
                <li class="pl-2  d-flex justify-content-between align-items-center disp-hover">
                    <div class="pl-2 mr-2 d-flex justify-content-between align-items-center disp-hover overflow-hidden">
                        <mat-checkbox (change)="manageCheckAllSel($event.checked)" [disabled]="!selectedUserId"
                            [checked]="isSelectAllSel">
                            <span class="ml-2 font-family-open-sans white-space-nowrap"
                                matTooltip="{{ 'selectAll' | translate }}">
                                {{ 'selectAll' | translate }}
                            </span>
                        </mat-checkbox>
                    </div>
                </li>
            </ul>
            <ng-scrollbar [ngStyle]="{'height': 'calc(100% - 0.5rem - 60px)' }">
                <ul class="pl-2">
                    <li *ngFor="let object of (userRerBinSetupDocumentList$ | async)"
                        class="pl-2  d-flex justify-content-between align-items-center disp-hover">
                        <div
                            class="pl-2 mr-2 d-flex justify-content-between align-items-center disp-hover overflow-hidden">
                            <mat-checkbox (change)="manageCheckUncheckSelected($event.checked, object.id)"
                                [checked]="checkSelectedAreaObjectsIs(object.id)">
                                <span class="ml-2 font-family-open-sans white-space-nowrap"
                                    matTooltip="{{ object.rer_client_name }} {{ object.number }}">
                                    {{ object.rer_client_name }}, {{ object.division }} {{ object.city }} {{object.address }}
                                    {{ object.street }} {{ object.street_number }}, {{ object.bin_type }}, PV nr.{{ object.number }}, 
                                    {{ object.count_bins }} {{ 'Rer.rerBinSetupDocumentAssociation.binsPlanned' | translate }},
                                    {{ object.signed_at ? ('Rer.rerBinSetupDocumentAssociation.statusSigned' | translate) : ('Rer.rerBinSetupDocumentAssociation.statusUnsigned' | translate) }} 
                                    {{ object.signed_at ? (object.signed_at | offset:true | date:dateFormat + ' HH:mm:ss') : "" }}
                                </span>
                            </mat-checkbox>
                        </div>
                    </li>
                </ul>
            </ng-scrollbar>
        </div>
        <div class="button-component m-auto"
            [ngClass]="selBinIdsToBeRemoved.length > 0 ? 'utility-green-bg-color': 'disabled'"
            (click)="updateRerClientAssociations(true)">
            <div [ngClass]="selBinIdsToBeRemoved.length > 0 ? 'inner-green' : 'inner-disabled'"
                class="inner user-no-select text-center pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.rerBinSetupDocumentAssociation.removeSelected' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="w-50 pl-2">
        <div class="search-content d-flex align-items-center">
            <div class="ng-select-wrapper" style="width: 90px;">
                <ng-select [items]="typeFilterList$ | async" placeholder="{{ 'Rer.selectType' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    [(ngModel)]="selectedTypeList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
            <div class="ng-select-wrapper ml-2" style="width: 120px;">
                <ng-select [items]="cityFilterList$ | async" placeholder="{{ 'Rer.selectCity' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    [(ngModel)]="selectedCityList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
            <div class="ng-select-wrapper ml-2">
                <ng-select [items]="divisionFilterList$ | async" placeholder="{{ 'Rer.selectDivision' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    [(ngModel)]="selectedDivisionList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
            <div class="ng-select-wrapper ml-2" style="width: 280px;">
                <ng-select [items]="streetFilterList$ | async" placeholder="{{ 'Rer.selectStreet' | translate }}"
                    appendTo="body" [multiple]="true" [clearable]="true" [closeOnSelect]="false"
                    [(ngModel)]="selectedStreetList">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <div class="ng-value" *ngIf="i < 2 && item">
                                <span class="ng-value-label">{{ item ? item.slice(0,6) : '-' }}</span>
                                <span class=" ml-1 ng-value-icon right" (click)="clear(item)"
                                    aria-hidden="true">×</span>
                            </div>
                        </ng-container>
                        <div class="ng-value" *ngIf="items.length > 2">
                            <span class="ng-value-label"> + {{ items.length - 2 }}</span>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <mat-checkbox (click)="preventEventToCheckbox($event)" [ngModel]="item$.selected">
                            <span class="ml-2">{{ item }}</span>
                        </mat-checkbox>
                    </ng-template>
                </ng-select>
            </div>
            <div class="button-component utility-green-bg-color ml-2 flex-shrink-0" (click)="onLoadList()">
                <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                    <span class="color-white ml-0">{{ 'Rer.loadList' | translate }}</span>
                </div>
            </div>
        </div>
        <div class="d-flex align-items-center">
            <div class="search-content d-flex align-items-center flex-grow-1">
                <div class="datatTableFilter p-2 d-flex align-items-center w-100">
                    <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                    <input matInput autocomplete="off" class="flex-grow-1 search-available-objects"
                        placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
                </div>
            </div>
            <div class="ng-select-wrapper flex-shrink-0 flex-grow-1 ml-1">
                <ng-select [items]="filterListItemsAll" class="flex-grow-1"
                    placeholder="{{ 'Rer.rerBinSetupDocumentAssociation.filter.title' | translate }}"
                    (change)="onBinsDocumentFilterChange($event)" appendTo="body" [multiple]="false" [clearable]="false"
                    [(ngModel)]="binSetupDocumentFilterSelected" bindLabel="label" bindValue="id" [closeOnSelect]="true">
                    <ng-template ng-multi-label-tmp let-items="items">
                        <ng-option *ngFor="let option of items">
                            {{ ('Rer.rerBinSetupDocumentAssociation.filter.' + option.label) | translate }}
                        </ng-option>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <div class="d-flex align-items-center justify-content-between">
                            <span class="ml-2">{{ 'Rer.rerBinSetupDocumentAssociation.filter.' + item.label | translate }}</span>
                            <span *ngIf="item.count !== null">{{ item.count }}</span>
                        </div>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="data-container">
            <ul class="pl-2 mb-0" style="border-bottom: 1px solid #eceef0; background-color: #FAFAFA;">
                <li class="pl-2  d-flex justify-content-between align-items-center disp-hover">
                    <div class="pl-2 mr-2 d-flex justify-content-between align-items-center disp-hover overflow-hidden">
                        <mat-checkbox (change)="manageCheckAllAv($event.checked)" [disabled]="!selectedUserId"
                            [(ngModel)]="isSelectAllPV">
                            <span class="ml-2 font-family-open-sans white-space-nowrap"
                                matTooltip="{{ 'selectAll' | translate }}">
                                {{ 'selectAll' | translate }}
                            </span>
                        </mat-checkbox>
                    </div>
                </li>
            </ul>
            <ng-scrollbar [ngStyle]="{'height': 'calc(100% - 0.5rem - 60px)' }">
                <ul class="pl-2">
                    <li *ngFor="let object of (rerBinSetupDocumentList$ | async)"
                        class="pl-2  d-flex justify-content-between align-items-center disp-hover">
                        <div
                            class="pl-2 mr-2 d-flex justify-content-between align-items-center disp-hover overflow-hidden">
                            <mat-checkbox (change)="manageCheckUncheckAv($event.checked, object.id)"
                                [disabled]="!selectedUserId" [checked]="checkAvdAreaObjectsIs(object.id)">
                                <span class="ml-2 font-family-open-sans white-space-nowrap"
                                    matTooltip="{{ object.rer_client_name }} {{ object.number }}">
                                    {{ object.rer_client_name }}, {{ object.division }} {{ object.city }} {{object.address }}
                                    {{ object.street }} {{ object.street_number }}, {{ object.bin_type }}, PV nr.{{ object.number }}, 
                                    {{ object.count_bins }} {{ 'Rer.rerBinSetupDocumentAssociation.binsPlanned' | translate }},
                                    {{ object.signed_at ? ('Rer.rerBinSetupDocumentAssociation.statusSigned' | translate) : ('Rer.rerBinSetupDocumentAssociation.statusUnsigned' | translate) }} 
                                    {{ object.signed_at ? (object.signed_at | offset:true | date:dateFormat + ' HH:mm:ss') : "" }}
                                </span>
                            </mat-checkbox>
                        </div>
                    </li>
                </ul>
            </ng-scrollbar>
        </div>
        <div class="button-component m-auto"
            [ngClass]="avBinSetupDocIdsToBeAdded.length > 0 && selectedUserId ? 'utility-green-bg-color': 'disabled'"
            (click)="updateRerClientAssociations(false)">
            <div [ngClass]="avBinSetupDocIdsToBeAdded.length > 0  && selectedUserId ? 'inner-green' : 'inner-disabled'"
                class="inner user-no-select text-center pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.rerBinSetupDocumentAssociation.addSelected' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
