import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { RerService } from '../../_service/rer.service';

import { RerRfidTagAllocation } from '../../_model/rerrfidtagallocation.model';
import { RerRfidTag } from '../../_model/rerrfidtag.model';
import { RerBin } from '../../_model/rerbin.model';
import { RerBinType } from '../../_model/rerbintype.model';
import { RerWasteType } from '../../_model/rerwastetype.model';

import { AddEditAllocateBinComponent } from './add-edit-allocate-bin/add-edit-allocate-bin.component';

@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit {
    private wasteTypeList$: Observable<RerWasteType[]>;
    private binTypeList$: Observable<RerBinType[]>;

    public wasteTypeList: RerWasteType[] = [];
    public binTypeList: RerBinType[] = [];
    public showMandatory: boolean;
    public showSpinner: boolean;
    public rfidCompleteList: any[];
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];

    @Input() bin: RerBin;
    @Input() rfidTagList: RerRfidTag[];
    @Output() closeTabEmitter: EventEmitter<number> = new EventEmitter();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    constructor(private rerService: RerService,
        public actionDialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.rfidCompleteList = JSON.parse(JSON.stringify(this.rfidTagList.map(x => Object.assign({}, x))));
        this.bin.code = (String('0').repeat(7) + this.bin.id).substr((7 * -1), 7);
        this.wasteTypeList$ = this.rerService.wasteTypeList$;
        this.binTypeList$ = this.rerService.binTypeList$;

        if (this.rfidCompleteList.find(rt => rt.id === this.bin.rer_rfid_tag_id) === undefined) {
            if (this.bin.rer_rfid_tag_id) {
                const current = Object.assign({}, new RerRfidTag(this.bin.rer_rfid_tag_id, null, this.bin.id, this.bin.rer_rfid, null, null, null, null));
                this.rfidCompleteList.unshift(current);
            }
            this.rfidCompleteList.unshift(new RerRfidTag());
        }

        this.displayedColumns = ['allocation_date', 'rer_rfid', 'username'];
        if (this.bin.rer_rfid_tag_id) {
            this.rerService.getRfidTagAllocationLog(this.bin.id).subscribe(
                (response: RerRfidTagAllocation[]) => {
                    this.dataSource = new MatTableDataSource(response);
                    this.setDataTablePaginatorSort();
                }
            );
        }

        this.wasteTypeList$.subscribe(
            (wasteType: RerWasteType[]) => {
                this.wasteTypeList = wasteType;
            }
        )
        this.binTypeList$.subscribe(
            (bintType: RerBinType[]) => {
                this.binTypeList = bintType;
            }
        )
    }

    public onAllocate(): void {
        const dialogRef = this.actionDialog.open(
            AddEditAllocateBinComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: { object: this.bin }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: RerBin) => {
                if (obj) {
                    this.rerService.editUnalocatedRerBinChangeStatus(obj).subscribe(
                        () => {
                            this.showSpinner = false;
                            this.closeTabEmitter.emit();
                        },
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            }
        );
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public onSave(): void {
        this.showMandatory = (this.bin.rer_waste_type_id && this.bin.rer_bin_type_id) ? false : true;
        const oldObjectId = this.bin.id;
        if (!this.showMandatory) {
            this.showSpinner = true;
            if (this.bin.rer_rfid_tag_id) {
                const rfidTag = this.rerService.getRfidTagById(this.bin.rer_rfid_tag_id);
                if (rfidTag) {
                    this.bin.rer_rfid = rfidTag.rfid;
                }
            }

            this.rerService.addEditUnallocatedBin(this.bin)
                .pipe(finalize(() => {
                    this.showSpinner = false;
                }))
                .subscribe(
                    () => {
                        if (!oldObjectId) {
                            this.bin.status = RerBin.STATUS_AVAILABLE;
                            this.bin.code = (String('0').repeat(7) + this.bin.id).substr((7 * -1), 7);
                        }
                    },
                    () => {
                        this.rerService.showErrorNotifications(
                            this.translationService.instant('errorNotification'));
                    }
                );
        }
    }

}
