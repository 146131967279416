import { RerSettingsComponent } from './rer-settings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedModule } from '../../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';
import { WasteTypeComponent } from './waste-type/waste-type.component';
import { BinTypeComponent } from './bin-type/bin-type.component';
import { AddWasteComponent } from './waste-type/add-waste/add-waste.component';
import { AddEditComponent } from './bin-type/add-edit/add-edit.component';
import { SettingLogsComponent } from './setting-logs/setting-logs.component';
import { AreaComponent } from './area/area.component';
import { AddAreaComponent } from './area/add-area/add-area.component';
import { AddEditOwnershipComponent } from './bin-ownership/add-edit-ownership/add-edit-ownership.component';
import { BinOwnershipComponent } from './bin-ownership/bin-ownership.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { EditEmailTemplateComponent } from './email-template/edit-email-template/edit-email-template.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { EmailSettingsComponent } from './email-settings/email-settings.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' },
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        RerSettingsComponent,
        GeneralSettingsComponent,
        WasteTypeComponent,
        BinTypeComponent,
        AddWasteComponent,
        AddEditComponent,
        SettingLogsComponent,
        AreaComponent,
        AddAreaComponent,
        BinOwnershipComponent,
        AddEditOwnershipComponent,
        EmailTemplateComponent,
        EditEmailTemplateComponent,
        EmailSettingsComponent,
        SaveDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        AngularEditorModule,
        MatTooltipModule,
        NgbTooltipModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: []
})
export class RerSettingsModule { }
