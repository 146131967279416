<h1 mat-dialog-title class="pl-2 pr-2 pt-2"> {{ 'Rer.dataExportRer.exportGeneralDetailsTitle' | translate }}
    <p class="font-size-14">{{ 'Rer.dataExportRer.exportNoSelect' | translate }}</p>
</h1>
<div mat-dialog-content>
    <div class="row ml-0 mr-0 mt-2">
        <div class="col-12 mt-4">
            <div class="ng-select-wrapper w-100" [ngClass]="{ 'ng-select-wrapper-disabled' : !data.clientTypes }">
                <ng-select placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.clientType' | translate }}" appendTo="body"
                    [(ngModel)]="clientType" [clearable]="false" name="clientType"
                    [disabled]="!data.clientTypes">
                    <ng-option *ngFor="let option of data.clientTypes" [value]="option">
                        {{ option }}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-12 mt-4">
            <div class="ng-select-wrapper w-100" [ngClass]="{ 'ng-select-wrapper-disabled' : !data.areas }">
                <ng-select placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.area' | translate }}" appendTo="body"
                    [(ngModel)]="areaId" [clearable]="false" name="areaId"
                    [disabled]="!data.areas">
                    <ng-option *ngFor="let option of data.areas" [value]="option.id">
                        {{ option.name }}</ng-option>
                </ng-select>
            </div>
        </div>
        <div class="col-12 mt-4">
            <div class="ng-select-wrapper w-100" [ngClass]="{ 'ng-select-wrapper-disabled' : !data.binOwnerships }">
                <ng-select placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.binOwnership' | translate }}" appendTo="body"
                    [(ngModel)]="binOwnershipId" [clearable]="false" name="binOwnershipId"
                    [disabled]="!data.binOwnerships">
                    <ng-option *ngFor="let option of data.binOwnerships" [value]="option.id">
                        {{ option.name }}</ng-option>
                </ng-select>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <span class="mr-3 eButton cancel pointer pb-2 pt-2 pl-3 pr-3" (click)="onCancel()">
        {{ 'cancel' | translate }}
    </span>
    <span class="mr-3 eButton pointer primary pb-2 pt-2 pl-3 pr-3" (click)="onExport()">
        {{ 'ok' | translate }}
    </span>

</div>
