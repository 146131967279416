<div class="position-relative" style="z-index: 1030">
    <mat-toolbar class="navbar navbar-expand-md bg-white header-navbar">
        <ul role="navigation" class="navbar-nav mr-auto" [ngStyle]="{'width': firstMenuWidth + 'px'}">
            <li class="nav-link height-64 nav-link-logo">
                <a href="https://cargotrack.ro/" target="_blank"><img src="{{ logoSrc }}"
                        class="vertical-align-sub"></a>
            </li>
            <li *ngFor="let item of primarymenu" class="nav-item d-none d-md-block d-lg-block d-xl-block"
                [ngClass]="{ 'position-relative': item.requiresAttention }" style="margin-left: 25px">
                <a routerLink="{{ item.route }}" routerLinkActive="{{ item.fonton }} menu-active"
                    [routerLinkActiveOptions]="{exact: false}" title="{{ 'Header.' + item.text | translate }}"
                    class="{{ item.fontoff }} nav-link" (click)="logClick(item.route)">
                </a>
                <i *ngIf="item.requiresAttention"
                    class="icon-cts-route-warning position-absolute requiresAttention font-size-20 color-orange">
                </i>
            </li>
            <li *ngIf="showmenudropdown && menudropdown.length > 0"
                class="nav-item d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                <a mat-icon-button [matMenuTriggerFor]="appMenu" class="font-size-64">
                    <i class="icon-ctl-nav-more-off nav-link"></i>
                </a>
                <mat-menu #appMenu="matMenu" class="nav-item ml-0 pl-0 bg-white w-100">
                    <div mat-menu-item class="burger-after-primary" *ngFor="let dropdownitem of menudropdown">
                        <a routerLink="{{ dropdownitem.route }}" routerLinkActive="menu-active"
                            [routerLinkActiveOptions]="{exact: true}"
                            class="dropdown-item m-0 p-0 d-flex align-items-center"
                            (click)="logClick(dropdownitem.route)">
                            <i class="font-size-64 {{ dropdownitem.fontoff }} nav-link"></i>
                            <span class="">{{ 'Header.' + dropdownitem.text | translate }}</span>
                        </a>
                    </div>
                </mat-menu>
            </li>
        </ul>
        <a mat-icon-button [matMenuTriggerFor]="appMenu" class="d-md-none d-lg-none d-xl-none font-size-64">
            <i class="icon-ctl-nav-more-on color-blue "></i>
        </a>
        <mat-menu #appMenu="matMenu" class="nav-item ml-0 d-md-none d-lg-none d-xl-none bg-white w-100">
            <div mat-menu-item class="only-burger" *ngFor="let item of primarymenu">
                <a routerLink="{{ item.route }}" routerLinkActive="menu-active"
                    [routerLinkActiveOptions]="{exact: true}" [ngClass]="{ 'color-orange': item.requiresAttention }"
                    class="nav-link burger" (click)="logClick(item.route)">
                    {{ 'Header.' + item.text | translate }}
                </a>
            </div>
            <!-- user menu  in dropdown -->
            <ng-container *ngIf="usermenudropdown">
                <hr />
                <div mat-menu-item class="only-burger" *ngFor="let item of menuconf">
                    <a routerLink="{{ item.route }}" routerLinkActive="menu-active"
                        [routerLinkActiveOptions]="{exact: true}" class="nav-link burger"
                        (click)="logClick(item.route)">
                        {{ 'Header.' + item.text | translate }}
                    </a>
                </div>
                <div mat-menu-item class="only-burger" *ngIf="hasNotificationsRights">
                    <a class="nav-link burger" routerLink="/notification" (click)="logClick('/notification')">
                        {{ 'Header.notifications' | translate }}
                    </a>
                    <i *ngIf="(notificationService.unreadUserNotificationsNumber$ | async)"
                        class="icon-cts-route-warning position-absolute notificationsRequiresAttention font-size-16 color-orange">
                    </i>
                </div>
                <div mat-menu-item class="only-burger">
                    <a class="nav-link burger" (click)="onLogout()">
                        {{ 'Header.logout' | translate }}
                    </a>
                </div>
            </ng-container>
        </mat-menu>
        <!-- right menu if usermenu not las of dropdown-->
        <ul role="navigation" *ngIf="!usermenudropdown" class="navbar-nav nav-flex-icons nav-right-menu">
            <li class="nav-item d-none d-xs-none d-sm-none d-md-block d-lg-block d-xl-block">
                <a mat-icon-button [matMenuTriggerFor]="appMenu" class="font-size-64 mr-2">
                    <i class="ctsicon-topnav-settings-off nav-link"></i>
                </a>
                <mat-menu #appMenu="matMenu" class="nav-item ml-0 pl-0 bg-white w-100">
                    <div mat-menu-item class="burger-after-primary" *ngIf="hasSettingsRights">
                        <a routerLink="/settings" routerLinkActive="menu-active"
                            [routerLinkActiveOptions]="{exact: false}"
                            class="dropdown-item m-0 p-0 d-flex align-items-center" (click)="logClick('/settings')">
                            <i class="ctsicon-topnav-settings-off nav-link"
                                routerLinkActive="ctsicon-topnav-settings-on"></i>
                            <span class="">{{ 'Header.settings' | translate }}</span>
                        </a>
                    </div>
                    <div mat-menu-item class="burger-after-primary" *ngIf="hasModulesRights">
                        <a routerLink="/packages" routerLinkActive="menu-active"
                            [routerLinkActiveOptions]="{exact: false}"
                            class="dropdown-item m-0 p-0 d-flex align-items-center" (click)="logClick('/packages')">
                            <i class="ctsicon-topnav-modules-off nav-link"
                                routerLinkActive="ctsicon-topnav-modules-on"></i>
                            <span class="">{{ 'Header.packages' | translate }}</span>
                        </a>
                    </div>
                    <div mat-menu-item class="burger-after-primary" *ngIf="hasNotificationsRights">
                        <a routerLink="/notification" routerLinkActive="menu-active"
                            [routerLinkActiveOptions]="{exact: false}"
                            class="dropdown-item m-0 p-0 d-flex align-items-center" (click)="logClick('/notification')">
                            <i class="ctsicon-topnav-notifications-off nav-link"
                                routerLinkActive="ctsicon-topnav-notifications-on"></i>
                            <i *ngIf="(notificationService.unreadUserNotificationsNumber$ | async)"
                                class="icon-cts-route-warning position-absolute notificationsRequiresAttention font-size-16 color-orange">
                            </i>
                            <span [ngClass]="{'ml-3' : (notificationService.unreadUserNotificationsNumber$ | async)}">{{
                                'Header.notifications' | translate }}</span>
                        </a>
                    </div>
                    <div mat-menu-item class="burger-after-primary">
                        <a class="dropdown-item m-0 p-0 d-flex align-items-center" (click)="onLogout()">
                            <i class="ctsicon-topnav-logout-off nav-link"></i>
                            <span class="">{{ 'Header.logout' | translate }}</span>
                        </a>
                    </div>
                    <div class="d-flex align-items-center justify-content-center align-self-center no-bullets" style="object-fit: cover; width: 100%;">
                        <span *ngIf="!user_photo" class="icon-ctl-user-no-photo font-size-42"></span>
                        <img *ngIf="user_photo" [src]="user_photo" class="profile-picture img-fluid rounded-circle"
                            style="margin-left: 10px; margin-right: 10px;" />
                        <span style="width: 250px; padding-left: 2px;"
                            class="nav-link font-size-14  text-overflow-ellipsis overflow-hidden white-space-nowrap font-weight-700">{{
                            fullname }}</span>
                    </div>
                </mat-menu>
            </li>

            <!-- <li class="nav-item" *ngIf="notifHref">
                <a class="icon-ctl-nav-notifications nav-link icon-ctl-nav-notifications-on menu-active"></a>
                <i *ngIf="(notificationService.unreadUserNotificationsNumber$ | async)"
                    class="icon-cts-route-warning position-absolute notificationsRequiresAttention font-size-16 color-orange">
                </i>
            </li>
            <li class="nav-item" *ngIf="!notifHref">
                <a class="icon-ctl-nav-notifications nav-link" mat-icon-button [matMenuTriggerFor]="menu"
                    aria-label="Notifications"></a>
                <i *ngIf="(notificationService.unreadUserNotificationsNumber$ | async) > 0"
                    class="icon-cts-route-warning position-absolute notificationsRequiresAttention font-size-16 color-orange"></i>
                <mat-menu #menu="matMenu" [overlapTrigger]="false" class="notification-menu"
                    backdropClass="customize-notification">
                    <div class="media notification-item p-0 d-flex align-items-center bgcolor-orange">
                        <div class="d-flex align-items-center justify-content-center bgcolor-orange-primary">
                            <i class="icon-cts-notification-icon font-size-24 color-white"></i>
                        </div>
                        <div class="media-body color-white text-capitalize font-size-13 ml-2 d-flex justify-content-between">
                            <span>
                                {{ notificationService.unreadUserNotificationsNumber$ | async }}
                                {{ 'Notification.unread' | translate }}
                                {{ 'Notification.topbarTitle' | translate }}
                            </span>
                            <span routerLink="/notification" (click)="logClick('/notification')" class="pointer text-uppercase mr-2">
                                See all
                            </span>
                        </div>
                    </div>
                    <div *ngFor="let notification of (userNotifications$ | async)" class="media notification-item pr-2"
                        [routerLink]="['/notification', notification.id]" (click)="logClick('/notification')">
                        <div class="d-flex align-items-center justify-content-center">
                            <i
                                class="{{ userNotificationTypes[notification.user_notification_type_id].class }} font-size-24 notification-icon"></i>
                        </div>
                        <div class="media-body text pointer ml-2 h-100 d-flex flex-column justify-content-center">
                            <p class="media-heading m-0" [ngClass]="{'font-weight-bold': !notification.date_read }">
                                {{ userNotificationTypes[notification.user_notification_type_id].name }}
                            </p>
                            <p class="m-0">{{ notification.summary }}</p>
                        </div>
                    </div>
                </mat-menu>
            </li> -->
        </ul>
    </mat-toolbar>
    <div>
        <div id="hideHeader" (click)="onToggleHeader()" class="pointer">
            <div class="iconHeaderContainer bgcolor-blue">
                <i [ngClass]="headerActive ? 'icon-cts-chevron-left': 'icon-cts-chevron-right'"
                    class="rotate color-white">
                </i>
            </div>
        </div>
    </div>

</div>