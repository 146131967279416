import { RerClientInterface } from '../_interface/rerclient.interface';

export class RerClient implements RerClientInterface {
    static readonly STATUS_ACTIVE = 1;
    static readonly STATUS_SUSPENDED_FOR_NON_PAYMENT = 2;
    static readonly STATUS_SUSPENDED_ON_REQUEST = 3;
    static readonly STATUS_TERMINATED = 4;
    static readonly STATUS_DELETED = 5;

    public constructor(
        public id = null,
        public code = null,
        public type = null,
        public name = null,
        public email = null,
        public address = null,
        public division = null,
        public city = null,
        public street = null,
        public street_number = null,
        public phone = null,
        public is_fixed_invoicing = 0,
        public status = null,
        public delegate_name = null,
        public description = null,
        public object_types_at_default_address = null,
        public rer_client_address = null,
        public rer_bins = null,
    ) { }
}
