import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shrinkfilter'
})
export class ShrinkfilterPipe implements PipeTransform {

    transform(value: string, count: number, elipsis = '...'): any {

        if (value.length > count) {
            return value.substring(0, count) + elipsis;
        }

        return value;
    }

}
