<div class="bgcolor-white position-relative form-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms">
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="ml-1 mr-2">{{ 'Rer.streetGeozones.generalInfo' | translate }}</span>
            </ng-template>
            <div style="height: calc(100% - 65px);">
                <ng-scrollbar #scrollable>
                    <div class="d-flex mt-3 max-width-724px">
                        <mat-form-field class="w-100 mr-4">
                            <input type="details" matInput type="details" placeholder="{{ 'Rer.streetGeozones.city.name' | translate }}" [(ngModel)]="city.name">
                            <span *ngIf="!city.name" matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                                class="icon-cts-information pointer font-size-18 color-red-primary align-middle" matSuffix>
                            </span>
                        </mat-form-field>
                    </div>
                </ng-scrollbar>
            </div>
            <div class="d-flex justify-content-between align-items-center button-container">
                <div class="action-buttons d-flex justify-content-between ml-2">
                    <div class="button-component utility-green-bg-color ml-2" (click)="onSaveCity()" [ngClass]="!city.name ? 'disabled' : ''">
                        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                            <span class="color-white ml-0">{{ 'save' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
                class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
                <mat-spinner></mat-spinner>
            </div>
        </mat-tab>
        <mat-tab [disabled]="city.id === undefined">
            <ng-template mat-tab-label>
                <span class="ml-1 mr-2">{{ 'Rer.streetGeozones.streets' | translate }}</span>
            </ng-template>
            <app-street-list [city]="city"></app-street-list>
        </mat-tab>
    </mat-tab-group>
</div>