import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import { finalize } from 'rxjs/operators';

import { RerService } from '../../_service/rer.service';

import { RerBin } from '../../_model/rerbin.model';
import { RerClient } from '../../_model/rerclient.model';
import { AuthService } from 'app/service/auth.service';

@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['./add-edit.component.scss']
})
export class AddEditComponent implements OnInit{

    @Input() client: RerClient;
    public showSpinner: boolean;
    public clientObjects: RerBin[];
    public hasVerbalProcessListRight: boolean = false;

    constructor(public actionDialog: MatDialog, private rerService: RerService, private translationService: TranslateService) { }

    ngOnInit() {
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_VERBAL_PROCESS)) {
            this.hasVerbalProcessListRight = true;
        }
    }

    public changeClientStatus(event) {
        this.showSpinner = true;
        this.changeRerClientStatus(event);
    }

    private changeRerClientStatus(status: number) {
        const clientJson = JSON.stringify(this.client);
        const newClient = JSON.parse(clientJson);

        this.rerService.editRerClientChangeStatus(newClient, status)
            .pipe(finalize(() => {
                this.showSpinner = false;
                this.rerService.getRerBinsList(newClient.id).subscribe(
                    (objectsList: RerBin[]) => {
                        objectsList.forEach(element => {
                            if (!element.address) {
                                element.address = element.city + ', ' + 
                                                  element.division + ' ' + 
                                                  element.street + ' ' +
                                                  element.street_number;
                            }
                        });
                        this.rerService.rerBinListChangedSubject.next({ rerBin: objectsList, clientId: newClient.id });
                    }
                );
            }))
            .subscribe({
                next:() => {
                    this.client.status = status;
                    this.rerService.rerClientChangedSubject.next(true);
                    if (this.client.status === RerClient.STATUS_ACTIVE) {
                        this.rerService.rerClientEditableSubject.next(true);
                    } else {
                        this.rerService.rerClientEditableSubject.next(false);
                    }
                    this.rerService.showSaveConfirmation(this.translationService.instant('Rer.clients.addEdit.statusChangeSuccess'));
                },
                error:(error: HttpErrorResponse) => {
                    let errorMessage = 'errorNotification';
                    switch (error.status) {
                        case StatusCodes.FAILED_DEPENDENCY:
                            errorMessage = 'Rer.clients.addEdit.failedTerminateOperation';
                            break;
                        default:
                            errorMessage = 'errorNotification';
                    }
                    this.rerService.showErrorNotifications(this.translationService.instant(errorMessage));
                }
            });
    }
}
