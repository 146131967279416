import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RerService } from '../_service/rer.service';
import { RerIncident } from '../_model/rerincident.model';
import { AuthService } from 'app/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'app/modules/model/menuitem.model';
import { faFileContract, faEnvelopeOpenText, faFileExcel, faUpload, faReceipt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-rer-collection-by-weight',
  templateUrl: './rer-collection-by-weight.component.html',
  styleUrls: ['./rer-collection-by-weight.component.scss']
})
export class RerCollectionByWeightComponent implements OnInit {

  public incidentDetailsTabs: RerIncident[] = [];
  public activeTab: number;
  public selectedTabIndex = 0;
  public hasDetailsRight: boolean = false;
  public geofencesRight: boolean;
  public deviceGroupRight: boolean;
  public eventsSetupRight: boolean;
  public toNavigate: string;
  public menuitems: MenuItem[];
  public faFileContract = faFileContract;
  public faEnvelopeOpenText = faEnvelopeOpenText;
  public faFileExcel = faFileExcel;
  public faReceipt = faReceipt;
  public faUpload = faUpload;

  constructor(
    private authService: AuthService,
    public actionDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private rerService: RerService) { }

  ngOnInit() {
    this.toNavigate = this.route.pathFromRoot[this.route.pathFromRoot.length - 1].firstChild ? this.route.pathFromRoot[this.route.pathFromRoot.length - 1].firstChild.snapshot.url[0].path : null;
    // this.router.navigate([{ outlets: { rercollectionbyweight: this.toNavigate ? [this.toNavigate] : ['collection-decisions-by-weight'] } }]);
  }

  public hasRight(option: String) {
    return this.authService.userModulesOptions.rer
      .find((item: string) => item === option);
  }
}
