import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedModule } from 'app/shared/shared.module';

import { RouteComponent } from './route/route.component';
import { ListComponent } from './list/list.component';
import { RouteItemComponent } from './route-item/route-item.component';
import { StreetListComponent } from './street-list/street-list.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
      { prefix: './assets/i18n/', suffix: '.json' },
      { prefix: './assets/i18n/rer/', suffix: '.json' },
  ]);
}

@NgModule({
  declarations: [
    RouteComponent,
    ListComponent,
    RouteItemComponent,
    StreetListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        isolate: true
    }),
  ]
})
export class RouteModule { }
