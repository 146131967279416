<h4 mat-dialog-title class="p-2 header font-size-18">
    {{ 'Rer.rerCollectionByWeight.unloadingArea.addArea' | translate }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="col-md-12 pb-2">
        <mat-form-field class="w-100 mt-1">
            <input matInput autocomplete="off" type="details" [(ngModel)]="data.unloadingArea.name"
                placeholder="{{ 'Rer.rerCollectionByWeight.unloadingArea.name' | translate }}" name="name">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                [ngClass]="showMandatory && !data.unloadingArea.name ? 'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                matSuffix>
            </span>
        </mat-form-field>
    </div>
    <div class="col-md-12 pb-2">
        <mat-form-field class="w-100 mt-1">
            <input matInput autocomplete="off" [matAutocomplete]="auto" class="shapeAddress" type="details"
                [(ngModel)]="addressFormated"
                placeholder="{{ 'Rer.rerCollectionByWeight.unloadingArea.address' | translate }}">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                [ngClass]="showMandatory && !data.unloadingArea.address? 'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'"
                matSuffix>
            </span>
        </mat-form-field>
    </div>
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let suggestions of autocompleteAddressSuggestions.group[0].suggestions"
            [value]="suggestions.formatted_address" (onSelectionChange)="onClickAddressAutocomplete(suggestions)">
            <i class="fi fi-{{ suggestions.country_code }}">
            </i>
            <span>{{ suggestions.formatted_address }}</span>
        </mat-option>
    </mat-autocomplete>
    <div #gmap style="height: 550px!important;" class="h-100 flex-grow-1"></div>
</div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex align-items-center justify-content-between pt-2">
        <div class="button-component bgcolor-white" (click)="onCancel()">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div class="button-component ml-4 utility-green-bg-color" (click)="onGeozoneSave()">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4 inner-green">
                <span class="color-white ml-0">{{ 'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>