<div class="search-content d-flex align-items-center mt-2">
    <div class="datatTableFilter mb-1 mr-1 mb-2 p-2 d-flex align-items-center w-80 flex-grow-1">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerEmployee.list.quickSearch' | translate }}">
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.emailTemplate.id' | translate}} </mat-header-cell>
        <mat-cell class="pl-0" *matCellDef="let element">{{ element.id}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.emailTemplate.name_template' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.label || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.emailTemplate.subject' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.subject || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="updated_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.emailTemplate.updated_at' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.updated_at | offset:false | date: dateFormat }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="updated_by">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerSettings.emailTemplate.updated_by' | translate}} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element">{{ element.updated_by }}
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <i (click)="editEmailTemplate(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer"></i>
        </mat-cell>
    </ng-container>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute"
    style="height: 100%; top: 0; width: calc(100% - 100px);">
    <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
</div>
