import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';

import { RerEmployee } from '../../_model/reremployee.model';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  public employeeList$: Observable<RerEmployee[]>;
  public employeeType: any;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[];
  public selectedTypeList: string[] = [];
  public showSpinner: boolean = false;
  public currentFilterValue: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() openTabsNumber: number;

  @Output() editEmployeeEventEmitter: EventEmitter<number> = new EventEmitter();
  @Output() deleteEmployeeEventEmitter: EventEmitter<number> = new EventEmitter();
  @Output() loadEmployeeListEventEmitter: EventEmitter<number> = new EventEmitter();

  constructor(private rerService: RerService, 
    public dialog: MatDialog,
    private translationService: TranslateService) { }

  ngOnInit() {
    this.employeeList$ = this.rerService.employeeList$;
    this.employeeType = this.rerService.employeeType;
    this.displayedColumns = ['type', 'name', 'phone', 'tag1', 'tag2', 'tag3', 'actions'];
    this.showSpinner = true;
    this.employeeList$.subscribe(
      (employee: RerEmployee[]) => {
        this.dataSource = new MatTableDataSource(employee);
        this.setDataTablePaginatorSort();
        if (this.currentFilterValue) {
          this.applyFilter(this.currentFilterValue);
        }
        this.showSpinner = false;
      }
    );
  }

  ngAfterViewInit(): void {
    this.setDataTablePaginatorSort();
  }

  private setDataTablePaginatorSort(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public editEmployee(id: number): void {
    this.editEmployeeEventEmitter.emit(id);
  }

  public deleteEmployee(id: number): void {
    this.deleteEmployeeEventEmitter.emit(id);
  }

  public preventEventToCheckbox(event): void {
    event.preventDefault();
  }

  public onLoadList(): void {
    if (this.openTabsNumber) {
      const dialogRef = this.dialog.open(
        ConfirmActionComponent,
        {
          width: '450px',
          panelClass: 'custom-dialog-container',
          data: {
            headerMessage: this.translationService.instant('Rer.confirmCloseTabs.title'),
            contentMessage: this.translationService.instant('Rer.confirmCloseTabs.msg')
          }
        }
      );
      dialogRef.afterClosed().subscribe(
        (response) => {
          if (response) {
            this.loadEmployeeListEventEmitter.emit();
            this.filterData();
          }
        }
      );
    } else {
      this.filterData();
    }
  }

  private filterData() {
    this.showSpinner = true;
    this.employeeList$.subscribe(
      (employee: RerEmployee[]) => {
        if (this.selectedTypeList.length == 0) {
          this.dataSource = new MatTableDataSource(employee);
        } else {
          const filteredType = employee.filter((employee: RerEmployee) => this.selectedTypeList.includes(employee.type.toString()));
          this.dataSource = new MatTableDataSource(filteredType);
        }
        this.setDataTablePaginatorSort();
        this.showSpinner = false;
      }
    );
  }
}
