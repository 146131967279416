import { ExportPeriodDialogComponent } from './data-export-rer/export-period-dialog/export-period-dialog.component';
import { RerBinGuard } from '../service/guard/rer-bin-guard.service';
import { RerClientGuard } from '../service/guard/rer-client-guard.service';
import { RerPlanningModule } from './rer-planning/rer-planning.module';
import { RerPlanningGuard } from '../service/guard/rer-planning-guard.service';
import { SharedModule } from 'app/shared/shared.module';
import { BinSuspendDialogComponent } from './bin-suspend-dialog/bin-suspend-dialog.component';
import { DataExportRerGuard } from '../service/guard/data-export-rer-guard.service';
import { NgModule, } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RerRoutingModule } from './rer-routing.module';
import { ObjectsAssociationModule } from './bins-association/bins-association.module';
import { DataImportRerModule } from './data-import-rer/data-import-rer.module';
import { DataExportRerModule } from './data-export-rer/data-export-rer.module';
import { ClientModule } from './rer-client/rerclient.module';
import { ObjectModule } from './rer-bin/rerbin.module';
import { MapService } from '../service/shared/map.service';
import { RerComponent } from './rer.component';
import { LeftsidebarComponent } from './leftsidebar/leftsidebar.component';
import { RerClientAssociationsGuard } from '../service/guard/rer-client-association-guard.service';
import { RerBinSetupDocumentGuard } from '../service/guard/rer-bin-setup-document-guard.service';
import { DataImportRerGuard } from '../service/guard/data-import-rer-guard.service';
import { RfidTagsManagementGuard } from '../service/guard/rfid-tags-management-guard.service';
import { RfidTagModule } from './rfid-tag/rfid-tag.module';
import { UnallocatedObjectModule } from './unallocated-bin/unallocated-bin.module';
import { UnallocatedRerBinGuard } from '../service/guard/unallocated-rer-bin-guard.service';
import { RerEmployeeModule } from './rer-employee/rer-employee.module';
import { RerEmployeeGuard } from '../service/guard/rer-employee-guard.service';
import { RerDashboardModule } from './rer-dashboard/rer-dashboard.module';
import { RerDashboardGuard } from '../service/guard/rer-dashboard.service';
import { RerSettingsModule } from './rer-settings/rer-settings.module';
import { RerSettingsGuard } from '../service/guard/rer-setting.service';
import { RerIncidentModule } from './rer-incident/rer-incident.module';
import { RerIncidentGuard } from '../service/guard/rer-incident.service';
import { RerCollectionByWeightGuard } from '../service/guard/rer-collection-by-weight.service';
import { RerCollectionByWeightModule } from './rer-collection-by-weight/rer-collection-by-weight.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RerBinSetupDocumentsAssociationModule } from './bins-setup-document-association/bins-setup-document-association.module';
import { RerVerbalProcessModule } from './rer-verbal-process/rer-verbal-process.module';
import { RerVerbalProcessGuard } from '../service/guard/rer-verbal-process.service';
import { RerStreetGeozonesGuard } from '../service/guard/rer-street-geozones-guard.service';
import { RerRouteGuard } from '../service/guard/rer-route-guard.service';
import { RerRoutePlanningSearchGuard } from '../service/guard/rer-route-planning-search-guard.service';
import { StreetGeozonesModule } from './street-geozones/street-geozones.module';
import { RoutePlanningSearch } from './route-planning-search/route-planning-search.module';
import { RouteModule } from './route/route.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' },
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        RerComponent,
        LeftsidebarComponent,
        BinSuspendDialogComponent,
        ExportPeriodDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        AngularEditorModule,
        RerRoutingModule,
        ClientModule,
        ObjectModule,
        SharedModule,
        UnallocatedObjectModule,
        ObjectsAssociationModule,
        RerBinSetupDocumentsAssociationModule,
        DataImportRerModule,
        DataExportRerModule,
        RfidTagModule,
        MatTooltipModule,
        RerEmployeeModule,
        RerPlanningModule,
        StreetGeozonesModule,
        RoutePlanningSearch,
        RouteModule,
        RerDashboardModule,
        RerSettingsModule,
        RerIncidentModule,
        RerCollectionByWeightModule,
        RerVerbalProcessModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        })
    ],
    providers: [
        UnallocatedRerBinGuard,
        DataImportRerGuard,
        DataExportRerGuard,
        DataExportRerModule,
        RfidTagsManagementGuard,
        RerClientAssociationsGuard,
        MapService,
        RerEmployeeGuard,
        RerPlanningGuard,
        RerClientGuard,
        RerBinGuard,
        RerDashboardGuard,
        RerSettingsGuard,
        RerIncidentGuard,
        RerCollectionByWeightGuard,
        RerBinSetupDocumentGuard,
        RerVerbalProcessGuard,
        RerStreetGeozonesGuard,
        RerRouteGuard,
        RerRoutePlanningSearchGuard
    ]
})
export class RerModule { }
