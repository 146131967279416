<div class="search-content mr-1">
    <div class="row mt-2 w-100 mx-0">
        <div class="col-12 pl-0 pr-0">
            <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
                <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
                    class="flex-grow-1" placeholder="{{ 'Rer.streetGeozones.list.quickSearch' | translate }}">
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.streetGeozones.list.dataTable.name' | translate }} </mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" style="justify-content: flex-end!important;">
            <i title="{{ 'delete' | translate }}" class="icon-tmss-list-action-delete font-size-14 pointer" [ngClass]="{'cursor-not-allowed' : element.street && element.street.length > 0}"
                (click)="onDeleteCity(element)" [class.pointer]="!element.street || element.street.length === 0"></i>
            <i (click)="onEditCity(element.id)" title="{{ 'edit' | translate }}" class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator class="mr-1" #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div *ngIf="showSpinner" class="d-flex align-self-center justify-content-center position-absolute"
    style="height: 100%; top: 0; width: calc(100% - 100px);">
    <mat-spinner style="position: absolute;top: calc(50% - 50px); z-index: 1000;"></mat-spinner>
</div>