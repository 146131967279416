import { UserPreferencesService } from '../../../../service/userpreferences.service';
import { RerRfidTag } from '../../_model/rerrfidtag.model';
import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RerService } from '../../_service/rer.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, AfterViewInit {

    public rfidTagList$: Observable<RerRfidTag[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public selectedRfidTag: string[] = [];
    public selectedDivisionList: string[] = [];
    public selectedTypeList: string[] = [];
    public showSpinner: boolean;
    public currentFilterValue: string = null;
    public dateFormat: string = null;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    @Input() openTabsNumber: number;

    @Output() editRfidTagEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() deleteRfidTagEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(private rerService: RerService, 
        public dialog: MatDialog,
        private userPreferencesService: UserPreferencesService
    ) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        this.rfidTagList$ = this.rerService.rfidTagList$;
        this.displayedColumns = ['rfid', 'created_at', 'created_by_name', 'updated_at', 'updated_by_name', 'actions'];
        this.rfidTagList$.subscribe(
            (rfidTags: RerRfidTag[]) => {
                this.dataSource = new MatTableDataSource(rfidTags);
                this.setDataTablePaginatorSort();
                if (this.currentFilterValue) {
                    this.applyFilter(this.currentFilterValue);
                }
            }
        );
    }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onEditRfidTag(id: number): void {
        this.editRfidTagEventEmitter.emit(id);
    }

    public onDeleteRfidTag(id: number): void {
        this.deleteRfidTagEventEmitter.emit(id);
    }

}
