import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../../_service/rer.service';

import { NotificationComponent } from 'app/shared/notification/notification.component';
import { TagImport } from 'app/modules/rer/_model/tagimport.model';

@Component({
    selector: 'app-tags-final-import',
    templateUrl: './tags-final-import.component.html',
    styleUrls: ['./tags-final-import.component.scss']
})
export class TagsFinalImportComponent implements AfterViewInit {
    public emptyLines = [];
    public duplicateRfids = [];
    public requestData: TagImport[] = [];
    public dataSource: MatTableDataSource<any>;
    public displayedColumns = [
        'line',
        'rfid',
    ];

    @Input() set dataList(value: any) {
        let data = [];
        if (value && Object.keys(value).length) {
            Object.keys(value).forEach(element => {
                if (value[element] && value[element].length) {
                    let dataToPush = value[element].map(x => {
                        if (this.requestData.find(rfid => rfid.rfid == x)) {
                            if(!this.duplicateRfids.find(element => element == x)) {
                                this.duplicateRfids.push(x);
                            }
                            return false;
                        } else {
                            this.requestData.push(new TagImport(x));
                            return { line: element, rfid: x }
                        }

                    }).filter(x => x != false);
                    data = data.concat(dataToPush);
                } else {
                    this.emptyLines.push(element);
                }
            });
        }
        this.dataSource = new MatTableDataSource(data);
        this.setDataTablePaginatorSort();
    }
    @Output() cancelImportEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() importDataEventEmitter: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;


    constructor(private rerService: RerService,
        public matDialog: MatDialog,
        private route: Router,
        private translationService: TranslateService,) { }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onDoImport(): void {
        if (!this.requestData && !this.requestData.length) {
            this.importDataEventEmitter.next(false);
            this.matDialog.open(
                NotificationComponent,
                {
                    panelClass: 'custom-dialog-container',
                    maxWidth: '480px',
                    data: {
                        headermessage: this.translationService.instant('error'),
                        message: this.translationService.instant('generalError'),
                        success: 0
                    }
                }
            );
        } else {
            this.importDataEventEmitter.next(true);
            this.rerService.importRerTagData({ importData: this.requestData }).subscribe(
                () => {
                    this.matDialog.open(
                        NotificationComponent,
                        {
                            maxWidth: '510px',
                            panelClass: 'custom-dialog-container',
                            data: {
                                success: 1,
                                headermessage: this.translationService.instant('Rer.dataImportRer.importSuccessHeader'),
                                message: this.translationService.instant('Rer.dataImportRer.importSuccessMessage')
                            }
                        }
                    );
                    this.route.navigate(['rer/management', { outlets: { rercontent: ['rfid-management'] } }]);
                },
                () => {
                    this.importDataEventEmitter.next(false);
                    this.matDialog.open(
                        NotificationComponent,
                        {
                            panelClass: 'custom-dialog-container',
                            maxWidth: '480px',
                            data: {
                                headermessage: this.translationService.instant('error'),
                                message: this.translationService.instant('generalError'),
                                success: 0
                            }
                        }
                    );
                }
            );
        }
    }
}
