<div class="companycontainer">
    <div class="row ml-0 mr-0">
        <div class="col-md-6 pl-0">
            <span class="summary-text">{{ 'Rer.rerSettings.emailSettings.smtpEmailAddress' | translate }}</span>
        </div>
    </div>
    <div class="customMail mt-4">
        <div class="col-12 col-md-4 ml-0">    
            <div class="row">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput required 
                            placeholder="{{ 'Rer.rerSettings.emailSettings.smtpEmailAddress' | translate }}"
                            [(ngModel)]="smtp_email_address" (ngModelChange)="dataChanged()"> 
                            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                                [ngClass]="(showMandatory && !smtp_email_address) || (showFormatMandatory && smtp_email_address) ?
                                    'icon-cts-information pointer font-size-16 color-red-primary align-middle': 'align-middle'" matSuffix>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100 height-40">
                        <input [type]="hide ? 'password' : 'text'" class="password" matInput required
                            placeholder="{{ 'Rer.rerSettings.emailSettings.smtpEmailPassword' | translate }}"
                            [(ngModel)]="smtp_email_password" (ngModelChange)="dataChanged()">
                            <mat-icon matSuffix (click)="hide = !hide"><fa-icon [icon]="hide ? faEyeSlash : faEye" class="password-state-icon"></fa-icon></mat-icon>
                            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                                [ngClass]="showMandatory && !smtp_email_password ?
                                        'icon-cts-information pointer font-size-16 color-red-primary ml-2': 'align-middle'" matSuffix>
                            </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="details" matInput required 
                            placeholder="{{ 'Rer.rerSettings.emailSettings.smtpHost' | translate }}"
                            [(ngModel)]="smtp_host" (ngModelChange)="dataChanged()">
                        <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                            [ngClass]="showMandatory && !smtp_email_password ?
                                'icon-cts-information pointer font-size-16 color-red-primary ml-2': 'align-middle'" matSuffix>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="number" matInput required 
                            placeholder="{{ 'Rer.rerSettings.emailSettings.smtpPort' | translate }}"
                            [(ngModel)]="smtp_port" (ngModelChange)="dataChanged()">
                        <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'"
                            [ngClass]="showMandatory && !smtp_email_password ?
                                'icon-cts-information pointer font-size-16 color-red-primary ml-2': 'align-middle'" matSuffix>
                        </span>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 pl-0">
                    <mat-form-field class="w-100">
                        <input type="number" matInput readonly 
                            placeholder="{{ 'Rer.rerSettings.emailSettings.failedCounter' | translate }}"
                            [value]="smtp_failed_counter">
                    </mat-form-field>
                </div>
            </div>
            <div class="mt-4 pl-0">
                <div *ngIf="showMandatory">
                    <i class="icon-cts-information font-size-16 color-red-primary align-middle mr-1"></i>
                    <span class="align-middle"> {{ 'mandatoryFieldText' | translate }}</span>
                </div>
                <div *ngIf="showFormatMandatory" class="mt-1">
                    <i class="icon-cts-information font-size-16 color-red-primary align-middle mr-1"></i>
                    <span class="align-middle"> {{ 'invalidFormatFieldText' | translate }}</span>
                </div>
                <div *ngIf="smtp_verified == false" class="mt-1">
                    <i class="icon-cts-information font-size-16 color-red-primary align-middle mr-1"></i>
                    <span class="align-middle"> {{ 'Rer.rerSettings.emailSettings.testMandatory' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex align-items-center button-container mt-4">
    <div class="button-component utility-green-bg-color" (click)="saveEmailSettings()">
        <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0" [ngClass]="showMandatory || showFormatMandatory ? 'disable': 'pointer primary'">
                {{ 'save' | translate }}
            </span>
        </div>
    </div>
    <div class="button-component utility-blue-bg-color ml-2" (click)="onTestMail()">
        <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
            <span class="color-white ml-0" [ngClass]="showMandatory || showFormatMandatory ? 'disable': 'pointer primary'">
                {{ 'Rer.rerSettings.emailSettings.testSettings' | translate }}
            </span>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>