
export function convertUTCDateToLocalDate(datetime: Date): string | any {
    const newDate = new Date(datetime.getTime() + datetime.getTimezoneOffset()*60*1000);
    const offset = datetime.getTimezoneOffset() / 60;
    const hours = datetime.getHours();

    newDate.setHours(hours - offset);

    return newDate;   
}
