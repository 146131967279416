<div class="bgcolor-white p-2 m-4 position-relative rerplannings-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms">
        <mat-tab *ngIf="this.rerService.hasRight('rer-clients-import')">
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-partners font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.dataImportRer.clientsImport.tabTitle' | translate }}
                </span>
            </ng-template>
            <app-clients-import></app-clients-import>
        </mat-tab>
        <mat-tab *ngIf="this.rerService.hasRight('rer-tags-import')">
            <ng-template mat-tab-label>
                <i class="ctsicon-fap-rfid font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.dataImportRer.tagsImport.tabTitle' | translate }}
                </span>
            </ng-template>
            <app-tags-import></app-tags-import>
        </mat-tab>
        <mat-tab *ngIf="this.rerService.hasRight('rer-bin-setups-import')">
            <ng-template mat-tab-label>
                <i class="ctsicon-fap-rfid font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.dataImportRer.binSetupImport.tabTitle' | translate }}
                </span>
            </ng-template>
            <app-bin-setup-import></app-bin-setup-import>
        </mat-tab>
    </mat-tab-group>
</div>