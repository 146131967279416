import { Injectable } from '@angular/core';
import { noop, Observable } from 'rxjs';

import { UserNotificationInterface } from 'app/modules/notification/_interface/usernotification.interface';

@Injectable({ providedIn: 'root' })
export class PushNotificationsService {

    public permission: Permission;

    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    public requestPermission(): void {
        if ('Notification' in window) {
            Notification.requestPermission( (status) => this.permission = status);
        }
    }

    public create(title: string, options?: PushNotification): any {
        return new Observable( (obs) => {
            if (!('Notification' in window)) {
                obs.complete();
            }
            if (this.permission !== 'granted') {
                obs.complete();
            }

            const _notify = new Notification(title, options);

            _notify.onshow =  (e) => obs.next({ notification: _notify, event: e });
            _notify.onclick =  (e) => obs.next({ notification: _notify, event: e });
            _notify.onerror =  (e) => obs.error({ notification: _notify, event: e });
            _notify.onclose =  () => obs.complete();
        });
    }

    public generateNotification(notif: UserNotificationInterface): void {
        const options = { body: notif.title, icon: 'assets/images/icon/megaphone.png'};
        this.create(notif.body, options).subscribe(noop, noop);
    }
}

export declare type Permission = 'denied' | 'granted' | 'default';
export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
}
