
export const environment = {
    production: true,
    projectName: 'cargotrack',
    appTitle: 'CargoTrack',
    ctsLoginUrl: 'https://cts.cargotrack.ro/',
    isRandomBackgroundAtSignin: true,
    isGoogleMapsAvailable: true,
    googleapisVersion: '3.44',
    googleapisKey: 'AIzaSyCvpfD6NGFg2oV8nsGoBOM_4Q9UMtZgjMs',
    googleapisLibraries: 'geometry,drawing',
};
