<div class="search-content d-flex align-items-center">
    <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
            class="flex-grow-1" placeholder="{{ 'Rer.rerIncident.list.quickSearch' | translate }}">
    </div>
    <div class="col-12 mb-4 mb-sm-0 col-sm-6 col-md-2 pl-2 ">
        <div class="button-component utility-green-bg-color ml-2" (click)="loadIncidentsByPeriod()"
            style="width: fit-content; float: right;" >
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4 height-42">
                <span class="color-white ml-0">{{ 'Rer.rerIncident.list.loadIncidents' | translate }}</span>
            </div>
        </div>
    </div>
    <div class="col-12 mb-4 mb-sm-0 col-sm-6 col-md-2 col-lg-1 pl-2">
        <div class="button-component utility-green-bg-color" (click)="doExportIncident()" style="width: 130px;">
            <div class="inner user-no-select text-center inner-green pb-2 pt-2 pl-4 pr-4 height-42">
                <span class="color-white ml-0">{{ 'Rer.rerIncident.list.exportxls' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
    <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header st>
            {{ 'Rer.rerIncident.list.dataTable.id' | translate }} </mat-header-cell>
        <mat-cell class="pl-2" *matCellDef="let element" (click)="detailsIncident(element.id)"
            title="{{ 'edit' | translate }}" class="pointer">{{ element.id }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="incident_time">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.incident_time' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.incident_time | offset: true | date: dateFormat + ' HH:mm:ss'}}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.status' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">
            {{ element.status_string | translate }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="incident_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.incident_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.incident_type_name || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.location' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{ element.location ||
            '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="route_code">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.route_code' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.route_code || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="rer_waste_type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.rer_waste_type' | translate}} </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
            element.rer_waste_type || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="device_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.device_name' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
                element.device_name || '-' }}
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="main_employee_name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.main_employee_name' | translate}}
        </mat-header-cell>
        <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
           element.main_employee_name || '-' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="responsible">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'Rer.rerIncident.list.dataTable.responsible' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element">{{
                element.responsible_name || '-' }}
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="evaluation">
        <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center!important;">
            {{ 'Rer.rerIncident.list.dataTable.evaluation' | translate}} </mat-header-cell>
            <mat-cell class="pl-2 font-size-24" *matCellDef="let element" style="justify-content: center!important;" [ngSwitch]="element.evaluation">
                <span class="badge badge-success" *ngSwitchCase="'0'">
                    {{ element.evaluation_string }}
                </span>
                <span class="badge badge-warning" *ngSwitchCase="'1'">
                    {{ element.evaluation_string }}
                </span>
                <span class="badge badge-danger" *ngSwitchCase="'2'">
                    {{ element.evaluation_string }}
                </span>
            </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let rowIndex = index" style="justify-content: flex-end!important;">
            <i (click)="detailsIncident(element.id)" title="{{ 'edit' | translate }}"
                class="icon-tmss-list-action-edit font-size-14 pointer ml-2 pr-2"></i>
            <i *ngIf="hasDeleteRight" (click)="deleteIncident(element.id)" title="{{ 'delete' | translate }}"
                class="icon-tmss-list-action-delete font-size-14 pointer"></i>
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
    </mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
    </mat-row>
</mat-table>
<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
</mat-paginator>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
