import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { EndpointsService } from '../../../service/endpoints.service';

import { RouteElement } from '../../model/route/routeelement.model';
import { Route } from '../../model/route/route.model';

@Injectable()
export class RouteService {


    public deviceChangedSubject = new Subject<string>();
    public routeEditedSubject = new Subject<Route>();

    constructor( private httpClient: HttpClient, public translateService: TranslateService, private endpointsService: EndpointsService) { }


    private adjustRoute(routes: any[]): any[] {
        routes.forEach(
            route => {
                route.routes.forEach(
                    (rt: any) => {
                        rt.options = JSON.parse(rt.options);
                        rt.path = JSON.parse(rt.path);
                    }
                );
                route.color = '#' + Math.random().toString(16).substr(-6);
            }
        );

        return routes;
    }

    public setDateOffset(date_send, requestToServer = false, timeformat = 'YYYY-MM-DD HH:mm:ss') {

        if (!date_send) {
            return '';
        }

        if (typeof date_send !== 'string') {
            date_send = date_send.toString();
        }
        const offset = new Date().getTimezoneOffset();
        const date = new Date(date_send);
        if (requestToServer) {
            date.setMinutes(date.getMinutes() + offset);
        } else {
            date.setMinutes(date.getMinutes() - offset);
        }
        return moment(date).format(timeformat);
    }

    public getCountryCodeFromAddressComponents(components: any): String {
        for (const component of components) {
            if (component.types.indexOf('country') !== -1) {
                return component.short_name.toLowerCase();
            }
        }

        return '';
    }

    public checkForActiveShipping(deviceId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(
            this.endpointsService.get('route.checkactiveshipping', [deviceId, moment().format('YYYY-MM-DD HH:mm:ss')]));
    }

    public getVehicleLastDeliveryPosition(deviceId: number): Observable<{ delivery_address: string, delivery_position: string }> {
        return this.httpClient.get<{ delivery_address: string, delivery_position: string }>
            (this.endpointsService.get('route.lastdeliveryposition', [deviceId]));
    }

    public getLocale(): String {
        const lang = localStorage.getItem('currentLang');
        return (lang) ? lang.substring(0, 2) : 'ro';
    }

    public checkIfAfterDates(route: RouteElement[]): boolean {
        let mandatoryRouteDates: boolean;
        for (let index = 2; index < route.length; index++) {

            mandatoryRouteDates = moment(route[index].datetime_begin).isAfter(route[index - 1].datetime_begin);
            if (mandatoryRouteDates === false) {
                break;
            }
        }

        return !mandatoryRouteDates;
    }

    public getTranslationFor(label: string, value = {}): string {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public createRoute(route: Route): Observable<string> {

        return this.httpClient.post<string>(this.endpointsService.get('route.create'), route);
    }

    public getVisibleRoutes(): Observable<Route[]> {

        return this.httpClient.get<Route[]>(this.endpointsService.get('route.getvisibleroutes')).pipe(map(
            routes => this.adjustRoute(routes)
        ));
    }

    public getDeviceRoutes(deviceId: number): Observable<Route[]> {

        return this.httpClient.get<Route[]>(
                this.endpointsService.get('route.getdeviceroutes', [deviceId])
            ).pipe(map(
                routes => this.adjustRoute(routes)
            ));
    }

    public deleteRoute(routeId: number): Observable<number> {

        return this.httpClient.delete<number>(this.endpointsService.get('route.delete', [routeId]));
    }

}
