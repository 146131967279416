<div class="row pb-2 pt-2 pr-2">
    <div class="col-7" style="overflow-x:auto">
        <table>
            <tr class="table table-bordered font">
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.route_code' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.route' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.waste_type' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.device_name' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.period_begin' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.period_end' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.area' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.main_employee_name' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.notes' | translate }}</th>
              <th scope="col">{{ 'Rer.rerPlanning.dataTable.executionPercentage' | translate }}</th>
            </tr>
            <tr class="font-row">
                <td>{{ planning.route_code }}</td>
                <td>{{ planning.rer_route?.name }}</td>
                <td>{{ planning.rer_waste_type_id ? getWasteTypeName(planning.rer_waste_type_id) : "-"}}</td>
                <td>{{ planning.device_name }}</td>
                <td>{{ planning.period_begin | offset: true | date: "HH:mm" }}</td>
                <td>{{ planning.period_end | offset: true | date: "HH:mm" }}</td>
                <td>{{ planning.area }}</td>
                <td>{{ planning.main_employee_name }}</td>
                <td>{{ planning.notes }}</td>
                <td>{{ executionPercentage }}% <fa-icon [icon]="faEye" (click)="onRoutePlanningExecutionView()" class="ml-2 pointer"></fa-icon></td>
              </tr>
        </table>
    </div>
    <div class="col-5">
        <div class="row">
            <div class="col-md-4 col-sm-4 reading-buttons pl-1">
                <button class="btn button-component ml-2 bgcolor-blue" 
                    (click)="changeReadingsFilter(null)">
                    <div class="inner inner-blue user-no-select pb-0 pt-0 pl-4 pr-4">
                        <span class="color-white ml-4 mr-4 font-size-16"> {{ 'Rer.rerPlanning.readingsTotal' | translate }}</span>
                        <p class="color-white m-0 font-size-16 font-weight-bold text-uppercase">{{ 'Rer.rerPlanning.readings' | translate }}</p>
                        <p class="color-white m-0 font-size-16 font-weight-bold">{{ readingsCount.total }}</p>
                    </div>
                </button>
            </div>
            <div class="col-md-4 col-sm-4 reading-buttons pl-1">
                <button class="btn button-component ml-2 utility-green-bg-color" 
                    (click)="changeReadingsFilter(true)">
                    <div class="inner inner-green user-no-select pb-0 pt-0 pl-4 pr-4">
                        <span class="color-white ml-0 font-size-16"> {{ 'Rer.rerPlanning.readingsInvoiceable' | translate }}</span>
                        <p class="color-white m-0 font-size-16 font-weight-bold">{{ readingsCount.invoiceable }}</p>
                    </div>
                </button>
            </div>
            <div class="col-md-4 col-sm-4 reading-buttons pl-1 mr-0 pr-0">
                <button class="btn button-component ml-2 utility-orange-bg-color" 
                    (click)="changeReadingsFilter(false)">
                    <div class="inner inner-orange user-no-select pb-0 pt-0 pl-4 pr-4">
                        <span class="color-white ml-0 font-size-16"> {{ 'Rer.rerPlanning.readingsInvalid' | translate }}</span>
                        <p class="color-white m-0 font-size-16 font-weight-bold">{{ readingsCount.invalid }}</p>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
<app-map [planning]="planning"  (changeDateEventEmitter)="changePlanningDate($event)" (lastTransmitionEventEmitter)="onChangeLastTransmition($event)"
    (readingsCountEventEmitter)="onChangeReadingsCount($event)"></app-map>