import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { UserPreferencesService } from 'app/service/userpreferences.service';
import { RerStatusTransformPipe } from '../../../_pipe/rerStatusTransform.pipe';
import { RerService } from '../../../_service/rer.service';

import { RerClientChanges } from '../../../_model/rerclientchanges.model';
import { RerClient } from '../../../_model/rerclient.model';

@Component({
    selector: 'app-client-changes',
    templateUrl: './client-changes.component.html',
    styleUrls: ['./client-changes.component.scss']
})
export class ClientChangesComponent implements OnInit, OnDestroy {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public dateFormat: string;
    public currentFilterValue: string = null;
    private _subscriptions: Subscription[] = [];

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @Input() client: RerClient;

    constructor(
        private rerService: RerService,
        public userPrefService: UserPreferencesService,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.displayedColumns = ['date', 'entity', 'field', 'old_value', 'new_value', 'username'];
        if (this.client.id) {
            this.getClientChanges();
        }
        this.dateFormat = this.userPrefService.userPreferences.dateFormat ?
            this.userPrefService.userPreferences.dateFormat : this.userPrefService.defaultPreferences.dateFormat;
        this.setSubscriptions();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    private getClientChanges() {
        this.rerService.getRerClientChanges(this.client.id).subscribe(
            (response: RerClientChanges[]) => {
                const changesList = [];
                response.forEach(change => {
                    let entity = 'client';
                    let statusTranslatePrefix = 'Rer.clients.addEdit.status.';
                    if (change.rer_client_address_id) {
                        entity = 'address';
                    } else if (change.rer_bin_id) {
                        entity = 'object';
                        statusTranslatePrefix = 'Rer.objects.status.';
                    }

                    if (change.field === 'status') {
                        const transfirmStatusPipe = new RerStatusTransformPipe();
                        change.old_value = change.old_value ? this.translationService.instant(
                            statusTranslatePrefix + transfirmStatusPipe.transform(change.old_value, entity)) : '';
                        change.new_value = change.new_value ? this.translationService.instant(
                            statusTranslatePrefix + transfirmStatusPipe.transform(change.new_value, entity)) : '';
                    }
                    changesList.push({
                        date: change.date,
                        entity: entity,
                        field: change.field,
                        old_value: change.old_value,
                        new_value: change.new_value,
                        username: change.username,
                    });

                    this.dataSource = new MatTableDataSource(changesList);
                    this.setDataTablePaginatorSort();
                    if (this.currentFilterValue) {
                        this.applyFilter(this.currentFilterValue);
                    }
                });
            });
    }

    private setSubscriptions(): void {
        this._subscriptions.push(
            this.rerService.rerClientChangedSubject.subscribe(
                (response: boolean) => {
                    if (response && this.client.id) {
                        this.getClientChanges();
                    }
                }
            ),
            this.rerService.rerBinListChangedSubject.subscribe(
                (clientObjects) => {
                    if (clientObjects && this.client.id) {
                        this.getClientChanges();
                    }
                }
            ),
            this.rerService.rerClientAddressListSubject.subscribe(
                (clientAddresses) => {
                    if (clientAddresses && this.client.id) {
                        this.getClientChanges();
                    }
                }
            ),
        );
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
