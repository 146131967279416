import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable,Subject,EMPTY, map } from 'rxjs';

import { User } from '../model/user.model';
import { UserInfo } from '../model/userinfo.model';
import { HistoryMessage } from '../model/historymessage.model';
import { AuthService } from '../../service/auth.service';
import { DispatcherSocketService } from './dispatcher/dispatcher-socket.service';
import { EndpointsService } from 'app/service/endpoints.service';

@Injectable()
export class UserService {

    private firstUserIndex: number;
    public usersChanged = new EventEmitter<{ users: User[], sort: boolean }>();
    public users: User[] = [];
    public toggleHeader = new Subject<boolean>();
    public usersListSubject = new Subject<any>();
    public updateMessageComponentSubject = new Subject<number>();
    public userIndexClickedSubject = new Subject<number>();
    public userBeforeIndexClickedSubject = new Subject<number>();
    public scrollDownSubject = new Subject<void>();
    public clearMessageContainerSubject = new Subject<void>();
    public updateMessageDateReadSubject = new Subject<{ userId, messageId, dateRead }>();
    public updateMessageStaeSubject = new Subject<{ userId, messageId, state }>();

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private router: Router,
        private sanitizer: DomSanitizer,
        private dispatcheSocketService: DispatcherSocketService,
        private endpointsService: EndpointsService
    ) { }

    public getUsers(hostname: string) {

        this.httpClient.get(this.endpointsService.get('dispatcher.getUsers')).subscribe(
                (users: User[]) => {
                    this.users = users;
                    let wsServers = this.users.map(user => user.client_data_server);
                    const groupIds = this.users.map(user => user.id);
                    wsServers.push(hostname);
                    wsServers = Array.from(new Set(wsServers));
                    this.dispatcheSocketService.startWssDispatchers(wsServers, this.authService.userId, groupIds, hostname);
                    this.dispatcheSocketService.userService = this;

                    this.users.forEach(
                        (user) => {
                            user.history = [];
                            user.history_date = '';
                            user.unread_ids = [];
                        }
                    );

                    this.usersListSubject.next(this.prepareUsers(this.users));
                },
                (error) => {
                    // console.log('getUserList Api error!');
                    this.router.navigate(['/signin']);
                }
            );

        this.dispatcheSocketService.startReconnectObservable();
    }

    public getUserData(id): Observable<any>  {
        return this.httpClient.get(this.endpointsService.get('dispatcher.getUserData', [id]));
    }

    // Prepare array of Userinfo for UsersComponent
    private prepareUsers(users: User[]) {
        const userinfo: UserInfo[] = [];
        let index = 0;

        for (const user of users) {
            userinfo.push({
                id: user.id,
                device_id: user.traccar_device_id,
                client_data_server: user.client_data_server,
                index: index,
                unread_ids: user.unread_ids,
                name: user.name,
                message: user.message,
                date_send: user.date_send,
                is_conected: false
            });
            index++;
        }

        return userinfo;
    }

    public getUserList() {
        return this.prepareUsers(this.users.slice(0));
    }

    // Get messages  for MessageComponent
    public getUserMessages(index) {
        if (this.users[index] && this.users[index].history) {
            return this.users[index].history.slice(0);
        }
    }

    // Get first User Index  for MessageComponent
    public getFirstUserIndex() {
        return this.firstUserIndex;
    }

    // Set first User Index  for MessageComponent
    public setFirstUserIndex(index) {
        this.firstUserIndex = index;
    }

    // Get name by index
    public getNameByIndex(index) {
        if (this.users.length) {
            return this.users[index].name;
        }
    }

    // Get id by index
    public getUserIdByIndex(index: number) {
        if (this.users.length) {
            return this.users[index].id;
        }
    }

    // Get userDs by index
    public getUserDsById(id: number): string {

        const user = this.users.find((usr: any) => usr.id === id);

        return (user) ? user.client_data_server : null;
    }

    // Get index by id
    public getUserIndexById(id) {
        let userIndex;
        if (this.users.length > 0) {
            this.users.forEach(
                (user, index) => {
                    if (user.id === id) {
                        userIndex = index;
                    }
                }
            );
        }
        return userIndex;
    }

    // Get deviceid by index
    public getDeviceIdByIndex(index) {
        if (this.users.length) {
            return this.users[index].traccar_device_id;
        }
    }

    // Get message by user index and message index
    public getUserMessageByIndex(userIndex, messageIndex) {
        if (this.users.length) {
            return this.users[userIndex].history[messageIndex];
        }
    }

    // Set unreadmessages
    public setUnread(index) {
        this.users[index].unread = 0;
        this.users[index].unread_ids = [];
    }

    // If message recieved update users
    public updateUsersHistory(message, userId) {
        if (this.users && this.users.length > 0) {
            this.users.map((user) => {
                if (user.id === userId) {
                    user.history.push(message);
                }
            });
        }
    }

    // Update History from read more
    public addMoreHistoryMessages(data) {
        if (this.users && this.users.length > 0) {
            this.users.forEach((user) => {
                if (user.id === data.from) {
                    user.history_date = data.history_date;
                    for (const hmessage of data.messages) {
                        const messageExists = user.history.findIndex((msg: any) => msg.id === hmessage['id']);
                        if (messageExists === -1) {
                            const hmessageToAdd = new HistoryMessage(
                                hmessage['date_send'],
                                hmessage['date_read'],
                                hmessage['from_user_id'],
                                hmessage['id'],
                                hmessage['message'],
                                hmessage['to_user_id'],
                                hmessage['from_real_user_id'],
                                hmessage['is_sms'],
                                hmessage['user_file_id'],
                                true,
                                user.name,
                                false,
                                hmessage['real_user_from']
                            );
                            user.history.unshift(hmessageToAdd);
                        }
                    }
                    user.history.sort((a, b) => new Date(a.date_send).getTime() - new Date(b.date_send).getTime());
                }
            });

            //  sort data
        }
    }

    // Get Ruptela Token for current user
    public getRuptelaToken() {
        const lang = localStorage.getItem('currentLang');
        let localeString;
        if (lang) {
            localeString = lang.replace('_', '-');
            if (lang === 'en_US') {
                localeString = lang.replace('_US', '-GB');
            }
        } else {
            localeString = 'ro-RO';
        }
        return this.httpClient.get(
            this.endpointsService.get('dispatcher.ruptelaTokenV2')
            // this.ruptelaTokenEndpointV2
            )
            .pipe(map(
                (response: Response) => {
                    // const iFrameUrl: String = this.ruptelaTt2 + response + '&locale=' + localeString;
                    const iFrameUrl: String = this.endpointsService.get('dispatcher.ruptelaTt2')
                     + response + '&locale=' + localeString;

                    // console.log(response);

                    return this.sanitizer.bypassSecurityTrustResourceUrl(iFrameUrl.toString());
                }
            ));
    }

    public getUserHistoryDate(userId: number) {
        try {
            const user = this.users.find((usr: any) => usr.id === userId);
            return (user.history_date) ? user.history_date : null ;
        } catch (error) {
            return 0;
        }
    }

    // public setUserHistoryDate(user_id, date_history) {
    //     for (const user of this.users) {
    //         if (user.id === +user_id) {
    //             user.history_date = date_history;
    //         }
    //     }
    // }

    public updateMessageId(userId, messageId) {
        this.users.map((user) => {
            if (user.id === userId) {
                for (let i = user.history.length - 1; i >= 0; i--) {
                    if (user.history[i].from_user_id === this.authService.userId) {
                        user.history[i].id = messageId;
                        break;
                    }
                }
            }
        });
    }

    public updateMessageState(userId, messageIds, messageState, state) {
        this.users.map((user) => {
            if (user.id === userId) {
                user.history.map((message) => {
                    if (messageIds.indexOf(message.id) !== -1) {
                        message['state_received'] = state;
                    }
                });
            }
        });
    }

    public updateMessageStateOnConnect(userIds, stateType) {
        this.users.map((user) => {
            if (userIds.indexOf(user.id) !== -1) {
                user.history.map((message) => {
                    if (!message[stateType]) {
                        message[stateType] = true;
                        // if same user logged in multiple devices
                        this.updateMessageStaeSubject.next({ userId: user, messageId: message.id, state: true });
                    }
                });
            }
        });
    }

    public updateMessageStateOnRead(userId, messages) {
        this.users.forEach((user) => {
            if (userId === user.id) {
                user.history.forEach((message) => {
                    for (const msg of messages) {
                        if (message.id === msg.message_id) {
                            message.date_read = msg.date_read;
                            // if same user logged in multiple devices
                            this.updateMessageDateReadSubject.next({ userId: userId, messageId: message.id, dateRead: msg.date_read });
                        }
                    }
                });
            }
        });
    }

    public loginCts(): Observable<any> {
        const token = this.authService.token;
        if (token === null) {
            this.authService.logout();
            return EMPTY;
        }

        return this.httpClient.post(
            this.endpointsService.get('dispatcher.loginCts')
        , { atoken: token });
    }

    public setDateOffset(date_send) {
        const offset = new Date().getTimezoneOffset();

        const date = new Date(this.replaceChar(date_send, '-', '/'));
        const timeformat = 'YYYY-MM-DD HH:mm:ss';
        let date_msg = '';

        if (offset < 0) {
            date.setMinutes(date.getMinutes() - offset);
        } else {
            date.setMinutes(date.getMinutes() + offset);
        }

        date_msg = moment(date).format(timeformat);

        return date_msg;
    }

    public replaceChar(string, to, withChar) {
        let result = '';
        for (const i of string) {
            if (i === to) {
                result += withChar;
            } else {
                result += i;
            }
        }

        return result;
    }
}
