import { CollectionByWeightPlanningAddressInterface, RerPlanningInterface } from '../_interface/rerplanning.interface';

export class RerPlanning implements RerPlanningInterface {
    constructor(
        public id = null,
        public client_id = null,
        public date = null,
        public area = null,
        public rer_route_id = null,
        public route_code = null,
        public waste_type = null,
        public rer_waste_type_id = null,
        public device = null,
        public device_id = null,
        public ipcameras = null,
        public device_name = null,
        public device_hostname = null,
        public main_employee_id = null,
        public main_employee_name = null,
        public secundary_employee_id = null,
        public secundary_employee_name = null,
        public tertiary_employee_id = null,
        public tertiary_employee_name = null,
        public notes = null,
        public period_begin = null,
        public period_end = null,
        public antenna1_last_reading = null,
        public antenna2_last_reading = null,
        public created_by_name = null,
        public created_at = null,
        public updated_by_name = null,
        public updated_at = null,
        public addresses: CollectionByWeightPlanningAddress[] = [],
        public rer_route?
    ) { }
}
export class CollectionByWeightPlanningAddress implements CollectionByWeightPlanningAddressInterface {
    constructor(
        public rer_client_address_id = null,
        public rer_client_id = null,
        public name = null,
        public client_name = null,
        public minimum_notification_quantity = 0
    ) { }
}
