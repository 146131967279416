import { Injectable } from '@angular/core';

@Injectable()
export class UnitMeasurementsService {

    private _unitMeasurement: string;

    public set unitMeasurement(value: any) {
        this._unitMeasurement = value;
    }

    constructor() { }

    public getUMString(type: string): string {
        switch (this._unitMeasurement) {
            case 'us':
                switch (type) {
                    case 'distance':
                        return 'mi';
                    case 'distance_si':
                        return 'mi';
                    case 'speed':
                        return 'mph';
                    case 'temperature':
                        return '°F';
                    case 'volume':
                        return 'gal';
                    case 'consumption':
                        return 'ga/h';
                    case 'avg_consumption':
                        return 'ga/mi';
                }
                break;
            case 'metric':
            default:
                switch (type) {
                    case 'distance':
                        return 'km';
                    case 'distance_si':
                        return 'm';
                    case 'speed':
                        return 'km/h';
                    case 'temperature':
                        return '°C';
                    case 'volume':
                        return 'L';
                    case 'consumption':
                        return 'L/h';
                    case 'avg_consumption':
                        return 'l/100km';
                }
                break;
        }
    }

    public getUMValue(value: any, type: string, um = false, digits = 0): string {
        switch (this._unitMeasurement) {
            case 'us':
                switch (type) {
                    case 'distance':
                        return (+value * 0.62137).toFixed(digits) + (um ? ' mi' : '');
                    case 'distance_si':
                        return (+value * 0.62137).toFixed(digits) + (um ? ' mi' : '');
                    case 'volume':
                        return (+value * 0.264172052).toFixed(digits) + (um ? ' gal' : '');
                    case 'speed':
                        return (+value * 0.62137).toFixed(digits) + (um ? ' mph' : '');
                    case 'consumption':
                        return (isNaN(+value) ? '' : (235.215 / +value).toFixed(digits));
                    case 'temperature':
                        return (isNaN(+value) ? '' : ((+value * 9 / 5) + 32).toFixed(digits) + (um ? ' °F' : ''));
                    case 'avg_consumption':
                        return (isNaN(+value) ? '' : (+value * 0.0042514).toFixed(digits) + (um ? ' ga/mi' : ''));
                }
                break;
            case 'metric':
            default:
                switch (type) {
                    case 'distance':
                        return (+value).toFixed(digits) + (um ? ' km' : '');
                    case 'distance_si':
                        return (+value * 1000).toFixed(digits) + (um ? ' m' : '');
                    case 'volume':
                        return (+value).toFixed(digits) + (um ? ' L' : '');
                    case 'speed':
                        return (+value).toFixed(digits) + (um ? ' km/h' : '');
                    case 'consumption':
                        return (isNaN(+value) ? '' : (+value).toFixed(digits));
                    case 'temperature':
                        return (isNaN(+value) ? '' : (+value).toFixed(digits) + (um ? ' °C' : ''));
                    case 'avg_consumption':
                        return (isNaN(+value) ? '' : (+value).toFixed(digits) + (um ? ' l/100km' : ''));
                }
                break;
        }
    }
}
