import { Component, OnInit } from '@angular/core';
import { RerService } from '../_service/rer.service';
@Component({
    selector: 'app-data-import-rer',
    templateUrl: './data-import-rer.component.html',
    styleUrls: ['./data-import-rer.component.scss']
})
export class DataImportRerComponent implements OnInit {

    constructor(
        public rerService: RerService
    ) { }

    ngOnInit() {
    }
}
