import { TemplateRef } from '@angular/core';
import { UserNotificationInterface } from '../modules/notification/_interface/usernotification.interface';
import { UserNotificationTypeInterface } from '../modules/notification/_interface/usernotificationtype.interface';

export class ToastData {
    notification?: UserNotificationInterface;
    type?: UserNotificationTypeInterface;
    template?: TemplateRef<any>;
    templateContext?: {};
}
