import { Component, OnInit, AfterContentInit, ElementRef, OnDestroy } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { BehaviorSubject, fromEvent, Subscription, Observable } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import * as moment from 'moment';

import { EndpointsService } from 'app/service/endpoints.service';
import { RerService } from '../_service/rer.service';
import { NotificationService } from 'app/service/notification.service';

import { RerBin } from '../_model/rerbin.model';
import { RerClientAssociation } from '../_model/rerclientassociations.model';

import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
    selector: 'app-bins-association',
    templateUrl: './bins-association.component.html',
    styleUrls: ['./bins-association.component.scss']
})
export class RerBinsAssociationComponent implements OnInit, AfterContentInit, OnDestroy {

    private userRerBinListSubject = new BehaviorSubject<RerBin[]>([]);
    private searchAvSubscription: Subscription;
    private searchSelectedSubscription: Subscription;
    private avFilterValue: string;
    private selectedFilterValue: string;
    private binsIds: number[];

    public cityFilterList$: Observable<string[]>;
    public divisionFilterList$: Observable<string[]>;
    public typeFilterList$: Observable<string[]>;
    public streetFilterList$: Observable<string[]>;
    public binOwnershipFilterList$: Observable<string[]>;
    public selectedCityList: string[] = [];
    public selectedDivisionList: string[] = [];
    public selectedTypeList: string[] = [];
    public selectedStreetList: string[] = [];
    public selectedBinOwnershipList: string[] = [];
    public showSpinner: boolean;
    public selectedUserId: number;
    public selectedUserName: string;
    public avBinIdsToBeAdded: number[];
    public selBinIdsToBeRemoved: number[];
    public userRerBinList$: Observable<RerBin[]>;
    public rerBinList$: Observable<RerBin[]>;
    public clientUsersList$: Observable<RerClientAssociation[]>;
    public filterListItemsUser: { id: number, label: string, count: number }[];
    public filterListItemsAll: { id: number, label: string, count: number }[];
    public binsFilterSelected: number;
    public userFilterSelected: number;
    public isSelectAllAv: boolean;
    public isSelectAllSel: boolean;

    constructor(
        private rerService: RerService,
        private notificationService: NotificationService,
        private elRef: ElementRef,
        public dialog: MatDialog,
        private endpointsService: EndpointsService,
        private httpClient: HttpClient,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.cityFilterList$ = this.rerService.cityFilterList$;
        this.divisionFilterList$ = this.rerService.devisionFilterList$;
        this.typeFilterList$ = this.rerService.typeFilterList$;
        this.streetFilterList$ = this.rerService.streetFilterList$;
        this.binOwnershipFilterList$ = this.rerService.binOwnershipFilteredList$;
        this.avFilterValue = '';
        this.selectedFilterValue = '';
        this.avBinIdsToBeAdded = [];
        this.selBinIdsToBeRemoved = [];
        this.binsFilterSelected = 0;
        this.userFilterSelected = 0;
        this.filterListItemsUser = [
            { id: 0, label: 'all', count: null },
            { id: 1, label: 'withRFID', count: null },
            { id: 2, label: 'withoutRFID', count: null }
        ];
        this.filterListItemsAll = [
            { id: 0, label: 'all', count: null },
            { id: 1, label: 'withRFID', count: null },
            { id: 2, label: 'withoutRFID', count: null }
        ];
        this.clientUsersList$ = this.rerService.clientUsersList$;
        this.rerBinList$ = this.rerService.rerBinsListFilter(this.avFilterValue, this.binsFilterSelected);
        this.userRerBinList$ = this.userRerBinListSubject.asObservable().pipe(
            map((objects: RerBin[]) => objects.filter(
                (object: RerBin) => this.rerService.evaluateFilter(
                    object, this.selectedFilterValue, this.userFilterSelected))
            )
        );

        this.notificationService.rerNotificationSubject.subscribe(
            response => {
                const data = response.rer_bins;
                this.rerService.onReceiveWssNotifications(data);
                if (this.selectedUserId === data.user_id) {
                    const objects = JSON.stringify(this.userRerBinListSubject.getValue());
                    const newObjects = JSON.parse(objects);
                    this.rerService.processChangedRerBins(newObjects, data.rer_bins);
                    this.userRerBinListSubject.next(newObjects);
                }
            }
        );

        this.rerBinList$.subscribe(
            (objects: RerBin[]) => {
                this.filterListItemsAll[0].count = objects.length;
                this.filterListItemsAll[1].count = objects.filter((obj: RerBin) => obj.rer_rfid).length;
                this.filterListItemsAll[2].count = objects.filter((obj: RerBin) => !obj.rer_rfid).length;
            }
        );
    }

    ngAfterContentInit(): void {
        const availableBins = this.elRef.nativeElement.querySelector('.search-available-objects');
        const selectedBins = this.elRef.nativeElement.querySelector('.search-selected-objects');
        this.searchSelectedSubscription = fromEvent(selectedBins, 'input')
            .pipe(map(event => event['target'].value)).pipe(debounceTime(500)
                , distinctUntilChanged())
            .subscribe((value: string) => {
                this.selectedFilterValue = value;
                this.userRerBinList$ = this.userRerBinListSubject.asObservable().pipe(
                    map((objects: RerBin[]) => objects.filter(
                        (object: RerBin) => this.rerService.evaluateFilter(
                            object, this.selectedFilterValue, this.userFilterSelected))
                    )
                );
            });

        this.searchAvSubscription = fromEvent(availableBins, 'input')
            .pipe(map(event => event['target'].value)).pipe(
                debounceTime(500)
                , distinctUntilChanged()
            )
            .subscribe((value: string) => {
                this.avFilterValue = value;
                this.rerBinList$ = this.rerService
                    .rerBinsListFilter(this.avFilterValue, this.binsFilterSelected);
            });
    }

    ngOnDestroy(): void {
        this.searchAvSubscription.unsubscribe();
        this.searchSelectedSubscription.unsubscribe();
    }

    public onClientUsersListChange(event: any): void {
        this.selBinIdsToBeRemoved = [];
        this.avBinIdsToBeAdded = [];
        this.isSelectAllAv = false;
        this.isSelectAllSel = false;
        this.selectedUserId = event ? event.id : null;
        this.selectedUserName = event ? event.name : '';
        const objects = event ? event.rer_bins : [];
        this.binsIds = event ? event.rer_bins.map((el: RerBin) => el.id) : null;

        if (this.binsIds) {
            this.binsIds.forEach((objectId: number) => this.removeItemFromAvBinIds(objectId));
        }

        this.userRerBinListSubject.next(objects);
        this.filterListItemsUser[0].count = objects.length;
        this.filterListItemsUser[1].count = objects.filter((obj: RerBin) => obj.rer_rfid).length;
        this.filterListItemsUser[2].count = objects.filter((obj: RerBin) => !obj.rer_rfid).length;
        this.rerBinList$ = this.rerService.rerBinsListFilter(this.avFilterValue, this.binsFilterSelected);
    }

    public onUserFilterChange(event: any): void {
        const objects = this.userRerBinListSubject.getValue();
        this.userRerBinListSubject.next(objects);
    }

    public onBinsFilterChange(event: any): void {
        const objects = this.userRerBinListSubject.getValue();
        this.userRerBinListSubject.next(objects);
        this.rerBinList$ = this.rerService.rerBinsListFilter(this.avFilterValue, this.binsFilterSelected);
    }

    private removeItemFromAvBinIds(objectId: number): void {
        const index = this.avBinIdsToBeAdded.findIndex(objId => objId === objectId);
        if (index !== -1) {
            this.avBinIdsToBeAdded.splice(index, 1);
        }
    }

    public manageCheckUncheckAv(status: boolean, objectId: number): void {
        if (status) {
            this.avBinIdsToBeAdded.push(objectId);
            this.rerBinList$.subscribe(
                (objects: RerBin[]) => this.isSelectAllAv = objects.length === this.avBinIdsToBeAdded.length ? true : false
            );
        } else {
            this.removeItemFromAvBinIds(objectId);
            this.isSelectAllAv = false;
        }
    }

    public manageCheckAllAv(checked: boolean): void {
        if (checked) {
            this.rerBinList$.subscribe(
                (objects: RerBin[]) => this.avBinIdsToBeAdded = objects.map((obj: RerBin) => obj.id)
            );
        } else {
            this.avBinIdsToBeAdded = [];
        }
    }

    public manageCheckAllSel(checked: boolean): void {
        if (checked) {
            this.userRerBinList$.subscribe(
                (objects: RerBin[]) => this.selBinIdsToBeRemoved = objects.map((obj: RerBin) => obj.id)
            );
        } else {
            this.selBinIdsToBeRemoved = [];
        }
    }

    public manageCheckUncheckSelected(status: boolean, objectId: number): void {
        if (status) {
            this.selBinIdsToBeRemoved.push(objectId);
            this.userRerBinList$.subscribe(
                (objects: RerBin[]) => this.isSelectAllSel = objects.length === this.selBinIdsToBeRemoved.length ? true : false
            );
        } else {
            this.isSelectAllSel = false;
            const index = this.selBinIdsToBeRemoved.findIndex(objId => objId === objectId);
            if (index !== -1) {
                this.selBinIdsToBeRemoved.splice(index, 1);
            }
        }
    }

    public updateRerClientAssociations(toBeRemoved: boolean): void {
        const objectIds = toBeRemoved ? this.selBinIdsToBeRemoved : this.avBinIdsToBeAdded;
        if (objectIds.length === 0 || !toBeRemoved && !this.selectedUserId) {
            return;
        }
        this.showSpinner = true;
        this.rerService.updateRerClientAssociations(toBeRemoved, this.selectedUserId, objectIds).subscribe(
            (objects: RerBin[]) => {
                this.binsIds = objects.map((el: RerBin) => el.id);
                this.userRerBinListSubject.next(objects);
                this.rerBinList$ = this.rerService
                    .rerBinsListFilter(this.avFilterValue, this.binsFilterSelected);
                this.avBinIdsToBeAdded = [];
                this.selBinIdsToBeRemoved = [];
                this.showSpinner = false;
            },
            () => {
                this.dialog.open(
                    NotificationComponent,
                    {
                        maxWidth: '800px', panelClass: 'custom-dialog-container',
                        data: {
                            success: 0,
                            headermessage: this.translationService.instant('error'),
                            message: this.translationService.instant('generalError')
                        }
                    }
                );
                this.showSpinner = false;
            }
        );
    }

    public checkSelectedAreaObjectsIs(objId: number): boolean {
        return (this.selBinIdsToBeRemoved.findIndex(el => el === objId) !== -1);
    }

    public checkAvdAreaObjectsIs(objId: number): boolean {
        return (this.avBinIdsToBeAdded.findIndex(el => el === objId) !== -1);
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }

    public onLoadList(): void {
        this.showSpinner = true;
        this.rerService.rerBins(
            {
                cityList: this.selectedCityList.join(','),
                divisionList: this.selectedDivisionList.join(','),
                typeList: this.selectedTypeList.join(','),
                streetList: this.selectedStreetList.join(','),
                binOwnershipList: this.selectedBinOwnershipList.join(','),
                activeOnly: true
            }
        ).subscribe(
            () => this.showSpinner = false,
            () => this.showNotification()
        );
    }

    public doExportRerClientAssociationList() {
        this.showSpinner = true;
        const httpOptions = {
            headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
            responseType: 'arrayBuffer' as 'json',
        };
        this.httpClient.get(this.endpointsService.get('rer.exportUserAssociatedObjectsList',
            [this.selectedUserId]), httpOptions).subscribe(
                (data: ArrayBuffer) => {
                    const blob = new Blob([data], { type: 'application/octet-stream' });
                    let fileName: any = this.translationService.instant('Rer.userAssociation.exportUserAssociatedObjectsFilename');
                    fileName = fileName.replaceAll(' ', "_") + "_" + this.selectedUserName.replace(new RegExp(" ", 'g'), "_") + '_'
                        + moment().format('YYYY-MM-DD HH.mm').replace(" ", "_") + '.csv';
                    saveAs(blob, fileName);
                    this.showSpinner = false;
                },
                () => this.showNotification()
            );
    }
}
