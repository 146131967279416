import { TmsSettingsInterface } from '../../tms/settings/interface/tmssettings.interface';
import { AddressesConfigModel } from './addressesconfig.model';
import { FieldsSettingsModel } from './fieldssettings.model';

export class TmsSettingsModel implements TmsSettingsInterface {
    
    constructor(
        public accepted_distance_to_pickup = 0,
        public addresses = Array<AddressesConfigModel>(),
        public archive_shippings_days_number = 100,
        public bank_name = '',
        public constrain_addresses_list = false,
        public contact_person_name = '',
        public country = '',
        public cr_number = '',
        public currency = '',
        public delegate__ic = '',
        public delegate_ic_number = 0,
        public delegate_ic_serie = '',
        public delegate_name = '',
        public delivery_address = '',
        public delivery_as_headquarters = true,
        public email_address = '',
        public email_password = '',
        public email_verified = false,
        public field_settings = Array<FieldsSettingsModel>(),
        public headquarters_address = '',
        public iban_eur = '',
        public iban_ron = '',
        public ignore_vehicle_location = false,
        public invoice_background = '',
        public invoice_currency = '',
        public invoice_footer = '',
        public invoice_generated_name = true,
        public invoice_header = '',
        public invoice_number = 0,
        public invoice_other = '',
        public invoice_payment_term = 0,
        public invoice_series = '',
        public invoice_vat_paid_type = 0,
        public location_from = 0, // setting for implicit vehicle location - only selectable if user has map or tt2 (true for get from monitoring, false for get from last shipping)
        public logo_content = '',
        public name = '',
        public phone = '',
        public price_per_km = 0,
        public shipping_document_generated_name = true,
        public shipping_number = 0,
        public shipping_series = '',
        public shipping_special_conditions = '',
        public smtp_host = '',
        public smtp_port = 0,
        public stamp_content = '',
        public swift_code = '',
        public unfinished_shipping_days = 0,
        public unfinished_shipping_status = 0,
        public use_custom_mail = false,
        public vat_number = '',
        public vat_percentage = 19,
        public wizard_step = 0,
 ) {}
}
