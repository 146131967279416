import { RerClientAssociationInterface } from '../_interface/rerclientassociation.interface';

export class RerClientAssociation implements RerClientAssociationInterface {

    public constructor(
        public id = null,
        public name = null,
        public rer_bins = [],
    ) { }
}
