import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-recover-deleted-client',
    templateUrl: './recoverdeletedclientdialog.component.html',
    styleUrls: ['./recoverdeletedclientdialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RecoverDeletedClientDialogComponent implements OnInit {

    public code: string;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        public dialogRef: MatDialogRef<RecoverDeletedClientDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() { }

    public onSave(): void {
        this.dialogRef.close(this.code);
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public getLocale(): String {
        return 'ro';
    }

}
