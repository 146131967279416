<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    {{ ((data.object.id ? 'Rer.clients.addEdit.objects.editObject' :
    'Rer.clients.addEdit.objects.addObject') | translate) + ' - ' + data.clientName }}
</h4>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100 mt-4" *ngIf="data.object.id">
                <input matInput autocomplete="off" type="details" [disabled]="true" [value]="(data.object.division ? data.object.division : '') + ' ' + data.object.city + ' '
                    + (data.object.street ? data.object.street : '')
                    + ' ' + (data.object.street_number ? data.object.street_number : '')"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.address' | translate }}">
            </mat-form-field>
            <div *ngIf="!data.object.id" class="ng-select-wrapper mt-4 w-100 d-flex align-items-center"
                style="max-width: unset;">
                <ng-select [items]="data.address" class="geozone flex-grow-1"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.address' | translate }}" appendTo="body"
                    bindLabel="label" bindValue="id" [multiple]="false" [clearable]="false" [closeOnSelect]="true"
                    [(ngModel)]="data.object.rer_client_address_id">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span class="ml-2">{{ item.division ? item.division : '' }} {{ item.city }} {{ item.street ?
                            item.street : '' }} {{ item.street_number ? item.street_number : ''
                            }}</span>
                    </ng-template>
                </ng-select>
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.object.rer_client_address_id ?
            'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="data.object.id">
        <div class="col-6">
            <mat-form-field class="w-100 mt-4 mr-1">
                <input matInput autocomplete="off" type="details" [disabled]="true" [(ngModel)]="data.object.code"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.code' | translate }}">
            </mat-form-field>
        </div>
        <div class="col-6">
            <mat-form-field class="w-100 mt-4">
                <input matInput autocomplete="off" type="details" [disabled]="true"
                    value="{{ 'Rer.objects.status.' + (data.object.status | rerStatusTransform:'object') | translate }}"
                    placeholder="{{ 'Rer.objects.list.dataTable.status' | translate }}">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="ng-select-wrapper w-100 mt-4" [ngClass]="data.object.id ? 'disabled-select' : ''"
                style="max-width: unset;">
                <ng-select [items]="data.wasteType" appendTo="body" [multiple]="false" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.object.rer_waste_type_id" bindLabel="name" bindValue="id"
                    name="wasteTypeId" #wasteTypeId="ngModel" style="flex-grow: 1; max-width: unset !important;"
                    [disabled]="data.object.id"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.wasteType' | translate }}">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.object.wasteType ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </div>
        </div>
        <div class="col-6">
            <div class="ng-select-wrapper w-100 mt-4" [ngClass]="data.object.id ? 'disabled-select' : ''"
                style="max-width: unset;">
                <ng-select [items]="data.binType" appendTo="body" [multiple]="false" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.object.rer_bin_type_id" bindLabel="name" bindValue="id"
                    name="binTypeId" #binTypeId="ngModel" style="flex-grow: 1; max-width: unset !important;"
                    [disabled]="data.object.id"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.binType' | translate }}">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.object.bin_type ?
                'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <div class="ng-select-wrapper mt-4 w-100 d-flex align-items-center" style="max-width: unset;">
                <ng-select [items]="data.rfidTagList" class="geozone flex-grow-1"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.rfid' | translate }}" appendTo="body"
                    bindLabel="rfid" bindValue="id" [multiple]="false" [clearable]="false" [closeOnSelect]="true"
                    [(ngModel)]="data.object.rer_rfid_tag_id"
                    [disabled]="!data.object.id || data.object.status === 1 ? false : true">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span class="ml-2">{{ item.rfid }}</span>
                    </ng-template>
                </ng-select>
                <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !data.object.rer_rfid_tag_id ?
            'icon-cts-information pointer font-size-18 color-red-primary align-middle': 'align-middle'" matSuffix>
                </span>
            </div>
        </div>
        <div class="col-6">
            <div class="ng-select-wrapper w-100 mt-4" [ngClass]="data.object.id ? 'disabled-select' : ''"
                style="max-width: unset;">
                <ng-select [items]="data.binOwnership" appendTo="body" [multiple]="false" [clearable]="false"
                    [closeOnSelect]="true" [(ngModel)]="data.object.rer_bin_ownership_id" bindLabel="name" bindValue="id"
                    name="binOwnershipId" #binOwnershipId="ngModel" style="flex-grow: 1; max-width: unset !important;"
                    [disabled]="data.object.id"
                    placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.binOwnership' | translate }}">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                        <ng-container *ngFor="let item of items; let i=index">
                            <span class="ng-value ng-value-label"> {{ item.name }} </span>
                        </ng-container>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <span>{{ item.name }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100 mt-4 mr-1">
                <input matInput autocomplete="off" type="details" [disabled]="true" [value]="data.object.rer_bin_setup_document_number"
                    placeholder="{{ 'Rer.objects.list.dataTable.rer_bin_setup_document_number' | translate }}">
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100 mt-4 mb-4">
                <textarea matInput autocomplete="off" matTextareaAutosize matAutosizeMinRows="2" matAutosizeMaxRows="2"
                    [(ngModel)]="data.object.description"
                    [disabled]="!data.object.id || data.object.status === 1 ? false : true">
            placeholder="{{ 'Rer.clients.addEdit.objects.dataTable.description' | translate }}">
        </textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <a *ngIf="data.object.latitude && data.object.latitude !== 0 && data.object.longitude && data.object.longitude !== 0"
                href="https://www.google.com/maps/search/?api=1&query={{ data.object.latitude }},{{ data.object.longitude}}"
                target="_blank" class="mt-4">
                {{ data.object.latitude + ', ' + data.object.latitude }}
            </a>
        </div>
    </div>
</div>
<div mat-dialog-actions class="mt-4 p-2">
    <div class="buttons d-flex pt-2">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div *ngIf="showSendPVByEmailButton === true" class="button-component utility-info-bg-color ml-4"
            (click)="sendByEmail(data.object.rer_bin_setup_document_id)">
            <div class="inner inner-info user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.send' | translate }}</span>
            </div>
        </div>
        <div *ngIf="showSendPVByEmailButton === true" class="button-component utility-green-bg-color ml-4 mr-3"
            (click)="downloadVerbalProcess(data.object.rer_bin_setup_document_id)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.exportPdf' | translate }}</span>
            </div>
        </div>
        <div *ngIf="data.object.id && (data.object.status === 1 || isClientSuspended) && data.object.rer_bin_setup_document_id === null"
            class="button-component utility-red-bg-color ml-2" (click)="onChangeBinStatus(0)">
            <div class="inner inner-red user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.deallocate' | translate
                    }}</span>
            </div>
        </div>
        <div *ngIf="data.object.id && data.object.status === 1 && data.object.rer_bin_setup_document_id === null" class="button-component utility-orange-bg-color ml-2"
            (click)="onSuspend()">
            <div class="inner inner-orrange user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.suspend' | translate
                    }}</span>
            </div>
        </div>
        <div *ngIf="data.object.id && data.object.status === 1 && data.object.rer_bin_setup_document_id === null" class="button-component utility-blue-bg-color ml-2"
            (click)="onChangeBinStatus(3)">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.lost' | translate }}</span>
            </div>
        </div>
        <div *ngIf="data.object.id && ((data.object.status === 2 || data.object.status === 3) 
                && data.clientStatus === 1 && data.object.rer_bin_setup_document_id === null)"
            class="button-component utility-blue-bg-color ml-2" (click)="onChangeBinStatus(1)">
            <div class="inner inner-blue user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'Rer.objects.action.activate' | translate
                    }}</span>
            </div>
        </div>
        <div *ngIf="!data.object.id || data.object.status === 1" class="button-component ml-2" (click)="onAdd()"
            [ngClass]="data.object.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
            <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4"
                [ngClass]="data.object.id ? 'inner-green' : 'inner-blue'">
                <span class="color-white ml-0">{{ (data.object.id ? 'save' : 'add') | translate }}</span>
            </div>
        </div>
    </div>
</div>
