import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateLoader, TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { SharedModule } from 'app/shared/shared.module';

import { StreetGeozonesComponent } from './street-geozones/street-geozones.component';
import { ListComponent } from './list/list.component';
import { CityComponent } from './city/city.component';
import { StreetListComponent } from './street-list/street-list.component';
import { AddEditStreetComponent } from './add-edit-street/add-edit-street.component';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
      { prefix: './assets/i18n/', suffix: '.json' },
      { prefix: './assets/i18n/rer/', suffix: '.json' },
  ]);
}

@NgModule({
  declarations: [
    StreetGeozonesComponent,
    ListComponent,
    CityComponent,
    StreetListComponent,
    AddEditStreetComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgSelectModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        },
        isolate: true
    }),
  ]
})
export class StreetGeozonesModule { 

}
