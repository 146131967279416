import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import * as moment from 'moment';

import { RerService } from 'app/modules/rer/_service/rer.service';

import { RerEmployee } from './../../../_model/reremployee.model';
import { CollectionByWeightPlanningAddress, RerPlanning } from '../../../_model/rerplanning.model';
import { RerWasteType } from 'app/modules/rer/_model/rerwastetype.model';

import { ConfirmDialogComponent } from '../../../../../components/signin/dialogs/confirm-dialog/confirm-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RerCollectionByWeightService } from 'app/modules/rer/_service/rercollectionbyweight.service';

@Component({
    selector: 'app-add-edit-list-item',
    templateUrl: './add-edit-list-item.component.html',
    styleUrls: ['./add-edit-list-item.component.scss']
})
export class AddEditListItemComponent implements OnInit {

    public showMandatory: boolean;
    public wasteTypeCompleteList: RerWasteType[] = [];
    public tertiaryEmployeeCompleteList: RerEmployee[] = [];
    public hasPlanningAddressRight: boolean = false;
    public isMandatory = {
        route_code: false, waste_type: false, device_id: false, area: false,
        main_employee_id: false, secundary_employee_id: false, tertiary_employee_id: false
    };
    public showSpinner: boolean;

    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public currentFilterValue: string = null;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    public adressSelectList: any[];
    public isSelectAllAddressesChecked: boolean = false;
    public showAddressesListSelectAll: boolean;
    public selectedAddresses: any[];
    public selectedClientId: number;
    public clientCompleteList: any;
    public driverList: RerEmployee[] = [];
    public operatorList: RerEmployee[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddEditListItemComponent>,
        public translateService: TranslateService,
        private rerCollectionByWeightService: RerCollectionByWeightService,
        public rerService: RerService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.rerService.getRerRouteList();
        this.showMandatory = false;
        this.rerCollectionByWeightService.getClientSelectList();
        this.showAddressesListSelectAll = true;
        this.hasPlanningAddressRight = this.data.hasPlanningAddressRight;
        this.displayedColumns = ['clientName', 'addressName', 'minimumNotificationQuantity', 'actions'];
        this.clientCompleteList = this.rerCollectionByWeightService.clientListSubject.getValue();
        this.data.planning.date = !this.data.planning.id ? this.data.date : this.data.planning.date;
        this.data.planning.period_begin = !this.data.planning.id ? '00:00' : this.data.planning.period_begin;
        this.data.planning.period_end = !this.data.planning.id ? '23:59' : this.data.planning.period_end;
        this.dataSource = new MatTableDataSource(this.data.planning.addresses);
        this.wasteTypeCompleteList = this.data.wasteTypeList.map(x => Object.assign({}, x));
        this.wasteTypeCompleteList.unshift(new RerWasteType());
        this.rerCollectionByWeightService.clientListSubject.subscribe(
            (area: any[]) => {
                this.clientCompleteList = area;
            }
        );
        this.rerService.employeeList$.subscribe(
            (employee: RerEmployee[]) => {
                this.driverList = employee.filter(
                    (driver: RerEmployee) => driver.type === RerEmployee.TYPE_DRIVER);
                this.operatorList = employee.filter(
                    (operator: RerEmployee) => operator.type === RerEmployee.TYPE_OPERATOR);
                this.tertiaryEmployeeCompleteList = this.operatorList.map(x => Object.assign({}, x));
                // this.tertiaryEmployeeCompleteList.unshift(new RerEmployee());
            }
        )
    }

    public onSave() {
        const planning = Object.assign({}, this.data.planning);
        if (this.canSavePlanning(planning)) {
            this.showSpinner = true;
            if (planning.period_begin) {
                planning.period_begin = moment(planning.date).format('YYYY-MM-DD ') + planning.period_begin + ':00';
                planning.period_begin = moment(planning.period_begin).utc(false).format('YYYY-MM-DD HH:mm:ss');
            }
            if (planning.period_end) {
                planning.period_end = moment(planning.date).format('YYYY-MM-DD ') + planning.period_end + ':59';
                planning.period_end = moment(planning.period_end).utc(false).format('YYYY-MM-DD HH:mm:ss');
            }
            this.rerService.editPlanning(planning).subscribe({
                next:() => {
                    this.showSpinner = false;
                    this.dialogRef.close(true);
                },
                error:(error: HttpErrorResponse) => {
                    planning.period_begin = moment.utc(planning.period_begin).local().format("HH:mm");
                    planning.period_end = moment.utc(planning.period_end).local().format("HH:mm");
                    this.showSpinner = false;
                    let errorMessage = 'errorNotification';
                    switch (error.status) {
                        case StatusCodes.FAILED_DEPENDENCY:
                            errorMessage = 'Rer.rerPlanning.duplicateInputError';
                            break;
                        case StatusCodes.UNPROCESSABLE_ENTITY:
                            errorMessage = 'Rer.rerPlanning.periodBeginErrror';
                            break;
                        default:
                            errorMessage = 'errorNotification';
                    }
                    this.rerService.showErrorNotifications(
                        this.translateService.instant(errorMessage));
                }
            });
        }
        else if (planning.period_begin > planning.period_end) {
            let errorMessage = 'Rer.rerPlanning.periodBeginErrror';
            this.rerService.showErrorNotifications(
                this.translateService.instant(errorMessage), '600px');
        }
    }

    public updateSelection(action: RerWasteType) {
        this.data.planning.waste_type = action.name;
    }

    public updateRouteSelection(event: any) {
        this.data.planning.route_code = event.code;
    }

    public setPlanningDate(event) {
        this.data.planning.date = event;
        this.data.planning.period_begin = '00:00';
        this.data.planning.period_end = '23:59';
    }

    private canSavePlanning(item: RerPlanning): boolean {
        let can = true;

        this.isMandatory = {
            route_code: false, waste_type: false, device_id: false, area: false,
            main_employee_id: false, secundary_employee_id: false, tertiary_employee_id: false
        };

        if (!item.area || item.area.trim().length === 0) {
            this.isMandatory['area'] = true;
            can = false;
        }
        if (!item.device_id) {
            this.isMandatory['device_id'] = true;
            can = false;
        }
        if (!item.main_employee_id) {
            this.isMandatory['main_employee_id'] = true;
            can = false;
        }
        if (!item.secundary_employee_id) {
            this.isMandatory['secundary_employee_id'] = true;
            can = false;
        }

        if (!item.period_begin || !item.period_end ||
            moment(moment(item.date).format('YYYY-MM-DD ') + item.period_begin + ':00').isAfter(moment(item.date).format('YYYY-MM-DD ') + item.period_end + ':59')) {
            can = false;
        }
        return can;
    }

    public onDelete() {
        const plannId = this.data.planning.id;
        const dialogConf = this.dialog.open(
            ConfirmDialogComponent, {
            data: {
                message: this.translateService.instant('Rer.rerPlanning.list.actionDeleteConfirmation'),
                messageCode: 'SUCCESS'
            }
        }
        );

        dialogConf.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.rerService.deletePlanning(plannId).subscribe(
                        () => {
                            this.rerService.showSaveConfirmation(
                                this.translateService.instant('Rer.rerPlanning.list.successfullyDeleted'));
                        },
                        () => {
                            this.rerService.showErrorNotifications(
                                this.translateService.instant('errorNotification'));
                        }
                    );
                }
                this.dialogRef.close(false);
            }
        );
    }

    public isValidTimeFormat(input: string) {
        if (input.length !== 5) {
            return false;
        }

        // format
        const timeFormat = input.split(':');
        if (timeFormat.length !== 2 || timeFormat[0].length !== 2 || timeFormat[1].length !== 2) {
            return false;
        }

        // hour
        if (parseInt(timeFormat[0], 10) > 23 || parseInt(timeFormat[0], 10) < 0) {
            return false;
        }

        // minute
        if (parseInt(timeFormat[1], 10) > 59 || parseInt(timeFormat[1], 10) < 0) {
            return false;
        }

        return true;
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public updateClientAddress(event: any) {
        this.selectedAddresses = [];
        this.rerCollectionByWeightService.getRerCollectionClientAddressList(this.selectedClientId).subscribe({
            next: (response) => {
                this.adressSelectList = [];
                if (response) {
                    response.forEach(x => {
                        const found = this.data.planning.addresses.find(y => y.rer_client_address_id == x.id)
                        if (!found) {
                            this.adressSelectList.push(x);
                        }
                    });
                }
            }
        })
    }

    public onDeleteAddress(rerClientAddressId) {
        this.data.planning.addresses = this.data.planning.addresses.filter(function (obj) {
            return obj.rer_client_address_id !== rerClientAddressId;
        });
        this.dataSource = new MatTableDataSource(this.data.planning.addresses);
        this.setDataTablePaginatorSort();
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }

    public onAddAddresses() {
        if (this.selectedClientId) {
            let client = this.clientCompleteList.find(x => x.id == this.selectedClientId);
            this.selectedAddresses.forEach(x => {
                let address = this.adressSelectList.find(y => y.id == x);
                let alreadyExist = this.data.planning.addresses.find(y => y.rer_client_address_id == x);
                if (!alreadyExist) {
                    this.data.planning.addresses.push(new CollectionByWeightPlanningAddress(x, this.selectedClientId, address.name, client.name));
                }
            });
            this.dataSource = new MatTableDataSource(this.data.planning.addresses);
            this.setDataTablePaginatorSort();
            this.selectedClientId = null;
            this.selectedAddresses = [];
            this.isSelectAllAddressesChecked = false;
            this.adressSelectList = [];
        }
    }

    public onSelectAllAddresses(event): void {
        event.preventDefault();
        this.isSelectAllAddressesChecked = !this.isSelectAllAddressesChecked;
        this.selectedAddresses = this.isSelectAllAddressesChecked ? this.adressSelectList.map((device: any) => device.id) : [];
    }

    public onAddressSearch(event: any): void {
        this.showAddressesListSelectAll = event.term.length > 0 ? false : true;
    }

    public onChangeAddressList(event: any[]): void {
        this.isSelectAllAddressesChecked = (this.adressSelectList.length === this.selectedAddresses.length);
    }

    public getTranslationFor(label: string): String {
        return this.translateService.instant(label);
    }

    public onRouteCodeChanged() {
        this.data.planning.rer_route_id  = null;
    }
}
