export enum RerClientStatus {
    'active' = 1,
    'suspendedForNonPayment' = 2,
    'suspendedOnRequest' = 3,
    'terminated' = 4,
    'deleted' = 5
}
export enum RerClientStatusActions {
    'activate' = 1,
    'suspendForNonPayment' = 2,
    'suspendOnRequest' = 3,
    'terminate' = 4,
}
