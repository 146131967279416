import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-confirm-action',
    templateUrl: './confirm-action.component.html',
    styleUrls: ['./confirm-action.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmActionComponent {

    constructor(
        public dialogRef: MatDialogRef<ConfirmActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

}
