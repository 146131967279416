<div class="h-100 d-flex flex-column">
    <h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18 mb-1">
        {{ ((data.id ? data.street.name : 'Rer.streetGeozones.label') | translate)}}
    </h4>
    <div class="flex-grow-1 d-flex flex-column pl-1 pr-1">
        <mat-form-field class="w-100 mb-1">
            <input type="text" matInput type="details" placeholder="{{'Rer.streetGeozones.name' | translate }}" [(ngModel)]="data.street.name">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" *ngIf="!data.street.name"
                class="icon-cts-information pointer font-size-18 color-red-primary align-middle" matSuffix>
            </span>
        </mat-form-field>
        <div class="flex-grow-1">
            <div #gmap class="h-100"></div>
        </div>
    </div>
    <div class="pl-2 pr-2 pb-2">
        <div class="buttons d-flex align-items-center justify-content-lg-end pt-2">
            <div class="button-component bgcolor-white mr-auto" (click)="dialogRef.close(false)">
                <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                    <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
                </div>
            </div>
            <div class="button-component ml-4" (click)="onNew()"
                [ngClass]="data.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
                <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="data.id ? 'inner-green' : 'inner-blue'">
                    <span class="color-white ml-0">{{ 'new' | translate }}</span>
                </div>
            </div>
            <div class="button-component ml-4" (click)="onAdd()"
                [ngClass]="data.id ? 'utility-green-bg-color' : 'bgcolor-blue'">
                <div class="inner user-no-select pb-2 pt-2 pl-4 pr-4" [ngClass]="data.id ? 'inner-green' : 'inner-blue'">
                    <span class="color-white ml-0">{{ (data.id ? 'save' : 'add') | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>