import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {

  public logoSrc = '/assets/project/' + environment.projectName  + '/images/logo-small.png';
  constructor() { }

  ngOnInit() {
  }

}
