import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from '../../../shared/shared.module';
import { ListComponent } from './list/list.component';
import { OverviewComponent } from './details/overview/overview.component';
import { AdministrationComponent } from './details/administration/administration.component';
import { RerIncidentComponent } from './rer-incident.component';
import { IncidentDetailsComponent } from './details/incident-details.component'
import { IncidentLogComponent } from './details/incident-log/incident-log.component'
import { VideoComponent } from './details/video/video.component';
import { MapComponent } from './details/map/map.component';

import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { GoogleMapDrawService } from 'app/modules/service/shared/googlemapdraw.service';
import { HistoryService } from 'app/modules/service/map/history.service';
import { GoogleapisService } from 'app/modules/service/shared/googleapis.service';
import { GoogleMapCustomControl } from 'app/modules/service/shared/googlemapcustomcontrol.service';
import { GoogleKmlService } from 'app/modules/service/map/googlekmlservice.service';
import { MarkerIconService } from 'app/modules/service/shared/markericon.service';
import { GoogleMapTileService } from 'app/modules/service/shared/googlemaptile.service';
import { PositionSocketService } from 'app/modules/service/map/position-socket.service';
import { GalleryComponent } from './details/gallery/gallery.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }, // common / shared translation
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        RerIncidentComponent,
        ListComponent,
        IncidentDetailsComponent,
        OverviewComponent,
        AdministrationComponent,
        IncidentLogComponent,
        VideoComponent,
        MapComponent,
        GalleryComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        CommonModule,
        MatTabsModule,
        MatTableModule,
        MatSortModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: [
        GoogleMapDrawService,
        HistoryService,
        GoogleapisService,
        GoogleMapCustomControl,
        GoogleKmlService,
        MarkerIconService,
        GoogleMapTileService,
        PositionSocketService,
        GoogleapisService
    ]
})
export class RerIncidentModule { }
