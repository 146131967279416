import { Component, Inject, OnInit, ViewChild, ElementRef, Input, } from '@angular/core';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import * as moment from 'moment';
import { RerService } from '../../../_service/rer.service';
import { faFastForward, faFastBackward } from '@fortawesome/free-solid-svg-icons';
import { RerIncident } from 'app/modules/rer/_model/rerincident.model';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

    public showMandatory: boolean;
    public date = moment().format('YYYY-MM-DD HH:mm:ss');
    public showSpinner = true;
    public noVideoOrError = false;
    public dateFormat;
    public faFastForward = faFastForward;
    public faFastBackward = faFastBackward;
    public skip = 0;
    public cameraId;
    public videoUrl;
    public videoFileName;

    @Input() incident: RerIncident;
    @Input() videoData: { fileName: string, url: any };
    @ViewChild("videoStream", { static: true }) videoStreamElement: ElementRef;

    constructor(
        private rerService: RerService,
        private userPreferencesService: UserPreferencesService,
        private sanitizer: DomSanitizer
    ) {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
            this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
        }
        
    ngOnInit() {
        this.cameraId = this.incident.ipcameras[0];
        if (this.videoStreamElement.nativeElement) {
            this.rerService.getRerRfidReadingVideo(this.incident.device_id, this.rerService.setDateOffset(this.incident.incident_time), this.cameraId).subscribe({
                next: result => {
                    this.videoData.url = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(result.body));
                    this.videoData.fileName = (`${this.incident.device_name}-${this.incident.incident_type_name}-${this.incident.incident_time}`).replace(/ /g, "_");
                    const reader = new FileReader(); 
                    reader.readAsDataURL(result.body);
                    reader.onload = _event => {
                        this.showSpinner = false;
                        let url = reader.result;
                        this.videoStreamElement.nativeElement.src = url;
                        this.videoStreamElement.nativeElement.autoplay = true;
                        this.videoStreamElement.nativeElement.load();
                    };
                },
                error: error => {
                    this.noVideoOrError = true;
                    this.showSpinner = false;
                }
            });
        }
    }

    public getNextPrev(next: number) {
        next ? this.skip += 1 : this.skip -= 1;
        let direction = next;
        let skip = this.skip;
        this.skip >= 0 ? direction = 1 : direction = 0;skip = Math.abs(skip);
        this.rerService.getNextPrevVideo(this.incident.device_id, this.rerService.setDateOffset(this.incident.incident_time), this.cameraId, skip, direction).subscribe(result => {
            const reader = new FileReader();
                reader.readAsDataURL(result.body);
                reader.onload = _event => {
                    this.showSpinner = false;
                    let url = reader.result;
                    this.videoStreamElement.nativeElement.src = url;
                    this.videoStreamElement.nativeElement.autoplay = true;
                    this.videoStreamElement.nativeElement.load();
                };
        });
    }

    public getLocale(): String {
        return this.rerService.getLocale();
    }
}