import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

import { RerService } from 'app/modules/rer/_service/rer.service';

import { RerPlanning } from '../_model/rerplanning.model';
import { RerSettings } from '../_model/rersettings.model';
import { AuthService } from 'app/service/auth.service';

@Component({
	selector: 'app-rer-dashboard',
	templateUrl: './rer-dashboard.component.html',
	styleUrls: ['./rer-dashboard.component.scss']
})
export class RerDashboardComponent implements OnInit, OnDestroy {

	public dataSource: MatTableDataSource<RerPlanning>;
	public displayedColumns: string[] = [];
	public showSpinner: boolean = false;
	public hasDetailsRight: boolean = false;
	public hasEditRight: boolean = false;

	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public readonly STATUS_BAD = 0;
	public readonly STATUS_GOOD = 1;
	public readonly STATUS_WARNING = 2;

	private _subscriptions: Subscription[] = [];
	private rerDashboardList$: Observable<RerPlanning[]>;
	private deviceValidUntilLimit = 15;
	private deviceInvalidFromLimit = 60;

	private readonly notWorkingIcon = "icon-cts-route-warning color-red-primary";
	private readonly warningIcon = "icon-cts-route-warning color-orange";
	private readonly workingIcon = "icon-cts-selection-radio-on color-green-primary";
	private readonly validWorkingIcon = "utility-green-bg-color";
	private readonly validWarningIcon = "bgcolor-orange";
	private readonly invalidIcon = "utility-bg-error";
	private readonly dateTimeFormat = "YYYY-MM-DD HH:mm";

	private lastUpdate: any;
	private interval: any;

	constructor(
		private rerService: RerService,
		public dialog: MatDialog,
		public messageDialog: MatDialog,
		public actionDialog: MatDialog,
		private router: Router,
	) { }

	ngOnInit() {
		this.displayedColumns = ['route_code', 'rer_route', 'rer_waste_type_id', 'device_name', 'device_status', 'main_employee_name', 'secundary_employee_name',
			'tertiary_employee_name', 'antenna_1', 'status_antenna_1', 'antenna_2', 'status_antenna_2'];
        this.showSpinner = true;
		this.dataSource = new MatTableDataSource();
		this.dataSource.sort = this.sort;
        this.rerDashboardList$ = RerService.rerDashboardList$;
        this.getPlanningForDate();
		this.setTimer();
		this.setSubscriptions();
		if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_DETAILS)) {
			this.hasDetailsRight = true;
		}
		if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_EDIT)) {
			this.hasEditRight = true;
		}
	}

	ngOnDestroy() {
		clearInterval(this.interval);
		this._subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	public antenaStatusIcon(antenna: string): string {
		let antennaStatus = this.antenaStatus(antenna);

		switch (antennaStatus) {
			case this.STATUS_GOOD:
				return this.workingIcon;
			case this.STATUS_WARNING:
				return this.warningIcon;
			default:
				return this.notWorkingIcon;
		}
	}

	public isValidString(antenna1: string, antenna2: string) {
		let antennaStatus1 = this.antenaStatus(antenna1);
		let antennaStatus2 = this.antenaStatus(antenna2);

		if (antennaStatus1 == this.STATUS_GOOD || antennaStatus2 == this.STATUS_GOOD) {
			return true;
		}

		if (antennaStatus1 == this.STATUS_WARNING && antennaStatus2 == this.STATUS_WARNING) {
			return true;
		}

		return false;
	}

	public deviceStatusColor(antenna1: string, antenna2: string) {
		let antennaStatus1 = this.antenaStatus(antenna1);
		let antennaStatus2 = this.antenaStatus(antenna2);

		if (antennaStatus1 == this.STATUS_GOOD && antennaStatus2 == this.STATUS_GOOD) {
			return this.validWorkingIcon;
		}

		if (antennaStatus1 == this.STATUS_GOOD || antennaStatus2 == this.STATUS_GOOD) {
			return this.validWarningIcon;
		}

		if (antennaStatus1 == this.STATUS_WARNING && antennaStatus2 == this.STATUS_WARNING) {
			return this.validWarningIcon;
		}

		return this.invalidIcon;
	}

	public antenaStatus(antenna: string): number {
		if (!antenna) {
			return 0;
		}

		let validLimit = moment().utc().subtract(this.deviceValidUntilLimit, 'minutes').format(this.dateTimeFormat);
		if (antenna >= validLimit) {
			return 1;
		}

		let invalidLimit = moment().utc().subtract(this.deviceInvalidFromLimit, 'minutes').format(this.dateTimeFormat);
		if (antenna >= invalidLimit) {
			return 2;
		}

		return 0;
	}

	private getPlanningForDate() {
		this.showSpinner = true;
		this.rerService.updateDashboarList();
	}

	private setTimer() {
		this.interval = setInterval(() => {
			if (!this.lastUpdate || this.lastUpdate <= moment().utc().subtract(15, "minutes").format(this.dateTimeFormat)) {
				this.getPlanningForDate();
			}
		}, 600000);
	}

	private setSubscriptions() {
		this._subscriptions.push(
            this.rerService.spinnerSubject.subscribe(
                (response) => {
                    this.showSpinner = response;
                }
            ),
            RerService.rerDashboardList$.subscribe(
                (response: RerPlanning[]) => {
                    if (response && response.length > 0) {
                        this.dataSource.data = response;
                        this.lastUpdate = moment().utc().format(this.dateTimeFormat);
                        this.showSpinner = false;
					}
				},
			),
			this.rerService.getRerSettings().subscribe(
				(response: RerSettings[]) => {
					if (response.length > 0) {
						let validUntil = response.find((setting: RerSettings) => setting.name == this.rerService.RER_SETTINGS_VALID_UNTIL);
						if (validUntil.value) {
							this.deviceValidUntilLimit = validUntil.value;
						}
						let invalidFrom = response.find((setting: RerSettings) => setting.name == this.rerService.RER_SETTINGS_INVALID_FROM);
						if (invalidFrom.value) {
							this.deviceInvalidFromLimit = invalidFrom.value;
						}
					}
				}
			)
        )
	}

	public getWasteTypeName(id: number) {
		if (!id) {
			return '';
		}

		let wasteType = this.rerService.getWasteTypeById(id);
		return wasteType ? wasteType.name : '';
	}

	public navigateToPlanningDetails(planningId: number) {
		if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_DETAILS)) {
			const navigationExtras: NavigationExtras = {
				queryParams: {
					'planningId': planningId
				},
				skipLocationChange: true
			};
			this.router.navigate(['rer/management', { outlets: { rercontent: [RerService.ROUTE_RER_PLANING] } }], navigationExtras);
		}
	}

	public applyFilter(filterValue: string): void {
		filterValue = filterValue.trim();
		filterValue = filterValue.toUpperCase();
		this.dataSource.filter = filterValue;
	}
}
