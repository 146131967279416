interface Coords { lng: string; lat: string; }

export class RerStreet {
    constructor(
        public id: number | undefined = undefined,
        public rerCityId: number | undefined = undefined,
        public name: string | undefined = undefined,
        public coords: Coords[] = [],
        public precalcValues: any = undefined,
        public geoZone?: google.maps.Polygon
    ) {}
}
