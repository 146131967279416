<div class="d-flex align-items-center justify-content-between align-items-center mb-2 pt-2">
    <div id="instructions" >
        <video [ngClass]="!showSpinner && !noVideoOrError ? 'd-block' : 'd-none'" autoplay playsinline #videoStream style="width:850px; margin: auto;" 
            controls preload="none">
        </video>
        <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
            class="d-flex justify-content-center align-items-center position-absolute z-index-100"
            *ngIf="showSpinner">
            <mat-spinner></mat-spinner>
        </div>
        <p *ngIf="noVideoOrError">{{ 'Rer.rerPlanning.cameraStreamDialog.noVideoOrError' | translate }}</p>
        <div class="d-flex justify-content-between">
            <div class="button-component bgcolor-white" style="padding-left: 150px;" (click)="getNextPrev(0)">
                <div class="inner inner-white user-no-select">
                    <span class="color-grey m-0"><fa-icon class="mr-2"[icon]="faFastBackward"></fa-icon>{{ 'previous' | translate }}</span>
                </div>
            </div>
            <div class="button-component bgcolor-white" style="padding-right: 150px;" (click)="getNextPrev(1)">
                <div class="inner inner-white user-no-select">
                    <span class="color-grey m-0">{{ 'next' | translate }}<fa-icon class="ml-2" [icon]="faFastForward"></fa-icon></span>
                </div>
            </div>
        </div>
    </div>
</div>