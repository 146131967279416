import { Injectable } from '@angular/core';

@Injectable()
export class GoogleMapTileService {

    private mapBoxAccessToken = 'pk.eyJ1IjoidHJha29zb2Z0IiwiYSI6ImNqYTd2aDR2aDAwZ3ozMnF1dDJ1NGtseDkifQ.OEPhcPkdAvoXI9ar1S3g3Q';
    private mapBoxCustomId = 'trakosoft';

    constructor() { }

    public getOSMTileLayer() {
        const openStreetsMap = new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
                // eslint-disable-next-line no-bitwise
                const scale = 1 << zoom;
                const x = ((coord.x % scale) + scale) % scale;
                const y = coord.y;
                if (y < 0 || y >= scale) {
                    return null;
                }

                return 'https://tile.openstreetmap.org/' + zoom + '/' + x + '/' + y + '.png';
            },
            tileSize: new google.maps.Size(256, 256),
            name: 'OSM',
            maxZoom: 18
        }
        );

        return openStreetsMap;
    }

    public getMapBoxCustomTileLayer() {
        const mapBoxCustomId = this.mapBoxCustomId;
        const mapBoxAccessToken = this.mapBoxAccessToken;

        const mapBoxCustom = new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
                // eslint-disable-next-line no-bitwise
                const scale = 1 << zoom;
                const x = ((coord.x % scale) + scale) % scale;
                const y = coord.y;
                if (y < 0 || y >= scale) {
                    return null;
                }

                return 'https://api.mapbox.com/styles/v1/' + mapBoxCustomId + '/cjf7vpg7w4hgw2rlh3guwux8v/tiles/256/' + zoom + '/' +
                x + '/' + y + '?access_token=' + mapBoxAccessToken;
            },
            tileSize: new google.maps.Size(256, 256),
            name: 'Custom',
            maxZoom: 20
        });

        return mapBoxCustom;
    }

    public getMapBoxHuGoTileLayer() {
        const mapBoxCustom = new google.maps.ImageMapType({
            getTileUrl: function (coord, zoom) {
                return '/assets/images/rsz_hugo.png';
            },
            tileSize: new google.maps.Size(256, 256),
            name: 'HuGo',
            maxZoom: 20
        });

        return mapBoxCustom;
    }
}
