import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FilterReportCollectionByWeightClientDialogComponent } from './filter-report-collecton-by-weight-client-dialog/filter-report-collection-by-weight-client-dialog.component';
import { EndpointsService } from 'app/service/endpoints.service';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { Observable, Subscription } from 'rxjs';
import { RerCollectionClient } from '../../_model/rercollectionclient.model';
import { FilterReportCollectionByWeightInDetailDialogComponent } from './filter-report-collection-by-weight-in-detail-dialog/filter-report-collection-by-weight-in-detail-dialog.component';
import { Device } from 'app/modules/model/settings/device.model';
import { RerCollectionByWeightService } from '../../_service/rercollectionbyweight.service';
import { ExportPeriodDialogComponent } from '../../data-export-rer/export-period-dialog/export-period-dialog.component';

@Component({
    selector: 'app-rer-general-reports',
    templateUrl: './rer-general-reports.component.html',
    styleUrls: ['./rer-general-reports.component.scss']
})
export class RerGeneralReportsComponent implements OnInit {

    public showSpinner: boolean;
    public rerclientaddress: RerCollectionClient[];
    public deviceList: Device[] = [];
    public rerCollectionClientAddressList$: Observable<RerCollectionClient[]>;
    private deviceList$: Observable<Device[]>;
    private _subscriptions: Subscription[] = []

    constructor(
        private rerCollectionByWeightService: RerCollectionByWeightService,
        public dialog: MatDialog,
        private endpointsService: EndpointsService,
        private httpClient: HttpClient,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.rerCollectionClientAddressList$ = this.rerCollectionByWeightService.getRerCollectionClientAddressList();
        this.deviceList$ = this.rerCollectionByWeightService.getCollectionDevices();
        this.setSubscriptions();
    }

    private setSubscriptions() {
        this._subscriptions.push(
            this.deviceList$.subscribe(
                (devices: Device[]) => {
                    this.deviceList = devices;
                }
            ),
            this.rerCollectionClientAddressList$.subscribe(
                (address: RerCollectionClient[]) => {
                    this.rerclientaddress = address;
                }
            ),
        );
    }

    public doExportRerCollectionByWeightClient() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            FilterReportCollectionByWeightClientDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    clientAddress: this.rerclientaddress,
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.collectionByWeight.exportRerCollectionByWeightClient', [
                        obj.exportDates.startDate, obj.exportDates.endDate, obj.clientAddressIds]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                let fileName: any = this.translationService.instant('Rer.rerCollectionByWeight.export.exportCollectionTitle');
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + obj.exportDates.startDate + '_' + obj.exportDates.endDate + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportRerCollectionByWeightInDetail() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            FilterReportCollectionByWeightInDetailDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    devices: this.deviceList,
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    if (!obj.deviceId) {
                        obj.deviceId = 0;
                    }
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.collectionByWeight.exportRerCollectionByWeightInDetails', [
                        obj.exportDates.startDate, obj.exportDates.endDate, obj.deviceId]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                let fileName: any = this.translationService.instant('Rer.rerCollectionByWeight.export.exportCollectionDetailTitle');
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + obj.exportDates.startDate + '_' + obj.exportDates.endDate + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }


    public doExportRerWeighingReceipts() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {}
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.collectionByWeight.exportRerWeighingReceipts', [
                        obj.startDate, obj.endDate]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                let fileName: any = this.translationService.instant('Rer.rerCollectionByWeight.export.exportWeighingReceiptTitle');
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + obj.startDate + '_' + obj.endDate + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );

    }

    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }
}