import { Injectable } from '@angular/core';

@Injectable()
export class ErrorMessageService {

    private _lastError: any = '';

    public getLastError(): any {
        return this._lastError;
    }

    public setLastError(value: any) {
        this._lastError = value;
    }
}
