import { ClientChangesComponent } from './add-edit/client-changes/client-changes.component';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddEditAddressComponent } from './add-edit/address/add-edit-address/add-edit-address.component';
import { AddEditBinComponent } from './add-edit/bins/add-edit-bin/add-edit-bin.component';
import { OverviewComponent } from './add-edit/overview/overview.component';
import { AddressComponent } from './add-edit/address/address.component';
import { BinsComponent } from './add-edit/bins/bins.component';
import { AddEditComponent } from './add-edit/add-edit.component';
import { SharedModule } from 'app/shared/shared.module';
import { ListComponent } from './list/list.component';
import { ClientComponent } from './rerclient.component';
import { RfidReadingsComponent } from './add-edit/rfid-readings/rfid-readings.component';
import { RecoverDeletedClientDialogComponent } from './recoverdeletecclientdialog/recoverdeletedclientdialog.component';
import { GeofenceService } from 'app/modules/service/map/geofence.service';
import { VerbalProcessComponent } from './add-edit/verbal-process/verbal-process.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        { prefix: './assets/i18n/', suffix: '.json' }, // common / shared translation
        { prefix: './assets/i18n/rer/', suffix: '.json' },
    ]);
}

@NgModule({
    declarations: [
        ClientComponent,
        ListComponent,
        AddEditComponent,
        OverviewComponent,
        AddressComponent,
        AddEditAddressComponent,
        BinsComponent,
        AddEditBinComponent,
        RfidReadingsComponent,
        ClientChangesComponent,
        RecoverDeletedClientDialogComponent,
        VerbalProcessComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        NgSelectModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            isolate: true
        }),
    ],
    providers: [
        GeofenceService
    ]
})
export class ClientModule { }
