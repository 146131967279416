import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';

import { RerService } from '../../_service/rer.service';

import { RerWasteType } from '../../_model/rerwastetype.model';
import { AddWasteComponent } from './add-waste/add-waste.component';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
@Component({
    selector: 'app-waste-type',
    templateUrl: './waste-type.component.html',
    styleUrls: ['./waste-type.component.scss']
})

export class WasteTypeComponent implements OnInit {

    public wasteTypeList$: Observable<RerWasteType[]>;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSpinner: boolean = false;

    constructor(private rerService: RerService, 
        public dialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.displayedColumns = ['name', 'value', 'actions'];
        this.wasteTypeList$ = this.rerService.wasteTypeList$;

        this.wasteTypeList$.subscribe(
            (wasteTypes: RerWasteType[]) => {
                this.dataSource = new MatTableDataSource(wasteTypes);
            }
        );
    }

    addEditWasteType(wasteTypeId) {
        let wasteType = this.rerService.getWasteTypeById(wasteTypeId);
        if (!wasteType) {
            wasteType = new RerWasteType();
            wasteType.id = wasteTypeId;
        }

        const dialogRef = this.dialog.open(
            AddWasteComponent,
            {
                width: '500px',
                data: { wasteType: wasteType }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response) => {
                if (response) {
                    this.rerService.saveWasteType(response).subscribe(
                        () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerSettings.savedSuccefuly")),
                        () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.saveFailed"))
                    )
                }
            }
        );
    }

    deleteWasteType(id) {
        const dialogRef = this.dialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.rerService
                        .getTranslationFor('Rer.rerSettings.confirmDeleteActionWT')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const wType = this.rerService.getWasteTypeById(id);
                    this.rerService.deleteWasteType(wType.id).subscribe(
                        () => { this.showSpinner = false; },
                        (error: HttpErrorResponse) => {
                            let errorMessage = 'errorNotification';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rerSettings.errorWasteTypeInUse';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage), '600px');
                        }
                    );
                }
            }
        );
    }
}
