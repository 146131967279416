import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { map, filter, mergeMap } from 'rxjs/operators'

import { UserService } from './modules/service/user.service';
import { AuthService } from './service/auth.service';
import { EnvService } from './env.service';
import { SettingsService } from './modules/service/settings/settings.service';
import { app } from 'environments/app';
import { MatDialog } from '@angular/material/dialog';
import { UpgradeRequiredComponent } from './components/upgrade-required/upgrade-required.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

    private subscriptionTitle: Subscription;
    private _subscriptions: Subscription[] = [];

    public title = environment.appTitle;
    public headerActive: boolean = true;
    public isMobile = false;
    public hiddenNavigation = false;
    public localVersionName = '';
    public localVersion: {
        major: number,
        minor: number,
        patch: number,
    };

    constructor(
        public translateService: TranslateService,
        public titleService: Title,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public authService: AuthService,
        private userService: UserService,
        private env: EnvService,
        private settingsService: SettingsService,
        private dialog: MatDialog,
    ) {
        this.translateService.setDefaultLang('ro_RO');
    }

    @HostListener('window:resize') onResize() {
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit() {
        if (!this.env.envFileLoaded) {
            // env file is not loaded. stop the app.
            this.router.navigate(['/error/env file could not be loaded']);
            return;
        }
        this.initRouterAndSubscriptions();
        this.localVersion = app.version;
        this.localVersionName = app.versionName();
        this.settingsService.getMinimumVersion().subscribe(result => {
            this.settingsService.minimumVersion = result.version;
            this.verifyVersion();
        });
    }

    ngOnDestroy() {
        this.subscriptionTitle.unsubscribe();
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }

    public initRouterAndSubscriptions() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(map(() => this.activatedRoute))
            .pipe(map((route: ActivatedRoute) => {
                if (
                    route.snapshot['_routerState'] && (route.snapshot['_routerState'].url.includes('sharelinkview') ||
                        route.snapshot['_routerState'].url.includes('thankyou') || this.authService.hiddenHeader == true
                    )) {
                    this.hiddenNavigation = true;
                }
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }))
            .pipe(filter((route) => route.outlet === 'primary'))
            .pipe(mergeMap((route) => route.data))
            .subscribe((event) => {

                const lng = localStorage.getItem('currentLang');
                if (lng) {
                    this.translateService.use(lng);
                }
                if (event && event.title) {
                    this.translateService.get(event.title).subscribe(
                        (response: string) => this.titleService.setTitle(response)
                    );
                } else {
                    this.translateService.get('title').subscribe(
                        (response: string) => this.titleService.setTitle(this.title + ' - ' + response)
                    );
                }
            });

        // Set Page Language
        const lang = localStorage.getItem('currentLang');
        if (lang) {
            this.translateService.use(lang);
        } else {
            const navLang = navigator.language;
            switch (navLang) {
                case 'en-US':
                    this.translateService.use('en_US');
                    localStorage.setItem('currentLang', 'en_US');
                    break;
                case 'de-DE':
                    this.translateService.use('de_DE');
                    localStorage.setItem('currentLang', 'de_DE');
                    break;
                case 'ro-RO':
                    this.translateService.use('ro_RO');
                    localStorage.setItem('currentLang', 'ro_RO');
                    break;

                default:
                    break;
            }

        }
        if (window.innerWidth < 768) { // 768px portrait
            this.isMobile = true;
            this.headerActive = false;
        } else {
            this.headerActive = true;
        }

        this._subscriptions.push(
            this.userService.toggleHeader.subscribe(
                (response: boolean) => {
                    this.headerActive = response;
                }),
            // this.authService.isAuthSubject.subscribe((isAuth: boolean) => this.ifIsAuth = isAuth)
        );
    }

    private verifyVersion() {
        const minimumVersionMajor = +this.settingsService.minimumVersion.substring(0, this.settingsService.minimumVersion.indexOf('.'));
        const minimumVersionMinor = +this.settingsService.minimumVersion.slice(this.settingsService.minimumVersion.indexOf('.') + 1);
        if (this.settingsService.minimumVersion && (this.localVersion.major < minimumVersionMajor
            || (this.localVersion.major == minimumVersionMajor && this.localVersion.minor < minimumVersionMinor))) {
            this.authService.logout();
            const dialogRef = this.dialog.open(
                UpgradeRequiredComponent,
                { maxWidth: '800px', panelClass: 'custom-dialog-container', disableClose: true }
            );
            dialogRef.afterClosed().subscribe(
                response => {
                    if (response) {
                        location.reload();
                        return false;
                    }
                }
            );
        } else {
            return;
        }
    }
}
