<p class="pt-4 pb-4 bgcolor-blue text-center font-weight-bold color-white"> {{
    ('Rer.rerPlanning.cameraStreamDialog.stream' | translate) + (planning ?
    planning.device.name : "") + ('Rer.rerPlanning.cameraStreamDialog.at' | translate) + (data.reading.datetime_read |
    offset:true | date: dateFormat + ' HH:mm:ss') }}</p>
<div mat-dialog-content class="content pl-4 pr-4">
    <div class="d-flex justify-content-between align-items-center mb-2">
        <div id="instructions w-100" style="min-width: 600px!important;min-height: 200px!important;">
            <img *ngIf="firstImage" style="max-width: 600px!important;" [src]="firstImage.thumb" class="pointer img-responsive" (click)="open()" />
            <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
                class="d-flex justify-content-center align-items-center position-absolute z-index-100"
                *ngIf="showSpinner">
                <mat-spinner></mat-spinner>
            </div>
            <p *ngIf="noVideoOrError">{{ 'Rer.rerPlanning.cameraStreamDialog.noVideoOrError' | translate }}</p>
        </div>
    </div>
</div>
<div mat-dialog-actions class="mt-2 p-2">
    <div class="buttons d-flex align-items-center justify-content-end pt-2">
        <div class="mr-4 button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
    </div>
</div>