import { PowerBIReportComponent } from './power-bi-report/power-bi-report.component';
import { RerBinGuard } from '../service/guard/rer-bin-guard.service';
import { RerClientGuard } from '../service/guard/rer-client-guard.service';
import { GoogleMapsGuard } from '../service/guard/google-maps.guard';
import { RerPlanningComponent } from './rer-planning/rer-planning.component';
import { DataExportRerGuard } from '../service/guard/data-export-rer-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataImportRerGuard } from '../service/guard/data-import-rer-guard.service';
import { RerClientAssociationsGuard } from '../service/guard/rer-client-association-guard.service';
import { AuthGuard } from '../service/guard/auth-guard.service';
import { RfidTagsManagementGuard } from '../service/guard/rfid-tags-management-guard.service';
import { UnallocatedRerBinGuard } from '../service/guard/unallocated-rer-bin-guard.service';
import { RerPlanningGuard } from '../service/guard/rer-planning-guard.service';
import { RerEmployeeGuard } from '../service/guard/rer-employee-guard.service';
import { RerBinsAssociationComponent } from './bins-association/bins-association.component';
import { RerComponent } from './rer.component';
import { DataImportRerComponent } from './data-import-rer/data-import-rer.component';
import { DataExportRerComponent } from './data-export-rer/data-export-rer.component';
import { ClientComponent } from './rer-client/rerclient.component';
import { ObjectComponent } from './rer-bin/rerbin.component';
import { RfidTagComponent } from './rfid-tag/rfid-tag.component';
import { UnallocatedObjectComponent } from './unallocated-bin/unallocated-bin.component';
import { RerEmployeeComponent } from './rer-employee/rer-employee.component';
import { RerDashboardGuard } from '../service/guard/rer-dashboard.service';
import { RerDashboardComponent } from './rer-dashboard/rer-dashboard.component';
import { RerSettingsComponent } from './rer-settings/rer-settings.component';
import { RerSettingsGuard } from '../service/guard/rer-setting.service';
import { RerIncidentGuard } from '../service/guard/rer-incident.service';
import { RerIncidentComponent } from './rer-incident/rer-incident.component';
import { RerService } from './_service/rer.service';
import { RerCollectionByWeightComponent } from './rer-collection-by-weight/rer-collection-by-weight.component';
import { RerReportsSentAutomaticallyComponent } from './rer-collection-by-weight/rer-reports-sent-automatically/rer-reports-sent-automatically.component';
import { RerGeneralReportsComponent } from './rer-collection-by-weight/rer-general-reports/rer-general-reports.component';
import { RerUnloadingAreasComponent } from './rer-collection-by-weight/rer-unloading-areas/rer-unloading-areas.component';
import { RerOperationOfWeighingReceiptsComponent } from './rer-collection-by-weight/rer-operation-of-weighing-receipts/rer-operation-of-weighing-receipts.component';
import { RerBinSetupDocumentsAssociationComponent } from './bins-setup-document-association/bins-setup-document-association.component';
import { RerBinSetupDocumentGuard } from '../service/guard/rer-bin-setup-document-guard.service';
import { RerVerbalProcessGuard } from '../service/guard/rer-verbal-process.service';
import { RerVerbalProcessComponent } from './rer-verbal-process/rer-verbal-process.component';
import { RerStreetGeozonesGuard } from '../service/guard/rer-street-geozones-guard.service';
import { RerRouteGuard } from '../service/guard/rer-route-guard.service';
import { RerRoutePlanningSearchGuard } from '../service/guard/rer-route-planning-search-guard.service';
import { StreetGeozonesComponent } from './street-geozones/street-geozones/street-geozones.component';
import { RoutePlanningSearchComponent } from './route-planning-search/route-planning-search/route-planning-search.component';
import { RouteComponent } from './route/route/route.component';

const routes: Routes = [
    {
        path: '',
        component: RerComponent,
    },
    {
        path: 'management', component: RerComponent,
        children: [
            {
                path: RerService.ROUTE_RER_CLIENTS,
                canActivate: [AuthGuard, RerClientGuard, GoogleMapsGuard],
                canLoad: [GoogleMapsGuard],
                component: ClientComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_OBJECTS,
                canActivate: [AuthGuard, RerBinGuard],
                component: ObjectComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_OBJECTS_ALLOCATION,
                canActivate: [AuthGuard, RerClientAssociationsGuard],
                component: RerBinsAssociationComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_BIN_SETUP_DOCUMENTS_ALLOCATION,
                canActivate: [AuthGuard, RerBinSetupDocumentGuard],
                component: RerBinSetupDocumentsAssociationComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_RFID_MANAGEMENT,
                canActivate: [AuthGuard, RfidTagsManagementGuard],
                component: RfidTagComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_UNALLOCATED_OBJECTS,
                canActivate: [AuthGuard, UnallocatedRerBinGuard],
                component: UnallocatedObjectComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_DATA_IMPORT,
                canActivate: [AuthGuard, DataImportRerGuard],
                component: DataImportRerComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_DATA_EXPORT,
                canActivate: [AuthGuard, DataExportRerGuard],
                component: DataExportRerComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_PLANING,
                canActivate: [AuthGuard, RerPlanningGuard, GoogleMapsGuard],
                canLoad: [GoogleMapsGuard],
                component: RerPlanningComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_EMPLOYEE,
                canActivate: [AuthGuard, RerEmployeeGuard],
                component: RerEmployeeComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_DASHBOARD,
                canActivate: [AuthGuard, RerDashboardGuard],
                component: RerDashboardComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_STREET_GEOZONE,
                component: StreetGeozonesComponent,
                canActivate: [AuthGuard, RerStreetGeozonesGuard],
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_ROUTE,
                component: RouteComponent,
                canActivate: [AuthGuard, RerRouteGuard],
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_ROUTE_PLANNING_SEARCH,
                component: RoutePlanningSearchComponent,
                canActivate: [AuthGuard, RerRoutePlanningSearchGuard],
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_SETTINGS,
                canActivate: [AuthGuard, RerSettingsGuard],
                component: RerSettingsComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_INCIDENT,
                canActivate: [AuthGuard, RerIncidentGuard, GoogleMapsGuard],
                canLoad: [GoogleMapsGuard],
                component: RerIncidentComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_POWER_BI_REPORT,
                canActivate: [AuthGuard],
                component: PowerBIReportComponent,
                outlet: 'rercontent'
            },
            {
                path: RerService.ROUTE_RER_COLLECTION_BY_WEIGHT,
                canActivate: [AuthGuard],
                component: RerCollectionByWeightComponent,
                outlet: 'rercontent',
                children: [
                    {
                        path: RerService.ROUTE_RER_REPORTS_SENT_AUTOMATICALLY,
                        canActivate: [AuthGuard],
                        component: RerReportsSentAutomaticallyComponent,
                        outlet: 'rercollectionbyweight',
                    },
                    {
                        path: RerService.ROUTE_RER_GENERAL_REPORTS,
                        canActivate: [AuthGuard],
                        component: RerGeneralReportsComponent,
                        outlet: 'rercollectionbyweight',
                    },
                    {
                        path: RerService.ROUTE_RER_UNLOADING_AREAS,
                        canActivate: [AuthGuard,  GoogleMapsGuard],
                        canLoad: [GoogleMapsGuard],
                        component: RerUnloadingAreasComponent,
                        outlet: 'rercollectionbyweight',
                    },
                    {
                        path: RerService.ROUTE_RER_OPERATION_OF_WEIGHING_RECEIPTS,
                        canActivate: [AuthGuard],
                        component: RerOperationOfWeighingReceiptsComponent,
                        outlet: 'rercollectionbyweight',
                    },
                ]
            },
            {
                path: RerService.ROUTE_RER_VERBAL_PROCESS,
                canActivate: [AuthGuard, RerVerbalProcessGuard, GoogleMapsGuard],
                canLoad: [GoogleMapsGuard],
                component: RerVerbalProcessComponent,
                outlet: 'rercontent'
            },
        ]
    }
];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class RerRoutingModule { }
