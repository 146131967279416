import { Injectable } from '@angular/core';
import { interval, noop, Subscription } from 'rxjs';

import { PushNotificationsService } from './pushNotification.service';
import { NotificationService } from './notification.service';
import { AuthService } from './auth.service';
import { RerService } from 'app/modules/rer/_service/rer.service';
import { EnvService } from 'app/env.service';

const SCOPE_GENERAL = 'general';
const CHECK_CONNECTION_INTERVAL = 10000;
const MODULE_MAP = 'map';
const MODULE_NOTIFICATION = 'notification';
const MODULE_FLEET_ACTIVITY_PLANNING = 'fleet_activity_planning';
const MODULE_REPORTS = 'reports';
const MODULE_ROADTAX = 'roadtax';
const MODULE_RER = 'rer';
const USER_RIGHTS = 'user_rights';
const LOGOUT = 'auth';

// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
const WEBSOCKET_READYSTATE_CONNECTING = 0;
const WEBSOCKET_READYSTATE_OPEN = 1;
const WEBSOCKET_READYSTATE_CLOSING = 2;
const WEBSOCKET_READYSTATE_CLOSED = 3;

@Injectable({
    providedIn: 'root',
})
export class GeneralSocket {

    private reconnectObservable: Subscription;
    private wssConnection: WebSocket;
    private wsProtocol: String = 'wss://';
    private wsPath: String = '/wss';
    private wsHostname: string = this.authService.clientDataServer;

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private pushNotificationService: PushNotificationsService,
        private rerService: RerService,
        private env: EnvService
    ) {
        if(this.env.wsProtocol && this.env.wsHostname){
            this.wsProtocol = this.env.wsProtocol;
            this.wsPath = this.env.wsPath
            this.wsHostname = this.env.wsHostname;
        }
    }

    private createWssConnection(): void {
        this.wssConnection = new WebSocket(this.wsProtocol + this.wsHostname + this.wsPath);
        this.wssConnection.onopen = event => this.onConnectMessage();
        this.wssConnection.onmessage = event => this.onMessage(event);
        this.wssConnection.onclose = event => noop;
        this.wssConnection.onerror = event => this.onError();
    }

    private onConnectMessage(): void {
        const msg = { scope: SCOPE_GENERAL, token: this.authService.token, user_id: this.authService.userId };
        this.wssConnection.send(JSON.stringify(msg));
    }

    public wssSubscribeToEntity(entityname:string, entity_id: number): void {
        const msg = { scope: SCOPE_GENERAL, token: this.authService.token, user_id: this.authService.userId, type: 'subscribe', 'entity': entityname, 'entity_id': entity_id };
        this.wssConnection.send(JSON.stringify(msg));
    }

    public wssUnsubscribeEntity(entityname:string, entity_id: number): void {
        const msg = { scope: SCOPE_GENERAL, token: this.authService.token, user_id: this.authService.userId, type: 'unsubscribe', 'entity': entityname, 'entity_id': entity_id };
        this.wssConnection.send(JSON.stringify(msg));
    }

    private onMessage(event: any) {
        const data = JSON.parse(event.data);
        if (!data.type) {
            return;
        }
        switch (data.type) {
            case LOGOUT:
                this.onClose();
                this.authService.logout();
                break;
            case MODULE_NOTIFICATION:
                this.notificationService.addUserNotification(data.payload);
                this.pushNotificationService.generateNotification(data.payload);
                break;
            case MODULE_REPORTS:
                this.notificationService.reportNotificationSubject.next(data.payload);
                break;
            case MODULE_FLEET_ACTIVITY_PLANNING:
                this.notificationService.rerNotificationSubject.next(data.payload);
                break;
            case MODULE_ROADTAX:
                this.notificationService.roadtaxNotificationSubject.next(data.payload);
                break;
            case USER_RIGHTS:
                this.authService.logout();
                break;
            case MODULE_RER:
                this.rerService.handleWssMessage(data.payload, data.entity);
                break; 
        }
    }

    private checkWssConnectionConnection(): void {
        this.reconnectObservable = interval(CHECK_CONNECTION_INTERVAL).subscribe(
            () => {
                // console.log('checking wss connection ... ' + this.wssConnection.readyState);
                if (this.wssConnection.readyState === WEBSOCKET_READYSTATE_CLOSED) {
                    this.createWssConnection();
                }
            }
        );
    }

    public init(): void {
        this.createWssConnection();
        this.checkWssConnectionConnection();
    }

    public onClose(): void {
        if (this.reconnectObservable) {
            this.reconnectObservable.unsubscribe();
        }
    }

    private onError(): void { }

}
