<div style="height: calc(100% - 64px);">
    <div class="search-content d-flex align-items-center">
        <div *ngIf="isEditable" class="button-component utility-green-bg-color mr-4" (click)="onAddEditBin(null)">
            <div class="inner inner-green user-no-select" style="padding: 5px 23px;">
                <span class="color-white ml-0">{{ 'Rer.clients.addEdit.objects.addObject' | translate }}</span>
            </div>
        </div>
        <div class="datatTableFilter p-2 d-flex align-items-center flex-grow-1">
            <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" class="flex-grow-1"
                placeholder="{{ 'Rer.clients.list.quickSearch' | translate }}">
        </div>
    </div>
    <mat-table #table [dataSource]="dataSource" matSort class="h-100">
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.objects.dataTable.code' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element1" (click)="onAddEditBin(element1.id)" title="{{ 'edit' | translate }}"
                class="pointer">{{ element1.id | LeftPadFilter:7:'0' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="waste_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.objects.dataTable.wasteType' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element2" class="ml-2" (click)="onAddEditBin(element2.id)"
                title="{{ 'edit' | translate }}" class="pointer">{{ element2.waste_type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.objects.dataTable.address' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element3">
                {{ element3.address }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="bin_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center!important;">
                {{ 'Rer.clients.addEdit.objects.dataTable.binType' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element4" style="justify-content: center!important;"
                (click)="onAddEditBin(element4.id)" title="{{ 'edit' | translate }}" class="pointer">{{
                element4.bin_type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="bin_ownership">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center!important;">
                {{ 'Rer.clients.addEdit.objects.dataTable.binOwnership' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element4" style="justify-content: center!important;"
                (click)="onAddEditBin(element4.id)" title="{{ 'edit' | translate }}" class="pointer">{{
                element4.bin_ownership }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center!important;">
                {{ 'Rer.objects.list.dataTable.status' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element5" style="justify-content: center!important;">
                {{ 'Rer.objects.status.' + (element5.status | rerStatusTransform:'object') | translate }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rer_rfid">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.objects.dataTable.rfid' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element6">{{ element6.rer_rfid }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.objects.dataTable.description' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element7">{{ element7.description }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="latitude">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.objects.list.dataTable.coords' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element8">
                <a *ngIf="element8.latitude && element8.latitude !== 0 && element8.longitude && element8.longitude !== 0"
                    href="https://www.google.com/maps/search/?api=1&query={{ element8.latitude }},{{ element8.longitude}}"
                    target="_blank">
                    {{ element8.latitude + ', ' + element8.longitude }}
                </a>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="datetime_read">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.objects.list.dataTable.latest_datetime_read' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element9">{{ element9.datetime_read | offset:true |
                date:this.dateFormat + ' HH:mm:ss' }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicle_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.objects.list.dataTable.vehicle_name' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element10">{{ element10.vehicle_name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rer_bin_setup_document_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.objects.list.dataTable.rer_bin_setup_document_number' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element11">{{ element11.rer_bin_setup_document_number }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rer_bin_setup_document_status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.objects.list.dataTable.rer_bin_setup_document_status' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element12" style="justify-content: center!important;">
                <i *ngIf="element12.rer_bin_setup_document_status === 1" class="icon-cts-selection-radio-on color-green-primary font-size-18" [title]="'Semnat'"></i>
                <i *ngIf="element12.rer_bin_setup_document_status === 0" class="icon-cts-action-close color-red-primary font-size-18" [title]="'Nesemnat'"></i>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> {{ 'actions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element; let rowIndex = index"
                style="justify-content: flex-end!important;">
                <i (click)="onAddEditBin(element.id)" title="{{ 'edit' | translate }}"
                    class="icon-tmss-list-action-edit font-size-14 pointer ml-2"></i>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
        </mat-row>
    </mat-table>
    <mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
    </mat-paginator>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>
