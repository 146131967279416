<div style="height: calc(100% - 65px);">
    <mat-table #table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="datetime_read">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.rfidReadings.dataTable.datetime_read' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.datetime_read | offset:true | date:dateFormat + ' HH:mm:ss'
                }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="vehicle_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.rfidReadings.dataTable.vehicle_name' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.vehicle_name }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="waste_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.rfidReadings.dataTable.wasteType' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.waste_type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="bin_type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.rfidReadings.dataTable.binType' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.bin_type }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="rfid">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.rfidReadings.dataTable.rfid' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.rfid }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="coords">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
                {{ 'Rer.clients.addEdit.rfidReadings.dataTable.coords' | translate }} </mat-header-cell>
            <mat-cell class="pl-2" *matCellDef="let element">
                <a *ngIf="element.latitude && element.latitude != 0 && element.longitude && element.longitude != 0"
                    href="https://www.google.com/maps/search/?api=1&query={{ element.latitude }},{{ element.longitude}}"
                    target="_blank">
                    {{ element.latitude + "" | shrinkfilter:8:'' }}, {{ element.longitude + "" | shrinkfilter:8:'' }}
                </a>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
        </mat-row>
    </mat-table>
</div>