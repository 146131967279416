import { TranslateService } from '@ngx-translate/core';
import { RerIncidentLogInterface } from '../_interface/rerincidentlog.interface';

export class RerIncidentLog implements RerIncidentLogInterface {
    
    //next log type id to be added is always the next from this array(helper for backend also)
    public static logTypes = {
        'statusChange': 1,
        'evaluationChange': 2,
        'responsibleChange': 3,
        'rerClientChange': 4,
        'addressChange': 5,
        'notesChange': 6,
    };

    constructor(
        public translateService: TranslateService,
        public id: number = 0,
        public user_id: number = 0,
        public rer_incident_id: number = 0,
        public type_id: number = 0,
        public old_value: string = null,
        public new_value: string = null,
        public created_at: string = null,
        public user: any = null,
        public username: string = null,
        public text: string = null,
        public labelTranslated: string = null,
        public dateShown: string = null // used for shipping tab where we only show the date when it changes
    ) {}
}
