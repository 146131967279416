<div class="text">
    <div class="row ml-0 mr-0 mt-3 font-weight-bold">
        <div class="col-md-6">{{ 'Rer.rerIncident.details.incident_type' | translate }}</div>
        <div class="col-md-6">{{ 'Rer.rerIncident.details.incident_time' | translate }}</div>
    </div>
    <div class="row ml-0 mr-0"  >
        <div class="col-md-6">{{ incident.incident_type_name }}</div>
        <div class="col-md-6">{{ incident.incident_time | offset: true | date: dateFormat + ' HH:mm:ss' }}</div>
    </div>
    <hr class="padding">
    <div class="row ml-0 mr-0 font-weight-bold">
        <div class="col-md-6">{{ 'Rer.rerIncident.details.incident_location' | translate }}</div>
        <div class="col-md-6">{{ 'Rer.rerIncident.details.coordinates' | translate }}</div>
    </div>
    <div class="row ml-0 mr-0">
        <div class="col-md-6">{{ incident.location }}</div>
        <a class="col-md-6" *ngIf="incident.latitude && incident.latitude != 0 && incident.longitude && incident.longitude != 0"
        href="https://www.google.com/maps/search/?api=1&query={{ incident.latitude }},{{ incident.longitude}}"
        target="_blank">{{  incident.latitude +', '+ incident.longitude }}</a>
    </div>
    <hr class="padding">
    <div class="row ml-0 mr-0 font-weight-bold">
        <div class="col-md-6">{{ 'Rer.rerIncident.details.route_code' | translate }}</div>
        <div class="col-md-3">{{ 'Rer.rerIncident.details.device_name' | translate }}</div>
        <div class="col-md-3">{{ 'Rer.rerIncident.details.rer_waste_type' | translate }}</div>
    </div>
    <div class="row ml-0 mr-0">
        <div class="col-md-6">{{ incident.route_code }}</div>
        <div class="col-md-3">{{ incident.device_name }}</div>
        <div class="col-md-3">{{ incident.rer_waste_type }}</div>
    </div>
    <hr class="padding">
    <div class="row ml-0 mr-0 font-weight-bold">
        <div class="col-md-6">{{ 'Rer.rerIncident.details.main_employee_name' | translate }}</div>
        <div class="col-md-3">{{ 'Rer.rerIncident.details.secundary_employee_name' | translate }}</div>
        <div class="col-md-3">{{ 'Rer.rerIncident.details.tertiary_employee_name' | translate }}</div>
    </div>
    <div class="row ml-0 mr-0">
        <div class="col-md-6">{{ incident.main_employee_name }}</div>
        <div class="col-md-3">{{ incident.secundary_employee_name }}</div>
        <div class="col-md-3">{{ incident.tertiary_employee_name }}</div>
    </div>
    <hr class="padding">
</div>