import { AuthService } from './../../../../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../_service/rer.service';

import { RerSettings } from '../../_model/rersettings.model';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  public showSpinner: boolean;
  public deviceMinimumSpeed: number = 10;
  public validUntil: number = 15;
  public invalidFrom: number = 60;
  public phone1: number;
  public phone2: number;
  public phone3: number;
  public phone4: number;
  public phone5: number;
  public powerBIUrl: string;
  public hasPowerBIRight: false;

  private settings: RerSettings[] = [];

  constructor(private rerService: RerService,
    private translationService: TranslateService) { }

  ngOnInit() {
    this.showSpinner = true;
    this.rerService.getRerSettings().subscribe(
      (response: RerSettings[]) => {
        if (response.length > 0) {
          this.settings = response;
          this.updateValues();
        }
      }
    );
    this.showSpinner = false;

    this.hasPowerBIRight = this.rerService.hasRight(AuthService.USER_RIGHT_RER_POWER_BI_REPORT);
  }

  public saveSettings() {
    this.showSpinner = true;
    this.updateSettingValue(this.rerService.RER_SETTINGS_DEVICE_SPEED, this.deviceMinimumSpeed);
    this.updateSettingValue(this.rerService.RER_SETTINGS_VALID_UNTIL, this.validUntil);
    this.updateSettingValue(this.rerService.RER_SETTINGS_INVALID_FROM, this.invalidFrom);

    if (!this.deviceMinimumSpeed || !this.validUntil || !this.invalidFrom) {
      this.showSpinner = false;
      this.rerService.showErrorNotifications(
        this.translationService.instant("Rer.rerSettings.mandatoryFields")
      );
      return;
    }

    this.updateSettingValue(this.rerService.RER_SETTINGS_PHONE_1, this.phone1);
    this.updateSettingValue(this.rerService.RER_SETTINGS_PHONE_2, this.phone2);
    this.updateSettingValue(this.rerService.RER_SETTINGS_PHONE_3, this.phone3);
    this.updateSettingValue(this.rerService.RER_SETTINGS_PHONE_4, this.phone4);
    this.updateSettingValue(this.rerService.RER_SETTINGS_PHONE_5, this.phone5);
    this.updateSettingValue(this.rerService.RER_SETTINGS_POWER_BI_URL, this.powerBIUrl);
    this.rerService.updateRerSettings(this.settings).subscribe(
      () => {
        this.showSpinner = false
        this.rerService.showSaveConfirmation(
          this.translationService.instant("Rer.rerSettings.savedSuccefuly")
        );
      },
      () => {
        this.rerService.showErrorNotifications(
          this.translationService.instant("Rer.rerSettings.saveFailed")
        );
        this.showSpinner = false;
      }
    )
  }

  public removeChars(event: KeyboardEvent) {
    let pattern = /[0-9]/;
    if (!pattern.test(event.key)) {
      event.preventDefault();
    }
  }

  private updateValues() {
    let deviceSpeedMinimumSpeed = this.getSettingValue(this.rerService.RER_SETTINGS_DEVICE_SPEED);
    if (deviceSpeedMinimumSpeed) {
      this.deviceMinimumSpeed = deviceSpeedMinimumSpeed;
    }

    let validUntil = this.getSettingValue(this.rerService.RER_SETTINGS_VALID_UNTIL);
    if (validUntil) {
      this.validUntil = validUntil
    }

    let invalidFrom = this.getSettingValue(this.rerService.RER_SETTINGS_INVALID_FROM);
    if (invalidFrom) {
      this.invalidFrom = invalidFrom;
    }

    this.phone1 = this.getSettingValue(this.rerService.RER_SETTINGS_PHONE_1);
    this.phone2 = this.getSettingValue(this.rerService.RER_SETTINGS_PHONE_2);
    this.phone3 = this.getSettingValue(this.rerService.RER_SETTINGS_PHONE_3);
    this.phone4 = this.getSettingValue(this.rerService.RER_SETTINGS_PHONE_4);
    this.phone5 = this.getSettingValue(this.rerService.RER_SETTINGS_PHONE_5);
    if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_POWER_BI_REPORT)) {
        this.powerBIUrl = this.getSettingValue(this.rerService.RER_SETTINGS_POWER_BI_URL);
    }
  }

  private getSettingValue(settingName: String) {
    let setting = this.settings.find((setting: RerSettings) => setting.name == settingName);
    return setting != null ? setting.value : null;
  }

  private updateSettingValue(settingName: String, newValue: any) {
    let index = this.settings.findIndex((setting: RerSettings) => setting.name == settingName);
    if (index > -1) {
      this.settings[index].value = newValue;
    } else {
      let setting = new RerSettings(null, settingName, newValue);
      this.settings.push(setting);
    }
  }
}
