import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import * as StringPixelWidth from 'string-pixel-width';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { EndpointsService } from 'app/service/endpoints.service';

import { GeofenceGroup } from '../../model/map/geofencegroup.model';
import { GeoCoords } from 'app/modules/model/map/geocoords.model';
import { Geofence } from '../../model/map/geofence.model';
import { RerClientAddressGeozone } from 'app/modules/rer/_model/rerclientaddressgeozone.model';
import { RerUnloadingArea } from 'app/modules/rer/_model/rerunloadingarea.model';

@Injectable()
export class GeofenceService {

    public geofenceGroupCheckboxActionSubject: Subject<{ checked: boolean, id: number }> = new Subject();
    public addEditGeofenceSubject = new Subject<{ geozoneId: number, latLng: google.maps.LatLng }>();
    public dilogSaveGeofenceSubject = new Subject<boolean>();
    public color;
    private polyOptions;

    constructor(private httpClient: HttpClient, public translateService: TranslateService, private endpointsService: EndpointsService) { }

    public getAll(): Observable<any> {

        return this.httpClient.get(this.endpointsService.get('geofence.getAll')).pipe(map(
            (response: { geofenceGroup: GeofenceGroup[], geofence: Geofence[] }) => {
                response.geofenceGroup.forEach(
                    (group: GeofenceGroup) => {
                        group.geofences = (group.geofences) ? (group.geofences.toString()).split(',').map( Number ) : [];
                        group.checked = false;
                        group.visible = true;
                    }
                );
                response.geofence.forEach(
                    (geofence: Geofence) => {
                        geofence.geofenceGroups = (geofence.geofenceGroups) ?
                            (geofence.geofenceGroups.toString()).split(',').map( Number ) : [];
                            if (geofence.coords) {
                                geofence.coords = JSON.parse(geofence.coords).map(
                                    (coords: GeoCoords) => new GeoCoords(+coords.lng, +coords.lat)
                                );
                            }
                            if (geofence.precalc_values) {
                                geofence.precalc_values = JSON.parse(geofence.precalc_values);
                            }
                        geofence.address = (geofence.address) ? JSON.parse(geofence.address) : '';
                        geofence.checked = false;
                        geofence.visible = true;
                    }
                );
                return response;
            }
        ));
    }


    public addEditGeofenceGroup(geofenceGroup: GeofenceGroup): Observable<any> {

        return this.httpClient.post(this.endpointsService.get('geofence.saveGeofenceGroup'), geofenceGroup);
    }

    public addEditGeofence(geofence: Geofence): Observable<any> {

        return this.httpClient.post(this.endpointsService.get('geofence.saveGeofence'), geofence);
    }

    public deleteGeofences(ids: number[]): Observable<any> {

        return this.httpClient.post(this.endpointsService.get('geofence.deleteGeofence'), { ids: ids });
    }

    public deleteGeofenceGroup(id: number): Observable<any> {

        return this.httpClient.delete(
            this.endpointsService.get('geofence.deleteGeofenceGroup', [id]));
    }

    public setShapesVisible(geofences: Geofence[], map: google.maps.Map): void {
        if (!geofences) {
            return;
        }
        geofences.forEach(
            (geofence: Geofence) => {
                this.setShapeVisible(geofence, map);
                geofence.checked = true;
                this.setGeofencesCheckBoxStatus(geofence.id, geofence.checked);
            }
        );
    }

    public setShapeVisible(geofence: Geofence, map: google.maps.Map): void {

        if (geofence.shape) {
            geofence.shape.setVisible(true);
        } else {
            switch (geofence.type) {
                case 'circle':
                    geofence.shape = this.createGoogleMapsCircle(map, geofence, this.polyOptions, false);
                    break;
                case 'path':
                    geofence.shape = this.createGoogleMapsPolygon(map, geofence, false, false);
                break;
            }
            geofence.shape.setDraggable(false);

            geofence.shape.addListener('click', () => {
                geofence.shape.setVisible(false);
                if (geofence.marker) {
                    geofence.marker.setIcon({ url: 'assets/images/map/geozone-marker.png', anchor: new google.maps.Point(21, 42) });
                }
            });
        }
    }

    public hideGeofencesFromMap(geofences: Geofence[]): void {
        if (geofences && geofences.length > 0) {
            geofences.forEach(
                (geofence: Geofence) => {
                    if (geofence.shape) {
                        geofence.shape.setVisible(false);
                        if (geofence.marker && geofence.marker.getVisible()) {
                            geofence.marker.setVisible(false);
                            geofence.label.close();
                        }
                        geofence.checked = false;
                        this.setGeofencesCheckBoxStatus(geofence.id, geofence.checked);
                    }
                }
            );
        }
    }

    public createGoogleMapsCircle(map: google.maps.Map, geofence: Geofence|RerClientAddressGeozone|RerUnloadingArea, polyOptions: any, editable: boolean): google.maps.Circle {
        const point = new google.maps.LatLng(Number(geofence.center_latitude), Number(geofence.center_longitude));
        
        if(geofence.color){
            this.color = geofence.color
        } else if(geofence.group_color) {
            this.color = geofence.group_color
        } else {
            this.color = '#274397';
        }
        
        this.polyOptions = {
            strokeWeight: 1,
            fillOpacity: 0.25,
            fillColor: this.color,
            strokeColor: this.color,
            editable: true,
            draggable: true
        };
        
        return new google.maps.Circle(Object.assign({}, this.polyOptions, {
            type: google.maps.drawing.OverlayType.CIRCLE,
            center: point,
            map: map,
            radius: geofence.radius,
            editable: editable
        }));
    }

    public createGoogleMapsPolygon(map: google.maps.Map, geofence: Geofence|RerClientAddressGeozone|RerUnloadingArea|any, editable: boolean, draggable: boolean): google.maps.Polygon {
        if(geofence.color){
            this.color = geofence.color
        } else if(geofence.group_color) {
            this.color = geofence.group_color
        } else {
            this.color = '#274397';
        }
        return new google.maps.Polygon({
            paths: geofence.coords,
            strokeWeight: 1,
            fillOpacity: 0.25,
            fillColor: this.color,
            strokeColor: this.color,
            map: map,
            editable: editable,
            draggable: draggable
        });
    }

    public onGeofenceClick(geofence: Geofence, status: boolean, map: google.maps.Map): void {

        geofence.checked = status;
        if (geofence.checked) {
            this.setGeofenceMarkerVisible(map, geofence);
        } else {
            if (geofence.marker) {
                geofence.marker.setVisible(false);
                geofence.label.close();
            }
            if (geofence && geofence.shape) {
                geofence.shape.setVisible(false);
            }
        }

        this.setGeofencesCheckBoxStatus(geofence.id, geofence.checked);
    }

    public createMarker(map: google.maps.Map, geofence: Geofence) {
        const marker = new google.maps.Marker({
            position: { lat: geofence.center_latitude, lng: geofence.center_longitude },
            icon: {
                url: 'assets/images/map/geozone-marker.png',
                anchor: new google.maps.Point(21, 42)
            },
            zIndex: 90,
            map: map,
        });

        marker.addListener('click', () => {
            let url: string;

            if (geofence.shape) {
                if (geofence.shape.getVisible()) {
                    geofence.shape.setVisible(false);
                    url = 'assets/images/map/geozone-marker.png';
                } else {
                    geofence.shape.setVisible(true);
                    url = 'assets/images/map/geozone-marker-on.png';
                }
            } else {
                this.setShapeVisible(geofence, map);
                url = 'assets/images/map/geozone-marker-on.png';
            }

            geofence.marker.setIcon({ url: url, anchor: new google.maps.Point(21, 42) });

        });

        return marker;
    }

    private createGeozoneLabel(geofence: Geofence) {
        const { InfoBox } = require('google-maps-infobox/infobox-module');
        const geozoneId = 'geozone-' + geofence.name + '-' + geofence.id;
        const title = (geofence.description ? geofence.description : '');
        const contentString = '<div class="label-geozone" id="' + geozoneId  + '" title="' + title + '">' + geofence.name + '</div>';

        const pixelOffset = StringPixelWidth(geofence.name, { font: 'Open Sans', size: 11, bold: true }) + 40;
        const infobox = new InfoBox({
            content: contentString,
            disableAutoPan: true,
            alignBottom: true,
            showPointer: true,
            pixelOffset: new google.maps.Size(-1 * ((pixelOffset / 2)), -48),
            infoBoxClearance: new google.maps.Size(1, 1)
        });

        if (google.maps.event) {
            google.maps.event.addListener(infobox, 'domready', () => {
                document.getElementById(geozoneId).addEventListener(
                    'click', () => this.addEditGeofenceSubject.next({ geozoneId: geofence.id, latLng: null}));
            });
        }

        return infobox;
    }

    private setGeofencesCheckBoxStatus(geofenceId: number, status: boolean): void {
        const geofences = document.querySelectorAll('[data-geofence-id="' + geofenceId + '"]');
        geofences.forEach(
            (img: any) => img.attributes.src.value = (status) ?
                'assets/images/map/checkbox-checked.png' : 'assets/images/map/checkbox-unchecked.png'
        );
    }

    public setGeofenceMarkerVisible(map: google.maps.Map, geofence: Geofence): void {
        if (geofence.marker) {
            geofence.marker.setVisible(true);
            geofence.marker.setIcon({
                url: 'assets/images/map/geozone-marker.png',
                anchor: new google.maps.Point(21, 42)
            });
        } else {
            geofence.marker = this.createMarker(map, geofence);
            geofence.label = this.createGeozoneLabel(geofence);
        }

        geofence.label.open(map, geofence.marker);
    }

    public setGeofenceGroupCheckBoxStatus(geofenceGroupId: Number, status: boolean): void {
        const group = document.querySelector('[data-group-id="' + geofenceGroupId + '"]');
        if (!group) {
            return;
        }
        if (status) {
            group.setAttribute('src', 'assets/images/map/checkbox-checked.png');
        } else {
            group.setAttribute('src', 'assets/images/map/checkbox-unchecked.png');
        }
    }

    public getTranslationFor(label: string, value = {}): String {
        let result = '';
        this.translateService.get(label, value).subscribe((response: string) => result = response);

        return result;
    }

    public getPolygonApproximateCenter(shape: google.maps.Polygon): any {
        const maxSearchSteps = 10;
        let boundsHeight = 0;
        let boundsWidth = 0;
        let heightIncr = 0;
        let maxSearchLoops;
        let n = 1;
        let northWest;
        let testPos;
        let widthIncr = 0;
        const polygonBounds = new google.maps.LatLngBounds;

        shape.getPath().getArray().forEach(latLng => polygonBounds.extend(latLng));

        const centerPoint = polygonBounds.getCenter();

        if (google.maps.geometry.poly.containsLocation(centerPoint, shape)) {
            return centerPoint;
        } else {
            maxSearchLoops = maxSearchSteps / 2;
            // Calculate NorthWest point so we can work out
            // height of polygon NW->SE
            northWest = new google.maps.LatLng(polygonBounds.getNorthEast().lat(), polygonBounds.getSouthWest().lng());
            // Work out how tall and wide the bounds are and what our search
            // increment will be
            boundsHeight = google.maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getSouthWest());
            heightIncr = boundsHeight / maxSearchSteps;
            boundsWidth = google.maps.geometry.spherical.computeDistanceBetween(northWest, polygonBounds.getNorthEast());
            widthIncr = boundsWidth / maxSearchSteps;
            // Expand out from Centroid and find a point within polygon at
            // 0, 90, 180, 270 degrees
            for (; n <= maxSearchSteps; n++) {
                // Test point North of Centroid
                testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (heightIncr * n), 0);
                if (google.maps.geometry.poly.containsLocation(testPos, shape)) {
                    break;
                }
                // Test point East of Centroid
                testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (widthIncr * n), 90);
                if (google.maps.geometry.poly.containsLocation(testPos, shape)) {
                    break;
                }
                // Test point South of Centroid
                testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (heightIncr * n), 180);
                if (google.maps.geometry.poly.containsLocation(testPos, shape)) {
                    break;
                }
                // Test point West of Centroid
                testPos = google.maps.geometry.spherical.computeOffset(centerPoint, (widthIncr * n), 270);
                if (google.maps.geometry.poly.containsLocation(testPos, shape)) {
                    break;
                }
            }

            return (testPos);
        }
    }
}
