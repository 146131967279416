import { RerStreet } from "../../street-geozones/models/rer-street.model";

export class RerRoute {
    constructor(
        public id: number | undefined = undefined,
        public name: string | undefined = undefined,
        public code: string | undefined = undefined,
        public street: RerStreet[] = undefined,
        public rerPlannings: number | undefined = undefined
    ) {}
}