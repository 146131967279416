
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Observable } from 'rxjs'
    ;
import { RerService } from '../_service/rer.service';

import { RerEmployee } from '../_model/reremployee.model';
import { Device } from 'app/modules/model/settings/device.model';
import { RerWasteType } from '../_model/rerwastetype.model';
import { RerPlanning } from '../_model/rerplanning.model';

import { MessageDialogComponent } from 'app/modules/tms/shipping/add-edit/message-dialog/message-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../service/auth.service';

@Component({
    selector: 'app-rer-planning',
    templateUrl: './rer-planning.component.html',
    styleUrls: ['./rer-planning.component.scss']
})
export class RerPlanningComponent implements OnInit {
    private _subscriptions: Subscription[] = [];
    private deviceList$: Observable<Device[]>;
    private employeeList$: Observable<RerEmployee[]>;
    private wasteTypeList$: Observable<RerWasteType[]>;

    public showSpinner: boolean;
    public deviceList: Device[] = [];
    public driverList: RerEmployee[] = [];
    public operatorList: RerEmployee[] = [];
    public wasteTypeList: RerWasteType[] = [];
    public planningDetailsTabs: RerPlanning[] = [];
    public activeTab: number;
    public hasEditRight: boolean = false;
    public hasPlanningAddressRight: boolean = false;

    constructor(
        private rerService: RerService,
        public actionDialog: MatDialog,
        public messageDialog: MatDialog,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.deviceList$ = this.rerService.deviceList$;
        this.employeeList$ = this.rerService.employeeList$;
        this.wasteTypeList$ = this.rerService.wasteTypeList$;
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_EDIT)) {
            this.hasEditRight = true;
        }
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_PLANNING_ADDRESS)) {
            this.hasPlanningAddressRight = true;
        }

        this.setSubscriptions();
    }

    private setSubscriptions() {
        this._subscriptions.push(
            this.deviceList$.subscribe(
                (devices: Device[]) => {
                    this.deviceList = devices;
                }
            ),
            this.employeeList$.subscribe(
                (employee: RerEmployee[]) => {
                    this.driverList = employee.filter(
                        (driver: RerEmployee) => driver.type === RerEmployee.TYPE_DRIVER);
                    this.operatorList = employee.filter(
                        (operator: RerEmployee) => operator.type === RerEmployee.TYPE_OPERATOR);
                }
            ),
            this.wasteTypeList$.subscribe(
                (wasteTypes: RerWasteType[]) => {
                    this.wasteTypeList = wasteTypes;

                }
            )
        );
    }

    public onDetailsPlanning(event): void {
        const index = this.planningDetailsTabs.findIndex(element => element.id === event);
        if (index === -1) {
            this.planningDetailsTabs.push(Object.assign({}, this.rerService.getPlanningById(event)));
            setTimeout(() => { this.activeTab = this.planningDetailsTabs.length + 3; }, 100);
        } else {
            setTimeout(() => { this.activeTab = (this.planningDetailsTabs.length === 1) ? 2 : index + 3; }, 100);
        }
        this.rerService.planningsDetailsChangedSubject.next(this.planningDetailsTabs);
    }

    public onChangeTab(event) {
        this.activeTab = event.index;
    }

    public onChangePeriod(event) {
        const plannings = this.rerService.rerPlanningListSubject.getValue();
        let currentPlanning = plannings.find(x => x.id == event.planningId);
        let foudNewPlanning = plannings.find(x => x.device_id == currentPlanning.device_id && x.date == event.date);
        var highestDate = new Date(Math.max.apply(null, plannings.map(function (e) {
            return new Date(e.date);
        })));

        var lowestDate = new Date(Math.min.apply(null, plannings.map(function (e) {
            return new Date(e.date);
        })));
        let selectedDate = new Date(event.date);
        if (selectedDate >= lowestDate && selectedDate <= highestDate) {
            if (foudNewPlanning) {
                this.onClosePlanning(currentPlanning.id);
                this.onDetailsPlanning(foudNewPlanning.id);
            } else {
                this.messageDialog.open(MessageDialogComponent,
                    {
                        data: {
                            message: this.translationService.instant('Rer.rerPlanning.list.emptyPlanningList'),
                            messageCode: 'warning'
                        }
                    }
                );
            }
        } else {
            this.rerService.getPlannings(event.date, event.date).subscribe(
                (plannings: RerPlanning[]) => {
                    if (plannings) {
                        this.showSpinner = false;
                        this.onClosePlanning(-1);
                        let newPlanning = plannings.find(x => x.device_id == currentPlanning.device_id);
                        this.rerService.rerPlanningListSubject.next(plannings);
                        this.rerService.intervalForPlanningsChangedSubject.next(event.date);
                        this.onDetailsPlanning(newPlanning.id)
                        if (plannings.length === 0) {
                            this.messageDialog.open(MessageDialogComponent,
                                {
                                    data: {
                                        message: this.translationService.instant('Rer.rerPlanning.list.emptyPlanningList'),
                                        messageCode: 'warning'
                                    }
                                }
                            );
                        }
                    }
                },
                (error) => {
                    this.showSpinner = false;
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('errorNotification'));
                }
            );
        }
    }

    public onClosePlanning(event: number): void {

        if (event == -1) {
            this.planningDetailsTabs = [];
            this.rerService.planningsDetailsChangedSubject.next(this.planningDetailsTabs);
            setTimeout(() => { this.activeTab = 0; }, 100);
        } else {
            const index = this.planningDetailsTabs.findIndex(element => element.id === event);

            if (index > -1) {
                this.planningDetailsTabs.splice(index, 1);
                setTimeout(() => { this.activeTab = this.planningDetailsTabs.length ? this.planningDetailsTabs.length + 2 : 0; }, 100);
            }

            this.rerService.planningsDetailsChangedSubject.next(this.planningDetailsTabs);
        }
    }
}
