<div class="bgcolor-white p-2 m-4 position-relative form-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms" [selectedIndex]="selectedTabIndex"
        (selectedIndexChange)="selectedTabIndex = $event">
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-partners"></i>
                <span class="ml-1 mr-2">{{ 'Rer.route.label' | translate }}</span>
                <span (click)="addNewRoute()"
                    class="utility-green-bg-color d-flex align-items-center justify-content-center ml-1" style="width: 24px; height: 24px;">
                    <i class="icon-cts-action-add font-size-12 color-white"></i>
                </span>
            </ng-template>
            <app-list (editrouteEventEmitter)="onEditRoute($event)"></app-list>
        </mat-tab>
        <mat-tab *ngFor="let route of routeTabs; let i = index">
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-partners"></i>
                <span class="ml-1 d-flex align-items-center">{{ route.id !== undefined ? route.name : ('Rer.route.newRoute' | translate) }}
                    <i class="icon-tmss-tab-close font-size-14 ml-1" (click)="onCloseTab(i)"></i>
                </span>
            </ng-template>
            <app-route-item [route]="route" ></app-route-item>
        </mat-tab>
    </mat-tab-group>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
</div>