import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../_service/rer.service';

import { RerLogs } from '../../_model/rerlogs.model';
import { RerEmailTemplate } from '../../_model/reremailtemplate.model';
import { MatDialog } from '@angular/material/dialog';
import { EditEmailTemplateComponent } from './edit-email-template/edit-email-template.component';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  public emailTemplateList$: Observable<RerEmailTemplate[]>
  public dataSource: MatTableDataSource<RerEmailTemplate>;
  public dateFormat:string = 'yyyy-MM-dd HH:mm';
  public displayedColumns: string[];
  public showSpinner: boolean;
  public currentFilterValue: string = null;

  constructor(private rerService: RerService,
    private translationService: TranslateService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.showSpinner = true;
    this.displayedColumns = ['id', 'name', 'subject', 'updated_at', 'updated_by', 'actions'];
    this.dataSource = new MatTableDataSource();
    this.rerService.getEmailTemplate();
    this.emailTemplateList$ = this.rerService.emailTemplateList$;
    this.emailTemplateList$.subscribe(
      (response: RerEmailTemplate[]) => {
        this.dataSource.data = response;
        this.showSpinner = false;
      },
      () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.logs.errorNotification"))
    )
  }

  ngAfterViewInit(): void {
    this.setDataTablePaginatorSort();
  }

  private setDataTablePaginatorSort(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  public editEmailTemplate(emailTemplateId){
    let emailTemplate = this.rerService.getEmailTemplateByID(emailTemplateId);
    const dialogRef = this.dialog.open(
      EditEmailTemplateComponent,
      {
          width: '1000px',
          data: { emailTemplate: emailTemplate }
      }
  );

  dialogRef.afterClosed().subscribe(
      (response) => {
          if (response) {
              this.rerService.saveEmailTemplate(response);
          }
      }
  );

  }
}
