<div class="bgcolor-white p-2 m-4 position-relative rerplannings-container overflow-auto" style="height: calc(100% - 32px);">
    <div class="search-content d-flex align-items-center mt-2">
        <div class="datatTableFilter mb-1 mr-1 mb-2 p-2 d-flex align-items-center w-80 flex-grow-1">
            <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
            <input matInput autocomplete="off" (keyup)="applyFilter($event.target.value)" class="flex-grow-1"
                placeholder="{{ 'Rer.rerEmployee.list.quickSearch' | translate }}">
        </div>
    </div>
    <div>
        <mat-table #table [dataSource]="dataSource" matSort style="height: 100%">
            <ng-container matColumnDef="details">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'Details' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">
                    <i *ngIf="hasDetailsRight" title="{{ 'Details' | translate }}"
                        class="pl-2 pt-1 pointer icon-screen font-size-14 pointer"></i>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="route_code">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.route_code' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">
                    {{ element.route_code || '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="rer_route">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.route' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">
                    {{ element.rer_route?.name || '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="rer_waste_type_id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.waste_type' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{
                    getWasteTypeName(element.rer_waste_type_id) ||
                    '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="device_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.device_name' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{ element.device_name ||
                    '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="main_employee_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.main_employee_name' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{
                    element.main_employee_name || '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="secundary_employee_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.secundary_employee_name' | translate}}
                </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{
                    element.secundary_employee_name || '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tertiary_employee_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.tertiary_employee_name' | translate}}
                </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{
                    element.tertiary_employee_name || '-' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="antenna_1">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.antenna_1' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{
                    element.antenna1_last_reading | offset: true | date: "dd-MM-yyyy HH:mm:ss" || "-" }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status_antenna_1">
                <mat-header-cell *matHeaderCellDef> {{ 'Rer.rerPlanning.dataTable.status_antenna' |
                    translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="w-100 d-flex justify-content-center font-size-22 align-items-center">
                        <i [ngClass]="antenaStatusIcon(element.antenna1_last_reading)"></i>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="antenna_2">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'Rer.rerPlanning.dataTable.antenna_2' | translate}} </mat-header-cell>
                <mat-cell class="pl-2 font-size-24 font-weight-bold" *matCellDef="let element">{{
                    element.antenna2_last_reading | offset: true | date: "dd-MM-yyyy HH:mm:ss" || "-" }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status_antenna_2">
                <mat-header-cell *matHeaderCellDef> {{ 'Rer.rerPlanning.dataTable.status_antenna' |
                    translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <div class="w-100 d-flex justify-content-center font-size-22 py-2 align-items-center">
                        <i [ngClass]="antenaStatusIcon(element.antenna2_last_reading)"></i>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="device_status">
                <mat-header-cell *matHeaderCellDef> {{ 'Rer.rerPlanning.dataTable.device_status' |
                    translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element" class="w-100">
                    <span class="font-size-18 w-100 ml-2 text-center font-weight-bold text-uppercase color-white"
                        style="border-radius: 4px;"
                        [ngClass]="deviceStatusColor(element.antenna1_last_reading, element.antenna2_last_reading)">
                        {{ isValidString(element.antenna1_last_reading, element.antenna2_last_reading) ? "valid" :
                        "invalid" }}
                    </span>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
            </mat-header-row>
            <mat-row (click)="navigateToPlanningDetails(row.id)" *matRowDef="let row; columns: displayedColumns;"
                class="pointer mb-1 p-1 mr-3">
            </mat-row>
        </mat-table>
    </div>
    <div style="top: calc(50% - 50px); left: calc(50% - 50px)"
        class="d-flex justify-content-center align-items-center position-absolute z-index-100" *ngIf="showSpinner">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!dataSource.data.length && !showSpinner" style="top: calc(45%); left: 38%"
        class="d-flex justify-content-center align-items-center position-absolute z-index-100">
        <span class="font-size-24 font-weight-bold">
            {{ 'Rer.rerPlanning.dataTable.emptyTable' | translate }}
        </span>
    </div>
</div>