import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DatePipe } from '@angular/common';
import { finalize } from "rxjs/operators";
import { noop } from 'rxjs';

import { ErrorMessageService } from '../modules/service/notification/errormessage.service';
import { AuthService } from '../service/auth.service';
import { StatusCodes } from 'http-status-codes';
import { EndpointsService } from 'app/service/endpoints.service';
import { EnvService } from 'app/env.service';
import { app } from 'environments/app';

// rate limit is 3 errors in 1 sec OR 5 errors in 60 sec
const ERROR_HANDLER_RATE_LIMIT_1_CALLS = 3;
const ERROR_HANDLER_RATE_LIMIT_1_MILISECONDS = 1000;
const ERROR_HANDLER_RATE_LIMIT_2_CALLS = 5;
const ERROR_HANDLER_RATE_LIMIT_2_MILISECONDS = 60000;
const ERROR_HANDLER_WAIT_MILISECONDS = 60000;

@Injectable()
export class CtsErrorHandler implements ErrorHandler {

    static countCalls = 0;
    static countMiliseconds = 0;
    static pastTimestamp = null;
    static waitUntil = null;

    constructor(
        private injector: Injector,
        private errorService: ErrorMessageService,
        private httpClient: HttpClient,
        private endpointsService: EndpointsService,
        private env: EnvService
    ) { }

    public handleError(error: any) {
        if (this.rateLimitReached()
            || !error
            || (error && error.status === 421) // MISDIRECTED REQUEST is used at login
            // || (error && error.status === StatusCodes.UNPROCESSABLE_ENTITY)
        ) {
            return;
        }

        if (!environment.production) {
            console.error(error);
        }

        if (error && error.url && error.url.includes('geocode/reverse') === true) {
            return;
        }

        const slackWebhookUrl = this.injector.get(AuthService).slackWebhookUrl;
        if (slackWebhookUrl) {
            const clientId = this.injector.get(AuthService).clientId;
            const userEmail = this.injector.get(AuthService).email;
            let ipAddress = null;
            this.getIp().pipe(
                finalize(() => {
                const message = this.buildErrorMessage(error, clientId, userEmail, ipAddress);
                this.sendSlackMessage(slackWebhookUrl, message);
            })).subscribe(
                (response: {'ip': string}) => {
                    ipAddress = response.ip;
                }
            );
        }

        // logout when UNAUTHORIZED from own dataserver
        if (error && error.status === StatusCodes.UNAUTHORIZED && error.url.includes(this.env.apiHostname) === true) {
            this.injector.get(AuthService).logout();
        }
    }

    private rateLimitReached() {
        const nowMs = Date.now();
        if (CtsErrorHandler.waitUntil && CtsErrorHandler.waitUntil > nowMs) {
            // waiting
            // console.log('error handler waiting');
            return true;
        } else if (CtsErrorHandler.waitUntil) {
            // waiting finished
            // console.log('error handler waiting finished');
            CtsErrorHandler.countCalls = 0;
            CtsErrorHandler.countMiliseconds = 0;
            CtsErrorHandler.pastTimestamp = null;
            CtsErrorHandler.waitUntil = null;
        }

        if (!CtsErrorHandler.pastTimestamp) {
            CtsErrorHandler.pastTimestamp = nowMs;
            CtsErrorHandler.countCalls++;
            return false;
        }
        CtsErrorHandler.countCalls++;
        CtsErrorHandler.countMiliseconds += nowMs - CtsErrorHandler.pastTimestamp;
        CtsErrorHandler.pastTimestamp = nowMs;
        if (CtsErrorHandler.countCalls > ERROR_HANDLER_RATE_LIMIT_1_CALLS
            && CtsErrorHandler.countMiliseconds < ERROR_HANDLER_RATE_LIMIT_1_MILISECONDS) {
            // rate limit 1 reached
            // console.log('error handler rate limit 1 reached');
            CtsErrorHandler.waitUntil = nowMs + ERROR_HANDLER_WAIT_MILISECONDS;
            return true;
        } else if (CtsErrorHandler.countCalls > ERROR_HANDLER_RATE_LIMIT_2_CALLS
            && CtsErrorHandler.countMiliseconds < ERROR_HANDLER_RATE_LIMIT_2_MILISECONDS) {
            // rate limit 2 reached
            // console.log('error handler rate limit 2 reached');
            CtsErrorHandler.waitUntil = nowMs + ERROR_HANDLER_WAIT_MILISECONDS;
            return true;
        } else if (CtsErrorHandler.countCalls > ERROR_HANDLER_RATE_LIMIT_2_CALLS) {
            // reset counters
            // console.log('error handler reset counters');
            CtsErrorHandler.countCalls = 0;
            CtsErrorHandler.countMiliseconds = 0;
            CtsErrorHandler.pastTimestamp = null;
            CtsErrorHandler.waitUntil = null;
        }
        // console.log('error: ' + CtsErrorHandler.countCalls + ' in ' + CtsErrorHandler.countMiliseconds + ' ms');
        return false;
    }

    private getIp() {
        return this.httpClient.get(this.endpointsService.get('util.myIp'));
    }

    private buildErrorMessage(error, clientId, userEmail, ipAddress) {
        return {
            'channel': '#ng-errors', // '#ng-errors',
            'username': 'ng-frontend@' + this.env.apiHostname,
            'icon_emoji': ':warning:',
            'attachments': [
                {
                    'fallback': error.message.length > 100 ? error.message.split('\n', 1) : error.message,
                    'pretext': error.message.length > 100 ? error.message.split('\n', 1) : error.message,
                    'color': '#D00000',
                    'fields': [
                        {
                            'title': 'User',
                            'value': 'email: ' + userEmail + ' (clientid: ' + clientId + ')',
                            'short': false
                        },
                        {
                            'title': 'WebApp Version',
                            'value': app.versionName(),
                            'short': false
                        },
                        {
                            'title': 'Ip',
                            'value': ipAddress,
                            'short': false
                        },
                        {
                            'title': 'Browser',
                            'value': 'UserAgent: ' + window.navigator.userAgent +
                                '\n Screen: ' + window.innerWidth + 'x' + window.innerHeight,
                            'short': false
                        },
                        (error.message.length > 100 || error.stack) ?
                            {
                                'title': 'Complete error message',
                                'value': (error.stack ? error.stack : error.message),
                                'short': false
                            } : {},
                    ]
                }
            ]
        };
    }

    private sendSlackMessage(slackWebhookUrl: string, message: any) {
        if (message.attachments[0].pretext !== this.errorService.getLastError()) {
            const options = {
                headers: new HttpHeaders(
                    { 'Content-Type': 'application/x-www-form-urlencoded' }
                )
            };
            this.errorService.setLastError(message.attachments[0].pretext);
            message.attachments[0].pretext += ' at ' + this.injector.get(DatePipe).transform(Date.now(), 'dd/MM/yyyy hh:mm:ss');
            this.httpClient.post(slackWebhookUrl, message, options).subscribe({});
        }
    }
}
