import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { UnitMeasurementsService } from './unitmeasurements.service';

import { VehicleDisplayInfo } from '../model/vehicledisplayinfo.model';
import { ShippingDisplayInfo } from '../model/shippingdisplayinfo.model';
import { UserDisplayInfo } from '../model/userdisplayinfo.model';

import { Preferences } from '../interface/preferences.interface';
import { EndpointsService } from './endpoints.service';

@Injectable()
export class UserPreferencesService {
    private _defaultPreferences: Preferences;
    private _userPreferences: Preferences;
    private _signin = true;
    private _token = false;

    readonly DEFAULT_MIN_STOP_DURATION = '15'; // minutes

    public set token(value: boolean) {
        this._token = value;
    }

    public get defaultPreferences(): Preferences {
        return this._defaultPreferences;
    }

    public get userPreferences() {
        return this._userPreferences;
    }

    public set userPreferences(value) {
        if (value === null || typeof value !== 'object') {
            value = this.defaultPreferences;
        }

        if (this._signin) {
            for (const prop in this._defaultPreferences) {
                if (!value.hasOwnProperty(prop)) {
                    value[prop] = this._defaultPreferences[prop];
                } else if (typeof this._defaultPreferences[prop] === 'object') {
                    Object.keys(this._defaultPreferences[prop]).forEach(
                        (key) => {
                            if (!value[prop].hasOwnProperty(key)) {
                                value[prop][key] = this._defaultPreferences[prop][key];
                            }
                        }
                    );
                }
            }
            for (const prop in value) {
                if (!this._defaultPreferences.hasOwnProperty(prop)) {
                    delete value[prop];
                } else if (typeof value[prop] === 'object') {
                    Object.keys(value[prop]).forEach(
                        (key) => {
                            if (!this._defaultPreferences[prop].hasOwnProperty(key)) {
                                delete value[prop][key];
                            }
                        }
                    );
                }
            }
        }
        /* verificam daca avem token si doar atunci sa se execute */
        if (this._token) {
            this.saveFrontendPreferences(value).subscribe(
                (response) => {
                    this.unitMeasurementsService.unitMeasurement = value.unitMeasurement;
                    if (response.message !== 'success') {
                        this._userPreferences = this._defaultPreferences;
                    }
                },
                () => this._userPreferences = this._defaultPreferences
            );
        }

        this._userPreferences = value;
    }

    public set signin(value: boolean) {
        this._signin = value;
    }

    constructor(
        private httpClient: HttpClient,
        private unitMeasurementsService: UnitMeasurementsService,
        private endpointsService: EndpointsService
    ) {
        this._defaultPreferences = {
            map: {
                vehicleDisplayInfo: this.setVehicleDisplayInfo(),
                vehicleFocusTrail: '10',
                groupVehicles: false,
                devices_attributes: [],
                zipPattern: false,
                zipCountries: [],
                zipBoundary: false,
                zipBoundaryCountries: [],
                mapTypeId: environment.isGoogleMapsAvailable ? 'roadmap' : 'openStreetsMap',
                highlightPowerSupply: false,
                geofences: false,
                vehicleSeparateTab: false,
                huGoLayer: true,
                mapZoomlevel: 14
            },
            dispatcher: {
                userDisplayInfo: this.setUserDisplayInfo()
            },
            shipping: {
                shippingDisplayInfo: this.setShippingDisplayInfo()
            },
            exports: {
                intermediationCostsinShippingDoc: true,
            },
            stopDuration : this.DEFAULT_MIN_STOP_DURATION,
            defaultMapCenter: '47.751569, 1.675063',
            unitMeasurement: 'metric',
            dateFormat: 'yyyy-MM-dd'
        };
    }

    private setVehicleDisplayInfo(): VehicleDisplayInfo[]  {
        const vehicleProperties = [
            'name',
            'locationAddress',
            'countryFlag',
            'gpsCoordinates',
            'speed',
            'directionIcon',
            'datetime',
            'driverName',
            'vehicleParameters'
        ];
        const vehicleDisplayInfo: VehicleDisplayInfo[] = [];
        vehicleProperties.forEach(
            property => {
                if (property === 'name') {
                    vehicleDisplayInfo.push(new VehicleDisplayInfo(property, true));
                } else if (property === 'driverName' || property === 'gpsCoordinates' ) {
                    vehicleDisplayInfo.push(new VehicleDisplayInfo(property, false, false));
                } else {
                    vehicleDisplayInfo.push(new VehicleDisplayInfo(property));
                }
            }
        );

        return vehicleDisplayInfo;
    }

    private setShippingDisplayInfo(): ShippingDisplayInfo[]  {
        const shippingProperties = [
            'dispatcher',
            'vehicle',
            'pickupAddress',
            'pickupDate',
            'deliveryDate',
            'deliveryAddress',
            'price',
            'distance',
            'duration',
            'volume',
            'weight'
        ];
        const shippingDisplayInfo: ShippingDisplayInfo[] = [];
        shippingProperties.forEach(
            property => {
                if (property === 'name') {
                    shippingDisplayInfo.push(new ShippingDisplayInfo(property, true));
                } else {
                    shippingDisplayInfo.push(new ShippingDisplayInfo(property));
                }
            }
        );

        return shippingDisplayInfo;
    }

    private setUserDisplayInfo(): UserDisplayInfo[]  {
        const userProperties = ['name', 'userIcon', 'lastMessage', 'lastMessageDatetime'];
        const userDisplayInfo: UserDisplayInfo[] = [];
        userProperties.forEach(
            property => {
                if (property === 'name') {
                    userDisplayInfo.push(new UserDisplayInfo(property, true));
                } else {
                    userDisplayInfo.push(new UserDisplayInfo(property));
                }
        });

        return userDisplayInfo;
    }

    private saveFrontendPreferences(frontendPreferences): Observable<any> {
        const dataRequest = {
            frontendPreferences: JSON.stringify(frontendPreferences)
        };
        return this.httpClient.post(
            this.endpointsService.get('user.savefrontendpreferences'),
            dataRequest);
    }

    public isEquivalent(a, b) {
        // Create arrays of property names
        const aProps = Object.getOwnPropertyNames(a);
        const bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (let i = 0; i < aProps.length; i++) {
            const propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;
    }
}
