import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../_service/rer.service';

import { RerBin } from '../_model/rerbin.model';
import { RerRfidTag } from '../_model/rerrfidtag.model';

import { ConfirmActionComponent } from '../../../shared/confirm-action/confirm-action.component';

@Component({
    selector: 'app-unallocated-bin',
    templateUrl: './unallocated-bin.component.html',
    styleUrls: ['./unallocated-bin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UnallocatedObjectComponent implements OnInit {

    public binsTabs: RerBin[] = [];
    public selectedTabIndex = 0;
    public showSpinner: boolean;
    public rfidTagList$ = null;
    public rfidTagList = null;

    constructor(private rerService: RerService, 
        public actionDialog: MatDialog,
        private translationService: TranslateService) { }

    ngOnInit() {
        this.rfidTagList$ = this.rerService.rfidTagList$;
        this.rfidTagList$.subscribe(
            (rfidTags: RerRfidTag[]) => {
                this.rfidTagList = rfidTags;
            }
        );
        this.rerService.getUnallocatedBinsList();
    }

    public addNewBin(): void {
        const newObject = this.binsTabs.find(ob => ob.id === null);
        if (!newObject) {
            this.binsTabs.unshift(new RerBin());
        }
        setTimeout(() => { this.selectedTabIndex = 1; });
    }

    public onCloseTab(tabIndex: number): void {
        this.binsTabs.splice(tabIndex, 1);
        if (this.selectedTabIndex === tabIndex - 1) {
            this.selectedTabIndex = 0;
        }
    }

    public editBin(id: number): void {
        let moveTo: number;
        const rerBin = this.rerService.getUnallocatedRerBinsById(id);
        const indexTab = this.binsTabs.findIndex((obj: RerBin) => obj.id === rerBin.id);
        if (indexTab === -1) {
            this.binsTabs.push(rerBin);
            moveTo = this.binsTabs.length;
        } else {
            moveTo = indexTab + 1;
        }

        setTimeout(() => { this.selectedTabIndex = moveTo; });
    }

    public deleteBin(id: number): void {

        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.objects.list.dataTable.confirmDeleteAction')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const clientObject = this.rerService.getUnallocatedRerBinsById(id);
                    this.rerService.deleteUnallocatedRerBin(clientObject.id).subscribe(
                        () => this.showSpinner = false,
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            }
        );
    }

    public closeAllTabs(): void {
        this.binsTabs = [];
    }
}
