import { RerBinOwnership } from './../../../_model/rerbinownership';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { RerService } from 'app/modules/rer/_service/rer.service';

import { RerClientAddress } from '../../../_model/rerclientaddress.model';

@Component({
    selector: 'app-add-edit-allocate-bin',
    templateUrl: './add-edit-allocate-bin.component.html',
    styleUrls: ['./add-edit-allocate-bin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditAllocateBinComponent implements OnInit {

    private clientAddressListSubject = new BehaviorSubject<RerClientAddress[]>([]);
    public showMandatory: boolean;
    public showSpinner: boolean;
    public addressList = [];
    public clientList = [];
    public binOwnershipList: RerBinOwnership[];
    public clientAddressList$: Observable<RerClientAddress[]> = this.clientAddressListSubject.asObservable();
    public cityFilterList$: Observable<string[]>;
    public divisionFilterList$: Observable<string[]>;
    public streetFilterList$: Observable<string[]>;
    public binOwnershipList$: Observable<RerBinOwnership[]>;
    public selectedCityList: string[] = [];
    public selectedDivisionList: string[] = [];
    public selectedStreetList: string[] = [];
    public searchCode = '';

    constructor(
        public dialogRef: MatDialogRef<AddEditAllocateBinComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private rerService: RerService,
        private translationService: TranslateService
    ) { }

    ngOnInit() {
        this.data.object.rer_client_id = null;
        this.cityFilterList$ = this.rerService.cityFilterList$;
        this.divisionFilterList$ = this.rerService.devisionFilterList$;
        this.streetFilterList$ = this.rerService.streetFilterList$;
        this.rerService.clientList$.subscribe(
            (data) => {
                this.clientList = data;
            }
        )
        this.rerService.binOwnershipList$.subscribe(
            (data) => {
                this.binOwnershipList = data;
            }
        )
        this.showMandatory = false;
    }

    public onCityListChange(): void {
        this.getClientPartnerList();
    }

    public onStreetListChange(): void {
        this.getClientPartnerList();
    }

    public onDivisionListChange(): void {
        this.getClientPartnerList();
    }

    public onClientCodeListChange(): void {
        this.showSpinner = true;
        this.rerService.getRerClients(
            {
                cityList: this.selectedCityList.join(','),
                divisionList: this.selectedDivisionList.join(','),
                typeList: null,
                streetList: this.selectedStreetList.join(','),
                activeOnly: true
            }
        ).subscribe(
            () => {
                this.showSpinner = false;
                this.clientList = [];
                let client = this.rerService.getRerClientByCode(this.searchCode);
                if (client) {
                    this.clientList.push(client);
                    this.data.object.rer_client_id = client.id;
                    this.onClientListChange(client);
                } else {
                    this.rerService.showErrorNotifications(
                        this.translationService.instant('Rer.unallocatedObjects.clientNotFound'));
                }
            }
        );
    }

    private getClientPartnerList(): void {
        this.showSpinner = true;
        this.rerService.getRerClients(
            {
                cityList: this.selectedCityList.join(','),
                divisionList: this.selectedDivisionList.join(','),
                typeList: null,
                streetList: this.selectedStreetList.join(','),
                activeOnly: true
            }
        ).subscribe(
            () => this.showSpinner = false
        );
    }

    public onClientListChange(event): void {
        this.showSpinner = true;
        this.rerService.getRerClientAddressList(event.id).subscribe(
            (addressList: RerClientAddress[]) => {
                this.addressList = addressList;
                this.addressList.forEach(
                    address => address.label = (address.division != null ? address.division + ' ' : '') + address.city + ' ' + (address.street != null ? address.street + ' ' : '') + (address.street_number != null ? address.street_number : '')
                );
                this.clientAddressListSubject.next(addressList);
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
                this.rerService.showErrorNotifications(
                    this.translationService.instant('errorNotification'));
            }
        );
    }

    public onAdd(): void {
        this.showMandatory = (this.data.object.rer_client_id && this.data.object.rer_client_address_id) ? false : true;

        if (!this.showMandatory) {
            this.data.object.status = 1;
            this.dialogRef.close(this.data.object);
        }
    }

    public preventEventToCheckbox(event): void {
        event.preventDefault();
    }
}
