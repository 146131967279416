import { RerClientAddressInterface } from '../_interface/rerclientaddress.interface';

export class RerClientAddress implements RerClientAddressInterface {
    public constructor(
        public id = null,
        public code = null,
        public address = null,
        public city = null,
        public is_default = 0,
        public description = null,
        public rer_client_id = null,
        public division = null,
        public street = null,
        public street_number = null,
        public reference_date = null,
        public name = null,
        public bins_number = null,
        public rer_area_id = null,
        public address_geozone = null
    ) { }
}
