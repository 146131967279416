<h1 mat-dialog-title class="p-2"> {{ 'Rer.rerCollectionByWeight.export.title' | translate }}</h1>
<div mat-dialog-content>
    <div class="row ml-0 mr-0 mt-4">
        <div class="col-md-12">
            <app-datepicker-range [dateRange]="[exportDates.startDate, exportDates.endDate]"
                (selectDateEventEmitter)="setExportDataPeriod($event)" [time]="false" [alignCenter]="true"
                [justifyCenter]="true" [locale]="getLocale()" [label]="getTranslationFor('dateRange')">
            </app-datepicker-range>
        </div>
        <div class="col-12 mt-4">
            <div class="ng-select-wrapper w-100">
                <ng-select placeholder="{{ 'Rer.rerCollectionByWeight.export.clientAddress' | translate }}" appendTo="body"
                    [(ngModel)]="clientAddressIds" [clearable]="false" name="clientAddressIds" [(items)]="data.clientAddress"
                    [closeOnSelect]="true" [multiple]="true" dropdownPosition="bottom" (change)="onChangeAddressList()">
                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                            <ng-container *ngFor="let item of items; let i=index">
                                <div class="ng-value">
                                    <span class="ng-value-label">{{item.name.slice(0,10) }}</span>
                                    <span class="ml-1 ng-value-icon right" (click)="clear(item)"
                                        aria-hidden="true">×</span>
                                </div>
                            </ng-container>
                        </ng-template>
                        <ng-template ng-header-tmp>
                            <div style="margin-right: 10px">
                                <mat-checkbox (change)="onSelectAllAddress($event)" [(ngModel)]="isSelectAllAddressChecked">
                                    <span class="ml-2 color-corbeau">{{ 'selectAll' | translate }}</span>
                                </mat-checkbox>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <mat-checkbox (click)="preventEventToCheckbox($event)" [(ngModel)]="item$.selected">
                                <span>{{ item.name }}</span>
                            </mat-checkbox>
                        </ng-template>
                </ng-select>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <span class="mr-3 eButton cancel pointer pb-2 pt-2 pl-3 pr-3" (click)="onCancel()">
        {{ 'cancel' | translate }}
    </span>
    <span class="mr-3 eButton pointer primary pb-2 pt-2 pl-3 pr-3" (click)="onExport()">
        {{ 'ok' | translate }}
    </span>

</div>