import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

    // The values that are defined here are the default values that can
    // be overridden by .env.js

    // know if env file is loaded
    public envFileLoaded = false;

    // API domain
    // used by auth.interceptor to inject the bearer token
    public apiDomain = '';

    // API protocol
    public apiProtocol = 'https://';

    // API hostname
    // used by endpoints.service as default client data server (for requests done before being logged in)
    public apiHostname = '';

    // WebSocket protocol
    public wsProtocol = '';
    
    // WebSocket hostname
    public wsHostname = '';

    // WebSocket path
    public wsPath = '';

    // One Signal WebbApp Id
    public oneSignalAppId = '';
     
    constructor() {
    }
}
