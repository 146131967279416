import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RerService } from '../../_service/rer.service';
import { RerBinSetupImport } from '../../_model/rerbinsetupimport.model';

@Component({
    selector: 'app-bin-setup-import',
    templateUrl: './bin-setup-import.component.html',
    styleUrls: ['./bin-setup-import.component.scss']
})
export class BinSetupImportComponent implements OnInit {

    public showSpinner: boolean;
    public fileColumns: string[];
    public dataForImport: RerBinSetupImport[] = [];
    public dataWarningList = {};
    public Object;
    public showFinalImport: boolean;
    public fileName: string;

    constructor(private rerService: RerService) { }

    ngOnInit() {
        this.Object = Object;
        this.fileColumns = [
            'Tip client',
            'Cod client',
            'Cod secundar',
            'Denumire client',
            'Telefon',
            'Adresa email',
            'Diviziune',
            'Judet',
            'Localitate',
            'Strada',
            'Nr. strada',
            'Tip deseu',
            'Tip recipient',
            'Tip proprietate',
            'Nr. proces verbal',
            'Data planificare proces verbal',
            'Nr. recipienți planificați proces verbal'
        ];
    }

    private isImportLineValid(importLine: string[], line: number): { warnings: any[], data: any } {
        let response = true;
        // console.log(importLine);
        let dataWarningList = { warnings: [], data: null };
        if (importLine.length !== 17) {
            dataWarningList.warnings.push('Numarul de coloane diferit de 17!');
            dataWarningList.data = importLine;
            return dataWarningList;
        }
        const codPattern = /^[0-9]+$/i;

        if (!importLine[0] || importLine[0].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip client</span> nu poate fi goala!');
            response = false;
        }

        const clientTypeArray = ['0','1','2','3'];
        if (!clientTypeArray.includes(importLine[0])) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip client</span> poate contine doar valorie 0 – Persoana fizica, 1 – Asociatie proprietari, 2 – Persoana juridica, 3 – Institutie publica!');
            response = false;
        }

        if (!codPattern.test(importLine[1])) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Cod client</span> trebuie sa contina doar numere!');
            response = false;
        }

        if (!codPattern.test(importLine[2])) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Cod secundar</span> trebuie sa contina doar numere!');
            response = false;
        }

        if (!importLine[3] || importLine[3].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Denumire client</span> nu poate fi goala!');
            response = false;
        }


        if (!importLine[8] || importLine[8].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Localitate</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[11] || importLine[11].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip deseu</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[12] || importLine[12].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Tip pubela</span> nu poate fi goala!');
            response = false;
        }

        if (!importLine[14] || importLine[14].trim().length === 0) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Nr. proces verbal</span> nu poate fi goala!');
            response = false;
        }

        const pattern = /^\d{4}\.(0[1-9]|1[012])\.(0[1-9]|[12][0-9]|3[01])$/;
        if (!pattern.test(importLine[15])) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Data planificare proces verbal</span> trebuie sa fie valida si in format <span class="font-weight-bold">aaaa.ll.zz</span>!');
            response = false;
        }

        if (isNaN(parseInt(importLine[16], 10))) {
            dataWarningList.warnings.push('Coloana <span class="font-weight-bold">Cantitate</span> trebuie sa fie un numar!');
            if (response) {
                importLine[11] = null;
                importLine[12] = null;
            }

            response = false;
        }

        if (response) {
            dataWarningList = null;
        } else {
            dataWarningList.data = importLine;
        }

        return dataWarningList;
    }

    public onInportFileChanged(event: any): void {
        this.showSpinner = true;
        const file = event.target.files[0];
        const reader = new FileReader();
        this.fileName = file.name;
        reader.readAsText(file);
        reader.onload = () => {
            this.rerService.rerBinSetupImportList$.subscribe(
                (rerBinImportLine: RerBinSetupImport[]) => {
                    const csvData = reader.result;
                    const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
                    if (csvRecordsArray[0].includes('Cod client') && csvRecordsArray[0].includes('Diviziune')) {
                        csvRecordsArray.splice(0, 1);
                    }
                    const dataWarningList = {};

                    csvRecordsArray.forEach(
                        (line: string, index: number) => {
                            if (line.length > 0) {
                                const dataToProcess = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
                                const isValid = this.isImportLineValid(dataToProcess, index);
                                if (isValid) {
                                    dataWarningList[index] = isValid;
                                } else {
                                    this.dataForImport.push(
                                        new RerBinSetupImport(
                                            dataToProcess[0],
                                            dataToProcess[1],
                                            dataToProcess[2],
                                            dataToProcess[3],
                                            dataToProcess[4],
                                            dataToProcess[5].replace('"',''),
                                            dataToProcess[6],
                                            dataToProcess[7],
                                            dataToProcess[8],
                                            dataToProcess[9],
                                            dataToProcess[10],
                                            dataToProcess[11],
                                            dataToProcess[12],
                                            dataToProcess[13],
                                            dataToProcess[14],
                                            moment(dataToProcess[15], 'YYYY.MM.DD').format('YYYY-MM-DD').toString(),
                                            dataToProcess[16],
                                        )
                                    );
                                }
                            }
                        }
                    );
                    this.dataWarningList = dataWarningList;
                    if (Object.keys(this.dataWarningList).length === 0) {
                        this.showFinalImport = true;
                    }
                    this.showSpinner = false;
                }
            );
        };
    }

    public onCancelImport(): void {
        this.dataForImport = [];
        this.dataWarningList = {};
        this.showFinalImport = false;
    }

    public onContinueImport(): void {
        this.dataWarningList = {};
        this.showFinalImport = true;
    }
}
