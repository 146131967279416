<div style="height: calc(100% - 65px); width: 50%;">
    <div class="mt-3">
        <mat-form-field class="w-100 mr-4">
            <input type="details" matInput type="details"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.name' | translate }}" [(ngModel)]="employee.name">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" matSuffix
                [ngClass]="showMandatory && !employee.name ? 'mt-2 pt-1 mr-2 icon-cts-information pointer font-size-18 color-red-primary': 'align-middle'">
            </span>
        </mat-form-field>
    </div>
    <div class="d-flex mt-3">
        <div class="ng-select-wrapper w-100 mr-4 d-flex">
            <ng-select [items]="employeeType | keyvalue" [multiple]="false" [clearable]="false" [closeOnSelect]="true"
                [(ngModel)]="employee.type" appendTo="body" bindValue="key" bindLabel="value"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.type' | translate }}">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <ng-option *ngFor="let option of items" [value]="(employee.type ? employee.type : option.key)">
                        {{ ('Rer.rerEmployee.list.type.' + (employee.type ?
                        employeeType[employee.type] : option.value)) | translate }}
                    </ng-option>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <span class="ml-2">
                        {{ 'Rer.rerEmployee.list.type.' + item.value | translate }}
                    </span>
                </ng-template>
            </ng-select>
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !employee.type ?
                    'mt-2 pt-1 mr-2 icon-cts-information pointer font-size-18 color-red-primary': 'align-middle'"
                matSuffix>
            </span>
        </div>
        <mat-form-field class="w-100">
            <input type="details" matInput type="details" (keypress)="removeChars($event)"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.phone' | translate }}" [(ngModel)]="employee.phone">
            <span matTooltip="{{ 'mandatoryField' | translate }}" [matTooltipPosition]="'above'" [ngClass]="showMandatory && !employee.phone ?
                    'mt-2 pt-1 mr-2 icon-cts-information pointer font-size-18 color-red-primary': 'align-middle'"
                matSuffix>
            </span>
        </mat-form-field>
    </div>
    <div class="mt-3">
        <mat-form-field class="w-100">
            <input type="details" matInput type="details" [(ngModel)]="employee.tag1"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.tagX' | translate:{number: 1} }}">
        </mat-form-field>
    </div>
    <div class="mt-3">
        <mat-form-field class="w-100">
            <input type="details" matInput type="details" [(ngModel)]="employee.tag2"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.tagX' | translate:{number: 2} }}">
        </mat-form-field>
    </div>
    <div class="mt-3">
        <mat-form-field class="w-100">
            <input type="details" matInput type="details" [(ngModel)]="employee.tag3"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.tagX' | translate:{number: 3} }}">
        </mat-form-field>
    </div>
    <div class="mt-3">
        <mat-form-field class="w-100">
            <textarea type="details" matInput [(ngModel)]='employee.notes' [ngModelOptions]="{standalone: true}"
                placeholder="{{ 'Rer.rerEmployee.list.dataTable.notes' | translate }}">
            </textarea>
        </mat-form-field>
    </div>
</div>
<div class="d-flex justify-content-between align-items-center button-container">
    <div class="d-flex align-items-center">
        <div *ngIf="showMandatory">
            <i class="icon-cts-information font-size-28 color-red-primary align-middle mr-1"></i>
            <span class="align-middle"> {{ 'mandatoryFieldText' | translate }}</span>
        </div>
    </div>
    <div class="action-buttons d-flex justify-content-between ml-2">
        <div class="button-component utility-green-bg-color ml-2" (click)="employee.id ? onUpdate() : onSave()">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'save' | translate }}</span>
            </div>
        </div>
    </div>
</div>
<div style="top: calc(50% - 50px); left: calc(50% - 50px)"
    class="d-flex justify-content-center align-items-center position-absolute" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>