import { NotificationTypeInterface } from '../../settings/interface/notificationtype.interface';

export class NotificationType implements NotificationTypeInterface {

    constructor(
        public id = null,
        public name = null,
        public icon = null,
    ) { }

}
