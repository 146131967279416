import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { RerService } from '../../../_service/rer.service';

import { RerBinImport } from '../../../_model/rerbinimport.model';
import { RerClientImportAddress } from '../../../_model/rerclientimportaddress.model';
import { ClientDataImportRer } from '../../../_model/clientdataimportrer.model';

import { NotificationComponent } from 'app/shared/notification/notification.component';

@Component({
    selector: 'app-final-import',
    templateUrl: './final-import.component.html',
    styleUrls: ['./final-import.component.scss']
})
export class FinalImportComponent implements AfterViewInit {

    public dataSource: MatTableDataSource<any>;
    public displayedColumns = [
        'type',
        'code',
        'name',
        'referenceDate',
        'phone',
        'addressCode',
        'area',
        'addressName',
        'division',
        'city',
        'street',
        'streetNumber',
        'wasteCode',
        'binCode',
        'binOwnershipCode',
        'quantity',
    ];

    @Input() set dataForImport(value: ClientDataImportRer[]) {
        this.dataSource = new MatTableDataSource(value);
        this.setDataTablePaginatorSort();
    }
    @Output() cancelImportEventEmitter: EventEmitter<number> = new EventEmitter();
    @Output() importDataEventEmitter: EventEmitter<boolean> = new EventEmitter();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;


    constructor(private rerService: RerService,
                public matDialog: MatDialog,
                private route: Router,
                private translationService: TranslateService,)
    { }

    ngAfterViewInit(): void {
        this.setDataTablePaginatorSort();
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    private prepareDataForRequest(data: ClientDataImportRer[]): any {
        const resultData = {};
        for (let i = 0; i< data.length; i++) {
            if(data[i].quantity > 50) {
                return false;
            }

            if (resultData[data[i].code]) {
                const indexAddress = resultData[data[i].code].rer_client_address.findIndex(addr => +addr.code === +data[i].addressCode);

                const object = new RerBinImport( data[i].wasteCode, data[i].binCode, data[i].binOwnershipCode, data[i].quantity );
                if (indexAddress === -1) {
                    resultData[data[i].code].rer_client_address.push(
                        new RerClientImportAddress(
                            data[i].addressCode, data[i].referenceDate, data[i].addressName, data[i].division, data[i].city, data[i].street, data[i].streetNumber, [object])
                    );
                } else {
                    const address = resultData[data[i].code].rer_client_address[indexAddress];
                    if (address) {
                        if (address.rer_bins) {
                            address.rer_bins.push(object);

                            if(address.rer_bins.length > 50) {
                                return false;
                            }
                        } else {
                            address.rer_bins = [object];
                        }
                    }
                }
            } else {
                resultData[data[i].code] = {
                    type: data[i].type,
                    code: data[i].code,
                    name: data[i].name,
                    phone: data[i].phone,
                    rer_client_address: [
                        new RerClientImportAddress(
                            data[i].addressCode, data[i].referenceDate, data[i].addressName, data[i].division, data[i].city, data[i].street, data[i].streetNumber, [], data[i].area)
                    ]
                };
                if (data[i].wasteCode && data[i].binCode) {
                    resultData[data[i].code].rer_client_address[0].rer_bins.push(
                        { rer_waste_type_code: data[i].wasteCode, rer_bin_type_code: data[i].binCode, rer_bin_ownership_code: data[i].binOwnershipCode, quantity: data[i].quantity });
                }
            }
        }

        return resultData;
    }

    public applyFilter(filterValue: string): void {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public onDoImport(): void {
        const requestData = this.prepareDataForRequest(this.dataSource.data);
        if(!requestData) {
            this.importDataEventEmitter.next(false);
            this.matDialog.open(
                NotificationComponent,
                {
                    panelClass: 'custom-dialog-container',
                    maxWidth: '480px',
                    data: {
                        headermessage: this.translationService.instant('error'),
                        message: this.translationService.instant('multipleImportDataError'),
                        success: 0
                    }
                }
            );
        } else {
            this.importDataEventEmitter.next(true);
            this.rerService.importRerClientData({ importData: requestData }).subscribe(
                () => {
                    this.matDialog.open(
                        NotificationComponent,
                        {
                            maxWidth: '510px',
                            panelClass: 'custom-dialog-container',
                            data: {
                                success: 1,
                                headermessage: this.translationService.instant('Rer.dataImportRer.importSuccessHeader'),
                                message: this.translationService.instant('Rer.dataImportRer.importSuccessMessage')
                            }
                        }
                    );
                    this.route.navigate(['rer/management', { outlets: { rercontent: ['rer-client'] } }]);
                },
                () => {
                    this.importDataEventEmitter.next(false);
                    this.matDialog.open(
                        NotificationComponent,
                        {
                            panelClass: 'custom-dialog-container',
                            maxWidth: '480px',
                            data: {
                                headermessage: this.translationService.instant('error'),
                                message: this.translationService.instant('generalError'),
                                success: 0
                            }
                        }
                    );
                }
            );
        }
    }
}
