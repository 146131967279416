import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'app/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'app/modules/model/menuitem.model';
import { RerService } from '../../_service/rer.service';
import { RerCollectionByWeightService } from '../../_service/rercollectionbyweight.service';
import { Observable } from 'rxjs';
import { RerUnloadingArea } from '../../_model/rerunloadingarea.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditUnloadingAreaComponent } from './add-edit-unloading-area/add-edit-unloading-area.component';
import { UserPreferencesService } from 'app/service/userpreferences.service';
import { faFileAlt, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-rer-unloading-areas',
  templateUrl: './rer-unloading-areas.component.html',
  styleUrls: ['./rer-unloading-areas.component.scss']
})
export class RerUnloadingAreasComponent implements OnInit {

  public unloadingAreaList$: Observable<RerUnloadingArea[]>;
  public dataSource: MatTableDataSource<any>;
  public currentFilterValue: string = null;
  public displayedColumns: string[];
  public showSpinner: boolean = false;
  public dateFormat: string;
  public unloadingArea;
  public faFileAlt = faFileAlt;
  public faCheck = faCheck;
  public faTimes = faTimes;

  constructor(private rerCollectionByWeightService: RerCollectionByWeightService,
    public dialog: MatDialog,
    private userPreferencesService: UserPreferencesService) { }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

    ngOnInit() {
      this.dateFormat = this.userPreferencesService.userPreferences.dateFormat ?
        this.userPreferencesService.userPreferences.dateFormat : this.userPreferencesService.defaultPreferences.dateFormat;
      this.displayedColumns = ['name', 'address', 'type', 'center_latitude', 'center_longitude', 'radius', 'coords', 'precalc_values', 'created_at','created_by', 'actions'];
      this.rerCollectionByWeightService.getUnloadingZoneList();
      this.unloadingAreaList$ = this.rerCollectionByWeightService.unloadingAreaList$;

      this.unloadingAreaList$.subscribe(
        (unloadingAreas: RerUnloadingArea[]) => {
            this.dataSource = new MatTableDataSource(unloadingAreas);
            this.setDataTablePaginatorSort();
            if (this.currentFilterValue) {
                this.applyFilter(this.currentFilterValue);
          }
        }
      )
    }

    private setDataTablePaginatorSort(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  
    public applyFilter(filterValue: string): void {
      filterValue = filterValue.trim();
      filterValue = filterValue.toLowerCase();
      this.dataSource.filter = filterValue;
    }
    public addEditUnloadingArea(id: number): void {
      let unloadingArea = this.rerCollectionByWeightService.getUnloadingAreaById(id);
      this.unloadingArea = unloadingArea;
      if (!unloadingArea) {
          unloadingArea = new RerUnloadingArea();
          unloadingArea.id = id;
      }
      
      const dialogRef = this.dialog.open(
          AddEditUnloadingAreaComponent,
          {
              width: '500px',
              data: { unloadingArea: unloadingArea }
          }
      );

      dialogRef.afterClosed().subscribe(
          (response) => {
              if (response) {
                  this.rerCollectionByWeightService.saveUnloadingArea(response)
                      // () => this.rerService.showSaveConfirmation(this.translationService.instant("Rer.rerSettings.savedSuccefuly")),
                      // () => this.rerService.showErrorNotifications(this.translationService.instant("Rer.rerSettings.saveFailed"))
                  
              }
          }
      );
    }

    public activateOrDeactivateArea(id){
        this.rerCollectionByWeightService.activateOrDeactivateArea(id);
    }
    
}