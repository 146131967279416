import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';

import { RerService } from 'app/modules/rer/_service/rer.service';

import { RerBinAction } from '../../../../_constants/rerbinstatusconstants';
import { RerBin } from '../../../../_model/rerbin.model';
import { RerRfidTagAllocation } from '../../../../_model/rerrfidtagallocation.model';
import { RerClient } from '../../../../_model/rerclient.model';

import { ConfirmActionComponent } from 'app/shared/confirm-action/confirm-action.component';
import { BinSuspendDialogComponent } from '../../../../bin-suspend-dialog/bin-suspend-dialog.component';
import { NotificationComponent } from 'app/shared/notification/notification.component';
import { HttpErrorResponse } from '@angular/common/http';
import { StatusCodes } from 'http-status-codes';

@Component({
    selector: 'app-add-edit-bin',
    templateUrl: './add-edit-bin.component.html',
    styleUrls: ['./add-edit-bin.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditBinComponent implements OnInit {

    public isClientSuspended = false;
    public isClientSuspendedByRequest = false;
    public showMandatory: boolean;
    public dataSource: MatTableDataSource<any>;
    public displayedColumns: string[];
    public showSendPVByEmailButton: boolean;
    public showSpinner: boolean;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddEditBinComponent>,
        private rerService: RerService,
        public actionDialog: MatDialog,
        private translationService: TranslateService,
        public matDialog: MatDialog
    ) { }

    ngOnInit() {
        this.showSendPVByEmailButton = false;
        this.data.address.forEach(
            address => {
                address.label = (address.division ? address.division + ' ' : '') + address.city + ' ' +
                (address.street ? address.street + ' ' : '') + (address.street_number ? address.street_number : '')
            }
        );
        
        this.data.object.code = (String('0').repeat(7) + this.data.object.id).substr((7 * -1), 7);
        this.showMandatory = false;

        this.displayedColumns = ['allocation_date', 'object_name', 'period_begin', 'period_end', 'username'];
        if (this.data.object.rer_rfid_tag_id) {
            this.rerService.getRfidTagAllocationLog(this.data.object.rer_rfid_tag_id).subscribe(
                (response: RerRfidTagAllocation[]) => {
                    this.dataSource = new MatTableDataSource(response);
                    this.setDataTablePaginatorSort();
                }
            );
        }

        if (this.data.clientStatus === RerClient.STATUS_SUSPENDED_FOR_NON_PAYMENT) {
            this.isClientSuspended = true;
        } else if (this.data.clientStatus === RerClient.STATUS_SUSPENDED_ON_REQUEST) {
            this.isClientSuspendedByRequest = true;
            this.isClientSuspended = true;
        }

        if(this.data.object.rer_bin_setup_document_id && this.data.object.rer_bin_setup_document_status === 1){
            this.showSendPVByEmailButton = true;
        }
    }

    private setDataTablePaginatorSort(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public onAdd(): void {
        this.showMandatory = (this.data.object.rer_waste_type_id && this.data.object.rer_client_address_id) ? false : true;

        if (!this.showMandatory) {
            if (this.data.object.rer_rfid_tag_id) {
                const rfidTag = this.rerService.getRfidTagById(this.data.object.rer_rfid_tag_id);
                if (rfidTag) {
                    this.data.object.rer_rfid = rfidTag.rfid;
                }
            }

            this.dialogRef.close(this.data.object);
        }
    }

    public onSuspend(): void {
        const dialogRef = this.actionDialog.open(
            BinSuspendDialogComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: { object: this.data.object }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    this.data.object.date = obj.date;
                    this.onChangeBinStatus(RerBin.STATUS_SUSPENDED);
                }
            }
        );
    }

    public onChangeBinStatus(status: number) {
        if (status === RerBin.STATUS_AVAILABLE ||
            status === RerBin.STATUS_LOST ||
            status === RerBin.STATUS_ACTIVE) {
            const dialogRef = this.actionDialog.open(
                ConfirmActionComponent,
                {
                    maxWidth: '800px',
                    panelClass: 'custom-dialog-container',
                    data: {
                        headerMessage: this.translationService.instant('Rer.objects.action.' + RerBinAction[status] + ''),
                        contentMessage: this.translationService.instant('Rer.objects.confirmStatusChange')
                    }
                }
            );

            dialogRef.afterClosed().subscribe(
                (data) => {
                    if (data) {
                        this.data.object.status = status;
                        this.dialogRef.close(this.data.object);
                    }
                }
            );
        } else {
            this.data.object.status = status;
            this.dialogRef.close(this.data.object);
        }
    }

    public sendByEmail(rerBinSetupDocumentId: number): void{
        if (!rerBinSetupDocumentId) {
            return;
        }

        this.showSpinner = true;
        const client = this.rerService.sendBinSetupByEmail(rerBinSetupDocumentId).subscribe(
            () => {
                this.matDialog.open(
                    NotificationComponent,
                    {
                        maxWidth: '510px',
                        panelClass: 'custom-dialog-container',
                        data: {
                            success: 1,
                            headermessage: this.translationService.instant('Rer.dataImportRer.importSuccessHeader'),
                            message: this.translationService.instant('Rer.sendBinSetupByEmail.sendEmailSuccessMessage')
                        }
                    }
                );
                this.showSpinner = false;
            },
            (error: HttpErrorResponse) => {
                let errorMessage = '';
                switch (error.status) {
                    case  StatusCodes.FAILED_DEPENDENCY:
                        errorMessage = 'Rer.sendBinSetupByEmail.clientHasNoEmail';
                        break;
                    case  StatusCodes.NOT_FOUND:
                        errorMessage = 'Rer.sendBinSetupByEmail.failedSend';
                        break;
                    default :
                        errorMessage = 'errorNotification';
                }
                this.matDialog.open(
                    NotificationComponent,
                    {
                        panelClass: 'custom-dialog-container',
                        maxWidth: '480px',
                        data: {
                            headermessage: this.translationService.instant('error'),
                            message: this.translationService.instant(errorMessage),
                            success: 0
                        }
                    }
                );
                this.showSpinner = false;
            }
        );
    }

    public downloadVerbalProcess(documentId: number){
        this.rerService.downloadVerbalProcess(documentId).subscribe({
            next: (response: any) => {
              if (response.data.length !== undefined) {
                const filename = response.filename;
                const content = response.data;
                const filetype = '';
                const byteCharacters = content;
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: filetype });
                this.showSpinner = false;
                saveAs(blob, filename);
              }
            },
            error:(error: any) => this.rerService.showErrorNotifications(this.translationService.instant('errorNotification'))
        });
    }
}
