import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodes } from 'http-status-codes';

import { RerService } from '../_service/rer.service';

import { RerRfidTag } from '../_model/rerrfidtag.model';

import { ConfirmActionComponent } from '../../../shared/confirm-action/confirm-action.component';
import { AddEditComponent } from './add-edit/add-edit.component';

@Component({
    selector: 'app-rfid-tag',
    templateUrl: './rfid-tag.component.html',
    styleUrls: ['./rfid-tag.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RfidTagComponent implements OnInit {

    public selectedTabIndex = 0;
    public showSpinner: boolean;
    public rfidTagTabs: any;

    constructor(
        private rerService: RerService,
        public actionDialog: MatDialog,
        private translationService: TranslateService
    ) { }

    ngOnInit() { }

    public addEditRfidTag(id: number): void {
        let rfidTag = null;
        if (id === 0) {
            rfidTag = new RerRfidTag(null, null, null, null);
        } else {
            rfidTag = this.rerService.getRfidTagById(id);
        }
        const dialogRef = this.actionDialog.open(
            AddEditComponent,
            {
                width: '800px',
                panelClass: 'custom-dialog-container',
                data: rfidTag
            }
        );

        dialogRef.afterClosed().subscribe(
            (result: RerRfidTag) => {
                if (result) {
                    this.showSpinner = true;
                    this.rerService.addEditRfidTag(result).subscribe(
                        (res: { id: number }) => {
                            this.showSpinner = false;
                        },
                        (error: HttpErrorResponse) => {
                            this.showSpinner = false;
                            let errorMessage = '';
                            switch (error.status) {
                                case StatusCodes.FAILED_DEPENDENCY:
                                    errorMessage = 'Rer.rfidManagement.errorDuplicateRfidTag';
                                    break;
                                default:
                                    errorMessage = 'errorNotification';
                            }
                            this.rerService.showErrorNotifications(
                                this.translationService.instant(errorMessage));
                        }
                    );
                }
            }
        );
    }

    public deleteRfidTag(id: number): void {

        const dialogRef = this.actionDialog.open(
            ConfirmActionComponent,
            {
                maxWidth: '800px',
                panelClass: 'custom-dialog-container',
                data: {
                    headerMessage: this.translationService.instant('delete'),
                    contentMessage: this.translationService.instant('Rer.rfidManagement.confirmDeleteAction')
                }
            }
        );

        dialogRef.afterClosed().subscribe(
            (response: boolean) => {
                if (response) {
                    this.showSpinner = true;
                    const rfidTag = this.rerService.getRfidTagById(id);
                    this.rerService.deleteRfidTag(rfidTag.id).subscribe(
                        () => this.showSpinner = false,
                        () => {
                            this.showSpinner = false;
                            this.rerService.showErrorNotifications(
                                this.translationService.instant('errorNotification'));
                        }
                    );
                }
            }
        );
    }
}
