import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { GeofenceGroup } from '../../model/map/geofencegroup.model';
import { Geofence } from '../../model/map/geofence.model';

const ZoomFitBoundsImageSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAACyUlEQVQ4' +
'jX2Vz08TQRTHvzvd3bZLabelBFTAoiRaFMSDiQdIjBdPEk/evXnhbEw0MVFj4pWL8SR/Anj0wAGjHCA20iCIWKoltHTDFmi32+4vM9vWdtst32R3J2/efObNezOzjGVZaNfCKqYD' +
'frz3slbMy8EPCxB9BkaCVVQ1lGWFbB+VyNyda/zn9rEO4KcdiPIplsO9mOqYpS6OMTHRr8LrMfFbYhOTMd9NV+CHr7jIs1iTi4ieKIBhAEEBEHzAYATwcs1BdMiNfgU9nAlZIeW' +
'dQzZ+9zqf/g98tQTRx+OLWkXcLSqPBxgfqcGbVODWYAkssWzowqpw/tksCqTe/aIbjIpGu/kHqGitawOSUm2GsGD6ZyfVZdv8ctGKAUidFC1oes2XRuL3MR1gap8YddqGAlUM91b' +
't9lqan2HVCp4kfhg4KjirHREZjI8RBANNsKIC+xJwIdr0yxR5+0uhkR5znnn41sj/PTCjcBHLArenPA4oVfxirWAOWRZ0HQo5yLvDqHQdWE0YKKvO6H9matE6xDBgOUYgut4N14S' +
'uJ02HrVGkDihd1dm4mmjBNn+Zdk5boRspoD8EePm6X6kOJAyQTR1CUzX0nQvBFwp0QPcyJoIBYGiQOOz542ab7hIi+KEepiVUShWYhol8RsZJTnaN9PuWiUzWdO2j2to1JUIsI1M' +
'+LTs6jqXimdD1pGGnoSFaNFq8qmZlWK9VegRgpX0ghVIFB8Id0JxkIScZ8NcPQGMPXx4hc/ZZnnmc+7aXUlxvmFA04AptVzSMjaXn7KSd4Ugf/0AUubKbI400u5uFh+meO2JUVAq' +
'z2/S1+DqcvjTWE+8GpdXfS+7beWUZHRwx7EdXFJSyebUvUL3a8HVcsMP3UmJsVFjutvx2xUaFxMq7AfcLtlX3nx5NF2RtviBrVwoFzd/aR1chhrltMczNfXwTcf4CAPwDyZ9Cvs3' +
'KCe8AAAAASUVORK5CYII=';
const RouteControlImageSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IA' +
'rs4c6QAAAARnQU1BAACxjwv8YQUAAAGuSURBVHgB7Za/SwJhGMe/dx1h+aPUIMXGamiIWgId2lJpqujHH+CSErREU1D+A05RDfUHFA1NQls4VNQgQS2aW3ZFaKCFEXKX7wVHer/s' +
'zhLEz3LH+/Lw4Xme93nvKL4CmgCNJtEWt76YQQMoloCnPI9kBkhlOaSzQPqRx3mMabz4KMELkuQ9wL5KJ9LtUI/XLWYrGcavla8Al4NSC6+vx4V3TrK2OkNjaVI53GKCMfHFzQcC' +
'4SyCkWzVOpsHErecYpzbSMbRvRwW11k8PJdxl/lEdDcn7q3slAW5EtYuqKLYY5Ihkf1k/6SA+YAVl2lGVkpk5IQTdGfs93VL1gb6GTh6O3FwKi0xOcXHG4zYd5cdqlBqX6farGNr' +
'fThLdVfmlZdItyOMOELxKw7jg7TqSKn2eHPZKb6TbMdGrBIpIeSvlkxP0JpzrCr2jpoQ8H6XPDRrw2FCvsRE9Fs0I0JzPcIz6DPLjg/JVg+aUSTrhSkLXoodeCtJ90kv/0RM2Ao7' +
'hUu/liGP9p1sSGwz07Jz67JT0EvddSrKlHnY8w/iRmNIbDVBN1T7v7otbjnxF5x6hUxXOfNDAAAAAElFTkSuQmCC';
const TrafficLayerControlImageSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAA2' +
'klEQVQ4jc1V0QmDMBQ8S/8KTpEBWnQAN3CE1gz2qCO4h9IO4BIV+m2JxmAfL8YPiz0IHOHlzN2JRn3fY0scJS2t9R3ANfCckohufPPgGQ6JeWecZa216D1v38MyqNRpWBKIKOI3L' +
'H2CEmdwZ52gzaMA8Fphd4KZLeZZfmVIRKaMbL43tyjYzewZB/G18eXJMeUmCnpLSVvkSTvesFGoarUoHi7FinHOsGcpjRK5xR+U8khjPJN44Oemw6XuFsWDpUxinDPsWIqxKXGL3' +
'5fi+x6Kea6Z2fYXAOADBumEdXsOaKQAAAAASUVORK5CYII=';
const HuGoControlImageSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAABWklEQVQ4j' +
'c1VsW7CMBB9Qex0bRY6dS1/AH9AZ0uWmDvRHQlH6k4mz1RRM+cT2j+gK1NZPMMXpLrjgozBKVKp1Cc5Od+dX+7ZlySp324NgDmug4wI6yuRMTp8VS5pvfsgn+9v5uLrHAViCEnKt' +
'D6ZH1UYOE8eQDE/Hj7cm//RHv5nwu4FOTsAudhTAL3fEBLZCMqteFamFYD3NtKfCA2RaWO5wsK4KcqU3qxFbEHbHn5AuVwbOwLwSJWyrVzOsQhibUNS7/R6RjbJ7Yt/A2BQ3L+Q/' +
'XVOeqzCCZTbAlgK2bOMPvv2scmlkl+hXKWNJZlj8a1kEMYcU67i3AChZJak17MbIYid5k6kb4Mt4QqziNSGbFOYp4QGgE/x9SLSsy6UozagwdDGUvMOvaRGKoEO4kHsIeUWhk/98' +
'HE42kNt7OBMj/mEvk1YyJoDwsYmqSHm2ti2XwSt2ZMC+AasF4cvAOnqOwAAAABJRU5ErkJggg==';

@Injectable()
export class GoogleMapCustomControl {
    static readonly fullscreenControlSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X' +
    '78AAAATElEQVQ4jWNMTEz8z4ADzJs3jxGbTFJSEk49TLgkyAVUNxDFy7i8SAggB8Hg9zL1w/D/f5wpgCwwAsNwNKdQDoZYGKKDYVjAMjAwAAAeTBupKq76lwAAAABJRU5Er' +
    'kJggg==';
    static readonly zoomOutControllSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X7' +
    '8AAAAQklEQVQ4jWP8//8/AzUBE1VNGxIGshBSkJSUhBLI8+bNY8SnfgSGIUY6RA8zQgA9TEfDEBOMpkOCYLQ8pBAwMDAAAKxLGBse4He6AAAAAElFTkSuQmCC';
    static readonly zoomInControllSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AA' +
    'AANUlEQVQ4jWP8//8/AzUBE1VNGzVwhBjIgi6QlJREUsKcN28eIzJ/BIbhaF4eNXDADWRgYAAAhsMKI8TqIIYAAAAASUVORK5CYII=';
    static readonly checkBoxUnchecked = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhd' +
    'G9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0' +
    'iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD' +
    '0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlI' +
    'j4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNCNEJFQzg7fQ0KCS5zdDF7ZmlsbDojRkZGRkZGO30NCgkuc3Qye2ZpbGw6IzI3NDM5Nzt9DQo8L3N0' +
    'eWxlPg0KPGcgaWQ9IlhNTElEXzEwMjZfIj4NCgk8ZyBpZD0iWE1MSURfMTE3MF8iPg0KCQk8cGF0aCBpZD0iWE1MSURfMTE4MF8iIGNsYXNzPSJzdDAiIGQ9Ik0xNCwyYzA' +
    'tMS4xLTAuOS0yLTItMkgyQzAuOSwwLDAsMC45LDAsMnYxMGMwLDEuMSwwLjksMiwyLDJoMTBjMS4xLDAsMi0wLjksMi0yVjJ6Ii8+DQoJCTxwYXRoIGlkPSJYTUxJRF8xM' +
    'TczXyIgY2xhc3M9InN0MSIgZD0iTTIsMTNjLTAuNTUsMC0xLTAuNDUtMS0xVjJjMC0wLjU1LDAuNDUtMSwxLTFoMTBjMC41NSwwLDEsMC40NSwxLDF2MTBjMCwwLjU1LTAu' +
    'NDUsMS0xLDFIMg0KCQkJeiIvPg0KCTwvZz4NCgk8ZyBpZD0iWE1MSURfMTEzMF8iPg0KCQk8Zz4NCgkJCTxwb2x5Z29uIGlkPSJYTUxJRF85XyIgY2xhc3M9InN0MiIgcG9' +
    'pbnRzPSIzLjM2LDcuMTUgNC40Miw2LjA5IDYuMDQsNy43MSA5LjU4LDQuMTcgMTAuNjQsNS4yMyA2LjA0LDkuODMgCQkJIi8+DQoJCTwvZz4NCgk8L2c+DQo8L2c+DQo8L3' +
    'N2Zz4NCg==';
    static readonly checkBoxChecked = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG' +
    '9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMy4wLjIsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iM' +
    'S4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0i' +
    'MHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxNCAxNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTQgMTQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4' +
    'NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNCNEJFQzg7fQ0KCS5zdDF7ZmlsbDojRkZGRkZGO30NCjwvc3R5bGU+DQo8ZyBpZD0iWE1MSURfMTkwOF' +
    '8iPg0KCTxwYXRoIGlkPSJYTUxJRF8xOTIwXyIgY2xhc3M9InN0MCIgZD0iTTE0LDJjMC0xLjEtMC45LTItMi0ySDJDMC45LDAsMCwwLjksMCwydjEwYzAsMS4xLDAuOSwyL' +
    'DIsMmgxMGMxLjEsMCwyLTAuOSwyLTJWMnoiLz4NCgk8cGF0aCBpZD0iWE1MSURfMTkxMV8iIGNsYXNzPSJzdDEiIGQ9Ik0yLDEzYy0wLjU1LDAtMS0wLjQ1LTEtMVYyYzAt' +
    'MC41NSwwLjQ1LTEsMS0xaDEwYzAuNTUsMCwxLDAuNDUsMSwxdjEwYzAsMC41NS0wLjQ1LDEtMSwxSDJ6DQoJCSIvPg0KPC9nPg0KPC9zdmc+DQo=';
    static readonly innerHTML = '<span role="checkbox" style="vertical-align: middle;"><img src="' +
    GoogleMapCustomControl.checkBoxUnchecked + '" style="height: 1em; width: 1em; transform: translateY(0.15em); display: none;">' +
    '<img src="' + GoogleMapCustomControl.checkBoxChecked + '" style="height: 1em; width: 1em; transform: translateY(0.15em);"></span>' +
    '<label style="vertical-align: middle; cursor: pointer;">';

    public geofenceGroupCheckboxActionSubject: Subject<{ checked: boolean, id: number }> = new Subject();
    public geofenceCheckboxActionSubject: Subject<{ checked: boolean, id: number }> = new Subject();
    public toggleRouteActionSubject: Subject<boolean> = new Subject();

    constructor(private translateService: TranslateService) { }

    public createTrafficLayerControl(trafficLayer: google.maps.TrafficLayer, map: google.maps.Map) {

        const centerControlDiv = document.createElement('div');
        centerControlDiv.style.backgroundColor = '#fff';
        centerControlDiv.style.padding = '5px';
        centerControlDiv.style.cursor = 'pointer';
        centerControlDiv.style['-webkit-background-clip'] = 'padding';
        centerControlDiv.style['background-clip'] = 'padding-box';
        centerControlDiv.style.outline = '4px solid rgba(0,0,0,0.1)';
        centerControlDiv.className = 'traffic-layer-top';
        centerControlDiv.style.filter = 'grayscale(100%)';
        this.translateService.get('Map.mapcontrols.traffic').subscribe(res => centerControlDiv.title = res);

        const controlUI = document.createElement('img');
        controlUI['src'] = TrafficLayerControlImageSrc;
        controlUI['style'].width = '20px';
        controlUI['style'].height = '20px';

        centerControlDiv.appendChild(controlUI);
        centerControlDiv.addEventListener('click', () => {
            if (trafficLayer.getMap() == null) {
                centerControlDiv.style.filter = 'grayscale(0%)';
                trafficLayer.setMap(map);
                if (map.getMapTypeId() !== 'roadmap') {
                    map.setMapTypeId('roadmap');
                }
            } else {
                centerControlDiv.style.filter = 'grayscale(100%)';
                trafficLayer.setMap(null);
            }
        });

        //  If MapTypeId is changed veryfy if traffic layer is on
        map.addListener('maptypeid_changed', function (e) {
            if (this.getMapTypeId() !== 'roadmap' && trafficLayer.getMap() !== undefined) {
                centerControlDiv.style.filter = 'grayscale(100%)';
                trafficLayer.setMap(null);
            }
        });
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv);
    }

    public createZoomFitBoundsControl(map: google.maps.Map, markers: google.maps.Marker[], routebounds?: google.maps.LatLngBounds) {

        const centerControlDiv = document.createElement('div');
        centerControlDiv.style.backgroundColor = '#fff';
        centerControlDiv.style.padding = '5px';
        centerControlDiv.style.cursor = 'pointer';
        centerControlDiv.style['-webkit-background-clip'] = 'padding';
        centerControlDiv.style['background-clip'] = 'padding-box';
        centerControlDiv.style.outline = '4px solid rgba(0,0,0,0.1)';
        centerControlDiv.className = environment.isGoogleMapsAvailable ? 'fit-to-bounds-top' : 'fit-to-bounds-top-wgm';
        this.translateService.get('Map.mapcontrols.fittobounds').subscribe(res => centerControlDiv.title = res);

        const controlUI = document.createElement('img');
        controlUI['src'] = ZoomFitBoundsImageSrc;
        controlUI['style'].width = '20px';
        controlUI['style'].height = '20px';

        centerControlDiv.appendChild(controlUI);

        centerControlDiv.addEventListener('click', () => {
            if (routebounds) {
                map.fitBounds(routebounds);
            } else {
                const bounds = new google.maps.LatLngBounds();
                markers.forEach(
                    (marker) => {
                        if (marker.getMap()) {
                            bounds.extend(marker.getPosition());
                        }
                    }
                );
                map.fitBounds(bounds);
            }
        });

        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv);
    }

    public createToogleRouteControl(map: google.maps.Map) {

        const centerControlDiv = document.createElement('div');
        centerControlDiv.style.backgroundColor = '#fff';
        centerControlDiv.style.padding = '0px';
        centerControlDiv.style.cursor = 'pointer';
        centerControlDiv.style['-webkit-background-clip'] = 'padding';
        centerControlDiv.style['background-clip'] = 'padding-box';
        centerControlDiv.style.outline = '4px solid rgba(0,0,0,0.1)';
        centerControlDiv.className = 'toggle-route-top';
        centerControlDiv.style.filter = 'grayscale(100%)';

        const controlUI = document.createElement('img');
        controlUI['src'] = RouteControlImageSrc;
        controlUI['style'].width = '30px';
        controlUI['style'].height = '30px';

        centerControlDiv.appendChild(controlUI);

        centerControlDiv.addEventListener('click', () => {
            if (centerControlDiv.style.filter === 'grayscale(0%)') {
                centerControlDiv.style.filter = 'grayscale(100%)';
            } else {
                centerControlDiv.style.filter = 'grayscale(0%)';
            }
            this.toggleRouteActionSubject.next(true);
        });

        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv);
    }

    public createHuGoControl(map: google.maps.Map) {

        const centerControlDiv = document.createElement('div');

        centerControlDiv.style.backgroundColor = '#fff';
        centerControlDiv.style.padding = '5px';
        centerControlDiv.className = 'hu-go-top';
        centerControlDiv.style.cursor = 'pointer';
        centerControlDiv.style['-webkit-background-clip'] = 'padding';
        centerControlDiv.style['background-clip'] = 'padding-box';
        centerControlDiv.style.outline = '4px solid rgba(0,0,0,0.1)';
        centerControlDiv.style.filter = 'grayscale(100%)';
        this.translateService.get('Map.mapcontrols.hugo').subscribe(res => centerControlDiv.title = res);

        const controlUI = document.createElement('img');
        controlUI['src'] = HuGoControlImageSrc;
        controlUI['style'].width = '20px';
        controlUI['style'].height = '20px';

        centerControlDiv.appendChild(controlUI);
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(centerControlDiv);
        return centerControlDiv;
    }

    public toggleControlCheckBox(control: HTMLElement): void {
        if (control.firstChild.firstChild['style'].display === 'none') {
            control.firstChild.lastChild['style'].display = 'none';
            control.firstChild.firstChild['style'].display = '';
        } else {
            control.firstChild.firstChild['style'].display = 'none';
            control.firstChild.lastChild['style'].display = '';
        }
    }

    private toggleGeofencesContainer(): void {
        const geofencesContainer  = document.querySelector('#geofencesContainer');
        if (geofencesContainer.classList.contains('d-none')) {
            geofencesContainer.className = '';
        } else {
            geofencesContainer.className = 'd-none';
        }
    }

    private createCheckboxContainer() {
        const checkoxContainer = document.createElement('div');
        checkoxContainer.style.display = 'flex';
        checkoxContainer.style.flexShrink = '0';
        checkoxContainer.style.justifyContent = 'center';
        checkoxContainer.style.alignItems = 'center';
        checkoxContainer.style.height = '36px';
        checkoxContainer.style.width = '36px';
        checkoxContainer.style.backgroundColor = '#FAFAFA';
        checkoxContainer.style.borderBottom = '1px solid #ECEEF0';

        return checkoxContainer;
    }

    private createCheckboxImage(type: string, id: string) {
        const checkoxImage = document.createElement('img');
        checkoxImage.src = 'assets/images/map/checkbox-unchecked.png';
        checkoxImage.setAttribute('data-type', type);
        checkoxImage.style.cursor = 'pointer';

        if (type === 'group') {
            checkoxImage.setAttribute('data-group-id', id);
        } else {
            checkoxImage.setAttribute('data-geofence-id', id);
        }

        checkoxImage.addEventListener('click', (event) => {
            let dataId: number;
            if (event.target['attributes']['data-type']['value'] === 'group') {
                dataId = +event.target['attributes']['data-group-id']['value'];
                this.geofenceGroupCheckboxActionSubject.next({ checked: null, id: dataId });
            } else {
                dataId = +event.target['attributes']['data-geofence-id']['value'];
                this.geofenceCheckboxActionSubject.next({ checked: null, id: dataId });
            }
        });

        return checkoxImage;
    }

    private createNameContainer(type: string, name: string) {
        const nameContainer = document.createElement('div');
        nameContainer.style.height = '36px';
        nameContainer.style.display = 'flex';
        nameContainer.style.alignItems = 'center';
        nameContainer.style.paddingLeft = '8px';
        nameContainer.style.paddingRight = '8px';
        nameContainer.style.flexGrow = '1';
        nameContainer.style.overflow = 'hidden';

        const nameSpan = document.createElement('span');
        nameSpan.textContent = name;
        nameSpan.style.fontSize = '13px';
        nameSpan.style.color = '#5A6478';
        nameSpan.style.whiteSpace = 'nowrap';
        nameSpan.style.overflow = 'hidden';
        nameSpan.style.textOverflow = 'ellipsis';
        if (type === 'group') {
            nameContainer.style.borderBottom = '1px solid #8C96AA';
            nameSpan.style.fontFamily = 'Montserrat';
            nameSpan.style.fontWeight = 'bold';
            nameSpan.style.textTransform = 'uppercase';
        } else {
            nameSpan.style.fontFamily = 'Open Sans';
            nameContainer.style.borderBottom = '1px solid #ECEEF0';
        }

        nameContainer.appendChild(nameSpan);

        return nameContainer;
    }

    private createItemContainer(type: string, item: any) {
        const itemContainer = document.createElement('div');
        itemContainer.style.display = 'flex';
        itemContainer.style.flexBasis = '100%';
        const checkoxContainer = this.createCheckboxContainer();
        const checkoxImage = this.createCheckboxImage(type, item.id);
        checkoxContainer.appendChild(checkoxImage);

        const nameContainer = this.createNameContainer(type, item.name);

        itemContainer.appendChild(checkoxContainer);
        itemContainer.appendChild(nameContainer);
        itemContainer.setAttribute('id', type + '-' + item.id);

        return itemContainer;
    }

    private createGeofenceGroupContainer(geofenceGroup: GeofenceGroup) {
        const geofenceGroupContainer = document.createElement('div');
        geofenceGroupContainer.style.width = '100%';
        geofenceGroupContainer.style.borderBottom = '1px solid #8C96AA';
        geofenceGroupContainer.style.backgroundColor = '#FFFFFF';
        geofenceGroupContainer.setAttribute('id', 'geofence-group-' + geofenceGroup.id);

        let itemContainer = this.createItemContainer('group', geofenceGroup);
        geofenceGroupContainer.appendChild(itemContainer);

        //  add groupgeofences
        geofenceGroup.geofences.forEach(
            (geofence: Geofence) => {
                itemContainer = this.createItemContainer('geofence', geofence);
                geofenceGroupContainer.appendChild(itemContainer);
            }
        );

        return geofenceGroupContainer;
    }

    private searchInputEventListener(value: string, geofenceDataList: GeofenceGroup[]): void {
        geofenceDataList.forEach(
            (data) => {
                if (!data.geofences || data.geofences.length === 0) {
                    return;
                }

                const group = document.querySelector('#geofence-group-' + data.id);
                if (!group) {
                    return;
                }

                if (
                    data.name.toUpperCase().indexOf(value.toUpperCase()) !== -1 ||
                    (data.description && data.description.toUpperCase().indexOf(value.toUpperCase()) !== -1) ||
                    value.length === 0
                ) {
                    if (group.classList.contains('d-none')) {
                        group.classList.remove('d-none');
                    }
                } else {
                    let isGrupVisible = false;
                    data.geofences.forEach(
                        (geofence: Geofence) => {
                            const geofenceElement = document.querySelector('#geofence-' + geofence.id);
                            if (
                                geofence.name.toUpperCase().indexOf(value.toUpperCase()) !== -1 ||
                                (
                                    geofence.description &&
                                    geofence.description.toUpperCase().indexOf(value.toUpperCase()) !== -1
                                ) ||
                                (
                                    geofence.address &&
                                    geofence.address.address_formatted &&
                                    geofence.address.address_formatted.toUpperCase().indexOf(value.toUpperCase()) !== -1
                                ) ||
                                value.length === 0
                            ) {
                                if (geofenceElement.classList.contains('d-none')) {
                                    geofenceElement.classList.remove('d-none');
                                }
                                isGrupVisible = true;
                            } else {
                                geofenceElement.classList.add('d-none');
                            }
                        }
                    );
                    if (isGrupVisible) {
                        if (group.classList.contains('d-none')) {
                            group.classList.remove('d-none');
                        }
                    } else {
                        group.classList.add('d-none');
                    }
                }
            }
        );
    }

    private createGeofenceSearchElement(geofenceDataList: GeofenceGroup[]) {
        const geofenceSearchContainer = document.createElement('div');
        geofenceSearchContainer.style.width = '100%';
        geofenceSearchContainer.style.borderBottom = '1px solid #8C96AA';
        geofenceSearchContainer.style.padding = '8px';
        geofenceSearchContainer.style.backgroundColor = '#FFFFFF';
        geofenceSearchContainer.style.position = '-webkit-sticky';
        geofenceSearchContainer.style.position = 'sticky';
        geofenceSearchContainer.style.top = '0';

        const searchInput = document.createElement('input');
        searchInput.style.fontFamily = 'Open Sans';
        searchInput.style.fontSize = '13px';
        searchInput.style.color = '#5A6478';
        searchInput.style.padding = '2px 5px';
        searchInput.style.width = '100%';

        searchInput.addEventListener('input', (event) => {
            const value = event.target['value'];
            this.searchInputEventListener(value, geofenceDataList);
        });

        geofenceSearchContainer.appendChild(searchInput);

        return geofenceSearchContainer;
    }

    public createGeofenceControl(map: google.maps.Map, geofenceDataList: GeofenceGroup[]): void {
        const elem = document.querySelector('.geofence-control-container');
        if (elem) {
            elem.parentNode.removeChild(elem);
        }

        const geofenceControlContainer = document.createElement('div');
        geofenceControlContainer.className = 'geofence-control-container';
        geofenceControlContainer.style.backgroundColor = '#fff';
        geofenceControlContainer.style.padding = '5px';
        geofenceControlContainer.style.marginTop = '8px';
        geofenceControlContainer.style.marginRight = '8px';
        geofenceControlContainer.style.height = '30px';
        geofenceControlContainer.style.width = '150px';
        geofenceControlContainer.style.border = '1px solid #B4BEC8';
        geofenceControlContainer.style['-webkit-background-clip'] = 'padding';
        geofenceControlContainer.style['background-clip'] = 'padding-box';
        geofenceControlContainer.style.outline = '4px solid rgba(0,0,0,0.1)';

        const divcnt = document.createElement('div');

        const divtxtarrow = document.createElement('div');
        divtxtarrow.addEventListener('click', this.toggleGeofencesContainer);

        divtxtarrow.style.fontFamily = 'Roboto, Arial, sans-serif';
        divtxtarrow.style.position = 'relative';
        divtxtarrow.style.fontSize = '13px';
        divtxtarrow.style.color = '#004B7D';
        divtxtarrow.style.cursor = 'pointer';
        divtxtarrow.style.fontWeight = 'bold';
        divtxtarrow.textContent = 'Geofence';
        divtxtarrow.style.userSelect = 'none';

        const arrowImage = document.createElement('img');
        arrowImage.src = 'https://maps.gstatic.com/mapfiles/arrow-down.png';
        arrowImage.style.border = '0px';
        arrowImage.style.padding = '0px';
        arrowImage.style.margin = '-2px 0px 0px';
        arrowImage.style.position = 'absolute';
        arrowImage.style.top = '50%';
        arrowImage.style.right = '6px';
        arrowImage.style.width = '7px';
        arrowImage.style.height = '4px';
        arrowImage.style.userSelect = 'none';
        divtxtarrow.appendChild(arrowImage);

        divcnt.appendChild(divtxtarrow);

        const geofencesContainer = document.createElement('div');
        geofencesContainer.id = 'geofencesContainer';
        geofencesContainer.className = 'd-none';
        geofencesContainer.style.maxHeight = '200px';
        geofencesContainer.style.overflow = 'auto';
        geofencesContainer.style.width = '100%';
        geofencesContainer.style.position = 'absolute';
        geofencesContainer.style.borderTop = '1px solid #8C96AA';
        geofencesContainer.style.backgroundColor = '#ffffff';
        geofencesContainer.style.marginLeft = '-4px';
        geofencesContainer.style.marginTop = '14px';

        const geofenceSearchContainer = this.createGeofenceSearchElement(geofenceDataList);
        geofencesContainer.appendChild(geofenceSearchContainer);

        geofenceDataList.forEach(
            (geofenceGroup: GeofenceGroup) => {
                if (geofenceGroup.geofences && geofenceGroup.geofences.length > 0) {
                    const geofenceGroupContainer = this.createGeofenceGroupContainer(geofenceGroup);
                    geofencesContainer.appendChild(geofenceGroupContainer);
                }
            }
        );
        divcnt.appendChild(geofencesContainer);
        geofenceControlContainer.appendChild(divcnt);
        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(geofenceControlContainer);
    }
}
