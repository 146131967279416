<h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18">
    <span>{{ 'upgradeRequired.header' | translate }}</span>
</h4>
<div mat-dialog-content class="p-4 content">
    {{ 'upgradeRequired.content' | translate }}
</div>
<div mat-dialog-actions class="mt-4 p-3">
    <div class="buttons d-flex align-items-center justify-content-center pt-3">
        <div class="button-component bgcolor-white" (click)="dialogRef.close(false)">
            <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-grey m-0">{{ 'cancel' | translate }}</span>
            </div>
        </div>
        <div
            class="button-component ml-2 utility-green-bg-color"
            (click)="dialogRef.close(true)">
            <div class="inner inner-green user-no-select pb-2 pt-2 pl-4 pr-4">
                <span class="color-white ml-0">{{ 'ok' | translate }}</span>
            </div>
        </div>
    </div>
</div>
