import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-tags-import',
    templateUrl: './tags-import.component.html',
    styleUrls: ['./tags-import.component.scss']
})
export class TagsImportComponent implements OnInit {

    public showSpinner: boolean;
    public fileColumns: string[];
    public dataList = {};
    public Object;
    public showFinalImport: boolean;
    public fileName: string;

    constructor() { }

    ngOnInit() {
        this.Object = Object;
    }

    private isImportLineValid(importLine: string) {
        let foundRfids = [];
        const pattern = /E[a-zA-Z0-9]{23}/g;
        let validRfids = importLine.match(pattern);
        if (validRfids) {
            foundRfids = validRfids;
        }
        return foundRfids;
    }

    public onInportFileChanged(event: any): void {
        this.showSpinner = true;
        const file = event.target.files[0];
        const reader = new FileReader();
        this.fileName = file.name;
        reader.readAsText(file);
        reader.onload = () => {
            const csvData = reader.result;
            const csvRecordsArray = (<string>csvData).split(/\r\n|\n/);
            const dataList = {};
            csvRecordsArray.forEach(
                (line: string, index: number) => {
                    if (line.length > 0) {
                        const dataToProcess = line;
                        const data = this.isImportLineValid(dataToProcess);
                        dataList[index+1] = data;
                    }
                }
            );
            this.showSpinner = false;
            this.dataList = dataList;
        };
    }

    public onCancelContinueImport(): void {
        this.dataList = [];
        this.dataList = {};
    }
}
