import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { RerIncident } from 'app/modules/rer/_model/rerincident.model';
import { UserPreferencesService } from 'app/service/userpreferences.service';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

    @Input() incident: RerIncident;
    public dateFormat: string;

    constructor(
        public translateService: TranslateService,
        private userPreferencesService: UserPreferencesService) { }

    ngOnInit() {
        this.dateFormat = this.userPreferencesService.userPreferences.dateFormat;

    }
    
}