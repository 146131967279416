import { RerEmployeeInterface } from '../_interface/reremployee.interface';

export class RerEmployee implements RerEmployeeInterface {
    static readonly TYPE_DRIVER = 1;
    static readonly TYPE_OPERATOR = 2;

    public constructor(
        public id = null,
        public type = null,
        public name = null,
        public phone = null,
        public tag1 = null,
        public tag2 = null,
        public tag3 = null,
        public notes = null,
    ) { }
}
