import { TagHistoryDialogComponent } from './tag-history-dialog/tag-history-dialog.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { saveAs } from 'file-saver';

import { RerService } from '../_service/rer.service';
import { EndpointsService } from 'app/service/endpoints.service';

import { RerWasteType } from '../_model/rerwastetype.model';

import { NotificationComponent } from 'app/shared/notification/notification.component';
import { ExportPeriodDialogComponent } from './export-period-dialog/export-period-dialog.component';
import { CalendarInvoiceableReadingsDialogComponent } from './calendar-invoiceable-readings-dialog/calendar-invoiceable-readings-dialog.component';
import { CalendarInvoiceableAreaDialogComponent } from './calendar-invoiceable-area-dialog/calendar-invoiceable-area-dialog.component';
import { AuthService } from 'app/service/auth.service';
import { RerArea } from '../_model/rerarea.model';
import { FilterClientAreaOwnershipDialogDialogComponent } from './filter-client-area-ownership-dialog/filter-client-area-ownership-dialog.component';
import { RerBinOwnership } from './../_model/rerbinownership';
import { BinSetupDialogComponent } from './bin-setup-dialog/bin-setup-dialog.component';

@Component({
    selector: 'app-data-export-rer',
    templateUrl: './data-export-rer.component.html',
    styleUrls: ['./data-export-rer.component.scss']
})
export class DataExportRerComponent implements OnInit, OnDestroy {

    public showSpinner: boolean;
    public wasteTypes: RerWasteType[];
    public areas: RerArea[];
    public binOwnerships: RerBinOwnership[];
    public clientTypes: String[];
    public hasCalendarInvoiceableReadingsRight: boolean = false;
    public hasExportTagHistoryRight: boolean =  false;
    public hasExportBinSetupsRight: boolean =  false;

    constructor(
        private rerService: RerService,
        private translationService: TranslateService,
        private endpointsService: EndpointsService,
        private httpClient: HttpClient,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.rerService.wasteTypeList$.subscribe(result => {
            this.wasteTypes = result;
        });
        this.rerService.areaList$.subscribe(result => {
            this.areas = result;
        });
        this.rerService.binOwnershipList$.subscribe(result => {
            this.binOwnerships = result;
        });

        this.rerService.typeFilterList$.subscribe(result => {
            this.clientTypes = result;
        });
        if (this.rerService.hasRight(AuthService.USER_RIGHT_RER_CALENDAR_INVOICEABLE_READINGS_EXPORT)) {
            this.hasCalendarInvoiceableReadingsRight = true;
        }

        if(this.rerService.hasRight(AuthService.USER_RIGHT_RER_EXPORT_TAG_HISTORY)) {
            this.hasExportTagHistoryRight = true;
        }

        if(this.rerService.hasRight(AuthService.USER_RIGHT_RER_EXPORT_BIN_SETUPS)) {
            this.hasExportBinSetupsRight = true;
        }
    }

    ngOnDestroy() {
    }

    public doExportBinSetups():void {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            BinSetupDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    if(!obj.status) {
                        obj.status = -1;
                    }
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerBinSetups', [
                        obj.exportDates.startDate, obj.exportDates.endDate, obj.status]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                const date = moment().local().format('YYYY-MM-DD HH.mm');
                                let fileName: any = this.translationService.instant('Rer.dataExportRer.exportRerBinSetupsFilename',
                                    { period_begin: obj.exportDates.startDate, period_end: obj.exportDates.endDate, date: date });
                                fileName = fileName.replaceAll(' ', '_') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportTagHistory():void {
        this.showSpinner = true;
        if(this.hasExportTagHistoryRight) {

            const dialogRef = this.dialog.open(
                TagHistoryDialogComponent,
                {
                    width: '780px',
                    disableClose: true,
                    panelClass: 'custom-dialog-container',
                }
            );

            dialogRef.afterClosed().subscribe(
                (obj: any) => {
                    if (obj) {
                        let fileName = this.translationService.instant('Rer.dataExportRer.exportRerRfidTagHistory');
                        let params = new HttpParams().set("rfidTag", String(obj.rfidTag));
                        const httpOptions = {
                            headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                            responseType: 'arrayBuffer' as 'json',
                            params: params
                        };


                        this.httpClient.get(this.endpointsService.get('rer.exportRerTagHistory'), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + moment().format('YYYY-MM-DD') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        )
                    } else {
                        this.showSpinner = false;
                    }
                }
            );
        } else {
            this.showSpinner = false;
            this.showNotification();
        }
    }

    private showNotification(): void {
        this.showSpinner = false;
        this.dialog.open(
            NotificationComponent,
            {
                maxWidth: '800px', panelClass: 'custom-dialog-container',
                data: {
                    success: 0,
                    headermessage: this.translationService.instant('error'),
                    message: this.translationService.instant('generalError')
                }
            }
        );
    }

    public doExport(type): void {
        this.showSpinner = true;
        const httpOptions = {
            headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
            responseType: 'arrayBuffer' as 'json'
        };

        let exportUrl = '';
        let fileName: any = 'export';
        switch (type) {
            case 'unallocated':
                exportUrl = 'rer.exportRerUnalocatedData';
                fileName = this.translationService.instant('Rer.dataExportRer.exportUnallocatedData');
                break;
        }

        this.httpClient.get(this.endpointsService.get(exportUrl), httpOptions).subscribe(
            (data: ArrayBuffer) => {
                const blob = new Blob([data], { type: 'application/octet-stream' });
                fileName = fileName.replaceAll(' ', "_") + "_"
                    + moment().format('YYYY-MM-DD') + '.csv';
                saveAs(blob, fileName);
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
                this.showNotification();
            }
        );
    }

    public doExportRerData() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            FilterClientAreaOwnershipDialogDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    areas: this.areas,
                    clientTypes: this.clientTypes,
                    binOwnerships: this.binOwnerships
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    let params = new HttpParams().set("client_type", String(obj.clientType))
                    .set("areaId", obj.areaId)
                    .set("binOwnershipId", String(obj.binOwnershipId));
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                        params: params
                    };

                    this.httpClient.get(this.endpointsService.get('rer.exportRerData'), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                const date = moment().local().format('YYYY-MM-DD HH.mm');
                                let fileName: any = this.translationService.instant('Rer.dataExportRer.exportCsv');
                                fileName = fileName.replaceAll(' ', '_') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportIllegitimate() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {}
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerIlleditimateReadings', [
                        obj.startDate, obj.endDate]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                let fileName: any = this.translationService.instant('Rer.dataExportRer.exportIllegitimateReadings');
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + moment().format('YYYY-MM-DD') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportDetailed() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            CalendarInvoiceableAreaDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    areas: this.areas
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    if(!obj.areaId) {
                        obj.areaId = 0;
                    }
                    if(!obj.invoiceable) {
                        obj.invoiceable = -1;
                    }
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerDetailedReadings', [
                        obj.exportDates.startDate, obj.exportDates.endDate, obj.areaId, obj.invoiceable]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                const date = moment().local().format('YYYY-MM-DD HH.mm');
                                let fileName: any = this.translationService.instant('Rer.dataExportRer.exportRerDetailedReadingsFilename',
                                    { period_begin: obj.exportDates.startDate, period_end: obj.exportDates.endDate, date: date });
                                fileName = fileName.replaceAll(' ', '_') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportInvoicing() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {}
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerInvoicingReadings', [
                        obj.startDate, obj.endDate]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                const date = moment().local().format('YYYY-MM-DD HH.mm');
                                let fileName: any = this.translationService.instant('Rer.dataExportRer.exportRerInvoicingReadingsFilename',
                                    { period_begin: obj.startDate, period_end: obj.endDate, date: date });
                                fileName = fileName.replaceAll(' ', '_') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportCalendarInvoiceableReadings() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            CalendarInvoiceableReadingsDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {
                    wasteTypes: this.wasteTypes
                }
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerCalendarInvoiceableReadings', [
                        obj.exportDates.startDate, obj.exportDates.endDate, obj.wasteTypeId]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                const date = moment().local().format('YYYY-MM-DD HH.mm');
                                let fileName: any = this.translationService
                                    .instant('Rer.dataExportRer.exportRerCalendarInvoiceableReadingsFilename',
                                        { period_begin: obj.exportDates.startDate, period_end: obj.exportDates.endDate, date: date });
                                fileName = fileName.replaceAll(' ', '_') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportCumulatedReadings() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {}
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerDeviceCumulatedReading', [obj.startDate, obj.endDate]), httpOptions).subscribe(
                        (data: ArrayBuffer) => {
                            const blob = new Blob([data], { type: 'application/octet-stream' });
                            const date = moment().local().format('YYYY-MM-DD HH.mm');
                            let fileName: any = this.translationService.instant('Rer.dataExportRer.exportDeviceCumulatedReadingsFilename',
                                { period_begin: obj.startDate, period_end: obj.endDate, date: date });
                            fileName = fileName.replaceAll(' ', '_') + '.csv';
                            saveAs(blob, fileName);
                            this.showSpinner = false;
                        },
                        () => {
                            this.showSpinner = false;
                            this.showNotification();
                        }
                    );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportOrphans() {
        this.showSpinner = true;
        const dialogRef = this.dialog.open(
            ExportPeriodDialogComponent,
            {
                width: '780px',
                disableClose: true,
                panelClass: 'custom-dialog-container',
                data: {}
            }
        );
        dialogRef.afterClosed().subscribe(
            (obj: any) => {
                if (obj) {
                    const httpOptions = {
                        headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
                        responseType: 'arrayBuffer' as 'json',
                    };
                    this.httpClient.get(this.endpointsService.get('rer.exportRerOrphanReadings', [
                        obj.startDate, obj.endDate]), httpOptions).subscribe(
                            (data: ArrayBuffer) => {
                                const blob = new Blob([data], { type: 'application/octet-stream' });
                                let fileName: any = this.translationService.instant('Rer.dataExportRer.exportOrphanReadings');
                                fileName = fileName.replaceAll(' ', "_") + "_"
                                    + moment().format('YYYY-MM-DD') + '.csv';
                                saveAs(blob, fileName);
                                this.showSpinner = false;
                            },
                            () => {
                                this.showSpinner = false;
                                this.showNotification();
                            }
                        );
                } else {
                    this.showSpinner = false;
                }
            }
        );
    }

    public doExportDeletedClients() {
        this.showSpinner = true;
        const httpOptions = {
            headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
            responseType: 'arrayBuffer' as 'json'
        };

        this.httpClient.get(this.endpointsService.get('rer.exportRerDeletedClientPartners'), httpOptions).subscribe(
            (data: ArrayBuffer) => {
                const blob = new Blob([data], { type: 'application/octet-stream' });
                let fileName: any =
                    this.translationService.instant('Rer.dataExportRer.exportRerDeletedClientPartners');
                fileName = fileName.replaceAll(' ', '_') + ' '
                    + moment().format('YYYY-MM-DD') + '.csv';
                saveAs(blob, fileName);
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
                this.showNotification();
            }
        );
    }
    public doExportLostAndSuspendedBins() {
        this.showSpinner = true;
        const httpOptions = {
            headers: new HttpHeaders({ 'Accept': 'text/plain, */*', 'Content-Type': 'application/json' }),
            responseType: 'arrayBuffer' as 'json'
        };

        this.httpClient.get(this.endpointsService.get('rer.exportLostAndSuspendedBins'), httpOptions).subscribe(
            (data: ArrayBuffer) => {
                const blob = new Blob([data], { type: 'application/octet-stream' });
                let fileName: any =
                    this.translationService.instant('Rer.dataExportRer.exportLostAndSuspendedBins');
                fileName = fileName.replaceAll(' ', '_') + ' '
                    + moment().format('YYYY-MM-DD') + '.csv';
                saveAs(blob, fileName);
                this.showSpinner = false;
            },
            () => {
                this.showSpinner = false;
                this.showNotification();
            }
        );
    }
}

