import { RerClientStatus } from '../_constants/rerclientstatusConstants';
import { RerBinStatus } from '../_constants/rerbinstatusconstants';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'rerStatusTransform',
})
export class RerStatusTransformPipe implements PipeTransform {
    transform(item: string, entity: string): string {
        if (entity === 'client') {
            return RerClientStatus[item];
        } else if (entity === 'object') {
            if (item === '-') {
                return '-';
            }

            return RerBinStatus[parseInt(item, 10)];
        }
    }
}
