<h1 mat-dialog-title class="p-2"> {{ 'Rer.rerPlanning.getFromDate' | translate }}</h1>
<div mat-dialog-content>
    <div class="row ml-0 mr-0 mt-4">
        <div class="col-md-12">
            <app-datepicker [label]="getTranslationFor('Rer.rerPlanning.copyPlanningDate')" [includeTime]="false"
                (value)="copyDate" (selectDateEventEmitter)="setDateForCopyEvent($event)" [justifyCenter]="true"
                [locale]="getLocale()" [zIndex]="'99999'">
            </app-datepicker>
        </div>
    </div>
    <div class="row ml-0 mr-0 mt-4">
        <div class="col-md-12">
            <app-datepicker [label]="getTranslationFor('Rer.rerPlanning.planningDate')" [includeTime]="false"
                (value)="date" (selectDateEventEmitter)="setDateForCurrentEvent($event)" [justifyCenter]="true"
                [locale]="getLocale()" [zIndex]="'9999'">
            </app-datepicker>
        </div>
    </div>
</div>
<div mat-dialog-actions class="pt-2 mt-4 d-flex align-items-center justify-content-center">
    <span class="mr-3 eButton cancel pointer pb-2 pt-2 pl-3 pr-3" (click)="onCancel()">
        {{ 'cancel' | translate }}
    </span>
    <span class="mr-3 eButton pointer primary pb-2 pt-2 pl-3 pr-3" (click)="onSubmit()">
        {{ 'ok' | translate }}
    </span>
</div>