import * as moment from 'moment';
import { RefuelingChart } from '../map/refuelingchart.model';

export class ConsumptionFuelLevelBased {

    constructor(
        private consumptions: { pb: string, pe: string, con: number }[],
        private fuelLevels: RefuelingChart[],
        private consumptionStartIndex: number = 0,
        private fuelevelStartIndex: number = 0,
    ) { }

    public get fuelevelIndex(): number {
        return this.fuelevelStartIndex;
    }

    public getConsumption(periodBegin: string, periodEnd: string): number {
        let fuelConsumption = 0;
        const dates = [];
        let index: number;

        for (index = this.consumptionStartIndex; index < this.consumptions.length; index++) {
            if (moment(this.consumptions[index].pb).isSameOrAfter(periodBegin)
                && moment(this.consumptions[index].pe).isSameOrBefore(periodEnd)
            ) {
                // is fully inside
                fuelConsumption += this.consumptions[index].con;
            } else if (moment(this.consumptions[index].pb).isAfter(periodEnd)) {
                // out of interest
                break;
            } else if (moment(this.consumptions[index].pe).isBefore(periodBegin)) {
                // before interest
                continue;
            } else if (moment(this.consumptions[index].pe).isAfter(periodBegin)) {
                // partially inside
                dates.push({
                    begin: moment.max(moment(this.consumptions[index].pb), moment(periodBegin)),
                    end: moment.min(moment(this.consumptions[index].pe), moment(periodEnd))
                });
            }
        }
        this.consumptionStartIndex = index;

        for (let i = 0; i < dates.length; i++) {
            let fuelLevelBegin = null;
            let fuelLevelEnd = null;
            for (index = this.fuelevelStartIndex; index < this.fuelLevels.length; index++) {
                if (moment(this.fuelLevels[index].devicetime).isSameOrBefore(dates[i].begin)) {
                    fuelLevelBegin = this.fuelLevels[index].fuel_level;
                }
                if (moment(this.fuelLevels[index].devicetime).isSameOrBefore(dates[i].end)) {
                    fuelLevelEnd = this.fuelLevels[index].fuel_level;
                } else {
                    break;
                }
            }

            if (fuelLevelBegin && fuelLevelEnd) {
                fuelConsumption += fuelLevelBegin - fuelLevelEnd;
            }

            this.fuelevelStartIndex = index;
        }

        return fuelConsumption;
    }
}
