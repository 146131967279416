import { Component, OnInit, Input, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { RerIncident } from 'app/modules/rer/_model/rerincident.model';
import { RerIncidentLog } from 'app/modules/rer/_model/rerincidentlog.model';
import { RerService } from 'app/modules/rer/_service/rer.service';

@Component({
    selector: 'app-incident-log',
    templateUrl: './incident-log.component.html',
    styleUrls: ['./incident-log.component.scss']
})
export class IncidentLogComponent implements OnInit {

    private _subscriptions: Subscription[] = [];

    @Input() incident: RerIncident;
    @Input() editMode: boolean;

    constructor(
        private rerService: RerService,
        public translateService: TranslateService,
        @Inject(LOCALE_ID) private locale: string) { }

    ngOnInit() {
        this.incident.logs = [];
        this.setSubscriptions();
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private setSubscriptions(): void {

        this._subscriptions.push(
            this.rerService.getRerIncidentLog(this.incident.id)
                .pipe(tap((costs: RerIncidentLog[]) => costs.map((log: RerIncidentLog) => {
                    log.text = this.rerService.getLogText(log)
                    log.dateShown = this.checkShowDate(log.created_at);
                })))
                .subscribe(
                    (logs: RerIncidentLog[]) => {
                        this.incident.logs = logs;
                    }
                )
        );
    }

    public checkShowDate(dateToCheck: string) {
        const dateTimePipe = new DatePipe(this.locale);
        dateToCheck = dateTimePipe.transform(dateToCheck, 'dd.MM.YYYY', null, this.locale);
        return dateToCheck;
    }
}