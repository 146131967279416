<div class="h-100">
    <h4 mat-dialog-title class="p-4 header bgcolor-grey border-bottom-grey-1 font-size-18 mb-1">
        {{ ('Rer.streetGeozones.label' | translate)}}
    </h4>
    <div class="pl-1 pr-1" style="height: calc(100% - 144px)">
        <div class="search-content mr-1">
            <div class="row mt-2 w-100 mx-0">
                <div class="col-12 pl-0 pr-0">
                    <div class="datatTableFilter p-2 d-flex align-items-center w-100 height-45">
                        <i class="icon-cts-action-search mr-2 font-size-20 color-grey"></i>
                        <input matInput autocomplete="off" [(ngModel)]="currentFilterValue" (keyup)="applyFilter($event.target.value)"
                            class="flex-grow-1" placeholder="{{ 'Rer.streetGeozones.list.quickSearch' | translate }}">
                    </div>
                </div>
            </div>
        </div>
        <mat-table #table [dataSource]="dataSource" matSort style="height: calc(100% - 144px)">
            <ng-container matColumnDef="street">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'Rer.rerPlanning.routePlanning.dataTable.street' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                     [class.color-red-primary]="!element.periodBegin && !element.periodBegin" [class.color-green-primary]="element.periodBegin && element.periodBegin">
                    {{ element.street }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="device">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Rer.rerPlanning.routePlanning.dataTable.device' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                     [class.color-red-primary]="!element.periodBegin && !element.periodBegin" [class.color-green-primary]="element.periodBegin && element.periodBegin">
                    {{ element.device }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="periodBegin">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Rer.rerPlanning.routePlanning.dataTable.periodBegin' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                     [class.color-red-primary]="!element.periodBegin && !element.periodBegin" [class.color-green-primary]="element.periodBegin && element.periodBegin">
                    {{ formatDate(element.periodBegin) | date: 'Y-MM-dd HH:mm' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="periodEnd">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Rer.rerPlanning.routePlanning.dataTable.periodEnd' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                     [class.color-red-primary]="!element.periodBegin && !element.periodBegin" [class.color-green-primary]="element.periodBegin && element.periodBegin">
                    {{ formatDate(element.periodEnd) | date: 'Y-MM-dd  HH:mm' }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="waste">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Rer.rerPlanning.routePlanning.dataTable.waste' | translate }} </mat-header-cell>
                <mat-cell *matCellDef="let element"
                     [class.color-red-primary]="!element.periodBegin && !element.periodBegin" [class.color-green-primary]="element.periodBegin && element.periodBegin">
                    {{ element.waste }}</mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="pt-2 pb-2 mb-1">
            </mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="mb-1 p-1 mr-3">
            </mat-row>
        </mat-table>
        <mat-paginator class="mr-1" #paginator [pageSize]="20" [pageSizeOptions]="[5, 10, 20, 50, 100]" [showFirstLastButtons]="true">
        </mat-paginator>
    </div>
    <div class="pl-2 pr-2 pb-2">
        <div class="buttons d-flex align-items-center justify-content-lg-end pt-2">
            <div class="button-component bgcolor-white mr-auto" (click)="dialogRef.close(false)">
                <div class="inner inner-white user-no-select pb-2 pt-2 pl-4 pr-4">
                    <span class="color-grey m-0">{{ 'close' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</div>