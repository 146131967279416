<div class="bgcolor-white p-2 m-4 position-relative rerplannings-container" style="height: calc(100% - 32px);">
    <mat-tab-group class="h-100" animationDuration="0ms">
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-menu-settings font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.rerSettings.generalSettings' | translate }}
                </span>
            </ng-template>
            <app-general-settings></app-general-settings>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="ctsicon-fap-waste-bin font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.rerSettings.wasteType' | translate }}
                </span>
            </ng-template>
            <app-waste-type></app-waste-type>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="ctsicon-fap-waste-bin font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.rerSettings.binType' | translate }}
                </span>
            </ng-template>
            <app-bin-type></app-bin-type>
        </mat-tab>
        <mat-tab *ngIf="hasRerBinOwnershipAccess">
            <ng-template mat-tab-label>
                <i class="ctsicon-fap-waste-bin font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.rerSettings.binOwnership' | translate }}
                </span>
            </ng-template>
            <app-bin-ownership></app-bin-ownership>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="ctsicon-topnav-reports-off font-size-16 p-0"></i>
                <span class="ml-1 d-flex align-items-center">
                    {{ 'Rer.rerSettings.area' | translate }}
                </span>
            </ng-template>
            <app-area></app-area>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-documents font-size-16"></i>
                <span class="ml-1 mr-2">{{ 'Rer.rerSettings.logs.label' | translate }}</span>
            </ng-template>
            <app-setting-logs></app-setting-logs>
        </mat-tab>  
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-documents font-size-16"></i>
                <span class="ml-1 mr-2">{{ 'Rer.rerSettings.emailSettings.label' | translate }}</span>
            </ng-template>        
            <app-email-settings></app-email-settings>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <i class="icon-tmss-tab-documents font-size-16"></i>
                <span class="ml-1 mr-2">{{ 'Rer.rerSettings.emailTemplate.name' | translate }}</span>
            </ng-template>
            <app-email-template></app-email-template>
        </mat-tab>
    </mat-tab-group>
</div>
